<div class="align-content-start justify-content-start notification_details">
  <div class="message_containers">
    <div class="toolbar_detail">
      <span class="time_date text-base font-medium">{{
        currentOpenNotification?.alert_datetime | date : "shortTime"
      }}</span>
      <span>
        <i class="material-symbols-outlined btn_warning" 
        (click)="handleToggleSingleNotificationPriority(currentOpenNotification.id)"> priority_high </i>
      </span>
      <span>
        <i
          class="material-symbols-outlined btn_delete"
          (click)="handleDeleteNotification(currentOpenNotification.id)"
        >
          delete
        </i>
      </span>
    </div> 

    <div class="message_body" [innerHTML]="currentOpenEmailTemplate"></div>
  </div>
</div>
