import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { BaseService } from "../../../common/services/base.service";
import {catchError, Observable} from "rxjs";
import { NDBNavaid } from "../models/NDBNavaid";

@Injectable({
    providedIn: 'root'
  })
  export class TerminalNDBNavaidService extends BaseService {
    constructor(private http: HttpClient) {
      super();
      this.baseUrl = environment.apiUrl.admin;
    }

    getDatesOfFiles(): Observable<string[]> {
      const url = environment.apiUrl.admin + "date-catalogue"
      return this.http.get<string[]>(url)
        .pipe(catchError(this.httpHandleError));
    }

    getTerminalNDBNavaids(dte, airport_icao_identifier) {
      const url = `${this.baseUrl}terminal-navaid-ndb/${dte}/${airport_icao_identifier}`
      return this.http.get<NDBNavaid[]>(url).pipe(catchError(this.httpHandleError));
    }

    getTerminalNDBNavaid(dte, airport_icao_identifier, ndb_identifier) {
      const url = `${this.baseUrl}terminal-navaid-ndb/${dte}/${airport_icao_identifier}/${ndb_identifier}`
      console.log("Calling url : " + url)
      return this.http.get<NDBNavaid>(url).pipe(catchError(this.httpHandleError));
    }
}
