import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/common/services/breadcrumb.service";
import { MenuService } from "src/app/common/services/menu.service";
import { AcmsReportMapModel } from "src/app/features/configuration/models/acmsReportMap";
import { AcmsReportMapService } from "src/app/features/configuration/services/acms-report-map.service";
import { BaseListComponent } from "../../base/base-list-component";
import { acmsReportMapListInput } from "../../input-model/acms-report-map-input";
import { SecurityUserService } from "src/app/security/services/security-user.service";
import { permissions } from "../../../../integration/configuration.permissions";
import { CommonService } from "src/app/common/services/common.service";
import { Components } from '../../../../integration/configuration.components';

@Component({
    selector: 'app-acms-report-map',
    templateUrl: '../../base/list.component.html',
    providers: [{provide: acmsReportMapListInput  ,
        useFactory:(acmsReportMapService: AcmsReportMapService, commonService: CommonService ) => new acmsReportMapListInput(acmsReportMapService, commonService) 
       , 
       deps:[AcmsReportMapService, CommonService]
      }
    ]
  })
  export class AcmsReportMapComponent 
  extends BaseListComponent<AcmsReportMapModel>
  implements OnInit  {
  
   // pageTitle = MenuService.AcmsReportMapLabel;

    constructor(
       
        messageService: MessageService,
        confirmationService: ConfirmationService,
        breadcrumbService: BreadcrumbService ,
        acmsReportInput: acmsReportMapListInput,
        router: Router,
        private route: ActivatedRoute,
        private realUserService: SecurityUserService)
    {
        super(messageService,confirmationService,router,breadcrumbService,acmsReportInput);                         
        this.breadcrumbService.setItems(route,[{label: Components.AcmsReportMap.label}])
    }

    ngOnInit(): void{
            
            this.userCanAdd = this.realUserService.userHasPermission(permissions.acms_report.create);
            this.userCanModify = this.realUserService.userHasPermission(permissions.acms_report.manage);
            this.userCanDelete =this.realUserService.userHasPermission(permissions.acms_report.delete);
            this.loadingCount ++ ;
            this.getAllData();
            this.breadcrumbService.setItems(this.route,[{label: Components.AcmsReportMap.label}])
         
          


    }
  }