import { Component, EventEmitter, Input, Output } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { MainComponent } from '../../main/main.component';
import {
  INotification,
  INotificationsGroupedByDate,
} from 'src/app/common/models/notifications';
import { Application } from 'src/app/features/administration/models/application';

@Component({
  selector: 'app-top-menu-notifications-modal',
  templateUrl: './notifications-modal.component.html',
  animations: [
    trigger('topbarActionPanelAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scaleY(0.8)' }),
        animate('.12s cubic-bezier(0, 0, 0.2, 1)', style({ opacity: 1, transform: '*' })),
      ]),
      transition(':leave', [
        animate('.1s linear', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class TopMenuNotificationsModalComponent {
  @Input() appMain: MainComponent;
  @Input() unreadNotifications: number;
  @Input() notifications: INotification[];
  @Input() notificationsGroupedByDate: INotificationsGroupedByDate[];
  @Input() applications: Application[];
  @Output() onNotificationClick = new EventEmitter<number>();
  @Output() handleViewAllNotificationsClick = new EventEmitter();

  getNotificationTime(isoDateString: string){
    const notificationDate = new Date(isoDateString);

    let hours = notificationDate.getHours();
    const minutes = notificationDate.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'pm' : 'am';

    hours = hours % 12;
    hours = hours ? hours : 12;

    const formattedTime = `${hours}:${minutes}${ampm}`;
    return formattedTime;
  }

  getConcatenatedNotificationMessage(message: string | null){
    if(!message) return "-"
    return message.length < 45 ? message : `${message.substring(0, 45)}...`
  }

  handleNotificationClick(notificationId: number){
    this.onNotificationClick.emit(notificationId);
  }

  viewAllNotifications(){
    this.handleViewAllNotificationsClick.emit()
  }

  getNotificationApplication(appId){
    return this.applications.find(({application_id}) => application_id == appId)?.application_name ?? ""
  }

  shouldRenderApplicationBadge(appId){
    return !!this.applications.find(({application_id}) => application_id == appId)?.application_name
  }
}
