<p-blockUI [blocked]="loadingCount > 0">
    <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
  </p-blockUI>

  <div class="card" *ngIf="loadingCount==0">
    <div class="card-header m-2">
      <h5>{{pageTitle}}</h5>
      <p-dropdown [options]="file_dates" [(ngModel)]="selected_date" optionLabel="dte" [filter]="true"
      (onChange)="onChange($event)" tooltip="Filter by AIRAC date" filterBy="dte" [showClear]="false" placeholder="Select a date">
      <ng-template pTemplate="selectedItem">
        <div *ngIf="selected_date">

          <div>{{selected_date.dte}}</div>
        </div>
      </ng-template>
      <ng-template let-filedate pTemplate="item">
        <div class="country-item">
          <div>{{filedate.dte}}</div>
        </div>
      </ng-template>
    </p-dropdown>
    </div>


    <div class="card">
      <div class=" grid">
        <div class="lg:col-12 p-0 m-0">
          <div class="custom-panel  m-2 p-4 mb-4">

       
      <h6>Details</h6>
        <div class="grid m-0 p-2 mb-2 row-highlights " *ngIf="data">
            <div class="col"><span>ICAO Code: </span></div>
            <div class="col">{{data.icao_code || "-"}}</div>
            <div class="col"><span>Waypoint Identifier: </span></div>
            <div class="col">{{data.waypoint_identifier || "-"}}</div>
            <div class="col"><span>Waypoint Type: </span></div>
            <div class="col">{{data.waypoint_type || "-"}}</div>
        </div>
        <div class="grid grid m-0 p-2 mb-2 row-highlights" *ngIf="data">
            <div class="col"><span>Waypoint Usage: </span></div>
            <div class="col">{{data.waypoint_usage || "-"}}</div>
            <div class="col"><span>Waypoint Name Description: </span></div>
            <div class="col">{{data.waypoint_name_description || "-"}}</div>
            <div class="col"><span>Cycle Date: </span></div>
            <div class="col">{{data.cycle_date || "-"}}</div>
        </div>
        <div>
            
        </div>
        </div>

        <div class="lg:col-12 p-0 m-0">
          <div class="custom-panel  m-2 p-4 mb-4">
      <h6>Position</h6>
        <div class="grid m-0 p-2 mb-2 row-highlights" *ngIf="data">
            <div class="col"><span>Customer Area Code: </span></div>
            <div class="col">{{data.customer_area_code || "-"}}</div>
            <div class="col"><span>Waypoint Latitude: </span></div>
            <div class="col">{{data.waypoint_latitude || "-" | number:'1.8-8'}}</div>
            <div class="col"><span>Waypoint Longitude: </span></div>
            <div class="col">{{data.waypoint_longitude || "-" | number:'1.8-8'}}</div>

        </div>
        <div class="grid m-0 p-2 mb-2 row-highlights" *ngIf="data">
            <div class="col"><span>Dynamic Magnetic Variation: </span></div>
            <div class="col">{{data.dynamic_magnetic_variation || "-"}}</div>
            <div class="col"><span>Datum Code: </span></div>
            <div class="col">{{data.datum_code || "-"}}</div>
            <div class="col"><span>Region Code: </span></div>
            <div class="col">{{data.region_code || "-"}}</div>
        </div>

        </div>
      </div>
    </div>

   
    <ng-template pTemplate="emptymessage">
				<tr >
					<td colspan="8">No terminal waypoint found.</td>
				</tr>
    </ng-template>
  </div>
