<div class="col-12">
  <p-table id="dt" [filterDelay]="0" sortField="name"
    #dataredaction [value]="flightdataredactions" [globalFilterFields]="[
      'name',
      'description',
      'dataframename',
      'ziptype',
      'textualoutput'
    ]"
    [showCurrentPageReport]="true" [paginator]="flightdataredactions.length > defaultTableRows" [rowsPerPageOptions]="[20,50,100,200]" [rows]="defaultTableRows" currentPageReportTemplate="{{currentPageReport + ' ' + pageTitle}}"
    [(selection)]="selectedCloneData" selectionMode="single" dataKey="redaction_id">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of cols" [pSortableColumn]="col.field">
          <div class="flex justify-content-start align-items-center" *ngIf="col.header != 'Action'">
            {{ col.header }}
            <p-sortIcon [field]="col.field"></p-sortIcon>
            <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu"
              [field]="col.field" type="text" matchMode="contains"></p-columnFilter>
          </div>
          <div *ngIf="col.header == 'Action'">
          </div>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-dataredaction let-tableRow>
      <tr class="p-selectable-row" (click)="handleClickedEdit(dataredaction,$event)">
        <td>{{dataredaction.name}}</td>
        <td>{{dataredaction.description}}</td>
        <td>{{dataredaction.dataframename}}</td>
        <td>{{dataredaction.ziptype}}</td>
        <td>{{dataredaction.textualoutput}}</td>
        <td *ngIf="!readonly">
          <div class="flex align-items-center justify-contents-end">
            <button id="cloneButton" name="cloneButton" type="button" class="p-button-rounded p-button-text mr-2"
              pButton icon="pi pi-clone" pRipple pTooltip="Clone..." *ngIf="canAdd"
              (click)="handleCloneRedaction(dataredaction, $event)"></button>
          </div>
        </td>
        <td *ngIf="!readonly">
          <div class="flex align-items-center justify-contents-end">
            <button id="deleteButton" name="deleteButton" type="button" *ngIf="canDelete"
              class="p-button-rounded p-button-text p-button-danger" pButton pRipple icon="pi pi-trash"
              pTooltip="Delete..." (click)="handleDeleteDataRedaction(dataredaction,$event)"></button>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="8">No Flight Data Redactions Found.</td>
      </tr>
    </ng-template>
  </p-table>
</div>
<div *ngIf="flightdataredactions.length > 0 && defaultTableRows >= flightdataredactions.length" style="text-align: center;">
  <p class="row-paging">Showing 1 to
    {{flightdataredactions.length}} of {{flightdataredactions.length}} {{pageTitle}}</p>
</div>
