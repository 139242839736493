import { permissions } from "src/app/security/models/permissions";
import { permissions as ConfigPermissions } from "src/app/features/configuration/integration/configuration.permissions";
import { environment } from "src/environments/environment";
import { permissions as DatadeliveryPermissions } from "src/app/features/datadelivery/integration/datadelivery.permissions";
import { permissions as EndpointDataDeliveryPermissions } from "src/app/features/endpointdatadelivery/integration/endpointdatadelivery.permissions";

export class Application {
  application_id?: number;
  application_name?: string;
  active_application_users?: number;
}

export const applicationRoleData = {
  "Administration": {
    "id": 1,                          // Must match application id in database
    "endPoint": environment.apiUrl.admin,
    "roles": {
      "label": "Administration Roles",
      "permissions": permissions.admin.administration_roles
    },
    "users": {
      "label": "Administration Users",
      "permissions": permissions.admin.administration_users
    }
  },
  "Configuration": {
    "id": 2,
    "endPoint": environment.apiUrl.config,
    "roles": {
      "label": "Configuration Roles",
      "permissions": ConfigPermissions.configuration_roles
    },
    "users": {
      "label": "Configuration Users",
      "permissions": ConfigPermissions.configuration_users
    }
  },
  "Datadelivery": {
    "id": 3,
    "endPoint": environment.apiUrl.datadelivery,
    "roles": {
      "label": "Data Delivery Roles",
      "permissions": DatadeliveryPermissions.datadelivery_roles
    },
    "users": {
      "label": "Data Delivery Users",
      "permissions": DatadeliveryPermissions.datadelivery_users
    }
  },
  "SendToTdy": {
    "id": 4,
    "endPoint": environment.apiUrl.sendToTdy,
    "roles": {
      "label": "Send To Teledyne Roles",
      "permissions": permissions.sendToTdy.sendToTdy_roles
    },
    "users": {
      "label": "Send To Teledyne Users",
      "permissions": permissions.sendToTdy.sendToTdy_users
    }
  },
  "EndpointDataDelivery": {
    "id": 5,
    "endPoint": environment.apiUrl.endpointdatadelivery,
    "roles": {
      "label": "Endpoint Data Delivery Roles",
      "permissions": EndpointDataDeliveryPermissions.endpointdatadelivery_roles
    },
    "users": {
      "label": "Endpoint Data Delivery Users",
      "permissions": EndpointDataDeliveryPermissions.endpointdatadelivery_users
    }
  },
}
