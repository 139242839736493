<p-confirmDialog header="Confirm Deletion" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-blockUI [blocked]="loadingCount > 0">
  <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<div class="col-12 card p-3">
    <div class="card-header">
      <h5>{{ pageTitle }}</h5>
     
    </div>
  
    <p-table
      [value]="recording"
      [tableStyle]="{ width: '100%' }"
      [showCurrentPageReport]="true" [paginator]="recording.length > defaultTableRows" 
      [rowsPerPageOptions]="[20,50,100,200]"
      [rows]="defaultTableRows" 
      currentPageReportTemplate="{{currentPageReport + ' ' + pageTitle}}" 
      [sortOrder]="1"
      [totalRecords]="recording.length"
    >
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="client_recording_id">
            <div class="flex justify-content-start align-items-center">
              Client Recording ID<p-sortIcon field="client_recording_id"></p-sortIcon>
              <p-columnFilter
                type="text"
                field="client_recording_id"
                [showAddButton]="false"
                display="menu"
              >
              </p-columnFilter>
            </div>
          </th>
          <th pSortableColumn="unc_path">
            <div class="flex justify-content-start align-items-center">
              Source File UNC Path <p-sortIcon field="unc_path"></p-sortIcon
              ><p-columnFilter
                type="number"
                field="unc_path"
                [showAddButton]="false"
                display="menu"
              >
              </p-columnFilter>
            </div>
          </th>
          <th pSortableColumn="date">
            <div class="flex justify-content-start align-items-center">
              Submitted Date<p-sortIcon field="date"></p-sortIcon>
              <p-columnFilter
                [showAddButton]="false"
                type="text"
                field="date"
                display="menu"
              >
              </p-columnFilter>
            </div>
          </th>
          <th pSortableColumn="aircraft">
            <div class="flex justify-content-start align-items-center">
              Aircraft <p-sortIcon field="aircraft"></p-sortIcon>
              <p-columnFilter
                type="number"
                field="aircraft"
                display="menu"
                [showAddButton]="false"
              >
              </p-columnFilter>
            </div>
          </th>
          <th pSortableColumn="status">
            <div class="flex justify-content-start align-items-center">
              Status <p-sortIcon field="status"></p-sortIcon>
              <p-columnFilter
                type="number"
                field="status"
                display="menu"
                [showAddButton]="false"
              >
              </p-columnFilter>
            </div>
          </th>
          <th pSortableColumn="warning">
            <div class="flex justify-content-start align-items-center">
              Warning Flag <p-sortIcon field="warning"></p-sortIcon>
              <p-columnFilter
                type="number"
                field="warning"
                display="menu"
                [showAddButton]="false"
              >
              </p-columnFilter>
            </div>
          </th>
          <th pSortableColumn="package_name">
            <div class="flex justify-content-start align-items-center">
              Package Name <p-sortIcon field="package_name"></p-sortIcon>
              <p-columnFilter
                type="number"
                field="package_name"
                display="menu"
                [showAddButton]="false"
              >
              </p-columnFilter>
            </div>
          </th>
          <th pSortableColumn="endpoints">
            <div class="flex justify-content-start align-items-center">
              Number Of Endpoints<p-sortIcon field="endpoints"></p-sortIcon>
              <p-columnFilter
                type="number"
                field="endpoints"
                display="menu"
                [showAddButton]="false"
              >
              </p-columnFilter>
            </div>
          </th>
          <th>
            <div class="flex justify-content-start align-items-center">
              Action
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data>
        <tr>
          <td>
            <span class="client_id" (click)="displayData(data.client_recording_id)">{{data.recording_id}}</span></td>
          <td>{{data.rawdata_location}}</td>
          <td>{{data.submitteddate}}</td>
          <td>{{data.aircraft_id}}</td>
          <td>{{data.status}}</td>
          <td>{{data.warning}}</td>
          <td>{{data.input_file_name}}</td>
          <td>{{data.endpoints}}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="9">No Data Delivery Found.</td>
        </tr>
      </ng-template>
    </p-table>
    <div *ngIf="recording.length > 0 && defaultTableRows >= recording.length" style="text-align: center;">
      <p class="row-paging">Showing 1 to
        {{recording.length}} of {{recording.length}} {{pageTitle}}</p>
    </div>
  </div>
 


