<p-blockUI [blocked]="loadingCount > 0">
  <tdy-spinner
    style="position: absolute; left: 50%; top: 50%; z-index: 10000"
  ></tdy-spinner>
</p-blockUI>
<div class="col-12 card p-3">
  <div class="card-header m-2">
    <h5>{{ pageTitle }}</h5>
  </div>

  <div *ngIf="SARredaction" class="col-12 card">
    <app-sar-redaction-details
      [canAdd]="canAdd"
      [canDelete]="canDelete"
      [canEdit]="canEdit"
      [isReadonly]="isReadonly"
      [isNew]="isNew"
      [SARredaction]="SARredaction"
      [convtype]="convtype"
      [redactiontype]="redactiontype"
      [sarmap]="sarmap"
      [jsonParameters]="jsonParameters"
      [showButtons]="showButtons"
      [selectedSarMapIDs]="selectedSarMapIDs"
      [sarMapList]="sarMapList"
      [avialableSarMap]="avialableSarMap"
      [isButtonDisabled]="isButtonDisabled"
      [picklistChange]="picklistChange"
      (submitForm)="onSubmit()"
      (cancelForm)="onCancel()"
      (resetForm)="onReset()"
      (conversionChange)="onConverionChange($event)"
      (sarMapChange)="onSarMapChange($event)"
      (moveToTarget)="onMoveToTarget($event)"
      (moveToSource)="onMoveToSource($event)"
      (moveAllToSource)="onMoveAllToSource($event)"
      (moveAllToTarget)="onMoveAllToTarget($event)"
    ></app-sar-redaction-details>
  </div>
</div>
