import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { Subscription } from 'rxjs';
import { Constants } from 'src/app/common/models/constants';
import { Organization, OrganizationApp, OrganizationType } from '../../models/organization';
import { Setting } from 'src/app/common/models/setting';
import { FileSizeFormatPipe } from 'src/app/platform/pipes/file-size-format.pipe';
import { OrganizationService, Status } from '../../services/organization.service';
import { SettingsChanges } from 'src/app/common/components/settings/settings.component';
import { AircraftApplication, AircraftPair, AircraftForApp } from '../../models/aircraft';
import { Application } from 'src/app/features/administration/models/application';
import { AircraftGroup } from '../../models/aircraftGroup';
import { SecurityUserService } from 'src/app/security/services/security-user.service';

export interface OrgFormChanges {
  details: boolean;
  apps: boolean;
  settings: boolean;
  aircraftApps?: boolean;
}

@Component({
  selector: 'app-organization-details',
  templateUrl: './organization-details.component.html',
  styleUrls: ['./organization-details.component.scss']
})
export class OrganizationDetailsComponent<T extends Organization> implements OnInit, OnDestroy {

  // Organization Instance (Airline/Company)
  _organization;
  @Input() set organization(organization: T) {
    this._organization = organization;
    this.createLogoFiles();
  }

  get organization(): T {
    return this._organization;
  }
  // Display Attributes
  @Input() pageTitle: string;
  @Input() nameDisplay = 'Name';        // Default value if not supplied
  @Input() codeDisplay = 'Code';        // Default value if not supplied
  @Input() IatacodeDisplay = 'IATA Code';        // Default value if not supplied
  @Input() organizationType: OrganizationType;
  @Input() isNew: boolean;
  @Input() loggedIn: boolean;

  // Permissions
  @Input() isSettingEditable: boolean;
  @Input() canEditOrg: boolean;
  @Input() canViewOrg: boolean;
  // Separate permissions to currently loggedIn user who has no permission to view/edit other orgs...
  @Input() canEditOrgDetail: boolean;
  @Input() canViewOrgDetail: boolean;
  @Input() codeIsReadOnly: boolean;

  // Output events
  @Output() onCancelClick = new EventEmitter();
  @Output() onResetClick = new EventEmitter();
  @Output() onSubmitClick = new EventEmitter<OrgFormChanges>();

  // Applications
  @Input() availableApplications: OrganizationApp[];
  @Input() assignedApplications: OrganizationApp[];
  @Output() assignedApplicationsChange = new EventEmitter<OrganizationApp[]>(); // For 2-way binding
  @Input() airlineApplication: any[] = [];

  // Settings
  @Input() settings: Setting[];
  settingMap: Map<number, Setting> = new Map<number, Setting>();
  @Output() settingsChange = new EventEmitter<Setting[]>();   // For 2-way binding

  // Aircraft Applications
  @Input() airline: Organization;
  @Input() aircraftApps: AircraftApplication[];
  @Input() filterAircraftApps: AircraftApplication[] = [];
  @Input() aircraftGroupsOptions: SelectItem[];
  @Input() airlineAircraft: AircraftPair[];
  @Input() aircraftGroups: AircraftGroup[];
  @Output() airlineAircraftChanged = new EventEmitter<AircraftApplication[]>();

  isAirline: boolean;
  logoUrl = '';
  logoFile: File = null;
  logoChanged = false;
  mobileLogoUrl = '';
  mobileLogoFile: File = null;
  mobileLogoChanged = false;
  applicationChanged = false;
  maxLogoFileSize = Constants.OneMegabyte;
  fileUploading = false;
  statusSubscription: Subscription;
  settingsChanged = false;
  settingsValid = true;
  selectedAircraftGroup: number;
  aircraftAppsChanged: boolean;
  minExpiryDate: Date;
  invalidAircraftLimit = false;
  activeTabIndex = 0;
  activeOrg = '';

  @ViewChild('organizationForm') organizationForm: NgForm;
  @ViewChild('logoUpload') logoUpload: FileUpload;
  @ViewChild('logoMobileUpload') logoMobileUpload: FileUpload;

  constructor(private confirmationService: ConfirmationService,
    private securityUserService: SecurityUserService,
    private messageService: MessageService,
    private fileSizeFormatter: FileSizeFormatPipe,
    private organizationService: OrganizationService) {
  }

  ngOnInit(): void {
    this.activeOrg = this.securityUserService.currentUserInfo.activeOrg;
    this.isAirline = this.organizationType == OrganizationType.Airline;
    this.statusSubscription = this.organizationService.statusChange$().subscribe(s => {
      switch (s.status) {
        case Status.Success:
          {
            this.organizationForm.resetForm(this.organizationForm.value);
            this.mobileLogoChanged = this.logoChanged = this.applicationChanged = this.settingsChanged = this.aircraftAppsChanged = false;
            this.reportSuccess(`${s.message}`);
            break;
          }
      }
    });

    // Set up minimum Expiry date for airline application
    const dt = new Date();
    // Set the time to the end of the day (23:59:59)
    dt.setHours(23);
    dt.setMinutes(59);
    dt.setSeconds(59);
    dt.setMilliseconds(999);
    this.minExpiryDate = dt;
  }

  ngOnDestroy(): void {
    this.statusSubscription?.unsubscribe();
  }

  createLogoFiles() {
    if (this.organization?.web_logo) {
      const myBlob = new Blob([this.organization?.web_logo], { type: 'image/*' });
      this.logoFile = new File([myBlob], 'image', { type: myBlob.type });
    }
    if (this.organization?.mobile_logo) {
      const myBlob = new Blob([this.organization?.mobile_logo], { type: 'image/*' });
      this.mobileLogoFile = new File([myBlob], 'image', { type: myBlob.type });
    }
  }

  createLogoImageFromBlob(image: any, isMobileLogo: boolean) {
    if (isMobileLogo) {
      this.mobileLogoFile = image;
    } else {
      this.logoFile = image;
    }
    const blob = new Blob([image], { type: image.type });
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      if (isMobileLogo) {
        this.organization.mobile_logo = reader.result as string;
      } else {
        this.organization.web_logo = reader.result as string;
      }
    }, false);

    if (image) {
      reader.readAsDataURL(blob);
    }
  }

  onLogoRemove(isMobileLogo: boolean) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to remove the logo?',
      header: 'Cancel?',
      icon: 'pi pi-exclamation-triangle',
      // rejectButtonStyleClass: 'p-button-text',
      accept: () => {
        if (isMobileLogo) {
          this.logoMobileUpload?.clear();
          this.mobileLogoFile = this.organization.mobile_logo = null;
          this.mobileLogoChanged = true;
        } else {
          this.logoUpload?.clear();
          this.logoFile = this.organization.web_logo = null;
          this.logoChanged = true;
        }
      }
    });
  }

  /**
   * Event handler for when the user selects a new mobile or web logo.
   *
   * @param event - The event that includes the uploaded file.
   * @param isMobileLogo - Flag indicating if selecting mobile logo or web logo.
   */
  onLogoSelect(event, isMobileLogo: boolean) {
    this.fileUploading = true;
    this.selectLogo(event, isMobileLogo);
    this.fileUploading = false;
  }

  /**
   * Shared function for web and mobile logo selection. If the file is too large or not the correct type,
   * the default file upload error messages are cleared and a custom one consistent with the rest of the site
   * is displayed. Otherwise, the logo is generated.
   * @param event
   * @param isMobileLogo
   */
  selectLogo(event, isMobileLogo: boolean) {
    if (event.files[0].size > this.maxLogoFileSize) {
      // Clear the default file upload error message and display our own
      if (isMobileLogo) {
        this.logoMobileUpload.msgs = [];
      } else {
        this.logoUpload.msgs = [];
      }
      this.messageService.add({
        key: 'message',
        severity: 'error',
        summary: 'File Too Large',
        detail: event.files[0].name + ' is too large (' + this.fileSizeFormatter.transform(event.files[0].size) +
          '). The maximum size is ' + this.fileSizeFormatter.transform(this.maxLogoFileSize) + '.'
      });
    } else if (!event.files[0].type.includes('image')) {
      // Clear the default file upload error message and display our own
      if (isMobileLogo) {
        this.logoMobileUpload.msgs = [];
      } else {
        this.logoUpload.msgs = [];
      }
      this.reportError(event.files[0].name + ' is not the correct type. Only image file types are supported.', 'Incorrect File Type');
    } else {
      this.createLogoImageFromBlob(event.files[0], isMobileLogo);
      if (isMobileLogo) {
        this.mobileLogoChanged = true;
      } else {
        this.logoChanged = true;
      }
    }
  }

  onSubmit() {

    if (this.isAirline) {
      this.airlineAircraftChanged.emit(this.filterAircraftApps);
    }
    for (const setting of this.settings) {
      if (typeof setting.setting_value === 'string' && setting.settingdefinition_id == 1) {
        const numericValue = Number(setting.setting_value);
        if (!isNaN(numericValue)) {
          setting.setting_value = numericValue;
        }
      }
    }
    this.onSubmitClick.emit({
      details: this.organizationForm.dirty || this.logoChanged || this.mobileLogoChanged,
      apps: this.applicationChanged,
      settings: this.settingsChanged,
      aircraftApps: this.aircraftAppsChanged
    });

  }

  onCancel() {
    //
    if (this.organizationForm.dirty || this.logoChanged || this.mobileLogoChanged || this.settingsChanged || this.applicationChanged || this.aircraftAppsChanged) {
      this.confirmCancel(() => this.onCancelClick.emit())
    }
    else {
      this.onCancelClick.emit();
    }
  }

  onReset() {
    //
    this.logoFile = this.mobileLogoFile = null;
    this.logoUpload?.clear();
    this.logoMobileUpload?.clear();
    this.assignedApplications = [];
    this.logoChanged = this.mobileLogoChanged = this.settingsChanged = this.applicationChanged = this.aircraftAppsChanged = false;
    this.organizationForm.resetForm();
    this.activeTabIndex = 0;
    this.onResetClick.emit();
  }

  onSelectAllApps(event) {
    this.filterAircraftApps.forEach(app => {
      app.selectedApplications = event.checked ? app.availApplications : [];
    });
    this.aircraftAppsChanged = true;
    //console.log("All Checked applicationAircraft", this.applicationAircraft)
  }

  onSelectAircraft(applications: AircraftApplication, event, rowIndex: any) {
    const applicationAircraft: AircraftForApp[] = this.structureToCheckAircraftLimit();

    if (event.checked) {
      applicationAircraft.forEach((appAc) => {
        if (appAc.application.number_of_aircrafts > appAc.appAircraft.length) {
          applications.selectedApplications.push(appAc.application);
        }
      })
    } else {
      applications.selectedApplications = [];
    }
    //applications.selectedApplications = event.checked ? applications.availApplications : [];
    this.aircraftAppsChanged = true;
  }

  onSelectIndividual(applications: AircraftApplication, app: Application, event, rowIndex: number) {
    if (event.checked) {
      if (!applications.selectedApplications?.includes(app)) {
        applications.selectedApplications.push(app);
      }
    } else {
      if (applications.selectedApplications?.includes(app)) {
        const index = applications.selectedApplications.findIndex(a => a.application_id === app.application_id);
        applications.selectedApplications = applications.selectedApplications.filter((selected) => selected != applications.selectedApplications[index])
      }
    }
    this.aircraftAppsChanged = true;
  }


  onSelectApp(appChecked: Application, event, airApp: AircraftApplication) {
    if (event.checked) {
      this.filterAircraftApps.forEach((aircraft) => {
        if (aircraft.availApplications?.includes(appChecked) && !(aircraft.selectedApplications?.includes(appChecked))) {
          aircraft.selectedApplications.push(appChecked)
        }
      })
    } else {
      this.filterAircraftApps.forEach((aircraft) => {
        if (aircraft.selectedApplications?.includes(appChecked)) {
          //const index = aircraft.selectedApplications.findIndex(a => a.application_id === appChecked.application_id);
          aircraft.selectedApplications = aircraft.selectedApplications.filter((selected) => selected != appChecked)
        }
      })
    }
    this.aircraftAppsChanged = true;
  }


  // Returns true if all applications are selected for all aircrafts
  checkAllAppsSelected(): boolean {
    return this.filterAircraftApps.every((aircraft) => aircraft.availApplications?.length === aircraft.selectedApplications?.length)
  }

  // Returns true if one Application is selected for all aircrafts
  checkApplicationSelected(app: Application): boolean {
    return this.filterAircraftApps.every((aircraft) => aircraft.selectedApplications?.includes(app))
  }

  onSelectedChange(selectedApps?: any) {
    if (selectedApps) {
      this.assignedApplications = selectedApps;
      this.assignedApplicationsChange.emit(selectedApps);
      if (this.isAirline) {
        // Populating new assigned applications to aircraft applications tab...
        this.filterAircraftApps = this.filterAircraftApps.map((aircraft) => {
          return {
            aircraft: aircraft.aircraft,
            availApplications: this.assignedApplications,
            selectedApplications: aircraft.selectedApplications.filter((sa) => this.assignedApplications.includes(sa))
          }
        });
        this.aircraftApps = this.filterAircraftApps;
        if (this.airlineAircraft.length) {
          this.aircraftAppsChanged = true;
        }
      }
    }
    this.applicationChanged = true;
  }


  aircraftLimitExceed(aircraftLimit: string, application?: OrganizationApp, aircraft?: AircraftPair, rowIndex?: any): boolean {
    const applicationAircraft: AircraftForApp[] = this.structureToCheckAircraftLimit();
    switch (aircraftLimit) {
      case 'allAppsCheck':
        return applicationAircraft.some((app) => app.application.number_of_aircrafts < app.airlineAircraft.length);
      case 'AppCheck':
        return application.number_of_aircrafts < applicationAircraft[0].airlineAircraft.length;
      case 'aircraftCheck':
        const appFound = applicationAircraft.find((appAC) => appAC.application.application_id == application.application_id);
        return (!appFound.appAircraft.includes(aircraft)) && (appFound.appAircraft.length >= appFound.application.number_of_aircrafts) && !this.filterAircraftApps[rowIndex].selectedApplications.includes(application);
    }
  }


  onChangeAircraftGroup(event) {
    const groupID = event.value;
    if (groupID) {
      const filteredGroup = this.aircraftGroups.filter((aircraft) => aircraft.aircraftgroup_id === groupID);
      this.filterAircraftApps = this.aircraftApps.filter((aircraft) => filteredGroup.some((ac) => ac.aircraft_id?.includes(aircraft.aircraft.aircraft_id)))
    }
    else {
      this.filterAircraftApps = this.aircraftApps
    }
  }

  // creating a structure to properly match application doesn't exceed manimum number of aircraft...
  structureToCheckAircraftLimit(): AircraftForApp[] {
    const applicationAircraft: AircraftForApp[] = [];
    this.aircraftApps[0]?.availApplications?.forEach(
      (availApp) => {
        const aircraft: AircraftPair[] = [];
        const exist = this.aircraftApps.filter((ac) => ac.selectedApplications.find((app) => app.application_id == availApp.application_id));
        if (exist) {
          exist.forEach((el) => {
            aircraft.push({
              aircraft_id: el.aircraft.aircraft_id,
              aircraft_registration: el.aircraft.aircraft_registration
            })
          })
        }

        applicationAircraft?.push({
          application: availApp,
          appAircraft: aircraft,
          airlineAircraft: this.aircraftApps.map(
            (filterAC) => {
              return {
                aircraft_id: filterAC.aircraft.aircraft_id,
                aircraft_registration: filterAC.aircraft.aircraft_registration
              }
            }
          )
        })
      })
    return applicationAircraft;
  }

  //  Compare number of Users/Aircraft value with actual payload....
  limitDecreased(app: OrganizationApp, source?: string): boolean {
    switch (source) {
      case 'check-users-limit':
        if ((app?.number_of_users < app?.active_application_users) && app?.number_of_users != null && app?.number_of_users != 0) {
          app.usersLimitInvalid = true;
          return true;
        } else {
          app.usersLimitInvalid = false;
          return false;
        }
      case 'check-aircraft-limit':
        const applicationAircraft: AircraftForApp[] = this.structureToCheckAircraftLimit();
        const found = applicationAircraft.find((ap) => ap.application.application_id == app.application_id);
        if ((found?.appAircraft.length > app?.number_of_aircrafts) && app?.number_of_aircrafts != null && app?.number_of_aircrafts != 0) {
          app.aircraftLimitInvalid = true;
          return true;
        } else {
          app.aircraftLimitInvalid = false;
          return false;
        }
    }
  }

  onSettingsChanged(setting: SettingsChanges) {
    this.settingMap[setting.value?.settingdefinition_id] = setting.value?.setting_value;
    this.settingsValid = setting.valid;
    this.settingsChanged = true;
    this.settingsChange.emit(Array.from(this.settingMap.values()));
  }

  organizationApplicationValidation(): boolean {
    if (this.assignedApplications?.length > 0 && this.applicationChanged) {
      return (this.assignedApplications.findIndex(s => s.expiry_date == null || (s.number_of_users == null || s.number_of_users == 0 || s.usersLimitInvalid === true) || (s.number_of_aircrafts == null || s.number_of_aircrafts == 0 || s.aircraftLimitInvalid === true)) == -1)
    } else if (!this.assignedApplications?.length && this.applicationChanged) {
      return true;
    }
    return true;
  }

  isFormValidatedOnSave(): boolean {
    if (this.isNew) {
      return !this.organizationForm?.valid || !this.settingsValid || !this.organizationApplicationValidation()
    } else {
      if (this.organizationForm?.dirty || this.logoChanged || this.mobileLogoChanged || this.settingsChanged || this.applicationChanged || this.aircraftAppsChanged) {
        return !(this.organizationForm.valid && this.settingsValid && this.organizationApplicationValidation())
      } else {
        return true
      }
    }
  }

  private reportError(errorDetail: any, errorSummary: string) {
    this.messageService.add({
      key: 'message',
      severity: 'error',
      summary: errorSummary,
      detail: errorDetail
    });
  }

  private reportSuccess(successDetail: string) {
    this.messageService.add({
      key: 'message',
      severity: 'info',
      summary: 'Success',
      detail: successDetail
    });
  }

  private confirmCancel(callback: () => void) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to cancel?',
      header: 'Cancel?',
      rejectButtonStyleClass: 'p-button-text',
      accept: () => {
        callback();
      }
    });
  }
}
