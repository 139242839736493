import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BaseService } from './base.service';
import { INotification } from '../models/notifications';
import { applicationRoleData } from 'src/app/features/administration/models/application';

export class NotificationsServiceHelper extends BaseService {
  getProperlyMappedNotifications(response: any) {
    const mappedNotifications = response
      ?.map((notification: INotification) => {
        notification.template_data = (() => {
          try {
            return JSON.parse(notification?.template_data || "");
          } catch (e) {}
        })()
        return notification
      })
      ?.filter(
        (n: INotification) =>
          n?.alert_type !== null &&
          n?.alert_type !== undefined &&
          n?.alert_type?.length > 0
      );
    return mappedNotifications;
  }

  markNotificationsAsSeenInternally(
    payload: {
      notification_id: number[];
      seen?: boolean;
      priority?: boolean;
    },
    notifications: INotification[]
  ) {
    notifications.forEach((notification) => {
      if (payload.notification_id.includes(notification.id)) {
        notification.seen = true;
      }
    });
  }
}

@Injectable({
  providedIn: 'root',
})
export class NotificationsService extends NotificationsServiceHelper {
  private notificationCountSource = new BehaviorSubject<number>(0);
  notificationCount$ = this.notificationCountSource.asObservable();

  private notifications: INotification[] = [];
  private commonNotifications: INotification[] = [];

  constructor(private http: HttpClient) {
    super();
    this.baseUrl = environment.apiUrl.datadelivery;
  }

  getCommonNotifications(limit: number = 10): Observable<INotification[]> {
    const url = environment.apiUrl.common + `alert-notification?limit=${limit}`;
    return this.http
      .get<INotification[]>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        map((response: any) => {
          this.commonNotifications =
            this.getProperlyMappedNotifications(response);
          return this.commonNotifications;
        }),
        catchError(this.httpHandleError)
      );
  }

  getAppSpecificNotifications(appId: number): Observable<INotification[]> {
    const url =
      Object.values(applicationRoleData).find(({ id }) => id == appId)
        .endPoint + `alert-notification`;
    return this.http
      .get<INotification[]>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        map((response: any) => {
          this.notifications = this.getProperlyMappedNotifications(response);
          return this.notifications;
        }),
        catchError(this.httpHandleError)
      );
  }

  markNotificationsAsRead(payload: {
    notification_id: number[];
    seen?: boolean;
    priority?: boolean;
  }): Observable<number[]> {
    const originalNotificationState = Object.assign([], this.notifications);
    const originalCommonNotificationState = Object.assign(
      [],
      this.commonNotifications
    );

    this.markNotificationsAsSeenInternally(payload, this.notifications);
    this.markNotificationsAsSeenInternally(payload, this.commonNotifications);

    this.updateUnreadNotificationsCount();

    const url = this.baseUrl + 'alert-notification';
    return this.http
      .patch<any>(url, payload, {
        headers: this.httpGetHeadersForJSON(),
      })
      .pipe(
        catchError((error: any) => {
          this.notifications = originalNotificationState;
          this.commonNotifications = originalCommonNotificationState;
          return this.httpHandleError(error);
        })
      );
  }

  toggleNotificationsPriority(payload: {
    notification_id: number[];
    priority?: boolean;
  }): Observable<number[]> {
    const originalNotificationState = Object.assign([], this.notifications);
    this.notifications.forEach((notification) => {
      if (payload.notification_id.includes(notification.id)) {
        notification.priority = !notification.priority;
      }
    });
    const url = this.baseUrl + 'alert-notification';
    return this.http
      .patch<any>(url, payload, {
        headers: this.httpGetHeadersForJSON(),
      })
      .pipe(
        catchError((error: any) => {
          this.notifications = originalNotificationState;
          return this.httpHandleError(error);
        })
      );
  }

  getCommonNotificationsUnreadCount() {
    return this.commonNotifications.filter(({ seen }) => !seen).length;
  }

  updateUnreadNotificationsCount(count?: number) {
    let unreadCount =
      count === undefined ? this.getCommonNotificationsUnreadCount() : count;
    this.notificationCountSource.next(unreadCount);
  }

  private removeDeletedNotificationsFromState(payload: {
    notification_id: number[];
  }) {
    const newNotifications = this.notifications.filter(
      ({ id }) => !payload.notification_id.includes(id)
    );
    this.notifications = newNotifications;
    const newCommonNotifications = this.commonNotifications.filter(
      ({ id }) => !payload.notification_id.includes(id)
    );
    this.commonNotifications = newCommonNotifications;
    this.updateUnreadNotificationsCount();
  }

  deleteNotifications(payload: { notification_id: number[] }) {
    const originalNotificationState = Object.assign([], this.notifications);
    const originalCommonNotificationState = Object.assign(
      [],
      this.commonNotifications
    );
    this.removeDeletedNotificationsFromState(payload);
    const url = this.baseUrl + 'alert-notification';
    return this.http
      .delete<any>(url, {
        body: payload,
        headers: this.httpGetHeadersForJSON(),
      })
      .pipe(
        catchError((error) => {
          this.notifications = originalNotificationState;
          this.commonNotifications = originalCommonNotificationState;
          return this.httpHandleError(error);
        })
      );
  }
}
