<div class="field col-12">
  <div class="col-6 p-1">
    <div class="row" style="margin-bottom: 0px;">
      <div class="custom-panel -ml-2 custom-not-avail-apps">
        <h6>Your Selected Business</h6>
        <ul class="my-list">
          <li>{{user.business_name}}</li>
        </ul>
      </div>
    </div>
  </div>
  <h6>Additional Organizations</h6>
  <p-pickList
    name="name"
    id="name"
    class="orgs_table"
    [source]="additionalAvailOrgs"
    [disabled]="(!canEdit && (this.user.user_id > 0 || loggedIn)) || (!canAdd && this.user.user_id == -1)"
    [target]="userAssignedOrgs"
    filterBy="name"
    sourceHeader="Available organizations"
    targetHeader="Selected organizations"
    [showSourceControls]="false"
    [showTargetControls]="false"
    (onMoveToTarget)="onOrgChange()"
    (onMoveToSource)="onOrgChange()"
    (onMoveAllToSource)="onOrgChange()"
    (onMoveAllToTarget)="onOrgChange()"
    [dragdrop]="true"
    [responsive]="true"
    [sourceStyle]="{ height: '30rem' }"
    [targetStyle]="{ height: '30rem' }"
    breakpoint="1400px"
  >
    <ng-template let-org pTemplate="org">
      <ng-container *ngIf="toUserOrg(org); let item" [class.disabled]="true" style="pointer-events: none; cursor: default;">
        <div>
          <div>{{org.name}}</div>
        </div>
      </ng-container>
    </ng-template>
  </p-pickList>
</div>