import { Component, OnInit, ViewChild } from '@angular/core';
import { ClientPackages, ClientPackage } from '../../models/ClientPackage';
import { ConfirmationService, MessageService } from 'primeng/api';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Components } from '../../integration/datadelivery.components';
import { BreadcrumbService } from '../../../../common/services/breadcrumb.service';
import {
  Action,
  BaseViewComponent,
} from 'src/app/common/components/base-view.component';
import { forkJoin, takeUntil } from 'rxjs';
import { ClientPackagesService } from '../../services/clientpackages.services';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { permissions } from '../../integration/datadelivery.permissions';
@Component({
  selector: 'app-clientpackage',
  templateUrl: './clientpackage.component.html',
  styleUrls: ['./clientpackage.component.css'],
})
export class ClientpackageComponent
  extends BaseViewComponent
  implements OnInit
{
  @ViewChild('clientpackageForm') clientpackageForm!: NgForm;
  pageTitle = Components.ClientPackages.label;
  public canAdd: boolean;
  public canDelete: boolean;
  public canEdit: boolean;
  cols: any[] = [];
  clientpackages: ClientPackages[] = [];
  clientpackage!: ClientPackage;
  messageLabel = 'client package';
  constructor(
    router: Router,
    private route: ActivatedRoute,
    breadcrumbService: BreadcrumbService,
    confirmationService: ConfirmationService,
    messageService: MessageService,
    private clientpackageservice: ClientPackagesService,
    private realUserService: SecurityUserService
  ) {
    super(messageService, confirmationService, router, breadcrumbService);
    this.getBreadCrums();
  }
  getBreadCrums() {
    this.breadcrumbService.setItems(this.route, [
      { label: Components.ClientPackages.label },
    ]);
  }
  intialVal() {
    this.clientpackage = new ClientPackage();
    this.loadClientPackages();
  }

  ngOnInit() {
    this.getBreadCrums();
    this.canAdd = this.realUserService.userHasPermission(
      permissions.datadelivery_client_packages.create
    );
    this.canEdit = this.realUserService.userHasPermission(
      permissions.datadelivery_client_packages.manage
    );
    this.canDelete = this.realUserService.userHasPermission(
      permissions.datadelivery_client_packages.delete
    );
    this.intialVal();
  }

  openNew() {
    if (!this.canAdd) {
      this.showErrorMsg(
        'User is not authorized to add client package.',
        Action.Add,
        `${this.messageLabel}`
      );
    } else {
      this.router.navigate([Components.AddClientPackages.path]).then();
    }
  }

  deleteClientPackage(packages: ClientPackages) {
    if (!this.canDelete) {
      this.showErrorMsg(
        `User is not authorized to delete an client package.`,
        Action.Delete,
        `${this.messageLabel}s`
      );
    } else {
      this.confirmationService.confirm({
        message:
          'Are you sure that you want to delete the selected client package?',
        accept: () => {
          this.clientpackageservice
            .deleteclientpackages(packages.package_id)
            .subscribe({
              next: () => {
                this.showSuccessMsg(
                  Action.Delete,
                  `${this.messageLabel}`,
                  `${packages.name}`
                );
                // Refresh the list of packages
                this.loadClientPackages();
              },
              error: (error) => {
                this.showErrorMsg(
                  `${error}`,
                  Action.Delete,
                  `${this.messageLabel}s`
                );
              },
            });
        },
      });
    }
  }

  clonePackage(cloned: ClientPackages) {
    this.router
      .navigate([Components.AddClientPackages.path], {
        queryParams: {
          packageId: cloned.package_id,
          name: cloned.name,
          clone: true,
        },
        queryParamsHandling: 'merge',
      })
      .then();
  }

  loadClientPackages() {
    this.loadingCount++;
    forkJoin({
      getclientpackages: this.clientpackageservice.getClientPackages(),
    })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results) => {
          this.clientpackages = results.getclientpackages;
        },
        error: (error) => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        },
      });
  }

  onRowSelect(event: any) {
    this.router
      .navigate([Components.AddClientPackages.path], {
        queryParams: {
          packageId: event.data.package_id,
          name: event.data.name,
        },
      })
      .then();
  }
}
