import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'fileSizeFormat'})

export class FileSizeFormatPipe implements PipeTransform {
  transform(value: number): string {
    if (value === 0) {
      return '0 B';
    } else {
      const k = 1024, dm = 3, sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(value) / Math.log(k));
      return parseFloat((value / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
  }
}
