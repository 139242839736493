import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
import { mergeMap, of, takeUntil } from "rxjs";
import { BreadcrumbService } from "src/app/common/services/breadcrumb.service";
import { DataFrameModel } from "src/app/features/configuration/models/dataframe";
import { DataFrameService } from "src/app/features/configuration/services/data-frame.service";
import { permissions } from "../../../../integration/configuration.permissions";
import { SecurityUserService } from "src/app/security/services/security-user.service";
import { BaseListComponent } from "../../base/base-list-component";
import { dataframeListInput } from "../../input-model/dataframe-input";
import { CommonService } from "src/app/common/services/common.service";
import { Components } from '../../../../integration/configuration.components';
import { Action, BaseViewComponent } from "src/app/common/components/base-view.component";
import { DataframeType } from "../shared/enums/dataframeEnums";

@Component({
    selector: 'data-frame-versions',
    templateUrl: '../../base/list-versions.component.html',
    providers: [{provide: dataframeListInput  ,
        useFactory:(dataframeService: DataFrameService, commonService: CommonService) => new dataframeListInput(dataframeService, commonService) 
       , 
       deps:[DataFrameService, CommonService]
      }
    ]
  })
  export class DataFrameVersionsComponent 
  extends BaseListComponent<DataFrameModel>
  implements OnInit  {
    
    displayDownload = {}
    userCanDownload : boolean;
    messageLabel = "dataframe version";
    constructor(
        
        messageService: MessageService,
        confirmationService: ConfirmationService,
        breadcrumbService: BreadcrumbService ,
        private dataframeInput: dataframeListInput,
        router: Router,
        private route: ActivatedRoute,
        private dataframeService: DataFrameService,
        private realUserService: SecurityUserService
        ) {
        super(messageService,confirmationService,router,breadcrumbService,dataframeInput);
       // this.breadcrumbService.setItems(route,[{label: Components.Dataframe.label,routerLink: Components.Dataframe.path},{label:`Versions`}])
        this.NoRecordMessage ="No versions for this dataframe ...."
        
    }
    ngOnInit():void{
       
        this.userCanAdd = this.realUserService.userHasPermission(permissions.dataframes.create);
        this.userCanModify = this.realUserService.userHasPermission(permissions.dataframes.manage);
        this.userCanDelete =this.realUserService.userHasPermission(permissions.dataframes.delete);
        this.userCanDownload = this.realUserService.userHasPermission(permissions.dataframes.download);
        this.userCanView = this.realUserService.userHasPermission(permissions.dataframes.data_frame_view);
        this.userCanManage =this.realUserService.userHasPermission(permissions.dataframes.data_frame_manage); 
    
            if(this.route.snapshot.queryParamMap.get('a')){
                this.breadcrumbService.setItems(this.route,[{label: Components.Dataframe.label,routerLink: Components.Dataframe.path},{label:`Versions`}])
                this.loadingCount ++;
                this.getRecord(+this.route.snapshot.queryParamMap.get('a'));

              
            }
    
        
    }


  override  onClone(row){
       if(!this.userCanAdd){
        this.showErrorMsg(`User is not authorized to clone dataframe`, Action.Add, `${this.messageLabel}`);
        return;
       }
       const dataframeVersionID = row["dataframeversion_id"];
       const version = row["version"];
       this.navigateToClonePage( dataframeVersionID,version);
    }

 
    navigateToClonePage(ID: number, version: number){
      
        this.router.navigate([this.dataframeInput.componentDetailsLink],{
            queryParams: {
                a: this.route.snapshot.queryParamMap.get('a'),
                c: ID,
                v: version
                }
            
        }).then();
    }

    override onDownload(row): void {
        if(!this.userCanDownload){
            this.showErrorMsg(`User is not authorized to download dataframe`, Action.Download, `${this.messageLabel}`);
            return;
           }
        const id = row['dataframeversion_id']
        row['isloading'] = true;
        this.dataframeService.getDataFrameVersionById(id)
        .pipe(takeUntil(this.ngUnsubscribe),
              mergeMap(res =>{
                
                this.download_file(res["file"]);
                row['isloading']=false;
                //return this.dataframeService.deletefilefromS3(res["object_name"])
                return of()
              }
                )
        )
        .subscribe({
            next: res=>{},
          error: err=>{
            this.showErrorMsg(`${err}`, Action.Download, `${this.messageLabel}`);
             row['isloading'] = false;
          },
          complete:() =>{row['isloading'] = false;}

        }
        )
        
    }


   private download_file(result){
    
    const src = result;
    const link = document.createElement("a");
    link.href = src;
    link.download = "dataframe.TDF";
    link.click();
    link.remove();
  }

  override onRowSelect($event) {
    this.dataframeService.IdentifyDataFrame($event.data.dataframe_id, $event.data.dataframeversion_id).subscribe((data: any) => {
      let response = JSON.parse(data.content);
      switch(response["ARINC STANDARD"]) {
        case DataframeType.A717: this.navigateListView717Page($event.data.dataframe_id, $event.data.dataframeversion_id,$event.data.version); break;
        case DataframeType.DXS: this.navigateListViewDXSPage($event.data.dataframe_id, $event.data.dataframeversion_id,$event.data.version); break;
        default: this.navigateListView767Page($event.data.dataframe_id, $event.data.dataframeversion_id,$event.data.version);
      }
    });
  }

  navigateListView717Page(frame_Id, version_Id,version_index) {
    //this.pageTitle = "Versions " + this.componentInput.componentVersionsLabel;
    this.navigateToPage(frame_Id, version_Id,version_index, this.dataframeInput.componentListView717Link);
  }

  navigateListView767Page(frame_Id, version_Id,version_index) {
    //this.pageTitle = "Versions " + this.componentInput.componentVersionsLabel;
    this.navigateToPage(frame_Id, version_Id,version_index, this.dataframeInput.componentListView767Link);
  }

  navigateListViewDXSPage(frame_Id, version_Id,version_index) {
    //this.pageTitle = "Versions " + this.componentInput.componentVersionsLabel;
    this.navigateToPage(frame_Id, version_Id,version_index, this.dataframeInput.componentListViewDXSLink);
  }

  navigateToPage(frame_Id, version_Id, version_index, link) {
    this.router.navigate([link], {
      queryParams: {
        Name: this.route.snapshot.queryParamMap.get('Name'),
        frame: frame_Id,
        version: version_Id,
        version_index: version_index
      }
    })
  }
}
