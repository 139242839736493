import { Component, OnInit } from '@angular/core';
import { sarMapsVersions } from 'src/app/features/configuration/models/sarMaps';
import { Action, BaseViewComponent } from 'src/app/common/components/base-view.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { SARMapsService } from 'src/app/features/configuration/services/sar-map.service';
import { takeUntil } from 'rxjs';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { permissions } from '../../../integration/configuration.permissions';
import {Components} from '../../../integration/configuration.components';

@Component({
  selector: 'app-sar-map-versions',
  templateUrl: './sar-map-versions.component.html'
})
export class SarMapVersionsComponent extends BaseViewComponent implements OnInit {
  public canAdd: boolean;
  public canDelete: boolean;
  public canEdit: boolean;
  
  constructor(
    messageService: MessageService,
    router: Router,
    private route: ActivatedRoute,
    private sarMapsService: SARMapsService, 
    breadcrumbService: BreadcrumbService,    
    confirmationService: ConfirmationService,
    private realUserService: SecurityUserService
  ) {
    super(messageService, confirmationService, router, breadcrumbService);
    this.breadcrumbService.setItems(this.route,[
      {label: Components.SarMaps.label,routerLink: Components.SarMaps.path},
      {label: Components.SarMapsVersions.label,routerLink:Components.SarMapsVersions.path}
    ]);
  }

  sarMapsVersions: sarMapsVersions[];
  pageTitle = Components.SarMapsVersions.label;
  sarMapID: number;

  ngOnInit(): void {
      this.canAdd = this.realUserService.userHasPermission(permissions.sar_maps.create);    
      this.canDelete = this.realUserService.userHasPermission(permissions.sar_maps.delete);
      this.canEdit = this.realUserService.userHasPermission(permissions.sar_maps.manage); 
      this.loadVersionData(+this.route.snapshot.queryParamMap.get('sarMapID'));
  }

  loadVersionData(sarMapsID){
    this.loadingCount ++;
    this.sarMapsService.getSARMapVersions(sarMapsID).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next:(data) => {
        this.sarMapsVersions = data; 
        this.updateBreadcrumb( `${Components.SarMapsVersions.label} (${data[0]['name']})`)
           
      },
      error:(err) => {
        this.showErrorMsg(err, Action.Get, "SAR Map Versions");
      },
      complete: () => this.loadingCount --
    })
  }

}
