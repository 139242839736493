import { takeUntil } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AircraftModel } from 'src/app/features/administration/models/aircraftModel';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { Action, BaseViewComponent } from 'src/app/common/components/base-view.component';
import { AircraftModelService } from 'src/app/features/administration/services/aircraft-model.service';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { permissions } from 'src/app/security/models/permissions';
import { Components } from '../../integration/administration.components';

@Component({
  selector: 'app-aircraft-models',
  templateUrl: './aircraft-models.component.html'
})
export class AircraftModelsComponent extends BaseViewComponent implements OnInit {
  pageTitle = Components.AircraftModels.label;
  allModels: AircraftModel[];  // The collection of models bound to the dataTable
  public canAdd: boolean;
  public canDelete: boolean;
  public canView: boolean;
  messageLabel = 'aircraft model';

  /**
   * Constructor for the component.
   *
   * @param aircraftModelService - Interacts with aircraft data.
   * @param userService - Gets user information.
   * @param messageService - Used to display messages.
   * @param route - Used to get the route's query parameters.
   * @param router - Used to navigate to other pages.
   * @param breadcrumbService - Used to update the breadcrumb.
   */
  constructor(private aircraftModelService: AircraftModelService,
    private route: ActivatedRoute,
    private securityUserService: SecurityUserService,
    messageService: MessageService,
    confirmationService: ConfirmationService,
    router: Router,
    breadcrumbService: BreadcrumbService) {
    super(messageService, confirmationService, router, breadcrumbService);

    // Note: Service won't update breadcrumb if caller already did so.
    this.breadcrumbService.setItems(this.route, [{ label: Components.AircraftModels.label }]); // No routerLink value for current page

  }

  /**
   * Lifecycle hook that is called after data-bound properties of a directive are initialized.
   */
  ngOnInit() {
    this.canView = this.securityUserService.userHasPermission(permissions.admin.aircraft_models.view);
    this.canAdd = this.securityUserService.userHasPermission(permissions.admin.aircraft_models.create);
    this.canDelete = this.securityUserService.userHasPermission(permissions.admin.aircraft_models.delete);

    this.breadcrumbService.setItems(this.route, [{ label: Components.AircraftModels.label }]);
    if (this.canView) {
      this.loadModels();
    }
  }

  /**
   * Gets the data required for the initial load of the page.
   */
  loadModels() {
    this.loadingCount++;
    this.aircraftModelService.listAircraftModels()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (models) => {
          this.allModels = models;
        },
        error: error => {
          this.showErrorMsg(error, Action.Get, `${this.messageLabel}`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        }
      });
  }

  /**
   * Event handler for when the Add button is clicked. Navigates to the details page to create a new object.
   */
  onClickAdd() {
    if (!this.canAdd) {
      this.showErrorMsg('User is not authorized to add an aircraft model.', Action.Add, `${this.messageLabel}`);
    } else {
      this.router.navigate([Components.AircraftModelDetails.path]).then();
    }
  }

  /**
   * Event handler for when a row is selected in the dataTable. Navigates to the details page.
   * @param event - The browser event. The data property contains the selected aircraft.
   */
  onRowSelect(event) {
    this.router.navigate([Components.AircraftModelDetails.path], {
      queryParams: {
        m: event.data.aircraftmodel_id, // m = model ID
        name: event.data.name
      }
    }).then();
  }

  onDeleteAircraftModel(aircraftModel: AircraftModel) {
    if (!this.canDelete) {
      this.showErrorMsg('User is not authorized to delete an aircraft model.', Action.Delete, `${this.messageLabel}`, `${aircraftModel?.displayName}`);
    } else {
      this.confirmationService.confirm({
        message: 'Are you sure that you want to delete the selected aircraft model?',
        accept: () => {
          this.aircraftModelService.deleteAircraftModel(aircraftModel.aircraftmodel_id).subscribe({
            next: () => {
              this.showSuccessMsg(Action.Delete, `${this.messageLabel}`, `${aircraftModel?.displayName}`);

              // Refresh the list of aircraft models
              this.loadModels();
            },
            error: error => {
              this.showErrorMsg(error, Action.Delete, `${this.messageLabel}`, `${aircraftModel?.displayName}`);
            }
          });
        }
      });
    }

  }

  toAircraftModel(model: AircraftModel) {
    return model;
  }
}
