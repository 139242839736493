import { Component, ViewChild, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

import {
  ClientApplication,
  ResponseClientApplication,
} from '../../models/clientApplication';
import { ClientApplicationService } from '../../services/clientApplication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Components } from '../../integration/datadelivery.components';
import { BreadcrumbService } from '../../../../common/services/breadcrumb.service';
import {
  Action,
  BaseViewComponent,
} from 'src/app/common/components/base-view.component';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { takeUntil } from 'rxjs';
import { ConfirmationService, MessageService } from 'primeng/api';
import { permissions } from '../../integration/datadelivery.permissions';

@Component({
  selector: 'app-clientapplication.details',
  templateUrl: './clientapplication.details.component.html',
  styleUrls: ['./clientapplication.details.component.css'],
})
export class ClientApplicationDetailsComponent
  extends BaseViewComponent
  implements OnInit
{
  @ViewChild('addclientauthForm') addclientauthForm!: NgForm;
  pageTitle = Components.AddClientApplication.label;
  public canAdd: boolean;
  isReadonly: boolean = true;
  showButtons: boolean = true;
  saveDialog = false;
  public submitted?: boolean;
  clientauth!: ClientApplication;
  responseClientAuth: ResponseClientApplication;
  titlestring = 'New Client Authentication';
  messageLabel = 'client authentication';

  initialVal() {
    this.clientauth = new ClientApplication();
  }
  constructor(
    router: Router,
    private route: ActivatedRoute,
    breadcrumbService: BreadcrumbService,
    confirmationService: ConfirmationService,
    messageService: MessageService,
    private clientApplicationService: ClientApplicationService,
    private realUserService: SecurityUserService
  ) {
    super(messageService, confirmationService, router, breadcrumbService);
  }

  getBreadCrums() {
    this.breadcrumbService.setItems(this.route, [
      {
        label: Components.ClientApplication.label,
        routerLink: Components.ClientApplication.path,
      },
      { label: Components.AddClientApplication.label },
    ]);
  }
  ngOnInit(): void {
    this.canAdd = this.realUserService.userHasPermission(
      permissions.datadelivery_client_authentication.create
    );
    this.initialVal();
    this.getBreadCrums();
    this.updateBreadcrumb(this.titlestring);
    this.pageTitle = this.titlestring;
    this.isNew = true;
    this.clientauth.id = -1;
    !this.canAdd ? (this.isReadonly = true) : (this.isReadonly = false);
    !this.canAdd ? (this.showButtons = false) : (this.showButtons = true);
  }

  onCancel() {
    this.submitted = false;
    this.router.navigate([Components.ClientApplication.path]);
  }
  onReset() {
    this.addclientauthForm.resetForm();
  }

  showDialog() {
    this.saveDialog = true;
  }
  hideDialog() {
    this.saveDialog = false;
  }

  onSubmit() {
    this.setSubmissionState(true);
    this.prepareClientAuthData();

    if (this.isNew) {
      this.addClient();
    }
  }

  prepareClientAuthData() {
    this.responseClientAuth = {
      name: this.clientauth.Name,
      organization_id: 0,
      client_authentication_id: -1,
    };
  }

  addClient() {
    this.clientApplicationService
      .addClientApplication(this.responseClientAuth)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (result) => this.handleNavigation(),
        error: (err) => this.handleError(err),
        complete: () => this.handleSuccess(),
      });
  }

  handleNavigation() {
    this.router.navigate([Components.ClientApplication.path]);
  }

  handleError(err: any) {
    this.showErrorMsg(
      `${err}`,
      Action.Add,
      `${this.messageLabel}`,
      `${this.clientauth.Name}`
    );
    this.resetFormAndState();
  }

  handleSuccess() {
    this.showSuccessMsg(
      Action.Add,
      `${this.messageLabel}`,
      `${this.clientauth.Name}`
    );
    this.resetFormAndState();
    this.isNew = false;
  }

  resetFormAndState() {
    this.submitted = false;
    this.loadingCount--;
    this.addclientauthForm.resetForm(this.addclientauthForm.value);
  }

  setSubmissionState(isSubmitting: boolean) {
    this.submitted = isSubmitting;
    this.loadingCount += isSubmitting ? 1 : -1;
  }
}
