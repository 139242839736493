import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-data-frame-footer-action',
  templateUrl: './data-frame-footer-action.component.html',
  styleUrls: ['./data-frame-footer-action.component.css'],
})
export class DataFrameFooterActionComponent {
  @Input() saveDisabled: boolean;
  @Input() resetDisabled: boolean;
  @Input() userCanManage: boolean;
  @Output() onCancel = new EventEmitter();
  @Output() onReset = new EventEmitter();
  @Output() onSave = new EventEmitter();

  cancelDataFrame() {
    this.onCancel.emit();
  }

  resetDataFrame() {
    this.onReset.emit();
  }

  saveDataFrame() {
    this.onSave.emit();
  }

}
