import { Component, Input, OnInit } from '@angular/core';
import { SECURITY_CONSTANTS } from '../models/security-constants';
import { PostOrgSwitchBehavior, SecurityAuthService } from '../services/security-auth.service';
import { SecurityUserService } from '../services/security-user.service';
import { ThemeService } from 'src/app/platform/services/theme.service';

@Component({
  selector: 'app-org-selector',
  template: `
    <div class="container">
      <p-dropdown class="left" [options]="availableOrgs" [(ngModel)]="selectedOrg" (ngModelChange)="onModelChange($event)"></p-dropdown>
      <input *ngIf="showFavoriteSelector" title="remember this organization" class="left-down" [disabled]="!isCheckboxEnabled" type="checkbox" name="favorite" (ngModelChange)="favoriteChanged($event)" [(ngModel)]="isFavorite" [ngModelOptions]="{standalone: true}">
    </div>
  `,
  styles: [`
    .container {
      clear:both;
    }
    .container .left {
      float:left;
    }
    .container .left-down {
      float:left;
      margin-top: 25px;
    }
  `]
})

export class OrgSelectorComponent implements OnInit {

  @Input() postSwitchBehavior: PostOrgSwitchBehavior = PostOrgSwitchBehavior.Reload;
  @Input() showFavoriteSelector = true;

  public availableOrgs: string[] = [];
  public selectedOrg?: string;
  private activeOrg?: string;

  public isFavorite: boolean = false;
  public isCheckboxEnabled: boolean;
  private favoriteOrg: string;
  private favoriteStorageKey: string;

  constructor(
    private _authService: SecurityAuthService,
    private _userService: SecurityUserService,
    private themeService: ThemeService) { }

  public ngOnInit(): void {
    this.themeService.initializeDefaultTheme();
    this._userService.userInfo.subscribe(userInfo => {
      const noneEntry = userInfo.activeOrg === SECURITY_CONSTANTS.no_org_scope ? [SECURITY_CONSTANTS.no_org_scope] : [];
      this.favoriteStorageKey = userInfo.email + SECURITY_CONSTANTS.favorite_org_local_storage_key_suffix;
      this.favoriteOrg = localStorage.getItem(this.favoriteStorageKey);
      const orderedOrgs = userInfo?.orgs?.sort((a, b) => (a > b ? 1 : -1)) ?? []
      this.availableOrgs = [...noneEntry, ...orderedOrgs];
      this.selectedOrg = this.activeOrg = userInfo.activeOrg;
      this.isFavorite = this.selectedOrg === this.favoriteOrg;
      this.isCheckboxEnabled = this.isOrgSelected();
    })
  }

  public onModelChange($event: any) {
    if (this.isOrgSelected()) {
      this.isFavorite = this.selectedOrg === this.favoriteOrg;
      this.isCheckboxEnabled = true;
      if (this.selectedOrg !== this.activeOrg) {
        this._authService.switchOrg(this.selectedOrg, this.postSwitchBehavior);
      }
    } else {
      this.isCheckboxEnabled = false;
    }
  }

  public favoriteChanged($event: any) {
    this.favoriteOrg = $event ? this.selectedOrg : null;
    localStorage.setItem(this.favoriteStorageKey, this.favoriteOrg);
  }

  private isOrgSelected(): boolean {
    return this.selectedOrg !== undefined && this.selectedOrg !== null && this.selectedOrg !== '' && this.selectedOrg !== SECURITY_CONSTANTS.no_org_scope;
  }
}
