<p-blockUI [blocked]="loadingCount > 0">
  <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<p-confirmDialog></p-confirmDialog>

<div *ngIf="aircraftModel" class="col-12 card p-3">
  <form #modelForm="ngForm" id="formAircraftModel" (ngSubmit)="onSubmit();" novalidate>
    <div class="card-header m-2">
      <h5>{{pageTitle}}</h5>
    </div>
    <div class="grid mt-2">
      <div class="col-12 lg:col-6 p-2">
        <div class="card mb-0">
          <h6>Details</h6>
          <div class="field">
            <div class="p-float-label">
              <p-dropdown id="drpOemNames" name="modelOEMID" #oemID="ngModel" [(ngModel)]="aircraftModel.airframer_id"
                [autoDisplayFirst]="false" [disabled]="isReadOnly" [options]="airframers" optionLabel="airframer_name"
                optionValue="airframer_id" [style]="{'width':'100%'}" [required]="true"></p-dropdown>
              <label for="drpOemNames">Airframer</label>
            </div>
            <div *ngIf="(oemID.touched || oemID.dirty) && oemID.hasError('required')" class="tdy-required">
              Airframer is required
            </div>
          </div>

          <div class="field">
            <div class="p-float-label">
              <input #model="ngModel" [(ngModel)]="aircraftModel.aircraftmodel_model" id="model" maxlength="32"
                name="model" pInputText style="width: 100%" type="text" required pattern=".*\S.*"
                [disabled]="isReadOnly" />
              <label for="model">Model</label>
            </div>
            <div *ngIf="(model.touched || model.dirty) && model.hasError('required')" class="tdy-required">
              Model is required
            </div>
            <div *ngIf="model.hasError('pattern')" class="tdy-required">
              Model name cannot be blank
            </div>
          </div>

          <div class="field">
            <div class="p-float-label">
              <input #subModel="ngModel" [(ngModel)]="aircraftModel.aircraftmodel_sub_model" id="subModel"
                maxlength="32" name="subModel" pInputText style="width: 100%" type="text" required pattern=".*\S.*"
                [disabled]="isReadOnly" />
              <label for="subModel">Sub-Model</label>
            </div>
            <div *ngIf="(subModel.touched || subModel.dirty) && subModel.hasError('required')" class="tdy-required">
              Sub-Model is required
            </div>
            <div *ngIf="subModel.hasError('pattern')" class="tdy-required">
              Sub-Model name cannot be blank
            </div>
          </div>

          <div class="field">
            <div class="p-float-label">
              <input #engineCount="ngModel" id="engineCount" name="engineCount" [(ngModel)]="aircraftModel.engine_count"
                pInputText min="1" max="10" step="1" required type="number"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57" style="width: 100%"
                [disabled]="isReadOnly" />
              <label for="engineCount">Engine Count</label>
            </div>
            <div *ngIf="(engineCount.touched || engineCount.dirty) && engineCount.hasError('required')"
              class="tdy-required">
              Engine Count is required
            </div>
            <div *ngIf="engineCount.hasError('min') ||engineCount.hasError('max')" class="tdy-required">
              Engine Count needs to be between 1 and 10
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="flex justify-content-end">
      <span class="p-input-icon-left">
        <button (click)="onCancel()" class="p-button-text" icon="pi pi-times" id="cancelButton" label="Cancel" pButton
          pRipple type="button"></button>
      </span>
      <span class="p-input-icon-left">
        <button *ngIf="!isReadOnly" (click)="onReset()" class="p-button-text" icon="pi pi-undo" id="resetButton"
          label="Reset" pButton [disabled]="!modelForm.dirty" pRipple type="button"></button>
      </span>

      <span class="p-input-icon-left pl-2">
        <button *ngIf="!isReadOnly" [disabled]="isNew? !modelForm.valid : !(modelForm.dirty && modelForm.valid)"
          icon="pi pi-check" id="okButton" label="Save" pButton pRipple type="submit"></button>
      </span>
    </div>
  </form>
</div>
