import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/common/services/breadcrumb.service";
import { MenuService } from "src/app/common/services/menu.service";
import { AcmsReportMapService } from "src/app/features/configuration/services/acms-report-map.service";
import { BaseCompareComponent } from "../../base/base-compare.component";
import { acmsReportMapCompareInput } from "src/app/features/configuration/components/dataframe-acms/input-model/acms-report-map-input";
import { Components } from '../../../../integration/configuration.components';


@Component({
    selector: 'app-acms-report-map-compare',
    templateUrl: '../../base/compare.component.html',
    providers: [{provide: acmsReportMapCompareInput  ,
      useFactory:(acmsReportMapService: AcmsReportMapService) => new acmsReportMapCompareInput(acmsReportMapService)
     ,
     deps:[AcmsReportMapService]
    }

     ]


  })

  export class AcmsReportMapCompareComponent extends BaseCompareComponent  implements OnInit{

    constructor(
        private route: ActivatedRoute,
        router: Router,
        messageService: MessageService,
        confirmationService: ConfirmationService,
        breadcrumbService: BreadcrumbService,
        acmsReportInput: acmsReportMapCompareInput

    ){

        super(messageService,confirmationService,router,breadcrumbService,acmsReportInput);


        breadcrumbService.setItems(route,[
       {label : Components.AcmsReportMap.label ,routerLink: Components.AcmsReportMap.path},
       {label : `(${this.route.snapshot.queryParamMap.get('Name')})`,routerLink: `${Components.AcmsReportMapVersions.path}` ,queryParams: {"a": this.route.snapshot.queryParamMap.get('b'), "Name":this.route.snapshot.queryParamMap.get('Name')}}
       ]);
       if(breadcrumbService.getItems().length == 3){
        breadcrumbService.popItem();
       }



    }

    ngOnInit(){
         this.load_Compare(+this.route.snapshot.queryParamMap.get('a')
        ,this.route.snapshot.queryParamMap.get('Name')
        ,this.route.snapshot.queryParamMap.get('versionList')
        )

    }
  }
