import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { sarMapsModel } from 'src/app/features/configuration/models/sarMaps';
import { SARMapsService } from 'src/app/features/configuration/services/sar-map.service';
import { Action, BaseViewComponent } from 'src/app/common/components/base-view.component';
import { permissions } from '../../integration/configuration.permissions';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { CommonService } from 'src/app/common/services/common.service';
import {Components} from '../../integration/configuration.components';

@Component({
  selector: 'app-sar-maps',
  templateUrl: './sar-maps.component.html'
})
export class SarMapsComponent extends BaseViewComponent implements OnInit {

  pageTitle = Components.SarMaps.label;
  sarMapsList: sarMapsModel[];
  public canAdd: boolean;
  public canDelete: boolean;
  public canEdit: boolean;
  public canView: boolean;
  messageLabel = "sar-map";

  constructor(
    messageService: MessageService,
    confirmationService: ConfirmationService,    
    router: Router,
    breadcrumbService: BreadcrumbService,
    private sarMapsService: SARMapsService, 
    private route: ActivatedRoute,
    private commonService: CommonService,
    private realUserService: SecurityUserService
  ) {
    super(messageService, confirmationService, router, breadcrumbService);
    this.breadcrumbService.setItems(route, [
      { label: Components.SarMaps.label, routerLink: Components.SarMaps.path }
    ]);
  }

  ngOnInit(): void {
    //this.loggedInUser = this.userService.getUserFromSession();
    this.breadcrumbService.setItems(this.route, [ { label: Components.SarMaps.label } ]);
    
      this.canAdd = this.realUserService.userHasPermission(permissions.sar_maps.create);    
      this.canView = this.realUserService.userHasPermission(permissions.sar_maps.view);    
      this.canDelete = this.realUserService.userHasPermission(permissions.sar_maps.delete);
      this.canEdit = this.realUserService.userHasPermission(permissions.sar_maps.manage);
      if(this.canView){
        this.loadTableData();
      }
    
  }

  loadTableData(){
    this.loadingCount++;
    this.commonService.getSARMapsList().pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next:(data) => {
        this.sarMapsList = data;        
      },
      error:(err) => {
        this.showErrorMsg(err, Action.Get, `${this.messageLabel}`);
      },
      complete: () => this.loadingCount--
    })
  }

  onClickAdd(){
    if (!this.canAdd) {
      this.showErrorMsg('User is not authorized to add SAR Map.', Action.Add, `${this.messageLabel}`)
    } else {
      this.router.navigate([Components.SarMapsAdd.path]).then();
    }
  }

  onRowSelect(event){
    this.router.navigate([Components.SarMapsVersions.path], {
      queryParams: {
        sarMapID: event.data.sarmap_id
         
      }
    }).then()
  }

  onDeleteSarMaps(event){
    if(this.userCanDelete){
      this.showErrorMsg('User is not authorized to Delete Sar Map.', Action.Delete, `${this.messageLabel}`)
    } else {
      this.confirmationService.confirm({
        message:'Are you sure that you want to delete the selected Sar Map?',
        accept: () => {
          this.sarMapsService.deleteSarmap(event.sarmap_id).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
            next:(data) => {
              this.showSuccessMsg(Action.Delete, `${this.messageLabel}`, `${event.name}`);
              this.loadTableData();        
            },
            error:(error) => {
              this.showErrorMsg(`${error}`, Action.Delete, `${this.messageLabel}`, `${event.name}`);
            },
          })
        }
      })
    }
  }

  onManageRecord(event){
      this.router.navigate([Components.SarMapsDetails.path], {
        queryParams: {
          sarMapID: event.sarmap_id 
        }
      }).then()
  }

}
