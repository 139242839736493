import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { forkJoin, takeUntil } from 'rxjs';
import {
  Action,
  BaseViewComponent,
} from 'src/app/common/components/base-view.component';
import { CommonService } from 'src/app/common/services/common.service';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { BreadcrumbService } from '../../../../common/services/breadcrumb.service';
import { AcmsReportMapService } from '../../../../features/configuration/services/acms-report-map.service';
import { Components } from '../../integration/datadelivery.components';
import { permissions } from '../../integration/datadelivery.permissions';
import {
  ACMS,
  ACMSType,
  FileType,
  GetFileType,
  ResponseACMS,
  fileType,
  getRegex,
  jsonValues,
} from '../../models/ACMS';
import { ACMSRedactionServive } from '../../services/acmsRedaction.service';
@Component({
  selector: 'app-acmsreport',
  templateUrl: './acmsreport-add.component.html',
  styleUrls: ['./acmsreport-add.component.css'],
})
export class AcmsreportAddComponent
  extends BaseViewComponent
  implements OnInit
{
  public pageTitle = Components.ACMSReportAddRedactions.label;
  public acmsreport!: ACMS;
  public acmsReportlist: ACMSType[] = [];
  public acmsType: ACMSType[] = [];
  public fileType: fileType[] = [];
  public jsonregex: getRegex;
  public isReadonly: boolean = true;
  public uniqueError: boolean = false;
  public avialableAcms: ACMSType[] = [];
  public selectedMapIDs: string[] = [];
  public picklistChange = false;
  public isButtonDisabled: boolean = true;
  public jsonParameters: jsonValues;
  public showButtons: boolean = true;
  public FileType = FileType;

  private getSelectedAcms: object;
  public canAdd: boolean;
  public canDelete: boolean;
  public canEdit: boolean;
  public acmsreports: ACMS[] = [];
  private selectedAcms: ACMSType[] = [];
  private editselectedMapIDs: string[] = [];
  private initialRecordersCount: number;
  private saveButtonDisabled: boolean;
  private selectAcmsValue: number;
  private responseACMS: ResponseACMS;
  private isClone: number;
  private messageLabel = 'ACMS report redaction';
  private newLabel = 'Add ACMS Report Redaction';
  organization_id: number;

  constructor(
    router: Router,
    private route: ActivatedRoute,
    breadcrumbService: BreadcrumbService,
    confirmationService: ConfirmationService,
    messageService: MessageService,
    private acmsReportMapService: AcmsReportMapService,
    private acmsredaction: ACMSRedactionServive,
    private commonService: CommonService,
    private realUserService: SecurityUserService,
    private ngZone: NgZone
  ) {
    super(messageService, confirmationService, router, breadcrumbService);
    this.getBreadCrums();
  }

  intialVal() {
    this.pageTitle = this.newLabel;
    this.updateBreadcrumb(this.pageTitle);
    this.canEdit = true;
    this.canDelete = true;
    this.acmsreport = new ACMS();
    this.jsonParameters = new jsonValues();
    this.jsonregex = new getRegex();
    this.acmsreport.redaction_id = -1;
    this.isNew = true;
  }

  getBreadCrums() {
    this.breadcrumbService.setItems(this.route, [
      {
        label: Components.ACMSReportRedactions.label,
        routerLink: Components.ACMSReportRedactions.path,
      },
      {
        label:
          Components.ACMSReportAddRedactions.label +
          ` (${this.route.snapshot.queryParamMap.get('Name')})`,
      },
    ]);
  }

  ngOnInit() {
    this.initializePermissions();
    this.getBreadCrums();
    const redactionId = +this.route.snapshot.queryParamMap.get('redactionId');
    const isClone = !!this.route.snapshot.queryParamMap.get('clone');

    if (redactionId) {
      this.handleRedactionId(redactionId, isClone);
    } else {
      this.handleNoRedactionId();
    }

    this.fileType = GetFileType;
    this.jsonregex = new getRegex();
  }

  initializePermissions() {
    this.canAdd = this.realUserService.userHasPermission(
      permissions.datadelivery_acms_redaction.create
    );
    this.canDelete = this.realUserService.userHasPermission(
      permissions.datadelivery_acms_redaction.delete
    );
    this.canEdit = this.realUserService.userHasPermission(
      permissions.datadelivery_acms_redaction.manage
    );
  }

  handleRedactionId(redactionId: number, isClone: boolean) {
    if (isClone) {
      this.isNew = true;
      this.isReadonly = !this.canAdd;
      this.showButtons = this.canAdd;
      this.pageTitle = 'Clone ACMS Report Redaction';
      this.updateBreadcrumb(
        `${this.pageTitle} (${this.route.snapshot.queryParamMap.get('Name')})`
      );
    } else {
      this.isNew = false;
      this.isReadonly = !this.canEdit;
      this.showButtons = this.canEdit;
    }
    this.loadAcmsDetails(redactionId, isClone);
  }

  handleNoRedactionId() {
    this.isReadonly = !this.canAdd;
    this.showButtons = this.canAdd;
    this.loadAcmsMap();
    this.intialVal();
  }
  onCancel() {
    this.router.navigate([Components.ACMSReportRedactions.path]);
  }
  onChange(event: any) {
    this.selectAcmsValue = event.value;
    this.loadAcmsConfigDetails(event.value);
  }

  loadAcmsMap() {
    this.loadingCount++;
    this.saveButtonDisabled = true;
    forkJoin({
      avialableAcmsMap: this.commonService.getAcmsReportMapData(),
    })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results) => {
          //sorting to show in alphabetical order
          results.avialableAcmsMap.forEach((ele) => {
            this.acmsType.push({
              acms_id: ele.acmsreportmap_id,
              acms_name: ele.name,
            });
          });
        },
        error: (error) => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        },
      });
  }

  loadAcmsConfigDetails(mapId?: number) {
    this.startLoading();
    this.fetchAcmsConfigDetails(mapId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results) => this.processAcmsConfigDetails(results),
        error: (error) => this.handleError(error),
        complete: () => this.completeLoading(),
      });
  }

  startLoading() {
    this.loadingCount++;
    this.saveButtonDisabled = true;
    this.acmsReportlist = [];
    this.avialableAcms = [];
  }

  fetchAcmsConfigDetails(mapId?: number) {
    return forkJoin({
      avialableAcms: this.commonService.getACMSMapReports(mapId),
    });
  }

  processAcmsConfigDetails(results: any) {
    // Sorting to show in alphabetical order
    results.avialableAcms.forEach((ele) => {
      this.acmsReportlist.push({ acms_name: ele });
    });
    this.acmsReportlist.forEach((ele) => {
      this.selectedAcms.push({ acms_name: ele.acms_name });
    });

    this.avialableAcms = [];

    this.selectedAcms.forEach((ele) => {
      this.selectedMapIDs.push(ele.acms_name);
    });
    this.filterAcmsMethods();

    this.updateInitialsRecordersCount();
    this.initialRecordersCount = this.selectedAcms.length;
    this.selectedMapIDs = [];
    this.acmsReportlist.sort((a, b) => {
      return a.acms_name.localeCompare(b.acms_name);
    });
  }

  updateInitialsRecordersCount() {
    this.acmsReportlist
      ? (this.initialRecordersCount = this.acmsReportlist.length)
      : (this.initialRecordersCount = 0);
  }

  filterAcmsMethods() {
    this.avialableAcms = this.avialableAcms.filter(
      (p) => this.selectedAcms.findIndex((i) => i.acms_id == p.acms_id) == -1
    );
  }

  handleError(error: any) {
    this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}`);
    this.loadingCount--;
  }

  completeLoading() {
    this.loadingCount--;
  }

  loadAcmsDetails(redaction_id: number, clone: boolean) {
    this.loadingCount++;
    forkJoin({
      getacmsdetails: this.acmsredaction.getACMSRedaction_by_id(redaction_id),
      avialableAcmsMap: this.commonService.getAcmsReportMapData(),
    })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results) => {
          results.avialableAcmsMap.forEach((ele) => {
            this.acmsType.push({
              acms_id: ele.acmsreportmap_id,
              acms_name: ele.name,
            });
          });
          this.acmsreport = results.getacmsdetails;
          this.jsonParameters = JSON.parse(
            this.acmsreport.defaultparametervaluesconfig
          );
          if (this.jsonParameters.filetype_id == FileType.Teledyne) {
            const getMapJson = JSON.parse(this.acmsreport.mapjson);
            this.editselectedMapIDs = JSON.parse(getMapJson.acmsreports);
            this.jsonParameters = JSON.parse(
              this.acmsreport.defaultparametervaluesconfig
            );
            this.onEditloadAcmsDetails(this.acmsreport.acmsreportmap_id);
          } else {
            const getMapJson = JSON.parse(this.acmsreport.mapjson);
            this.jsonregex.regex = getMapJson.regex;
          }
          if (clone) {
            this.acmsreport.name = '';
          }
        },
        error: (error) => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        },
      });
  }

  onEditloadAcmsDetails(mapId?: number) {
    this.loadingCount++;
    this.saveButtonDisabled = true;
    forkJoin({
      avialableAcms: this.commonService.getACMSMapReports(mapId),
    })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results) => {
          //acmsReportlist source
          this.avialableAcms = [];
          results.avialableAcms.forEach((ele) => {
            if (this.editselectedMapIDs.find((a) => a == ele)) {
              this.avialableAcms.push({ acms_name: ele });
              this.selectedAcms.push({ acms_name: ele });
            }
            this.acmsReportlist.push({ acms_name: ele });
          });

          this.selectedAcms.forEach((ele) => {
            this.selectedMapIDs.push(ele.acms_name);
          });

          //Filter the source Regex List
          if (this.acmsReportlist && this.avialableAcms) {
            this.acmsReportlist = this.acmsReportlist.filter(
              (p) =>
                this.selectedAcms.findIndex(
                  (i) => i.acms_name == p.acms_name
                ) == -1
            );
          }

          this.updateInitialsRecordersCount();
          this.initialRecordersCount = this.selectedAcms.length;
          this.sortAcmsReports();
        },
        error: (error) => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        },
      });
  }

  sortAcmsReports() {
    this.acmsReportlist.sort((a, b) => {
      return a.acms_name.localeCompare(b.acms_name);
    });
    this.avialableAcms.sort((a, b) => {
      return a.acms_name.localeCompare(b.acms_name);
    });
  }

  onReset() {
    this.picklistChange = false;
    this.selectedAcms = [];
    this.selectedMapIDs = [];
    this.acmsReportlist = [];
    this.acmsType = [];
    this.avialableAcms = [];
    this.ngOnInit();
    this.isButtonDisabled = true;
  }
  onAirlineChange(e: any) {
    this.jsonParameters.filetype_id = e.value;
    if (e.value === FileType.Teledyne) {
      this.loadAcmsConfigDetails(this.acmsType[0]?.acms_id);
      this.selectAcmsValue = this.acmsType[0]?.acms_id;
      this.acmsreport.acmsreportmap_id = this.acmsType[0]?.acms_id;
    }else{
      this.acmsreport.acmsreportmap_id = null
      this.selectAcmsValue = null
    }
  }

  onSubmit() {
    this.loadingCount++;
    if (this.jsonParameters.filetype_id == FileType.Teledyne) {
      this.getSelectedAcms = {
        acmsreports: JSON.stringify(this.selectedMapIDs),
      };
    } else {
      this.getSelectedAcms = { regex: this.jsonregex.regex };
    }
    this.updateResponseACMS();
    if (this.isNew) {
      this.acmsredaction
        .addACMSRedaction(this.responseACMS)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (result) => {
            this.acmsreport.redaction_id = result;
            // Update query param

            this.router.navigate([Components.ACMSReportRedactions.path]);
            this.router
              .navigate([], {
                relativeTo: this.route,
                queryParams: {
                  redactionId: this.acmsreport.redaction_id,
                  Name: this.acmsreport.name,
                },
                queryParamsHandling: 'merge',
              })
              .then();
            this.updateBreadcrumb(
              Components.ACMSReportAddRedactions.label +
                ' (' +
                this.acmsreport.name +
                ')'
            );
            this.pageTitle = Components.ACMSReportAddRedactions.label;
            this.picklistChange = false;
          },
          error: (err) => {
            this.loadingCount--;
            this.isButtonDisabled = true;
            this.showErrorMsg(`${err}`, Action.Add, `${this.messageLabel}s`);
          },
          complete: () => {
            this.showSuccessMsg(
              Action.Add,
              `${this.messageLabel}`,
              `${this.acmsreport.name}`
            );
            this.isNew = false;
            this.isButtonDisabled = true;
            this.saveButtonDisabled = true;
            this.loadingCount--;
          },
        });
    } else {
      this.acmsredaction
        .updateACMSRedaction(this.responseACMS)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.updateBreadcrumb(
              Components.ACMSReportAddRedactions.label +
                ' (' +
                this.acmsreport.name +
                ')'
            );
            this.picklistChange = false;
          },
          error: (err) => {
            this.showErrorMsg(`${err}`, Action.Update, `${this.messageLabel}`);
            this.isButtonDisabled = true;
            this.loadingCount--;
          },
          complete: () => {
            this.showSuccessMsg(
              Action.Update,
              `${this.messageLabel}`,
              `${this.acmsreport.name}`
            );
            this.isButtonDisabled = true;
            this.loadingCount--;
          },
        });
    }
  }

  updateResponseACMS() {
    this.responseACMS = {
      redaction_id: this.acmsreport.redaction_id,
      redaction_name: this.acmsreport.name,
      redaction_description: this.acmsreport.description,
      redaction_textualoutput:
        this.acmsreport.textualoutput == undefined
          ? false
          : this.acmsreport.textualoutput,
      redaction_acmsreportmap_id:
        (this.selectAcmsValue == undefined
          ? this.acmsreport.acmsreportmap_id
          : this.selectAcmsValue) ?? null,
      redaction_defaultparametervaluesconfig: JSON.stringify(
        this.jsonParameters
      ),
      redaction_mapjson: JSON.stringify(this.getSelectedAcms),
      redaction_redactiontype_id: 1,
      organization_id: 0,
      enhanced: false,
    };
  }

  onMoveToTarget(event) {
    event.items.forEach((ele) => {
      this.selectedMapIDs.push(ele.acms_name);
    });
    this.isButtonDisabled = false;
    this.selectedMapIDs = [...new Set(this.selectedMapIDs)]; // remove the duplicate entries
    this.picklistChange = true;
  }

  onMoveAllToSource(event) {
    event.items.forEach((ele) => {
      const index = this.selectedMapIDs.indexOf(ele.acms_name);
      if (index > -1) {
        this.selectedMapIDs.splice(index, 1);
      }
    });
    this.picklistChange = true;
    this.isButtonDisabled = false;
  }

  onMoveAllToTarget(event) {
    this.picklistChange = true;
    this.isButtonDisabled = false;
    event.items.forEach((ele) => {
      this.selectedMapIDs.push(ele.acms_name);
    });
  }

  onMoveToSource(event) {
    this.picklistChange = true;
    this.isButtonDisabled = false;
    event.items.forEach((ele) => {
      const index = this.selectedMapIDs.indexOf(ele.acms_name);
      if (index > -1) {
        this.selectedMapIDs.splice(index, 1);
      }
    });
  }
}
