import { Component, OnInit, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { FormGroup, Validators} from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { RangeTextPair, TopLevel, Parameter767, Frame, parameters767, PolyCoeff, IntegerRealTable, RealRealTable } from 'src/app/features/configuration/models/dataframe717-767';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { Action, BaseViewComponent } from 'src/app/common/components/base-view.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DataFrameService } from 'src/app/features/configuration/services/data-frame.service';
import { Components } from '../../../integration/configuration.components';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { permissions } from '../../../integration/configuration.permissions';
import { takeUntil } from 'rxjs';
import { FormInputBase} from "../../dataframe-acms/input-model/input/form-input-base";
import { FormTextbox} from "../../dataframe-acms/input-model/input/form-textbox";
import { ValidationEnum } from '../../dataframe-acms/dataframe/shared/enums/validationEnum';
import { ValidationHelper } from '../../dataframe-acms/dataframe/shared/helpers/validation.helper';


@Component({
  selector: 'app-addparameterDXS',
  templateUrl: 'parameterDXS.component.html'
})
export class ParameterDXSComponent extends BaseViewComponent implements OnInit, AfterViewChecked {
  title: string = "";
  myForm: FormInputBase<string | boolean>[] = [];
  additionalData: any = {};
  pagelbl = ""
  pageTitle = this.pagelbl;
  formdata: any;
  message: any;
  isActive = false;
  order: any;
  tableData: RangeTextPair[] = [];
  EuData: PolyCoeff['Coeff_Pair'][] = [];
  EuInteger: IntegerRealTable['Integer_Real_Pair'][] = [];
  EuReal: RealRealTable['Real_Real_Pair'][] = [];
  enumType = [ "ASCII","BCD","Floating Point", "Signed Binary", "Unsigned Binary"];
  euType = ["Integer-Real Table","Polynomial", "Real-Real Table"]
  disCols: any;
  EuCols: any;
  isLoading: boolean = true;
  EuIntCols: any;
  EuRealCols: any;
  rowIndex: any;
  dataparameter: Parameter767[] = [];
  frameIdentification: Frame[] = [];
  jsonValue!: TopLevel;
  isAdd: boolean = false;
  isEdit: boolean = false;
  isClone: boolean = false;
  selectedMnemonic: any;
  timeout: any = null;
  Mnemonic = "";
  mnemonicBool = false;
  selectedValue: any;
  selectedName: any;
  numerator: any;
  demoniator: any;
  numberframe: any;
  frameId: number;
  versionId: number;
  isNull = false;
  isEmpty = false;
  getNameArray: any;
  bitLength = null;
  copyBitLength = null;
  minIndex: any[] = [];
  textIndex = null;
  isDuplicate = false;
  rowExceedBool = false;
  cloneIndex: number;
  frameRate: any;
  lblHz = " Hz";
  selectedEuValue = '';
  polyData: { "index": number, "text": number }[] = [];
  intData: { "index": number, "text": number }[] = [];
  realData: { "index": number, "text": number }[] = [];
  emptyVal = false;
  polyArray: any[] = [];
  intArray: any[] = [];
  realArray: any[] = [];
  polyObj: { Coeff_Pair: { index: number | undefined; "#text": number | undefined; }; };
  intObj: { Integer_Real_Pair: { index: number | undefined; "#text": number | undefined; }; };
  realObj: { Real_Real_Pair: { index: number | undefined; "#text": number | undefined; }; };
  indexValue = 0;
  discMin: any[] = [];
  FrameIDs: number[] = [];
  jsonArray: any[] = [];
  updatedMnemonics: any[] = [];
  messageLabel = 'Data Frame DXS';
  tableUpdated = false;
  selectedFrameID = null;
  busList = [];
  fullBusList = [];
  sdiList = []
  labelList = [];
  dynamicForm: FormGroup;

  getFormsBlock(): any[] {
    return [
      new FormTextbox({
        key: 'Mnemonic_Code',
        placeholder: 'Mnemonic Code',
        type: 'text',
        errorMessages: {
          [ValidationEnum.Required]: 'Mnemonic is required.',
          [ValidationEnum.Exists]: 'Mnemonic already exist.'
        },
        maxLength: 20,
        colSize: 4,
        newRow: true,
        rowSize: 3,
        disabled: !this.userCanManage,
        validators: [Validators.required, ValidationHelper.onSearchMnemonic(this.dataparameter)]
      }),
      new FormTextbox({
        key: 'Name',
        placeholder: 'Name',
        type: 'text',
        maxLength: 64,
        colSize: 4,
        errorMessages: {
          [ValidationEnum.Required]: 'Name is required',
          [ValidationEnum.Exists]: 'Name already exists'
        },
        disabled: !this.userCanManage,
        validators: [Validators.required, ValidationHelper.onSearchName(this.getNameArray)]
      }),
      new FormTextbox({
        key: 'Description',
        placeholder: 'Description',
        type: 'text',
        colSize: 4,
        disabled: !this.userCanManage
      }),
    ]
  }

  constructor (
    router: Router,
    breadcrumbService: BreadcrumbService,
    confirmationService: ConfirmationService,
    messageService: MessageService,
    private route: ActivatedRoute,
    private dataFrameService: DataFrameService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private realUserService: SecurityUserService
  ) {
    super(messageService, confirmationService, router, breadcrumbService);
  }

  getBreadCrumbs() {
    this.breadcrumbService.setItems(this.route, [{ label: Components.Dataframe.label, routerLink: Components.Dataframe.path },
      {
        label: `Versions (${this.route.snapshot.queryParamMap.get('Name')})`, routerLink: Components.DataframeVersions.path, queryParams:
          {
            a: this.route.snapshot.queryParamMap.get('frame'),
            Name: this.route.snapshot.queryParamMap.get('Name')
          }
      }, {
        label: this.route.snapshot.queryParamMap.get('Name') + ` (Version ${this.route.snapshot.queryParamMap.get('version_index')})`,
        routerLink: Components.ListView767.path, queryParams: {
          Name: this.route.snapshot.queryParamMap.get('Name'),
          frame: this.route.snapshot.queryParamMap.get('frame'),
          version: this.route.snapshot.queryParamMap.get('version'),
          version_index: this.route.snapshot.queryParamMap.get('version_index')
        }
      },
      { label: this.pagelbl }])
    this.frameId = parseInt(this.route.snapshot.queryParamMap.get('frame'));
    this.versionId = parseInt(this.route.snapshot.queryParamMap.get('version'));
  }

  ngOnInit() {
    this.isAdd = this.route.snapshot.queryParamMap.get('add') === '1' ? true : false;
    this.isEdit = this.route.snapshot.queryParamMap.get('editName') ? true : false;
    this.isClone = this.route.snapshot.queryParamMap.get('clone') === '1' ? true : false;
    this.setTitle();
    this.getBreadCrumbs();
    this.userCanManage = this.realUserService.userHasPermission(permissions.dataframes.data_frame_manage);
    this.userCanView = this.realUserService.userHasPermission(permissions.dataframes.data_frame_view);
    this.formdata = {};
    this.disCols = [
      { field: 'text', header: 'Discrete Text' },
      { field: 'min', header: 'Discrete Min' },
      { field: 'max', header: 'Discrete Max' },

    ];
    this.EuCols = [
      { field: 'coeff_index', header: 'Coefficient Index' },
      { field: 'coeff_value', header: 'Coefficient Value' },
    ]
    this.EuIntCols = [
      { field: 'int_index', header: 'Int-Real Pair Index' },
      { field: 'int_value', header: 'Int-Real Pair Value' },
    ]
    this.EuRealCols = [
      { field: 'real_index', header: 'Real-Real Pair Index' },
      { field: 'real_value', header: 'Real-Real Pair Value' },
    ]
    this.order = {};
    this.formdata = {};
    this.getDataFrame(this.frameId, this.versionId);
    this.myForm = this.getFormsBlock();
    const checkEU = this.order.Range?.Conversion_Step.hasOwnProperty("Poly_Coeff");
    this.EuData = checkEU === true ? this.polyData : [];
    const checkEUInt = this.order.Range?.Conversion_Step.hasOwnProperty("Integer_Real_Table");
    this.EuInteger = checkEUInt === true ? this.intData : [];
    const checkEUReal = this.order.Range?.Conversion_Step.hasOwnProperty("Real_Real_Table");
    this.EuReal = checkEUReal === true ? this.realData : [];
    this.isLoading = false;
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  setTitle() {
    if (this.isAdd) this.title = "Add Parameter Details";
    if (this.isEdit) this.title = "Edit Parameter Details";
    if (this.isClone) this.title = "Clone Parameter Details";
    this.pagelbl = this.title;
  }

  displayPolyValue() {
    if (this.formdata.Schema767.Range.hasOwnProperty('Conversion_Step') &&
      (this.formdata.Schema767.Range.Conversion_Step.hasOwnProperty('Poly_Coeff'))) {

      let polyCoeffValue = this.formdata.Schema767.Range.Conversion_Step.Poly_Coeff;
      polyCoeffValue?.forEach((element: any) => {

        let val = (element.Coeff_Pair)
        this.polyData.push({
          "index": val["index"],
          "text": val["#text"]
        })
      });
      return this.polyData;
    }
  }
  displayIntValue() {
    if (this.formdata.Schema767.Range.hasOwnProperty('Conversion_Step') &&
      (this.formdata.Schema767.Range.Conversion_Step.hasOwnProperty('Integer_Real_Table'))) {
      let intValue = this.formdata.Schema767.Range.Conversion_Step.Integer_Real_Table;
      intValue.forEach((element: any) => {
        let val = element.Integer_Real_Pair
        this.intData.push({
          "index": val["index"],
          "text": val["#text"]
        })
      });
      return this.intData;
    }
  }

  displayRealValue() {
    if (this.formdata.Schema767.Range.hasOwnProperty('Conversion_Step') &&
      (this.formdata.Schema767.Range.Conversion_Step.hasOwnProperty('Real_Real_Table'))) {
      let realValue = this.formdata.Schema767.Range.Conversion_Step.Real_Real_Table;
      realValue.forEach((element: any) => {
        let val = { "index": element.Real_Real_Pair["index"],
          "text": element.Real_Real_Pair["#text"]}
        this.realData.push(val)
      });
      return this.realData;
    }
  }

  onKeySearch(event: any) {
    return (event.charCode != 8 && event.charCode == 0  && event.charCode != 109  && event.charCode != 69  || (event.charCode >= 48 && event.charCode <= 57 || event.charCode == 190));
  }
  isNumber(val): boolean { return typeof val === 'number'; }
  isString(val): boolean { return typeof val === 'string'; }
  isInteger(val):boolean {return Number.isInteger(val)}

  onChangeFrameId() {
    const frameDetails = this.frameIdentification.filter((fra) => {
      if (fra.Frame_ID === this.formdata.Frame_ID) {
        return fra
      }
    })
    this.formdata.Frame = Object.assign({}, ...frameDetails);
    this.formdata.Name = "";
    this.formdata.Frame_Number = null;
    this.formdata.Frame_Title = this.formdata.Frame.Frame_Title;
    if (this.formdata.Frame.Recording_Rate.Numerator?.toString() != undefined) { this.frameRate = this.formdata.Frame.Recording_Rate.Numerator }
    if (this.formdata.Frame.Recording_Rate.Denominator?.toString() != undefined) { this.frameRate += "/" + this.formdata.Frame.Recording_Rate.Denominator }
    this.frameRate += this.lblHz;
  }

  
  onChange(event: any) {
    this.tableData = [];
    const found = this.formdata.Schema767.Range.hasOwnProperty("Text_Conversion");
    if (event.value === 'Unsigned Binary' && found === true) {
      this.tableData = this.formdata.Schema767.Range.Text_Conversion.Range_Text_Pair;
    }
    this.selectedValue = event.value;

  }
  onChangeEU(event: any) {
    this.selectedEuValue = event.value;
    this.EuData = [];
    this.EuInteger = [];
    this.EuReal =[];
    this.polyArray = [];
    this.intArray = [];
    this.realArray = [];
    if (this.selectedValue == 'Polynomial') {
      this.displayPolyValue()
    } else if (this.selectedValue == 'Integer-Real Table') {
      this.displayIntValue()
    } else if (this.selectedValue == 'Real-Real Table') {
      this.displayRealValue()
    }
  }

  addEUParameters() {
    let length = 0;
    if (this.EuData == undefined) {
      length = 0
    }
    else {
      if (this.EuData.length >= 0) {
        length = this.EuData.length;
      }
    }
    let neweu = {

      index: length,
      text: length
    }
    if (this.EuData != undefined) {
      this.EuData.push(neweu)
    }
    else {
      this.EuData = [];
      this.EuData.push(neweu)
    }
    this.tableUpdated = true;
  }
  addEUInt() {
    let length = 0;
    if (this.EuInteger == undefined) {
      length = 0
    }
    else {
      if (this.EuInteger.length >= 0) {
        length = this.EuInteger.length;
      }
    }
    let neweu = {

      index: length,
      text: length
    }
    if (this.EuInteger != undefined) {
      this.EuInteger.push(neweu)
    }
    else {
      this.EuInteger = [];
      this.EuInteger.push(neweu)
    }
    this.tableUpdated = true;
  }
  addEUReal() {
    let length = 0;
    if (this.EuReal == undefined) {
      length = 0
    }
    else {
      if (this.EuReal.length >= 0) {
        length = this.EuReal.length;
      }
    }
    let neweu = {

      index: length,
      text: length
    }
    if (this.EuReal != undefined) {
      this.EuReal.push(neweu)
    }
    else {
      this.EuReal = [];
      this.EuReal.push(neweu)
    }
    this.tableUpdated = true;
  }
  OnNameChange(event: any) {
    this.selectedName = event.value.Name;
    for (let element of this.frameIdentification) {
      if (element.Parameter_List.Name_Ref.includes(event.value.Name) === true) {
        this.order = element;
        this.numerator = element.Recording_Rate.Numerator;
        this.demoniator = element.Recording_Rate.Denominator;
        this.numberframe = element.Parameter_List.Name_Ref.indexOf(event.value.Name) + 1;
      }
    }

  }
  
   getDataFrame(frameId: number, versionId: number) {
    this.loadingCount++;
    this.dataFrameService.IdentifyDataFrame(frameId, versionId).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (data) => {
        let response = JSON.parse(data.content);
        this.getListView(response)
      },
      error: (err) => {
        this.showErrorMsg(err, Action.Get, `${this.messageLabel}`);
        this.loadingCount--;
      },
      complete: () => this.loadingCount--
    })
  }

  getListView(response) {
    this.initialValues();
    let updatedJSON = response;
    this.jsonValue = response;
    this.labelList = updatedJSON.FREDFile.FRED767.LabelList;
    this.fullBusList = updatedJSON.FREDFile.FRED767.BusList;

    for (var key in updatedJSON.FREDFile.FRED767.Frame) {
      for (var key1 in updatedJSON.FREDFile.FRED767.Frame[key]) {
        this.frameIdentification.push(updatedJSON.FREDFile.FRED767.Frame[key][key1]);
      }
    }
    this.frameIdentification.map((namelist) => {
      namelist.Parameter_List.Name_Ref.map((name) => {
        this.getNameArray.push(name);
      })
    })

    for (var key in updatedJSON.FREDFile.FRED767.Parameter767) {
      for (var key1 in updatedJSON.FREDFile.FRED767.Parameter767[key]) {
        this.dataparameter.push(updatedJSON.FREDFile.FRED767.Parameter767[key][key1]);
      }
    }

    let updatedMnemonics = JSON.parse(sessionStorage.getItem('Mnemonics'));
    for (var key in updatedMnemonics) {
      for (var key1 in updatedMnemonics[key]) {
        for (var key2 in updatedMnemonics[key][key1]) {
          if (key1 === "Edit") {
            this.dataparameter[key2] = updatedMnemonics[key][key1][key2].Schema767;
          }
          if (key1 === "Clone") {
            this.dataparameter.push(updatedMnemonics[key][key1][key2].Schema767);
            this.frameIdentification.filter((value) => {
              if (value.Frame_ID === updatedMnemonics[key][key1][key2].Frame.Frame_ID) {
                value.Parameter_List.Name_Ref.push(updatedMnemonics[key][key1][key2].Schema767.Name)
              }
            });
          }
          if (key1 === "Add") {
            this.dataparameter.push(updatedMnemonics[key][key1][key2].Schema767);
            this.frameIdentification.filter((value) => {
              if (value.Frame_ID === updatedMnemonics[key][key1][key2].Frame.Frame_ID) {
                value.Parameter_List.Name_Ref.push(updatedMnemonics[key][key1][key2].Schema767.Name)
              }
            });
          }
          this.updatedMnemonics.push({ [key1]: { [key2]: updatedMnemonics[key][key1][key2] } });
        }
      }
    }

    this.dataparameter.forEach((val, index) => {
      for (let value of this.frameIdentification) {
        if (value.Parameter_List.Name_Ref.includes(val.Name) === true) {
          var obj = {
            Frame_Number: value.Parameter_List.Name_Ref.indexOf(val.Name) + 1,
            Frame: value,
            Schema767: val,
            Frame_Rate: value.Recording_Rate.hasOwnProperty('Denominator') ? parseFloat((value.Recording_Rate.Numerator / value.Recording_Rate.Denominator).toFixed(2)) : value.Recording_Rate.Numerator,
          }
          this.jsonArray.push(obj)
        }

      }
    })
    if (this.isEdit && !this.isAdd) {
    this.setForm();
    }
    this.loadingCount--;

  }

  onLabelListChanged($event) {
    let selectedItem = this.labelList.filter(x => x.Label === $event.value);
    selectedItem[0].Busses.forEach((x) => {
      var bussDetails = this.fullBusList.filter(y => y.Port === x.Port);
      this.busList.push(bussDetails[0]);
    });
  }

  onBusListChanged($event, label = undefined, port = undefined) {
    let labelValue = label ? label : this.formdata.DXS_Label;
    let portValue = port ? port : $event.value;
    let selectedItem = this.labelList.filter(x => x.Label === labelValue);
    let sdis = selectedItem[0].Busses.filter(x => x.Port === portValue);
    this.sdiList = sdis[0].SDIs
  }

  setForm() {
    this.loadingCount++;
    const selectedMnemonic = this.route.snapshot.queryParamMap.get('editName');
    let selectMnemonic = this.jsonArray.filter((item, index) => {
      if (item.Schema767.Mnemonic_Code === selectedMnemonic) {
        this.cloneIndex = index;
        return item;
      }
    });

    selectMnemonic.map((item) => { this.formdata = item;  })
    this.selectedMnemonic = this.formdata.Schema767.Mnemonic_Code;
    this.bitLength = Math.pow(2, this.formdata.Schema767.Number_of_Bits) - 1;
    this.copyBitLength = Math.pow(2, this.formdata.Schema767.Number_of_Bits) - 1;
    if (this.formdata.Frame.Recording_Rate.Numerator?.toString() != undefined) { this.frameRate = this.formdata.Frame.Recording_Rate.Numerator + this.lblHz }
    if (this.formdata.Frame.Recording_Rate.Denominator?.toString() != undefined) { this.frameRate += "/" + this.formdata.Frame.Recording_Rate.Denominator + this.lblHz }

    const found = this.formdata.Schema767.Range.hasOwnProperty("Text_Conversion");
    if (this.formdata.Schema767.Range.Data_Type === 'Unsigned Binary' && found === true) {
     this.tableData = this.formdata.Schema767.Range.Text_Conversion.Range_Text_Pair;
    };
    const rangeFound = this.formdata.Schema767.hasOwnProperty("Operational_Range");
    if (!rangeFound) {
      this.formdata.Schema767.Operational_Range = { "RealRange_Start": null, "RealRange_End": null };
    }

    this.selectedValue = this.formdata.Schema767.Range.Data_Type;

    if (this.formdata.Schema767.Range.hasOwnProperty("Conversion_Step")) {
      const checkEU = this.formdata.Schema767.Range.Conversion_Step.hasOwnProperty("Poly_Coeff");
      const checkEUInt = this.formdata.Schema767.Range.Conversion_Step.hasOwnProperty("Integer_Real_Table");
      const checkEUReal = this.formdata.Schema767.Range.Conversion_Step.hasOwnProperty("Real_Real_Table");
      this.EuData = checkEU === true ? this.displayPolyValue(): [];
      this.EuInteger = checkEUInt === true ? this.displayIntValue() : [];
      this.EuReal = checkEUReal === true ? this.displayRealValue(): [];
    }
    if (this.formdata.Schema767.hasOwnProperty("EU_Type")) {this.selectedEuValue = this.formdata.Schema767.EU_Type}else{  this.selectedEuValue = 'Integer-Real Table'; this.displayPolyValue();}
    
    if (this.formdata.Schema767.hasOwnProperty("Label")) {
      this.formdata.Schema767['DXS_Label'] = this.formdata.Schema767.Label;
      this.onLabelListChanged({value: this.formdata.Schema767.Label});
      this.formdata.Schema767['DXS_BusName'] = this.formdata.Schema767.Port;
      if (this.formdata.Schema767.hasOwnProperty('Port')) {
        this.onBusListChanged({}, this.formdata.Schema767.Label, this.formdata.Schema767.Port);
        this.formdata.Schema767['DXS_SDI'] = this.formdata.Schema767.SDI;
      }
    }

    this.formdata.Schema767['Frame_Title'] = this.formdata.Frame.Frame_Title;
    this.formdata.Schema767['Frame_Number'] = this.formdata.Frame_Number;
    this.formdata.Schema767['Recording_Rate'] = this.formdata.Frame_Rate + this.lblHz;
    this.formdata.Schema767['Raw_Range_Max'] = this.formdata.Schema767.Range.Raw_Range.IntRange_End;
    this.formdata.Schema767['Raw_Range_Min'] = this.formdata.Schema767.Range.Raw_Range.IntRange_Start;
    this.formdata.Schema767['Bit_Length'] = this.formdata.Schema767.Number_of_Bits;
    this.formdata.Schema767['Data_Type'] = this.formdata.Schema767.Range.Data_Type;
    this.formdata.Schema767["RealRange_Start"] = this.formdata.Schema767.Operational_Range.RealRange_Start;
    this.formdata.Schema767["RealRange_End"] = this.formdata.Schema767.Operational_Range.RealRange_End;
    this.formdata.Schema767["Signal_Source"] = this.formdata.Schema767.Signal_Source;
    const selectedFrameID = this.formdata.Frame.Frame_ID;
    this.formdata = this.formdata.Schema767;
    this.myForm[1].options = this.FrameIDs;
    this.formdata['Frame_ID'] = selectedFrameID;
    this.formdata['Description'] = this.formdata.Comments;
    this.loadingCount--;
  }

  initialValues(){
    this.frameIdentification = [];
    this.dataparameter = [];
    this.jsonArray = [];
    this.getNameArray = [];
    this.FrameIDs = [];
    this.tableData =[];
    this.EuData = [];
    this.EuInteger = [];
    this.EuReal =[];
    this.polyArray = [];
    this.intArray = [];
    this.realArray = [];
    this.formdata = {};
  }

  addDiscreteParameters(discreteArray: any) {
    this.minIndex = [];
    let newRow, row;
    row = discreteArray.length - 1;
    row != undefined ? newRow = { "min": row + 1, "max": row + 1, "text": "" } : newRow = { "min": 0, "max": 0, "text": "" }
    if (newRow.min <= this.bitLength) {
      this.tableData.push(newRow);
      this.checkBitLength(newRow, newRow.min)
      this.rowExceedBool = false
    }
    else {
      this.rowExceedBool = true
    }
    this.tableUpdated = true;
  }

  isDuplicateText(event: any, index: number) {
    this.isDuplicate = false;
    this.textIndex = null;
    const getVal = this.tableData.filter((obj, index, self) =>
      self.some((innerObj, innerIndex) => innerIndex !== index && (innerObj.text === obj.text)));
    if (getVal.length > 1) {
      return this.isDuplicate = true, this.textIndex = index;
    } else {
      return this.isDuplicate;
    }

  }
  openDeleteDialog(data: any, index: number) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete?',
      accept: () => {
        this.deleteparameter(index, data);
      }
    });
  }
  handleChange(e: any) {
    this.indexValue = e.index
  }
  formReset(form) {
    this.loadingCount++;
    this.mnemonicBool = false;
    this.rowExceedBool = false;
    this.isDuplicate = false;
    this.minIndex = [];
    this.discMin = [];
    this.textIndex = null;
    this.tableUpdated = false;
    this.selectedName = "";
    this.selectedValue = "BCD";
    this.tableData = [];
    this.EuData = [];
    this.copyBitLength = null;
    this.bitLength = null;
    this.formdata = {};
  }

  displayConversionStep() {
    for (let i = 0; i < this.EuData.length; i++) {
      this.polyObj = {
        "Coeff_Pair": {
          "index": this.EuData[i]?.index,
          "#text": this.EuData[i]?.text
        }
      }
      this.polyArray.push(this.polyObj)
    }
    for (let i = 0; i < this.EuInteger.length; i++) {
      this.intObj = {
        "Integer_Real_Pair": {
          "index": this.EuInteger[i]?.index,
          "#text": this.EuInteger[i]?.text
        }
      }
      this.intArray.push(this.intObj)
    }
    for (let i = 0; i < this.EuReal.length; i++) {
      this.realObj = {
        "Real_Real_Pair": {
          "index": this.EuReal[i]?.index,
          "#text": this.EuReal[i]?.text
        }
      }
      this.realArray.push(this.realObj)
    }
  }

  onSave(data: any) {
    this.displayConversionStep();
    let Text_Conversion  = [];
    if (this.formdata.Data_Type === "Unsigned Binary") {
      if (this.tableData.length > 0) {
        Text_Conversion =  this.tableData;
      }
    }

    let busName = this.busList.filter(x => x.Port === this.formdata.DXS_BusName);

    let obj: parameters767 = {
      Frame_Number: this.formdata.Frame_Number,
      Frame : this.formdata.Frame_ID,
      Schema767 :{
        Name: data.Name,
        Mnemonic_Code: data.Mnemonic_Code,
        Comments: data.Description,
        Units: this.formdata.Units,
        Sign_Convention: this.formdata.Sign_Convention,
        Signal_Source: this.formdata.Signal_Source,
        Number_of_Bits: this.formdata.Number_of_Bits,
        Range: {
          Raw_Range: {
            "IntRange_Start": this.formdata.Raw_Range_Min,
            "IntRange_End": this.formdata.Raw_Range_Max
          },
          Data_Type: this.formdata.Data_Type,
        },
        Operational_Range: {
          "RealRange_Start": this.formdata.RealRange_Start,
          "RealRange_End": this.formdata.RealRange_End
        },
        Resolution: this.formdata.Resolution,
        EU_Type: this.selectedEuValue,
        Port: this.formdata.DXS_BusName,
        BusName: busName[0].Name,
        SDI: this.formdata.DXS_SDI,
        Label: this.formdata.DXS_Label,
      },
      Frame_Rate: this.frameRate,
    }
    
    if (this.formdata.Data_Type === "Unsigned Binary") {
      obj.Schema767.Range['Text_Conversion'] = {
        "Range_Text_Pair":Text_Conversion
      }
    }
    const checkbool = obj.hasOwnProperty('Signal_Source');
    if(checkbool){
      obj.Schema767['Signal_Source'] = obj.Schema767.Signal_Source.toUpperCase();
    }
    if(this.intArray.length > 0){
      obj.Schema767.Range['Conversion_Step'] = {
        "Integer_Real_Table": this.intArray,
      }
    }
    if(this.polyArray.length > 0){
      obj.Schema767.Range['Conversion_Step'] = {
        "Poly_Coeff": this.polyArray,
      }
    }
    if(this.realArray.length > 0){
      obj.Schema767.Range['Conversion_Step'] = {
        "Real_Real_Table": this.realArray,
      }
    }

    if (this.isAdd) {
    this.updatedMnemonics.push({ ["Add"]: { [0]: obj } });
    sessionStorage.setItem('Mnemonics', JSON.stringify(this.updatedMnemonics));
  } else if (this.isEdit && !this.isClone) {
    this.tableValues();
    obj["Mnemonic_Code"] = this.selectedMnemonic;
    this.updatedMnemonics.push({ ["Edit"]: { [this.cloneIndex]: obj } });
    sessionStorage.setItem('Mnemonics', JSON.stringify(this.updatedMnemonics));
  } else if (this.isClone) {
    this.pushMnemonicArray();
    this.updatedMnemonics.push({ ["Clone"]: { [0]: obj } });
    sessionStorage.setItem('Mnemonics', JSON.stringify(this.updatedMnemonics));
  }

    this.router.navigate([Components.ListViewDXS.path], {
      queryParams: {
        Name: this.route.snapshot.queryParamMap.get('Name'),
        frame: this.route.snapshot.queryParamMap.get('frame'),
        version: this.route.snapshot.queryParamMap.get('version'),
        version_index: this.route.snapshot.queryParamMap.get('version_index')
      }, state: { data: data }
    });
  }

  pushMnemonicArray(){
    let updatedMnemonics = JSON.parse(sessionStorage.getItem('Mnemonics'));
    for (var key in updatedMnemonics) {
      for (var key1 in updatedMnemonics[key]) {
        for (var key2 in updatedMnemonics[key][key1]) {
          this.updatedMnemonics.push({ [key1]: { [key2]: updatedMnemonics[key][key1][key2] } });
        }
      }
    }
  }

  tableValues(){
    this.formdata.EU_Type = this.selectedEuValue;
    if (this.formdata.Range.Data_Type === "Unsigned Binary") {
      if (this.tableData.length > 0) {
        this.formdata.Range.Text_Conversion = { "Range_Text_Pair": this.tableData };
      }
    }else{
      const found = this.formdata.Range.hasOwnProperty("Text_Conversion");
      if ( found === true) {
       delete this.formdata.Range['Text_Conversion'];
      }
    }
    if (this.formdata.EU_Type === "Polynomial") {
      if (this.EuData.length > 0) {
        this.formdata.Range.Conversion_Step = { "Poly_Coeff": this.polyArray };
      }

    }
    if (this.formdata.EU_Type === "Integer-Real Table") {
      if (this.EuInteger.length > 0) {
        this.formdata.Range.Conversion_Step = { "Integer_Real_Table": this.intArray };
      }
    }
    if (this.formdata.EU_Type === "Real-Real Table") {
      if (this.EuReal.length > 0) {
        this.formdata.Range.Conversion_Step = { "Real_Real_Table": this.realArray };
      }
    }
    const checkbool = this.formdata.hasOwnProperty('Signal_Source');
    if(checkbool && this.formdata.Signal_Source){
      this.formdata.Signal_Source = this.formdata.Signal_Source.toUpperCase()
    }
  }

  deleteparameter(i: number, deleteRow: any) {

    const foundDiscrete = this.tableData.includes(deleteRow);

    if (foundDiscrete === true) {

      this.tableData.splice(i, 1);

      this.isNull = this.tableData.length >= 1 ? false : true;

      return true;

    }

    const foundEU = this.EuData.includes(deleteRow);

    if (foundEU === true) {

      this.EuData.splice(i, 1);

      this.isNull = this.EuData.length >= 1 ? false : true;

      return true;

    }

    const foundEUInt = this.EuInteger.includes(deleteRow);

    if (foundEUInt === true) {

      this.EuInteger.splice(i, 1);

      this.isNull = this.EuInteger.length >= 1 ? false : true;

      return true;

    }

    const foundEUReal = this.EuReal.includes(deleteRow);

    if (foundEUReal === true) {

      this.EuReal.splice(i, 1);

      this.isNull = this.EuReal.length >= 1 ? false : true;

      return true;

    }
    this.tableUpdated = true;
    return false;

  }

  formCancel() {
    this.router.navigate([Components.ListViewDXS.path], {
      queryParams: {
        Name: this.route.snapshot.queryParamMap.get('Name'),
        frame: this.route.snapshot.queryParamMap.get('frame'),
        version: this.route.snapshot.queryParamMap.get('version'),
        version_index: this.route.snapshot.queryParamMap.get('version_index')
      }
    });
  }

  isCellEmpty(data: any) {
    this.isEmpty = data === '' ? true : false;
    return this.isEmpty;
  }

  calculateBitlength(num) {
    this.bitLength = Math.pow(2, num) - 1;
    this.copyBitLength = Math.pow(2, num) - 1;
    this.formdata.IntRange_End = Math.pow(2, num) - 1;
  }

  checkBitLength(num, di) {
    this.minIndex = [];
    if (num.min != null) {
      var obj1 = { "index": di, "min": num.min }
      if (this.discMin.length != 0) {
        this.discMin.map((obj, index) => {
          if (obj.index === di) { obj.min = num.min; } else {
            var chek = this.discMin.find(c => c.index === di);
            if (chek === undefined) { this.discMin.push(obj1); }
          }
        })
      }
      else {
        this.discMin.push(obj1);
      }
      this.discMin.map((input) => {
        this.tableData.forEach((item, ind) => {
          if (input.min === item.min && ind != input.index) {
            this.minIndex.push(ind);
          }
        })
      })

    }
    this.minIndex = this.minIndex.filter(function (item, index, inputArray) {
      return inputArray.indexOf(item) == index;
    });
  }
  checkEmptyValue(data: any) {
    if (data == null) {
      this.emptyVal = true;
      this.mnemonicBool = true
    } else {
      this.emptyVal = false;
      this.mnemonicBool = false
    }

  }
  onKeypressNum(event: any) {
    return (event.charCode != 8 && event.charCode == 0  && event.charCode != 109  && event.charCode != 69  || (event.charCode >= 48 && event.charCode <= 57 || event.charCode == 190));
  }

  public isSaveButtonDisabled(): boolean {
    if (!this.dynamicForm?.value.Mnemonic_Code || !this.dynamicForm?.value.Name) {
      return true
    }
    if (this.formdata.RealRange_Start === null || this.formdata.RealRange_End === null) {
      return true;
    }
    if (this.formdata.RealRange_End < this.formdata.RealRange_Start) {
      return true;
    }
    if (this.formdata.Raw_Range_Min === null || this.formdata.Raw_Range_Max === null) {
      return true;
    }
    if (this.formdata.Raw_Range_Max < this.formdata.Raw_Range_Min) {
      return true;
    }
    if (!this.formdata.Number_of_Bits || this.formdata.Number_of_Bits < 1) {
      return true;
    }
    if (!this.formdata.Resolution) {
      return true;
    }
    if (this.selectedEuValue === 'Polynomial') {
      if (!this.EuData.length) {
        return true;
      }
      for (let dataIndex in this.EuData) {
        if (this.EuData[dataIndex].text === null || this.EuData[dataIndex].index === null){
          return true;
        }
      }
    }
    if (this.selectedEuValue === 'Integer-Real Table') {
      if (!this.EuInteger.length) {
        return true;
      }
      for (let dataIndex in this.EuInteger) {
        if (this.EuInteger[dataIndex].text === null || this.EuInteger[dataIndex].index === null){
          return true;
        }
      }
    } 
    if (this.selectedEuValue === 'Real-Real Table') {
      if (!this.EuReal.length) {
        return true;
      }
      for (let dataIndex in this.EuReal) {
        if (this.EuReal[dataIndex].text === null || this.EuReal[dataIndex].index === null){
          return true;
        }
      }
    }
    for (let item of this.tableData) {
      if (!item.text || item.max === null || item.min === null) {
        return true;
      }
    }
    return false;
  }
}
