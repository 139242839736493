import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { forkJoin, takeUntil } from 'rxjs';
import {
  Action,
  BaseViewComponent,
} from 'src/app/common/components/base-view.component';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { CommonService } from 'src/app/common/services/common.service';
import {
  ConversionType,
  DataFrameType,
  RedactionType,
  ResponseSAR,
  SAR,
  SARMap,
  jsonValues,
} from 'src/app/features/datadelivery/models/SARRedaction';
import { EndpointSARRedactionService } from '../../../services/sarRedaction.service';
import { Components } from '../../../integration/endpointdatadelivery.components';
import { mappedRedactionTypes } from 'src/app/features/datadelivery/models/FlightDataRedactions';

@Component({
  selector: 'app-sarredaction-data-details',
  templateUrl: './sarredaction-data-details.component.html',
  styleUrls: ['./sarredaction-data-details.component.css'],
})
export class SARRedactionDataDetailsComponent
  extends BaseViewComponent
  implements OnInit
{
  pageTitle = Components.ViewSARRedactionsDataDelivery.label;
  public modalDialog?: boolean;
  public submitted: boolean = false;
  isDataFrame: boolean = false;
  uniqueError: boolean = false;
  showButtons: boolean = true;
  today = new Date();
  todayAsStr = `${this.today.getFullYear()}-${this.today.getMonth()}-${this.today.getDate()}`;
  cols: any[] = [];
  SARredactions: SAR[] = [];
  SARredaction!: SAR;
  dataframe: DataFrameType[] = [];
  convtype: ConversionType[] = [];
  redactiontype: RedactionType[] = [];
  sarmap: SARMap[] = [];
  jsonParameters: jsonValues;
  sarData: any;
  storeddata: any;
  sarMapList: SARMap[] = [];
  avialableSarMap: SARMap[] = [];
  selectedSarMap: SARMap[] = [];
  selectedSarMapIDs: string[] = [];
  editselectedSarMapIDs: string[] = [];
  picklistChange = false;
  isButtonDisabled: boolean = true;
  initialRecordersCount: number;
  saveButtonDisabled: boolean;
  isReadonly: boolean = true;
  responseSar: ResponseSAR;
  selectMapId: number;
  selectedMapType: string;
  selectedFrameId: any;
  selectedFrameName: string;
  selectConvertionId: number;
  selectedConversionType: string;
  messageLabel = 'SAR redaction';

  intialVal() {
    this.pageTitle = this.messageLabel;
    this.updateBreadcrumb(this.pageTitle);
    this.SARredaction = new SAR();
    this.jsonParameters = new jsonValues();
    this.SARredaction.redaction_id = -1;
    this.isNew = true;
  }

  constructor(
    router: Router,
    private route: ActivatedRoute,
    breadcrumbService: BreadcrumbService,
    confirmationService: ConfirmationService,
    messageService: MessageService,
    private endpointSarRedactionService: EndpointSARRedactionService,
    private commonService: CommonService
  ) {
    super(messageService, confirmationService, router, breadcrumbService);
    this.getBreadCrums();
  }

  getBreadCrums() {
    this.breadcrumbService.setItems(this.route, [
      {
        label: Components.SARRedactionsDataDelivery.label,
        routerLink: Components.SARRedactionsDataDelivery.path,
      },
      {
        label: this.route.snapshot.queryParamMap.get('name')
          ? Components.ViewSARRedactionsDataDelivery.label +
            ` (${this.route.snapshot.queryParamMap.get('name')})`
          : Components.ViewSARRedactionsDataDelivery.label,
      },
    ]);
  }

  ngOnInit(): void {
    this.convtype = [];
    mappedRedactionTypes.forEach(type => {
      this.convtype.push({
        conversion_id: type.redactiontype_id,
        conversion_type: type.name
      })
    })
    this.getBreadCrums();
    const redactionId = +this.route.snapshot.queryParamMap.get('redactionId');
    if (redactionId) {
      this.loadSarRedactionDetails(redactionId);
    } else {
      this.showErrorMsg(
        `Redaction id not found`,
        Action.Get,
        `${this.messageLabel}s`
      );
      this.intialVal();
      this.loadSARReportsMap();
    }
  }

  loadSarRedactionDetails(redaction_id: number) {
    this.loadingCount++;
    this.jsonParameters = new jsonValues();
    forkJoin({
      getsardetails:
        this.endpointSarRedactionService.getSARRedaction_by_id(redaction_id),
      dataframe: this.commonService.getDataFrameData(),
      avialableAcmsMap: this.commonService.getSARMapsList(),
    })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results) => {
          results.dataframe.forEach((ele) => {
            this.dataframe.push({
              dataframe_id: ele.dataframe_id,
              dataframe_type: ele.name,
            });
          });
          results.avialableAcmsMap.forEach((ele) => {
            this.sarmap.push({
              SARMap_id: ele.sarmap_id,
              SARMap_type: ele.name,
            });
          });
          this.SARredaction = results.getsardetails;
          if (this.SARredaction.defaultparametervaluesconfig != null) {
            this.jsonParameters = JSON.parse(
              this.SARredaction.defaultparametervaluesconfig
            );
            this.selectedFrameId = this.jsonParameters.dataframe_id;
          }
          let getMapJson = JSON.parse(this.SARredaction.mapjson);
          this.editselectedSarMapIDs = getMapJson;
          this.selectedSarMapIDs = getMapJson;
          this.loadSARMapDetails(this.SARredaction.sarmap_id)
        },
        error: (error) => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        },
      });
  }

  loadSARReportsMap() {
    this.loadingCount++;
    forkJoin({
      avialableAcmsMap: this.commonService.getSARMapsList(),
      dataframe: this.commonService.getDataFrameData(),
    })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results) => {
          //sorting to show in alphabetical order
          results.avialableAcmsMap.forEach((ele) => {
            this.sarmap.push({
              SARMap_id: ele.sarmap_id,
              SARMap_type: ele.name,
            });
          });
          results.dataframe.forEach((ele) => {
            this.dataframe.push({
              dataframe_id: ele.dataframe_id,
              dataframe_type: ele.name,
            });
          });
        },
        error: (error) => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        },
      });
  }

  loadSARMapDetails(mapId?: number) {
    this.loadingCount++;
    this.saveButtonDisabled = true;
    forkJoin({
      availablesar: this.commonService.getSARMapReports(mapId),
    })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results) => {
          //sorting to show in alphabetical order
          this.avialableSarMap = [];
          results.availablesar.forEach((ele) => {
            if (this.editselectedSarMapIDs.find((a) => a == ele)) {
              this.avialableSarMap.push({ SARMap_type: ele });
              this.selectedSarMap.push({ SARMap_type: ele });
            }
            this.sarMapList.push({ SARMap_type: ele });
          });

          this.selectedSarMap.forEach((ele) => {
            this.selectedSarMapIDs.push(ele.SARMap_type);
          });

          //Filter the source Regex List
          if (this.sarMapList && this.avialableSarMap) {
            this.sarMapList = this.sarMapList.filter(
              (p) =>
                this.selectedSarMap.findIndex(
                  (i) => i.SARMap_type == p.SARMap_type
                ) == -1
            );
          }
          this.initialRecordersCount = this.selectedSarMap.length;
          this.sortSarMapList()
          this.sortAvailableSarMapList()
        },
        error: (error) => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        },
      });
  }

  sortSarMapList(){
    this.sarMapList.sort((a, b) => {
      return a.SARMap_type.localeCompare(b.SARMap_type);
    });
  }

  sortAvailableSarMapList(){
    this.avialableSarMap.sort((a, b) => {
      return a.SARMap_type.localeCompare(b.SARMap_type);
    });
  }

  onCancel() {
    this.router.navigate([Components.SARRedactionsDataDelivery.path]);
  }
}
