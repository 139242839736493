<p-blockUI [blocked]="loadingCount > 0">
    <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<p-confirmDialog></p-confirmDialog>

<div class="col-12 card p-3">
    <form #recorderConfigForm="ngForm" (ngSubmit)="onSubmit();" novalidate>
      <div class="card-header m-2">
        <h5>{{pageTitle}}</h5>
      </div>  
        <div class="card">
          <h6>Details</h6>
          <div class="field">
            <p-pickList [source]="availableAirlines" [target]="selectedAirlines" [responsive]="true" id="airlineID"
            sourceHeader="Available Airlines" targetHeader="Selected Airlines" filterBy="name"
            [showSourceControls]="false" [showTargetControls]="false"
            (onMoveToTarget)="onMoveToTarget($event)" (onMoveToSource)="onMoveToSource($event)"
            (onMoveAllToSource)="onMoveToSource($event)" (onMoveAllToTarget)="onMoveToTarget($event)"
            [sourceStyle]="{'height':'200px'}" [targetStyle]="{'height':'200px'}" [disabled]="!canEdit"
            >
            <ng-template let-airline pTemplate="item">
              <ng-container *ngIf="toAirlineList(airline)" >
                <div>
                  <div>{{airline.name}}</div>
                </div>
              </ng-container>
            </ng-template>
          </p-pickList>

          <div class="col-6 p-0"> 
              <div class="tdy-required" *ngIf="(selectedAirlines.length< 1 )">
                At least 1 Airline is required.
              </div>
            </div>  
          </div>
        </div>
  
        <div class="flex justify-content-end">
            <span class="p-input-icon-left">
                <button (click)="onCancel()" class="p-button-text" icon="pi pi-times" id="cancelButton" label="Cancel"
                    pButton pRipple type="button"></button>
            </span>
            <span class="p-input-icon-left">
                <button *ngIf="canEdit" (click)="onReset()" class="p-button-text" icon="pi pi-undo" id="resetButton" label="Reset"
                    pButton pRipple [disabled]="isSaveDisabled" type="button"></button>
            </span>
            <span class="p-input-icon-left pl-2">
                <button *ngIf="canEdit" [disabled]="isSaveDisabled " icon="pi pi-check"
                    id="okButton" label="Save" pButton pRipple type="submit"></button>
            </span>
        </div>
    </form>
  </div>