import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, observable, of, switchMap } from "rxjs";
import { catchError, concatMap, retry, takeUntil } from "rxjs/operators";
import { BaseService } from "src/app/common/services/base.service";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
  })
  export class DataUploadService extends BaseService {
  
    constructor(
      private http: HttpClient) {
      super();
      this.baseUrl = environment.apiUrl.sendToTdy;
    }

    uploadData(folderName: string, file: any, fileName: string): Observable<any> {
      //file = file.replace('data:application/x-zip-compressed;base64,', '')
      let url = this.baseUrl + "airline-upload-url" +`/${folderName}`;
      const options = { headers: this.httpGetHeadersForJSON() };
      let req_presigned_url = this.http.post(url, {"filename": fileName}, options).pipe(catchError(this.httpHandleError))
      const formData = new FormData();
      return req_presigned_url
      .pipe(
          switchMap(val=>{
            Object.keys(val["fields"]).forEach(key=>{
              formData.append(key, val["fields"][key]);
            })
            formData.append("file", file);

            return this.http.post(val["url"],formData )
                   .pipe(
                    catchError(this.httpHandleError)
                   )
          })
      )
    }

    getDataUploadFolder(): Observable<any>{
      const url = this.baseUrl + "start-upload";
    return this.http.get<any>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError)
      );
    }

    getProcessedData(folderName: string): Observable<any>{
      const url = this.baseUrl + "upload-complete" + `/${folderName}`;
    return this.http.get<any>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError)
      );
    } 
    
    submitData(folderName: string, processedData: any): Observable<any>{
      const url = this.baseUrl + "submit-data" + `/${folderName}`;
    return this.http.post<any>(url, JSON.stringify(processedData), { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError)
      );
    } 

}
