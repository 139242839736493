export class EnhancedPackages {
  package_id: number;
  name: string;
  description: string;
  redaction_id: number;
  predefined_redaction_id: number;
  ziptype_id: number;
  zip_password: string;
  file_name: string;
  conversiontype_id: number;
  conversiontype_name: string;
  predefined_redaction_name: string;
  ziptype: string;
  endpoint_name: string;
  endpoints?: EndPoints[];
  aircrafts: aircrafts[];
  secondary: boolean;
  primary: boolean;
  datatype: string;
}

export class EnhancedPackage {
  cp_id?: string;
  name?: string;
  data_type?: string;
  description?: string;
  zip_options?: string;
  redaction?: string;
  conversion_type?: string;
  preset_flightdata?: string;
  preset_acms?: string;
  preset_sar?: string;
  aircraft?: string;
  filepath?: string;
  filename?: string;
  password?: string;
  output_filename?: string;
  endpoints?: EndPoints[];
}

export interface ResponseModelEnhancedPackages {
  package_id: number;
  name: string;
  description: string;
  redaction_id: number;
  predefined_redaction_id: number;
  ziptype_id: number;
  zip_password: string;
  conversiontype_id: number;
  file_name: string;
  endpoint_id: number[];
  aircraft_id: number[];
  organization_id: number;
  secondary: boolean;
  primary: boolean;
  enhanced: boolean;
}

export interface EndPoints {
  endpoint_id: number;
  endpoint_name: string;
}

export interface aircrafts {
  aircraft_id: number;
  aircraft_name: string;
}

export enum ConversionTypes {
  NoConversion = 1,
  FlightData = 2,
}
