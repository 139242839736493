import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { AircraftGroupService } from 'src/app/features/administration/services/aircraft-group.service';
import { AircraftService } from 'src/app/features/administration/services/aircraft.service';
import { Action, BaseViewComponent } from 'src/app/common/components/base-view.component';
import { Location } from '@angular/common';
import { AircraftGroup } from 'src/app/features/administration/models/aircraftGroup';
import { Aircraft, AircraftPair } from 'src/app/features/administration/models/aircraft';
import { forkJoin, takeUntil } from 'rxjs';
import { NgForm } from '@angular/forms';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { permissions } from 'src/app/security/models/permissions';
import { CommonService } from 'src/app/common/services/common.service';
import { Components } from '../../integration/administration.components';

@Component({
  selector: 'app-aircraft-group-details',
  templateUrl: './aircraft-group-details.component.html'
})
export class AircraftGroupDetailsComponent extends BaseViewComponent implements OnInit {
  pageTitle = Components.AircraftGroupDetails.label;
  aircraftGroup: AircraftGroup = null;
  aircraft: AircraftPair[];
  selectedAircraft: Aircraft[] = [];
  aircraftChange = false;
  isReadOnly = false;
  messageLabel = 'aircraft group';

  @ViewChild('aircraftGroupForm') aircraftGroupForm: NgForm;

  constructor(private aircraftGroupService: AircraftGroupService,
    private aircraftService: AircraftService,
    private route: ActivatedRoute,
    private securityUserService: SecurityUserService,
    private location: Location,
    private commonService: CommonService,
    messageService: MessageService,
    confirmationService: ConfirmationService,
    router: Router,
    breadcrumbService: BreadcrumbService) {
    super(messageService, confirmationService, router, breadcrumbService);

    // Note: Service won't update breadcrumb if caller already did so.
    this.breadcrumbService.setItems(route, [
      { label: Components.AircraftGroup.label, routerLink: Components.AircraftGroup.path },
      { label: (this.route.snapshot.queryParamMap.get('name')) ? Components.AircraftDetails.label + ` (${this.route.snapshot.queryParamMap.get('name')})` : Components.AircraftDetails.label },
    ]);
  }

  ngOnInit(): void {
    const canAdd = this.securityUserService.userHasPermission(permissions.admin.aircraft_groups.create);
    const canEdit = this.securityUserService.userHasPermission(permissions.admin.aircraft_groups.manage);

    const isEdit = !!this.route.snapshot.queryParamMap.get('g'); // g = group ID

    if (!isEdit) {
      this.isReadOnly = !canAdd;
      this.addAircraftGroup();
    }
    else {
      this.isReadOnly = !canEdit;
      this.loadAircraftGroup(+this.route.snapshot.queryParamMap.get('g'));
    }
  }

  loadAircraftGroup(aircraftGroupId: number) {
    this.loadingCount++;

    // Current user has airlineId = -1, and throws an error while getting  listAircraftByAirline(-1). So changed airlineId for now.
    //this.userAirlineId = OrganizationType.Teledyne

    // Get the aircraft group and the aircraft list
    forkJoin([this.aircraftGroupService.getAircraftGroup(aircraftGroupId),
    this.commonService.listAircraftFilters()
    ]).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: ([aircraftGroup, aircraft]) => {
        this.aircraftGroup = aircraftGroup;
        this.aircraft = aircraft;

        // Filter the source aircraft list
        if (this.aircraft && this.aircraftGroup.aircraft) {
          this.aircraft = this.aircraft.filter(p => this.aircraftGroup.aircraft.findIndex(i => i.aircraft_id == p.aircraft_id) == -1)
        }

        if (!this.aircraftGroup.aircraft) {
          this.aircraftGroup.aircraft = [];
        }

        this.updateBreadcrumb(Components.AircraftGroupDetails.label + ' (' + this.aircraftGroup.aircraftgroup_name + ')');

      },
      error: error => {
        this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}`);
        this.loadingCount--;
      },
      complete: () => this.loadingCount--
    });
  }

  addAircraftGroup() {
    this.loadingCount++;

    // Update page title and breadcrumb with New...
    this.updateBreadcrumb('New Aircraft Group');
    this.pageTitle = "New Aircraft Group";

    // Current user has airlineId = -1, and throws an error while getting  listAircraftByAirline(-1). So changed airlineId for now.
    //this.userAirlineId = OrganizationType.Teledyne

    this.commonService.listAircraftFilters()
      .pipe(
        takeUntil(this.ngUnsubscribe)).subscribe({
          next: results => {
            this.aircraft = results;

            // Create the aircraft model
            this.isNew = true;
            this.aircraftGroup = new AircraftGroup();
            this.aircraftGroup.aircraftgroup_id = -1;
            this.aircraftGroup.aircraft = [];
          },
          error: error => {
            this.showErrorMsg(`${error}`, Action.Add, `${this.messageLabel}`);
            this.loadingCount--;
          },
          complete: () => {
            this.loadingCount--;
          }
        });
  }

  onSubmit() {
    this.loadingCount++;

    if (this.aircraftGroup.aircraft) {
      this.aircraftGroup.aircraft_id = this.aircraftGroup.aircraft.map(a => a.aircraft_id);
    }
    if (this.isNew) {
      this.aircraftGroupService.addAircraftGroup(this.aircraftGroup).pipe(
        takeUntil(this.ngUnsubscribe)).subscribe({
          next: id => {
            this.aircraftGroup.aircraftgroup_id = id;
            this.router.navigate([], {
              relativeTo: this.route,
              queryParams: {
                g: this.aircraftGroup.aircraftgroup_id
              },
              queryParamsHandling: 'merge'
            }).then();

            this.updateBreadcrumb(Components.AircraftGroupDetails.label + ' (' + this.aircraftGroup.aircraftgroup_name + ')');
            this.aircraftChange = false;
          },
          error: error => {
            this.showErrorMsg(error, Action.Add, `${this.messageLabel}`);
            this.loadingCount--;
          },
          complete: () => {
            this.showSuccessMsg(Action.Add, `${this.messageLabel}`, `${this.aircraftGroup.aircraftgroup_name}`);
            this.isNew = false;
            this.pageTitle = Components.AircraftGroupDetails.label;
            this.aircraftGroupForm.resetForm(this.aircraftGroupForm.value);
            this.loadingCount--;
          }
        }
        );
    } else {
      console.log(this.aircraftGroup)
      this.aircraftGroupService.updateAircraftGroup(this.aircraftGroup).pipe(
        takeUntil(this.ngUnsubscribe)).subscribe({
          next: () => {
            this.updateBreadcrumb(Components.AircraftGroupDetails.label + ' (' + this.aircraftGroup.aircraftgroup_name + ')');
            this.aircraftChange = false;
          },
          error: error => {
            this.showErrorMsg(error, Action.Update, `${this.messageLabel}`);
            this.loadingCount--;
          },
          complete: () => {
            this.showSuccessMsg(Action.Update, `${this.messageLabel}`, `${this.aircraftGroup.aircraftgroup_name}`);
            this.aircraftGroupForm.resetForm(this.aircraftGroupForm.value);
            this.loadingCount--;
          }
        }
        );
    }
  }

  onCancel() {
    if (this.aircraftGroupForm.dirty || this.aircraftChange)
      this.confirmCancel(Components.AircraftGroup.path);
    else
      this.router.navigate([Components.AircraftGroup.path]).then();
  }

  onReset() {
    this.aircraftChange = false;
    this.aircraftGroupForm.resetForm();
    this.ngOnInit();
  }

  onAircraftChange() {
    this.aircraftChange = true;
  }

  toAircraft(aircraft): AircraftPair {
    return aircraft;
  }

}
