import { Observable, forkJoin } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { License, User } from 'src/app/features/administration/models/user';
import { BaseService } from 'src/app/common/services/base.service';
import { MenuService } from 'src/app/common/services/menu.service';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/common/services/common.service';

@Injectable()
export class UserService extends BaseService {
  userUrl = environment.apiUrl.admin + 'user';

  constructor(private router: Router,
    private http: HttpClient,
    private commonService: CommonService) {
    super();
  }

  /**
   * Logs out the current user by removing user from session, stopping services, and navigating to the login page.
   */
  logOut() {
    this.router.navigate([MenuService.Login.Link]);
  }

  getUser(userID: number): Observable<User> {
    const url = this.userUrl + `/${userID}`;
    return this.http.get<User>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError)
      );
  }

  getCurrentUser(): Observable<User> {
    const url = environment.apiUrl.admin + 'current-user';
    return this.http.get<User>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError)
      );
  }

  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.userUrl, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError)
      );
  }

  listUsersByApplication(api: string, applicationID: number): Observable<User[]> {
    const url = api + `user-application/${applicationID}`;
    return this.http.get<any[]>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(map(users => users.map(u => {
        u.user_admin = Boolean(u.user_admin);
        u.istdyuser = Boolean(u.istdyuser);
        u.role = { role_id: u.role_id ?? null, role_name: u.role_name ?? '' };
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { role_id, role_name, ...user } = u;
        return user;
      })),
        catchError(this.httpHandleError)
      );
  }

  addUser(user: User): Observable<number> {
    const url = this.userUrl;
    const headers = this.httpGetHeadersForJSON();

    headers.append('Accept', 'multipart/form-data');

    return this.http.post<number>(url, JSON.stringify(user), { headers: headers })
      .pipe(
        catchError(this.httpHandleError)
      )
  }

  saveUser(user: User): Observable<number> {
    const url = this.userUrl + `/${user.user_id}`;
    const headers = this.httpGetHeadersForJSON();

    headers.append('Accept', 'multipart/form-data');

    return this.http.put<number>(url, JSON.stringify(user), { headers: headers })
      .pipe(
        catchError(this.httpHandleError)
      );
  }

  saveCurrentUser(user: User): Observable<number> {
    const url = environment.apiUrl.admin + 'current-user';
    const headers = this.httpGetHeadersForJSON();

    headers.append('Accept', 'multipart/form-data');

    return this.http.put<number>(url, JSON.stringify(user), { headers: headers })
      .pipe(
        catchError(this.httpHandleError)
      );
  }

  saveUserRole(api: string, applicationID: number, userRoles: number[][]) {
    const url = api + `user-role/${applicationID}`;
    const headers = this.httpGetHeadersForJSON();

    return this.http.put<number>(url, userRoles, { headers: headers })
      .pipe(
        catchError(this.httpHandleError)
      );
  }

  deleteUser(userID: number) {
    if (userID > 0) {
      const url = this.userUrl + `/${userID}`;
      console.log(url)
      return this.http.delete(url, { headers: this.httpGetHeadersForJSON() })
        .pipe(
          catchError(this.httpHandleError)
        );
    }
  }

  // get the number of licenses and used licenses for an application
  getAppLicensesAndUsers(applicationId: number): Observable<License> {
    const url = environment.apiUrl.common + 'application-active-user' + `/${applicationId}`;
    return this.http.get<License>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError)
      );
  }

  // Combining airline and company list to show list of organizations on user detail page...
  getOrganizationsList(): Observable<any> {
    return forkJoin({
      airline: this.commonService.listAirlinesFilter(),
      company: this.commonService.listCompaniesFilter()
    })
  }
}
