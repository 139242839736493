<p-blockUI [blocked]="loadingCount > 0">
    <tdy-spinner
      style="position: absolute; left: 50%; top: 50%; z-index: 10000"
    ></tdy-spinner>
  </p-blockUI>
  <div class="col-12 card p-3">
    <div class="card-header m-2">
      <h5>{{ pageTitle }}</h5>
    </div>
    <div *ngIf="ddsalert" class="col-12 card p-3">
      <form #DataDeliveryAlertForm="ngForm" (ngSubmit)="OnSubmit()" novalidate>
        <div class="formgrid grid">
          <div class="field col-12 md:col-6 lg:col-3">
            <span class="p-float-label">
              <input
                [disabled]="isReadonly"
                type="text"
                pattern=".*\S.*"
                class="form-control"
                [style]="{ width: '100%' }"
                pInputText
                id="name"
                name="name"
                [(ngModel)]="ddsalert.name"
                required
                #name="ngModel"
                maxlength="50"
              />
              <label for="name">Name</label>
              <div
                class="tdy-required"
                *ngIf="(name.touched || name.dirty) && name.hasError('required')"
              >
                Name is required.
              </div>
              <div class="tdy-required" *ngIf="name.hasError('pattern')">
                Name cannot be left blank.
              </div>
            </span>
          </div>
          <div class="field col-12 md:col-6 lg:col-3">
            <span class="p-float-label">
              <p-dropdown
                [disabled]="isReadonly"
                [style]="{ width: '100%' }"
                [autoDisplayFirst]="false"
                autoWidth="false"
                [style]="{ width: '100%' }"
                optionLabel="alert_type"
                optionValue="alert_type_id"
                [options]="this.alerttype"
                id="alerttype"
                name="alerttype"
                #alerttypes="ngModel"
                [required]="true"
                (onChange)="onAlertTypeChange($event)"
                [(ngModel)]="ddsalert.alert_type_id"
              ></p-dropdown>
              <label for="alertType">Type</label>
              <div
                class="tdy-required"
                *ngIf="
                  (alerttypes.touched || alerttypes.dirty) &&
                  alerttypes.hasError('required')
                "
              >
                Type is required.
              </div>
            </span>
          </div>
          <div class="field col-12 md:col-6 lg:col-3">
            <span class="p-float-label">
              <input
                [disabled]="isReadonly"
                [min]="min_timelookback"
                [max]="max_timelookback"
                type="number"
                class="form-control"
                [style]="{ width: '100%' }"
                pInputText
                id="time_to_look_back"
                name="timeback"
                [(ngModel)]="ddsalert.time_to_look_back"
                [required]="ddsalert.enable_flag"
                #looktime="ngModel"
              />
              <label for="time">Time to look back {{ lblTime }}</label>
              <div
                class="tdy-required"
                *ngIf="
                  ddsalert.enable_flag &&
                  (looktime.touched || looktime.dirty) &&
                  looktime.hasError('required')
                "
              >
                Time to look back is required.
              </div>
              <div *ngIf="ddsalert.time_to_look_back">
                <div
                  class="tdy-required"
                  *ngIf="
                    !(ddsalert.time_to_look_back > min_timelookback) ||
                    !(ddsalert.time_to_look_back <= max_timelookback)
                  "
                >
                  Invalid Time to look back {{ lblTime }}.
                </div>
              </div>
            </span>
          </div>
          <div class="field col-12 md:col-6 lg:col-3">
            <span class="p-float-label">
              <input
                [disabled]="isReadonly"
                [min]="min_timelookback"
                [max]="max_timelookback"
                type="number"
                class="form-control"
                [style]="{ width: '100%' }"
                pInputText
                id="rate"
                name="rate"
                [(ngModel)]="ddsalert.rate"
                [required]="ddsalert.enable_flag"
                #rate="ngModel"
              />
              <label for="name">Rate {{ lblTime }}</label>
              <div
                class="tdy-required"
                *ngIf="
                  ddsalert.enable_flag &&
                  (rate.touched || rate.dirty) &&
                  rate.hasError('required')
                "
              >
                Rate is required.
              </div>
              <div *ngIf="ddsalert.rate">
                <div
                  class="tdy-required"
                  *ngIf="
                    !(ddsalert.rate > min_timelookback) ||
                    !(ddsalert.rate <= max_timelookback)
                  "
                >
                  Invalid Rate {{ lblTime }}.
                </div>
              </div>
            </span>
          </div>
          <div class="field col-12 md:col-6 lg:col-3">
            <p-checkbox
              class="en"
              label="Enable"
              [disabled]="isReadonly"
              [(ngModel)]="ddsalert.enable_flag"
              id="enable"
              name="enable"
              [binary]="true"
              inputId="binary"
            ></p-checkbox>
          </div>
        </div>
        <div class="card">
          <h6>Client Authentication Selection</h6>
          <div class="field col-12">
            <p-pickList
              [source]="availableClient"
              [target]="ddsalert.clientauths"
              id="client_auth_id"
              sourceHeader="Available Client Authentication(s)"
              targetHeader="Selected Client Authentication(s)"
              [showSourceControls]="false"
              [showTargetControls]="false"
              [responsive]="true"
              [sourceStyle]="{ height: '200px' }"
              (onMoveToTarget)="onMoveToTarget($event)"
              (onMoveToSource)="onMoveToSource($event)"
              (onMoveAllToSource)="onMoveAllToSource($event)"
              (onMoveAllToTarget)="onMoveAllToTarget($event)"
              [disabled]="isReadonly"
              [targetStyle]="{ height: '200px' }"
            >
              <ng-template let-clientauth pTemplate="item">
                <ng-container *ngIf="toclientauths(clientauth)">
                  <div>
                    <div>{{ clientauth.clientname }}</div>
                  </div>
                </ng-container>
              </ng-template>
            </p-pickList>
            <div
              class="tdy-required"
              *ngIf="
                isNew
                  ? ddsalert.enable_flag && selectedClientAuthIDs.length < 1
                  : selectedClientAuthIDs.length < 1 &&
                    selectedClientAuthIDs.length != null &&
                    ddsalert.enable_flag
              "
            >
              At least 1 Client Authentication is required.
            </div>
          </div>
        </div>
  
        <div class="flex justify-content-end">
          <span class="p-input-icon-left p-button-text" style="float: right">
            <button
              pButton
              pRipple
              label="Cancel"
              type="button"
              icon="pi pi-times"
              class="p-button-text"
              (click)="onCancel()"
            ></button>
          </span>
          <span class="p-input-icon-left">
            <button
              pButton
              pRipple
              label="Reset"
              [disabled]="!DataDeliveryAlertForm.dirty && isButtonDisabled"
              type="button"
              icon="pi pi-refresh"
              class="p-button-text"
              *ngIf="showButtons"
              (click)="onReset()"
            ></button>
          </span>
          <span class="p-input-icon-left ml-3" style="float: right">
            <button
              pButton
              pRipple
              label="Save"
              icon="pi pi-check"
              type="submit"
              *ngIf="showButtons"
              [disabled]="shouldDisableSaveButton()"
            ></button>
          </span>
        </div>
      </form>
    </div>
  </div>
  