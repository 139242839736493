import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/common/services/breadcrumb.service";
import { DataFrameService } from "src/app/features/configuration/services/data-frame.service";
import { BaseCompareComponent } from "../../base/base-compare.component";
import { dataframeCompareInput } from "../../input-model/dataframe-input";
import { Components } from '../../../../integration/configuration.components';

@Component({
    selector: 'data-frame-compare',
    templateUrl: '../../base/compare.component.html',
    providers: [{provide: dataframeCompareInput  ,
      useFactory:(dataframeService: DataFrameService) => new dataframeCompareInput(dataframeService) 
     , 
     deps:[DataFrameService]
    }
      
     ]
     
    
  })
 
  export class DataFrameCompareComponent extends BaseCompareComponent  implements OnInit{
  
    constructor(
        private route: ActivatedRoute,
        router: Router,
        messageService: MessageService,
        confirmationService: ConfirmationService,
        breadcrumbService: BreadcrumbService,
        dataframeInput: dataframeCompareInput

    ){
     
        super(messageService,confirmationService,router,breadcrumbService,dataframeInput);
  
    
        breadcrumbService.setItems(route,[
       {label : Components.Dataframe.label ,routerLink: Components.Dataframe.path},
       {label : `Versions (${this.route.snapshot.queryParamMap.get('Name')})`,routerLink: `${Components.DataframeVersions.path}` ,queryParams: {"a": this.route.snapshot.queryParamMap.get('b'), "Name":this.route.snapshot.queryParamMap.get('Name')}}
     
       ]);
       if(breadcrumbService.getItems().length == 3 )
           breadcrumbService.popItem();
    }


    ngOnInit(){
       
        this.load_Compare(+this.route.snapshot.queryParamMap.get('a')
        ,this.route.snapshot.queryParamMap.get('Name')
        ,this.route.snapshot.queryParamMap.get('versionList')
        )
      
    }

}
   
  