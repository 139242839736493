import { Component, OnInit, ViewChild } from '@angular/core';
import { Action,BaseViewComponent } from 'src/app/common/components/base-view.component';
import { Components } from "../../../../integration/configuration.components";
import { ConfirmationService, MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { takeUntil } from 'rxjs';

import { FileUpload } from 'primeng/fileupload';
import { PickListModule } from 'primeng/picklist';
import { DataFrameModel, DataFrameParametersPayload } from 'src/app/features/configuration/models/dataframe';
import { DataFrameService } from 'src/app/features/configuration/services/data-frame.service';

@Component({
  selector: 'app-parameter-selection',
  templateUrl: './parameter-selection.component.html'
})
export class ParameterSelectionComponent extends BaseViewComponent implements OnInit {

  @ViewChild(FileUpload) fileUpload: FileUpload;

  pageTitle = Components.ParameterSelection.label
  fileButtonEnabled = false
  FileToSave : any;
  //record :DataFrameModel;
  record :DataFrameModel = new DataFrameModel();
  isSaveDisabled: boolean;
  recordFile: any;
  fileName: string;
  availableParameters: string[] = [];
  selectedParameters:string[] = [];
  //
  allowedFileType = ".FAP, .XML, .TDF, .PRM";
  maxFileSize: any;
  messageLabel = "data-frame";
  recordType = "";
  dataFrameVersion: number;
  dataFrameVersionID: number;
  dataframeName: string = this.route.snapshot.queryParamMap.get('dataFrameName');
  payloadParametersData: DataFrameParametersPayload;
  isFileSelected: boolean = false;


  constructor(
    messageService: MessageService,
    confirmationService: ConfirmationService,
    router: Router,
    breadcrumbService: BreadcrumbService,
    private route: ActivatedRoute,
    private dataFrameService: DataFrameService,
    private realUserService: SecurityUserService,
  ) {
    super(messageService, confirmationService, router, breadcrumbService);
    this.breadcrumbService.setItems(route, [
      { label: Components.Dataframe.label, routerLink: Components.Dataframe.path },
      { label: Components.DataframeVersions.label + `(${this.route.snapshot.queryParamMap.get('dataFrameName')})`, routerLink: Components.DataframeVersions.path,queryParams:{"a": this.route.snapshot.queryParamMap.get('dataFrameID'),"Name":this.route.snapshot.queryParamMap.get('dataFrameName') }}, //?a=5&Name=JB%20TEST
      { label: Components.ParameterSelection.label, routerLink: Components.ParameterSelection.path,queryParams:{"a": this.route.snapshot.queryParamMap.get('dataFrameID'), "Name": this.dataframeName } }
    ]);
   }

  ngOnInit(): void {
    this.dataFrameVersion = +this.route.snapshot.queryParamMap.get('dataFrameVersion');
    this.dataFrameVersionID = +this.route.snapshot.queryParamMap.get('dataFrameVersionID');
    this.isSaveDisabled = true;
    const a =this.route.snapshot.queryParamMap.get('a');
    this.dataframeName = this.route.snapshot.queryParamMap.get('Name');
    this.breadcrumbService.setItems(this.route, [
      { label: Components.Dataframe.label, routerLink: Components.Dataframe.path },
      { label: Components.DataframeVersions.label + `(${this.route.snapshot.queryParamMap.get('dataFrameName')})`, routerLink: Components.DataframeVersions.path, queryParams:{"a": this.route.snapshot.queryParamMap.get('dataFrameID'),"Name":this.route.snapshot.queryParamMap.get('dataFrameName') }  },
      { label: Components.ParameterSelection.label,  }
    ]);
  }

  onFileRemove(){
    this.availableParameters = [];
    this.selectedParameters = [];
    this.isFileSelected = false;
  }

  async onFileSelect(eve){
    this.loadingCount++;
    if (this.LoadFileToSave(eve)) return;
    this.record.filename = this.fileName;

    setTimeout(()=>{
      this.addDataFrame();
    }, 300)
    this.loadingCount--;
    this.isFileSelected = true;
  }

  addDataFrame(){
    this.loadingCount++;
    this.dataFrameService.uploadDataFrame(this.record,this.FileToSave,this.recordType).subscribe({
      next: res=>{
       this.isSaveDisabled = true;
      },
      error: err=>{
       this.showErrorMsg(`${err}`, Action.Add, `${this.messageLabel}`);
       this.loadingCount--;
     },
      complete: () => {
       this.getParameterDifferences(this.dataFrameVersionID,this.fileName);
       this.loadingCount--
      }
     })
  }

  LoadFileToSave(event) : boolean{
    // check the format file
    const allowedfileType = ['.FAP', '.XML', '.TDF', '.PRM']
    const fileType = event.files[0].name.split('.').pop();
    this.fileName = event.files[0].name;
    this.recordType = '';

    if (fileType.toLowerCase() === 'fap') {
      this.recordType = '-A717';
    } else if (fileType.toLowerCase() === 'tdf') {
      this.recordType = 'TDF';
    } else if (fileType.toLowerCase() === 'prm'){
      this.recordType = 'PRM';
    }

    else {
      this.recordType = '';
    }

    if (!allowedfileType.includes(`.${fileType.toUpperCase()}`)) {
      this.messageService.add({
        key: 'message',
        severity: 'error',
        summary: 'Incorrect File Type',
        detail: `${event.files[0].name} is not the correct type . Only ${this.allowedFileType} types are supported.`,
      });
      this.loadingCount --;
      return true;
    }

    const file = event.files[0];
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      this.FileToSave = reader.result;

      if (fileType.toLowerCase() === 'xml' || fileType.toLowerCase() === 'prm') {

        const base64Data = this.FileToSave.replace('data:text/xml;base64,', '');
        const decodedData = atob(base64Data);
        const content = new TextDecoder('utf-8').decode(new Uint8Array(decodedData.length).map((_, i) => decodedData.charCodeAt(i)));
        this.FileToSave = base64Data;
        if (content.indexOf('utf-8') == -1 && content.indexOf('UTF-8') == -1) {
          this.showErrorMsg(
            `File encoding error , file is not utf-8 encoded`,
            Action.Upload,
            null
          );
          return;
        }
        if (content.indexOf('FRED767') >= 0) this.recordType = '-A767';
        else if (content.indexOf('FRED717') >= 0) this.recordType = '-A717';
        else {
          this.showErrorMsg(
            'File content error, File content is not for A717 or A767',
            Action.Upload,
            null
          );
          return;
        }
      }
    };
  }

  getParameterDifferences(dataFrameVersionID,fileName){
    this.loadingCount++
    this.dataFrameService.getDataFrameParameters(dataFrameVersionID,fileName).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next:res =>{
        try {
          if (res.length == 0){

            this.loadingCount--;
            this.showWarningMsg(Action.Update,"Parameters are available for Selection");
            return;
          }
          this.availableParameters = res;
          this.availableParameters.sort();
          this.showWarningMsg(Action.Update,"Parameters are available for Selection");
          this.loadingCount--
        } catch (e) {
          this.showErrorMsg(`Data Frame not in the correct format`, Action.Update, this.messageLabel);
          this.loadingCount--;
        }
      },
      error: err => {
        this.showErrorMsg(`${err}`, Action.Update, `${this.messageLabel}`);
        this.loadingCount--
      }
    })
  }

  onMoveToTarget(event){
    this.selectedParameters.length >0 ? this.isSaveDisabled = false : this.isSaveDisabled = true
    this.selectedParameters.sort();
  }

  onMoveToSource(){
    this.availableParameters.sort();
    this.selectedParameters.length >0 ? this.isSaveDisabled = false : this.isSaveDisabled = true
  }

  onSubmit(){
    this.loadingCount++;
    let finalObject = {
      name: this.route.snapshot.queryParamMap.get('dataFrameName'),
      dataFrameID: this.route.snapshot.queryParamMap.get('dataFrameID'),
      dataFrameVersion: this.route.snapshot.queryParamMap.get('dataFrameVersion'),
      filename: this.fileName,
      listofParams: this.selectedParameters
    }
    this.dataFrameService.updateDataFrameParams(finalObject).subscribe({
      error:(err) => {
        this.showErrorMsg(err, Action.Update, `${err}`);
        this.loadingCount--;
      },
      complete: () => {
        this.loadingCount--;
        this.showSuccessMsg(Action.Update, "the Data Frame with selected parameters.");
        this.isFileSelected = false;
        this.ngOnInit();
      }
    })
  }

  onCancel(){
    this.ngOnInit();
    this.selectedParameters = [];
    this.availableParameters = [];
    this.onFileRemove();
    this.isFileSelected = false;
    this.fileUpload.clear();
    const queryParams = {
      a: this.route.snapshot.queryParamMap.get('dataFrameID'),
      Name: this.route.snapshot.queryParamMap.get('dataFrameName'),
    }
    this.router.navigate([Components.DataframeVersions.path],{queryParams}).then();
  }
  onReset(){
    this.ngOnInit();
    this.selectedParameters = [];
    this.availableParameters = [];
    this.isFileSelected = false;
  }

}
