import { Component, OnInit, ViewChild, AfterViewChecked,ChangeDetectorRef} from '@angular/core';
import {
  DisplayFormatEnum, AcquiredParams, DisplayMapping, Lcp, Euc, Txd, SignedMapping, Signed,
  EuTypeEnum, EuTypeMapping, StorageFormatEnum, StorageMapping, LcpType, Eul
} from 'src/app/features/configuration/models/dataframe717-767';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractControl, NgForm, ValidatorFn, Validators } from '@angular/forms';
import { TransferdataService } from 'src/app/features/configuration/services/transferdata.service';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { Action, BaseViewComponent } from 'src/app/common/components/base-view.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DataFrameService } from 'src/app/features/configuration/services/data-frame.service';
import { Components } from '../../../integration/configuration.components';
import { takeUntil } from 'rxjs';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { permissions } from '../../../integration/configuration.permissions';
import { FormInputBase } from '../../dataframe-acms/input-model/input/form-input-base';
import { FormDropdown } from '../../dataframe-acms/input-model/input/form-dropdown';
import { FormTextbox } from '../../dataframe-acms/input-model/input/form-textbox';
import { enumSelector } from '../../dataframe-acms/dataframe/shared/helpers/enum.helper';
import { ValidationEnum } from '../../dataframe-acms/dataframe/shared/enums/validationEnum';

@Component({
  selector: 'app-addparameter717v2',
  templateUrl: 'parameter717.component.html'
})
export class Parameter717Component extends BaseViewComponent implements OnInit, AfterViewChecked {
  title = '';
  myForm: FormInputBase<string | boolean>[] = [];
  dataSource: any[] = [];
  @ViewChild('LCPForm') LCPForm!: NgForm;
  @ViewChild('EUForm') EUForm!: NgForm;
  @ViewChild('DiscreteForm') DiscreteForm!: NgForm;
  templateToShare: any;
  tabData: any = {};
  context = { name: 'Angular' };
  message: any;
  order: any;
  accordians: string[] = ["General"];
  selectedValue: any;
  storageFormat: any;
  displaySelectedValue: any;
  displayFormat: any;
  SelectedEuType: any;
  euType: any;
  data: any;
  options: any;
  cols: any;
  empty: boolean = false;
  euCols: any;
  disCols: any;
  colsdata: Lcp[] = [];
  euColsdata: Euc[] = [];
  disColsdata: Txd[] = [];
  euItems: Eul[] = [];
  euColItem: any;
  disableDisplayDropdown = false;
  decimalplace = true;
  minbool = false;
  maxbool = false;
  configbool = true;
  signed: any;
  SignedFormat: any;
  wordspersecond: number = 0;
  ComponentFormat: string[] = ['REGULAR', 'SUPERFRAME'];
  componentType: any;
  EUtypebool = true;
  SubFrameArray: string[] = ['1', '2', '3', '4', '1,3', '2,4', 'ALL'];
  dataparameter: AcquiredParams[] = [];
  deleteDialog = false;
  bitlength: number = 0;
  rowIndex: any;
  editRow: any;
  superFrame = "SUPERFRAME";
  timeout: any = null;
  Mnemonic = "";
  mnemonicBool = false;
  formdata: any;
  Signedvalue = 0;
  frameId: number;
  versionId: number;
  discreteBool = true;
  edited: boolean = false;
  sampleValue: any;
  currentData: any;
  recError: boolean = false;
  bitLengthBool = false;
  minIndex = [];
  isNull = false;
  frameLength: number = 1;
  referenceData: any = {};
  newRow: boolean = false;
  euIndex!: number;
  isMapView = false;
  discInputValue!: number;
  cloneMnemonic: string;
  updatedMnemonics: any[] = [];
  selectedParameter: any;
  cloneIndex: number;
  messageLabel = 'Data Frame 717';
  discRowLimit: boolean = false;
  lcpLength!: number;
  bcdVal!: number;
  digitErr: boolean = false;
  BCD_Value: any;
  firstVal: any[] = [];
  descErr: boolean = false;
  discTableErr: boolean = false;
  editedDiscValue: number;
  sampleIndex: number[] = [];
  componentIndex: number[] = [];
  discMin: any[] = [];
  tableUpdated = false;
  sampleBool = false;
  ComponentBool = false;
  partnumber: number = 0;
  bitLengthArray = [];
  bcdBool =  false;
  isAdd: boolean = false;
  isEdit: boolean = false;
  isClone: boolean = false;
  isNumberIndex = [];
  isIntegerIndex = [];
  isStringIndex = [];
  isRecording = [];
  isRaw = [];
  isDiscrete = [];

  constructor(private appService: TransferdataService, router: Router, private route: ActivatedRoute,
    breadcrumbService: BreadcrumbService, confirmationService: ConfirmationService,
    messageService: MessageService, private dataframeservice: DataFrameService, private realUserService: SecurityUserService,
    private readonly changeDetectorRef: ChangeDetectorRef,) {
    super(messageService, confirmationService, router, breadcrumbService);
    this.formdata = {};
  }

  getFormsBlock(): any[] {
    return [
      new FormTextbox({
        key: 'PRM_MNEMONIC',
        placeholder: 'Mnemonic',
        type: 'text',
        errorMessages: {
          [ValidationEnum.Required]: 'Mnemonic is required.',
          [ValidationEnum.Pattern]: 'Mnemonic should be alphanumeric.',
          [ValidationEnum.Exists]: 'Mnemonic already exist.',
        },
 //       pattern: '^[a-zA-Z0-9_-]+$*',
        maxLength: 20,
        colSize: 3,
        newRow: true,
        rowSize: 3,
        disabled: !this.referenceData.userCanManage,
        validators: [Validators.required, Validators.pattern('^[a-zA-Z0-9_-]+$')]
      }),
      new FormTextbox({
        key: 'PRM_NAME',
        placeholder: 'Name',
        type: 'text',
        maxLength: 64,
        colSize: 3,
       // pattern: '.*\S.*',
        errorMessages: {
          [ValidationEnum.Pattern]: 'Name cannot be left blank',
          [ValidationEnum.Required]: 'Name is required',
        },
        disabled: !this.referenceData.userCanManage,
        validators: [Validators.required]
      }),
      new FormTextbox({
        key: 'PRM_DESCRIPTION',
        placeholder: 'Description',
        type: 'text',
        maxLength: 64,
        colSize: 6,
        errorMessages: {
          [ValidationEnum.Pattern]: 'Description cannot be left blank',
          [ValidationEnum.Required]: 'Description is required'
        },
        disabled: !this.referenceData.userCanManage,
        validators: [Validators.required]
      })
    ]
  }
  
  getBreadCrums() {
    this.breadcrumbService.setItems(this.route, [{ label: Components.Dataframe.label, routerLink: Components.Dataframe.path },
    {
      label: `Versions (${this.route.snapshot.queryParamMap.get('Name')})`, routerLink: Components.DataframeVersions.path, queryParams:
      {
        a: this.route.snapshot.queryParamMap.get('frame'),
        Name: this.route.snapshot.queryParamMap.get('Name')
      }
    }, {
      label: this.route.snapshot.queryParamMap.get('Name') + ` (Version ${this.route.snapshot.queryParamMap.get('version_index')})`,
      routerLink: Components.ListView717.path, queryParams: {
        Name: this.route.snapshot.queryParamMap.get('Name'),
        frame: this.route.snapshot.queryParamMap.get('frame'),
        version: this.route.snapshot.queryParamMap.get('version'),
        version_index: this.route.snapshot.queryParamMap.get('version_index')
      }
    },
    { label: this.title }])
    this.frameId = parseInt(this.route.snapshot.queryParamMap.get('frame'));
    this.versionId = parseInt(this.route.snapshot.queryParamMap.get('version'));
  }

  ngOnInit() {
    this.isAdd = this.route.snapshot.queryParamMap.get('add') === '1' ? true : false;
    this.isEdit = this.route.snapshot.queryParamMap.get('editName') ? true : false;
    this.isClone = this.route.snapshot.queryParamMap.get('clone') === '1' ? true : false;
    this.setTitle();
    this.getBreadCrums();
    this.userCanManage = this.realUserService.userHasPermission(permissions.dataframes.data_frame_manage);
    this.userCanView = this.realUserService.userHasPermission(permissions.dataframes.data_frame_view);
    this.disableDisplayDropdown = false;
    this.referenceData["userCanManage"] = this.userCanManage;
    this.referenceData["userCanView"] = this.userCanView;
    this.referenceData["disableDisplayDropdown"] = this.disableDisplayDropdown;
    this.referenceData["decimalplace"] = this.decimalplace;
    this.disColsdata = [];
    this.cols = [
      { field: 'LCP_SAMPLE_NUMBER', header: 'Sample Number' },
      { field: 'LCP_WORD_NB', header: 'Sample Word Number' },
      { field: 'LCP_SUBFRAME', header: 'Sub Frame' },
      { field: 'LCP_BIT_SOURCE_LSB', header: 'Sample Starting Bit' },
      { field: 'LCP_BIT_LENGTH', header: 'Sample Bit Length' },
      { field: 'PRM_RATE', header: 'Sample Rate' },
      { field: 'LCP_MODULO', header: 'Sample Frame Number of Superframe' },
      { field: 'LCP_TYPE', header: 'Sample Component Type' }
    ];

    this.euCols = [
      { field: 'EUC_COEFF_0', header: 'Coeff 0' },
      { field: 'EUC_COEFF_EXP_0', header: 'Exp 0' },
      { field: 'EUC_COEFF_1', header: 'Coeff 1' },
      { field: 'EUC_COEFF_EXP_1', header: 'Exp 1' },
      { field: 'EUC_COEFF_2', header: 'Coeff 2' },
      { field: 'EUC_COEFF_EXP_2', header: 'Exp 2' },
      { field: 'EUC_COEFF_3', header: 'Coeff 3' },
      { field: 'EUC_COEFF_EXP_3', header: 'Exp 3' },
      { field: 'EUC_COEFF_4', header: 'Coeff 4' },
      { field: 'EUC_COEFF_EXP_4', header: 'Exp 4' },
      { field: 'EUC_COEFF_5', header: 'Coeff 5' },
      { field: 'EUC_COEFF_EXP_5', header: 'Exp 5' },
    ];

    this.disCols = [
      { field: 'TXD_RAW_DATA_VALUE', header: 'Raw Value' },
      { field: 'TXD_DESCRIPTION', header: 'Associated Text' },

    ];
    this.euColItem = [
      { field: ' EUL_RAW_DATA_VALUE', header: 'Raw Data Value' },
      { field: ' EUL_EU_VALUE', header: 'EU Value' },

    ];
    this.cloneMnemonic = this.route.snapshot.queryParamMap.get('editName');
    this.getDataFrame(this.frameId, this.versionId);
    this.myForm = this.getFormsBlock();
  }

  setTitle() {
    if (this.isAdd) this.title = "Add Parameter Details";
    if (this.isEdit) this.title = "Edit Parameter Details";
    if (this.isClone) this.title = "Clone Parameter Details";
  }

 ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  isSaveButtonEnabled() : boolean {
    return false;
  }

  getDataFrame(frameId, versionId) {
    this.loadingCount++;
    this.dataframeservice.IdentifyDataFrame(frameId, versionId).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (data) => {
        let response = JSON.parse(data.content);
        this.wordspersecond = response.FRA.FRA_SUBFRAME_WORD_NB
        for (var key in response.FRA.AcquiredParams) {
          for (var key1 in response.FRA.AcquiredParams[key]) {
            this.dataparameter.push(response.FRA.AcquiredParams[key][key1]);
          }
        }
        let updatedframe = JSON.parse(sessionStorage.getItem('FrameDetails'));
        if (updatedframe != (undefined || null)) {
          this.wordspersecond = updatedframe.FRA.FRA_SUBFRAME_WORD_NB;
        }
        let updatedMnemonics = JSON.parse(sessionStorage.getItem('Mnemonics'));
        for (var key in updatedMnemonics) {
          for (var key1 in updatedMnemonics[key]) {
            for (var key2 in updatedMnemonics[key][key1]) {
              if (key1 === "Edit") {
                this.dataparameter[key2] = updatedMnemonics[key][key1][key2];

              }
              if (key1 === "Clone") {
                this.dataparameter.push(updatedMnemonics[key][key1][key2]);
              }
              if (key1 === "Add") {
                this.dataparameter.push(updatedMnemonics[key][key1][key2]);

              }
              this.updatedMnemonics.push({ [key1]: { [key2]: updatedMnemonics[key][key1][key2] } });
            }
          }
        }

        this.selectedParameter = this.dataparameter.filter((parameters, index) => {
          if (parameters.PRM_MNEMONIC === this.cloneMnemonic) {
            this.cloneIndex = index;
          }
          return parameters.PRM_MNEMONIC === this.cloneMnemonic;
        });
        if (this.isEdit){
          this.setform();
        }
      },
      error: (err) => {
        this.showErrorMsg(err, Action.Get, `${this.messageLabel}`);
        this.loadingCount--;
      },
      complete: () => this.loadingCount--
    });
    this.dropdownValues();
    this.findFrameRate();
    this.checkDiscreteLen();
    this.discInputValue = Math.pow(2, this.lcpLength) - 1;
  }

  setform() {
    this.selectedParameter.map((row) => {
      this.order = row;
      this.formdata = row;
    });
    this.dropdownValues();
   // this.selectedMnemonic = this.cloneMnemonic;
    const checkLCP = this.order?.hasOwnProperty("LCP");
    const checkEUC = this.order?.hasOwnProperty("EUC");
    const checkDiscrete = this.order?.hasOwnProperty("TXD");
    const checkEUL = this.order?.hasOwnProperty("EUL");
    this.colsdata = checkLCP === true ? this.order.LCP : [];
    this.euColsdata = checkEUC === true ? this.appService.setEUC(this.order.EUC,this.euCols) : [];
    this.disColsdata = checkDiscrete === true ? this.order.TXD : [];
    this.euItems = checkEUL === true ? this.order.EUL : [];
    this.decimalplace = this.displaySelectedValue === DisplayFormatEnum.Float ? false : true;
    this.minbool = this.selectedValue === StorageFormatEnum.Discrete ? true : false;
    this.maxbool = this.selectedValue === StorageFormatEnum.Discrete ? true : false;
    this.configbool = ((this.selectedValue === StorageFormatEnum.BCD) || (this.selectedValue === StorageFormatEnum.ISO)) ? false : true;
    this.EUtypebool = this.SelectedEuType === (EuTypeEnum.Polynomial || EuTypeEnum.Table) ? false : true;
    this.tabData.PRM_PLOTLIST_DEFAULT_DISPLAY_FORMAT = this.order.PRM_PLOTLIST_DEFAULT_DISPLAY_FORMAT;
    this.tabData.PRM_RATE = this.order.PRM_RATE;
    this.tabData.PRM_MIN_OP_RANGE = this.order.PRM_MIN_OP_RANGE;
    this.tabData.PRM_MAX_OP_RANGE = this.order.PRM_MAX_OP_RANGE;
    this.colsdata.forEach((element) => {
      this.bitlength = element.LCP_BIT_LENGTH;
      this.partnumber = element.LCP_PART_NUMBER;
    })
    this.findFrameRate();
    this.colsdata.forEach((item,row)=>{
      this.editSampleNum(item.LCP_SAMPLE_NUMBER,row);
      this.editComponentNum(item.LCP_SAMPLE_NUMBER,item.LCP_PART_NUMBER,row);
    });
    this.disColsdata.forEach((item, row) => {
      this.checkBitLength(item.TXD_RAW_DATA_VALUE, row)
    })
    this.findBCD(this.tabData.PRA_CONV_CONF);
    this.isStringIndex = this.appService.validateStringValues(this.order);
    this.isNumberIndex = this.appService.validateNumericValues(this.order);
    this.isRecording = this.appService.validateRecordingValues(this.order);
    if (this.order.EUT_ID == 17) {
      this.isRaw = this.appService.validateRawValues(this.order);
    }
    if (this.order.EUT_ID == 0) {
      this.isDiscrete = this.appService.validateDiscreteValues(this.order);
    }
  }

  onKeySearch(event: any) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.executeListing(event.target.value);
      }
    }, 1000);
  }

  executeListing(value: string) {
    this.Mnemonic = value;
    const isFound = this.dataparameter.some((element, index) => {
      if (element.PRM_MNEMONIC == value) {
        return true;
      }
      return false;
    })
    this.mnemonicBool = isFound === true ? true : false;
  }

  onChange(event: any) {
    this.selectedValue = event.value;
    this.displaySelectedValue = ((this.displaySelectedValue === StorageFormatEnum.ASCII) || (this.displaySelectedValue === StorageFormatEnum.ISO) || (this.displaySelectedValue === StorageFormatEnum.Discrete)) ?
      (this.disableDisplayDropdown = true, this.decimalplace = true, this.displayFormat.find((obj: any) => { return obj.value === 5 })) : (this.disableDisplayDropdown = false, this.decimalplace = false, this.displayFormat.find((obj: any) => { return obj.value === 5 }));
    this.minbool = event.value.value === StorageFormatEnum.Discrete ? true : false;
    this.maxbool = event.value.value === StorageFormatEnum.Discrete ? true : false;
    this.configbool = ((event.value.value === StorageFormatEnum.BCD) || (event.value.value === StorageFormatEnum.ISO)) ? false : true;
    this.discreteBool = event.value.value === StorageFormatEnum.Discrete ? false : true;
    this.configbool = ((event.value.value === StorageFormatEnum.BCD) || (event.value.value === StorageFormatEnum.ISO)) ? false : true;
    this.discreteBool = event.value.value === StorageFormatEnum.Discrete ? false : true;
  }

  onEUTypeChange(event: any) {
    this.SelectedEuType = event.value;
    this.EUtypebool = event.value.value === (EuTypeEnum.Polynomial || EuTypeEnum.Table) ? false : true;
  }

  onSignedChange(event: any) {
    this.signed = event.checked;
    this.Signedvalue = this.signed === true ? Signed.true : Signed.false;
  }

  onComponentChange(event: any) {
    this.componentType = event.value;
  }
  onSubFrameChange(event: any) {}

  onDisplayChange(event: any) {
    this.displaySelectedValue = event.value;
    this.decimalplace = event.value.value === DisplayFormatEnum.Float ? false : true;
  }

  dropdownValues() {
    this.displayFormat = DisplayMapping;
    this.storageFormat = StorageMapping;
    this.euType = EuTypeMapping;
    this.SignedFormat = SignedMapping;
    this.displayFormat.sort((a,b)=>{ return a.type.localeCompare(b.type)});
    this.storageFormat.sort((a,b)=>{ return a.type.localeCompare(b.type)});
    this.euType.sort((a,b)=>{return a.type.localeCompare(b.type)});

    if (this.order) {
      this.selectedValue = this.order.FMT_ID;
      this.SelectedEuType = this.order.EUT_ID ;
      this.displaySelectedValue = ((this.selectedValue.value === StorageFormatEnum.ASCII) || (this.selectedValue.value === StorageFormatEnum.ISO) || (this.selectedValue.value === StorageFormatEnum.Discrete)) ?
        (this.disableDisplayDropdown = true, this.displayFormat.find((obj: any) => { return obj.value === 5 })) : (this.disableDisplayDropdown = false, this.displayFormat.find((obj: any) => { return obj.value === this.order.FMT_ID_DISPLAY }));
      this.decimalplace = this.displaySelectedValue?.value === DisplayFormatEnum.Float ? false : true;
      this.signed = this.SignedFormat.find((obj: any) => { return obj.value === this.order.SRT_ID }) ? true : false;
      this.Signedvalue = this.signed === true ? Signed.true : Signed.false;
    }

    this.formdata
  }

  openDeleteDialog(data: any, i: number) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete?',
      accept: () => {
        this.deleteparameter(i, data);
        this.findFrameRate();
        this.calculateBitLength();
        this.tableUpdated = true;
      }
    });
  }
  addLCPparameters(data: any) {
    let newLcp;
    if (this.colsdata.length != 0) {
      this.sampleValue = data[data.length - 1];
      newLcp = {
        "LCP_BIT_LENGTH": 1,
        "LCP_BIT_SOURCE_LSB": 1,
        "LCP_MODULO": 0,
        "LCP_PART_NUMBER": 1,
        "LCP_SAMPLE_NUMBER": this.sampleValue.LCP_SAMPLE_NUMBER + 1,
        "LCP_SUBFRAME": "ALL",
        "LCP_TYPE": LcpType.Regular,
        "LCP_WORD_NB": 1,
        "PRM_RATE": this.frameLength 
      }
    }
    else {
      newLcp = {
        "LCP_BIT_LENGTH": 1,
        "LCP_BIT_SOURCE_LSB": 1,
        "LCP_MODULO": 0,
        "LCP_PART_NUMBER": 1,
        "LCP_SAMPLE_NUMBER": 1,
        "LCP_SUBFRAME": "ALL",
        "LCP_TYPE": LcpType.Regular,
        "LCP_WORD_NB": 1,
        "PRM_RATE": this.frameLength
      }
    }
    this.colsdata.push(newLcp);
    this.tableUpdated = true;
    if ((newLcp.LCP_PART_NUMBER == (this.sampleValue?.LCP_PART_NUMBER) + 1) && (newLcp?.LCP_SAMPLE_NUMBER == this.sampleValue?.LCP_SAMPLE_NUMBER) && (this.selectedValue.value == 2)) {
      let newBitLen = (this.sampleValue.LCP_BIT_LENGTH) / (newLcp.LCP_PART_NUMBER)
      newLcp.LCP_BIT_LENGTH = newBitLen
      this.sampleValue.LCP_BIT_LENGTH = newBitLen
    }
    this.calculateBitLength();
    this.findFrameRate();
  }
  findFrameRate() {
    let rate = [];
    this.colsdata.map(row => {
      rate.push(row.LCP_SAMPLE_NUMBER);

    })
    this.tabData.PRM_RATE = new Set(rate).size;
  }
  deleteparameter(i: number, editRow: any) {
    this.sampleIndex = [];
    this.componentIndex = [];
    const foundLcp = this.colsdata.includes(editRow);
    if (foundLcp === true) {
      this.colsdata.splice(i, 1);
      this.checkUniqueSampleNum();
      this.checkUniqueComponentNum();
      this.hideDeleteDialog();
      return true;
    }

    const foundEU = this.euColsdata.includes(editRow);
    if (foundEU === true) {
      this.euColsdata.splice(i, 1);
      this.hideDeleteDialog();
      return true;
    }
    const foundDI = this.disColsdata.includes(editRow);
    if (foundDI === true) {
      this.disColsdata.splice(i, 1);
      this.hideDeleteDialog();
      return true;
    }
    const foundEI = this.euItems.includes(editRow);
    if (foundEI === true) {
      this.euItems.splice(i, 1);
      this.hideDeleteDialog();
      return true;
    }
    return false;
  }
  hideDeleteDialog() {
    this.rowIndex = null;
    this.editRow = null;
    this.deleteDialog = false;
  }
  isCellEmpty(data: any) {
    this.isNull = data === '' ? true : false;
    if (data == '') {
      this.descErr = true
      this.discTableErr = true
    } else {
      this.descErr = false
      this.discTableErr = false
    }
    return this.isNull;
  }

  addEUparameters() {
    const newEU = {
      "EUC_MIN": 0,
      "EUC_MAX": 0,
      "EUC_COEFF_0": 0,
      "EUC_COEFF_EXP_0": 0,
      "EUC_COEFF_1": 0,
      "EUC_COEFF_EXP_1": 0,
      "EUC_COEFF_2": 0,
      "EUC_COEFF_EXP_2": 0,
      "EUC_COEFF_3": 0,
      "EUC_COEFF_EXP_3": 0,
      "EUC_COEFF_4": 0,
      "EUC_COEFF_EXP_4": 0,
      "EUC_COEFF_5": 0,
      "EUC_COEFF_EXP_5": 0
    }
    if (this.euColsdata != undefined) {
      this.euColsdata.push(newEU)
      this.euIndex = this.euColsdata.length - 1;
      this.newRow = true;
      this.isNull = true;
    }
    else {
      this.euColsdata = [];
      this.euColsdata.push(newEU)
      this.euIndex = this.euColsdata.length - 1;
      this.newRow = true;
      this.isNull = true;
    }
    this.tableUpdated = true;

  }

  addEUItems() {
    let length = 0;
    if (this.euItems == undefined) {
      length = 0
    }
    else {
      if (this.euItems.length >= 0) {
        length = this.euItems.length;
      }
    }
    const newEUItem = {
      "EUL_RAW_DATA_VALUE": length,
      "EUL_EU_VALUE": length,

    }
    if (this.euItems != undefined) {
      this.euItems.push(newEUItem)
    }
    else {
      this.euItems = [];
      this.euItems.push(newEUItem)
    }
    this.tableUpdated = true;

  }
  
  isSaveButtonDisabled() : boolean {
    if((
      this.mnemonicBool === true
      || !this.formdata
      || !this.formdata.PRM_MNEMONIC
      || !this.formdata.PRM_NAME
      || this.colsdata.length === 0 
      || (this.SelectedEuType?.value === 1 && this.isNull === true )
      || this.bitLengthBool === true
      || (this.SelectedEuType?.value === 1 && this.euColsdata.length === 0)
      || (this.minbool === true && (this.disColsdata.length < 2 || this.isNull === true  ))
      || (this.SelectedEuType?.value === 17 && this.euItems.length < 2 ))
      || this.sampleIndex.length != 0
      || this.componentIndex.length!= 0
      || this.minIndex.length != 0
      || this.sampleBool
      || this.ComponentBool
      || this.digitErr
      || (this.selectedValue?.value === (2 || 4) && this.bcdBool === true )
      || this.bitLengthArray.length != 0
      || this.isStringIndex.length > 0
      || this.isNumberIndex.length > 0
      || this.isRecording.length > 0
      || this.isRaw.length > 0
      || this.isDiscrete.length > 0
      || this.tabData.PRM_MIN_OP_RANGE === null
      || this.tabData.PRM_MAX_OP_RANGE === null
      || this.tabData.PRM_MAX_OP_RANGE < this.tabData.PRM_MIN_OP_RANGE)
      {
        return true;
      }
      for (let item of this.colsdata) {
        if (!item.LCP_BIT_LENGTH
          || !item.LCP_BIT_SOURCE_LSB
          || !item.LCP_PART_NUMBER
          || !item.LCP_SAMPLE_NUMBER
          || !item.LCP_SUBFRAME
          || !item.LCP_WORD_NB) {
          return true;
        }
      }
      for (let item of this.euColsdata) {
        if (!item.EUC_COEFF_0
          || !item.EUC_COEFF_EXP_0
          || item.EUC_COEFF_1 === 0
          || item.EUC_COEFF_EXP_1 === 0
          || item.EUC_COEFF_2 === 0
          || item.EUC_COEFF_EXP_2 === 0
          || item.EUC_COEFF_3 === 0
          || item.EUC_COEFF_EXP_3 === 0
          || item.EUC_COEFF_4 === 0
          || item.EUC_COEFF_EXP_4 === 0
          || item.EUC_COEFF_5 === 0
          || item.EUC_COEFF_EXP_5 === 0) {
            return true;
          }
      }
      for (let item of this.disColsdata) {
        if (!item.TXD_DESCRIPTION || item.TXD_RAW_DATA_VALUE < 0 || item.TXD_RAW_DATA_VALUE === null) {
          return true;
        }
      }
      return false;
  }

  public ValidateMnumonic(dataparameter): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const isFound = dataparameter.some((element, index) => {
        return element.mnemonicBool;
      });
      return isFound ? { 'exists': true } : {};
    };
  }

  addDiscreteParameters(val: any) {
    let discValue = val[val.length - 1];
    let length = 0;
    if (this.disColsdata.length >= this.discInputValue) {
      this.discRowLimit = true;
    } else {
      this.discRowLimit = false;
    }
    if (this.disColsdata == undefined) {
      length = 0
    }
    else {
      if (this.disColsdata.length >= 0) {
        length = this.disColsdata.length;
      }
    }
    let newdis = {
      "TXD_RAW_DATA_VALUE": discValue ? discValue.TXD_RAW_DATA_VALUE + 1 : length,
      "TXD_DESCRIPTION": ""
    }
    if (this.disColsdata != undefined) {
      this.disColsdata.push(newdis)
    }
    else {
      this.disColsdata = [];
      this.disColsdata.push(newdis)
    }
    this.tableUpdated = true;
  }

  handleChangeEvent(eventData) {
     if (eventData.form) {
       this.formdata = eventData.form;
     }
     switch (eventData.field) {
       case 'FMT_ID':
        this.onChange(eventData);
         break;
       case 'FMT_ID_DISPLAY':
         this.onDisplayChange(eventData);
         break;
       case 'EUT_ID':
         this.onEUTypeChange(eventData);
         break;
       default:
         break;
     }
   }

  OnSubmit(data: any) {
    if (this.isAdd) {
      this.handleAdd(data);
    } 
    else if (this.isClone) {
      this.handleEdit(data, true);
    }
    else if (this.isEdit) {
      this.handleEdit(data);
    }
  }

  handleAdd(data: any) {
    let addobj: AcquiredParams = {
      PRM_MNEMONIC: data.PRM_MNEMONIC,
      PRA_CONV_CONF: this.tabData.PRA_CONV_CONF,
      PRM_PLOTLIST_DEFAULT_DISPLAY_FORMAT:  this.tabData.PRM_PLOTLIST_DEFAULT_DISPLAY_FORMAT,
      FMT_ID: this.selectedValue,
      PRM_NAME: data.PRM_NAME.toUpperCase(),
      PRM_DESCRIPTION: data.PRM_DESCRIPTION.toUpperCase(),
      PRM_UNIT: this.tabData.PRM_UNIT,
      PRM_DECIMAL_PLACES: data.PRM_DECIMAL_PLACES,
      PRM_RATE: this.tabData.PRM_RATE,
      PRM_MIN_OP_RANGE: this.tabData.PRM_MIN_OP_RANGE,
      PRM_MAX_OP_RANGE: this.tabData.PRM_MAX_OP_RANGE,
      FMT_ID_DISPLAY: this.displaySelectedValue,
      EUT_ID: this.SelectedEuType,
      SRT_ID: this.Signedvalue,
      LCP: this.colsdata,
    }
    if( data.EUT_ID === EuTypeEnum.Table){
      addobj.EUL = this.euItems
    }

     if( data.EUT_ID ===  EuTypeEnum.Polynomial){
      addobj.EUC = this.euColsdata
    }
     if( data.FMT_ID === StorageFormatEnum.Discrete){
      addobj.TXD =this.disColsdata
    }
    this.updatedMnemonics.push({ ["Add"]: { [0]: addobj } });
    sessionStorage.setItem('Mnemonics', JSON.stringify(this.updatedMnemonics));
    this.router.navigate([Components.ListView717.path], {
      queryParams: {
        Name: this.route.snapshot.queryParamMap.get('Name'),
        frame: this.route.snapshot.queryParamMap.get('frame'),
        version: this.route.snapshot.queryParamMap.get('version'),
        version_index: this.route.snapshot.queryParamMap.get('version_index')
      }, state: { name: addobj.PRM_MNEMONIC, data: addobj }
    });
  }

  handleEdit(data: any, isClone : boolean = false) {
    let updatedata: AcquiredParams = {
      PRM_MNEMONIC: data.PRM_MNEMONIC,
      PRA_SUPERFRAME: this.order.PRA_SUPERFRAME,
      PRM_PLOTLIST_DEFAULT_DISPLAY_FORMAT:  this.tabData.PRM_PLOTLIST_DEFAULT_DISPLAY_FORMAT,
      PRA_NB_FRAMES_PER_SCYCLE: this.order.PRA_NB_FRAMES_PER_SCYCLE,
      PRA_CONV_CONF: this.order.PRA_CONV_CONF,
      PRA_NUMBER_OF_LOCATION_PARTS: this.order.PRA_NUMBER_OF_LOCATION_PARTS,
      FMT_ID: this.selectedValue,
      PRM_NAME: data.PRM_NAME.toUpperCase(),
      PRM_DESCRIPTION: data.PRM_DESCRIPTION.toUpperCase(),
      PRM_UNIT: this.order.PRM_UNIT,
      PRM_RATE: this.order.PRM_RATE,
      PRM_DECIMAL_PLACES: data.PRM_DECIMAL_PLACES,
      PRM_MIN_OP_RANGE: this.order.PRM_MIN_OP_RANGE,
      PRM_MAX_OP_RANGE: this.order.PRM_MAX_OP_RANGE,
      PRM_UPDATE_DATE: this.order.PRM_UPDATE_DATE,
      PRM_PARAMETER_SIGNED: this.order.PRM_PARAMETER_SIGNED,
      FMT_ID_DISPLAY: this.displaySelectedValue,
      FTT_ID: this.order.FTT_ID,
      EUT_ID: this.SelectedEuType,
      SRT_ID: this.Signedvalue,
      EUC: this.euColsdata,
      LCP: this.colsdata,
      TXD: this.disColsdata,
    }

    if(data.EUT_ID === EuTypeEnum.Table){
      updatedata.EUL = this.euItems
    }

    if (isClone) {
      this.updatedMnemonics.push({ ["Clone"]: { [0]: updatedata } });
    } else {
      this.updatedMnemonics.push({ ["Edit"]: { [this.cloneIndex]: updatedata } });
    }

    sessionStorage.setItem('Mnemonics', JSON.stringify(this.updatedMnemonics));
    if (this.isMapView) {
      this.router.navigate([Components.MapView717.path], {
        queryParams: {
          Name: this.route.snapshot.queryParamMap.get('Name'),
          frame: this.route.snapshot.queryParamMap.get('frame'),
          version: this.route.snapshot.queryParamMap.get('version'),
          version_index: this.route.snapshot.queryParamMap.get('version_index'),
          isMapView: 1,
        },
        state: {
          data: {}
        }
      });
    } else {
      this.router.navigate([Components.ListView717.path], {
        queryParams: {
          Name: this.route.snapshot.queryParamMap.get('Name'),
          frame: this.route.snapshot.queryParamMap.get('frame'),
          version: this.route.snapshot.queryParamMap.get('version'),
          version_index: this.route.snapshot.queryParamMap.get('version_index')
        },
        state: {
          data: {}
        }
      });
    }
  }

  formReset() {
    this.sampleIndex = [];
    this.componentIndex = [];
    this.minIndex = [];
    this.tableUpdated = false;
    this.formdata.resetForm();
    this.ngOnInit();

  }

  formCancel() {
    this.router.navigate([Components.ListView717.path], {
      queryParams: {
        Name: this.route.snapshot.queryParamMap.get('Name'),
        frame: this.route.snapshot.queryParamMap.get('frame'),
        version: this.route.snapshot.queryParamMap.get('version'),
        version_index: this.route.snapshot.queryParamMap.get('version_index')
      }
    });
  }
  onRowEdit(cellValue: any) {
    this.currentData = cellValue.data;
  }

  checkBitLength(num, di) {
    this.minIndex = [];
    if (num != null) {
      var obj1 = { "index": di, "min": num }
      if (this.discMin.length != 0) {
        this.discMin.map((obj, index) => {
          if (obj.index === di) { obj.min = num; } else {
            var chek = this.discMin.find(c => c.index === di);
            if (chek === undefined) { this.discMin.push(obj1); }
          }
        })
      }
      else {
        this.discMin.push(obj1);
      }
      this.discMin.map((input) => {
        this.disColsdata.forEach((item, ind) => {
          if (input.min === item.TXD_RAW_DATA_VALUE && ind != input.index) {
            this.minIndex.push(ind);
          }
        })
      })

    }
    this.minIndex = this.minIndex.filter(function (item, index, inputArray) {
      return inputArray.indexOf(item) == index;
    });
  }
  enteredValue(data: any, i: number) {
    if (data == 0) {
      this.isNull = true;
    } else {
      this.isNull = false;
    }
    if (data != 0 && i == this.euIndex) {
      this.newRow = false
      this.isNull = false;
    }
  }
  isValueNull(data: any) {
    let re = /^[0]$/;
    if (re.test(data) == true) {
      this.isNull = true
    } else {
      this.isNull = false
    }
    return re.test(data);

  }

  checkDiscreteLen() {
    let n: number;
    this.colsdata.forEach((element) => {
      n = element.LCP_BIT_LENGTH;
      this.lcpLength = n;
    });
  }
  findBCD(data: any) {
    if(data != (null || undefined || "")){
      if(this.selectedValue.type === 'BCD'){
        this.BCD_Value = data.split("");
        let n = 0;
        for (let i = 0; i < this.BCD_Value.length; i++) {
          n = n + Number(this.BCD_Value[i]);
          if (this.BCD_Value[i] > 4) {
            this.digitErr = true;
          } else {
            this.digitErr = false;
          }
        }
        this.bcdVal = n;

      }else if(this.selectedValue.type === 'ISO#5'){
         this.bcdVal = data
      }
      this.calculateBitLength();

    }

  }

  getBitValue(e: any) {
    this.firstVal.push(e.target.value)
  }
  editSampleNum(samNum, rowindex) {
    // sample number repeat
    this.sampleIndex = [];
    if (samNum != null) {
      if (this.colsdata[0].LCP_SAMPLE_NUMBER != 1) {
        this.sampleIndex.push(0)
      }
      else if (this.colsdata[rowindex - 1]?.LCP_SAMPLE_NUMBER === samNum) {
        this.colsdata[rowindex].LCP_PART_NUMBER = this.colsdata[rowindex - 1]?.LCP_PART_NUMBER + 1;
        let sampleNumIndex = this.sampleIndex.indexOf(rowindex);
        if (sampleNumIndex > -1) {
          this.sampleIndex.splice(sampleNumIndex, 1)
        }
      }
      else if (samNum != this.colsdata[rowindex - 1]?.LCP_SAMPLE_NUMBER) {
        if (samNum === this.colsdata[rowindex - 1]?.LCP_SAMPLE_NUMBER + 1) {
          if (this.colsdata[rowindex - 1]?.LCP_SAMPLE_NUMBER === this.colsdata[rowindex + 1]?.LCP_SAMPLE_NUMBER) {
            let sampleNumIndex = this.sampleIndex.indexOf(rowindex);
            if (sampleNumIndex > -1) {
              this.sampleIndex.splice(sampleNumIndex, 1)
            }
            this.sampleIndex.push(rowindex);
          }
          else {
            this.colsdata[rowindex].LCP_PART_NUMBER = 1;
            let sampleNumIndex = this.sampleIndex.indexOf(rowindex);
            if (sampleNumIndex > -1) {
              this.sampleIndex.splice(sampleNumIndex, 1)
            }
          }
        }
        else if (samNum != this.colsdata[rowindex - 1]?.LCP_SAMPLE_NUMBER && samNum != this.colsdata[rowindex + 1]?.LCP_SAMPLE_NUMBER && this.colsdata[rowindex - 1]?.LCP_SAMPLE_NUMBER === 1) {
          this.colsdata[rowindex].LCP_PART_NUMBER = 1;
          this.sampleIndex.push(rowindex);
        }

      }

      this.checkUniqueSampleNum();
      this.findFrameRate();
      this.calculateBitLength();
    }
  }

  checkUniqueSampleNum() {
    for (let i = 1; i < this.colsdata.length; i++) {
      this.sampleBool = this.colsdata[0].LCP_SAMPLE_NUMBER != (1||0) ? true :false;
      if (this.colsdata[i]?.LCP_SAMPLE_NUMBER - this.colsdata[i - 1]?.LCP_SAMPLE_NUMBER != 1 && this.colsdata[i]?.LCP_SAMPLE_NUMBER - this.colsdata[i - 1]?.LCP_SAMPLE_NUMBER != 0) {
        this.sampleIndex.push(i)
      }
      else {
        this.sampleIndex.splice(i, 1)
      }
    }
    this.sampleIndex = [...new Set(this.sampleIndex)];
  }

  editComponentNum(samNum, ComNum, rowindex) {
    this.componentIndex = [];
    this.ComponentBool = this.colsdata[0].LCP_PART_NUMBER != (1||0) ? true :false;
    if (samNum != null && ComNum != null) {
      if (this.colsdata[0].LCP_PART_NUMBER != 1) {
        this.componentIndex.push(0)
      }
      else if ((this.colsdata[rowindex]?.LCP_SAMPLE_NUMBER - this.colsdata[rowindex - 1]?.LCP_SAMPLE_NUMBER) === 0) {
        if (ComNum != (this.colsdata[rowindex - 1]?.LCP_PART_NUMBER + 1)) {
          this.componentIndex.push(rowindex)
        }

      }
      else if ((this.colsdata[rowindex]?.LCP_SAMPLE_NUMBER - this.colsdata[rowindex - 1]?.LCP_SAMPLE_NUMBER) === 1) {
        if (ComNum != 1) {
          this.componentIndex.push(rowindex)
        }

      }

      this.checkUniqueComponentNum();


    }

  }
  checkUniqueComponentNum() {
    for (let i = 1; i < this.colsdata.length; i++) {

      if ((this.colsdata[i]?.LCP_SAMPLE_NUMBER - this.colsdata[i - 1]?.LCP_SAMPLE_NUMBER) === 0) {
        if (this.colsdata[i]?.LCP_PART_NUMBER != (this.colsdata[i - 1]?.LCP_PART_NUMBER + 1)) {
          this.componentIndex.push(i);
        }
        else {
          this.componentIndex.splice(i, 1)
        }
      }
      else if ((this.colsdata[i]?.LCP_SAMPLE_NUMBER - this.colsdata[i - 1]?.LCP_SAMPLE_NUMBER) === 1) {
        if (this.colsdata[i]?.LCP_PART_NUMBER != 1) {
          this.componentIndex.push(i);
        }
        else {
          this.componentIndex.splice(i, 1)
        }
      }
      else {
        this.componentIndex.splice(i, 1)
      }
    }
    this.componentIndex = [...new Set(this.componentIndex)];
    this.findFrameRate();
  }
  calculateBitLength(){
    this.bitLengthArray=[];
    let startingBit, bitLength, totalBitLen;
    let bitLengthDup = 0;
      if(this.colsdata[0].LCP_SAMPLE_NUMBER === 1 && this.colsdata[0].LCP_PART_NUMBER === 1){
        startingBit = this.colsdata[0].LCP_BIT_SOURCE_LSB;
        bitLength = this.colsdata[0].LCP_BIT_LENGTH;
        totalBitLen = bitLength;
      }
      for (let i = 1; i < this.colsdata.length; i++) {
       if(this.colsdata[i]?.LCP_SAMPLE_NUMBER === this.colsdata[i-1]?.LCP_SAMPLE_NUMBER + 1)  {
        if(totalBitLen === this.colsdata[i]?.LCP_BIT_LENGTH){}
        else{
          this.bitLengthArray.push(i)
        }

      }else if(this.colsdata[i]?.LCP_SAMPLE_NUMBER === this.colsdata[i-1]?.LCP_SAMPLE_NUMBER){
        bitLengthDup = bitLengthDup + this.colsdata[i]?.LCP_BIT_LENGTH;
        if( totalBitLen === bitLengthDup){
        }else{
          this.bitLengthArray.push(i)
        }
      }
    }
    if(this.selectedValue.value === (StorageFormatEnum.BCD ||StorageFormatEnum.ISO)){
      this.bcdBool = this.bcdVal == totalBitLen ?  false : true;
    }
   }

}
