import {
  FeatureComponentsDefinition,
  FeatureItem,
} from 'src/app/common/models/feature-item.model';
import { ClientpackageDataDetailsComponent } from '../components/clientpackage-data/clientpackage-data-details/clientpackage-data-details.component';
import { ClientpackageDataListComponent } from '../components/clientpackage-data/clientpackage-data-list/clientpackage-data-list.component';
import { DataRedactionDetailsComponent } from '../components/data-redactions/data-redaction-details/data-redaction-details.component';
import { DataRedactionsListComponent } from '../components/data-redactions/data-redactions-list/data-redactions-list.component';
import { AcmsreportDataDetailsComponent } from '../components/acmsreport-data/acmsreport-data-details/acmsreport-data-details.component';
import { AcmsreportDataListComponent } from '../components/acmsreport-data/acmsreport-data-list/acmsreport-data-list.component';
import { EndpointsDataDetailsComponent } from '../components/endpoints-data/endpoints-data-details/endpoints-data-details.component';
import { EndpointsDataListComponent } from '../components/endpoints-data/endpoints-data-list/endpoints-data-list.component';
import { SARRedactionDataDetailsComponent } from '../components/sarredaction-data/sarredaction-data-details/sarredaction-data-details.component';
import { SARRedactionDataListComponent } from '../components/sarredaction-data/sarredaction-data-list/sarredaction-data-list.component';
import { permissions } from './endpointdatadelivery.permissions';
import { NotificationsViewForEndpointDataDeliveryComponent } from '../components/notifications-view/notifications-view.component';
import { EndpointDataDeliveryAlertsComponent } from '../components/alerts/alerts.component';
import { EndpointDataDeliveryAlertDetailsComponent } from '../components/alert-details/alert-details.component';

const ROUTE_ROOT = 'endpointdatadelivery';

export class Components extends FeatureComponentsDefinition {
  public static readonly EndpointDataDelivery: FeatureItem = {
    label: 'Endpoints',
    icon: 'send',
    path: `${ROUTE_ROOT}/endpointslist`,
    permission: permissions.endpointData.view,
    component: EndpointsDataListComponent,
  };

  public static readonly ViewEndpointDataDelivery: FeatureItem = {
    label: 'Endpoint Details',
    path: `${ROUTE_ROOT}/viewendpoints`,
    permission: permissions.endpointData.view,
    component: EndpointsDataDetailsComponent,
  };

  public static readonly DataRedactions: FeatureItem = {
    label: 'Flight Data Redactions',
    path: `${ROUTE_ROOT}/dataRedactionsList`,
    permission: permissions.endpointdatadelivery_flight_redaction.view,
    icon: 'flight_takeoff',
    component: DataRedactionsListComponent,
  };

  public static readonly DataRedactionDetails: FeatureItem = {
    label: 'Flight Data Redaction Details',
    path: `${ROUTE_ROOT}/adddataredactions`,
    permission: permissions.datadelivery_flight_redaction.view,
    component: DataRedactionDetailsComponent,
  };

  public static readonly ACMSReportRedactionsDataDelivery: FeatureItem = {
    label: 'ACMS Report Redactions',
    icon: 'analytics',
    path: `${ROUTE_ROOT}/acmsreportredactions`,
    permission: permissions.acmsredactionData.view,
    component: AcmsreportDataListComponent,
  };

  public static readonly ViewACMSReportRedactionsDataDelivery: FeatureItem = {
    label: 'ACMS Report Details',
    icon: 'analytics',
    path: `${ROUTE_ROOT}/viewacmsreportredaction`,
    permission: permissions.acmsredactionData.view,
    component: AcmsreportDataDetailsComponent,
  };

  public static readonly SARRedactionsDataDelivery: FeatureItem = {
    label: 'SAR Redactions',
    icon: 'analytics',
    path: `${ROUTE_ROOT}/sarredactions`,
    permission: permissions.sarRedactionsData.view,
    component: SARRedactionDataListComponent,
  };

  public static readonly ViewSARRedactionsDataDelivery: FeatureItem = {
    label: 'SAR Redaction Details',
    path: `${ROUTE_ROOT}/viewsarredaction`,
    permission: permissions.sarRedactionsData.view,
    component: SARRedactionDataDetailsComponent,
  };

  public static readonly ClientpackagesDataDelivery: FeatureItem = {
    label: 'Client Packages',
    icon: 'analytics',
    path: `${ROUTE_ROOT}/clientpackages`,
    permission: permissions.clientpackagesData.view,
    component: ClientpackageDataListComponent,
  };

  public static readonly ViewClientpackagesDataDelivery: FeatureItem = {
    label: 'Client Package Details',
    path: `${ROUTE_ROOT}/viewclientpackage`,
    permission: permissions.clientpackagesData.view,
    component: ClientpackageDataDetailsComponent,
  };

  public static readonly NotificationsViewForEndpointDataDelivery: FeatureItem =
    {
      label: 'User Notifications',
      path: `${ROUTE_ROOT}/notifications`,
      permission: permissions.alert_notifications.view,
      component: NotificationsViewForEndpointDataDeliveryComponent,
    };

  public static readonly EndpointDataDeliveryAlert: FeatureItem = {
    label: 'Endpoint Data Delivery Alerts',
    path: `${ROUTE_ROOT}/ddsalerts`,
    icon: 'alarm_add',
    permission: permissions.recording_alert.view,
    component: EndpointDataDeliveryAlertsComponent,
  };

  public static readonly EndpointDataDeliveryAlertDetails: FeatureItem = {
    label: 'Endpoint Data Delivery Alert Details',
    path: `${ROUTE_ROOT}/addddsalerts`,
    permission: permissions.recording_alert.view,
    component: EndpointDataDeliveryAlertDetailsComponent,
  };
}
