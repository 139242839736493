import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { INotification } from 'src/app/common/models/notifications';

@Component({
  selector: 'app-notifications-page-left-pane',
  templateUrl: './left-pane.component.html',
  styleUrls: ['./left-pane.component.scss'],
})
export class NotificationsPageLeftPane {
  @ViewChild('sortOverlay') sortOverlay: OverlayPanel;

  @Input() notifications: INotification[];
  @Input() selectedNotifications: { [key: number]: boolean };
  @Input() selectAll: boolean;
  @Output() toggleSelectAll = new EventEmitter();
  @Output() bulkDelete = new EventEmitter();
  @Output() bulkMarkAsRead = new EventEmitter();
  @Output() toggleSelectNotification = new EventEmitter();
  @Output() openNotification = new EventEmitter<any>();
  @Output() deleteNotification = new EventEmitter<any>();
  @Output() sortOptionChange = new EventEmitter<string>();

  selectedSortOption: string = "newest";

  toggleOverlayPanel(event: Event, sortIcon: any) {
    this.sortOverlay.show(event, sortIcon);
  }

  sortByNewest() {
    this.selectedSortOption = 'newest';
    this.sortOptionChange.emit(this.selectedSortOption);
    this.sortOverlay.hide();
  }

  sortByOldest() {
    this.selectedSortOption = 'oldest';
    this.sortOptionChange.emit(this.selectedSortOption);
    this.sortOverlay.hide();
  }

  handleToggleSelectAll(event: any) {
    this.toggleSelectAll.emit(event);
  }

  handleBulkDelete() {
    this.bulkDelete.emit();
  }

  handleBulkMarkAsRead() {
    this.bulkMarkAsRead.emit();
  }

  handleToggleSelectNotification() {
    this.toggleSelectNotification.emit();
  }

  handleOpenNotification(id: any) {
    this.openNotification.emit(id);
  }

  handleDeleteNotification(id: any) {
    this.deleteNotification.emit(id);
  }

  shouldRenderNotificationRow(notification: INotification | null | undefined){
    return !!(notification?.alert_type !== null && notification?.alert_type !== undefined && notification?.alert_type?.length > 0)
  }
}
