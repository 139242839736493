import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { takeUntil } from 'rxjs';
import { RecorderService } from 'src/app/features/configuration/services/recroders.service';
import { ConfirmationService, MessageService} from 'primeng/api';
import { Location } from '@angular/common';
import { Action, BaseViewComponent } from 'src/app/common/components/base-view.component';
import { RecorderConfigModel } from 'src/app/features/configuration/models/recorderConfig';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { permissions } from '../../integration/configuration.permissions';
import {Components} from '../../integration/configuration.components';

@Component({
  selector: 'app-recorders',
  templateUrl: './recorders.component.html',
})
export class RecordersComponent extends BaseViewComponent implements OnInit {
  tableData: RecorderConfigModel[] = [];
  pageTitle = Components.Recorders.label;

  public canView: boolean;
  public canAdd: boolean;
  public canDelete: boolean;
  public canEdit: boolean;
  messageLabel="recorder";
  constructor(
    breadcrumbService: BreadcrumbService,
    messageService: MessageService,
    router: Router,
    private route: ActivatedRoute,
    private dataApiService: RecorderService,
    private realUserService: SecurityUserService,
    confirmationService: ConfirmationService
  ) {
    super(messageService, confirmationService, router, breadcrumbService);

    // Note: Service won't update breadcrumb if caller already did so.
    this.breadcrumbService.setItems(route, [
      { label: Components.Recorders.label } // No routerLink value for current page
    ]);
  }

  ngOnInit(): void {
    
      this.canView = this.realUserService.userHasPermission(permissions.recorders.view);
      this.canAdd = this.realUserService.userHasPermission(permissions.recorders.create);
      this.canEdit = this.realUserService.userHasPermission(permissions.recorders.manage);
      this.canDelete = this.realUserService.userHasPermission(permissions.recorders.delete);
      if(this.canView){
        this.loadRecordersData();
      }
    
  }

   onClickAdd() {
    if (!this.canAdd) {
      this.showErrorMsg('User is not authorized to add Recorder.', Action.Add, `${this.messageLabel}`)  
    } else {
      this.router.navigate([Components.RecordersDetails.path]).then();
    }
  }

  onClickDelete(selectedRecorder) {
    if (this.userCanDelete) {
      this.showErrorMsg('User is not authorized to delete Recorder.', Action.Delete, `${this.messageLabel}`)  
    } else {
      this.confirmationService.confirm({
        message: 'Are you sure that you want to delete the selected recorder?',
        accept: () => {
          this.dataApiService.deleteRecorder(selectedRecorder.recorder_id).subscribe({
            next: () => {
              this.showSuccessMsg(Action.Delete, `${this.messageLabel}`, `${selectedRecorder.name}`);
              // Refresh the list of recorders
              this.loadRecordersData();
            },
            error: error => {
              this.showErrorMsg(`${error}`, Action.Delete, `${this.messageLabel}`, `${selectedRecorder.name}`); 
            }
          });
        }
      });
    }
  }

   loadRecordersData() { //get all the recorders data to populate the Table
    this.loadingCount++;
    this.dataApiService.getRecorders().pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (data) => {
        const dataArray = data.map(item => ({
          ...item,
          // subframesize_name:parseInt(item.subframesize_name, 10),
          subframesize_name:isNaN(item.subframesize_name as number) ? 'Autodetect' : parseInt(item.subframesize_name, 10),
        }))


        this.tableData = dataArray;

      },
      error: (err) => {
        this.showErrorMsg(`${err}`, Action.Get, `${this.messageLabel}s`); 
        this.loadingCount--;        
      },
      complete: () => this.loadingCount--
    })
  }

  onRowSelect(event) {
      this.router.navigate([Components.RecordersDetails.path], {
        queryParams: {
          recorderID: event.data.recorder_id,
          name:event.data.name
        }
      }).then(); 
  }

 
  // CustomSort on primeng table...
  customSort(event: any) {
    event.data.sort((data1, data2) => {
      const value1 = this.getValue(data1, event.field);
      const value2 = this.getValue(data2, event.field);
      return this.compareValues(value1, value2, event.order);
    });
  }

  // Extract value of specified field from each object array ...
  private getValue(data: any, field: string): any {
    const value = data[field];
    return value !== null && value !== undefined ? value : null;
  }

  // check if values are number or string, for string values we use localeCompare ... 
  private compareValues(value1: any, value2: any, order: number): number {
    if (value1 === null && value2 !== null) return -1 * order;
    if (value1 !== null && value2 === null) return 1 * order;
    if (value1 === null && value2 === null) return 0;

    if (typeof value1 === 'number' && typeof value2 === 'number') {
      return (value1 - value2) * order;
    } else {
      value1 = value1.toString();
      value2 = value2.toString();
      return value1.localeCompare(value2) * order;
    }
  }
  
}
