import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Waypoint } from "../models/waypoint";
import { NDBNavaid } from "../models/NDBNavaid";
import { BaseService } from "../../../common/services/base.service";

@Injectable({
    providedIn: 'root'
  })
  export class EnRouteWaypointService extends BaseService {

    constructor(private http:HttpClient){
        super();
      }

      getDatesOfFiles():Observable<string[]>{
         const url = environment.apiUrl.admin + "date-catalogue"
         return this.http.get<string[]>(url)
         .pipe(
           catchError(this.httpHandleError)
         )
       }

      getEnRouteWaypoints(dte):Observable<Waypoint[]>{
         const url = `${environment.apiUrl.admin}enroute-waypoint/${dte}`
         return this.http.get<Waypoint[]>(url)
         .pipe(
            catchError(this.httpHandleError)
          );
      }

      getEnRouteWaypoint(dte, short_icao, waypoint_id): Observable<Waypoint>{
      console.log(dte, short_icao, waypoint_id)
         const url = `${environment.apiUrl.admin}enroute-waypoint/${dte}/${short_icao}/${waypoint_id}`
         return this.http.get<Waypoint>(url)
         .pipe(
            catchError(this.httpHandleError)
          );
      }
  }


  @Injectable({
    providedIn: 'root'
  })

  export class NDBNavaidService extends BaseService{
    constructor(private http: HttpClient){
      super();
    }

    getDatesOfFiles():Observable<string[]>{
      const url = environment.apiUrl.admin + "date-catalogue"
      return this.http.get<string[]>(url)
      .pipe(
        catchError(this.httpHandleError)
      )
    }

    getEnrouteNDBNavaids(dte): Observable<NDBNavaid[]>{
       const url = `${environment.apiUrl.admin}enroute-navaid-ndb/${dte}`
      // const url = `assets/temporary/${dte}-enroute-ndb.json`
      return this.http.get<NDBNavaid[]>(url)
      .pipe(
         catchError(this.httpHandleError)
       );
   }

    getEnrouteNDBNavaid(dte, short_icao, ndb_identifier): Observable<NDBNavaid>{
      const url = `${environment.apiUrl.admin}enroute-navaid-ndb/${dte}/${short_icao}/${ndb_identifier}`
      return this.http.get<NDBNavaid>(url)
      .pipe(
         catchError(this.httpHandleError)
       );
   }
  }
