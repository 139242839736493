import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { ProcedureService } from 'src/app/features/administration/services/procedure.service';
import { ApiType, NavigationInformationBaseViewComponent } from '../navigation-information-base/navigation-information-base-view.component';
import { Procedure } from "src/app/features/administration/models/procedure";
import { Components } from 'src/app/features/administration/integration/administration.components';

@Component({
  selector: 'app-procedures',
  templateUrl: './procedures.component.html'
})

export class ProceduresComponent extends NavigationInformationBaseViewComponent implements OnInit {
  pageTitle = Components.ProceduresList.label;
  file_dates: any;
  selected_date: any;
  icao: string;
  display_icao_iata: string;
  transition_identifier: string;
  procedures: Procedure[];
  selected_procedure: Procedure;

  constructor(
    private procedureService: ProcedureService,
    messageService: MessageService,
    confirmationService: ConfirmationService,
    router: Router,
    route: ActivatedRoute,
    breadcrumbService: BreadcrumbService) {
    super(messageService, confirmationService, router, breadcrumbService, route)
    this.icao = route.snapshot.queryParamMap.get('a');
    this.display_icao_iata = route.snapshot.queryParamMap.get('b');
    const dte = route.snapshot.queryParamMap.get('c');
    const file_dates = route.snapshot.queryParamMap.get('d');
    this.transition_identifier = route.snapshot.queryParamMap.get('e');

    this.selected_date = { "dte": dte };
    this.file_dates = JSON.parse(file_dates);
    this.getProcedureList(dte, this.icao, this.transition_identifier);
    this.setBreadcrumb(this.transition_identifier);
  }

  ngOnInit(): void {
    this.setBreadcrumb(this.transition_identifier)
  }

  getProcedureList(data, icao, transition_identifier) {
    const arg: ApiType = {
      request: this.procedureService.getProcedures(data, icao, transition_identifier),
      api_name: 'procedures',
      callback: this.callback.bind(this)
    }
    this.callAPI(arg);
  }

  callback(data) {
    this.data = data;
  }

  override setBreadcrumb(transition_identifier) {
    const trimRunwayIdentifier = (runwayIdentifier: string): string => {
      if (runwayIdentifier) {
        return runwayIdentifier.replace(/^RW/, '');
      }
      return '-';
    };

    super.setBreadcrumb(
      [
        {
          label: Components.AirportList.label,
          routerLink: Components.AirportList.path,
          queryParams: { a: this.selected_date["dte"] }
        },
        {
          label: Components.RunwayList.label + ` (${this.display_icao_iata})`,
          routerLink: Components.RunwayList.path,
          queryParams: {
            a: this.icao,
            b: this.display_icao_iata,
            c: this.selected_date["dte"],
            d: JSON.stringify(this.file_dates)
          }
        },
        {
          label: Components.ProceduresList.label + ` (${trimRunwayIdentifier(this.transition_identifier)})`
        }
      ]);
  }

  onRowSelect($event) {
    console.log(this.selected_procedure)
    console.log(JSON.stringify(this.selected_procedure))
    this.router.navigate(
      [Components.ProcedureDetails.path], {
      queryParams: {
        a: this.icao,
        b: this.display_icao_iata,
        c: this.selected_date["dte"],
        d: JSON.stringify(this.file_dates),
        e: this.transition_identifier,
        f: this.selected_procedure.procedure_id,
        g: this.selected_procedure.sid_star_approach_identifier
      }
    }
    )
  }

  override onChange($event): void {
    super.onChange([Components.ProceduresList.path],
      {
        queryParams: {
          a: this.icao,
          b: this.display_icao_iata,
          c: this.selected_date["dte"],
          d: JSON.stringify(this.file_dates),
          e: this.transition_identifier
        }
      });
  }
}
