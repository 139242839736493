<p-blockUI [blocked]="loadingCount > 0">
    <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<p-confirmDialog header="Confirm Deletion" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<div class="col-12 p-3 card" *ngIf="canView">
    <div class="card-header">
        <h5>{{ pageTitle }}</h5>
        <div>
            <button (click)="onClickAdd()" *ngIf="canAdd" icon="pi pi-plus" id="addButton" label="Add" pButton
                pRipple type="button"></button>
        </div>
    </div>

  
        <p-table (onRowSelect)="onRowSelect($event)" [tableStyle]="{'table-layout':''}" [filterDelay]="0" [sortOrder]="1"
            [value]="aircraftgroup" dataKey="aircraftGroupID"
            [showCurrentPageReport]="true" [paginator]="aircraftgroup.length > defaultTableRows" [rowsPerPageOptions]="[20,50,100,200]" [rows]="defaultTableRows" currentPageReportTemplate="{{currentPageReport + ' ' + pageTitle}}" 
             id="aircraftGroupTable" selectionMode="single" sortField="aircraftgroup_name">
            <ng-template pTemplate="header">
                <tr> 

                <th pSortableColumn="aircraftgroup_name" >
                    <div class="flex justify-content-start align-items-center">
                        Name
                        <p-sortIcon field="aircraftgroup_name"></p-sortIcon>
                        <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu" field="aircraftgroup_name"
                            type="text" matchMode="contains"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="aircraftgroup_description">
                    <div class="flex justify-content-start align-items-center">
                        Description
                        <p-sortIcon field="aircraftgroup_description"></p-sortIcon>
                        <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu"
                            field="aircraftgroup_description" type="text" matchMode="contains"></p-columnFilter>
                    </div>
                </th>  
                <th pSortableColumn="aircraft_registration" >
                    <div class="flex justify-content-start align-items-center">
                        Aircraft
                        <p-sortIcon field="aircraft_registration"></p-sortIcon>
                        <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu"
                            field="aircraft_registration" type="text" matchMode="contains"></p-columnFilter>
                    </div>
                </th>   
                <th></th>
            </tr>                   
        </ng-template>
        <ng-template let-aircraftGroup pTemplate="body">
            <ng-container *ngIf="toAircraftGroup(aircraftGroup); let aircraftGroup">
                <tr [pSelectableRow]="aircraftGroup">
                    <td>{{aircraftGroup.aircraftgroup_name}} </td>
                    <td>{{aircraftGroup.aircraftgroup_description}} </td>  
                    <td>{{aircraftGroup.aircraft_registration.join(', ')}}</td>
                    <td *ngIf="canDelete">
                        <div class="flex align-items-center justify-content-end">
                            <button id="deleteButton" name="deleteButton" type="button"
                                class="p-button-rounded p-button-text p-button-danger" pButton pRipple icon="pi pi-trash"
                                pTooltip="Delete..."
                                (click)="onDeleteAircraftGroup(aircraftGroup)"></button>
                        </div>
                    </td>
                </tr>
            </ng-container>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="3">No Aircraft Groups found.</td>
            </tr>
        </ng-template>
        </p-table>
        <div *ngIf="aircraftgroup.length > 0 && defaultTableRows >= aircraftgroup.length" style="text-align: center;">
            <p class="row-paging">Showing 1 to
              {{aircraftgroup.length}} of {{aircraftgroup.length}} {{pageTitle}}</p>
          </div>
     
</div>
