import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs';
import { SECURITY_CONSTANTS } from '../models/security-constants';
import { PostOrgSwitchBehavior, SecurityAuthService } from '../services/security-auth.service';
import { SecurityUserService } from '../services/security-user.service';

@Component({
  selector: 'app-org-login',
  template: `
    <div [class.hide]="!showSelector">
      <p>Select an Organization:</p>
      <app-org-selector [showFavoriteSelector]="false" [postSwitchBehavior]='postSwitchBehavior'></app-org-selector>
    </div>
  `,
  styles: [`
    .hide {
      visibility: hidden;
    }
  `]
})

export class OrgloginComponent implements OnInit {

  public postSwitchBehavior: PostOrgSwitchBehavior = PostOrgSwitchBehavior.Redirect
  public showSelector = false;

  constructor(
    private _authService: SecurityAuthService,
    private _userService: SecurityUserService
  ) { }

  public ngOnInit(): void {
    const subscription = this._userService.userInfo.subscribe(userInfo => {
      this.showSelector = false;
      let orgs = userInfo.orgs;

      if (!!orgs && orgs.length === 1) {
        this._authService.switchOrg(orgs[0], this.postSwitchBehavior);
        setTimeout(() => subscription.unsubscribe(), 0);
        return;
      }

      const favoriteStorageKey = userInfo.email + SECURITY_CONSTANTS.favorite_org_local_storage_key_suffix;
      const favoriteOrg = localStorage.getItem(favoriteStorageKey);

      if (!!orgs && orgs.includes(favoriteOrg)) {
        this._authService.switchOrg(favoriteOrg, this.postSwitchBehavior);
        setTimeout(() => subscription.unsubscribe(), 0);
        return;
      }

      this.showSelector = true;
    });
  }
}
