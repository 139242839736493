import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { BaseService } from '../../../common/services/base.service';
import { catchError, map } from 'rxjs/operators';
import { environment } from "src/environments/environment";
import { SAR, ResponseSAR } from "../models/SARRedaction";
@Injectable({
  providedIn: 'root'
})
export class SARRedactionService extends BaseService {

  constructor(
    public http: HttpClient) {
    super();
    this.baseUrl = environment.apiUrl.datadelivery;
  }

  getSARRedactions(): Observable<SAR[]> {
    let url = this.baseUrl + "sarredaction";
    return this.http.get<SAR[]>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError)
      );
  }

  getSARRedaction_by_id(redaction_id: number): Observable<SAR> {
    let url = this.baseUrl + "sarredaction" + `/${redaction_id}`;
    return this.http.get<SAR>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError)
      );
  }

  addSARRedaction(sarredaction: ResponseSAR) {
    const url = this.baseUrl + 'sarredaction';
    return this.http.post<number>(url, sarredaction, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError)
      )
  }

  updateSARRedaction(sarredaction: ResponseSAR) {
    const url = this.baseUrl + 'sarredaction';
    return this.http.put<number>(url, sarredaction, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError)
      )
  }

  public deletesarRedaction(redaction_id: number) {
    const url = this.baseUrl + 'sarredaction/' + `${redaction_id}`;
    const options = { headers: this.httpGetHeadersForJSON() }
    return this.http.delete(url, options)
      .pipe(catchError(this.httpHandleError));
  }

}
