// trim-spaces-global.directive.ts
import { Directive, HostListener, Renderer2, ElementRef } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: 'input[type=text][ngModel], input[type=email][ngModel]'
})
export class TrimSpacesGlobalDirective {

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private ngModel: NgModel
  ) {}

  @HostListener('blur')
  // Trim leading and trailing spaces
  onBlur(): void {
    const value = this.el.nativeElement.value;
    const trimmedValue = value.trim();
    this.renderer.setProperty(this.el.nativeElement, 'value', trimmedValue);
    this.ngModel.update.emit(trimmedValue);
  }
}
