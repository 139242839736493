<p-blockUI [blocked]="loadingCount > 0">
  <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<p-confirmDialog header="Confirm Deletion" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<div *ngIf="formdata" class="col-12 card p-3">
  <div class="card-header m-2">
    <h5>{{pageTitle}}</h5>
  </div>
  <form #add717dataForm="ngForm" (ngSubmit)="OnSubmit(add717dataForm);" novalidate>
    <div class="card">
      <div class="formgrid">
        <!-----FORM FIELDS----->
        <div *ngFor="let field of getFormFields(); let i = index;" style="width:100%">
          <div *ngIf="field.newRow && field.rowSize === 3" style="width:100%" class="form-grid grid">
            <div class="lg:col-{{field.colSize}}">
                <app-dynamic-form-input
                  [field]="filteredOptions[i]"
                  [form]="form"
                  (onChange)="handleChangeEvent($event)"
                  [isDetailsMode]="false"></app-dynamic-form-input>
            </div>
            <div class="lg:col-{{filteredOptions[i+1].colSize}}" *ngIf="!filteredOptions[i+1].newRow">
              <app-dynamic-form-input
                [field]="filteredOptions[i+1]"
                [form]="form"
                (onChange)="handleChangeEvent($event)"
                [isDetailsMode]="false"></app-dynamic-form-input>
            </div>
            <div class="lg:col-{{filteredOptions[i+2].colSize}}" *ngIf="!filteredOptions[i+2].newRow">
              <app-dynamic-form-input
                [field]="filteredOptions[i+2]"
                [form]="form"
                (onChange)="handleChangeEvent($event)"
                [isDetailsMode]="false"></app-dynamic-form-input>
            </div>
          </div>
        
          <div *ngIf="field.newRow && field.rowSize == 2" style="width:100%" class="form-grid grid">
            <div class="lg:col-{{field.colSize}}">
                <app-dynamic-form-input
                  [field]="filteredOptions[i]"
                  [form]="form"
                  (onChange)="handleChangeEvent($event)"
                  [isDetailsMode]="false"></app-dynamic-form-input>
            </div>
            <div class="lg:col-{{filteredOptions[i+1].colSize}}" *ngIf="!filteredOptions[i+1].newRow">
              <app-dynamic-form-input
                [field]="filteredOptions[i+1]"
                [form]="form"
                (onChange)="handleChangeEvent($event)"
                [isDetailsMode]="false"></app-dynamic-form-input>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-content></ng-content>


    <br />
    <!-----Buttons------>
   <div class="">
      <div class="flex justify-content-end">
          <span class="p-input-icon-left">
            <button pButton label="Cancel" class="p-button-text" icon="pi pi-times" type="button" id="Cancel_Btn" name="Cancel_Btn"
              (click)="formCancel()"></button>
          </span>
          <span class="p-input-icon-left">
            <button pButton type="button" label="Reset" class="p-button-text" icon="pi pi-undo" id="Reset_Btn" name="Reset_Btn"
              (click)="formReset()"></button>
          </span>
          <span class="p-input-icon-left pl-2">
            <button pButton pRipple label="Save" type="submit" icon="pi pi-check" id="Save_Btn" name="Save_Btn" [attr.disabled]="isSaveButtonDisabled ? true : null"
            (click)="saveForm()"></button>
          </span>
    </div>
   </div>
  </form>
</div>
