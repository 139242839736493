import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { Action, BaseViewComponent } from 'src/app/common/components/base-view.component';
import { RecorderRegexService } from 'src/app/features/configuration/services/recorder-regex.service';
import { takeUntil } from 'rxjs';
import { Location } from '@angular/common';
import { RecorderRegexModel } from 'src/app/features/configuration/models/recorderRegex';
import { NgForm } from '@angular/forms';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { permissions } from '../../integration/configuration.permissions';
import {Components} from '../../integration/configuration.components';

@Component({
  selector: 'app-recorder-regex-details',
  templateUrl: './recorder-regex-details.component.html'
})
export class RecorderRegexDetailsComponent extends BaseViewComponent implements OnInit {
  pageTitle = Components.RecorderRegexDetails.label;
  recorderRegex: RecorderRegexModel;
  public canEdit: boolean;
  public canAdd: boolean;
  messageLabel="recorder regex";

  @ViewChild('recorderRegexForm') recorderRegexForm: NgForm;
  constructor(
    private recorderRegexService: RecorderRegexService,
    
    messageService: MessageService,
    confirmationService: ConfirmationService,    
    router: Router,
    breadcrumbService: BreadcrumbService,
    private route: ActivatedRoute,
    private location: Location,
    private realUserService: SecurityUserService,
  ) {
    super(messageService, confirmationService, router, breadcrumbService);
        // Note: Service won't update breadcrumb if caller already did so.
        this.breadcrumbService.setItems(route, [
          { label: Components.RecorderRegexes.label, routerLink: Components.RecorderRegexes.path },
          { label: (this.route.snapshot.queryParamMap.get('recorderRegexName'))? Components.RecorderRegexDetails.label + ` (${this.route.snapshot.queryParamMap.get('recorderRegexName')})` : Components.RecorderRegexDetails.label }, 
        ]);
   }

  ngOnInit(): void {
    this.canEdit = this.realUserService.userHasPermission(permissions.regexes.manage);
    this.canAdd = this.realUserService.userHasPermission(permissions.regexes.create);
    const recorderRegexID = this.route.snapshot.queryParamMap.get('recorderRegexID')
    
    if (recorderRegexID) {
      this.loadRecorderRegexDetails(recorderRegexID);
    } else {
      if (this.canAdd) {
        this.addRecorderRegex();
      } else {
        this.showErrorMsg('User is not authorized to add Recorder Regex.', Action.Add, `${this.messageLabel}`)
        // Wait a few seconds for user to see the error, and send them back to where they came from.
        setTimeout(() => {
          this.location.back();
        }, 3000);
      }
    }
  }

  addRecorderRegex() { //when creating a new recorder regex
    // Update page title and breadcrumb with New...
    this.updateBreadcrumb('New Recorder Regex');
    this.pageTitle = 'New Recorder Regex';
    this.isNew = true;
    this.recorderRegex = new RecorderRegexModel();
    this.recorderRegex.regex_id = -1;
  }

  loadRecorderRegexDetails(recorderRegexID){ //if modifying the existing recorder
    this.loadingCount++;
    this.recorderRegexService.getRecorderRegexDetails(recorderRegexID).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (data) => {
        this.recorderRegex = data;
      },
      error: (err) => {
        this.showErrorMsg(`${err}`, Action.Get, `${this.messageLabel}`)
        this.loadingCount--;        
      },
      complete: () => this.loadingCount--
    })
  }

  onSubmit(){
    this.loadingCount++;
    if (this.isNew) {
      this.recorderRegexService.createRecorderRegex(this.recorderRegex).pipe(
        takeUntil(this.ngUnsubscribe)).subscribe({
          next: id => {
            this.recorderRegex.regex_id = id;
            this.router.navigate([], {
              relativeTo: this.route,
              queryParams: {
                recorderRegexID: this.recorderRegex.regex_id
              },
              queryParamsHandling: 'merge'
            }).then();
            this.updateBreadcrumb(Components.RecorderRegexDetails.label + ' (' + this.recorderRegex.name + ')');
          },
          error: error => {
            this.showErrorMsg(error, Action.Add, `${this.messageLabel}`);
            this.loadingCount--;
          },
          complete: () => {
            this.showSuccessMsg(Action.Add, `${this.messageLabel}`, `${this.recorderRegex.name}`);
            this.isNew = false;
            this.pageTitle = Components.RecorderRegexDetails.label;
            this.recorderRegexForm.resetForm(this.recorderRegexForm.value);
            this.loadingCount--;
          }
        }
        );
    } else {
      this.recorderRegexService.updateRecorderRegex(this.recorderRegex).pipe(
        takeUntil(this.ngUnsubscribe)).subscribe({
          next: () => {
            this.updateBreadcrumb(Components.RecorderRegexDetails.label + ' (' + this.recorderRegex.name + ')');
          },
          error: error => {
            this.showErrorMsg(error, Action.Update, `${this.messageLabel}`);
            this.loadingCount--;
          },
          complete: () => {
            this.showSuccessMsg(Action.Update, `${this.messageLabel}`, `${this.recorderRegex.name}`);
            this.recorderRegexForm.resetForm(this.recorderRegexForm.value);
            this.loadingCount--;
          }
        }
        );
    }
  }

  onCancel(){
    if(this.recorderRegexForm.dirty ) {
      this.confirmCancel(Components.RecorderRegexes.path);
    } 
    else {
      this.router.navigate([Components.RecorderRegexes.path]).then();
    }   
  }

  onReset(){
    this.recorderRegexForm.resetForm();
    this.ngOnInit();
  }
}
