import { Directive } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { User } from 'src/app/features/administration/models/user';
import { BaseComponent } from './base.component';

export enum Action {
  Add,
  Delete,
  Update,
  Get,
  Download,
  Upload,
  Info
}

@Directive()
export abstract class BaseViewComponent extends BaseComponent {

  loadingCount = 0;  // Number of functions that are loading data, block page while not zero.
  isNew = false;
  userCanAdd = false;  // Flag indicating if user can add
  userCanModify = false;  // Flag indicating if user can modify
  userCanDelete = false;  // Flag indicating if user can delete
  loggedInUser: User = null;
  userAirlineId = -1;
  userCanManage = false; // Flag indicating if user can manage data frame details
  userCanView = false; // Flag indicating if user can view data frame details
  defaultTableRows = 20; // Number of rows will be displayed in a table by default.
  currentPageReport = 'Showing {first} to {last} of {totalRecords}'; // Show number of records under each table. 

  messageVerb: Record<Action, { "Past": string, "Continuous": string }> = {
    [Action.Add]: {
      Past: 'added',
      Continuous: 'adding'
    },
    [Action.Delete]: {
      Past: 'deleted',
      Continuous: 'deleting'
    },
    [Action.Update]: {
      Past: 'updated',
      Continuous: 'updating'
    },
    [Action.Download]: {
      Past: 'downloaded',
      Continuous: 'downloading'
    },
    [Action.Upload]: {
      Past: 'uploaded',
      Continuous: 'uploading'
    },
    [Action.Get]: {
      Past: 'retrieved',
      Continuous: 'retrieving'
    },
    [Action.Info]: {
      Past: 'Info',
      Continuous: 'Info'
    }
  }

  constructor(
    //  protected userService: UserService,
    protected messageService: MessageService,
    protected confirmationService: ConfirmationService,
    protected router: Router,
    protected breadcrumbService: BreadcrumbService) {
    super();
  }

   confirmCancel(parentLink: any, queryParams?: any) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to cancel?',
      header: 'Cancel?',
      rejectButtonStyleClass: 'p-button-text',
      accept: () => {
        if (queryParams)
          this.router.navigate([parentLink], { queryParams });
        else
          this.router.navigate([parentLink]).then();
      }
    });
  }

  /**
   * 
   * @param action The action being performed (Should be from Action enum)
   * @param resource The resource the action is being performed on (e.g. aircraft)
   * @param resourceId A user-friendly unique identifier to distinguish the resource (e.g. name). 
   *                    This has been made optional to cater for instances where we are retrieving a list of item
   * @param errorDetail Further details about the error, (e.g. the error response from the HTTP request)
   */
   showErrorMsg(errorDetail: any, action: Action, resource: string, resourceId: string = "") {
    const verb = this.messageVerb[action].Continuous;
    const id = resourceId == "" ? "" : `<${resourceId}>`;
    this.messageService.add({
      key: 'message',
      severity: 'error',
      summary: 'Error',
      detail: `Error ${verb} ${resource} ${id} : ${errorDetail}`,
      life: 5000
    });
  }
  /**
   * 
   * @param action  The action being performed (Should be from Action enum)
   * @param resource  The resource the action is being performed on (e.g. aircraft)
   * @param resourceId  A user-friendly unique identifier to distinguish the resource (e.g. name)
   *                     This has been made optional to cater for instances where a list of items are being updated
   */
   showSuccessMsg(action: Action, resource: string, resourceId: string = "") {
    const verb = this.messageVerb[action].Past;
    const id = resourceId == "" ? "" : `<${resourceId}>`;
    this.messageService.add({
      key: 'message',
      severity: 'info',
      summary: 'Success',
      detail: `Successfully ${verb} ${resource} ${id}`,
      life: 5000
    });
  }

    /**
   * 
   * @param action  The action being performed (Should be from Action enum)
   * @param resource  The resource the action is being performed on (e.g. aircraft)
   * @param resourceId  A user-friendly unique identifier to distinguish the resource (e.g. name)
   *                     This has been made optional to cater for instances where a list of items are being updated
   */
   showWarningMsg(action: Action, resource: string, resourceId: string = "") {
    const id = resourceId == "" ? "" : `<${resourceId}>`;
    this.messageService.add({
      key: 'message',
      severity: 'info',
      summary: 'Info',
      detail: `${resource} ${id}`,
      life: 5000
    });
  }

   updateBreadcrumb(label: string) {
    // Update the breadcrumb
    const item = this.breadcrumbService.popItem();
    if (item) {
      this.breadcrumbService.pushItems([
        { label: label }  // No routerLink value for current page
      ]);
    }
  }
}
