import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AircraftModel } from '../models/aircraftModel';
import { BaseService } from 'src/app/common/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class AircraftModelService extends BaseService {

  aircraftModelUrl = environment.apiUrl.admin + 'aircraft-model';

  constructor(private http: HttpClient) {
    super();
  }

  getAircraftModel(aircraftModelID: number): Observable<AircraftModel> {
    const url = this.aircraftModelUrl + `/${aircraftModelID}`;
    return this.http.get<AircraftModel>(url, { headers: this.httpGetHeadersForJSON(true) })
      .pipe(catchError(this.httpHandleError)
      );
  }

  listAircraftModels(): Observable<AircraftModel[]> {
    return this.http.get<AircraftModel[]>(this.aircraftModelUrl, { headers: this.httpGetHeadersForJSON(true) })
      .pipe(map(models => models.map(model => {
        model.displayName = model.aircraftmodel_model + '-' + model.aircraftmodel_sub_model;
        return model;
      })),
        catchError(this.httpHandleError)
      );
  }

  addAircraftModel(aircraftModel: AircraftModel) {
    return this.http.post<number>(this.aircraftModelUrl, aircraftModel, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError)
      );
  }

  deleteAircraftModel(aircraftModelID: number) {
    const url = this.aircraftModelUrl + `/${aircraftModelID}`;
    return this.http.delete<AircraftModel>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError)
      );
  }

  updateAircraftModel(aircraftModel: AircraftModel) {
    const url = this.aircraftModelUrl + `/${aircraftModel.aircraftmodel_id}`;
    return this.http.put(url, aircraftModel, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError)
      );
  }

}
