<div
  id="avatarContainer"
  class="flex justify-content-center border-round-md align-items-center avatarWrapper"
>
  <img
    *ngIf="avatarToShow"
    id="avatarPreview"
    [src]="avatarToShow"
    alt="User Avatar"
  />
  <button
    *ngIf="avatarToShow !== null"
    [disabled]="IsFieldDisabled()"
    id="avatarDeleteButton"
    (click)="removeLogo()"
    class="p-button-rounded"
    pButton
    pRipple
    style="position: absolute; background-color: rgba(0, 0, 0, 0.75)"
    icon="pi pi-times"
    pTooltip="Delete avatar"
    type="button"
  ></button>
  <p-fileUpload
    *ngIf="!avatarToShow"
    [disabled]="IsFieldDisabled()"
    #avatarUpload
    id="avatarUpload"
    name="avatarUpload"
    mode="basic"
    [auto]="true"
    pTooltip="Upload Avatar"
    accept=".jpg,.png,.jpeg"
    [maxFileSize]="maxAvatarFileSize"
    (onSelect)="selectAvatar($event)"
    [ngStyle]="{ position: 'absolute' }"
    [style]="{ 'background-color': 'rgba(0, 0, 0, 0.75)' }"
    [styleClass]="'p-button-rounded'"
  >
  </p-fileUpload>
</div>
