<p-blockUI [blocked]="loadingCount > 0">
  <tdy-spinner
    style="position: absolute; left: 50%; top: 50%; z-index: 10000"
  ></tdy-spinner>
</p-blockUI>
<div class="col-12 card p-3">
  <div class="card-header m-2">
    <h5>{{ pageTitle }}</h5>
  </div>
  <div *ngIf="acmsreport" class="col-12">
    <app-acmsreport-form
      [acmsreport]="acmsreport"
      [acmsReportlist]="acmsReportlist"
      [acmsType]="acmsType"
      [fileType]="fileType"
      [jsonregex]="jsonregex"
      [uniqueError]="uniqueError"
      [avialableAcms]="avialableAcms"
      [selectedMapIDs]="selectedMapIDs"
      [picklistChange]="picklistChange"
      [isButtonDisabled]="isButtonDisabled"
      [jsonParameters]="jsonParameters"
      [showButtons]="showButtons"
      [canAdd]="false"
      [canDelete]="false"
      [canEdit]="false"
      [isNew]="false"
      [isReadonly]="true"
      [acmsreports]="acmsreports"
      [jsonParameters]="jsonParameters"
      (cancelForm)="onCancel()"
    ></app-acmsreport-form>
  </div>
</div>
