import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: 'app-user-information-form',
    templateUrl: './user-info-form.component.html'
  })
  export class UserInformationFormComponent {
    @Input() user: any;
    @Input() availOrgsOptions: any[];
    @Input() isBusinessExists: boolean;
    @Input() isReadOnly: boolean;
    @Input() canAdd: boolean;
    @Input() canEdit: boolean;
    @Input() dropdownStyle: any;
    @Input() isFieldDisabled: boolean;
    @Output() onBusinessSelection = new EventEmitter<void>();
  
    IsFieldDisabled() {
      return this.isFieldDisabled;
    }
  
    selectBusiness() {
      this.onBusinessSelection.emit()
    }
  }