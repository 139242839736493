export class DateUtil {
  public static readonly GuiDateTimeFormat = 'dd-MMM-yyyy HH:mm:ss';
  public static readonly ApiDateTimeFormat = 'yyyy-MM-dd HH:mm:ss';

  private static monthsShort: string[] = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];

  private static monthsLong: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  private static dayShort: string[] = [
    'Sun',
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat'
  ];

  private static dayLong: string[] = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ];

  /**
   * Formats a Date object or a string/number representation of a date into a user readable format, based on the format supplied. <br>
   * Available formatting options: <br>
   * <b>yyyy</b> - Year. It is represented as a four-digit number. For example, 2021. <br>
   * <b>yy</b>   - Year. It is represented as a two-digit number. For example, 21. <br>
   * <b>MMMM</b> - Month. It is represented as the full name of the month. For example, January. <br>
   * <b>MMM</b>  - Month. It is represented as a three-letter abbreviation of the name of the month. For example, Jan. <br>
   * <b>MM</b>   - Month. It is represented as a two-digit number. For example, 01 or 11. <br>
   * <b>M</b>    - Month. It is represented as a one or two-digit number. For example, 1 or 11. <br>
   * <b>dddd</b> - Day of the week. It is represented as a full name of the day of the week. For example, Wednesday. <br>
   * <b>ddd</b>  - Day of the week. It is represented as a three-letter abbreviation of the day of the week. For example, Wed. <br>
   * <b>dd</b>   - Day of the month. It is represented as a two-digit number. For example, 02 or 18. <br>
   * <b>d</b>    - Day of the month. It is represented as a one or two-digit number. For example, 2 or 18. <br>
   * <b>HH</b>   - Hour of the day using the 24-hour format [0-23]. It is represented as a two-digit number. For example, 01 or 23. <br>
   * <b>H</b>    - Hour of the day using 24-hour format [0-23]. It is represented as a one or two-digit number. For example, 1 or 23. <br>
   * <b>mm</b>   - Minutes [0-59]. It is represented as a two-digit number. For example, 01 or 59. <br>
   * <b>m</b>    - Minutes [0-59]. It is represented as a one or two-digit number. For example, 1 or 59. <br>
   * <b>ss</b>   - Seconds [0-59]. It is represented as a two-digit number. For example, 01 or 59. <br>
   * <b>s</b>    - Seconds [0-59]. It is represented as a one or two-digit number. For example, 1 or 59.
   *
   * @param inputDate - The Date object, string representation, or number representation (in milliseconds) of the date to be formatted.
   * @param format - Represents the desired format of the outputted date string.
   * @param utc - Whether to format the date using UTC date or locale date; defaults to use UTC.
   * @returns - A string that represents a formatted version of the date.
   */
  public static format(inputDate: Date | string | number, format: string, utc = true): string {
    if (inputDate == null) {
      return '';
    }
    // rrm? NOTE that this only handles format parts that are separated - yyyyMMdd will not work, but it SHOULD!

    // Convert to Date object if necessary
    if (!(inputDate instanceof Date)) {
      inputDate = new Date(inputDate);
    }

    // If no format specified, use GUI default.
    if (format == null || format.length <= 0) {
      format = this.GuiDateTimeFormat;
    }

    // Split the desired format up into the relevant pieces
    const formatSegments = format.match(/\w+/g);
    const separatorSegments = format.match(/\W+/g);

    // Populate the desired format segments with the corresponding date component
    const completedSegments: string[] = [];

    for (const segment of formatSegments) {
      // YEAR COMPONENT
      if (segment === 'yyyy') {
        completedSegments.push(utc ? inputDate.getUTCFullYear().toString() : inputDate.getFullYear().toString());
      } else if (segment === 'yy') {
        completedSegments.push(utc ? inputDate.getUTCFullYear().toString().substr(2, 2) : inputDate.getFullYear().toString().substr(2, 2));
      } else if (segment === 'MMMM') {
        completedSegments.push(utc ? this.monthsLong[inputDate.getUTCMonth()] : this.monthsLong[inputDate.getMonth()]);
      } else if (segment === 'MMM') {
        completedSegments.push(utc ? this.monthsShort[inputDate.getUTCMonth()] : this.monthsShort[inputDate.getMonth()]);
      } else if (segment === 'MM') {
        completedSegments.push(((utc ? inputDate.getUTCMonth() : inputDate.getMonth()) + 1).toString().padStart(2, '0'));
      } else if (segment === 'M') {
        completedSegments.push(((utc ? inputDate.getUTCMonth() : inputDate.getMonth()) + 1).toString());
      } else if (segment === 'dddd') {
        completedSegments.push(utc ? this.dayLong[inputDate.getUTCDay()] : this.dayLong[inputDate.getDay()]);
      } else if (segment === 'ddd') {
        completedSegments.push(utc ? this.dayShort[inputDate.getUTCDay()] : this.dayShort[inputDate.getDay()]);
      } else if (segment === 'dd') {
        completedSegments.push((utc ? inputDate.getUTCDate() : inputDate.getDate()).toString().padStart(2, '0'));
      } else if (segment === 'd') {
        completedSegments.push((utc ? inputDate.getUTCDate() : inputDate.getDate()).toString());
      } else if (segment === 'HH') {
        completedSegments.push((utc ? inputDate.getUTCHours() : inputDate.getHours()).toString().padStart(2, '0'));
      } else if (segment === 'H') {
        completedSegments.push((utc ? inputDate.getUTCHours() : inputDate.getHours()).toString());
      } else if (segment === 'mm') {
        completedSegments.push((utc ? inputDate.getUTCMinutes() : inputDate.getMinutes()).toString().padStart(2, '0'));
      } else if (segment === 'm') {
        completedSegments.push((utc ? inputDate.getUTCMinutes() : inputDate.getMinutes()).toString());
      } else if (segment === 'ss') {
        completedSegments.push((utc ? inputDate.getUTCSeconds() : inputDate.getSeconds()).toString().padStart(2, '0'));
      } else if (segment === 's') {
        completedSegments.push((utc ? inputDate.getUTCSeconds() : inputDate.getSeconds()).toString());
      } else {
        completedSegments.push(segment);
      }
    }

    // Construct final string with date segments and separator segments
    let formattedString = '';
    if (completedSegments.length > separatorSegments?.length) {
      // IF THERE ARE NO SEPARATORS AT THE BEGINNING OR END OF THE DESIRED OUTPUT
      for (let i = 0; i < completedSegments.length; i++) {
        formattedString += completedSegments[i];
        if (i !== completedSegments.length - 1) {
          formattedString += separatorSegments[i];
        }
      }
    } else if (completedSegments.length === separatorSegments?.length) {
      // IF THERE IS ONE SEPARATOR AT THE BEGINNING OR END OF THE DESIRED OUTPUT
      if (format.match(/^\W/g)) {
        for (let i = 0; i < completedSegments.length; i++) {
          formattedString += separatorSegments[i] + completedSegments[i];
        }
      } else if (format.match(/\W$/g)) {
        for (let i = 0; i < completedSegments.length; i++) {
          formattedString += completedSegments[i] + separatorSegments[i];
        }
      }
    } else if (completedSegments.length < separatorSegments?.length) {
      // IF THERE ARE SEPARATORS AT BOTH THE BEGINNING AND END OF THE DESIRED OUTPUT
      for (let i = 0; i < separatorSegments.length; i++) {
        formattedString += separatorSegments[i];
        if (i !== separatorSegments.length - 1) {
          formattedString += completedSegments[i];
        }
      }
    }

    return formattedString;
  }

  /**
   * Formats a Date object or a string/number representation of a date into UTC date/time format, based on the format supplied.
   *
   * @param inputDate - The Date object, string representation, or number representation (in milliseconds) of the date to be formatted.
   * @param format - Represents the desired format of the outputted date string.
   * @returns - A string that represents a formatted version of the date.
   */
  public static formatUtc(inputDate: Date | string | number, format: string): string {
    return DateUtil.format(inputDate, format, true);
  }

  /**
   * Formats a Date object or a string/number representation of a date into UTC date/time format used by many
   * REST API calls (yyyy-MM-dd HH:mm:ss).
   * @param inputDate - The Date object, string representation, or number representation (in milliseconds) of the date to be formatted.
   * @returns - A string that represents a formatted version of the date.
   */
  public static formatForApi(inputDate: Date | string | number): string {
    return DateUtil.format(inputDate, DateUtil.ApiDateTimeFormat, true);
  }

  /**
   * Formats a Date object or a string/number representation of a date into date/time format used by most
   * ACS GUI displays (dd-MMM-yyyy HH:mm:ss).
   * @param inputDate - The Date object, string representation, or number representation (in milliseconds) of the date to be formatted.
   * @param utc - Whether to format the date using UTC date or locale date; defaults to use UTC.
   * @returns - A string that represents a formatted version of the date.
   */
  public static formatForGui(inputDate: Date | string | number, utc = true): string {
    return DateUtil.format(inputDate, DateUtil.GuiDateTimeFormat, utc).toUpperCase();
  }

  /**
   * Gets today's date with hours, minutes, seconds, and milliseconds zeroed out.
   * @param utc Optional flag to specify UTC date or not (default is true).
   */
  public static todayDateOnly(utc = true): Date {
    const now = new Date();
    if (utc) {
      now.setUTCHours(0, 0, 0, 0);
    } else {
      now.setHours(0, 0, 0, 0);
    }
    return now;
  }

  /**
   * Gets today's date with time values 23:59:59:999
   * @param utc Optional flag to specify UTC date or not (default is true).
   */
  public static todayToLastMillisecond(utc = true): Date {
    const now = new Date();
    if (utc) {
      now.setUTCHours(23, 59, 59, 999);
    } else {
      now.setHours(23, 59, 59, 999);
    }
    return now;
  }

  /**
   * Converts a UTC date to a local date, accounting for time zone and daylight savings differences.
   * @param utcDate The date to convert.
   */
  public static convertUtcToLocal(utcDate: Date): Date {
    const localDate = new Date(utcDate);
    localDate.setMinutes(utcDate.getMinutes() - utcDate.getTimezoneOffset());
    return localDate;
  }

  /**
   * Returns flag indicating if one date is equal to another, down to the second (disregards any milliseconds).
   * @param date1 The first date.
   * @param date2 The second date.
   */
  public static equalToSeconds(date1: Date, date2: Date): boolean {
    if (!date1 || !date2) {
      return false;
    }

    // Compare not equal and use ! operator to short circuit ASAP.
    return !(date1.getFullYear() !== date2.getFullYear() ||
      date1.getMonth() !== date2.getMonth() ||
      date1.getDate() !== date2.getDate() ||
      date1.getHours() !== date2.getHours() ||
      date1.getMinutes() !== date2.getMinutes() ||
      date1.getSeconds() !== date2.getSeconds());
  }
}
