<p-blockUI [blocked]="loadingCount > 0">
    <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<div class="col-12 card p-3">
    <div class="card-header m-2">
        <h5>{{pageTitle}}</h5>
    </div>

    <div class="grid mt-2">
        <div class="col-12 lg:col-12 p-2">
            <div class="card mb-0">


                    <div class="formgrid grid">
                        <div class="lg:col-6">
                            <div class="p-float-label">
                                <p-dropdown [options]="listDataFrame1" [style]="{'width':'100%'}" placeholder="Data Frame 1"
                                [(ngModel)]="selectedDataFrame1" optionLabel="name" autoWidth="false"
                                    optionValue="dataframe_id" (onChange)="onChangeDataFrame1($event)">
                                </p-dropdown>
                                <label for="p-float-label"  > Data Frame 1</label>
                            </div> </div>
                        <div class="lg:col-6"> 
                            <div class="p-float-label">

                                <p-dropdown [options]="listDataFrameVersions1" optionLabel="version" optionValue="dataframeversion_id" [style]="{'width':'100%'}" placeholder="Data Frame 1 Version"
                                [(ngModel)]="selectedDataFrameVersion1" (onChange)="onChangeDataFrameVersion1($event)" >
                                </p-dropdown>
                                <label for="p-float-label" > Data Frame 1 Version</label>
                            </div>
                        </div>
                        <div class="lg:col-6">
                            <div class="p-float-label">
                                <p-dropdown [options]="listDataFrame2"  optionLabel="name" placeholder="Data Frame 2" [style]="{'width':'100%'}" 
                                    optionValue="dataframe_id" [(ngModel)]="selectedDataFrame2" (onChange)="onChangeDataFrame2($event)">
                                </p-dropdown>
                                <label for="p-float-label"  > Data Frame 2 </label>
                            </div> </div>
                        <div class="lg:col-6">
                            <div class="p-float-label">
                                <p-dropdown [options]="listDataFrameVersions2" placeholder="Data Frame 2 Version" [style]="{'width':'100%'}"  optionLabel="version" optionValue="dataframeversion_id"
                                     [(ngModel)]="selectedDataFrameVersion2" (onChange)="onChangeDataFrameVersion2($event)">
                                </p-dropdown>
                                <label for="p-float-label"  > Data Frame 2 Version </label>
                            </div> </div>

                            <div class="lg:col-6"> 
                                <span class="p-input-icon-left">
                                    <p-button label="Compare" icon="pi pi-tags"  
                                    [disabled]="!selectedDataFrame1 || !selectedDataFrameVersion1 || !selectedDataFrame2 || !selectedDataFrameVersion2"
                                        (click)="onClickCompare()"></p-button>
                                </span>
                            </div>

                    
                    </div>
                
               

                    
                <div class="grid mt-2 justify-content-between">

                    <div class="lg:col-6">
                        <div class="custom-panel parameter_changes mb-4">
                            <p-panel header="Data Frame Changes" [toggleable]="false" class="">
                                <textarea id="textarea1" [rows]="10" [(ngModel)]="comparisonResult_data_frame_changes"
                                    pInputTextarea readonly style="resize:none;width:100%"></textarea>
                            </p-panel>

                        </div>
                    <div class="custom-panel parameter_changes">
                       
                        <p-panel header="Parameters Added" [toggleable]="false" class="">
                            <textarea id="textarea2" [rows]="10" [(ngModel)]="comparisonResult_parameters_added"
                                pInputTextarea readonly style="resize:none;width:100%"></textarea>
                        </p-panel>
                    
                    </div>    
                    </div>
                    <div class="lg:col-5">

                    <div class="custom-panel mb-4">

                        <p-panel header="Parameters Changed" [toggleable]="false" class="">
                            <textarea id="textarea3" [rows]="10" [(ngModel)]="comparisonResult_parameters_changed"
                                pInputTextarea readonly style="resize:none;width:100%"></textarea>
                        </p-panel>

                        </div>


                    <div class="custom-panel">
                        
                        <p-panel header="Parameters Removed" [toggleable]="false" class="">
                            <textarea id="textarea4" [rows]="10" [(ngModel)]="comparisonResult_parameters_removed"
                                pInputTextarea readonly style="resize:none;width:100%"></textarea>
                        </p-panel></div>    

                    </div>

                    <div class="grid" style="width: 100%;">
                     
                   
                    </div>
                </div>
            </div>
        </div>

        

    </div>

</div>