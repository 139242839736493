<p-blockUI [blocked]="loadingCount > 0">
  <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<p-confirmDialog header="Confirm Deletion" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<div *ngIf="users != null && canView" class="col-12 card p-3">
  <div class="card-header">
    <h5>{{pageTitle}}</h5>
    <div>
      <button *ngIf="canAdd" (click)="onAdd()" id="addUserButton" name="addUserButton" icon="pi pi-plus" label="Add"
        pButton pRipple type="button"></button>
    </div>
  </div>
  <p-table (onRowSelect)="onRowSelect($event)" [value]="users" [filterDelay]="0" dataKey="userID" selectionMode="single"
    [globalFilterFields]="['userName','firstname','lastName','statusDisplayName']" [showCurrentPageReport]="true"
    [paginator]="users.length > defaultTableRows" [rowsPerPageOptions]="[20,50,100,200]" [rows]="defaultTableRows"
    currentPageReportTemplate="{{currentPageReport + ' ' + pageTitle}}" [sortOrder]="1"
    sortField="display_name" sortMode="single" [customSort]="true" (sortFunction)="customSort($event)">
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="display_name">
          <div class="flex justify-content-start align-items-center">
            Display Name
            <p-sortIcon field="display_name"></p-sortIcon>
            <p-columnFilter [showAddButton]="false" [showOperator]="false" matchMode="contains" class="ml-auto"
              display="menu" field="display_name" type="text"></p-columnFilter>
          </div>
        </th>
        <th pSortableColumn="first_name">
          <div class="flex justify-content-start align-items-center">
            First Name
            <p-sortIcon field="first_name"></p-sortIcon>
            <p-columnFilter [showAddButton]="false" [showOperator]="false" matchMode="contains" class="ml-0"
              display="menu" field="first_name" type="text"></p-columnFilter>
          </div>
        </th>
        <th pSortableColumn="last_name">
          <div class="flex justify-content-start align-items-center">
            Last Name
            <p-sortIcon field="last_name"></p-sortIcon>
            <p-columnFilter [showAddButton]="false" [showOperator]="false" matchMode="contains" class="ml-0"
              display="menu" field="last_name" type="text"></p-columnFilter>
          </div>
        </th>
        <th pSortableColumn="user_email">
          <div class="flex justify-content-start align-items-center">
            Email
            <p-sortIcon field="user_email"></p-sortIcon>
            <p-columnFilter [showAddButton]="false" [showOperator]="false" matchMode="contains" class="ml-0"
              display="menu" field="user_email" type="text"></p-columnFilter>
          </div>
        </th>
        <th pSortableColumn="business">
          <div class="flex justify-content-start align-items-center">
            Business
            <p-sortIcon field="business"></p-sortIcon>
            <p-columnFilter [showAddButton]="false" [showOperator]="false" matchMode="contains" class="ml-0"
              display="menu" field="business" type="text"></p-columnFilter>
          </div>
        </th>
        <th pSortableColumn="status">
          <div class="flex justify-content-start align-items-center">
            Status
            <p-sortIcon field="status"></p-sortIcon>
            <p-columnFilter [showAddButton]="false" [showOperator]="false" matchMode="contains" class="ml-0"
              display="menu" field="status" type="text"></p-columnFilter>
          </div>
        </th>
        <th pSortableColumn="AD_user">
          <div class=" justify-content-between align-items-center">
            Active Directory
            <p-sortIcon field="AD_user"></p-sortIcon>
          </div>
        </th>
        <th></th>
      </tr>

    </ng-template>
    <ng-template let-user pTemplate="body">
      <ng-container *ngIf="toUser(user); let user">
        <tr [pSelectableRow]="user">
          <td style="word-wrap: break-word;">
            {{user.display_name}}
          </td>
          <td style="word-wrap: break-word;">
            {{user.first_name}}
          </td>
          <td style="word-wrap: break-word;">
            {{user.last_name}}
          </td>
          <td style="word-wrap: break-word;">
            {{user.user_email}}
          </td>
          <td style="word-wrap: break-word;">
            {{user.business}}
          </td>
          <td style="word-wrap: break-word;">
            <!-- {{user.status}} -->
            <p-tag severity="success">{{user.status}}</p-tag>

            <!-- how to bind a severity status task for aseel
            https://primeng.org/table#single-column-sort
          <p-tag [value]="customer.status" [severity]="getSeverity(customer.status)"></p-tag> -->
            
          </td>
          <td style="word-wrap: break-word;">
            <p-checkbox [(ngModel)]="user.AD_user" pTooltip="Active Directory User" [binary]="true" [readonly]="true"
              name="AD_user"></p-checkbox>
          </td>
          <td style="word-wrap: break-word;">
            <div *ngIf="canDelete && (user.user_email != currentUser && user.user_email != 'CTL_Spectrum_Okta_Dev@Teledyne.com')" class="flex justify-content-end">
              <button id="deleteButton" name="deleteButton" type="button"
                class="p-button-rounded p-button-text p-button-danger" pButton pRipple icon="pi pi-trash"
                pTooltip="Delete" [disabled]="!canDelete" (click)="onDeleteUser(user)"></button>
            </div>
          </td>
        </tr>
      </ng-container>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="5">
          No user found.
        </td>
      </tr>
    </ng-template>
  </p-table>
  <div *ngIf="users.length > 0 && defaultTableRows >= users.length" style="text-align: center;">
    <p class="row-paging">Showing 1 to
      {{users.length}} of {{users.length}} {{pageTitle}}</p>
  </div>
</div>