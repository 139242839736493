<p-toast
  [showTransformOptions]="'translate(10%,1000px)'"
  [showTransitionOptions]="'2000ms ease-out'"
  key="message"
  position="top-right"
></p-toast>
<div class="layout-topbar p-shadow-4">
  <!-- <a class="layout-menu-button collapse-menu"  >
    <i class="pi pi-bars"></i>
    </a> -->
  <div class="layout-topbar-left">
    <!-- [style]="{'width': app.menuMode === 'slim' ? '9rem' : '17rem'}" -->
    <a
      class="layout-topbar-logo"
      routerLink="/"
      pTooltip="Dashboard..."
      tooltipPosition="bottom"
    >
      <!-- <img id="app-logo" src="assets/images/spectr_noun_002_35076.jpg" alt="ACES" style="height: 3rem"> -->
    </a>
    <a
      class="layout-menu-button p-shadow-6"
      [style]="{ left: app.menuMode === 'slim' ? '1.5rem' : '1.5rem' }"
      (click)="appMain.onMenuButtonClick($event)"
      pRipple
      [pTooltip]="appMain.getMenuButtonTooltip()"
    >
      <!-- <i class="pi pi-chevron-right"></i> heheh -->
      <i class="material-symbols-outlined">menu_open</i>
    </a>
  </div>

  <div
    class="layout-topbar-right"
    [ngClass]="{ 'layout-topbar-mobile-active': appMain.mobileTopbarActive }"
  >
    <div class="layout-topbar-actions-left">
      <span class="p-text-xl-left p-ml-1" style="">
        <ng-container *ngIf="showOrgSwitch$ | async; else orgLabel">
          <app-org-selector></app-org-selector>
        </ng-container>
        <ng-template #orgLabel>
          {{ orgName$ | async }}
        </ng-template>
      </span>
    </div>

    <div class="layout-topbar-actions-right">
      <ul class="layout-topbar-items">
        <li class="layout-topbarright-logo">
          <!--          <a href="https://www.teledynecontrols.com" target="_blank">-->
          <!--            <img class="p-mr-2" id="tdy-logo" src="assets/layout/images/TDY-Logo-Blue.png" alt="ACES" style="height: 2.5rem">-->
          <!-- <img class=" " id="tdy-logo" src="assets/images/teledyne-logo.png" alt="" style="    height: 40px;" > -->
          <!--          </a>-->
        </li>
        <li class="layout-topbar-item"></li>
        <li class="layout-topbar-item layout-search-item">
          <a
            class="layout-topbar-action rounded-circle"
            (click)="appMain.onTopbarItemClick($event, 'search')"
            pRipple
            pTooltip="Search"
            tooltipPosition="bottom"
          >
            <i class="material-symbols-outlined"> search</i>
          </a>
        </li>
        <li class="layout-topbar-item layout-mode-item">
          <!-- <a class="layout-topbar-action rounded-circle" (click)="appMain.onTopbarItemClick($event, 'search')" pRipple pTooltip="Dark Theme" tooltipPosition="bottom">
            <i class="pi pi-moon fs-large"></i>
          </a> -->

          <a
            class="layout-topbar-action flex justify-content-center align-items-center p-px-2 rounded-circle"
            (click)="appMain.onTopbarItemClick($event, 'themes')"
            pRipple
            pTooltip="Themes"
            tooltipPosition="bottom"
          >
            <i class="material-symbols-outlined"> dark_mode </i>
          </a>
          <ul
            class="layout-topbar-action-panel p-shadow-6"
            style="min-width: 20rem"
            [@topbarActionPanelAnimation]="'visible'"
            *ngIf="appMain.activeTopbarItem === 'themes'"
          >
            <a (click)="changeTheme('layout-light-custom.css')">
              <li class="layout-topbar-action-item">
                <i class="material-symbols-outlined pr-2">palette</i>
                <span>Light Mode</span>
              </li>
            </a>
            <a (click)="changeTheme('layout-dark-custom.css')">
              <li class="layout-topbar-action-item">
                <i class="material-symbols-outlined pr-2">palette</i>

                <span>Dark Mode</span>
              </li>
            </a>

            <a (click)="changeTheme('royal-purple.css')">
              <li class="layout-topbar-action-item">
                <i class="material-symbols-outlined pr-2">palette</i>

                <span>Data Acquisition</span>
              </li>
            </a>

            <a (click)="changeTheme('dark-orange.css')">
              <li class="layout-topbar-action-item">
                <i class="material-symbols-outlined pr-2">palette</i>

                <span>Aircraft Data Solution</span>
              </li>
            </a>

            <a (click)="changeTheme('connectivity-theme.css')">
              <li class="layout-topbar-action-item">
                <i class="material-symbols-outlined pr-2">palette</i>

                <span>Connectivity</span>
              </li>
            </a>

            <a (click)="changeTheme('data-loading.css')">
              <li class="layout-topbar-action-item">
                <i class="material-symbols-outlined pr-2">palette</i>

                <span>Data Loading</span>
              </li>
            </a>
          </ul>
        </li>

        <li class="layout-topbar-item">
          <a
            class="layout-topbar-action flex justify-content-center align-items-center p-px-2 rounded-circle"
            (click)="appMain.onTopbarItemClick($event, 'organization')"
            pRipple
            pTooltip="Organization"
            tooltipPosition="bottom"
          >
            <i class="material-symbols-outlined"> grid_view </i>
          </a>

          <div
            class="layout-topbar-action-panel p-shadow-6 user-top-div"
            style="min-width: 20rem"
            [@topbarActionPanelAnimation]="'visible'"
            *ngIf="appMain.activeTopbarItem === 'organization'"
          >
            <div
              class="flex flex-wrap justify-content-start align-items-center organization_menu"
              style="max-width: 300px"
            >
              <div class="items_organization active">TDY</div>
              <div class="items_organization">AAA</div>

              <div class="items_organization">ABC</div>

              <div class="items_organization">TDY</div>
              <div class="items_organization">AAA</div>

              <div class="items_organization">ABC</div>

              <div class="items_organization">TDY</div>
              <div class="items_organization">AAA</div>

              <div class="items_organization">ABC</div>

              <div class="items_organization">ABC</div>
              <div class="items_organization">TDY</div>
              <div class="items_organization">AAA</div>

              <div class="items_organization">ABC</div>
              <div class="items_organization">ABC</div>
              <div class="items_organization">TDY</div>
              <div class="items_organization">AAA</div>

              <div class="items_organization">ABC</div>
              <div class="items_organization">ABC</div>

              <div class="items_organization">ABC</div>
            </div>
          </div>
        </li>
        
        <li class="layout-topbar-item notifications notifications_wrapper">
          <a
            class="layout-topbar-action rounded-circle"
            (click)="appMain.onTopbarItemClick($event, 'notifications')"
            pRipple
            pTooltip="Notifications"
            tooltipPosition="bottom"
          >
            <span class="p-overlay-badge">
              <span
                id=" "
                class="p-badge p-component p-badge-danger p-badge-dot"
                *ngIf="unreadNotifications > 0"
              ></span>
              <i class="material-symbols-outlined"> notifications_active </i>
              <span
                class="p-badge p-badge-dot"
                style="
                  background-color: #ff2828;
                  min-width: 0.5rem;
                  height: 0.5rem;
                "
                 *ngIf="unreadNotifications > 0"
              >
                <span>{{ unreadNotifications }}</span>
              </span>
            </span>
          </a>

          <app-top-menu-notifications-modal
            [appMain]="appMain"
            [unreadNotifications]="unreadNotifications"
            [applications]="applications"
            [notifications]="notifications"
            [notificationsGroupedByDate]="notificationsGroupedByDate"
            (onNotificationClick)="onNotificationClick($event)"
            (handleViewAllNotificationsClick)="handleViewAllNotificationsClick()"
          >
          </app-top-menu-notifications-modal>
        </li>
        <li class="layout-topbar-item">
          <a
            *ngIf="!userAvatarLoaded"
            class="layout-topbar-action flex justify-content-center align-items-center p-px-2 rounded-circle"
            (click)="appMain.onTopbarItemClick($event, 'profile')"
            pRipple
            pTooltip="User"
            tooltipPosition="bottom"
          >
            <i class="material-symbols-outlined"> person </i>
          </a>
          <a
            *ngIf="userAvatarLoaded"
            class="layout-topbar-action flex justify-content-center align-items-center p-px-2 rounded-circle"
            (click)="appMain.onTopbarItemClick($event, 'profile')"
            pTooltip="User"
            tooltipPosition="bottom"
            style="background-color: transparent"
          >
            <img
              id="userAvatar"
              alt="User Avatar"
              [src]="userAvatar"
              style="
                height: 100%;
                width: 45px;
                object-fit: cover;
                object-position: center;
                border-radius: 50%;
              "
            />
          </a>
          <ul
            class="layout-topbar-action-panel p-shadow-6 user-top-div"
            style="min-width: 20rem"
            [@topbarActionPanelAnimation]="'visible'"
            *ngIf="appMain.activeTopbarItem === 'profile'"
          >
            <div
              class="flex align-items-center text-gray user_wrapper"
              (click)="$event.stopPropagation()"
            >
              <p-avatar styleClass="mr-3" size="xlarge">
                <img
                  *ngIf="!userAvatarLoaded"
                  src="../../../../assets/images/icoUsers.png"
                />

                <!-- <i *ngIf="!userAvatarLoaded"
                class="pi pi-user fs-large p-ml-3 p-mr-3"
                style="padding: 10px;"></i> -->
              </p-avatar>

              <img
                *ngIf="userAvatarLoaded"
                id="userAvatarSubmenu"
                alt="User Avatar"
                [src]="userAvatar"
                class="p-ml-3 p-mr-3"
                style="
                  background-color: rgba(0, 0, 0, 0.1);
                  height: 100%;
                  width: 50px;
                  object-fit: cover;
                  object-position: center;
                  border-radius: 50%;
                "
              />
              <span class="flex flex-column">
                <span class="font-bold">{{ userName$ | async }}</span>
                <span class="p-pt-1 fs-small"
                  >{{ icaoCode }} - {{ userRole }}</span
                >
              </span>
            </div>
            <a (click)="onClickUserProfile()">
              <li class="layout-topbar-action-item">
                <i class="material-symbols-outlined" style="padding-right: 10px"
                  >manage_accounts</i
                >
                <span>{{ userProfileTitle }}</span>
              </li>
            </a>
            <a (click)="onClickCurrentDetails()" *ngIf="orgType && canViewOrg">
              <li class="layout-topbar-action-item">
                <i
                  *ngIf="orgType == 1 && canViewOrg"
                  class="material-symbols-outlined p-mr-2"
                  style="padding-right: 10px"
                  >contact_mail</i
                >
                <i
                  *ngIf="(orgType == 2 || orgType == 3) && canViewOrg"
                  class="material-symbols-outlined p-mr-2"
                  style="padding-right: 10px"
                >
                  contact_mail</i
                >

                <span>{{ orgName$ | async }} Details</span>
              </li>
            </a>
            <a (click)="onLogoutClick()">
              <li class="layout-topbar-action-item">
                <i class="material-symbols-outlined" style="padding-right: 10px"
                  >logout</i
                >

                <span>Logout</span>
              </li>
            </a>
          </ul>
        </li>
        <!--        <li class="layout-topbar-item">-->
        <!--          <img class="p-mr-2" id="tdy-logo2" src="assets/layout/images/TDY-Logo-White.png" alt="ACES" style="height: 4rem">-->
        <!--        </li>-->
      </ul>
    </div>
  </div>
</div>
