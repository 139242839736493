<p-blockUI [blocked]="loadingCount > 0">
  <tdy-spinner
    style="position: absolute; left: 50%; top: 50%; z-index: 10000"
  ></tdy-spinner>
</p-blockUI>
<p-confirmDialog
  header="Confirm Deletion"
  icon="pi pi-exclamation-triangle"
></p-confirmDialog>
<div class="col-12 card">
  <div class="card-header m-2">
    <h5>{{ pageTitle }}</h5>
    <div class="p-toolbar-group-end">
      <button
        pButton
        pRipple
        label="Add"
        id="addButton"
        *ngIf="canAdd"
        icon="pi pi-plus"
        (click)="openNew()"
      ></button>
    </div>
  </div>
  <app-sarredaction-table
    [SARredactions]="SARredactions"
    [defaultTableRows]="defaultTableRows"
    [currentPageReport]="currentPageReport"
    [pageTitle]="pageTitle"
    [canAdd]="canAdd"
    [canDelete]="canDelete"
    [jsonParameters]="jsonParameters"
    [messageLabel]="messageLabel"
    [modalDialog]="modalDialog"
    [submitted]="submitted"
    (onRowSelectEvent)="onRowSelect($event)"
    (cloneSAREvent)="cloneSAR($event)"
    (deleteSAREvent)="deleteSAR($event)"
  ></app-sarredaction-table>
</div>

<div
  *ngIf="SARredactions.length > 0 && defaultTableRows >= SARredactions.length"
  style="text-align: center"
>
  <p class="row-paging">
    Showing 1 to {{ SARredactions.length }} of {{ SARredactions.length }}
    {{ pageTitle }}
  </p>
</div>
