import { Directive } from "@angular/core";
import { Router } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
import { takeUntil } from "rxjs";
import { BreadcrumbService } from "src/app/common/services/breadcrumb.service";
import { UserService } from "src/app/features/administration/services/user.service";
import { Action, BaseViewComponent } from "src/app/common/components/base-view.component";
import { IComponentCompare } from "../input-model/IComponentInput";

@Directive()
export class BaseCompareComponent extends BaseViewComponent  {
    pageTitle: string;
    acmsName: string;
    versionOptions1 : any[] = [];
    versionOptions2 : any[] = [];
    selectedV1 :number;
    selectedV2 :number;
    versionLst: any[];
    comparisonResult: any;
    model:string;
    constructor(
        messageService: MessageService,
        confirmationService: ConfirmationService,
        router: Router,
        breadcrumbService: BreadcrumbService,
        private componentInput: IComponentCompare
    ){
        super(messageService,confirmationService,router,breadcrumbService)
        this.model = componentInput.modelName;
    }

    load_Compare(Id: number,Name = "",versionList){
        this.pageTitle = this.componentInput.InputClassName;
        this.acmsName = Name;


        this.breadcrumbService.pushItems([{label: `${this.pageTitle}`}])
        
        const versionObj =  JSON.parse(versionList);

         this.versionLst = [...Object.values(versionObj)];
         
         this.selectedV1 = Id;
         this.versionOptions1 = this.versionLst;
         this.versionOptions2 = this.filterList(Id,[...this.versionLst]);
         this.selectedV2 = Math.max(...this.versionOptions2.map(x=>x["aid"]));
                   
       
      
         setTimeout(()=>this.getResult(this.selectedV1,this.selectedV2),0)
     
      
    }

    filterList(item,list: any[]){

       for(var i = 0 ; i < list.length; i++) 
        if (list[i]["aid"]=== item){
            list.splice(i,1);
            break;
         
       }
       return list;
    }
    manageSelection(selectedV1 , list, version2 ){
             
      
      /*  let lstV1: any[] = [];
        let lstV2: any[] = [];
        let selectedV2 :number;

        const maxValue = Math.max(...list.map(x=>x["v"]));
        const minValue = Math.min(...list.map(x=>x["v"]));
        const maxVersion = list.filter(x=>x["v"] == maxValue);
        const maxAidObject = list.find(item => item.v === maxValue);
        const minAidObject = list.find(item => item.v === minValue);
       
        // if first selected version is latest the second is the latest except the selected one.
        // if(selectedV1 == maxVersion[0]["aid"]){
        //    selectedV2 = list.filter(x=>x["v"] < maxValue).reverse()[0]["aid"];
        // }
        // else{
        //    selectedV2 = maxVersion[0]["aid"];
        // }

        if (selectedV1 == version2) { // If Version 1 and Version 2 are same
          // this.selectedV2 = version2 -1;
          if (selectedV1 == +maxAidObject.aid) { //If version 1 is the max
            const sortedList = list.slice().sort((a, b) => b.v - a.v);
            const secondHighestAid = sortedList[1].aid;
            selectedV2 = secondHighestAid;
          } else if (selectedV1 == minAidObject) { //If version 1 is the min
            const sortedList = list.slice().sort((a, b) => a.v - b.v);
            const secondAid = sortedList[1].aid;
            selectedV2 = secondAid;
          }

        } else {
          this.selectedV1 = selectedV1;
          this.selectedV2 = version2;
        }



        list.map(x=>{ // code responsible to pop-out the selected version ID
            if(x["aid"] == selectedV1)
                lstV1.push(x);
            else{
                lstV2.push(x);
                lstV1.push(x);
                
            }
        })

        // list.map(x=>{ // code responsible to pop-out the selected version ID
        //     if(x["aid"] == selectedV2)
        //         lstV2.push(x);
        //     else{
        //         lstV1.push(x);
        //         lstV2.push(x);
        //     }
        // })


         this.versionOptions1 = lstV1;
         this.versionOptions2 = lstV2;

        setTimeout(()=>{
            this.selectedV1 = typeof selectedV1 === 'undefined' ? this.selectedV1 : selectedV1;
            this.selectedV2=typeof selectedV2 === 'undefined' ? this.selectedV2 : selectedV2;
        
        
        }, 0 );

       */
        
    }
   
    onChange($event){
        
     this.versionOptions2 = this.filterList($event.value,[...this.versionLst]);
     setTimeout(()=>{
        this.selectedV2 = Math.max(...this.versionOptions2.map(x=>x["aid"]));
      }, 0 );
    
    
    }

    onChangeVersion2($event){

        // this.selectedV2 = !!$event.value ? $event.value : $event;

        // let lstV1: any[] = [];
        // let lstV2: any[] = [];

        // this.versionLst.map(x=>{ // code responsible to pop-out the selected version ID
        //     if(x["aid"] == this.selectedV2)
        //         lstV2.push(x);
        //     else{
        //         lstV1.push(x);
        //         lstV2.push(x);
        //     }
        // })
        // this.versionOptions1 = lstV1;
        // this.versionOptions2 = lstV2;
        // //this.manageSelection(this.selectedV1,this.versionLst, $event.value) ;
        
    }

    onClick(){
     this.getResult(this.selectedV1,this.selectedV2);
    }


    getResult(version1: number, version2: number){
        this.loadingCount++;

        this.componentInput.getDiff(version1,version2).pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
         next:(res)=> {
                     
            if(res.length == 0)
              this.comparisonResult = this.componentInput.noRecordMessage;
            else    
              this.comparisonResult = res;
       
            (<any>window).res = this.comparisonResult;
        },
         error: (err)=>{
            this.showErrorMsg(`${err}`, Action.Get, " compare 2 version");
            this.comparisonResult = "";
            this.loadingCount--;  },
         complete: ()=> this.loadingCount--
        })
    }
}