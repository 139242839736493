import {takeUntil} from 'rxjs/operators';
import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {BaseComponent} from 'src/app/common/components/base.component';
import {AppComponent} from 'src/app/app.component';
import {BreadcrumbService} from 'src/app/common/services/breadcrumb.service';
import {UserService} from 'src/app/features/administration/services/user.service';
import {User} from 'src/app/features/administration/models/user';
import {MenuService} from 'src/app/common/services/menu.service';
import {DateUtil} from 'src/app/platform/util/dateUtil';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent extends BaseComponent implements OnInit {
  loggedInUser: User = null;
  items: MenuItem[];
  utcDateTime: string = DateUtil.formatForGui(new Date());
  home: MenuItem;

  /**
   *
   * @param breadcrumbService - Used to construct the items in the breadcrumb
   * @param app - Used to set global app variables
   * @param userService - Used to retrieve the current user's info
   */
  constructor(public breadcrumbService: BreadcrumbService,
              public app: AppComponent,
              private cd: ChangeDetectorRef,
              private userService: UserService) {
    super();

    this.home = {icon: 'pi pi-home', routerLink: MenuService.Dashboard.Link};
  }

  ngOnInit() {
    // On a page refresh, the breadcrumb will be lost if not set by the current page (calling page sets breadcrumb
    // to link back to caller). So if the service already has items, it got it from storage after the refresh
    // and we set the trail here (subscription below is too late)
    if (this.breadcrumbService.AlreadySet) {
      this.items = this.breadcrumbService.getItems();
      this.cd.detectChanges();
    }

    this.breadcrumbService.ItemsHandler.pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
      this.items = response;
      this.cd.detectChanges();
    });
  }

  getBreadcrumbLeftPosition() {
    if (window.innerWidth <= 991) {
      return '0';
    } else {
      return (this.app.menuMode === 'slim') ? '4.5rem' : '17rem';
    }
  }

  onFullscreenClick() {
    if (!this.app.fullscreen) {
      this.app.enterFullscreen();
    } else {
      this.app.exitFullscreen();
    }
  }

  onLogoutClick() {
    this.app.logoutUser();
  }
}
