<p-blockUI [blocked]="loadingCount > 0">
    <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<p-confirmDialog></p-confirmDialog>

<div *ngIf="record" class="col-12 card p-3">
    <form #addForm="ngForm" (ngSubmit)="onSubmit();" novalidate>
  
      <div class="card-header m-2">
                <h5>{{pageTitle}}</h5>
            </div>

            <div class="grid mt-2">

        <div class="lg:col-6">
            
          
            <div class="col-12  ">
            <div class=" mb-0" *ngIf="isNew">
                <h6>Details</h6>
                 
                    <div class="p-float-label"> 
                        <!-- <label [style]="{'text-align':'center','width':'100%','display':'block','border':'1px solid silver', 'padding':'10px','border-radius':'5px'}">{{(record.name)? "File name: " + record.name:"File name"}}</label> -->
                        <input #Name="ngModel" [(ngModel)]="record.name"
                            [style]="{'width':'100%'}"
                            (input)="valuechange($event)"
                            [textContent]="record.name" maxlength="256" name="Name"
                            pInputText  type="text" placeholder="Name" 
                            required
                            pattern=".*\S.*"
                            name="Name"
                            /> 
                        <label for="Name"> Name</label>
                        
                    </div> 
                         
                    <div *ngIf="(Name.touched || Name.dirty) && Name.hasError('required')" class="tdy-required">Name is required</div>
                        <div *ngIf="Name.hasError('pattern')" class="tdy-required">Name cannot be blank</div>
                
            <!-- <div class="col-12" *ngIf="!isNew">
                <h5>Airlines</h5>
                <p-multiSelect name="airlines" [options]="airlines"  [(ngModel)]="selectedAirlines" optionLabel="airline_name" optionValue="airline_id" [style]="{'width':'100%'}" (onChange)="onChange($event)" ></p-multiSelect>
            </div> -->
            </div>


        </div>   </div>

        <div class="lg:col-2"><p-divider layout="vertical"></p-divider></div>
        <div class="lg:col-4">  
            
            <div class=" custom-panel">
                <h6 class=" mb-0" >Upload</h6>
                
                <p-fileUpload #ContentUpload
            (onRemove)="onFileRemove()"
            (onSelect)="onFileSelect($event)"
            [disabled]=" !fileButtonEnabled || !userCanAdd && !userCanModify"
            [showCancelButton]=false
            [showUploadButton]=false
            [fileLimit]="1"
            [multiple]="false"
            [accept]="allowedFileType"
            chooseLabel="File"
            class="col-12"
            id="FileUpload"
            [maxFileSize]="maxFileSize" mode="advanced"
            name="FileUpload" url="">
<ng-template pTemplate="toolbar">
  <button (click)="onFileRemove()"
          *ngIf="recordFile !== undefined && recordFile !== null && recordFile?.size > 0"
          [disabled]="!userCanAdd && !userCanModify  " class="p-button-rounded p-button-text p-button-danger"
          icon="pi pi-trash"
          pButton
          pRipple
          pTooltip="Remove logo..." style="float: right"
          type="button">
  </button>
</ng-template>

<ng-template pTemplate="content">
  <div class="p-text-center">
   <div *ngIf="isSaveDisabled">
      No file uploaded.
    </div>
  </div>
</ng-template>
</p-fileUpload>

</div>

</div>
</div>

         <!-- MANAGE SECTION-->
         <div class="card" *ngIf = "!isNew">
            <h6> Details</h6>
            <div  class="mb-1">
                <p-pickList [source]="availableAirlines" [target]="selectedAirlines" [responsive]="true"
                sourceHeader="Available Airlines" targetHeader="Selected Airlines" filterBy="name"
                [showSourceControls]="false" [showTargetControls]="false"
                (onMoveToTarget)="onMoveToTarget()" (onMoveToSource)="onMoveToSource($event)"
                (onMoveAllToSource)="onMoveToSource($event)" (onMoveAllToTarget)="onMoveToTarget()"
                >
                    <ng-template let-airline pTemplate="item">
                    <div>
                            <div>{{airline.name}} </div>
                    </div>
                    
                    </ng-template>
                </p-pickList>

                <div class="col-6 p-0">      <div class="tdy-required" *ngIf="(selectedAirlines.length< 1 )">
                    At least 1 Airline is required.
                  </div>  </div>
          
            </div>
        </div>
        <div class="flex justify-content-end mt-2">
            <span  class="p-input-icon-left">
                <button (click)="onCancel()" class="p-button-text" icon="pi pi-times" id="cancelButton" label="Cancel"
                    pButton pRipple type="button"></button>
            </span>
            <span  class="p-input-icon-left">
                <button (click)="onReset()" [disabled]="isSaveDisabled " class="p-button-text" icon="pi pi-undo" id="resetButton" label="Reset"
                    pButton pRipple [disabled]="!addForm.dirty" type="button"></button>
            </span>            
            <span class="p-input-icon-left pl-2">
                <button *ngIf="userCanAdd || userCanModify"
                    [disabled]="isSaveDisabled "
                    icon="pi pi-check" id="okButton" label="Save" pButton pRipple type="submit"></button>
            </span>
        </div>
       
       
    </form>
</div> 

<!-- CLONE SECTION-->
<div class="col-12 card p-3" *ngIf="isClone" >

    
    <div class="card-header m-2 "><h5>Clone Data Frame Version</h5> </div>
    
   
    
        <div class="col-12">     
        <h6>Details </h6>

            <div class="col-6 p-0">
                <!-- <div class="p-float-label"> -->
                    <input  [(ngModel)]="newCloneName"
                        [style]="{'width':'100%'}"
                         maxlength="256" name="Name"
                        pInputText  type="text" placeholder="Name" />
                    <!-- <label for="Name"> Name</label> -->
                <!-- </div> -->
              
            </div>
            <div class="col-6">
             
                <p-messages [(value)]="cloneErrMsg"></p-messages>
              
            </div>
        
            <div class="flex justify-content-end">
                <span class="p-input-icon-left">
                    <button (click)="onCancelClone()" class="p-button-text" icon="pi pi-times" id="cancelButton" label="Cancel"
                        pButton pRipple type="button"></button>
                </span>
                          
                <span class="p-input-icon-left pl-2">
                    <button *ngIf="userCanAdd || userCanModify"
                        [disabled]="newCloneName.trim()==''"
                        icon="pi pi-check" id="okButton" label="Save" pButton pRipple type="button" (click)="executeClone()"></button>
                </span>
            </div>
        </div>
      
          
          
        
    
      
</div>