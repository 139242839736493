import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import {
  ClientPackages,
  ConversionType,
  EndPoints,
  Redaction,
  ZipType,
  aircrafts,
} from 'src/app/features/datadelivery/models/ClientPackage';

@Component({
  selector: 'app-clientpackage-form',
  templateUrl: './clientpackage-form.component.html',
})
export class ClientpackageFormComponent implements OnInit {
  @Input() clientpackage: ClientPackages;
  @Input() redactionType: Redaction[] = [];
  @Input() zipType: ZipType[];
  @Input() conversionType: ConversionType[] = [];
  @Input() availableEndPoints: EndPoints[];
  @Input() availableAircraft: aircrafts[] = [];
  @Input() selectedEndPoints: EndPoints[] = [];
  @Input() selectedAircraft: aircrafts[] = [];
  @Input() selectedEndPointsIDs: number[] = [];
  @Input() selectedAircraftIDs: number[] = [];
  @Input() picklistChange = false;
  @Input() pickAircraftlistChange = false;
  @Input() isButtonDisabled = true;
  @Input() isAircraftButtonDisabled = true;
  @Input() isReadonly: boolean = true;
  @Input() showButtons: boolean = false;
  @Input() selectedRedactionId: number;
  @Input() isRedaction = true;
  @Input() aircraftGroups: SelectItem[];
  @Input() targetAircraft: aircrafts[] = [];
  @Input() selectedAircraftGroup: number;
  @Input() selectedTargetAircraftGroup: number;
  @Input() isNew: boolean = false;

  @Output() moveToTarget = new EventEmitter<{ items: EndPoints[] }>();
  @Output() moveToSource = new EventEmitter<{ items: EndPoints[] }>();
  @Output() moveAllToSource = new EventEmitter<{ items: EndPoints[] }>();
  @Output() moveAllToTarget = new EventEmitter<{ items: EndPoints[] }>();
  @Output() conversionTypeChange = new EventEmitter<{ value: number }>();
  @Output() redactionTypeChange = new EventEmitter<{ value: number }>();
  @Output() zipSelectionChange = new EventEmitter<{ value: number }>();
  @Output() aircraftMoveToTarget = new EventEmitter<{ items: aircrafts[] }>();
  @Output() aircraftMoveToSource = new EventEmitter<{ items: aircrafts[] }>();
  @Output() aircraftMoveAllToSource = new EventEmitter<{
    items: aircrafts[];
  }>();
  @Output() aircraftMoveAllToTarget = new EventEmitter<{
    items: aircrafts[];
  }>();
  @Output() changeAircraftGroup = new EventEmitter<SelectItem>();
  @Output() changeTargetAircraftGroup = new EventEmitter<SelectItem>();

  @Output() submitForm = new EventEmitter<ClientPackages>();
  @Output() cancelForm = new EventEmitter<void>();
  @Output() resetForm = new EventEmitter<void>();

  @ViewChild('clientpackageForm')
  clientpackageForm!: NgForm;
  @ViewChild('panel') panel;

  constructor() {}

  ngOnInit(): void {}

  onSubmit() {
    this.submitForm.emit(this.clientpackage);
  }

  onCancel() {
    this.cancelForm.emit();
  }

  onReset() {
    this.clientpackageForm.resetForm();
    this.resetForm.emit();
  }

  onMoveToTarget(event: { items: EndPoints[] }) {
    this.moveToTarget.emit(event);
  }

  onMoveToSource(event: { items: EndPoints[] }) {
    this.moveToSource.emit(event);
  }

  onMoveAllToSource(event: { items: EndPoints[] }) {
    this.moveAllToSource.emit(event);
  }

  onMoveAllToTarget(event: { items: EndPoints[] }) {
    this.moveAllToTarget.emit(event);
  }

  onConversionType(event: { value: number }) {
    this.conversionTypeChange.emit(event);
  }

  onRedactionType(event: { value: number }) {
    this.redactionTypeChange.emit(event);
  }

  onZipSelection(event: { value: number }) {
    this.zipSelectionChange.emit(event);
  }

  onAircraftMoveToTarget(event: { items: aircrafts[] }) {
    this.aircraftMoveToTarget.emit(event);
  }

  onAircraftMoveToSource(event: { items: aircrafts[] }) {
    this.aircraftMoveToSource.emit(event);
  }

  onAircraftMoveAllToSource(event: { items: aircrafts[] }) {
    this.aircraftMoveAllToSource.emit(event);
  }

  onAircraftMoveAllToTarget(event: { items: aircrafts[] }) {
    this.aircraftMoveAllToTarget.emit(event);
  }

  onChangeAircraftGroup(event: SelectItem) {
    this.changeAircraftGroup.emit(event);
  }

  onChangeTargetAircraftGroup(event: SelectItem) {
    this.changeTargetAircraftGroup.emit(event);
  }
}
