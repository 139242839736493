import {
  FeatureItem,
  FeatureComponentsDefinition,
} from 'src/app/common/models/feature-item.model';
import { permissions } from './datadelivery.permissions';
import { EndpointsComponent } from '../components/endpoints/endpoints.component';
import { EndpointsAddComponent } from '../components/endpoints-add/endpoints-add.component';
import { DataRedactionComponent } from '../components/dataredaction/dataredaction.component';
import { DataRedactionAddComponent } from '../components/dataredaction-add/dataredaction-add.component';
import { AcmsreportComponent } from '../components/acmsreport/acmsreport.component';
import { AcmsreportAddComponent } from '../components/acmsreport-add/acmsreport-add.component';
import { SARRedactionComponent } from '../components/sarredaction/sarredaction.component';
import { SARRedactionAddComponent } from '../components/sarredaction-add/sarredaction-add.component';
import { ClientpackageComponent } from '../components/clientpackage/clientpackage.component';
import { ClientpackageAddComponent } from '../components/clientpackage-add/clientpackage-add.component';
import { ClientapplicationComponent } from '../components/clientapplication/clientapplication.component';
import { ClientApplicationDetailsComponent } from '../components/clientapplication-details/clientapplication.details.component';
import { RecordingClientLogComponent } from '../components/recordingclientlog/recording-clientlog.component';
import { RecordingClientLogDetailComponent } from '../components/recordingclientlog-details/recordingclientlog-details.component';
import { EnhancedRedactionComponent } from '../components/enhancedredaction/enhancedredaction.component';
import { EnhancedRedactionAddComponent } from '../components/enhancedredaction-details/enhancedredaction-details.component';
import { EnhancedpackageComponent } from '../components/enhancedpackage/enhancedpackage.component';
import { EnhancedClientpackageAddComponent } from '../components/enhancedpackage-details/enhancedpackage-details.component';
import { DataDeliveryAlertsComponent } from '../components/alerts/alerts.component';
import { DataDeliveryAlertDetailsComponent } from '../components/alert-details/alert-details.component';
import { UserRecordingSubscriptionComponent } from '../components/userrecording-subscription/userrecording-subscription.component';
import { NotificationsViewForDataDeliveryComponent } from '../components/notifications-view/notifications-view.component';
const ROUTE_ROOT = 'datadelivery';

export class Components extends FeatureComponentsDefinition {
  // dds

  public static readonly Endpoints: FeatureItem = {
    label: 'Endpoints',
    icon: 'send',
    path: `${ROUTE_ROOT}/endpoints`,
    permission: permissions.endpoint.view,
    component: EndpointsComponent,
  };

  public static readonly AddEndpoints: FeatureItem = {
    label: 'Endpoint Details',
    path: `${ROUTE_ROOT}/addendpoints`,
    permission: permissions.endpoint.view,
    component: EndpointsAddComponent,
  };

  public static readonly DataRedactions: FeatureItem = {
    label: 'Flight Data Redactions',
    path: `${ROUTE_ROOT}/dataredactions`,
    permission: permissions.datadelivery_flight_redaction.view,
    icon: 'flight_takeoff',
    component: DataRedactionComponent,
  };

  public static readonly AddDataRedactions: FeatureItem = {
    label: 'Flight Data Redaction Details',
    path: `${ROUTE_ROOT}/adddataredactions`,
    permission: permissions.datadelivery_flight_redaction.view,
    component: DataRedactionAddComponent,
  };

  public static readonly ACMSReportRedactions: FeatureItem = {
    label: 'ACMS Report Redactions',
    path: `${ROUTE_ROOT}/acmsreportredactions`,
    permission: permissions.datadelivery_acms_redaction.view,
    icon: 'analytics',
    component: AcmsreportComponent,
  };

  public static readonly ACMSReportAddRedactions: FeatureItem = {
    label: 'ACMS Report Redaction Details',
    path: `${ROUTE_ROOT}/acmsreportaddredactions`,
    permission: permissions.datadelivery_acms_redaction.view,
    component: AcmsreportAddComponent,
  };

  public static readonly SARRedactions: FeatureItem = {
    label: 'SAR Redactions',
    path: `${ROUTE_ROOT}/sarredactions`,
    permission: permissions.datadelivery_sar_redaction.view,
    icon: 'analytics',
    component: SARRedactionComponent,
  };
  public static readonly AddSARRedactions: FeatureItem = {
    label: 'SAR Redaction Details',
    path: `${ROUTE_ROOT}/saraddredactions`,
    permission: permissions.datadelivery_sar_redaction.view,
    component: SARRedactionAddComponent,
  };

  public static readonly ClientPackages: FeatureItem = {
    label: 'Client Packages',
    path: `${ROUTE_ROOT}/clientpackages`,
    permission: permissions.datadelivery_client_packages.view,
    icon: 'folder',
    component: ClientpackageComponent,
  };
  public static readonly AddClientPackages: FeatureItem = {
    label: 'Client Package Details',
    path: `${ROUTE_ROOT}/addclientpackages`,
    permission: permissions.datadelivery_client_packages.view,
    component: ClientpackageAddComponent,
  };

  public static readonly ClientApplication: FeatureItem = {
    label: 'Client Authentication',
    path: `${ROUTE_ROOT}/clientapplication`,
    icon: 'admin_panel_settings',
    permission: permissions.datadelivery_client_authentication.view,
    component: ClientapplicationComponent,
  };
  public static readonly AddClientApplication: FeatureItem = {
    label: 'Client Authentication Details',
    path: `${ROUTE_ROOT}/addclientapplication`,
    permission: permissions.datadelivery_client_authentication.view,
    component: ClientApplicationDetailsComponent,
  };

  public static readonly RecordingClientLog: FeatureItem = {
    label: 'Recording Log',
    path: `${ROUTE_ROOT}/getclientrecordinglog`,
    icon: 'folder',
    permission: undefined,
    component: RecordingClientLogComponent,
  };

  public static readonly RecordingClientLogDetails: FeatureItem = {
    label: 'Recording Log',
    path: `${ROUTE_ROOT}/getclientrecordinglogdetails`,
    icon: 'folder',
    permission: undefined,
    component: RecordingClientLogDetailComponent,
  };

  public static readonly EnhancedRedaction: FeatureItem = {
    label: 'Enhanced Data Redactions',
    path: `${ROUTE_ROOT}/enhancedredaction`,
    icon: 'library_add',
    permission: permissions.datadelivery_enhanced_redaction.view,
    component: EnhancedRedactionComponent,
  };
  public static readonly AddEnhancedRedaction: FeatureItem = {
    label: 'Enhanced Data Redaction Details',
    path: `${ROUTE_ROOT}/addenhancedredaction`,
    permission: permissions.datadelivery_enhanced_redaction.view,
    component: EnhancedRedactionAddComponent,
  };

  public static readonly EnhancedPackages: FeatureItem = {
    label: 'Enhanced Packages',
    path: `${ROUTE_ROOT}/enhancedpackage`,
    icon: 'create_new_folder',
    permission: permissions.datadelivery_enhanced_packages.view,
    component: EnhancedpackageComponent,
  };
  public static readonly AddEnhancedPackages: FeatureItem = {
    label: 'Enhanced Package Details',
    path: `${ROUTE_ROOT}/addenhancedpackage`,
    permission: permissions.datadelivery_enhanced_packages.view,
    component: EnhancedClientpackageAddComponent,
  };

  public static readonly DataDeliveryAlert: FeatureItem = {
    label: 'Data Delivery Alerts',
    path: `${ROUTE_ROOT}/ddsalerts`,
    icon: 'alarm_add',
    permission: permissions.recording_alert.view,
    component: DataDeliveryAlertsComponent,
  };

  public static readonly DataDeliveryAlertDetails: FeatureItem = {
    label: 'Data Delivery Alert Details',
    path: `${ROUTE_ROOT}/addddsalerts`,
    permission: permissions.recording_alert.view,
    component: DataDeliveryAlertDetailsComponent,
  };

  public static readonly UserRecordingSubscription: FeatureItem = {
    label: 'User Data Delivery Subscriptions',
    path: `${ROUTE_ROOT}/userrecordingsubscription`,
    permission: permissions.recording_alert.view,
    component: UserRecordingSubscriptionComponent,
  };

  public static readonly NotificationsViewForDataDelivery: FeatureItem = {
    label: 'User Notifications',
    path: `${ROUTE_ROOT}/notifications`,
    permission: permissions.alert_notifications.view,
    component: NotificationsViewForDataDeliveryComponent,
  };
}
