<app-loading-spinner [loadingCount]="loadingCount"></app-loading-spinner>
<p-confirmDialog></p-confirmDialog>

<div *ngIf="user !== null" class="col-12 card p-3">
  <form #userForm="ngForm" (ngSubmit)="onSubmit()" novalidate>
    <h5 class="m-1 mt-2 mb-3 flex align-items-center justify-content-start">
      {{ pageTitle }}
    </h5>

    <div class="grid">
      <div class="col-2">
        <app-avatar
          [avatarToShow]="avatarToShow"
          [isFieldDisabled]="IsFieldDisabled()"
          [maxAvatarFileSize]="maxAvatarFileSize"
          (onAvatarSelect)="onAvatarSelect($event)"
          (onLogoRemove)="onLogoRemove()"
        ></app-avatar>
        <div class="p-text-center p-mt-4" style="margin-top: 10px">
          <h5 class="flex align-items-center justify-content-center">
            {{ pageSubTitle }}
          </h5>
        </div>
      </div>
      <div class="col-10">
        <div class="card pt-0 p-3">
          <p-tabView id="tabView" [(activeIndex)]="activeTabIndex">
            <p-tabPanel
              header="Properties"
              id="propertiesPanel"
              leftIcon="pi pi-book"
            >
              <app-user-information-form
                [user]="user"
                [isReadOnly]="isReadOnly"
                [availOrgsOptions]="availOrgsOptions"
                [canEdit]="canEdit"
                [canAdd]="canAdd"
                [isFieldDisabled]="IsFieldDisabled()"
                (onBusinessSelection)="onBusinessSelection()"
              ></app-user-information-form>
            </p-tabPanel>
            <p-tabPanel
              header="Organizations"
              id="userOrgPanel"
              leftIcon="pi pi pi-building"
              *ngIf="user.business_code"
            >
              <app-user-organizations
                [user]="user"
                [additionalAvailOrgs]="additionalAvailOrgs"
                [userAssignedOrgs]="userAssignedOrgs"
                [canEdit]="canEdit"
                [canAdd]="canAdd"
                [loggedIn]="loggedIn"
                (onOrgChange)="onOrgChange()"
              ></app-user-organizations>
            </p-tabPanel>
            <p-tabPanel
              header="Subscriptions"
              id="Subscriptions"
              leftIcon="pi pi-bell"
              *ngIf="isUserAuthorizedToViewSubscriptionsTab"
            >
              <app-user-subscriptions
                [user]="user"
                [subscriptionsAndAlerts]="subscriptionsAndAlerts"
                [selectedAppId]="selectedAppId"
                (handleShowErrorMsg)="handleShowErrorMsg($event)"
                (handleShowSuccessMsg)="handleShowSuccessMsg($event)"
                (activeTabChange)="activeTabIndex = $event"
              ></app-user-subscriptions>
            </p-tabPanel>
          </p-tabView>
        </div>
        <app-action-buttons
          *ngIf="activeTabIndex === 0"
          [canView]="canView"
          [loggedIn]="loggedIn"
          [canEdit]="canEdit"
          [canCustomize]="canCustomize"
          [userForm]="userForm"
          [orgsChanged]="orgsChanged"
          [avatarChanged]="avatarChanged"
          (onCancel)="onCancel()"
          (onReset)="onReset()"
        ></app-action-buttons>
      </div>
    </div>
  </form>
</div>
