import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { firstValueFrom, forkJoin, map, of, switchMap, take, takeUntil, tap } from 'rxjs';
import {
  Action,
  BaseViewComponent,
} from 'src/app/common/components/base-view.component';
import { AircraftGroup } from 'src/app/features/administration/models/aircraftGroup';
import { AircraftGroupService } from 'src/app/features/administration/services/aircraft-group.service';
import { TeledynePackagesService } from 'src/app/features/sendtoteledyne/services/teledynepackages.service';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { BreadcrumbService } from '../../../../common/services/breadcrumb.service';
import { CommonService } from '../../../../common/services/common.service';
import { Components } from '../../integration/datadelivery.components';
import { permissions } from '../../integration/datadelivery.permissions';
import {
  ClientPackages,
  ConversionType,
  EndPoints,
  Redaction,
  ResponseModelClientPackages,
  ZipType,
  aircrafts,
} from '../../models/ClientPackage';
import { ClientPackagesService } from '../../services/clientpackages.services';
import { DataDeliveryEndPointService } from '../../services/endpoint.service';
import { FlightDataRedactionService } from '../../services/flightDataRedaction.service';
import { Organization } from 'src/app/features/administration/models/organization';

@Component({
  selector: 'app-clientpackage-add',
  templateUrl: './clientpackage-add.component.html',
  styleUrls: ['./clientpackage-add.component.css'],
})
export class ClientpackageAddComponent
  extends BaseViewComponent
  implements OnInit
{
  pageTitle = Components.AddClientPackages.label;
  public canAdd: boolean;
  public canDelete: boolean;
  public canEdit: boolean;
  clientpackage!: ClientPackages;
  redactionType: Redaction[] = [];
  zipType: ZipType[] = [];
  endpoints: EndPoints[] = [];
  conversionType: ConversionType[] = [];
  availableEndPoints: EndPoints[];
  availableAircraft: aircrafts[] = [];
  selectedEndPoints: EndPoints[] = [];
  selectedAircraft: aircrafts[] = [];
  selectedEndPointsIDs: number[] = [];
  selectedAircraftIDs: number[] = [];
  responseModelClientPackages: ResponseModelClientPackages;
  picklistChange = false;
  pickAircraftlistChange = false;
  isButtonDisabled = true;
  isAircraftButtonDisabled = true;
  initialEndpointsCount: number;
  initialAircraftsCount: number;
  saveButtonDisabled: boolean;
  isReadonly = true;
  messageLabel = 'client package';
  showButtons = true;
  selectedRedactionId: number;
  defaultfileName: string;
  isRedaction = true;
  noConversionId = 1;
  drpdownindex = 0;
  file_Name = `%Reg%%Source%%YYYY%%MM%%DD%%HH%%mm%%ss%`;
  newLabel = 'New Client Package';
  aircraftGroups: SelectItem[];
  selectedAircraftGroupIDs: aircrafts[] = [];
  targetAircraft: aircrafts[] = [];
  selectedAircraftGroup: number;
  selectedTargetAircraftGroup: number;
  organization_id: number;

  constructor(
    router: Router,
    private route: ActivatedRoute,
    breadcrumbService: BreadcrumbService,
    confirmationService: ConfirmationService,
    messageService: MessageService,
    private datadeliveryEndPointservice: DataDeliveryEndPointService,
    private clientPackagesService: ClientPackagesService,
    private flightDataRedactionService: FlightDataRedactionService,
    private commonService: CommonService,
    private realUserService: SecurityUserService,
    private aircraftGroupService: AircraftGroupService,
    private teledynePackagesService: TeledynePackagesService
  ) {
    super(messageService, confirmationService, router, breadcrumbService);
    this.getBreadCrums();
  }

  getBreadCrums() {
    this.breadcrumbService.setItems(this.route, [
      {
        label: Components.ClientPackages.label,
        routerLink: Components.ClientPackages.path,
      },
      {
        label:
          Components.AddClientPackages.label +
          ` (${this.route.snapshot.queryParamMap.get('name')})`,
      },
    ]);
  }

  intialVal() {
    this.isNew = true;
    this.pageTitle = this.newLabel;
    this.updateBreadcrumb(this.pageTitle);
    this.clientpackage.package_id = -1;
  }

  ngOnInit() {
    this.getBreadCrums();
    this.checkPermissions();
    const defaultClientPackage = new ClientPackages();
    const clientPackageExists = Object.keys(this.clientpackage || {}).length > 0;
    if(clientPackageExists){
      this.clientpackage.conversiontype_id = this.responseModelClientPackages.conversiontype_id
    }else{
      this.clientpackage = defaultClientPackage
    }
    this.initializePackage();
  }

  checkPermissions() {
    this.canAdd = this.realUserService.userHasPermission(
      permissions.datadelivery_client_packages.create
    );
    this.canEdit = this.realUserService.userHasPermission(
      permissions.datadelivery_client_packages.manage
    );
    this.canDelete = this.realUserService.userHasPermission(
      permissions.datadelivery_client_packages.delete
    );
  }

  initializePackage() {
    const packageId = +this.route.snapshot.queryParamMap.get('packageId');
    const isClone = !!this.route.snapshot.queryParamMap.get('clone');
    if (packageId) {
      this.handleExistingPackage(packageId, isClone);
    } else {
      this.handleNewPackage();
    }
  }

  handleExistingPackage(packageId: number, isClone: boolean) {
    if (isClone) {
      this.isNew = true;
      this.setReadonlyAndButtons(!this.canAdd);
      this.pageTitle = 'Clone Client Package';
      this.updateBreadcrumb(
        `${this.pageTitle} (${this.route.snapshot.queryParamMap.get('name')})`
      );
    } else {
      this.isNew = false;
      this.setReadonlyAndButtons(!this.canEdit);
    }
    this.loadClientPackages(packageId, isClone);
  }

  handleNewPackage() {
    this.setReadonlyAndButtons(!this.canAdd);
    this.intialVal();
    this.loadDataFrames();
  }

  setReadonlyAndButtons(condition: boolean) {
    this.isReadonly = condition;
    this.showButtons = !condition;
  }

  loadClientPackages(packageId: number, clone: boolean) {
    this.loadingCount++;
    forkJoin({
      getPackages: this.clientPackagesService.getClientPackage_by_id(packageId),
      availableEndPoints: this.datadeliveryEndPointservice.getEndPoints(),
      availableConversionType: this.clientPackagesService.getConvertionTypes(),
      zipTypes: this.flightDataRedactionService.getZipTypes(),
      aircraftGroups: this.aircraftGroupService.listAircraftGroups(),
      availableAircraft: this.commonService.listAircraftGroupIdFilters(0),
    })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results) => {
          this.handleLoadClientPackagesSuccess(results, clone);
        },
        error: (error) => {
          this.handleLoadClientPackagesError(error);
        },
        complete: () => {
          this.loadingCount--;
        },
      });
  }

  handleLoadClientPackagesSuccess(results: any, clone: boolean) {
    this.zipType = results.zipTypes;
    this.loadRedactionType(
      results.getPackages.conversiontype_id,
      results.getPackages
    );
    this.clientpackage = results.getPackages;
    this.clientpackage.primary = !!results.getPackages.primary;
    this.clientpackage.secondary = !!results.getPackages.secondary;
    this.isRedaction =
      this.clientpackage.conversiontype_id !== this.noConversionId;
    this.conversionType = results.availableConversionType;
    this.availableAircraft = [];
    this.availableEndPoints = [];
    this.processEndpoints(results);
    this.processAircraft(results);
    if (clone) {
      this.clientpackage.name = '';
    }
  }

  handleLoadClientPackagesError(error: any) {
    this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}`);
    this.loadingCount--;
  }

  processEndpoints(results: any) {
    this.clientpackage.endpoints.forEach((ele) => {
      if (
        !this.selectedEndPoints.find((a) => a.endpoint_id === ele.endpoint_id)
      ) {
        this.selectedEndPoints.push({
          endpoint_id: ele.endpoint_id,
          endpoint_name: ele.endpoint_name,
        });
      }
    });
    this.clientpackage.endpoints = this.selectedEndPoints;
    results.availableEndPoints.forEach((ele) => {
      this.availableEndPoints.push({
        endpoint_id: ele.endpoint_id,
        endpoint_name: ele.name,
      });
    });
    this.selectedEndPoints.forEach((ele) => {
      this.selectedEndPointsIDs.push(ele.endpoint_id);
    });
    this.availableEndPoints = this.availableEndPoints.filter(
      (p) =>
        this.selectedEndPoints.findIndex(
          (i) => i.endpoint_id === p.endpoint_id
        ) === -1
    );
    this.initialEndpointsCount = this.selectedEndPoints.length;
    this.availableEndPoints.sort((a, b) =>
      a.endpoint_name.localeCompare(b.endpoint_name)
    );
    this.clientpackage.endpoints.sort((a, b) =>
      a.endpoint_name.localeCompare(b.endpoint_name)
    );
    this.cleanUpEndpointsAndAircrafts()
  }

  processAircraft(results: any) {
    this.aircraftGroups =
      this.teledynePackagesService.populateDropdownItems<AircraftGroup>(
        results.aircraftGroups,
        'aircraftgroup_name',
        'aircraftgroup_id'
      );
    this.targetAircraft = [];
    this.clientpackage.aircrafts.forEach((ele) => {
      this.selectedAircraft.push({
        aircraft_id: ele.aircraft_id,
        aircraft_registration: ele.aircraft_name,
      });
    });
    results.availableAircraft.forEach((ele) => {
      const targhetAricraftExists = this.targetAircraft.find(
        (a) => a.aircraft_registration === ele.aircraft_registration
      );
      if (
        this.selectedAircraft.find(
          (a) => a.aircraft_registration === ele.aircraft_registration
        ) &&
        !targhetAricraftExists
      ) {
        this.targetAircraft.push({
          aircraftgroup_id: ele.aircraftgroup_id,
          aircraft_id: ele.aircraft_id,
          aircraft_registration: ele.aircraft_registration,
        });
      }
      if (
        !this.availableAircraft.find(
          (a) => a.aircraft_registration === ele.aircraft_registration
        )
      ) {
        this.availableAircraft.push({
          aircraftgroup_id: ele.aircraftgroup_id,
          aircraft_id: ele.aircraft_id,
          aircraft_registration: ele.aircraft_registration,
        });
      }
    });
    this.targetAircraft.forEach((ele) => {
      this.selectedAircraftGroupIDs.push({
        aircraft_id: ele.aircraft_id,
        aircraft_registration: ele.aircraft_registration,
        aircraftgroup_id: ele.aircraftgroup_id,
      });
    });
    this.availableAircraft = this.availableAircraft.filter(
      (p) =>
        this.selectedAircraft.findIndex(
          (i) => i.aircraft_registration === p.aircraft_registration
        ) === -1
    );
    this.initialAircraftsCount = this.selectedAircraft.length;
    this.availableAircraft.sort((a, b) =>
      a.aircraft_registration.localeCompare(b.aircraft_registration)
    );
  }

  onMoveToTarget(event: { items: EndPoints[] }) {
    event.items.forEach((ele) => {
      this.selectedEndPointsIDs.push(ele.endpoint_id);
    });
    this.isButtonDisabled = false;
    this.selectedEndPointsIDs = [...new Set(this.selectedEndPointsIDs)]; // remove the duplicate entries
    this.picklistChange = true;
  }

  onAircraftMoveAllToSource(event: { items: aircrafts[] }) {
    event.items.forEach((ele) => {
      const index = this.selectedAircraftGroupIDs.findIndex(
        (a) => a.aircraft_registration == ele.aircraft_registration
      );
      if (index > -1) {
        this.selectedAircraftGroupIDs.splice(index, 1);
      }
    });
    this.pickAircraftlistChange = true;
    this.isAircraftButtonDisabled = false;
    this.resetSelectedGroup(false);
    this.isButtonDisabled = false;
    this.picklistChange = true;
  }

  onAircraftMoveAllToTarget(event: { items: aircrafts[] }) {
    this.pickAircraftlistChange = true;
    this.isAircraftButtonDisabled = false;
    event.items.forEach((ele) => {
      if (
        !this.selectedAircraftGroupIDs.find(
          (a) => a.aircraft_registration == ele.aircraft_registration
        )
      ) {
        this.selectedAircraftGroupIDs.push({
          aircraftgroup_id: ele.aircraftgroup_id,
          aircraft_registration: ele.aircraft_registration,
          aircraft_id: ele.aircraft_id,
        });
      }
    });
    this.resetSelectedGroup(true);
    this.isButtonDisabled = false;
    this.picklistChange = true;
  }

  onAircraftMoveToSource(event: { items: aircrafts[] }) {
    this.pickAircraftlistChange = true;
    this.isAircraftButtonDisabled = false;
    event.items.forEach((ele) => {
      this.selectedAircraftGroupIDs = this.selectedAircraftGroupIDs.filter(item => item.aircraft_registration !== ele.aircraft_registration)
    });
    this.resetSelectedGroup(false);
    this.isButtonDisabled = false;
    this.picklistChange = true;
  }

  onAircraftMoveToTarget(event: { items: aircrafts[] }) {
    event.items.forEach((ele) => {
      if (
        !this.selectedAircraftGroupIDs.find(
          (a) => a.aircraft_registration == ele.aircraft_registration
        )
      ) {
        this.selectedAircraftGroupIDs.push({
          aircraftgroup_id: ele.aircraftgroup_id,
          aircraft_registration: ele.aircraft_registration,
          aircraft_id: ele.aircraft_id,
        });
      }
    });
    this.isAircraftButtonDisabled = false;
    this.selectedAircraftGroupIDs = [...new Set(this.selectedAircraftGroupIDs)]; // remove the duplicate entries
    this.pickAircraftlistChange = true;
    this.resetSelectedGroup(true);
    this.isButtonDisabled = false;
    this.picklistChange = true;
  }

  onMoveAllToSource(event: { items: EndPoints[] }) {
    event.items.forEach((ele) => {
      const index = this.selectedEndPointsIDs.indexOf(ele.endpoint_id);
      if (index > -1) {
        this.selectedEndPointsIDs.splice(index, 1);
      }
    });
    this.picklistChange = true;
    this.isButtonDisabled = false;
  }

  onMoveAllToTarget(event: { items: EndPoints[] }) {
    this.picklistChange = true;
    this.isButtonDisabled = false;
    event.items.forEach((ele) => {
      this.selectedEndPointsIDs.push(ele.endpoint_id);
    });
  }

  onMoveToSource(event: { items: EndPoints[] }) {
    this.picklistChange = true;
    this.isButtonDisabled = false;
    event.items.forEach((ele) => {
      const index = this.selectedEndPointsIDs.indexOf(ele.endpoint_id);
      if (index > -1) {
        this.selectedEndPointsIDs.splice(index, 1);
      }
    });
  }

  onRedactionType(e: { value: number }) {
    const value = this.redactionType.find((a) => a.redactiontypeId == e.value);
    if (value.isPredefined == true) {
      this.clientpackage.predefined_redaction_id = value.redaction_id;
      this.clientpackage.redaction_id = undefined;
    } else {
      this.clientpackage.redaction_id = value.redaction_id;
      this.clientpackage.predefined_redaction_id = undefined;
    }
  }

  onZipSelection(e: { value: number }) {
    if (e.value == 1) {
      this.clientpackage.zip_password = '';
    }
  }

  onConversionType(e: { value: number }) {
    if (e.value == this.noConversionId) {
      this.isRedaction = false;
      this.clientpackage.predefined_redaction_id = undefined;
      this.clientpackage.redaction_id = undefined;
      this.selectedRedactionId = undefined;
    } else {
      this.isRedaction = true;
      this.getPredindedRedaction(e.value);
    }
  }

  getPredindedRedaction(convertionTypeId: number) {
    this.loadingCount++;
    this.redactionType = [];
    forkJoin({
      predefinedDataType:
        this.clientPackagesService.getPredefinedRedaction(convertionTypeId),
    })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results) => {
          this.drpdownindex = 0;
          results.predefinedDataType.forEach((ele) => {
            this.drpdownindex = this.drpdownindex + 1;
            this.redactionType.push({
              redaction_id: ele.predefined_redaction_id,
              redaction_type: ele.name,
              isPredefined: true,
              redactiontypeId: this.drpdownindex,
            });
          });

          this.getRedactionType(
            convertionTypeId,
            this.drpdownindex,
            true,
            0,
            0
          );
        },
        error: (error) => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        },
      });
  }

  getRedactionType(
    convertionTypeId: number,
    drpdownindex: number,
    isEdit: boolean,
    redaction_id: number,
    isRedaction: any
  ) {
    this.loadingCount++;
    forkJoin({
      redactionType:
        this.clientPackagesService.getRedactionTypeDetails(convertionTypeId),
    })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results) => {
          results.redactionType.forEach((ele) => {
            drpdownindex = drpdownindex + 1;
            this.redactionType.push({
              redaction_id: ele.redaction_id,
              redaction_type: ele.name,
              isPredefined: false,
              redactiontypeId: drpdownindex,
            });
          });
          if (isEdit && isRedaction == null) {
            const getValue = this.redactionType.find(
              (a) => a.redaction_id == redaction_id && a.isPredefined == false
            );
            this.selectedRedactionId = getValue.redactiontypeId;
          }
        },
        error: (error) => {
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        },
      });
  }

  loadRedactionType(convertionTypeId: number, data: ClientPackages) {
    this.loadingCount++;
    this.redactionType = [];
    forkJoin({
      predefinedDataType:
        this.clientPackagesService.getPredefinedRedaction(convertionTypeId),
    })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results) => {
          this.drpdownindex = 0;
          results.predefinedDataType.forEach((ele) => {
            this.drpdownindex = this.drpdownindex + 1;
            this.redactionType.push({
              redaction_id: ele.predefined_redaction_id,
              redaction_type: ele.name,
              isPredefined: true,
              redactiontypeId: this.drpdownindex,
            });
          });

          if (data.predefined_redaction_id != null) {
            const getValue = this.redactionType.find(
              (a) =>
                a.redaction_id == data.predefined_redaction_id &&
                a.isPredefined == true
            );
            this.selectedRedactionId = getValue.redactiontypeId;
          }
          this.getRedactionType(
            convertionTypeId,
            this.drpdownindex,
            true,
            data.redaction_id,
            data.predefined_redaction_id
          );
        },
        error: (error) => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        },
      });
  }

  loadDataFrames() {
    this.resetClientPackageData();
    this.loadingCount++;
    forkJoin({
      availableEndPoints: this.datadeliveryEndPointservice.getEndPoints(),
      availableConversionType: this.clientPackagesService.getConvertionTypes(),
      redactionType: this.flightDataRedactionService.getRedactionType(),
      zipTypes: this.flightDataRedactionService.getZipTypes(),
      aircraftGroups: this.aircraftGroupService.listAircraftGroups(),
      getAircraft: this.commonService.listAircraftGroupIdFilters(0),
    })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results) => this.handleLoadDataFramesSuccess(results),
        error: (error) => this.handleLoadDataFramesError(error),
        complete: () => this.loadingCount--,
      });
  }

  resetClientPackageData() {
    this.clientpackage.aircrafts = this.isNew ? (this.clientpackage.aircrafts ?? []): [];
    this.clientpackage.endpoints = this.isNew ? (this.clientpackage.endpoints ?? []): [];
    this.conversionType = this.isNew ? (this.conversionType ?? []) : [];
  }

  handleLoadDataFramesSuccess(results: any) {
    this.availableEndPoints = this.mapEndPoints(results.availableEndPoints);
    this.availableAircraft = this.mapAircraft(results.getAircraft);
    this.zipType = this.mapZipTypes(results.zipTypes);
    this.conversionType = results.availableConversionType;
    this.aircraftGroups =
      this.teledynePackagesService.populateDropdownItems<AircraftGroup>(
        results.aircraftGroups,
        'aircraftgroup_name',
        'aircraftgroup_id'
      );
    this.initialEndpointsCount = 0;
    this.initialAircraftsCount = 0;
    this.clientpackage.file_name = this.file_Name;
    this.sortAvailableData();
    this.cleanUpEndpointsAndAircrafts()
  }

  cleanUpEndpointsAndAircrafts(){
    // ensures no duplicates are found on both available/selected columns
    this.availableEndPoints = this.availableEndPoints.filter(available => !this.selectedEndPointsIDs.includes(available.endpoint_id))
    this.availableAircraft = this.availableAircraft.filter(available => !this.selectedAircraftIDs.includes(available.aircraft_id))
  }

  handleLoadDataFramesError(error: any) {
    this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
    this.loadingCount--;
  }

  mapEndPoints(endPoints: any[]): EndPoints[] {
    return endPoints.map((ele) => ({
      endpoint_id: ele.endpoint_id,
      endpoint_name: ele.name,
    }));
  }

  mapAircraft(aircraft: any[]): aircrafts[] {
    return aircraft.reduce((acc, ele) => {
      if (
        !acc.find((a) => a.aircraft_registration === ele.aircraft_registration)
      ) {
        acc.push({
          aircraftgroup_id: ele.aircraftgroup_id,
          aircraft_id: ele.aircraft_id,
          aircraft_registration: ele.aircraft_registration,
        });
      }
      return acc;
    }, []);
  }

  mapZipTypes(zipTypes: any[]): ZipType[] {
    return zipTypes.map((ele) => ({
      ziptype: ele.ziptype,
      ziptype_id: ele.ziptype_id,
    }));
  }

  sortAvailableData() {
    this.availableAircraft.sort((a, b) =>
      a.aircraft_registration.localeCompare(b.aircraft_registration)
    );
    this.availableEndPoints.sort((a, b) =>
      a.endpoint_name.localeCompare(b.endpoint_name)
    );
  }

  onCancel() {
    this.router.navigate([Components.ClientPackages.path]);
  }
  onReset() {
    this.picklistChange = false;
    this.isRedaction = true;
    this.selectedAircraftIDs = [];
    this.selectedEndPointsIDs = [];
    this.availableAircraft = [];
    this.availableEndPoints = [];
    this.redactionType = [];
    this.conversionType = [];
    this.selectedEndPoints = [];
    this.selectedAircraft = [];
    this.selectedAircraftGroupIDs = [];
    this.targetAircraft = [];
    this.selectedAircraftGroup = null;
    this.selectedTargetAircraftGroup = null;
    this.ngOnInit();
    this.isButtonDisabled = true;
  }

  submitForm(clientPackage: ClientPackages) {
    this.clientpackage = clientPackage;
    this.onSubmit();
  }

  verifyAndSetPackageState(packageId: number | string | null) {
    this.isNew = !(!!packageId && !Number.isNaN(packageId) && Number(packageId) > 0); // if a package id exists, this is an existing package, not a new one
  }
  
  onSubmit() {
    this.loadingCount++;
    
    this.route.queryParams.pipe(
      take(1),
      map(params => +params['packageId']),
      tap(packageId => {
        this.verifyAndSetPackageState(packageId);
        this.prepareSelectedAircraftIDs();
        this.prepareResponseModelClientPackages(packageId);
      }),
      switchMap(() => this.isNew ? of(this.addClientPackage()) : of(this.updateClientPackage()))
    ).subscribe();
  }

  prepareSelectedAircraftIDs() {
    this.selectedAircraftIDs = this.selectedAircraftGroupIDs.map(
      (a) => a.aircraft_id
    );
  }

  prepareResponseModelClientPackages(packageId: number) {
    this.responseModelClientPackages = {
      package_id: Number.isNaN(packageId) ? this.clientpackage.package_id : packageId,
      conversiontype_id: this.clientpackage.conversiontype_id,
      file_name: this.clientpackage.file_name ?? this.defaultfileName,
      description: this.clientpackage.description,
      name: this.clientpackage.name,
      redaction_id: this.clientpackage.redaction_id,
      predefined_redaction_id: this.clientpackage.predefined_redaction_id,
      ziptype_id: this.clientpackage.ziptype_id,
      zip_password: this.clientpackage.zip_password ?? '',
      aircraft_id: this.selectedAircraftIDs,
      endpoint_id: this.selectedEndPointsIDs,
      organization_id: 0,
      primary: this.clientpackage.primary ?? false,
      secondary: this.clientpackage.secondary ?? false,
      enhanced: false,
    };
  }

  addClientPackage() {
    this.clientPackagesService
      .addClientPackage(this.responseModelClientPackages)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (result) => this.handleAddClientPackageSuccess(result),
        error: (err) => this.handleAddClientPackageError(err),
        complete: () => this.handleAddClientPackageComplete(),
      });
  }

  handleAddClientPackageSuccess(result: number) {
    this.clientpackage.package_id = result;
    this.router
      .navigate([], {
        relativeTo: this.route,
        queryParams: {
          packageId: this.clientpackage.package_id,
          name: this.clientpackage.name,
        },
        queryParamsHandling: 'merge',
      })
      .then();

    this.updateBreadcrumb(
      `${Components.AddClientPackages.label} (${this.clientpackage.name})`
    );
    this.pageTitle = Components.AddClientPackages.label;
    this.picklistChange = false;
  }

  handleAddClientPackageError(err: any) {
    this.showErrorMsg(`${err}`, Action.Add, `${this.messageLabel}s`);
    this.loadingCount--;
    this.isButtonDisabled = true;
    this.saveButtonDisabled = true;
  }

  handleAddClientPackageComplete() {
    this.showSuccessMsg(
      Action.Add,
      `${this.messageLabel}`,
      `${this.clientpackage.name}`
    );
    this.isNew = false;
    this.loadingCount--;
    this.isButtonDisabled = true;
    this.saveButtonDisabled = true;
    this.ngOnInit();
  }

  updateClientPackage() {
    this.clientPackagesService
      .updateClientPackage(this.responseModelClientPackages)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => this.handleUpdateClientPackageSuccess(),
        error: (err) => this.handleUpdateClientPackageError(err),
        complete: () => this.handleUpdateClientPackageComplete(),
      });
  }

  handleUpdateClientPackageSuccess() {
    this.updateBreadcrumb(
      `${Components.AddClientPackages.label} (${this.clientpackage.name})`
    );
    this.picklistChange = false;
  }

  handleUpdateClientPackageError(err: any) {
    this.showErrorMsg(`${err}`, Action.Update, `${this.messageLabel}s`);
    this.loadingCount--;
    this.isButtonDisabled = true;
    this.saveButtonDisabled = true;
  }

  handleUpdateClientPackageComplete() {
    this.showSuccessMsg(
      Action.Update,
      `${this.messageLabel}`,
      `${this.clientpackage.name}`
    );
    this.loadingCount--;
    this.isButtonDisabled = true;
    this.ngOnInit();
  }

  onChangeAircraftGroup(selectedAircraftGroup: any) {
    this.selectedAircraftGroup = selectedAircraftGroup.value;
    this.getAircraftValuesBinded(selectedAircraftGroup.value, true);
  }

  onChangeTargetAircraftGroup(selectedAircraftGroup: any) {
    this.selectedTargetAircraftGroup = selectedAircraftGroup.value;
    this.getAircraftValuesBinded(selectedAircraftGroup.value, false);
  }

  getAircraftValuesBinded(selectedValue: number, isSource: boolean) {
    this.loadingCount++;
    this.commonService
      .listAircraftGroupIdFilters(selectedValue == null ? 0 : selectedValue)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (data) => this.handleAircraftData(data, selectedValue, isSource),
        error: (err) => this.handleError(err),
        complete: () => this.loadingCount--,
      });
  }

  handleAircraftData(data: any[], selectedValue: number, isSource: boolean) {
    if (isSource) {
      this.availableAircraft = this.filterAvailableAircraft(selectedValue);
    }else {
      this.targetAircraft = this.filterTargetAircraft(data, selectedValue);
    }
  }

  filterAvailableAircraft(selectedValue){
    return this.availableAircraft.filter(craft => (craft.aircraft_registration !== null)).map((a) => ({
      aircraft_registration: a.aircraft_registration,
      aircraftgroup_id: selectedValue,
      aircraft_id: a.aircraft_id,
    }));
  }

  filterTargetAircraft(data: any[], selectedValue: number): any[] {
    if (selectedValue == null) {
      return this.selectedAircraftGroupIDs;
    } else {
      return this.selectedAircraftGroupIDs.filter((a) =>
        data.some((i) => i.aircraft_registration == a.aircraft_registration)
      );
    }
  }

  handleError(err: any) {
    this.showErrorMsg(`${err}`, Action.Get, `${this.messageLabel}`);
    this.loadingCount--;
  }

  resetSelectedGroup(isTarget: boolean) {
    const getFilterSelection = this.getUniqueAircraftGroupIDs(
      this.selectedAircraftGroupIDs
    );

    if (isTarget) {
      this.handleTargetGroupSelection(getFilterSelection);
    } else {
      const getSourceSelection = this.getUniqueAircraftGroupIDs(
        this.availableAircraft
      );
      this.handleSourceGroupSelection(getSourceSelection);
    }
  }

  getUniqueAircraftGroupIDs(aircrafts: any[]): number[] {
    return Array.from(new Set(aircrafts.map((a) => a.aircraftgroup_id)));
  }

  handleTargetGroupSelection(getFilterSelection: number[]) {
    if (getFilterSelection.length > 1) {
      this.selectedTargetAircraftGroup = null;
    } else {
      this.selectedTargetAircraftGroup = this.selectedAircraftGroup;
    }
    this.getAircraftValuesBinded(this.selectedTargetAircraftGroup, false);
  }

  handleSourceGroupSelection(getSourceSelection: number[]) {
    if (getSourceSelection.length > 1) {
      this.selectedAircraftGroup = null;
    } else {
      this.selectedAircraftGroup = this.selectedTargetAircraftGroup;
    }
    this.getAircraftValuesBinded(this.selectedAircraftGroup, true);
  }
}
