import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { Procedure, Transition, TransitionStep } from 'src/app/features/administration/models/procedure';
import { ProcedureService } from 'src/app/features/administration/services/procedure.service';
import { ApiType, NavigationInformationBaseViewComponent } from '../navigation-information-base/navigation-information-base-view.component';
import { TerminalVHFNavaidService } from "src/app/features/administration/services/terminal-vhf-navaid.service";
import { EnRouteVHFNavaidService } from "src/app/features/administration/services/enroute-vhf-navaid.service";
import { VHFNavaid } from "src/app/features/administration/models/VHFNavaid";
import { Components } from 'src/app/features/administration/integration/administration.components';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { permissions } from 'src/app/features/administration/integration/administration.permissions';

@Component({
  selector: 'app-procedure-details',
  templateUrl: './procedure-details.component.html',
})

export class ProcedureDetailsComponent extends NavigationInformationBaseViewComponent implements OnInit {
  public showRunways: boolean;
  public showVHFNavaids: boolean;
  public showNDBNavaids: boolean;
  public showWaypoints: boolean;

  pageTitle = Components.ProcedureDetails.label;
  file_dates: any;
  selected_date: any;
  display_icao_iata: string;
  procedure_id: any;
  transition_identifier: any;
  icao: string
  dte: string
  isReadOnly = false;
  columns: any;
  // Code
  procedure: Procedure;
  transition: Transition;
  transitions: Transition[] = [];
  transitionSteps: TransitionStep[] = [];
  selected_transition: any;
  sid_star_approach_identifier: string;
  vhf: VHFNavaid;

  constructor(
    private enRouteVHFService: EnRouteVHFNavaidService,
    private terminalVHFService: TerminalVHFNavaidService,
    private procedureService: ProcedureService,
    private realUserService: SecurityUserService,
    messageService: MessageService,
    confirmationService: ConfirmationService,
    router: Router,
    route: ActivatedRoute,
    breadcrumbService: BreadcrumbService) {
    super(messageService, confirmationService, router, breadcrumbService, route)
    this.icao = route.snapshot.queryParamMap.get('a');
    this.display_icao_iata = route.snapshot.queryParamMap.get('b');
    this.dte = route.snapshot.queryParamMap.get('c');
    const file_dates = route.snapshot.queryParamMap.get('d');
    this.transition_identifier = route.snapshot.queryParamMap.get('e');
    this.procedure_id = route.snapshot.queryParamMap.get('f');
    this.sid_star_approach_identifier = route.snapshot.queryParamMap.get('g')

    this.selected_date = { "dte": this.dte };
    this.file_dates = JSON.parse(file_dates);
    this.setBreadcrumb(this.procedure_id);
  }

  ngOnInit(): void {
    this.setBreadcrumb(this.procedure_id)

    this.getProcedure(this.dte, this.procedure_id);
    // Default transition populate Transition steps list
    this.getTransitions(this.dte, this.procedure_id);
    this.showRunways = this.realUserService.userHasPermission(permissions.runways.view);
    this.showVHFNavaids = this.realUserService.userHasPermission(permissions.vhf_navaids.view);
    this.showNDBNavaids = this.realUserService.userHasPermission(permissions.ndb_navaids.view);
    this.showWaypoints = this.realUserService.userHasPermission(permissions.waypoints.view);
  }

  getProcedure(dte, procedure_id) {
    const arg: ApiType = {
      request: this.procedureService.getProcedure(dte, procedure_id),
      api_name: 'procedure',
      callback: this.callbackProcedure.bind(this)
    }
    this.callAPI(arg);
  }

  callbackProcedure(data) {
    this.procedure = data;
  }

  getTransitions(dte, procedure_id) {
    const arg: ApiType = {
      request: this.procedureService.getTransitions(dte, procedure_id),
      api_name: 'transitions',
      callback: this.callbackTransitions.bind(this)
    }
    this.callAPI(arg);
  }

  callbackTransitions(data) {
    this.transitions = data;
    if (this.transitions) {
      // Set default transition and selected transition to populate filter on load
      this.selected_transition = this.transitions[0];
      this.transition = this.transitions[0];
      // Populate the list
      this.getTransitionSteps()
    }
  }

  // Filter transition steps based on current transition
  getTransitionSteps() {
    const arg: ApiType = {
      request: this.procedureService.getTransitionSteps(this.dte, this.transition.transition_id),
      api_name: 'transition steps',
      callback: this.callbackTransitionSteps.bind(this)
    }
    this.callAPI(arg);
  }

  callbackTransitionSteps(data) {
    this.transitionSteps = data;
  }

  onTransitionChange($event: any) {
    if (this.selected_transition) {
      this.transition = this.selected_transition;
      this.getTransitionSteps();
    }
  }

  override setBreadcrumb(procedure_id) {
    const trimRunwayIdentifier = (runwayIdentifier: string): string => {
      if (runwayIdentifier) {
        return runwayIdentifier.replace(/^RW/, '');
      }
      return '-';
    };

    super.setBreadcrumb(
      [
        {
          label: Components.AirportList.label,
          routerLink: Components.AirportList.path,
          queryParams: { a: this.selected_date["dte"] }

        },
        {
          label: Components.RunwayList.label + ` (${this.display_icao_iata})`,
          routerLink: Components.RunwayList.path,
          queryParams: {
            a: this.icao,
            b: this.display_icao_iata,
            c: this.selected_date["dte"],
            d: JSON.stringify(this.file_dates)
          }
        },
        {
          label: Components.ProceduresList.label + ` (${trimRunwayIdentifier(this.transition_identifier)})`,
          routerLink: Components.ProceduresList.path,
          queryParams: {
            a: this.icao,
            b: this.display_icao_iata,
            c: this.selected_date["dte"],
            d: JSON.stringify(this.file_dates),
            e: this.transition_identifier
          }
        },
        {
          label: Components.ProcedureDetails.label + ` (${this.sid_star_approach_identifier || "-"})`
        }
      ]);
  }

  callbackNavaid(data) {
    this.vhf = data;
  }

  async onFixIdentifierClick(event: MouseEvent, step: TransitionStep) {
    event.preventDefault();

    const fix_identifier = step.fix_identifier;
    const fix_identifier_type = step.fix_identifier_type;
    const fix_identifier_icao_code_short = step.icao_code_fix_identifier;
    const procedureBreadcrumb = {
      "transition_identifier": this.transition_identifier,
      "procedure_id": this.procedure_id,
      "sid_star_approach_identifier": this.sid_star_approach_identifier,
      "icao": this.icao,
      "display_icao_iata": this.display_icao_iata
    };

    if (fix_identifier) {
      const queryParams = {
        a: fix_identifier_type === "runway" || fix_identifier_type.startsWith('terminal_') ? this.icao : fix_identifier_icao_code_short,
        b: fix_identifier_type === "runway" || fix_identifier_type.startsWith('terminal_') ? this.display_icao_iata : fix_identifier,
        c: this.selected_date["dte"],
        d: JSON.stringify(this.file_dates),
        e: fix_identifier,
        f: JSON.stringify(procedureBreadcrumb)
      };
      // "enroute_waypoint"
      if (fix_identifier_type == "enroute_waypoint") {
        this.router.navigate(
          [Components.EnRouteWaypointsDetails.path], {
          queryParams: {
            a: fix_identifier_icao_code_short,
            b: this.selected_date["dte"],
            c: JSON.stringify(this.file_dates),
            d: fix_identifier,
            e: JSON.stringify(procedureBreadcrumb)
          }
        });
      }

      if (fix_identifier_type === "runway") {
        // Here runway fix identifier in transition step has RW14 prefix which has to be removed
        queryParams.e = fix_identifier.replace("RW", "")
        this.router.navigate([Components.RunwayDetails.path], { queryParams });
      } else if (fix_identifier_type === "terminal_waypoint") {
        this.router.navigate([Components.TerminalWaypointDetails.path], { queryParams });
      } else if (fix_identifier_type === "enroute_waypoint") {
        queryParams.a = fix_identifier_icao_code_short
        queryParams.b = this.selected_date["dte"]
        queryParams.c = JSON.stringify(this.file_dates)
        queryParams.e = fix_identifier
        queryParams.f = JSON.stringify(procedureBreadcrumb)
        this.router.navigate([Components.EnRouteWaypointsDetails.path], { queryParams });
      } else if (fix_identifier_type === "terminal_vhf_navaid") {
        this.router.navigate([Components.TerminalVHFNavaidsDetails.path], { queryParams });
      } else if (fix_identifier_type === "enroute_vhf_navaid") {
        queryParams.e = JSON.stringify(procedureBreadcrumb);
        delete queryParams.f;
        this.router.navigate([Components.EnRouteVHFNavaidsDetails.path], { queryParams });
      } else if (fix_identifier_type === "terminal_ndb_navaid") {
        this.router.navigate([Components.TerminalNDBNavaidsDetails.path], { queryParams });
      } else if (fix_identifier_type === "enroute_ndb_navaid") {
        queryParams.e = JSON.stringify(procedureBreadcrumb);
        delete queryParams.f;
        this.router.navigate([Components.EnRouteNdbNavaidsDetails.path], { queryParams });
      }
    }
  }


  formatFixIdentifierType(str: string) {
    if (str) {
      return str
        .split('_')
        .map(word => {
          if (word.toLowerCase() === 'ndb' || word.toLowerCase() === 'vhf') {
            return word.toUpperCase();
          }
          return word.charAt(0).toUpperCase() + word.substr(1);
        })
        .join(' ');
    }
    return str;
  }

  //Change date
  override onChange($event) {
    super.onChange([Components.ProcedureDetails.path], {
      queryParams: {
        a: this.icao,
        b: this.display_icao_iata,
        c: this.selected_date["dte"],
        d: JSON.stringify(this.file_dates),
        e: this.transition_identifier,
        f: this.procedure_id
      }
    })
  }
}
