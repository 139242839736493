import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Table } from 'primeng/table';
import { Subject, takeUntil } from 'rxjs';
import { BaseComponent } from 'src/app/common/components/base.component';
import { isInteger, isNumber, isString } from '../../shared/utils';

@Component({
  selector: 'app-frame-details-table',
  templateUrl: './frame-details-table.component.html',
  styleUrls: ['./frame-details-table.component.css'],
})
export class FrameDetailsTableComponent extends BaseComponent {
  @Input() dataparameter;
  @Input() userCanView: boolean;
  @Input() userCanManage: boolean;
  @Input() frameIDs;
  @Input() onValidateFrame: Subject<void>;
  @Input() onReset: Subject<void>;
  @Input() isDxs: boolean;
  @Output() onTableValidityUpdate = new EventEmitter<boolean>();
  @Output() onTableEdit = new EventEmitter();
  @Output() onDataParameterChange = new EventEmitter<any>();
  @ViewChild('dataTableComponent') dataTableComponent!: Table;
  frameIDBool = false;
  isValid = true;
  frameIndex = [];
  isNumberIndex = [];
  isStringIndex = [];
  isPatternIndex = [];
  isIntegerIndex = [];
  eventBool = false;
  index = 0;
  Rindex = null;
  recordingBool = false;

  pattern = new RegExp('^[1-9][0-9]*Hz$|^[1-9][0-9]*/[1-9][0-9]*Hz$');
  SubFrameArray: string[] = ['Event', 'Scheduled'];

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.onValidateFrame
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => this.validateFrame());
    this.onReset
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => this.reset());
  }

  checkFrameID(id, index) {
    if (id != null && this.dataparameter[index].Frame_ID != id) {
      this.frameIDBool = this.frameIDs.includes(id) === true ? true : false;
      if (this.frameIDBool) {
        this.frameIndex = index;
      }
      return this.frameIDBool;
    }
  }

  isTableValid(): boolean {
    return !(
      ((this.recordingBool === true || this.eventBool === true) &&
        this.Rindex != null) ||
      this.isNumberIndex.length > 0 ||
      this.isStringIndex.length > 0 ||
      this.isPatternIndex.length > 0 ||
      this.isIntegerIndex.length > 0 ||
      this.frameIDBool
    );
  }

  validateFrame() {
    this.isNumberIndex = [];
    this.isStringIndex = [];
    this.isPatternIndex = [];
    this.isIntegerIndex = [];
    const nameArray = this.dataparameter.map(function (el) {
      return el.Frame_ID;
    });
    this.dataparameter.forEach((row, index) => {
      if (!isNumber(row.Frame_ID)) {
        this.isNumberIndex.push(index);
      }
      if (!isString(row.Frame_Title)) {
        this.isStringIndex.push(index);
      }
      if (!this.isPattern(row.Recording_Rate)) {
        this.isPatternIndex.push(index);
      }
      if (!isInteger(row.Frame_ID)) {
        this.isIntegerIndex.push(index);
      }
      const count = nameArray.filter(
        (item, index) => nameArray.indexOf(item) !== index
      );
      if (count.length > 0) {
        // let obj = {'key':'Frame_ID','index' : index}
        this.frameIndex.push(index);
      }
    });
    this.onTableValidityUpdate.emit(this.isTableValid());
  }

  isPattern(val): boolean {
    return this.pattern.test(val);
  }

  reset() {
    this.dataTableComponent.reset();
    this.frameIDBool = false;
    this.recordingBool = false;
    this.eventBool = false;
    this.index = null;
  }

  onCheckEvent() {
    this.eventBool = false;
  }
  checkRecording(data, rowIndex) {
    this.Rindex = data != (null || '') ? rowIndex : null;
    this.recordingBool = data != (null || '') ? false : true;
    this.validateFrame();
  }
  checkEUCondition(data) {
    this.eventBool = data != (null || '') ? false : true;
    this.validateFrame();
  }

  onSubFrameChange(event, rowindex, frameId) {
    if (event.value === 'Event') {
      this.eventBool = event.value === 'Event' ? true : false;
      this.dataparameter.filter((item, index) => {
        if (frameId === item.Frame_ID) {
          item.Recording_Phase = null;
          delete this.dataparameter[index]['Recording_Phase'];
        }
      });
      this.index = rowindex;
      this.recordingBool = false;
    } else {
      this.recordingBool = event.value === 'Scheduled' ? true : false;
      this.dataparameter.filter((item, index) => {
        if (frameId === item.Frame_ID) {
          item.Event_Condition = '';
          delete this.dataparameter[index]['Event_Condition'];
        }
      });
      this.Rindex = rowindex;
      this.eventBool = false;
    }
    this.validateFrame();
  }

  onDeleteFrameDetailsGroupRow(row) {
    this.onDataParameterChange.emit(this.dataparameter.filter(x => x.Frame_ID != row.Frame_ID));
    this.onTableEdit.emit();
    this.validateFrame();
  }

  addFrameDetailsGroupRow() {
    const maxFrameId = this.dataparameter.reduce((acc, currDP) => currDP.Frame_ID > acc ? currDP.Frame_ID : acc , 0);
    this.onDataParameterChange.emit([...this.dataparameter, {
      Frame_ID: maxFrameId + 1,
      Frame_Title: null,
      Frame_Type: null,
      Recording_Rate: null,
      Parameter_List: [],
    }]);
    this.onTableEdit.emit();
    this.onTableValidityUpdate.emit(false); // When adding a new row => row will immediately become invalid
  }

  onCellEdit() {
    this.onTableEdit.emit();
    this.validateFrame();
  }
}
