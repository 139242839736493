import { Component, EventEmitter, Input, Output } from "@angular/core";
import { INotification } from "src/app/common/models/notifications";

@Component({
    selector: 'app-notifications-page-right-pane',
    templateUrl: './right-pane.component.html'
})

export class NotificationsPageRightPane {
    @Input() currentOpenNotification: INotification | null;
    @Input() currentOpenEmailTemplate: string | undefined
    @Output() deleteNotification = new EventEmitter();
    @Output() toggleSingleNotificationPriority = new EventEmitter();

    handleShouldRenderRightPane(){
        return this.currentOpenNotification !== null
    }

    handleDeleteNotification(id: number){
        this.deleteNotification.emit(id)
    } 

    handleToggleSingleNotificationPriority(id: number){
        this.toggleSingleNotificationPriority.emit(id)
    }
}