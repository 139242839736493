import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: 'app-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss']
  })
  export class AvatarComponent {
    @Input() avatarToShow: string;
    @Input() maxAvatarFileSize: number;
    @Input() isFieldDisabled: boolean;
    @Output() onLogoRemove = new EventEmitter<void>();
    @Output() onAvatarSelect = new EventEmitter<Event>();

    removeLogo(){
        this.onLogoRemove.emit();
    }

    selectAvatar(e: Event){
        this.onAvatarSelect.emit(e)
    }

    IsFieldDisabled() {
        return this.isFieldDisabled;
    }
  }