import { takeUntil } from 'rxjs/operators';
import { Component, forwardRef, Inject } from '@angular/core';
import { AppComponent } from '../../app.component';
import { User } from 'src/app/features/administration/models/user';
import { BaseComponent } from '../../common/components/base.component';
import { ComponentLink, MenuService } from '../../common/services/menu.service';
import { MenuItem } from '../../common/models/menuItem';
import { SecurityUserService } from '../../security/services/security-user.service';
import { permissions } from '../../security/models/permissions';
import { OrganizationType } from '../../features/administration/models/organization';

@Component({
  selector: 'app-menu',
  template: `
    <ul class="layout-menu">
      <li app-menuitem style="z-index: 100 !important;" *ngFor="let item of menuRoot; let i = index;" [item]="item"
          [index]="i" [root]="true" [id]="item.id" class="manu_for_collapse"></li>
    </ul>
  `
})
export class MenuComponent extends BaseComponent {
  user: User = new User();
  isAcesLicensed = false;
  menuRoot: MenuItem[] = [];

  constructor(@Inject(forwardRef(() => AppComponent))
  public app: AppComponent,
    private menuService: MenuService,
    private userService: SecurityUserService) {
    super();

    this.buildMenu();

    // observe on user login action
    this.userService.userInfo
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(userInfo => {
        if (userInfo.isAuthenticated) {
          this.buildMenu();
        }
      });
  }

  buildMenu() {

    this.menuRoot = this.menuService.buildMainMenu();

    // ARO: REMOVE ENTRIES FROM BELOW WHEN THE FEATURE IS MOVED OVER TO THE NEW DYNAMIC MENU SYSTEM BUILT ABOVE!


    // sub-nodes are already hidden if not visible but need to manually remove non-visible top-level nodes
    this.menuRoot = this.menuRoot.filter(o => o.visible);

  }

  addHeader(label: string, icon: string, id: string, items: MenuItem[]): MenuItem {
    return {
      label: label,
      icon: icon,
      id: id,
      items: items,
      visible: items.findIndex(i => i.visible) !== -1
    }
  }

  addUserManagement(appName: string, appUserPermissionName: string, roleAdminPermissionName: string): MenuItem {
    const node = {
      label: 'User Permissions',
      icon: 'manage_accounts',
      id: 'mnu-securityManagement',
      items: [
        {
          label: 'Users',
          icon: 'person_outline',
          routerLink: [MenuService.UsersAdmin.Link],
          id: 'mnu-' + MenuService.UsersAdmin.Link,
          queryParams: { app: appName },
          visible: this.userService.userHasPermission(roleAdminPermissionName)
        },
        {
          label: 'Roles',
          icon: 'people_alt',
          routerLink: [MenuService.RolesAdmin.Link],
          id: 'mnu-' + MenuService.RolesAdmin.Link,
          queryParams: { app: appName },
          visible: this.userService.userHasPermission(appUserPermissionName)
        }
      ],
      visible: true
    }

    node.visible = node.items.findIndex(i => i.visible) !== -1;
    return node;
  }

  addLink(component: ComponentLink): MenuItem {
    return {
      label: component.Label,
      icon: component.Icon,
      routerLink: [component.Link],
      id: 'mnu-' + component.Link,
      visible: component.Permission === undefined || this.userService.userHasPermission(component.Permission)
    }
  }

}
