<p-confirmDialog header="Confirm Deletion" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-blockUI [blocked]="loadingCount > 0">
  <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<div class="col-12 card p-3">
  <div class="card-header m-2">
    <h5>{{pageTitle}}</h5>
    <div class="p-toolbar-group-end">
      <button *ngIf="canAdd" pButton pRipple label="Add" id="addButton" icon="pi pi-plus" (click)="openNew()"></button>
    </div>
  </div>
  <p-table id="clpackage" sortField="name" #clpackage [value]="clientpackages" [globalFilterFields]="[
        'name',
        'description',
        'conversiontype_name',
        'datatype',
        'ziptype',
        'primary',
        'secondary'
      ]"
      [showCurrentPageReport]="true" [paginator]="clientpackages.length > defaultTableRows" [rowsPerPageOptions]="[20,50,100,200]" [rows]="defaultTableRows" currentPageReportTemplate="{{currentPageReport + ' ' + pageTitle}}" 
      dataKey="dataredaction" (onRowSelect)="onRowSelect($event)" selectionMode="single">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of cols" [pSortableColumn]="col.field">
          <div class="flex justify-content-start align-items-center" *ngIf="col.field != 'Action' && col.field != 'primary' && col.field != 'secondary'">
            {{ col.header }}
            <p-sortIcon [field]="col.field"></p-sortIcon>
            <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu"
              [field]="col.field" type="text" matchMode="contains"></p-columnFilter>
          </div>
          <div class="flex justify-content-start align-items-center" *ngIf="col.field == 'primary' || col.field == 'secondary'">
            {{ col.header }}
            <p-sortIcon [field]="col.field"></p-sortIcon>
            <p-columnFilter class="ml-0" [field]="col.field" display="menu" type="boolean" pTooltip="Filter"></p-columnFilter>
          </div>
          <div *ngIf="col.field == 'Action'">
            {{ col.header }}
          </div>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-clpackage>
      <tr [pSelectableRow]="clpackage" class="p-selectable-row">
        <td>{{ clpackage.name }}</td>
        <td>{{ clpackage.description }}</td>
        <td>{{ clpackage.conversiontype_name}}</td>
        <td>{{ clpackage.datatype}}</td>
        <td>{{ clpackage.ziptype }}</td>
        <td> <p-checkbox [disabled]="true"  [ngModel]="clpackage.primary" id="txtsec" name="txt-sec"
          [binary]="true" inputId="binary"></p-checkbox></td>
        <td><p-checkbox [disabled]="true"  [ngModel]="clpackage.secondary" id="txtprim" name="txt-prim"
          [binary]="true" inputId="binary"></p-checkbox></td>
        <td>
          <div class="flex align-items-center justify-contents-end">
            <button id="cloneButton" name="cloneButton" type="button" class="p-button-rounded p-button-text mr-2"
              pButton icon="pi pi-clone" pRipple pTooltip="Clone..." *ngIf="canAdd"
              (click)="clonePackage(clpackage)"></button>
            
          </div>
        </td>
        <td>
          <div class="flex align-items-center justify-contents-end">
            <button id="deleteButton" name="deleteButton" type="button"
            class="p-button-rounded p-button-text p-button-danger" pButton pRipple icon="pi pi-trash"
            pTooltip="Delete..." *ngIf="canDelete"  (click)="deleteClientPackage(clpackage)"></button>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="10">No Enhanced Packages Found.</td>
      </tr>
    </ng-template>
  </p-table>
  <div *ngIf="clientpackages.length > 0 && defaultTableRows >= clientpackages.length" style="text-align: center;">
    <p class="row-paging">Showing 1 to
      {{clientpackages.length}} of {{clientpackages.length}} {{pageTitle}}</p>
  </div>
</div>