import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { Airport } from 'src/app/features/administration/models/airport';
import { AirportService } from 'src/app/features/administration/services/airport.service';
import { ApiType, NavigationInformationBaseViewComponent } from '../navigation-information-base/navigation-information-base-view.component';
import { Components } from 'src/app/features/administration/integration/administration.components';


@Component({
  selector: 'app-airport-details',
  templateUrl: './airport-details.component.html',
})

export class AirportDetailsComponent extends NavigationInformationBaseViewComponent implements OnInit {
  airport: Airport;
  pageTitle = Components.AirportDetails.label;
  dte: string
  file_dates: any;
  selected_date: any;
  display_icao_iata: string;
  icao: string
  constructor(
    private airportService: AirportService,
    messageService: MessageService,
    confirmationService: ConfirmationService,
    router: Router,
    route: ActivatedRoute,
    breadcrumbService: BreadcrumbService) {
    super(messageService, confirmationService, router, breadcrumbService, route)
    this.icao = route.snapshot.queryParamMap.get('a');
    this.display_icao_iata = route.snapshot.queryParamMap.get('b');
    this.dte = route.snapshot.queryParamMap.get('c');
    const file_dates = route.snapshot.queryParamMap.get('d');

    this.selected_date = { "dte": this.dte };
    this.file_dates = JSON.parse(file_dates);
    this.setBreadcrumb(this.display_icao_iata);
  }

  ngOnInit(): void {
    this.setBreadcrumb(this.display_icao_iata);
    this.getAirport(this.dte, this.icao)
  }

  callback(data) {
    this.data = data;
  }

  getAirport(dte, icao) {
    const arg: ApiType = { request: this.airportService.getAirport(dte, icao), api_name: 'Airport', callback: this.callback.bind(this) }
    this.callAPI(arg)
  }

  formatDecimals(param: any, unit = ''): string | number {
    if (typeof param === 'string') {
      const num = parseFloat(param);
      if (!isNaN(num)) {
        // Convert number to string with 8 decimal places
        let formattedNum = num.toFixed(8);
        // Remove trailing zeros
        formattedNum = parseFloat(formattedNum).toString();
        // Return the formatted number if it's not a decimal
        if (num % 1 !== 0) {
          return `${formattedNum} ${unit}`;
        }
        // Return the number without decimal places if it's an integer
        return `${num} ${unit}`;
      }
    }
    // If param is not a valid number or is not a string, return "-" if null, otherwise return param as string
    return param ? `${param.toString()} ${unit}` : '-';
  }

  override setBreadcrumb(display_icao_iata) {
    const items = [
      {
        label: Components.AirportList.label,
        routerLink: Components.AirportList.path,
        queryParams: { a: this.selected_date["dte"] }
      },
      {
        label: Components.AirportDetails.label + ` (${display_icao_iata || "-"})`
      }
    ];
    super.setBreadcrumb(items);
  }

  override onChange($event) {
    super.onChange([Components.AirportDetails.path], {
      queryParams: {
        a: this.icao,
        b: this.display_icao_iata,
        c: this.selected_date["dte"],
        d: JSON.stringify(this.file_dates)
      }
    })
  }
}
