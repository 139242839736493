import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {MenuService} from '../../common/services/menu.service';

@Component({
  selector: 'app-page-not-found',
  template: `
    <div>
      <div class="card mb-0 p-5" style="text-align: center; color: rgba(0,0,0,0.6)">
        <i class="pi pi-exclamation-circle" style="font-size: 72px"></i>
        <h1 class="my-5" style="font-weight: 500">Page Not Found</h1>
        <button id="homepage" name="homepage" pButton pRipple
                icon="pi pi-home" label="Dashboard"
                (click)="onClickHome()"></button>
      </div>
    </div>
  `
})
export class PageNotFoundComponent {
  constructor(private router: Router) {
  }

  onClickHome() {
    this.router.navigate([MenuService.Dashboard.Link]).then();
  }
}
