<p-confirmDialog
  header="Confirm Deletion"
  icon="pi pi-exclamation-triangle"
></p-confirmDialog>
<p-blockUI [blocked]="loadingCount > 0">
  <tdy-spinner
    style="position: absolute; left: 50%; top: 50%; z-index: 10000"
  ></tdy-spinner>
</p-blockUI>

<div class="col-12 card p-3">
  <div class="card-header">
    <h5>{{ pageTitle }}</h5>
    <div class="p-toolbar-group-end">
      <button
        pButton
        pRipple
        *ngIf="canAdd"
        label="Add"
        id="addButton"
        icon="pi pi-plus"
        (click)="openNew()"
      ></button>
    </div>
  </div>
  <app-acmsreport-table
    [acmsreports]="acmsreports"
    [pageTitle]="pageTitle"
    [canAdd]="canAdd"
    [canDelete]="canDelete"
    (onRowSelectEvent)="onRowSelect($event)"
    (cloneACMSEvent)="cloneAcms($event)"
    (deleteACMSEvent)="deleteACMS($event)"
  ></app-acmsreport-table>
</div>
