<p-blockUI [blocked]="loadingCount > 0 && !processUploadedData">
  <p-dialog [visible]="true" [closable]="false" header="File Upload Status" [style]="{width: '50vw'}">
    <div>
      <p-progressBar mode="indeterminate" [style]="{'height': '5px'}" [showValue]="false"></p-progressBar>
      <div style="margin-top: 10px;">{{uploadedFiles}}/{{files.length}} files uploaded</div>
    </div>
  </p-dialog>
</p-blockUI>
<p-blockUI [draggable]="true" [blocked]="dragFiles"></p-blockUI>
<p-blockUI [blocked]="loadingCount > 0 && processUploadedData">
  <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<p-confirmDialog header="Confirm Deletion" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<div class="col-10 card p-3">
  <div *ngIf="!processUploadedData" class="card-header">
    <h5>File Selection</h5>
  </div>
  <div *ngIf="!processUploadedData" class="upload-container" (drop)="onDrop($event)" (dragover)="onDragOver($event)">
    <div class="drag-drop-container">
      <div class="upload-text">
        <img style="height:45px;" draggable="false" src="../../../../../assets/images/upload-files.svg"
          alt="upload-files">
        <p style="margin-bottom: 0px;">
          Drop Files, or click on Add.
        </p>
        <p> Max file size: <b>5GB</b></p>
      </div>
    </div>
  </div>
  <div *ngIf="!processUploadedData" class="p-fileupload p-fileupload-advanced p-component">
    <div class="p-fileupload-content">
      <p-table #dt [value]="files" [globalFilterFields]="['name', 'size']"
        [(selection)]="selectedFiles" [rowHover]="true"
        [showCurrentPageReport]="true" [paginator]="files.length > defaultTableRows" 
        [rowsPerPageOptions]="[20,50,100,200]" [rows]="defaultTableRows" 
        currentPageReportTemplate="{{currentPageReport + ' ' + 'files'}}" 
        dataKey="name" [tableStyle]="{'min-width': '50rem'}">
        <ng-template pTemplate="caption">
          <div class="flex align-items-center justify-content-start">
            <h5 class="m-0">Manage Files</h5>
            <div>
              <label for="fileInput" class="p-button choose-button">
                <span class="p-fileupload-button">
                  <i class="pi pi-plus"></i>
                  <span file-list="p-button-label">Add</span>
                </span>
              </label>
              <input type="file" #fileInput id="fileInput" class="p-fileupload-native visually-hidden"
                (change)="onFileSelected($event)" accept=".zip" multiple>
              <button style="margin-left: 5px;" pButton pRipple label="Remove" icon="pi pi-trash"
                class="p-button-danger" (click)="removeSelectedFiles()"
                [disabled]="!selectedFiles || !selectedFiles.length"></button>
            </div>
          </div>
        </ng-template>

        <ng-template pTemplate="header">
          <tr>
            <th style="width: 4rem">
              <p-tableHeaderCheckbox [disabled]="!files.length"></p-tableHeaderCheckbox>
            </th>
            <th pSortableColumn="name" style="width:40%">
              <div class="flex justify-content-start align-items-center">File Name
              <p-sortIcon field="name"></p-sortIcon>
              <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu"
                field="name" header="name" matchMode="contains"  type="text"></p-columnFilter>
                </div>
            </th>
            <th pSortableColumn="size" style="width:40%">
              <div class="flex justify-content-start align-items-center">
              File Size
              <p-sortIcon field="size"></p-sortIcon>
              <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu"
                field="size" header="size"  matchMode="contains"  type="text"></p-columnFilter>
                </div>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-file let-tableRow>
          <tr>
            <td>
              <p-tableCheckbox [value]="file"></p-tableCheckbox>
            </td>
            <td>{{file.name}}</td>
            <td>{{file.size | fileSizeFormat}}</td>

          </tr>
        </ng-template>
      </p-table>
      <div *ngIf="files.length > 0 && defaultTableRows >= files.length" style="text-align: center;">
        <p class="row-paging">Showing 1 to
          {{files.length}} of {{files.length}} files</p>
      </div>
    </div>
  </div>
  <div *ngIf="processUploadedData" class="custom-file-display">
    <p-table #dt [value]="fileUploadResponse" [globalFilterFields]="['filename']"
      [rowHover]="true" [showCurrentPageReport]="true" [paginator]="fileUploadResponse.length > defaultTableRows"
      [rowsPerPageOptions]="[20,50,100,200]" [rows]="defaultTableRows" 
      currentPageReportTemplate="{{currentPageReport + ' ' + 'files'}}" 
       dataKey="name" [tableStyle]="{'min-width': '50rem'}">
      <ng-template pTemplate="caption">
        <div class="flex align-items-center justify-content-start">
          <h5 class="m-0">Aircraft Selection</h5>
        </div>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th style="width:10%">No</th>
          <th pSortableColumn="filename" style="width:30%">
            <div class="flex justify-content-start align-items-center">File Name
            <p-sortIcon field="filename"></p-sortIcon>
            <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu"
              field="filename" header="filename" matchMode="contains"  type="text"></p-columnFilter>
              </div>
          </th>
          <th pSortableColumn="aircraft" style="width:30%">
            <div class="flex justify-content-start align-items-center">Aircraft Registration
            <p-sortIcon field="aircraft"></p-sortIcon>
            <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu"
              field="aircraft" header="aircraft" matchMode="contains"  type="text"></p-columnFilter>
              </div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-file let-rowIndex="rowIndex">
        <tr>
          <td>{{rowIndex + 1}}</td>
          <td>{{file.filename}}</td>
          <td><p-dropdown placeholder="Select Aircraft" [style]="{width: '50%'}" [options]="aircraft" [overlayOptions]="{appendTo: 'body', baseZIndex: 10000, autoZIndex: true, showTransitionOptions: '120ms cubic-bezier(0, 0, 0.2, 1)',
          hideTransitionOptions: '100ms linear'}" [(ngModel)]="file.aircraft" (onChange)="countSelectedAircraft()" id="reg" name="reg"></p-dropdown></td>
        </tr>
      </ng-template>
      <ng-template *ngIf="fileUploadResponse?.length > 0" pTemplate="summary">
        <div class="flex align-items-center justify-content-start">Selected aircraft: {{countSelectedAircraft()}} out of {{fileUploadResponse?.length}}</div>
    </ng-template>
    </p-table>
    <div *ngIf="fileUploadResponse?.length > 0 && defaultTableRows >= fileUploadResponse.length" style="text-align: center;">
      <p class="row-paging">Showing 1 to
        {{fileUploadResponse.length}} of {{fileUploadResponse.length}} files</p>
    </div>
  </div>
  <div *ngIf="processUploadedData && failedFiles.length > 0" style="margin-top: 12px;">
    <p-table [value]="failedFiles" [tableStyle]="{'min-width': '50rem'}">
      <ng-template pTemplate="caption">
        <div class="flex align-items-center justify-content-start">
          <h5 class="m-0">Failed Files</h5>
        </div>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="filename" style="width:30%">File Name<p-sortIcon field="filename"></p-sortIcon></th>
          <th style="width:30%">Error</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-file let-rowIndex="rowIndex">
        <tr>
          <td>{{file.filename}}</td>
          <td>{{file.error}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="flex justify-content-end" style="margin-top: 20px;">
    <span class="p-input-icon-left">
      <button (click)="onCancel()" [disabled]="files.length == 0 && !processUploadedData" class="p-button-text"
        icon="pi pi-times" id="cancelButton" label="Cancel" pButton pRipple type="button"></button>
    </span>

    <span *ngIf="processUploadedData" class="p-input-icon-left pl-2">
      <button (click)="onSubmit()" [disabled]="fileUploadResponse.length == 0" icon="pi pi-check"
        id="okButton" label="Submit" pButton pRipple type="submit"></button>
    </span>
    <span *ngIf="!processUploadedData" class="p-input-icon-left pl-2">
      <button (click)="onUpload()" [disabled]="files.length == 0" icon="pi pi-cloud-upload" id="okButton" label="Upload"
        pButton pRipple type="submit"></button>
    </span>
  </div>
</div>