import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ACMS } from 'src/app/features/datadelivery/models/ACMS';

@Component({
  selector: 'app-acmsreport-table',
  templateUrl: './acmsreport-table.component.html',
})
export class AcmsreportTableComponent {
  cols: any[];
  @Input() pageTitle: string;
  @Input() acmsreports: ACMS[] = [];
  @Input() canAdd: boolean;
  @Input() canDelete: boolean;
  @Input() defaultTableRows: number;
  @Input() currentPageReport: string;

  @Output() onRowSelectEvent = new EventEmitter<ACMS>();
  @Output() cloneACMSEvent = new EventEmitter<ACMS>();
  @Output() deleteACMSEvent = new EventEmitter<ACMS>();

  ngOnInit() {
    this.cols = [
      {
        field: 'name',
        header: 'Name',
      },
      {
        field: 'description',
        header: 'Description',
      },
      {
        field: 'acmsreportname',
        header: 'ACMS Report Map',
      },
      {
        field: 'defaultparametervaluesconfig',
        header: 'Regex',
      },
      {
        field: 'clone',
        header: 'Action',
      },
      {
        field: 'delete',
        header: 'Action',
      },
    ];
  }

  onRowSelect(event: ACMS) {
    this.onRowSelectEvent.emit(event);
  }

  cloneAcms(acms: ACMS) {
    this.cloneACMSEvent.emit(acms);
  }

  deleteACMS(acms: ACMS) {
    this.deleteACMSEvent.emit(acms);
  }
}
