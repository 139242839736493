import {takeUntil} from 'rxjs/operators';
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {MessageService, TreeNode} from 'primeng/api';
import {BaseComponent} from 'src/app/common/components/base.component';
import {MainComponent} from 'src/app/platform/components/main/main.component';
import {UserService} from 'src/app/features/administration/services/user.service';
import {User} from 'src/app/features/administration/models/user';
import {BreadcrumbService} from 'src/app/common/services/breadcrumb.service';
import {Constants} from 'src/app/common/models/constants';

// Simple class to store in tail TreeNodes to bind to in the GUI. Note that property names MUST
// differ from AOD property names (domain\aod.ts) or they will be affected by sort/filter logic
class TailNodeData {
  tailID: number;
  maxAqi: number;
  maxAqiColor: string;
  onWatchlist: boolean;
}

@Component({
  selector: 'app-rightmenu',
  templateUrl: './rightmenu.component.html'
})
export class RightMenuComponent extends BaseComponent implements OnInit {
  loggedInUser: User = null;
  loadingCount = 0;  // Number of functions that are loading data, block page while not zero.
  searchTimer;
  searchQuery = '';
  noResults = false;  
  rootNodes: TreeNode[] = null;
  airlineMap = new Map<number, TreeNode>();
  tailMap = new Map<number, TreeNode>();
  deviceMap = new Map<number, TreeNode>();
  nodeLevelAirline = 0;
  nodeLevelTail = 1;
  nodeLevelAod = 2;
  AppConstants = Constants;  // Local reference so HTML can access

  @ViewChild('searchInput') searchInputViewChild: ElementRef;

  constructor(public appMain: MainComponent,
              private router: Router,              
              private userService: UserService,
              private breadcrumbService: BreadcrumbService,
              private messageService: MessageService) {
    super();
  }

  ngOnInit(): void {
    // this.loggedInUser = this.userService.getUserFromSession();
    // if (!this.loggedInUser) {
    //   this.userService.logOut();
    // }
  }

  onShow() {
    // Retain previous results so user can come back without having to search again, EXCEPT if previous
    // results were empty, then clear everything.
    if (this.noResults) {
      this.clearSearch();
    }
  }

  setFocus(element: HTMLInputElement) {
    setTimeout(() => {
      element.focus();
    }, 100);
  }

  onSubmit() {
    this.search(true);
  }

  onClickSearch() {
    this.search(true);
  }

  onKeyUp() {
    this.search(false);
  }

  search(quickSearch: boolean) {
    // Stop any existing timeout before we reset the timer, or it will fire.
    if (this.searchTimer) {
      clearTimeout(this.searchTimer)
    }

    if (this.searchQuery === '') {
      return;
    }

    const delay = (quickSearch ? 0 : 1000);

    this.searchTimer = setTimeout(() => {
      this.loadingCount++;

      // Clear out previous results, even if searching on same or no value so user can see the results flash.
      this.rootNodes = [];
      this.noResults = false;

    }, delay);
  }

  clearSearch() {
    this.searchInputViewChild.nativeElement.value = '';
    this.rootNodes = [];
    this.airlineMap.clear();
    this.tailMap.clear();
    this.deviceMap.clear();
    this.noResults = false;
  }

  /**
   * Creates the collection of TreeNodes to populate the tree table. If the user is TDY admin, then
   * the root nodes are airlines, otherwise it is tails.
   */
  createTreeNodes() {    

    this.rootNodes = [];

    let airlineNode: TreeNode = null;
    let tailNode: TreeNode = null;
    let deviceNode: TreeNode = null;

    // Maps of ID to TreeNode for quicker searching.
    this.airlineMap.clear();
    this.tailMap.clear();
    this.deviceMap.clear();

    this.sortTreeNodes(this.rootNodes);
    this.expandAll(this.rootNodes);
  }

  /**
   * Sorts the TreeNodes in the tree table alphabetically with the unassigned nodes last. This is meant to
   * be called recursively on any child collections.
   *
   * @param nodes - The array of TreeNodes to sort.
   */
  sortTreeNodes(nodes: TreeNode[]) {
    if (nodes == null) {
      return;
    }

    // Natural sort
    nodes.sort((a, b) => {
      if (a.label === Constants.UnassignedText) {
        return 1;
      }
      return a.label.localeCompare(b.label, navigator.languages[0] || navigator.language, {
        numeric: true,
        ignorePunctuation: true
      })
    });

    for (const n of nodes) {
      if (n.children != null && n.children.length > 0) {
        this.sortTreeNodes(n.children);
      }
    }
  }

  expandAll(nodes: TreeNode[]) {
    for (const node of nodes) {
      if (node.children) {
        node.expanded = true;
        for (const child of node.children) {
          child.expanded = true;
        }
      }
    }
  }
  
}
