import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { permissions } from '../../security/models/permissions';
import { FeatureHeader, FeatureItem, FeatureRoot, FeatureUserManagementConfig } from '../models/feature-item.model';
import { MenuItem } from '../models/menuItem';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { Components as AdministrationComponents } from 'src/app/features/administration/integration/administration.components';
import { Components as ConfigurationComponents } from 'src/app/features/configuration/integration/configuration.components';
import { Components as DataDeliveryComponents } from 'src/app/features/datadelivery/integration/datadelivery.components';
import { Components as EndpointDataDeliveryComponents } from 'src/app/features/endpointdatadelivery/integration/endpointdatadelivery.components';
import { Components as SendToTeledyneComponents } from 'src/app/features/sendtoteledyne/integration/sendtoteledyne.components';

export interface ComponentLink {
  Label: string;
  Link: string;
  Permission?: string;
  Icon?: string;
}

@Injectable()
export class MenuService {

  private static featureRoot: FeatureRoot[] = [];
  private readonly maxRecursionDepth = 5;

  constructor(
    private userService: SecurityUserService
  ) {
  }

  public static loadFeatures(featureRoot: FeatureRoot[]) {
    MenuService.featureRoot = featureRoot;
  }

  public getFeatures() {
    return MenuService.featureRoot;
  }

  addAppParamToUserDetails(menuItems: any[]) {
    const queryParamMap: { [key: string]: string } = {
      "Administration": "Administration",
      "Configuration": "Configuration",
      "Data Delivery": "Datadelivery",
      "Send to Teledyne": "SendToTdy",
      "Endpoint Data Delivery": "EndpointDataDelivery",
    };

    menuItems.forEach((menu) => {
        if (menu.items) {
            menu.items.forEach((item: any) => {
                if (item.id === 'mnu-user') {
                    const appQueryParam = queryParamMap[menu.label] || menu.label.replace(/\s+/g, '');

                    item.items?.forEach((subItem: any) => {
                        if (subItem.label === 'User Profile' || subItem.id === 'mnu-user-profile') {
                            subItem.queryParams = { app: appQueryParam };
                        }
                    });
                }
            });
        }
    });
  }

  public buildMainMenu(): MenuItem[] {
    const mainMenuMultiRoot = []
    MenuService.featureRoot.forEach(feature => {
      const existingUserItem = feature.items.find(item => item?.label === "User");

      if (!existingUserItem) {
        // If the item does not exist, add it
        feature.items.push({
          label: "User",
          icon: "person",
          items: (() => {
            const featureItems: FeatureItem[] = [];
            switch(feature.label){
              case "Administration":
                featureItems.push(AdministrationComponents.NotificationsViewForAdministration);
                break;
              case "Configuration":
                featureItems.push(ConfigurationComponents.NotificationsViewForConfiguration);
                break;
              case "Data Delivery":
                featureItems.push(DataDeliveryComponents.NotificationsViewForDataDelivery);
                break;
              case "Endpoint Data Delivery":
                featureItems.push(EndpointDataDeliveryComponents.NotificationsViewForEndpointDataDelivery);
                break;
              case "Send to Teledyne":
                featureItems.push(SendToTeledyneComponents.NotificationsViewForSendToTeldyne);
                break;
            }
            featureItems.push(AdministrationComponents.UserProfile)
            return featureItems;
          })()
        });
      }

      mainMenuMultiRoot.push(this.buildMenuBranch(feature));
      if (feature.user_management) {
        mainMenuMultiRoot[mainMenuMultiRoot.length - 1].items.push(this.buildUserManagement(feature.user_management))
      }
    })

    this.addAppParamToUserDetails(mainMenuMultiRoot)

    return mainMenuMultiRoot;
  }

  private buildUserManagement(config: FeatureUserManagementConfig): MenuItem {

    const node = {
      label: 'User Permissions',
      icon: 'manage_accounts',
      id: 'mnu-securityManagement',
      items: [
        {
          label: 'Users',
          icon: 'person_outline',
          routerLink: [MenuService.UsersAdmin.Link],
          id: 'mnu-' + MenuService.UsersAdmin.Link,
          queryParams: { app: config.feature_name },
          visible: this.userService.userHasPermission(config.user_permission)
        },
        {
          label: 'Roles',
          icon: 'people_alt',
          routerLink: [MenuService.RolesAdmin.Link],
          id: 'mnu-' + MenuService.RolesAdmin.Link,
          queryParams: { app: config.feature_name },
          visible: this.userService.userHasPermission(config.roles_permission)
        }
      ],
      visible: true
    }

    node.visible = node.items.findIndex(i => i.visible) !== -1;
    return node;
  }

  private buildMenuBranch(root: FeatureHeader, recursionDepth = 0): MenuItem {
    const items = [];
    if (root && root.items && Array.isArray(root.items)) {
      root.items.forEach(item => {
        if (item.path) {
          items.push({
            label: item.label,
            icon: item.icon,
            routerLink: [item.path],
            id: this.getMenuIdFromLabel(item.label),
            visible: item.permission === undefined || this.userService.userHasPermission(item.permission)
          })
        } else {
          // sub-header
          if (recursionDepth < this.maxRecursionDepth)
            items.push(this.buildMenuBranch(item, recursionDepth + 1));
        }
      });
    }

    return {
      label: root.label,
      icon: root.icon,
      id: this.getMenuIdFromLabel(root.label),
      items: items,
      visible: items.findIndex(i => i.visible) !== -1
    }
  }

  private getMenuIdFromLabel(label: string): string {
    return 'mnu-' + label.toLowerCase().replace(' ', '-');
  }

  // ARO All code below here will get moved / deleted as features are refactored

  // Infrastructure

  public static readonly Dashboard: ComponentLink = {
    'Label': 'Home',
    'Link': '/',
    'Permission': undefined
  }

  public static readonly Login: ComponentLink = {
    'Label': 'Login',
    'Link': 'login',
    'Permission': undefined
  }

  public static readonly AccessDenied: ComponentLink = {
    'Label': 'Access Denied',
    'Link': 'access-denied',
    'Permission': undefined
  }

  public static readonly Errors: ComponentLink = {
    'Label': 'Application Errors',
    'Link': 'errors',
    'Permission': undefined
  }

  public static readonly UsersAdmin: ComponentLink = {
    'Label': 'User Roles',
    'Link': 'users-administration',
    'Permission': undefined
  }

  public static readonly UserDetailsAdmin: ComponentLink = {
    'Label': 'User Details',
    'Link': 'user-details-administration',
    'Permission': permissions.admin.users.manage //TDY ONLY
  }

  public static readonly RolesAdmin: ComponentLink = {
    'Label': 'Roles',
    'Link': 'roles-administration',
    'Permission': undefined
  }

  public static readonly RoleDetailsAdmin: ComponentLink = {
    'Label': 'Role Details',
    'Link': 'role-details-administration',
    'Permission': undefined
  }

  // DDS

  public static readonly DDSTest: ComponentLink = {
    'Label': 'Test',
    'Link': 'dds-test',
    'Permission': undefined,
    'Icon': 'quiz'
  }

  // Send to Teledyne

  public static readonly SendToTeledyneTest: ComponentLink = {
    'Label': 'Test',
    'Link': 'send-to-teledyne-test',
    'Permission': undefined,
    'Icon': 'quiz'
  }

  //End

  public static readonly Endpoints: ComponentLink = {
    'Label': 'Endpoints',
    'Link': 'endpoints',
    'Icon': 'send',
    'Permission': undefined
  }

  public static readonly AddEndpoints: ComponentLink = {
    'Label': 'Add Endpoints',
    'Link': 'addendpoints',
    'Permission': undefined
  }

  public static readonly DataRedactions: ComponentLink = {
    'Label': 'Flight Data Redactions',
    'Link': 'dataredactions',
    'Icon': 'flight',
    'Permission': undefined
  }

  public static readonly AddDataRedactions: ComponentLink = {
    'Label': 'Add Data Redactions',
    'Link': 'adddataredactions',
    'Permission': undefined
  }

  public static readonly ACMSReportRedactions: ComponentLink = {
    'Label': 'ACMS Report Redactions',
    'Link': 'acmsreportredactions',
    'Permission': undefined
  }

  public static readonly ACMSReportAddRedactions: ComponentLink = {
    'Label': 'Add ACMS Report Redactions',
    'Link': 'acmsreportaddredactions',
    'Permission': undefined
  }

  public static readonly SARRedactions: ComponentLink = {
    'Label': 'SAR Redactions',
    'Link': 'sarredactions',
    'Permission': undefined
  }

  public static readonly AddSARRedactions: ComponentLink = {
    'Label': 'Add SAR Redactions',
    'Link': 'saraddredactions',
    'Permission': undefined
  }

  public static readonly ClientPackages: ComponentLink = {
    'Label': 'Client Packages',
    'Link': 'clientpackages',
    'Permission': undefined
  }

  public static readonly AddClientPackages: ComponentLink = {
    'Label': 'Add Client Packages',
    'Link': 'addclientpackages',
    'Permission': undefined
  }

  public static readonly FlightDataRedactions: ComponentLink = {
    'Label': 'Flight Data Redactions',
    'Link': 'flightdataredactions',
    'Icon': 'flight',
    'Permission': undefined
  }

  public static readonly AddFlightDataRedactions: ComponentLink = {
    'Label': 'Add Flight Data Redactions',
    'Link': 'addflightdataredactions',
    'Permission': undefined
  }

  public static readonly ClientApplication: ComponentLink = {
    'Label': 'Client Authentication',
    'Link': 'clientapplication',
    'Permission': undefined
  }
  public static readonly AddClientApplication: ComponentLink = {
    'Label': 'Add Client Authentication',
    'Link': 'addclientapplication',
    'Permission': undefined
  }
  public static readonly TeledynePackages: ComponentLink = {
    'Label': 'Teledyne Packages',
    'Link': 'teledynepackages',
    'Permission': undefined
  }

  public static readonly AddTeledynePackages: ComponentLink = {
    'Label': 'Add Teledyne Packages',
    'Link': 'addteledynepackages',
    'Permission': undefined
  }

  public static readonly SendToTeledyne: ComponentLink = {
    'Label': 'Send to Teledyne',
    'Link': 'sendtoteledyne',
    'Permission': undefined
  }

  public static readonly AddSendToTeledyne: ComponentLink = {
    'Label': 'Add to Teledyne',
    'Link': 'addsendtoteledyne',
    'Permission': undefined
  }

  public static readonly TeledyneEndPoint: ComponentLink = {
    'Label': 'Teledyne Endpoints',
    'Link': 'teledyneendpoints',
    'Permission': undefined
  }

  public static readonly TeledyneAddEndPoint: ComponentLink = {
    'Label': 'Add Endpoints',
    'Link': 'addteledyneendpoints',
    'Permission': undefined
  }


  public static readonly AirportInformationList: ComponentLink = {
    'Label': 'Airport Information',
    'Link': 'airportinformationlist',
    'Permission': undefined
  }
  public static readonly AirportInformationDetails: ComponentLink = {
    'Label': 'Airport Information Details',
    'Link': 'airportinformationdetails',
    'Permission': undefined
  }
  public static readonly RunwayInformationList: ComponentLink = {
    'Label': 'Runway Information',
    'Link': 'runwayinformationlist',
    'Permission': undefined
  }
  public static readonly RunwayInformationDetails: ComponentLink = {
    'Label': 'Runway Information Details',
    'Link': 'runwayinfodetails',
    'Permission': undefined
  }
  // ARO: old menu items still plumbed into the ported ACES code.
  // Not moved over to new structure because they are not used in the current code.

  public static readonly AircraftStatusLabel = 'Aircraft';
  public static readonly AircraftStatusLink = 'aqi-by-tail';

  public static readonly NotificationEventsLabel = 'User Notifications';
  public static readonly NotificationEventsLink = 'notifications';

  public static readonly AuditEventsLabel = 'Audit Log';
  public static readonly AuditEventsLink = 'audit-log';

  public static readonly ErrorsLabel = 'Application Errors';
  public static readonly ErrorsLink = 'errors';

  private menuSource = new Subject<string>();
  menuSource$ = this.menuSource.asObservable();
  private resetSource = new Subject();
  resetSource$ = this.resetSource.asObservable();

  onMenuStateChange(key: string) {
    this.menuSource.next(key);
  }

  reset() {
    this.resetSource.next(null);
  }
}
