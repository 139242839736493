// Environment file for running under AWS DEV env

const aws_api_url = 'https://api-spectrum.teledyne.dstbtd.dev/';

export const environment = {
  configurationName: "development",
  production: false,
  oktaUrl: 'https://teledynecontrolsdev2.okta.com',
  oktaClientAppId: '0oa464ynfmwVaKrPY697',
  apiBase: aws_api_url,
  apiUrl: {
    'admin': aws_api_url + 'administration/',
    'config': aws_api_url + 'configuration/',
    'datadelivery': aws_api_url + 'data-delivery/',
    'security': aws_api_url + 'security/',
    'common': aws_api_url + 'common/',
    'sendToTdy': aws_api_url + 'send-to-tdy/',
    'endpointdatadelivery': aws_api_url + 'endpoint-data-delivery/',
  }
};
