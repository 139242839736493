import { Component, OnInit, ViewChild } from "@angular/core";
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/common/services/breadcrumb.service";
import { MenuService } from "src/app/common/services/menu.service";
import { AcmsReportMapModel } from "src/app/features/configuration/models/acmsReportMap";
import { AcmsReportMapService } from "src/app/features/configuration/services/acms-report-map.service";
import { FileSizeFormatPipe } from "src/app/platform/pipes/file-size-format.pipe";
import { Organization } from "src/app/features/administration/models/organization";
import { acmsReportMapDetailsInput } from "../../input-model/acms-report-map-input";
import { BaseDetailsComponent } from "../../base/base-details-component";
import { permissions } from "../../../../integration/configuration.permissions";
import { SecurityUserService } from "src/app/security/services/security-user.service";
import { Components } from '../../../../integration/configuration.components';

@Component({
    selector: 'app-acms-report-map-details',
    templateUrl: '../../base/add.component.html',
    providers: [{provide: acmsReportMapDetailsInput  ,
      useFactory:(acmsReportMapService: AcmsReportMapService) => new acmsReportMapDetailsInput(acmsReportMapService) 
     , 
     deps:[AcmsReportMapService]
    }
      
     ]
     
    
  })
  export class AcmsReportMapDetailsComponent  extends BaseDetailsComponent<AcmsReportMapModel,Organization> implements OnInit{
   
     
   
   //@ViewChild('ContentUpload') content : FileUpload;
   //@ViewChild('acmsReportMapForm') airlineForm: NgForm;    
  
    constructor(
     private route: ActivatedRoute,
     router: Router,
     private location: Location,
     messageService: MessageService,
     confirmationService: ConfirmationService,
     breadcrumbService: BreadcrumbService,
     fileSizeFormatter: FileSizeFormatPipe,
     acmsReportInput: acmsReportMapDetailsInput,
     private realUserService: SecurityUserService,
    )
    {
     
       super(messageService,confirmationService,router,breadcrumbService,fileSizeFormatter, acmsReportInput);
      
        /* 
        if(this.route.snapshot.queryParamMap.get('a')){
         
          this.breadcrumbService.setItems(
            this.route,
            [
              {label: Components.AcmsReportMap.label, routerLink: Components.AcmsReportMap.path},
              {label: `Manage ACMS Report Map`}
            ]
            );
        }
       else{
          this.breadcrumbService.setItems(
            this.route,
            [
              {label: Components.AcmsReportMap.label, routerLink: Components.AcmsReportMap.path},
              {label: `New ${Components.AcmsReportMap.label}`}
            ]
            );
        }*/
    }


    ngOnInit(): void{
        
      this.userCanAdd = this.realUserService.userHasPermission(permissions.acms_report.create);
      this.userCanModify = this.realUserService.userHasPermission(permissions.acms_report.manage);
      

        //Manage Acms report Section 
        if(this.route.snapshot.queryParamMap.get('a')){
         
          if(!this.userCanModify){
            this.messageService.add({
              key: 'message',
              severity: 'error',
              summary: 'Unauthorized',
              detail: 'User is not authorized to add an acms report map.'
          });

           // Wait a few seconds for user to see the error, and send them back to where they came from.
           setTimeout(() => {
              this.location.back();
            }, 3000);
          }
          
          
          const name=this.route.snapshot.queryParamMap.get('Name');
          this.breadcrumbService.setItems(
            this.route,
            [
              {label: Components.AcmsReportMap.label, routerLink: Components.AcmsReportMap.path},
              {label: `Manage ACMS Report Map`}
            ]
            );
          this.load_Record(+this.route.snapshot.queryParamMap.get('a'))

        }
        else{// Add New Acms report Section
         
          if(this.userCanAdd){
            this.breadcrumbService.setItems(
              this.route,
              [
                {label: Components.AcmsReportMap.label, routerLink: Components.AcmsReportMap.path},
                {label: `New ${Components.AcmsReportMap.label}`}
              ]
              );
            this.load_Record(-1);
          }
          else{
            this.messageService.add({
                key: 'message',
                severity: 'error',
                summary: 'Unauthorized',
                detail: 'User is not authorized to add an acms report map.'
            });

             // Wait a few seconds for user to see the error, and send them back to where they came from.
             setTimeout(() => {
                this.location.back();
              }, 3000);
          }
        }
    }
    /*
    onSubmit(){
      this.loadingCount++;   
     
        if (this.isNew){
         
          this.acmsReportMapService.addAcmsReportMap(this.record,this.FileToSave).pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
           next: res=>{
            this.isSaveDisabled = true;
            this.record["name"] = "";
            this.record.version = null;
            this.content.clear();
            this.reportSuccess(`Acms report map ${this.record.name} added successfully`,'Success');
           },
           error: err=>{ 
            console.log(err)
            this.reportError(`Error adding Acms report map ${this.record.name} with error ${err}`, `Failure : Add new Acms report map`);
            this.loadingCount--;
          },
           complete: () =>  this.loadingCount--
          })
        
       }
       else{
        const airlinesId = `[${this.selectedAirlines.join(",")}]`
       
        this.acmsReportMapService.manageAcmsReportMap(this.record.acmsreportmap_version_id,airlinesId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: res=>{
            this.reportSuccess(`Acms report map ${this.record.name} updated successfully`,`Success`);
            this.originalSelectedAirlines = this.selectedAirlines;
            this.airlineForm.form.markAsPristine();
            this.isSaveDisabled = true;
          },
          error: err=>{console.log(err)
          this.reportError(`Error updating Acms report map ${this.record.name} with error ${err.statusText}`,`Failure : Manage Acms report map`);
          this.loadingCount--;
        },
          complete: () =>this.loadingCount-- 
        
        })
         
       }
       
    }
    */
    
  }