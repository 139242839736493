import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/common/services/base.service';
import { Organization } from '../models/organization';
import { AirlineInfo } from '../models/airlineInfo';
import { AirlineFilter } from '../models/airlineFilter';
import { environment } from 'src/environments/environment';
import { SettingPair } from 'src/app/common/models/setting';

export class AirlinePayloadModel {
  id: number; code?: string; name: string; web_logo?: string; mobile_logo?: string; airline_iata_code?: string; airline_settings?: SettingPair[];
}
@Injectable()
export class AirlineService extends BaseService {
  rootAirlineUrl = environment.apiUrl.admin + 'airline';

  constructor(private http: HttpClient) {
    super();
  }

  getAirline(airlineID: number): Observable<Organization> {
    const url = this.rootAirlineUrl + `/${airlineID}`;
    return this.http.get<Organization>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError),
      );
  }

  getCurrentAirline(): Observable<Organization> {
    const url = environment.apiUrl.admin + 'current-airline';
    return this.http.get<Organization>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError),
      );
  }

  listAirlines(): Observable<Organization[]> {
    return this.http.get<Organization[]>(environment.apiUrl.admin + 'airline', { headers: this.httpGetHeadersForJSON(true) })
      .pipe(
        catchError(this.httpHandleError)
      );
  }

  addAirline(addAirlinePayload: AirlinePayloadModel): Observable<number> {
    return this.http.post<number>(this.rootAirlineUrl, addAirlinePayload, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError)
      );
  }

  updateAirline(airline: AirlinePayloadModel): Observable<number> {
    const url = this.rootAirlineUrl + `/${airline.id}`;
    return this.http.put<number>(url, airline, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError));
  }

  updateCurrentAirline(currentAirline: AirlinePayloadModel): Observable<number> {
    const url = environment.apiUrl.admin + 'current-airline';
    return this.http.put<number>(url, currentAirline, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError),
      );
  }

  getUserAssignedAirlines(userID: number): Observable<Organization[]> {
    const url = this.rootAirlineUrl + 'users/' + userID;
    return this.http.get<Organization[]>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError)
      );
  }

  save(airline: Organization, logoBinary: File, logoMobileBinary: File, licenseFile: File, fleetFile: File): Observable<number> {
    const httpHeaders = this.httpGetHeadersForJSON();
    httpHeaders.append('Accept', 'multipart/form-data');
    const options = { headers: httpHeaders };

    const formData = new FormData();
    formData.append('airline', JSON.stringify(airline));

    if (logoBinary != null) {
      formData.append('logoBinary', logoBinary);
    }
    if (logoMobileBinary != null) {
      formData.append('logoMobileBinary', logoMobileBinary);
    }
    if (licenseFile != null) {
      formData.append('licenseFile', licenseFile);
    }
    if (fleetFile != null) {
      formData.append('fleetImport', fleetFile);
    }

    return this.http.post<number>(this.rootAirlineUrl, formData, options)
      .pipe(
        catchError(this.httpHandleError)
      );
  }

  getAirlineICAOCodes(): Observable<Organization[]> {
    const url = this.rootAirlineUrl + 'icao';
    return this.http.get<Organization[]>(url, { headers: this.httpGetHeadersForJSON(true) })
      .pipe(
        catchError(this.httpHandleError)
      );
  }

  getAirlineInfo(airlineID: number): Observable<AirlineInfo[]> {
    const url = this.rootAirlineUrl + airlineID + '/info';
    return this.http.get<AirlineInfo[]>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError)
      );
  }

  getAirlineFilters(): Observable<AirlineFilter[]> {
    const url = this.rootAirlineUrl + 'airlinefilters/';
    return this.http.get<AirlineFilter[]>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError)
      );
  }
}
