export const permissions = {
    admin: {
        administration_roles: {
            create: "administration.administration_roles.create",
            manage: "administration.administration_roles.manage",
            delete: "administration.administration_roles.delete",
            view: "administration.administration_roles.view"
        },
        administration_users: {
            manage: "administration.administration_users.manage",
            view: "administration.administration_users.view"
        },
        aircraft: {
            create: "administration.aircraft.create",
            manage: "administration.aircraft.manage",
            delete: "administration.aircraft.delete",
            view: "administration.aircraft.view"
        },
        aircraft_groups: {
            create: "administration.aircraft_groups.create",
            manage: "administration.aircraft_groups.manage",
            delete: "administration.aircraft_groups.delete",
            view: "administration.aircraft_groups.view"
        },
        aircraft_interiors: {
            create: "administration.aircraft_interiors.create",
            manage: "administration.aircraft_interiors.manage",
            delete: "administration.aircraft_interiors.delete",
            view: "administration.aircraft_interiors.view"
        },
        aircraft_models: {
            create: "administration.aircraft_models.create",
            manage: "administration.aircraft_models.manage",
            delete: "administration.aircraft_models.delete",
            view: "administration.aircraft_models.view"
        },
        airframers: {
            create: "administration.airframers.create",
            manage: "administration.airframers.manage",
            delete: "administration.airframers.delete",
            view: "administration.airframers.view"
        },
        airlines: {
            create: "administration.airlines.create",
            manage: "administration.airlines.manage",
            delete: "administration.airlines.delete",
            view: "administration.airlines.view",
            customize: "administration.airlines.customize"
        },
        airline_details: {
            view: "administration.airline_details.view",
            manage: "administration.airline_details.manage"
        },
        airports: {
            view: "administration.airports.view",
        },
        companies: {
            create: "administration.companies.create",
            manage: "administration.companies.manage",
            view: "administration.companies.view",
            customize: "administration.companies.customize"
        },
        company_details: {
            view: "administration.company_details.view",
            manage: "administration.company_details.manage"
        },
        engine_types: {
            create: "administration.engine_types.create",
            manage: "administration.engine_types.manage",
            delete: "administration.engine_types.delete",
            view: "administration.engine_types.view"
        },
        ndb_navaids: {
            view: "administration.ndb_navaids.view",
        },
        procedures: {
            view: "administration.procedures.view",
        },
        runways: {
            view: "administration.runways.view",
        },
        users: {
            create: "administration.users.create",
            manage: "administration.users.manage",
            delete: "administration.users.delete",
            view: "administration.users.view",
            customize: "administration.users.customize"
        },
        tenants: {
            create: "administration.users.create",
            manage: "administration.users.manage",
            delete: "administration.users.delete",
            view: "administration.users.view",
        },
        vhf_navaids: {
            view: "administration.vhf_navaids.view",
        },
        waypoints: {
            view: "administration.waypoints.view",
        }
    },
    sendToTdy: {
        sendToTdy_roles: {
            create: "send_to_teledyne.send_to_teledyne_roles.create",
            manage: "send_to_teledyne.send_to_teledyne_roles.manage",
            delete: "send_to_teledyne.send_to_teledyne_roles.delete",
            view: "send_to_teledyne.send_to_teledyne_roles.view"
        },
        sendToTdy_users: {
            manage: "send_to_teledyne.send_to_teledyne_users.manage",
            view: "send_to_teledyne.send_to_teledyne_users.view"
        },
    }
}
