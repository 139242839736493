<div id="breadcrumbContainer" class="layout-breadcrumb-container p-d-flex p-jc-between p-ai-center p-shadow-1"
     style="position: fixed; top: 56px; right: 0; z-index: 100" [ngStyle]="{'left': getBreadcrumbLeftPosition()}">
  <p-breadcrumb [home]="home" [model]="items" styleClass="layout-breadcrumb p-pl-4"></p-breadcrumb>
  <!-- <div class="layout-breadcrumb-buttons p-d-flex p-ai-center p-pr-3">
    <div class="p-mb-0 p-mr-3"><p class="text-gray">{{utcDateTime}} (UTC)</p></div>
    <button id="dashboardFullscreen" name="dashboardFullscreen" type="button"
            [icon]="app.fullscreen ? 'pi pi-window-minimize' : 'pi pi-window-maximize'"
            [pTooltip]="app.fullscreen ? 'Exit fullscreen' : 'Enter fullscreen'"
            class="p-button-rounded p-button-text p-button-plain p-mr-1" pButton pRipple
            (click)="onFullscreenClick()"></button>
    <button id="logoutButton" name="logoutButton" type="button"
            class="p-button-rounded p-button-text p-button-plain" pButton pRipple
            (click)="onLogoutClick()" icon="pi pi-power-off" pTooltip="Logout"></button>
  </div> -->
</div>
