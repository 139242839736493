<p-blockUI [blocked]="loadingCount > 0">
  <tdy-spinner
    style="position: absolute; left: 50%; top: 50%; z-index: 10000"
  ></tdy-spinner>
</p-blockUI>
<div class="col-12 card p-4">
  <div class="card-header">
    <h5>{{ pageTitle }}</h5>
  </div>
  <div class="warningmsg" *ngIf="showMessage">
    <p-messages
      [(value)]="messages"
      [enableService]="false"
      [closable]="true"
    ></p-messages>
  </div>

    <app-dataredaction-form
      [isReadonly]="isReadonly"
      [flightdataredaction]="flightdataredaction"
      [uniqueError]="uniqueError"
      [dataframe]="dataframe"
      [redactionType]="redactionType"
      [selectedRedactionTypeId]="selectedRedactionTypeId"
      [fileButtonEnabled]="fileButtonEnabled"
      [uploadfilepath]="uploadfilepath"
      [availableDataFramelist]="availableDataFramelist"
      [availableDataFrame]="availableDataFrame"
      [isNew]="isNew"
      [selectedDataframesIDs]="selectedDataframesIDs"
      [isValid]="isValid"
      [picklistChange]="picklistChange"
      [empty]="empty"
      [acceptedFiles]="acceptedFiles"
      (onDataFrameChange)="onDataFrameChange($event)"
      (onChangeRedaction)="onChangeRedaction($event)"
      (onUpload)="onUpload($event)"
      (onMoveToTarget)="onMoveToTarget($event)"
      (onMoveToSource)="onMoveToSource($event)"
      (onMoveAllToSource)="onMoveAllToSource($event)"
      (onMoveAllToTarget)="onMoveAllToTarget($event)"
      (onCancel)="onCancel()"
      (onReset)="onReset()"
      (onSubmit)="handleSubmit($event)"
  ></app-dataredaction-form>
</div>
