import { RegexPair } from "./recorderRegex";
export class RecorderConfigModel {
  blockmode_id: number;
  blockmode_name: string;
  datapacking_id: number;
  datapacking_type: string;
  miniframe_id: number;
  miniframe_name: string;
  name: string;
  recorder_id: number;
  recorderformat_id: number;
  recorderformat_name: string;
  recordertype_id: number;
  recordertype_type: string;
  regexes: RegexPair[];
  subframesize_id: number;
  subframesize_name: any;
  recordingstandard_id: number;
  recordingstandard_name: string;
  recorder1_name: string;
  recorder2_name: string;
  data_level: number;
}

export class RecorderConfigModel_tst {
  blockmode_id: number;
  blockmode_name: string;
  data_level: number;
  datapacking_id: number;
  datapacking_type: string;
  miniframe_id: number;
  miniframe_name: string;
  name: string;
  recorder_id: number;
  recorderformat_id: number;
  recorderformat_name: string;
  recordertype_id: number;
  recordertype_type: string;
  regexes: RegexPair[];
  subframesize_id: number;
  subframesize_name: string;
  recordingstandard_id: number;
  recordingstandard_name: string;
  // recorder1_name: string;
  // recorder2_name: string;
}

export class RecorderModel {
  name: string;
  recorder_id: number;
  recordingstandard_id: number;
  recorder1_name: string;
  recorder2_name: string;
}

export class RecorderFinalModel {
  recordertype_id: number;
  datapacking_id: number;
  miniframe_id: number;
  subframesize_id: number;
  blockmode_id: number;
  recorderformat_id: number;
  name: string;
  regex?: [];
  recorder_id: number;
  data_level: number;
}

export class RecorderType {
  description: string;
  recordertype_id: number;
  recordingstandard_id: number;
  type: string;
}

export class RecorderFormat {
  description: string;
  name: string;
  recorderformat_id: number;
  type: string;
}

export class SubframeSize {
  name: string;
  subframesize_id: number;
}

export class MiniFrame {
  miniframe_id: number;
  miniframe_name: string;
}
export class DataPacking {
  datapacking_id: number;
  datapacking_type: string;
}

export class Regex {
  regex_id: number;
  regex_name: string;
}

export class RecorderBlockMode {
  blockmode_id: number;
  blockmode_name: string;
  description: string;
}
