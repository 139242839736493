import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FormGroup, NgForm } from '@angular/forms';
import { Components } from '../../integration/datadelivery.components';
import { BreadcrumbService } from '../../../../common/services/breadcrumb.service';
import {
  Action,
  BaseViewComponent,
} from 'src/app/common/components/base-view.component';
import { Router, ActivatedRoute } from '@angular/router';
import { forkJoin, takeUntil } from 'rxjs';
import { UserService } from 'src/app/features/administration/services/user.service';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { DataDeliveryAlerts } from '../../models/DatadeliveryAlerts';
import { DataDeliveryAlertService } from '../../services/DataDeliveryAlert.service';
import {
  UserRecordingSubscription,
  ResponseUserRecordingSubscription,
} from '../../models/userRecordingSubscriptionModel';
import { UserRecordingSubscriptionService } from '../../services/userRecordingSubscription.services';
import { Table } from 'primeng/table';
import { permissions } from '../../integration/datadelivery.permissions';
import { applicationRoleData } from '../../../administration/models/application';
@Component({
  selector: 'app-userrecording-subscription',
  templateUrl: './userrecording-subscription.component.html',
  styleUrls: ['./userrecording-subscription.component.css'],
})
export class UserRecordingSubscriptionComponent
  extends BaseViewComponent
  implements OnInit
{
  @ViewChild('UserSubscription') userSubscriptionForm!: NgForm;
  @ViewChild('dtAssignedSubscription') dtAssignedSubscription: Table;
  @ViewChild('dtAvailableSubscription') dtAvailableSubscription: Table;
  pageTitle = Components.UserRecordingSubscription.label;
  messageLabel = 'User Recording Subscription';
  public canAdd: boolean;
  public canDelete: boolean;
  public canEdit: boolean;
  public modalDialog?: boolean;
  public hideOnClear?: boolean;
  isReadonly: boolean = true;
  airlineId: number[] = [];
  showButtons: boolean = true;
  userId: number;
  name: string;
  assignedSubscription: UserRecordingSubscription[] = [];
  availableSubscriptionTypes: UserRecordingSubscription[] = [];
  assignedSubscriptionSelected: UserRecordingSubscription[] = [];
  availableSubscriptionTypesSelected: UserRecordingSubscription[] = [];
  responseSubscription: ResponseUserRecordingSubscription;
  constructor(
    router: Router,
    private route: ActivatedRoute,
    breadcrumbService: BreadcrumbService,
    confirmationService: ConfirmationService,
    messageService: MessageService,
    private dataDeliveryAlertService: DataDeliveryAlertService,
    private realUserService: SecurityUserService,
    private userRecordingSubscriptionService: UserRecordingSubscriptionService
  ) {
    super(messageService, confirmationService, router, breadcrumbService);
    this.getBreadCrums();
  }

  getBreadCrums() {
    this.breadcrumbService.setItems(this.route, [
      {
        label:
          Components.UserRecordingSubscription.label +
          ` (${this.route.snapshot.queryParamMap.get('name')})`,
      },
    ]);
  }

  ngOnInit(): void {
    this.getBreadCrums();
    this.canEdit = this.realUserService.userHasPermission(
      permissions.alert_subscriptions.manage
    );
    !this.canEdit ? (this.isReadonly = true) : (this.isReadonly = false);
    !this.canEdit ? (this.showButtons = false) : (this.showButtons = true);
    this.userId = +this.route.snapshot.queryParamMap.get('userId');
    this.name = this.route.snapshot.queryParamMap.get('name');
    this.loadSubscriptionDetails(this.userId);
  }

  assignSubscriptionClick() {
    if (this.availableSubscriptionTypesSelected?.length > 0) {
      this.assignedSubscription = [...this.assignedSubscription];
      this.availableSubscriptionTypes = [...this.availableSubscriptionTypes];
      this.assignedSubscription = this.assignedSubscription.concat(
        this.availableSubscriptionTypesSelected
      );

      for (
        let i = this.availableSubscriptionTypesSelected.length - 1;
        i >= 0;
        --i
      ) {
        const item = this.assignedSubscription.filter(
          (x) =>
            x.alert_id === this.availableSubscriptionTypesSelected[i].alert_id
        )[0];
        this.availableSubscriptionTypes.splice(
          this.availableSubscriptionTypes.indexOf(item),
          1
        );
      }

      this.availableSubscriptionTypesSelected = [];
      this.assignedSubscriptionSelected = [];
    }
  }

  unassignSunscriptionClick() {
    if (this.assignedSubscriptionSelected?.length > 0) {
      this.assignedSubscription = [...this.assignedSubscription];
      this.availableSubscriptionTypes = [...this.availableSubscriptionTypes];
      this.availableSubscriptionTypes = this.availableSubscriptionTypes.concat(
        this.assignedSubscriptionSelected
      );

      for (let i = this.assignedSubscriptionSelected.length - 1; i >= 0; --i) {
        const item = this.assignedSubscription.filter(
          (x) => x.alert_id === this.assignedSubscriptionSelected[i].alert_id
        )[0];
        this.assignedSubscription.splice(
          this.assignedSubscription.indexOf(item),
          1
        );
      }

      this.availableSubscriptionTypesSelected = [];
      this.assignedSubscriptionSelected = [];
    }
  }

  onCheckAllAssignedSubscription(event) {
    this.assignedSubscriptionSelected = [];

    if (event.checked === true) {
      if (this.dtAssignedSubscription.filteredValue == null) {
        for (let i = 0; i < this.assignedSubscription.length; i++) {
          this.assignedSubscriptionSelected.push(this.assignedSubscription[i]);
        }
      } else {
        for (
          let i = 0;
          i < this.dtAssignedSubscription.filteredValue.length;
          i++
        ) {
          this.assignedSubscriptionSelected.push(
            this.dtAssignedSubscription.filteredValue[i]
          );
        }
      }
    }
  }

  loadSubscriptionDetails(userId: number) {
    this.loadingCount++;
    forkJoin({
      datadeliveryalerts: this.dataDeliveryAlertService.getRecordingAlerts(),
      assignedSubscription:
        this.userRecordingSubscriptionService.getUserSubscriptionByUserId(
          userId
        ),
    })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results) => {
          this.assignedSubscription = results.assignedSubscription;

          this.availableSubscriptionTypes = [];
          console.log(results.datadeliveryalerts);
          results.datadeliveryalerts.forEach((ele) => {
            const exists = this.assignedSubscription.some(
              (a) => a.alert_id == ele.alert_id
            );
            if (!exists) {
              this.availableSubscriptionTypes.push({
                alert_id: ele.alert_id,
                alert_type: ele.alert_type,
                alert_type_id: ele.alert_type_id,
                name: ele.name,
              });
            }
          });
        },
        error: (err) => {
          this.showErrorMsg(`${err}`, Action.Get, `${this.messageLabel}`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        },
      });
  }

  onCheckAllAvailableSubscription(event) {
    this.availableSubscriptionTypesSelected = [];

    if (event.checked === true) {
      if (this.dtAvailableSubscription.filteredValue == null) {
        for (let i = 0; i < this.availableSubscriptionTypes.length; i++) {
          this.availableSubscriptionTypesSelected.push(
            this.availableSubscriptionTypes[i]
          );
        }
      } else {
        for (
          let i = 0;
          i < this.dtAvailableSubscription.filteredValue.length;
          i++
        ) {
          this.availableSubscriptionTypesSelected.push(
            this.dtAvailableSubscription.filteredValue[i]
          );
        }
      }
    }
  }

  OnSubmit() {
    this.airlineId = [];
    this.assignedSubscription.forEach((a) => {
      this.airlineId.push(a.alert_id);
    });
    this.responseSubscription = {
      user_id: this.userId,
      alert_id: this.airlineId,
    };
    this.userRecordingSubscriptionService
      .addusersubscription(this.responseSubscription)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (result) => {
          // Update query param
          this.router
            .navigate([], {
              relativeTo: this.route,
              queryParams: {
                userId: this.userId,
              },
              queryParamsHandling: 'merge',
            })
            .then();
          this.updateBreadcrumb(
            Components.UserRecordingSubscription.label + ' (' + this.name + ')'
          );
        },
        error: (err) => {
          this.showErrorMsg(`${err}`, Action.Update, `${this.messageLabel}s`);
          this.loadingCount--;
          this.userSubscriptionForm.resetForm(this.userSubscriptionForm.value);
        },
        complete: () => {
          this.showSuccessMsg(
            Action.Add,
            `${this.messageLabel}`,
            `${this.name}`
          );
          this.isNew = false;
          this.userSubscriptionForm.resetForm(this.userSubscriptionForm.value);
          this.loadingCount--;
        },
      });
  }
  toNotificationTypeUser(
    type: UserRecordingSubscription
  ): UserRecordingSubscription {
    return type;
  }

  onReset() {
    this.userSubscriptionForm.resetForm();
    this.availableSubscriptionTypes = [];
    this.assignedSubscription = [];
    this.ngOnInit();
  }

  onCancel() {
    if (this.userSubscriptionForm.dirty) {
      this.router.navigate([Components.DataDeliveryAlert.path]);
    } else {
      this.router.navigate([Components.DataDeliveryAlert.path]);
    }
  }
}
