import { forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { Aircraft } from 'src/app/features/administration/models/aircraft';
import { AircraftService } from 'src/app/features/administration/services/aircraft.service';
import { SortUtil } from 'src/app/platform/util/sortUtil';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { Action, BaseViewComponent } from 'src/app/common/components/base-view.component';
import { AircraftGroup } from 'src/app/features/administration/models/aircraftGroup';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { permissions } from 'src/app/security/models/permissions';
import { CommonService } from 'src/app/common/services/common.service';
import { Components } from '../../integration/administration.components';

@Component({
  selector: 'app-aircraft',
  templateUrl: './aircraft.component.html'
})
export class AircraftComponent extends BaseViewComponent implements OnInit {
  pageTitle = Components.Aircraft.label;
  aircraft: Aircraft[];
  aircraftGroups: SelectItem[];
  selectedAircraftGroup: number;
  messageLabel = 'aircraft';
  downloadButtonDisabled = true;

  public canAdd: boolean;
  public canDelete: boolean;
  public canEdit: boolean;
  public dynamicdownloadName: string;

  @ViewChild('dt') dataTable: Table;

  /**
   * Constructor for the component.
   *
   * @param aircraftService - Interacts with aircraft data.
   * @param userService - Gets user information.
   * @param confirmationService - Provides confirmation of tail deletion.
   * @param messageService - Used to display messages.
   * @param route - Used to get the route's query parameters.
   * @param router - used to navigate to other pages.
   * @param breadcrumbService - Used to update the breadcrumb.
   */
  constructor(private aircraftService: AircraftService,
    private route: ActivatedRoute,
    private commonService: CommonService,
    confirmationService: ConfirmationService,
    messageService: MessageService,
    router: Router,
    private realUserService: SecurityUserService,
    breadcrumbService: BreadcrumbService) {

    super(messageService, confirmationService, router, breadcrumbService);
    // Note: Service won't update breadcrumb if caller already did so.
    this.breadcrumbService.setItems(route, [
      { label: Components.Aircraft.label }  // No routerLink value for current page
    ]);
  }

  cols: any[];

  exportColumns: any[];

  /**
   * Lifecycle hook that is called after data-bound properties of a directive are initialized.
   */
  ngOnInit() {
    this.canAdd = this.realUserService.userHasPermission(permissions.admin.aircraft.create);
    this.canDelete = this.realUserService.userHasPermission(permissions.admin.aircraft.delete);
    this.canEdit = this.realUserService.userHasPermission(permissions.admin.aircraft.manage);

    this.breadcrumbService.setItems(this.route, [{ label: Components.Aircraft.label }]);
    this.loadAircraft();

    this.cols = [
      { field: 'aircraft_registration', header: 'aircraft_registration', customExportHeader: 'aircraft_registration' },
      { field: 'aircraftmodel', header: 'aircraftmodel', customExportHeader: 'aircraftmodel' },
      { field: 'enginetype_name', header: 'enginetype_name', customExportHeader: 'enginetype_name' },
      { field: 'serial_number', header: 'serial_number', customExportHeader: 'serial_number' },
      { field: 'nose_number', header: 'nose_number', customExportHeader: 'nose_number' },
      { field: 'aircraftinterior_display', header: 'aircraftinterior_display', customExportHeader: 'aircraftinterior_display' }
    ];
    this.dynamicdownloadName = 'Aircraft' + '_export_' + new Date().toLocaleDateString() + '_' + new Date().toLocaleTimeString();
    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
  }

  /**
   * Gets the data required for the initial load of the page. This includes the aircraft models
   * which are used to create key/value pairs to populate the dropDown lists. Also gets the aircraft objects and stores them in
   * a local collection for display in the main data table.
   */
  loadAircraft() {
    this.loadingCount++;
    forkJoin([this.aircraftService.listAircraft(),
    this.commonService.listAircraftGroupFilters()])
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe({
        next: ([aircraft, aircraftGroups]) => {
          this.aircraft = aircraft;
          this.aircraftGroups = this.populateDropdownItems<AircraftGroup>(aircraftGroups, 'aircraftgroup_name', 'aircraftgroup_id');
          this.downloadButtonDisabled = this.aircraft.length < 1;
        },
        error: error => {
          this.showErrorMsg(error, Action.Get, `${this.messageLabel}`);
          this.loadingCount--;
        },
        complete: () => this.loadingCount--
      });
  }

  populateDropdownItems<T>(items: T[], labelProp: string, valueProp: string, addAllOption = true): SelectItem[] {
    const options = [];
    // Some error checking
    if (!items || items.length == 0) {
      return options;
    }

    const keys = Object.keys(items[0]);
    if (!keys.includes(labelProp) || !keys.includes(valueProp)) {
      return options;
    }

    items.forEach(i => {
      options.push({ label: i[labelProp], value: i[valueProp] });
    });

    SortUtil.sortByLabel(options);

    if (addAllOption) {
      options.splice(0, 0, { label: 'All', value: null });   // Insert empty item as first item
    }
    return options;
  }

  /**
   * Event handler for when the Add button is clicked. Navigates to the details page to create a new object.
   */
  onClickAdd() {
    if (!this.canAdd) {
      this.showErrorMsg('User is not authorized to add an aircraft.', Action.Add, `${this.messageLabel}`);
    } else {
      this.router.navigate([Components.AircraftDetails.path]).then();
    }
  }

  /**
   * Event handler for when a row is selected in the data table. Navigates to the details page.
   * @param event - The browser event. The data property contains the selected Aircraft.
   */
  onRowSelect(event) {
    this.router.navigate([Components.AircraftDetails.path], {
      queryParams: {
        t: event.data.aircraft_id,  // t = tail ID
        name: event.data.name
      }
    }).then();
  }

  /**
   * Event handler for when the Clone button is clicked. Navigates to the details page to create a new object
   * based on an existing aircraft.
   * @param aircraftID The ID of the tail to clone.
   */
  onCloneAircraft(aircraftID: number) {
    if (!this.canAdd) {
      this.showErrorMsg('User is not authorized to add an aircraft.', Action.Add, `${this.messageLabel}`);
    } else {
      this.router.navigate([Components.AircraftDetails.path], {
        queryParams: {
          t: aircraftID,  // t = tail ID
          c: true // c = clone
        }
      }).then();
    }
  }

  /**
   * Event handler for when the Delete button is clicked. Confirms with user and then deletes the tail.
   * @param aircraft The aircraft to delete.
   */
  onDeleteAircraft(aircraft: Aircraft) {
    if (!this.canDelete) {
      this.showErrorMsg('User is not authorized to delete an aircraft.', Action.Delete, `${this.messageLabel}`, `${aircraft.aircraft_registration}`);
    } else {
      this.confirmationService.confirm({
        message: 'Are you sure that you want to delete the selected aircraft?',
        accept: () => {
          this.loadingCount++;
          this.aircraftService.deleteAircraft(aircraft.aircraft_id).subscribe({
            next: () => {
              this.showSuccessMsg(Action.Delete, `${this.messageLabel}`, `${aircraft.aircraft_registration}`);
              // Refresh the list of aircraft
              this.loadAircraft();
            },
            error: error => {
              this.showErrorMsg(`${error}`, Action.Delete, `${this.messageLabel}`, `${aircraft.aircraft_registration}`);
              this.loadingCount--;
            },
            complete: () => this.loadingCount--
          });
        }
      });
    }
  }

  onChangeAircraftGroup() {
    this.loadingCount++;
    if (this.selectedAircraftGroup) {
      this.aircraftService.listAircraft(this.selectedAircraftGroup).pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: ac => {
            this.aircraft = ac;
            this.downloadButtonDisabled = ac.length < 1;
          },
          error: err => {
            this.showErrorMsg(`${err}`, Action.Get, `${this.messageLabel}`);
            this.loadingCount--;
          },
          complete: () =>
            this.loadingCount--
        });

    } else {
      this.aircraftService.listAircraft().pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: ac => {
            this.aircraft = ac;
            this.downloadButtonDisabled = ac.length < 1;
          },
          error: err => {
            this.showErrorMsg(`${err}`, Action.Get, `${this.messageLabel}`);
            this.loadingCount--;
          },
          complete: () =>
            this.loadingCount--
        });
    }
  }


  /**
   * Used in HTML to get strongly-typed reference when using let-
   * @param tail The Aircraft object.
   */
  toAircraft(tail: Aircraft): Aircraft {
    return tail;
  }
}
