import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { FileUpload } from 'primeng/fileupload';
import { FlightDataRedactions } from 'src/app/features/datadelivery/models/FlightDataRedactions';

@Component({
  selector: 'app-dataredaction-form',
  templateUrl: './data-redaction-form.component.html',
  styleUrls: ['./data-redaction-form.component.scss'],
})
export class DataRedactionFormComponent {
  @Input() isReadonly: boolean = false;
  @Input() flightdataredaction!: FlightDataRedactions;
  @Input() uniqueError: boolean = false;
  @Input() dataframe: any[] = [];
  @Input() redactionType: any[] = [];
  @Input() selectedRedactionTypeId: number | null = null;
  @Input() fileButtonEnabled: boolean = false;
  @Input() uploadfilepath: any = {};
  @Input() availableDataFramelist: any[] = [];
  @Input() availableDataFrame: any[] = [];
  @Input() isNew: boolean = false;
  @Input() selectedDataframesIDs: any[] = [];
  @Input() isValid: boolean = false;
  @Input() isButtonDisabled: boolean = false;
  @Input() picklistChange: boolean = false;
  @Input() empty: boolean = true;
  @Input() acceptedFiles: string = '';

  @ViewChild('dataredactionForm') dataredactionForm!: NgForm;
  @ViewChild('ContentUpload') content: FileUpload;

  @Output() onDataFrameChange: EventEmitter<any> = new EventEmitter();
  @Output() onChangeRedaction: EventEmitter<any> = new EventEmitter();
  @Output() onUpload: EventEmitter<any> = new EventEmitter();
  @Output() onMoveToTarget: EventEmitter<any> = new EventEmitter();
  @Output() onMoveToSource: EventEmitter<any> = new EventEmitter();
  @Output() onMoveAllToSource: EventEmitter<any> = new EventEmitter();
  @Output() onMoveAllToTarget: EventEmitter<any> = new EventEmitter();
  @Output() onCancel: EventEmitter<void> = new EventEmitter();
  @Output() onReset: EventEmitter<void> = new EventEmitter();
  @Output() onSubmit: EventEmitter<FlightDataRedactions> = new EventEmitter();

  constructor() {}

  handleSubmit() {
    this.onSubmit.emit(this.flightdataredaction);
  }

  handleDataFrameChange(event: any) {
    this.onDataFrameChange.emit(event);
  }

  handleChangeRedaction(event: any) {
    this.onChangeRedaction.emit(event);
  }

  handleUpload(event: any) {
    if (this.content?.clear) this.content.clear();
    this.onUpload.emit(event);
  }

  handleMoveToTarget(event: any) {
    this.onMoveToTarget.emit(event);
  }

  handleMoveToSource(event: any) {
    this.onMoveToSource.emit(event);
  }

  handleMoveAllToSource(event: any) {
    this.onMoveAllToSource.emit(event);
  }

  handleMoveAllToTarget(event: any) {
    this.onMoveAllToTarget.emit(event);
  }

  handleCancel() {
    this.onCancel.emit();
  }

  handleReset() {
    this.dataredactionForm.resetForm();
    this.content.clear();
    this.onReset.emit();
  }
}
