import { Component, OnInit } from '@angular/core';
import {
  FlightDataRedactions,
  ConvertType,
  DataFrameType,
  FilePathType,
} from '../../models/FlightDataRedactions';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FormGroup } from '@angular/forms';
import { Components } from '../../integration/datadelivery.components';
import { BreadcrumbService } from '../../../../common/services/breadcrumb.service';
import {
  Action,
  BaseViewComponent,
} from 'src/app/common/components/base-view.component';
import { Router, ActivatedRoute } from '@angular/router';
import { FlightDataRedactionService } from '../../services/flightDataRedaction.service';
import {
  Observable,
  Subject,
  catchError,
  of,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { permissions } from '../../integration/datadelivery.permissions';

@Component({
  selector: 'app-dataredaction',
  templateUrl: './dataredaction.component.html',
  styleUrls: ['./dataredaction.component.css'],
})
export class DataRedactionComponent
  extends BaseViewComponent
  implements OnInit
{
  pageTitle = Components.DataRedactions.label;
  public canAdd: boolean;
  public canDelete: boolean;
  public canEdit: boolean;
  public modalDialog?: boolean;
  public submitted?: boolean;
  flightDataRedactions$: Observable<FlightDataRedactions[]>;
  private refreshFlightDataRedactions$ = new Subject<void>();

  today = new Date();
  todayAsStr = `${this.today.getFullYear()}-${this.today.getMonth()}-${this.today.getDate()}`;
  flightdataredactions: FlightDataRedactions[] = [];
  flightdataredaction!: FlightDataRedactions;
  convertionType: ConvertType[] = [];
  dataframe: DataFrameType[] = [];
  file_path: FilePathType[] = [];
  files: any = '';
  empty: boolean = false;
  displayError: boolean = false;
  redactionForm!: FormGroup;
  allnames: any[] = [];
  uploadedFiles: any[] = [];
  uniqueError: boolean = false;
  names: any[] = [];
  checked!: boolean;
  isClone = 0;
  acceptedFiles: string = '.csv';
  messageLabel = 'flight data redaction';
  selectedCloneData: FlightDataRedactions[] = [];
  convertionTypelbl = 'Raw 717/767';
  clonelbl = 'Selected values are cloned to Send to Teledyne';
  constructor(
    router: Router,
    private route: ActivatedRoute,
    breadcrumbService: BreadcrumbService,
    confirmationService: ConfirmationService,
    messageService: MessageService,
    private flightredactionservice: FlightDataRedactionService,
    private realUserService: SecurityUserService
  ) {
    super(messageService, confirmationService, router, breadcrumbService);
    this.getBreadCrums();
  }

  getBreadCrums() {
    this.breadcrumbService.setItems(this.route, [
      { label: Components.DataRedactions.label },
    ]);
  }

  ngOnInit(): void {
    this.getBreadCrums();
    this.canAdd = this.realUserService.userHasPermission(
      permissions.datadelivery_flight_redaction.create
    );
    this.canEdit = this.realUserService.userHasPermission(
      permissions.datadelivery_flight_redaction.manage
    );
    this.canDelete = this.realUserService.userHasPermission(
      permissions.datadelivery_flight_redaction.delete
    );
    this.flightdataredaction = new FlightDataRedactions();
    this.selectedCloneData = [];
    this.loadRedactionData();
  }

  openNew() {
    if (!this.canAdd) {
      this.showErrorMsg(
        'User is not authorized to add an Redaction.',
        Action.Add,
        `${this.messageLabel}`
      );
    } else {
      this.router.navigate([Components.AddDataRedactions.path]);
    }
  }

  onRowClick(event: any, e: any) {
    e.preventDefault();
    e.stopPropagation();
    this.router.navigate([Components.AddDataRedactions.path], {
      state: { flightData: event },
      queryParams: { redactionId: event.redaction_id, name: event.name },
    });
  }

  deleteDataRedaction(dataredaction: FlightDataRedactions, e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (!this.canDelete) {
      this.showErrorMsg(
        'User is not authorized to ',
        Action.Add,
        `${this.messageLabel}`
      );
    } else {
      this.confirmationService.confirm({
        message:
          'Are you sure that you want to delete the selected flight data redaction?',
        accept: () => {
          this.flightredactionservice
            .deleteFlightRedaction(dataredaction.redaction_id)
            .subscribe({
              next: () => {
                this.showSuccessMsg(
                  Action.Delete,
                  `${this.messageLabel}`,
                  `${dataredaction.name}`
                );
                // Refresh the list of redactions
                this.refreshFlightDataRedactions$.next();
                // this.loadRedactionData();
              },
              error: (error) => {
                this.showErrorMsg(
                  `${error}`,
                  Action.Delete,
                  `${this.messageLabel}s`
                );
              },
            });
        },
      });
    }
  }

  loadRedactionData() {
    //get all the redaction data to populate the Table
    this.flightDataRedactions$ = this.refreshFlightDataRedactions$.pipe(
      startWith([]),
      switchMap(() => this.flightredactionservice.getFligtRedactions()),
      catchError((err) => {
        this.showErrorMsg(`${err}`, Action.Get, `${this.messageLabel}s`);
        return of([]);
      })
    );
  }

  cloneRedaction(cloned: any) {
    if (!this.canAdd) {
      this.showErrorMsg(
        'User is not authorized to add an Redaction.',
        Action.Add,
        this.messageLabel
      );
    } else {
      this.router.navigate([Components.AddDataRedactions.path], {
        queryParams: {
          redactionId: cloned.redaction_id,
          name: cloned.name,
          clone: true,
        },
        queryParamsHandling: 'merge',
      }).then();
    }
  }
}
