import { AircraftInteriorPair } from "./aircraftInterior";
import { Application } from "./application";
import { OrganizationApp } from "./organization";

export class Aircraft {
  aircraft_id: number;
  aircraft_registration: string;
  airline_id: number;
  airline_icao_code: string;
  airline_name: string;
  aircraftmodel_id: number;
  aircraftmodel: string;
  enginetype_id: number;
  enginetype_name: string;
  aircraftinterior: AircraftInteriorPair[];
  aircraftinterior_id?: number[];
  aircraftinterior_deck?: string[];
  aircraftinterior_display?: string;
  nose_number?: string;
  serial_number?: string;
}

export interface AircraftPair {
  aircraft_id: number;
  aircraft_registration: string;
}

export interface AircraftApplication {
  aircraft: AircraftPair
  availApplications: Application[]
  selectedApplications?: Application[]
}

export interface AppsAssignedToAircraft extends AircraftPair {
  applications?: Application[]
}

export interface AircraftForApp {
  application: OrganizationApp
  appAircraft: AircraftPair[]
  airlineAircraft?: AircraftPair[]
}
