import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment";
import { ACMSRedactionServive } from '../../datadelivery/services/acmsRedaction.service';

@Injectable({
  providedIn: 'root'
})
export class EndpointACMSRedactionServive extends ACMSRedactionServive {

  constructor(
    public override http: HttpClient) {
    super(http);
    this.baseUrl = environment.apiUrl.endpointdatadelivery;
  }

}