<p-blockUI [blocked]="loadingCount > 0">
    <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>

<p-confirmDialog header="Confirm Deletion" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<div *ngIf="companies != null && canView" class="col-12 card">
    <div class="card-header m-2">
        <h5>{{pageTitle}}</h5>
        <div>
            <button (click)="onClickAdd()" *ngIf="canAdd" icon="pi pi-plus" id="addButton" label="Add" pButton
                pRipple type="button"></button>
        </div>
    </div>
    <div class="col-12">
        <p-table (onRowSelect)="onRowSelect($event)" [filterDelay]="0" [sortOrder]="1" [value]="companies" 
        [showCurrentPageReport]="true" [paginator]="companies.length > defaultTableRows" [rowsPerPageOptions]="[20,50,100,200]" [rows]="defaultTableRows" currentPageReportTemplate="{{currentPageReport + ' ' + pageTitle}}" 
        dataKey="id" id="companiesTable" selectionMode="single" sortField="code">
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="code" style="width: 20%">
                        <div class="flex justify-content-start align-items-center">
                            Code
                            <p-sortIcon field="code"></p-sortIcon>
                            <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0"
                                display="menu" field="code" type="text" matchMode="contains"></p-columnFilter>
                        </div>
                    </th>
                    <th pSortableColumn="name" style="width: 30%">
                        <div class="flex justify-content-start align-items-center">
                            Name
                            <p-sortIcon field="name"></p-sortIcon>
                            <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0"
                                display="menu" field="name" type="text" matchMode="contains"></p-columnFilter>
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template let-company pTemplate="body">
                <ng-container *ngIf="toCompany(company); let company">
                    <tr [pSelectableRow]="company">
                        <td style="width: 20%"> {{company.code}} </td>
                        <td style="width: 30%">{{company.name}} </td>
                    </tr>
                </ng-container>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="3">No companies found.</td>
                </tr>
            </ng-template>
        </p-table>
        <div *ngIf="companies.length > 0 && defaultTableRows >= companies.length" style="text-align: center;">
            <p class="row-paging">Showing 1 to
              {{companies.length}} of {{companies.length}} {{pageTitle}}</p>
          </div>
    </div>
</div>