export class sarMapsModel {
    sarmap_id: number;
    name: string;
}

export class sarMapsVersions {
    sarmap_id: number;
    name: string;
    description: string;
    cnt: number;
    version: number;
    sarmapversion_id: number;
}

export class AirlinesModel {
    airline_iata_code?: string;
    code?: string;
    id?: number;
    name?: string;
    airlines? : selectedAirlinesModel[];
}

export class selectedAirlinesModel {
    airline_icao_code?: string;
    id?: number;
    name?: string;
}