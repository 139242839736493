import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Setting } from '../../models/setting';

export interface SettingsChanges {
  value: Setting;
  valid: boolean;
}
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html'
})
export class SettingsComponent implements OnInit {

  @Input() isSettingEditable: boolean;
  @Input() settings: Setting[];
  @Output() settingsChanged = new EventEmitter<SettingsChanges>();

  @ViewChild('settingsForm') settingsForm: NgForm;

  constructor() {
    //
  }


  ngOnInit(): void {
    //
  }

  onSettingsChanged(setting: Setting) {
    this.settingsChanged.emit({ value: setting, valid: this.settingsForm.valid });
  }

  onSetToDefault(data: Setting) {
    data.setting_value = data.settingdefinition_defaultvalue;
    this.settingsChanged.emit({ valid: this.settingsForm.valid, value: data });
  }

}
