import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClientPackages } from 'src/app/features/datadelivery/models/ClientPackage';

@Component({
  selector: 'app-clientpackage-table',
  templateUrl: './clientpackage-table.component.html',
})
export class ClientpackageTableComponent implements OnInit {
  cols: any[];
  globalFilterFields: string[] = [
    'name',
    'description',
    'conversiontype_name',
    'datatype',
    'ziptype',
    'primary',
    'secondary',
  ];
  @Input() clientpackages: ClientPackages[] = [];
  @Input() defaultTableRows: number;
  @Input() currentPageReport: string;
  @Input() pageTitle: string;
  @Input() canAdd: boolean = false;
  @Input() canDelete: boolean = false;
  @Input() canEdit: boolean = false;

  @Output() rowSelected = new EventEmitter<ClientPackages>();
  @Output() packageCloned = new EventEmitter<ClientPackages>();
  @Output() clientPackageDeleted = new EventEmitter<ClientPackages>();

  constructor() {}

  ngOnInit(): void {
    this.cols = [
      {
        field: 'name',
        header: 'Name',
      },
      {
        field: 'description',
        header: 'Description',
      },

      {
        field: 'conversiontype_name',
        header: 'Conversion Type',
      },
      {
        field: 'datatype',
        header: 'Data Type',
      },
      {
        field: 'ziptype',
        header: 'Zip Options',
      },
      {
        field: 'primary',
        header: 'Primary Option',
      },
      {
        field: 'secondary',
        header: 'Secondary Option',
      },
      {
        field: 'Action',
        header: '',
      },
      {
        field: 'Action',
        header: '',
      },
      {
        field: 'Action',
        header: '',
      },
    ];
  }

  onRowSelect(clientPackages: ClientPackages): void {
    this.rowSelected.emit(clientPackages);
  }

  clonePackage(clientPackages: ClientPackages): void {
    this.packageCloned.emit(clientPackages);
  }

  deleteClientPackage(clientPackages: ClientPackages): void {
    this.clientPackageDeleted.emit(clientPackages);
  }
}
