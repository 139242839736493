import { Component } from '@angular/core';
import { Components } from '../../../integration/configuration.components';
import { Router, ActivatedRoute } from '@angular/router';
import { TransferdataService } from 'src/app/features/configuration/services/transferdata.service';
import { DataFrameService } from 'src/app/features/configuration/services/data-frame.service';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { ListViewColumnType } from '../../dataframe-acms/dataframe/shared/helpers/data-frame.enums';
import { Listview767AndDXSComponent } from '../../dataframe-acms/dataframe/data-frame-listview/list-view-767-dxs/listview767-dxs.component';

@Component({
  selector: 'app-listview767',
  templateUrl: './listview767.component.html',
  styleUrls: ['./listview767.component.css'],
})
export class Listview767Component extends Listview767AndDXSComponent {

  constructor(
    router: Router,
    appService: TransferdataService,
    dataFrameService: DataFrameService,
    route: ActivatedRoute,
    breadcrumbService: BreadcrumbService,
    confirmationService: ConfirmationService,
    messageService: MessageService,
    realUserService: SecurityUserService
  ) {
    super(router, appService, dataFrameService, route, breadcrumbService, confirmationService, messageService, realUserService);
    // Data Frame 767 - Init Data
    this.routeLinkPath = Components.ListView767.path;
    this.messageLabel = 'Data Frame 767.';
    this.dfPropId = 'Schema767.Mnemonic_Code';
    this.dataFrameClone = Components.DataFrames767;
    this.dataFrameAnalyze = Components.DataFrameAnalyse767;
    this.dataFrameEdit = Components.DataFrames767;
    this.dataFrameMapView = Components.MapView767;
    this.dataFrameAdd = Components.DataFrames767
    this.dataFrameDetails = Components.DataFrame767;
    this.breadcrumbService.setItems(route, [{ label: Components.ListView767.label }]);
    this.columns = [
      { field: 'Schema767.Mnemonic_Code', header: 'Mnemonic', colType: ListViewColumnType.default, filterType: 'text', extraAttributes: { matchMode: 'contains'}},
      { field: 'Schema767.Name', header: 'Name', colType: ListViewColumnType.default, filterType: 'text', extraAttributes: { matchMode: 'contains'}},
      { field: 'Schema767.Comments', header: 'Description', colType: ListViewColumnType.default, filterType: 'text', extraAttributes: { matchMode: 'contains'}},
      { field: 'Schema767.Units', header: 'Units', colType: ListViewColumnType.default, filterType: 'text', extraAttributes: { matchMode: 'contains'}},
      { field: 'Schema767.Range.Data_Type', header: 'Data Type', colType: ListViewColumnType.default, filterType: 'text', extraAttributes: { matchMode: 'contains'}},
      { field: 'Frame.Frame_ID', header: 'Frame ID', type: 'numeric', colType: ListViewColumnType.default, filterType: 'numeric', extraAttributes: { matchMode: 'contains'}},
      { field: 'Frame.Frame_Title', header: 'Frame Name', type: 'numeric', colType: ListViewColumnType.default, filterType: 'text', extraAttributes: { matchMode: 'contains'}},
      { field: 'Frame_Rate', header: 'Frame Rate', type: 'numeric', colType: ListViewColumnType.default, filterType: 'numeric', extraAttributes: { matchMode: 'contains', minFractionDigits: 0, maxFractionDigits: 4}},
      { field: 'Frame_Number', header: 'Number in Frame', type: 'numeric', colType: ListViewColumnType.default, filterType: 'numeric', extraAttributes: { matchMode: 'contains'}},
    ]; 
  }
}
