<p-blockUI [blocked]="loadingCount > 0">
  <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>

<p-confirmDialog header="Confirm Deletion" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<div *ngIf="airlines != null && canView" class="col-12 card">
  <div class="card-header m-2">
    <h5>{{pageTitle}}</h5>
    <div>
      <button (click)="onClickAdd()" *ngIf="canAdd" icon="pi pi-plus" id="addButton" label="Add" pButton pRipple
        type="button"></button>
    </div>
  </div>
  <div class="col-12">
    <p-table (onRowSelect)="onRowSelect($event)" [filterDelay]="0" [sortOrder]="1"  [value]="airlines"
    [showCurrentPageReport]="true" [paginator]="airlines.length > defaultTableRows" [rowsPerPageOptions]="[20,50,100,200]" [rows]="defaultTableRows" currentPageReportTemplate="{{currentPageReport + ' ' + pageTitle}}" 
      dataKey="id" id="airlinesTable" selectionMode="single" sortField="code">
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="code" style="width: 20%">
            <div class="flex justify-content-start align-items-center">
              ICAO Code
              <p-sortIcon field="code"></p-sortIcon>
              <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu" field="code"
                type="text" matchMode="contains"></p-columnFilter>
            </div>
          </th>
          <th pSortableColumn="airline_iata_code" style="width: 20%">
            <div class="flex justify-content-start align-items-center">
              IATA Code
              <p-sortIcon field="airline_iata_code"></p-sortIcon>
              <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu"
                field="airline_iata_code" type="text" matchMode="contains"></p-columnFilter>
            </div>
          </th>
          <th pSortableColumn="name" style="width: 30%">
            <div class="flex justify-content-start align-items-center">
              Name
              <p-sortIcon field="name"></p-sortIcon>
              <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu" field="name"
                type="text" matchMode="contains"></p-columnFilter>
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template let-airline pTemplate="body">
        <ng-container *ngIf="toAirline(airline); let airline">
          <tr [pSelectableRow]="airline">
            <td style="width: 20%"> {{airline.code}} </td>
            <td style="width: 20%"> {{airline.airline_iata_code}} </td>
            <td style="width: 30%">{{airline.name}} </td>
          </tr>
        </ng-container>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="3">No airlines found.</td>
        </tr>
      </ng-template>
    </p-table>
    <div *ngIf="airlines.length > 0 && defaultTableRows >= airlines.length" style="text-align: center;">
      <p class="row-paging">Showing 1 to
        {{airlines.length}} of {{airlines.length}} {{pageTitle}}</p>
    </div>
  </div>
</div>