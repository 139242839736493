import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-user-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.scss']
})
export class UserOrganizationsComponent {
  @Input() user: any;
  @Input() additionalAvailOrgs: any[];
  @Input() userAssignedOrgs: any[];
  @Input() canEdit: boolean;
  @Input() canAdd: boolean;
  @Input() loggedIn: boolean;

  @Output() orgChange = new EventEmitter<void>();

  onOrgChange() {
    this.orgChange.emit();
  }

  toUserOrg(org: any) {
    return org
  }
}