import { Component, NgZone, OnInit } from '@angular/core';
import {
  EndPoints,
  AWS_Region,
  jsonValues,
  ResponseEndPointModel,
} from '../../models/ConfigEndPoints';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Router, ActivatedRoute } from '@angular/router';
import { Components } from '../../integration/datadelivery.components';
import { BreadcrumbService } from '../../../../common/services/breadcrumb.service';
import {
  Action,
  BaseViewComponent,
} from 'src/app/common/components/base-view.component';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { DataDeliveryEndPointService } from '../../services/endpoint.service';
import { forkJoin, takeUntil } from 'rxjs';
import { permissions } from '../../integration/datadelivery.permissions';
import { CommonService } from '../../../../common/services/common.service';
import { Organization } from 'src/app/features/administration/models/organization'; 
@Component({
  selector: 'app-endpoints-add',
  templateUrl: './endpoints-add.component.html',
  styleUrls: ['./endpoints-add.component.css'],
})
export class EndpointsAddComponent extends BaseViewComponent implements OnInit {
  public pageTitle = Components.AddEndpoints.label;
  public isReadonly: boolean = true;
  public endpoint!: EndPoints;
  public showButtons: boolean = true;
  public jsonParameters: any;
  public uniqueError: boolean = false;
  public transferType: any[] = [];
  public organizationList: Organization[] = [];
  public awsregion: AWS_Region[] = [];
  public canAdd: boolean;
  public canDelete: boolean;
  public canEdit: boolean;
  private responseEndPoint!: ResponseEndPointModel;
  private submitted: boolean = false;
  private year!: any;
  private month!: any;
  private day!: any;
  private selectedTypeId: number;
  private titlestring = 'New Endpoint';
  private messageLabel = 'endpoint';

  constructor(
    router: Router,
    private route: ActivatedRoute,
    breadcrumbService: BreadcrumbService,
    confirmationService: ConfirmationService,
    messageService: MessageService,
    private dataDeliveryEndPointservice: DataDeliveryEndPointService,
    private realUserService: SecurityUserService,
    private commonService: CommonService,
    private ngZone: NgZone
  ) {
    super(messageService, confirmationService, router, breadcrumbService);
    this.getBreadCrums();
  }

  getBreadCrums() {
    this.breadcrumbService.setItems(this.route, [
      {
        label: Components.Endpoints.label,
        routerLink: Components.Endpoints.path,
      },
      {
        label: this.route.snapshot.queryParamMap.get('name')
          ? Components.AddEndpoints.label +
            ` (${this.route.snapshot.queryParamMap.get('name')})`
          : Components.AddEndpoints.label,
      },
    ]);
  }

  getEndpointCur() {
    this.year = new Date().getFullYear();
    this.month = String(new Date().getMonth() + 1).padStart(2, '0');
    this.day = String(new Date().getDate()).padStart(2, '0');
    let opt_name = `${this.year}\-${this.month}\-${this.day}`;
    return opt_name;
  }

  intialVal() {
    this.getBreadCrums();
    this.updateBreadcrumb(this.titlestring);
    this.pageTitle = this.titlestring;
    this.endpoint = new EndPoints();
    this.jsonParameters = new jsonValues();
    this.jsonParameters.endpoint_date = this.getEndpointCur();
    this.endpoint.endpoint_id = -1;
    this.jsonParameters.endpoint_authssh = true;
    this.isNew = true;
  }

  ngOnInit(): void {
    this.canAdd = this.realUserService.userHasPermission(
      permissions.endpoint.create
    );
    this.canDelete = this.realUserService.userHasPermission(
      permissions.endpoint.delete
    );
    this.canEdit = this.realUserService.userHasPermission(
      permissions.endpoint.manage
    );
    const endpointId = +this.route.snapshot.queryParamMap.get('endpointId'); //converting string to number
    this.getEndpointDropdownOptions();
    if (endpointId) {
      this.loadEndPointDetails(endpointId);
      this.isReadonly = !!!this.canEdit;
      this.showButtons = !!this.canEdit;
    } else {
      this.isReadonly = !!!this.canAdd;
      this.showButtons = !!this.canAdd;
      this.intialVal();
    }

    this.year = new Date().getFullYear();
    this.month = String(new Date().getMonth() + 1).padStart(2, '0');
    this.day = String(new Date().getDate()).padStart(2, '0');
  }

  loadEndPointDetails(endpointId: number) {
    this.loadingCount++;
    this.isNew = false;
    this.submitted = true;
    forkJoin({
      transferType: this.dataDeliveryEndPointservice.getTransferType(),
      endpointdetails:
        this.dataDeliveryEndPointservice.getEndPoint_by_id(endpointId),
    })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results) => {
          this.transferType = results.transferType;
          this.endpoint = results.endpointdetails;
          this.jsonParameters = JSON.parse(this.endpoint.jsonparameters);
          this.endpoint.isenabled =
            this.endpoint.isenabled == true ? true : false;
          this.selectedTypeId = this.endpoint.transfertype_id;
        },
        error: (error) => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        },
      });
  }

  getEndpointDropdownOptions() {
    this.loadingCount++;
    this.isNew = false;
    this.submitted = true;
    forkJoin({
      transferType: this.dataDeliveryEndPointservice.getTransferType(),
      awsregions: this.commonService.getAwsRegions(),
      airlines: this.commonService.listAirlinesFilter(),
      companies: this.commonService.listCompaniesFilter(),
    })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results) => {
          this.organizationList = [...results.airlines, ...results.companies];
          this.transferType = results.transferType;
          results.awsregions.forEach((ele) => {
            this.awsregion.push({ region: ele });
          });
          this.awsregion.sort((a, b) => {
            return a.region.localeCompare(b.region);
          });
        },
        error: (error) => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        },
      });
  }
  onCancel() {
    this.submitted = false;
    this.router.navigate([Components.Endpoints.path]);
  }
  onReset() {
    this.awsregion = [];
    this.transferType = [];
    this.ngOnInit();
  }

  getRelevantParameters(
    originalParams: Record<string, string | number | boolean>,
    keysToRemove: string[]
  ) {
    const filteredParams = {};

    for (let key in originalParams) {
      const actualKey = key?.trim();
      const keepParam = !keysToRemove.includes(actualKey);
      if (keepParam) {
        filteredParams[actualKey] = originalParams[actualKey];
      }
    }

    return JSON.stringify(filteredParams);
  }

  getParametersToExclude(transferTypeId: number) {
    const isSFTPTransfer = transferTypeId === 1;
    const isS3Transfer = transferTypeId === 2;

    let keysToRemove = [];

    if (isS3Transfer) {
      keysToRemove = [
        'endpoint_authssh',
        'endpoint_folderpath',
        'endpoint_ipaddress',
        'endpoint_port',
        'endpoint_username',
        'endpoint_password',
        'endpoint_public_ssh_key',
      ];
    }

    if (isSFTPTransfer) {
      keysToRemove = [
        'endpoint_accesskey',
        'endpoint_awskmskey',
        'endpoint_bucketpath',
        'endpoint_encryptkey',
        'endpoint_region',
        'endpoint_secretkey',
      ];
      // if authenticating with ssh we don't need to send the username/password
      if (this.jsonParameters.endpoint_authssh) {
        keysToRemove = keysToRemove.concat([
          'endpoint_username',
          'endpoint_password',
        ]);
      } else {
        keysToRemove = keysToRemove.concat(['endpoint_public_ssh_key']);
      }
    }

    return keysToRemove;
  }

  submitForm(endpoint: EndPoints) {
    this.endpoint = endpoint;
    this.onSubmit();
  }

  onSubmit() {
    this.loadingCount++;
    this.submitted = true;

    const parametersToRemove = this.getParametersToExclude(
      this.endpoint.transfertype_id
    );
    this.endpoint.jsonparameters = this.getRelevantParameters(
      this.jsonParameters,
      parametersToRemove
    );
    this.responseEndPoint = {
      endpoint_id: this.endpoint.endpoint_id,
      endpoint_name: this.endpoint.name,
      endpoint_description: this.endpoint.description,
      endpoint_transfertype_id:
        this.selectedTypeId === undefined
          ? this.endpoint.transfertype_id
          : this.selectedTypeId,
      endpoint_jsonparameters: this.endpoint.jsonparameters,
      endpoint_isenabled:
        this.endpoint.isenabled == null ? false : this.endpoint.isenabled,
        organization_id: 0,
      receiver_organization_id: this.endpoint.receiver_organization_id,
    };

    if (this.isNew) {
      this.dataDeliveryEndPointservice
        .addEndpoint(this.responseEndPoint)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (result) => {
            this.endpoint.endpoint_id = result;
            this.ngZone.run(() => {
              this.router.navigate([Components.Endpoints.path]);
              this.router.navigate([], {
                relativeTo: this.route,
                queryParams: {
                  endpointId: this.endpoint.endpoint_id,
                  name: this.endpoint.name,
                },
                queryParamsHandling: 'merge',
              });
            });
            this.updateBreadcrumb(
              Components.AddEndpoints.path + ' (' + this.endpoint.name + ')'
            );
          },
          error: (err) => {
            this.submitted = true;
            this.loadingCount--;
            this.showErrorMsg(`${err}`, Action.Add, `${this.messageLabel}s`);
          },
          complete: () => {
            this.showSuccessMsg(
              Action.Add,
              `${this.messageLabel}`,
              `${this.endpoint.name}`
            );
            this.submitted = true;
            this.isNew = false;
            this.loadingCount--;
          },
        });
    } else {
      this.dataDeliveryEndPointservice
        .updateEndpoint(this.responseEndPoint)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.updateBreadcrumb(
              Components.Endpoints.label + ' (' + this.endpoint.name + ')'
            );
          },
          error: (err) => {
            this.showErrorMsg(`${err}`, Action.Update, `${this.messageLabel}s`);
            this.loadingCount--;
            this.submitted = true;
          },
          complete: () => {
            this.showSuccessMsg(
              Action.Update,
              `${this.messageLabel}`,
              `${this.endpoint.name}`
            );
            this.loadingCount--;
            this.ngOnInit;
          },
        });
    }
  }
}
