<div *ngIf="visible" class="layout-footer flex justify-content-between align-items-center p-6 p-shadow-2">
  <div class="flex align-items-center">
    <img id="spectrumFooter" src="assets\images\demo-logo-footer.png"
         alt="Spectrum" pTooltip="About" (click)="aboutOverlay.toggle($event)"
         class="mr-2" style=" width: auto; cursor: pointer">
    <span>
      <p class="mb-0 text-sm">{{utcDateTime}} (UTC)</p>
      <p class="mb-0 text-sm">Version: {{version}}.{{build}}</p>
    </span>
  </div>
  <span class="mr-2">
    <a href="https://www.teledynecontrols.com" target="_blank" class="footer-logo-tdy">
      
    </a>
  </span>
</div>

<p-overlayPanel #aboutOverlay [showCloseIcon]="true" [style]="{'width':'500px'}">
  <ng-template pTemplate="content">
    <div>
      <h5>Spectrum</h5>
    </div>

    <div class="mb-2">
      <p class="font-bold mb-0">Installed Modules</p>
      <div class="ml-3 mb-2">
        Spectrum Cloud Service
      </div>
      <div class="ml-6 mb-2">
        Part Number: {{pn}}
      </div>
      <div class="ml-6 mb-2">
        Version: {{version}}.{{build}}
      </div>
      <div class="ml-6 mb-2">
        Ref:  {{gitref}}
      </div>
    </div>

    <div>
      <p class="font-bold mb-0">Support Information</p>
      <div class="ml-3 mb-2">
        <a href="https://www.teledynecontrols.com/support/regional-support"
           target="_blank" style="color: var(--primary-color)" pRipple>
          Regional Support
        </a>
      </div>
      <div class="ml-3 mb-2">
        <a href="https://www.teledynecontrols.com/support/product-support"
           target="_blank" style="color: var(--primary-color)" pRipple>
          Product Support
        </a>
      </div>
      <div class="ml-3 mb-2">
        <a href="https://www.teledynecontrols.com/e-services"
           target="_blank" style="color: var(--primary-color)" pRipple>
          Documentation Support
        </a>
      </div>
      <div class="ml-3">
        Phone: <a href="tel:13107653600" style="color: var(--primary-color)" pRipple>+1 (310) 765-3600</a>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>
