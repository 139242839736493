import {ChangeDetectorRef, Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Validators} from '@angular/forms';
import {DataFrameService} from 'src/app/features/configuration/services/data-frame.service';
import {BreadcrumbService} from 'src/app/common/services/breadcrumb.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {Components} from '../../../integration/configuration.components';
import {SecurityUserService} from 'src/app/security/services/security-user.service';
import {DFDetailsInputCategoryDXS} from '../../dataframe-acms/dataframe/shared/enums/formEnums';
import {ValidationHelper} from '../../dataframe-acms/dataframe/shared/helpers/validation.helper';
import {FrameDetails767DxsComponent} from "../../dataframe-acms/dataframe/frame-details/frame-details-767-DXS/frame-details-767-dxs.component";

@Component({
  selector: 'app-framedetailsDXS',
  templateUrl: './framedetailsDXS.component.html',
  styleUrls: ['./framedetailsDXS.component.css'],
})
export class FramedetailsDXSComponent extends FrameDetails767DxsComponent {

  constructor(
    router: Router,
    breadcrumbService: BreadcrumbService,
    confirmationService: ConfirmationService,
    messageService: MessageService,
    route: ActivatedRoute,
    realUserService: SecurityUserService,
    dataframeservice: DataFrameService,
    changeDetectorRef: ChangeDetectorRef,
  ) {
    super(
      messageService,
      confirmationService,
      router,
      route,
      breadcrumbService,
      realUserService,
      dataframeservice,
      changeDetectorRef
    );
    this.inputCategoryList = Object.values(DFDetailsInputCategoryDXS);
    this.messageLabel = 'Data Frame DXS';
    this.dataFrameMapView = Components.MapViewDXS;
    this.dataFrameListView = Components.ListViewDXS;
    this.breadcrumbService.setItems(route, [
      {label: Components.ListViewDXS.label, routerLink: Components.ListViewDXS.path},
      {label: Components.DataFrameDXS.label},
    ]);
  }

  override setFormConfiguration() {
    this.formConfiguration = {
      [DFDetailsInputCategoryDXS.TARGET_RECORDER.toString()]: [
        this.createSelect(DFDetailsInputCategoryDXS.TARGET_RECORDER, 'Target_Recorder', 'Target Recorder', this.targetRecorderArray, [Validators.required]),
      ],
      [DFDetailsInputCategoryDXS.FRED.toString()]: [
        this.createField(DFDetailsInputCategoryDXS.FRED, 'File_Revision', 'File Revision', [Validators.required, ValidationHelper.validateString()]),
        this.createField(DFDetailsInputCategoryDXS.FRED, 'Source_Document', 'SourceDocument'),
        this.createField(DFDetailsInputCategoryDXS.FRED, 'File_Comments', 'File Comments'),
      ],
      [DFDetailsInputCategoryDXS.Recorder.toString()]: [
        this.createField(DFDetailsInputCategoryDXS.Recorder, 'Recorder_Make_and_Part_Number', 'Recorder Make and Part Number'),
        this.createField(DFDetailsInputCategoryDXS.Recorder, 'FDAU_Make_and_Part_Number', 'FDAU Make and Part Number'),
      ],
      [DFDetailsInputCategoryDXS.Aircrat.toString()]: [
        this.createField(DFDetailsInputCategoryDXS.Aircrat, 'Aircraft_Make_and_Model', 'Aircraft Make and Model'),
        this.createField(DFDetailsInputCategoryDXS.Aircrat, 'Aircraft_Registration', 'Aircraft Registration'),
        this.createField(DFDetailsInputCategoryDXS.Aircrat, 'Aircraft_Alternate_ID', 'Aircraft Alternate ID'),
        this.createField(DFDetailsInputCategoryDXS.Aircrat, 'Aircraft_Serial_Number', 'Aircraft Serial Number'),
      ],
    };
  }
}
