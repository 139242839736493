import { Component, ElementRef, HostBinding, HostListener, OnInit, ViewChild } from '@angular/core';
import { Action, BaseViewComponent } from 'src/app/common/components/base-view.component';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Components } from '../../integration/sendtoteledyne.components';
import { DataUploadService } from '../../services/data-upload.service';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';
import { forkJoin, of, throwError } from 'rxjs';
import { CommonService } from 'src/app/common/services/common.service';
import { FileUpload } from 'primeng/fileupload';
import { Table } from 'primeng/table';


interface FileUploadResponse {
  aircraft: string;
  filename: string;
}

@Component({
  selector: 'app-data-upload',
  templateUrl: './data-upload.component.html',
  styleUrls: ['./data-upload.component.scss']
})
export class DataUploadComponent extends BaseViewComponent implements OnInit {
  @ViewChild('fileUpload') fileUpload: FileUpload;
  @ViewChild('dt') dt: Table;
  @ViewChild('fileInput') fileInput: ElementRef;

  pageTitle = Components.DataUpload.label;
  files: File[] = [];
  processUploadedData = false;
  aircraft: SelectItem[] = [];
  dataUploadFolder: string;
  fileUploadResponse: FileUploadResponse[] = [];
  failedFiles: any[] = [];
  uploadedFiles: number = 0;
  atLeastOneFileUploaded: boolean = false;
  selectedFiles: File[] | null = [];
  dragFiles = false;

  constructor(router: Router,
    private route: ActivatedRoute,
    breadcrumbService: BreadcrumbService,
    confirmationService: ConfirmationService,
    private dataUploadService: DataUploadService,
    private securityUserService: SecurityUserService,
    messageService: MessageService, private commonService: CommonService) { super(messageService, confirmationService, router, breadcrumbService); }

  ngOnInit(): void {
    this.breadcrumbService.setItems(this.route, [
      { label: Components.DataUpload.label + ' (File Selection)'},
    ]);
  }

  onUpload() {
    const files = this.files;
    this.loadingCount++;
    this.dataUploadService
      .getDataUploadFolder()
      .pipe(
        switchMap((folderName) => {
          this.dataUploadFolder = folderName;
          const uploadFileObservables = [];

          for (let i = 0; i < files.length; i++) {
            const file = files[i];

            const uploadObservable = this.dataUploadService.uploadData(folderName, file, file.name).pipe(
              tap(() => {
                this.uploadedFiles++;
              }),
              catchError((error) => {
                this.showErrorMsg(`${error}`, Action.Upload, file.name);
                return of(null);
              })
            );

            uploadFileObservables.push(uploadObservable);
          }

          return forkJoin(uploadFileObservables).pipe(
            tap(() => {
              this.atLeastOneFileUploaded = true;
            }),
            catchError((error) => {
              if (!this.atLeastOneFileUploaded) {
                return throwError(error);
              }
              return of([]);
            })
          );
        })
      )
      .subscribe(
        () => {
          if (this.atLeastOneFileUploaded) {
            this.processUploadedData = true;
            this.onUploadComplete();
          } else {
            this.loadingCount--;
          }
        },
        (error) => {
          console.error('Error during file uploads:', error);
          this.loadingCount--;
        }
      );
  }

  onUploadComplete() {
    forkJoin({
      processedData: this.dataUploadService.getProcessedData(this.dataUploadFolder),
      airlineAircraft: this.commonService.listAircraftFilters()
    }).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      {
        next: (res) => {
          res.airlineAircraft.forEach(ac => this.aircraft.push({ label: ac.aircraft_registration, value: ac.aircraft_registration }));
          this.fileUploadResponse = res.processedData.success;
          this.failedFiles = res.processedData.failed.map((file) => {
            return {
              ...file,
              filename: file.filename.split('/').pop()
            }
          });
        },
        error: (error) => {
          this.showErrorMsg(`${error}`, Action.Get, `processed uploaded data`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
          this.processUploadedData = true;
          this.updateBreadcrumb(Components.DataUpload.label + ' (Aircraft Selection)');
          this.files = [];
          this.uploadedFiles = 0;
        },
      }
    )
  }
  onFileSelected(event: any) {
    this.files = [...this.files]
    const maxSizeInBytes = 5 * Math.pow(1024, 3); // 5GB
    const files = event.target.files;
    for (let i = 0; i < files.length; i++) {
      const fileExists = this.files.find((file) => file.name === files[i].name);
      if(files[i].size > maxSizeInBytes) {
        this.showErrorMsg(files[i].name + " is exceeded MAX file size : 5GB", Action.Add, "File");
      }
      else if(fileExists == undefined) {
        this.files.push(files[i]);
      }
    }
  }

  @HostListener('document:drop', ['$event'])
  onDrop(event: DragEvent) {
    event.preventDefault();
    const files = event.dataTransfer.files;
    this.files = [...this.files]
    const maxSizeInBytes = 5 * Math.pow(1024, 3); // 5GB
    for (let i = 0; i < files.length; i++) {
      const fileExists = this.files.find((file) => file.name === files[i].name);
      if (files[i].type !== "application/x-zip-compressed") {
        this.showErrorMsg(files[i].name + " is not a Zip file", Action.Add, "File");
      } else if(files[i].size > maxSizeInBytes) {
        this.showErrorMsg(files[i].name + " is exceeded MAX file size : 5GB", Action.Add, "File");
      }
       else if (fileExists == undefined) {
        this.files.push(files[i]);
        this.selectedFiles = this.selectedFiles.filter((val) => val.name != files[i].name);
      }
    }
    this.dragFiles = false;
  }

  @HostListener('document:dragover', ['$event'])
  onDragOver(event: DragEvent) {
    event.preventDefault();
    this.dragFiles = true;
  }

  @HostListener('document:dragenter', ['$event'])
  onDragEnter(event: DragEvent) {
    event.preventDefault();
    this.dragFiles = true;
  }

  @HostListener('document:dragleave', ['$event'])
  onDragLeave(event: DragEvent) {
    event.preventDefault();
    if (event.relatedTarget == null) {
      this.dragFiles = false;
    }
  }

  removeFile(file: File) {
    const index = this.files.indexOf(file);
    if (index !== -1) {
      this.files.splice(index, 1);
    }
  }

  onClear() {
    this.fileUpload?.clear();
    this.files = [];
    this.aircraft = [];
    this.failedFiles = [];
    this.processUploadedData = false;
    this.dataUploadFolder = '';
    this.fileUploadResponse = [];
    this.selectedFiles = [];
    this.uploadedFiles = 0;
    this.fileInput.nativeElement.value = '';
    this.ngOnInit();
  }

  onCancel() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to cancel?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.onClear();
      }
    });
  }

  countSelectedAircraft(): number {
    return this.fileUploadResponse.filter(res => res.aircraft !== null).length;
  }

  removeSelectedFiles() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to remove the selected files?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.files = this.files.filter((val) => !this.selectedFiles?.includes(val));
        this.selectedFiles = [];
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Files Removed', life: 3000 });
        this.fileInput.nativeElement.value = '';
        }
    });
  }

  onSubmit() {
    this.loadingCount++;
    this.dataUploadService.submitData(this.dataUploadFolder, this.fileUploadResponse).pipe().subscribe({
      next: () => {
        this.loadingCount--;
        this.showSuccessMsg(Action.Add, "Data for processing");
      },
      error: (error) => {
        this.loadingCount--;
        this.showErrorMsg(`${error}`, Action.Update, `files`);
      },
      complete: () => {
        this.onClear();
      }
    }
    )
  }
}
