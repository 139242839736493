import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EngineType } from '../models/engineType';
import { BaseService } from 'src/app/common/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class EngineTypeService extends BaseService {
  engineTypeUrl = environment.apiUrl.admin + 'engine-type';

  constructor(private http: HttpClient) {
    super();
  }

  getEngineType(engineTypeID: number): Observable<EngineType> {
    const url = this.engineTypeUrl + `/${engineTypeID}`;
    return this.http.get<EngineType>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError)
      );
  }

  listEngineTypes(): Observable<EngineType[]> {
    const url = this.engineTypeUrl;
    return this.http.get<EngineType[]>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError)
      );
  }

  addEngineType(newEngineType: EngineType) {
    return this.http.post<number>(this.engineTypeUrl, newEngineType, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError));
  }

  deleteEngineType(engineTypeID: number) {
    const url = this.engineTypeUrl + `/${engineTypeID}`;
    return this.http.delete(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError));
  }

  updateEngineType(updatedEngineType: EngineType) {
    const url = this.engineTypeUrl + `/${updatedEngineType.enginetype_id}`;
    return this.http.put<number>(url, updatedEngineType, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError));
  }

}
