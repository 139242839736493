<p-blockUI [blocked]="loadingCount > 0">
    <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>

<div class="col-12 card">
    <div class="card-header m-2">
        <h5>{{pageTitle}}</h5>
    </div>
    <div class="col-12">
        <p-table [filterDelay]="0" [responsive]="true" [sortOrder]="1" [value]="sarMapsVersions" dataKey="name"
            id="recorderRegexTable" selectionMode="single" sortField="name">
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="name">
                        <div class="flex justify-content-start align-items-center">
                            Versions
                            <p-sortIcon field="name"></p-sortIcon>
                            <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0"
                                display="menu" field="name" type="text" matchMode="contains"></p-columnFilter>
                        </div>
                    </th>
                    <th>
                        <div class="flex justify-content-between align-items-center">
                            Description
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template let-sarMapVersion pTemplate="body">
                <tr [pSelectableRow]="sarMapVersion">
                    <td>
                        {{sarMapVersion.version}}
                    </td>
                    <td>
                        {{sarMapVersion.description}}
                    </td>

                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="3">No Sar Map Version found.</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>