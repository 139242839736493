import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { forkJoin, takeUntil } from 'rxjs';
import {
  Action,
  BaseViewComponent,
} from 'src/app/common/components/base-view.component';
import { CommonService } from 'src/app/common/services/common.service';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { BreadcrumbService } from '../../../../common/services/breadcrumb.service';
import { SARMapsService } from '../../../../features/configuration/services/sar-map.service';
import { Components } from '../../integration/datadelivery.components';
import { permissions } from '../../integration/datadelivery.permissions';

import {
  ConversionType,
  RedactionType,
  ResponseSAR,
  SAR,
  SARMap,
  jsonValues,
} from '../../models/SARRedaction';
import { SARRedactionService } from '../../services/sarRedaction.service';
import { mappedRedactionTypes, REDACTION_TYPES } from '../../models/FlightDataRedactions';
@Component({
  selector: 'app-sarredaction-add',
  templateUrl: './sarredaction-add.component.html',
  styleUrls: ['./sarredaction-add.component.css'],
})
export class SARRedactionAddComponent
  extends BaseViewComponent
  implements OnInit
{
  pageTitle = Components.AddSARRedactions.label;
  public canAdd: boolean;
  public canDelete: boolean;
  public canEdit: boolean;
  public modalDialog?: boolean;
  public submitted: boolean = false;
  uniqueError: boolean = false;
  showButtons: boolean = true;
  today = new Date();
  todayAsStr = `${this.today.getFullYear()}-${this.today.getMonth()}-${this.today.getDate()}`;
  cols: any[] = [];
  SARredactions: SAR[] = [];
  SARredaction!: SAR;
  convtype: ConversionType[] = [];
  redactiontype: RedactionType[] = [];
  sarmap: SARMap[] = [];
  jsonParameters: jsonValues;
  sarData: any;
  storeddata: any;
  sarMapList: SARMap[] = [];
  avialableSarMap: SARMap[] = [];
  selectedSarMap: SARMap[] = [];
  selectedSarMapIDs: string[] = [];
  editselectedSarMapIDs: string[] = [];
  picklistChange = false;
  isButtonDisabled: boolean = true;
  initialRecordersCount: number;
  saveButtonDisabled: boolean;
  isReadonly: boolean = true;
  responseSar: ResponseSAR;
  selectMapId: number;
  organization_id: number;
  selectedMapType: string;
  selectConvertionId: number;
  selectedConversionType: string;
  messageLabel = 'SAR redaction';
  newLabel = 'New SAR Redaction';

  intialVal() {
    this.pageTitle = this.newLabel;
    this.updateBreadcrumb(this.pageTitle);
    this.SARredaction = new SAR();
    this.jsonParameters = new jsonValues();
    this.SARredaction.redaction_id = -1;
    this.isNew = true;
  }

  constructor(
    router: Router,
    private route: ActivatedRoute,
    breadcrumbService: BreadcrumbService,
    confirmationService: ConfirmationService,
    messageService: MessageService,
    private sarmapsService: SARMapsService,
    private sarRedactionService: SARRedactionService,
    private commonService: CommonService,
    private realUserService: SecurityUserService
  ) {
    super(messageService, confirmationService, router, breadcrumbService);
    this.getBreadCrums();
  }

  getBreadCrums() {
    this.breadcrumbService.setItems(this.route, [
      {
        label: Components.SARRedactions.label,
        routerLink: Components.SARRedactions.path,
      },
      {
        label:
          Components.AddSARRedactions.label +
          ` (${this.route.snapshot.queryParamMap.get('name')})`,
      },
    ]);
  }

  ngOnInit(): void {
    this.convtype = [];
    mappedRedactionTypes.forEach(type => {
      if(type.redactiontype_id == REDACTION_TYPES.CSV || type.redactiontype_id === REDACTION_TYPES.PARQUET){
        this.convtype.push({
          conversion_id: type.redactiontype_id,
          conversion_type: type.name
        })
      }
    })
    this.getBreadCrums();
    this.canAdd = this.realUserService.userHasPermission(
      permissions.datadelivery_sar_redaction.create
    );
    this.canDelete = this.realUserService.userHasPermission(
      permissions.datadelivery_sar_redaction.delete
    );
    this.canEdit = this.realUserService.userHasPermission(
      permissions.datadelivery_sar_redaction.manage
    );
    const redactionId = +this.route.snapshot.queryParamMap.get('redactionId');
    if (redactionId) {
      const isClone = !!this.route.snapshot.queryParamMap.get('clone');
      if (isClone) {
        this.isNew = true;
        !this.canAdd ? (this.isReadonly = true) : (this.isReadonly = false);
        !this.canAdd ? (this.showButtons = false) : (this.showButtons = true);
        this.pageTitle = 'Clone SAR Redaction';
        this.updateBreadcrumb(
          `${this.pageTitle} (${this.route.snapshot.queryParamMap.get('name')})`
        );
      } else {
        this.isNew = false;
        !this.canEdit ? (this.isReadonly = true) : (this.isReadonly = false);
        !this.canEdit ? (this.showButtons = false) : (this.showButtons = true);
      }
      this.loadSarRedactionDetails(redactionId, isClone);
    } else {
      !this.canAdd ? (this.isReadonly = true) : (this.isReadonly = false);
      !this.canAdd ? (this.showButtons = false) : (this.showButtons = true);
      this.intialVal();
      this.loadSARReportsMap();
    }
  }

  loadSarRedactionDetails(redaction_id: number, clone: boolean) {
    this.loadingCount++;
    this.jsonParameters = new jsonValues();
    forkJoin({
      getsardetails:
        this.sarRedactionService.getSARRedaction_by_id(redaction_id),
      avialableAcmsMap: this.commonService.getSARMapsList(),
    })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results) => {
          results.avialableAcmsMap.forEach((ele) => {
            this.sarmap.push({
              SARMap_id: ele.sarmap_id,
              SARMap_type: ele.name,
            });
          });
          this.SARredaction = results.getsardetails;
          if (this.SARredaction.defaultparametervaluesconfig != null) {
            this.jsonParameters = JSON.parse(
              this.SARredaction.defaultparametervaluesconfig
            );
          }
          let getMapJson = JSON.parse(this.SARredaction.mapjson ?? "{}");
          this.updateSelectedSarMapIds(getMapJson, this.SARredaction.sarmap_id, clone)
        },
        error: (error) => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        },
      });
  }

  updateSelectedSarMapIds(getMapJson: string[], sarMapId: number, clone: boolean){
    this.editselectedSarMapIDs = getMapJson;
    this.onEditloadSARMapDetails(sarMapId);
    if (clone) {
      this.SARredaction.name = '';
    }
  }

  loadSARReportsMap() {
    this.loadingCount++;
    forkJoin({
      avialableAcmsMap: this.commonService.getSARMapsList(),
    })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results) => {
          //sorting to show in alphabetical order
          results.avialableAcmsMap.forEach((ele) => {
            this.sarmap.push({
              SARMap_id: ele.sarmap_id,
              SARMap_type: ele.name,
            });
          });
        },
        error: (error) => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        },
      });
  }

  loadSARMapDetails(mapId?: number) {
    //if modifying the existing SAR
    this.loadingCount++;
    this.saveButtonDisabled = true;
    this.sarMapList = [];
    this.avialableSarMap = [];
    forkJoin({
      avialablesar: this.commonService.getSARMapReports(mapId),
    })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results) => {
          //sorting to show in alphabetical order
          results.avialablesar.forEach((ele) => {
            this.sarMapList.push({ SARMap_type: ele });
          });
          this.sarMapList.forEach((ele) => {
            this.selectedSarMap.push({
              SARMap_id: ele.SARMap_id,
              SARMap_type: ele.SARMap_type,
            });
          });

          this.avialableSarMap = [];

          results.avialablesar.forEach((ele) => {
            this.avialableSarMap.push({ SARMap_type: ele });
          });

          this.selectedSarMap.forEach((ele) => {
            this.selectedSarMapIDs.push(ele.SARMap_type);
          });

          //Filter the source Regex List
          if (this.avialableSarMap && this.avialableSarMap) {
            this.avialableSarMap = this.avialableSarMap.filter(
              (p) =>
                this.selectedSarMap.findIndex(
                  (i) => i.SARMap_id == p.SARMap_id
                ) == -1
            );
          }
          this.initialRecordersCount = this.selectedSarMap.length;

          this.initialRecordersCount = 0;
          this.selectedSarMapIDs = [];
          this.sarMapList.sort((a, b) => {
            return a.SARMap_type.localeCompare(b.SARMap_type);
          });
        },
        error: (error) => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        },
      });
  }

  onEditloadSARMapDetails(mapId?: number) {
    //if modifying the existing SAR
    //!this.canEdit ? this.isReadonly = true : this.isReadonly = false;
    this.loadingCount++;
    this.saveButtonDisabled = true;
    forkJoin({
      availablesar: this.commonService.getSARMapReports(mapId),
    })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results) => {
          //sorting to show in alphabetical order
          this.avialableSarMap = [];
          results.availablesar.forEach((ele) => {
            if (this.editselectedSarMapIDs.find((a) => a == ele)) {
              this.avialableSarMap.push({ SARMap_type: ele });
              this.selectedSarMap.push({ SARMap_type: ele });
            }
            this.sarMapList.push({ SARMap_type: ele });
          });

          this.selectedSarMap.forEach((ele) => {
            this.selectedSarMapIDs.push(ele.SARMap_type);
          });

          //Filter the source Regex List
          if (this.sarMapList && this.avialableSarMap) {
            this.sarMapList = this.sarMapList.filter(
              (p) =>
                this.selectedSarMap.findIndex(
                  (i) => i.SARMap_type == p.SARMap_type
                ) == -1
            );
          }
          this.initialRecordersCount = this.selectedSarMap.length;
          this.sortSarMapList()
          this.sortAvailableSarMapList()
        },
        error: (error) => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        },
      });
  }

  sortSarMapList(){
    this.sarMapList.sort((a, b) => {
      return a.SARMap_type.localeCompare(b.SARMap_type);
    });
  }

  sortAvailableSarMapList(){
    this.avialableSarMap.sort((a, b) => {
      return a.SARMap_type.localeCompare(b.SARMap_type);
    });
  }

  onSarMapChange(event: any) {
    this.selectMapId = event.value;
    this.loadSARMapDetails(event.value);
  }

  onConverionChange(event: any) {
    const selectValue = this.convtype.find(
      (a) => (a.conversion_id = event.value)
    );
    this.selectConvertionId = selectValue.conversion_id;
    this.selectedConversionType = selectValue.conversion_type;
  }

  onCancel() {
    this.router.navigate([Components.SARRedactions.path]);
  }

  onReset() {
    this.picklistChange = false;
    this.selectedSarMap = [];
    this.selectedSarMapIDs = [];
    this.sarMapList = [];
    this.avialableSarMap = [];
    this.convtype = [];
    this.ngOnInit();
    this.isButtonDisabled = true;
  }

  onMoveToTarget(event) {
    event.items.forEach((ele) => {
      this.selectedSarMapIDs.push(ele.SARMap_type);
    });
    this.isButtonDisabled = false;
    this.selectedSarMapIDs = [...new Set(this.selectedSarMapIDs)]; // remove the duplicate entries
    this.picklistChange = true;
  }

  onMoveAllToSource(event) {
    event.items.forEach((ele) => {
      const index = this.selectedSarMapIDs.indexOf(ele.SARMap_type);
      if (index > -1) {
        this.selectedSarMapIDs.splice(index, 1);
      }
    });
    this.picklistChange = true;
    this.isButtonDisabled = false;
  }

  onMoveAllToTarget(event) {
    this.picklistChange = true;
    this.isButtonDisabled = false;
    event.items.forEach((ele) => {
      this.selectedSarMapIDs.push(ele.SARMap_type);
    });
  }

  onMoveToSource(event) {
    this.picklistChange = true;
    this.isButtonDisabled = false;
    event.items.forEach((ele) => {
      const index = this.selectedSarMapIDs.indexOf(ele.SARMap_type);
      if (index > -1) {
        this.selectedSarMapIDs.splice(index, 1);
      }
    });
  }

  onSubmit() {
    this.loadingCount++;
    this.submitted = true;
    this.responseSar = {
      redaction_id: this.SARredaction.redaction_id,
      redaction_name: this.SARredaction.name,
      redaction_description: this.SARredaction.description,
      redaction_redactiontype_id:
        this.selectConvertionId == undefined
          ? this.SARredaction.redactiontype_id
          : this.selectConvertionId,
      redaction_textualoutput:
        this.SARredaction.textualoutput == undefined
          ? false
          : this.SARredaction.textualoutput,
      redaction_sarmap_id:
        this.selectMapId == undefined
          ? this.SARredaction.sarmap_id
          : this.selectMapId,
      redaction_defaultparametervaluesconfig: JSON.stringify(this.jsonParameters),
      redaction_mapjson: JSON.stringify(this.selectedSarMapIDs),
      organization_id: 0,
      enhanced: false,
    };
    if (this.isNew) {
      this.sarRedactionService
        .addSARRedaction(this.responseSar)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (result) => {
            this.SARredaction.redaction_id = result;
            // Update query param
            this.router
              .navigate([], {
                relativeTo: this.route,
                queryParams: {
                  redactionId: this.SARredaction.redaction_id,
                },
                queryParamsHandling: 'merge',
              })
              .then();
            this.updateBreadcrumb(
              Components.AddSARRedactions.label +
                ' (' +
                this.SARredaction.name +
                ')'
            );
            this.pageTitle = Components.AddSARRedactions.label;
            this.picklistChange = false;
          },
          error: (err) => {
            this.showErrorMsg(`${err}`, Action.Add, `${this.messageLabel}s`);
            this.submitted = true;
            this.loadingCount--;
            this.isButtonDisabled = true;
          },
          complete: () => {
            this.showSuccessMsg(
              Action.Add,
              `${this.messageLabel}`,
              `${this.SARredaction.name}`
            );
            this.isNew = false;
            this.isButtonDisabled = true;
            this.saveButtonDisabled = true;
            this.loadingCount--;
          },
        });
    } else {
      this.sarRedactionService
        .updateSARRedaction(this.responseSar)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.updateBreadcrumb(
              Components.AddSARRedactions.label +
                ' (' +
                this.SARredaction.name +
                ')'
            );
            this.picklistChange = false;
          },
          error: (err) => {
            this.isButtonDisabled = true;
            this.showErrorMsg(`${err}`, Action.Update, `${this.messageLabel}s`);
            this.loadingCount--;
          },
          complete: () => {
            this.picklistChange = false;
            this.showSuccessMsg(
              Action.Update,
              `${this.messageLabel}`,
              `${this.SARredaction.name}`
            );
            this.loadingCount--;
            this.isButtonDisabled = true;
          },
        });
    }
  }
}
