import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { BaseService } from 'src/app/common/services/base.service';
import { AirlinesModel, sarMapsModel, sarMapsVersions } from 'src/app/features/configuration/models/sarMaps';
import { catchError } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SARMapsService extends BaseService {

  constructor( private http: HttpClient) {
    super();
   }
  sarMapsURL = environment.apiUrl.config;

  public addSarMap(recordData:sarMapsVersions, content: any ) {
     let model = {"name": recordData.name, "sarmapversion": recordData.version, content};
     const options = {headers : this.httpGetHeadersForJSON()};
     model.content = model.content.replace('data:text/plain;base64,', '');
     return this.http.post<number>(this.sarMapsURL + 'sar-map', model, options)
     .pipe(
       catchError(this.httpHandleError)
     )
  }

  public getSARMapVersions(sarMapID: number): Observable<sarMapsVersions[]> { 
    return this.http.get<sarMapsVersions[]>(this.sarMapsURL + 'sar-map' + `/${sarMapID}`,{ headers: this.httpGetHeadersForJSON() }).pipe(catchError(this.httpHandleError));   
  }

  public getSARMapVersionsById(sarMapsID: number): Observable<sarMapsVersions[]> { 
    return this.http.get<sarMapsVersions[]>(this.sarMapsURL + 'sar-map-versions' + `/${sarMapsID}`,{ headers: this.httpGetHeadersForJSON() }).pipe(catchError(this.httpHandleError));   
  }

  public getAirlinesBySARMaps(sarMapID: number): Observable<AirlinesModel[]> {
    return this.http.get<AirlinesModel[]>(this.sarMapsURL + 'airlines-by-sarmap' + `/${sarMapID}`,{ headers: this.httpGetHeadersForJSON() }).pipe(catchError(this.httpHandleError));   
  }

  public manageSARMap(sarMapID: number, listofairlineid: number[]) {
    return this.http.put(this.sarMapsURL + 'sar-map' + `/${sarMapID}`, { "listofairlineid": listofairlineid, "sarmap_id":sarMapID }, { headers: this.httpGetHeadersForJSON() }).
      pipe(catchError(this.httpHandleError));
  }

  public deleteSarmap(sarMapID: number) {
    return this.http.delete(this.sarMapsURL + 'sar-map' + `/${sarMapID}`, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError));
  }

  public SARMapVersionByName(sarMapName: string): Observable<number> { 
    let model = {"sar_map_name": sarMapName };

    return this.http.post<number>(this.sarMapsURL+'sar-map-latest-version'  ,model, { headers: this.httpGetHeadersForJSON() })
    .pipe(catchError(this.httpHandleError));
  }
}