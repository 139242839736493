import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class BreadcrumbService {
  public AlreadySet = false;
  private items: MenuItem[] = [];
  private itemsSource = new Subject<MenuItem[]>();
  public ItemsHandler = this.itemsSource.asObservable();

  constructor(private route: ActivatedRoute,
    private router: Router) {
    // If browser is refreshed, the last breadcrumb will be in session. Use it create the breadcrumb and ignore
    // any breadcrumb set from the current page.
    const data = sessionStorage.getItem('crumbs');

    if (data !== null && data !== '') {
      this.setItems(null, JSON.parse(data));
      this.AlreadySet = true;
    }
  }

  /**
 * Adds a query parameter to each item to mark it as a breadcrumb item, which is used to determine
 * when user clicks the breadcrumb versus some other navigation (menu click or other).
 *
 * @param items - The array of MenuItems to add the 'bc' flag to.
 */
  private static AddBreadcrumbFlag(items: MenuItem[]) {
    if (items) {
      for (const item of items) {
        if (item.queryParams === undefined) {
          item.queryParams = {};
        }

      }
    }
  }

  /**
   * Sets the breadcrumb trail to the specified items.
   *
   * @param route - The current page's route (used to determine if breadcrumb link was clicked, or a different navigation).
   * @param items - Collection of MenuItems to show in breadcrumb.
   */
  setItems(route: ActivatedRoute, items: MenuItem[]) {
    // Every page will call this function when it loads, but if the page caller sets the breadcrumb prior to
    // navigating to the page OR if the browser is refreshed, the breadcrumb will already exist, so we will
    // ignore the request and clear the AlreadySet flag for the next navigation.
    if (!items || items.length <= 0) {
      this.items = null;
      this.itemsSource.next(null);
      sessionStorage.setItem('crumbs', '');
    } else if (!this.AlreadySet) {
      let found = false;

      // Check if current route has 'bc' query parameter...
      if (route?.snapshot.queryParamMap.get('bc')) {
        // User clicked a breadcrumb item to navigate to the page (route) so find the item in the existing breadcrumb
        // trail and remove all subsequent items. Note that the "items" parameter is ignored in this case.
        // Note: logic below gets routerLink like '/aod-details' and trims it to 'aod-details' to look for match.
        let routerLink = route.snapshot['_routerState'].url.substring(0, route.snapshot['_routerState'].url.indexOf('?'));
        if (routerLink?.length > 0 && routerLink[0] === '/') {
          routerLink = routerLink.substring(1);
        }

        for (let x = this.items.length - 1; x >= 0; x--) {
          if (this.items[x].routerLink === routerLink) {
            // rrm? TODO - match on query params too? The same page may be in the breadcrumb trail multiple times

            // Found the matching item, concatenate any items after it in the trail and clear the routerLink (no link on current page)
            this.items.splice(x + 1);
            this.items[x].routerLink = null;

            found = true;
            break;
          }
        }
      }

      // No 'bc' in query parameter OR could not find the crumb in the trail, then reset to specified items
      if (!found) {
        // User clicked a main menu item or some other navigation link, create all new breadcrumb with items provided
        BreadcrumbService.AddBreadcrumbFlag(items);
        this.items = items;
      }

      // Always update source and session with new or changed items so it's available on a page refresh
      this.itemsSource.next(this.items);
      sessionStorage.setItem('crumbs', JSON.stringify(this.items));
    }

    this.AlreadySet = false;
  }

  /**
   * Pushes items onto the existing breadcrumb trail.
   *
   * @param items - Collection of MenuItems to add to breadcrumb.
   */
  pushItems(items: MenuItem[]) {
    BreadcrumbService.AddBreadcrumbFlag(items);
    const allItems = [...this.items];
    allItems.push(...items);
    this.setItems(null, allItems);
  }

  /**
   * Pushes items onto the existing breadcrumb trail for the next page navigation. When the next page attempts to set
   * the breadcrumb using the setItems function, it will be ignored.
   *
   * @param items - An array of MenuItems to add to the breadcrumb.
   */
  pushItemsBeforeNavigate(items: MenuItem[]) {
    BreadcrumbService.AddBreadcrumbFlag(items);
    const allItems = [...this.items];
    allItems.push(...items);
    this.setItems(null, allItems);
    this.AlreadySet = true;
  }

  /**
   * Pops the last item off the breadcrumb trail. This allows the caller to modify the item and add it back
   * (e.g. with a router link defined).
   *
   * @returns - The last item in the breadcrumb trail or null if it doesn't exist.
   */
  popItem(): MenuItem {
    return this.items.length > 0 ? this.items.pop() : null;
  }

  /**
   * Gets the current breadcrumb trail.
   *
   * @returns - The array of MenuItems that define the breadcrumb trail.
   */
  getItems(): MenuItem[] {
    return this.items;
  }

  public navigateTo(path: string, isMapView?: boolean, state?: any, rest?: any): void { // Maybe later should be in navigation service
    this.router.navigate([path], {
      queryParams: {
        Name: this.getQueryParam('Name'),
        frame: this.getQueryParam('frame'),
        version: this.getQueryParam('version'),
        version_index: this.getQueryParam('version_index'),
        ...(isMapView !== undefined && { isMapView: isMapView ? 1 : 0 }), // legacy logic is using numbers
        ...(state !== undefined && { state: state }),
        ...(rest !== undefined && rest)
      }
    });
  }

  public getQueryParam(queryParam: string): string {
    return this.route.snapshot.queryParamMap.get(queryParam);
  }
}
