import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-action-buttons',
  templateUrl: './action-buttons.component.html'
})

export class UserActionButtonsComponent {
  @Input() canView: boolean;
  @Input() loggedIn: boolean;
  @Input() canEdit: boolean;
  @Input() canCustomize: boolean;
  @Input() userForm: NgForm;
  @Input() orgsChanged: boolean;
  @Input() avatarChanged: boolean;

  @Output() checkValidationsOnSave = new EventEmitter<NgForm>()
  @Output() onCancel = new EventEmitter<void>();
  @Output() onReset = new EventEmitter<void>();

  onCancelClick() {
    this.onCancel.emit();
  }

  onResetClick() {
    this.onReset.emit();
  }

  checkValidations(userForm: NgForm){
    return this.checkValidationsOnSave.emit(userForm)
  }
}