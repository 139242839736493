import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { forkJoin, takeUntil } from 'rxjs';
import {
  Action,
  BaseViewComponent,
} from 'src/app/common/components/base-view.component';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { CommonService } from 'src/app/common/services/common.service';
import {
  ConversionType,
  RedactionType,
  SAR,
  SARChannel,
  SARMap,
  jsonValues,
} from 'src/app/features/datadelivery/models/SARRedaction';
import { EndpointSARRedactionService } from '../../../services/sarRedaction.service';
import { Components } from '../../../integration/endpointdatadelivery.components';
@Component({
  selector: 'app-sarredaction-data',
  templateUrl: './sarredaction-data-list.component.html',
  styleUrls: ['./sarredaction-data-list.component.css'],
})
export class SARRedactionDataListComponent
  extends BaseViewComponent
  implements OnInit
{
  pageTitle = Components.SARRedactionsDataDelivery.label;
  public canAdd: boolean;
  public canDelete: boolean;
  public canEdit: boolean;
  public canView: boolean;
  public modalDialog?: boolean;
  public submitted?: boolean;
  uniqueError: boolean = false;
  today = new Date();
  todayAsStr = `${this.today.getFullYear()}-${this.today.getMonth()}-${this.today.getDate()}`;
  SARredactions: SAR[] = [];
  SARredaction!: SAR;
  isClone: number = 1;
  convtype: ConversionType[] = [];
  redactiontype: RedactionType[] = [];
  sarmap: SARMap[] = [];
  sarchannel: SARChannel[] = [];
  storeddata: any;
  jsonParameters: jsonValues;
  messageLabel = 'SAR redaction';
  intialVal() {
    this.SARredaction = new SAR();
  }
  SARForm!: FormGroup;
  constructor(
    router: Router,
    private route: ActivatedRoute,
    breadcrumbService: BreadcrumbService,
    confirmationService: ConfirmationService,
    messageService: MessageService,
    private endpointSarRedactionService: EndpointSARRedactionService,
    private commonService: CommonService
  ) {
    super(messageService, confirmationService, router, breadcrumbService);
    this.getBreadCrums();
  }

  getBreadCrums() {
    this.breadcrumbService.setItems(this.route, [
      {
        label: Components.SARRedactionsDataDelivery.label,
        routerLink: Components.SARRedactionsDataDelivery.path,
      },
    ]);
  }

  ngOnInit(): void {
    this.getBreadCrums();
    this.intialVal();
    this.loadSARData();
  }

  loadSARData() {
    this.loadingCount++;
    forkJoin({
      sarData: this.endpointSarRedactionService.getSARRedactions(),
    })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results) => {
          this.SARredactions = [];
          results.sarData.forEach((element) => {
            if (element.defaultparametervaluesconfig != null) {
              this.jsonParameters = JSON.parse(
                element.defaultparametervaluesconfig
              );
            }
            this.SARredactions.push({
              conversiontype_name: element.conversiontype_name,
              name: element.name,
              description: element.description,
              redaction_id: element.redaction_id,
            });
          });
        },
        error: (error) => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        },
      });
  }

  onRowSelect(event) {
    this.router
      .navigate([Components.ViewSARRedactionsDataDelivery.path], {
        state: { sardata: event.data },
        queryParams: {
          redactionId: event.data.redaction_id,
          name: event.data.name,
        },
      })
      .then();
  }
}
