<p-blockUI [blocked]="loadingCount > 0">
  <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<p-confirmDialog></p-confirmDialog>

<div *ngIf="aircraft" class="col-12 card p-3">
  <form #aircraftForm="ngForm" (ngSubmit)="onSubmit();" novalidate>
    <div class="card-header m-2">
      <h5>{{pageTitle}}</h5>
    </div>

    <div class="card">
      <h6>Details</h6>
      <div class="formgrid grid ">
        <div class="field col-12 md:col-6">
          <span class="p-float-label">
            <input [disabled]="isReadOnly" pInputText type="text" name="aircraftNumber" id="aircraftNumber"
              #aircraftNumber="ngModel" [(ngModel)]="aircraft.aircraft_registration"
              [textContent]="aircraft.aircraft_registration" [style]="{'width': '100%'}" maxlength="32" required
              pattern=".*\S.*" />
            <label for="aircraftNumber">Aircraft Registration</label>
          </span>
          <div class="tdy-required"
            *ngIf="(aircraftNumber.touched || aircraftNumber.dirty) && aircraftNumber.hasError('required')">
            Aircraft Registration is required
          </div>
          <div class="tdy-required" *ngIf="aircraftNumber.hasError('pattern')">
            Aircraft Registration cannot be blank
          </div>
        </div>
        <div class="field col-12 md:col-6">
          <span class="p-float-label">
            <p-dropdown [disabled]="isReadOnly" [options]="models" optionLabel="aircraftmodel"
              optionValue="aircraftmodel_id" name="aircraftModelID" id="aircraftModelID" [autoDisplayFirst]="false"
              #aircraftModelID="ngModel" [(ngModel)]="aircraft.aircraftmodel_id" [style]="{'width': '100%'}"
              [required]=true appendTo="body" (onChange)="onModelChange()"></p-dropdown>
            <label for="aircraftModelID">Aircraft Model</label>
          </span>
          <div class="tdy-required"
            *ngIf="(aircraftModelID.touched || aircraftModelID.dirty) && aircraftModelID.hasError('required')">
            Aircraft Model is required
          </div>
        </div>
        <div class="field col-12 md:col-6">
          <span class="p-float-label">
            <p-dropdown [disabled]="isReadOnly" [options]="engineTypes" optionLabel="enginetype_name"
              optionValue="enginetype_id" name="engineTypeID" id="engineTypeID" [autoDisplayFirst]="false"
              #engineTypeID="ngModel" [(ngModel)]="aircraft.enginetype_id" [style]="{'width': '100%'}" [required]=true
              appendTo="body">
            </p-dropdown>
            <label for="engineTypeID">Engine Type</label>
          </span>
          <div class="tdy-required"
            *ngIf="(engineTypeID.touched || engineTypeID.dirty) && engineTypeID.hasError('required')">
            Engine Type is required
          </div>
        </div>
        <div class="field col-12 md:col-6">
          <span class="p-float-label">
            <input [disabled]="isReadOnly" pInputText type="text" style="width: 100%; font-size: small"
              name="aircraftSerialNumber" id="aircraftSerialNumber" [(ngModel)]="aircraft.serial_number" />
            <label>Serial Number</label>
          </span>
        </div>
        <div class="field col-12 md:col-6">
          <span class="p-float-label">
            <input [disabled]="isReadOnly" pInputText type="text" style="width: 100%; font-size: small"
              name="aircraftNoseNumber" id="aircraftNoseNumber" [(ngModel)]="aircraft.nose_number" />
            <label>Nose Number</label>
          </span>
        </div>
      </div>
    </div>
    <div class="card">
      <h6>Aircraft Interior Selection</h6>
      <div class="field">
        <p-pickList [disabled]="isReadOnly" name="aircraftInterior" id="aircraftInterior" [source]="aircraftInteriors"
          [target]="aircraft.aircraftinterior" filterBy="deck" sourceHeader="Available Aircraft Interiors"
          targetHeader="Selected Aircraft Interiors" [showSourceControls]="false" [showTargetControls]="false"
          (onMoveToTarget)="onInteriorChange()" (onMoveToSource)="onInteriorChange()"
          (onMoveAllToSource)="onInteriorChange()" (onMoveAllToTarget)="onInteriorChange()">
          <ng-template let-aci pTemplate="item">
            <ng-container *ngIf="toAircraftInterior(aci); let aci">
              <div>
                <div>{{aci.deck}}</div>
              </div>
            </ng-container>
          </ng-template>
        </p-pickList>
      </div>
    </div>
    <div class="flex justify-content-end">
      <span class="p-input-icon-left">
        <button (click)="onCancel()" class="p-button-text" icon="pi pi-times" id="cancelButton" label="Cancel" pButton
          pRipple type="button"></button>
      </span>
      <span class="p-input-icon-left">
        <button *ngIf="!isReadOnly" (click)="onReset()" class="p-button-text" icon="pi pi-undo" id="resetButton"
          label="Reset" [disabled]="!(aircraftForm.dirty || interiorChange)" pButton pRipple type="button"></button>
      </span>

      <span class="p-input-icon-left pl-2">
        <button *ngIf="!isReadOnly"
          [disabled]="isNew? !aircraftForm.valid : !((aircraftForm.dirty || interiorChange) && aircraftForm.valid)"
          icon="pi pi-check" id="okButton" label="Save" pButton pRipple type="submit"></button>
      </span>
    </div>
  </form>
</div>
