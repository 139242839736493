<p-blockUI [blocked]="loadingCount > 0">
    <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<div class="col-12 card p-3">
    <div class="card-header m-2">
        <h5>{{pageTitle}}</h5>
    </div>
    
    <div class="grid mt-2">
        <div class="col-12 lg:col-12 p-2" >
            <div class="card mb-0">
                
                <!-- <div class="grid">
                   <div class="col-2 ">
                    
                        <div class="p-float-label">
                            <p-dropdown [options]="versionOptions1" [(ngModel)]="selectedV1" optionLabel="v"  optionValue="aid" (onChange)="onChange($event)"
                            ></p-dropdown>
                            
                            <label for="version1"> Version 1</label>
                        </div>
                    
                    </div>
                    <div class="col-2" >
                        <div class="p-float-label">
                        
                            <p-dropdown [options]="versionOptions2"  [(ngModel)]="selectedV2" optionLabel="v" optionValue="aid"></p-dropdown>
                            <label for="version2"> Version 2</label>
                        </div>
                    
                    </div>
                  
                   <div class="col-2">
                    <div class="flex align-content-center  " >
                       
                        <p-button label="Compare" icon="pi pi-tags" styleClass="p-button-lg" (click)="onClick()"></p-button>
                    </div>
                   </div>
                </div> -->
                <div class="flex justify-content-end">
                  
                   
                    
                 </div>

                 <div class="col-12"> 
                    <div class="grid">
                        <div class="col-3 p-0">
                            <div class="col-12 p-0">
                                <span  class="p-input-icon-left w-full"  >
                                    <div class="p-float-label">
                                        <p-dropdown [options]="versionOptions1" [(ngModel)]="selectedV1"  styleClass="w-full" optionLabel="v"  optionValue="aid" (onChange)="onChange($event)"
                                        ></p-dropdown>
                                        
                                        <label for="version1"> Version 1</label>
                                    </div>
                                    
                                    
                                </span> </div>
                                <div class="col-12 p-0 mt-3"> 
                                    <span  class="p-input-icon-left w-full"  >
                                        <div class="p-float-label">
                                        
                                            <p-dropdown [options]="versionOptions2"  [(ngModel)]="selectedV2"  styleClass="w-full" optionLabel="v" optionValue="aid" (onChange)="onChangeVersion2($event)" ></p-dropdown>
                                            <label for="version2"> Version 2</label>
                                        </div>
                                    </span>  
                                </div>
                                <div class="col-12 p-0 pt-3"> <span class="p-input-icon-left ">
                                    <p-button label="Compare" icon="pi pi-tags"   (click)="onClick()"></p-button>
                                </span></div>
                   
                          
                        
                
                        
                    </div>
                    <div class="col-9 p-0 pl-3"> <div class="custom-panel "  compare-result [modelName]="model" [result] = "comparisonResult" style="width:100%"></div>  </div> </div>


                 </div>
                    <!-- <div class="col-12">
                        <textarea [rows]="10" [(ngModel)]="comparisonResult"  pInputTextarea  readonly  style="resize:none;width:100%"></textarea>
                    </div> -->
                    <!-- <div compare-result [modelName]="model" [result] = "comparisonResult"></div> -->
                    
         
                
                    
            
            </div>
        </div>
       
    </div>
   
</div>