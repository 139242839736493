
<div class="card">
  <p-table id="dt" [filterDelay]="0" [responsive]="true" sortField="name" #dt [value]="endpoints" [globalFilterFields]="[
    'name','transfertype_name','description','isenabled','jsonparameters','organization_type_name',
  ]"
  [showCurrentPageReport]="true" [paginator]="endpoints.length > 20" [rowsPerPageOptions]="[20,50,100,200]" [rows]="20" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Endpoints"
  dataKey="dt" [tableStyle]="{ width: '100%' }" (onRowSelect)="onSelect($event.data)" selectionMode="single">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of cols" [pSortableColumn]="col.field">
          <div class="flex justify-content-start align-items-center" *ngIf="col.header != 'Action'">
            {{ col.header }}
            <p-sortIcon [field]="col.field"></p-sortIcon>
            <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu"
              field="{{ col.field }}" type="text" matchMode="contains"></p-columnFilter>
          </div>
          <div *ngIf="col.header == 'Action'">
          </div>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-endpoint>
      <tr [pSelectableRow]="endpoint" class="p-selectable-row">
        <td>{{endpoint.name}}</td>
        <td>{{endpoint.transfertype_name}}</td>
        <td>{{endpoint.description}}</td>
        <td>{{endpoint.isenabled}}</td>
        <td>{{findDate(endpoint.jsonparameters)}}</td>
        <td>{{endpoint.organization_type_name}}</td>
        <td>
          <div class="flex align-items-center justify-contents-end">
            <button id="deleteButton" name="deleteButton" type="button" *ngIf="canDelete && !readOnly"
              class="p-button-rounded p-button-text p-button-danger" pButton pRipple icon="pi pi-trash"
              pTooltip="Delete..."  (click)="onDelete(endpoint)"></button>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="7">No Endpoints Found.</td>
      </tr>
    </ng-template>
  </p-table>
  <div *ngIf="endpoints.length > 0 && 20 >= endpoints.length" style="text-align: center;">
    <p class="row-paging">Showing 1 to {{endpoints.length}} of {{endpoints.length}} Endpoints</p>
  </div>
</div>
