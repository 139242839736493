import { Component, ViewChild, OnInit, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Router, ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from '../../../../common/services/breadcrumb.service';
import {
  Action,
  BaseViewComponent,
} from 'src/app/common/components/base-view.component';
import { forkJoin, takeUntil } from 'rxjs';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import {
  DataDeliveryAlerts as AlertsModel,
  Datadelivery_alert_type as AlertTypeModel,
  ResponseDDSAlerts as ResponseDDSAlertsModel,
  ClientAuthentication,
  AlertTypes,
} from 'src/app/features/datadelivery/models/DatadeliveryAlerts';
import { permissions } from 'src/app/features/datadelivery/integration/datadelivery.permissions';
import { ClientApplicationService } from 'src/app/features/datadelivery/services/clientApplication.service';

@Component({
  selector: 'app-common-alert-details-component',
  templateUrl: './alert-details.component.html',
  styleUrls: ['./alert-details.component.css'],
})
export class CommonAlertDetailsComponent
  extends BaseViewComponent
  implements OnInit
{
  @ViewChild('DataDeliveryAlertForm') DataDeliveryAlertForm!: NgForm;
  public canAdd: boolean;
  public canDelete: boolean;
  public canEdit: boolean;
  public modalDialog?: boolean;
  public submitted: boolean = false;
  isDataFrame: boolean = false;
  uniqueError: boolean = false;
  showButtons: boolean = true;
  today = new Date();
  todayAsStr = `${this.today.getFullYear()}-${this.today.getMonth()}-${this.today.getDate()}`;
  cols: any[] = [];
  ddsalerts: AlertsModel[] = [];
  ddsalert!: AlertsModel;
  isButtonDisabled: boolean = true;
  initialRecordersCount: number;
  saveButtonDisabled: boolean;
  isReadonly: boolean = true;
  responsealerts: ResponseDDSAlertsModel;
  alerttype: AlertTypeModel[] = [];
  min_timelookback: number;
  max_timelookback: number;
  lblTime: string;
  selectedClientAuthIDs: number[] = [];
  picklistChange: boolean = false;
  pickClientAuthlistChange = false;
  isClientAuthButtonDisabled: boolean = true;
  availableClient: ClientAuthentication[] = [];
  selectedClient: ClientAuthentication[] = [];
  initialClientCount: number;

  @Input() permissions: any;
  @Input() pageTitle: string;
  @Input() alertsService: any
  @Input() clientApplicationService: any
  @Input() tableViewLabel: string
  @Input() tableViewPath: string
  @Input() detailsViewPath: string

  intialVal() {
    this.updateBreadcrumb(`New ${this.pageTitle}`);
    this.ddsalert = new AlertsModel();
    this.ddsalert.alert_id = -1;
    this.isNew = true;
  }

  constructor(
    router: Router,
    private route: ActivatedRoute,
    breadcrumbService: BreadcrumbService,
    confirmationService: ConfirmationService,
    messageService: MessageService,
    private realUserService: SecurityUserService
  ) {
    super(messageService, confirmationService, router, breadcrumbService);
    this.getBreadCrums();
  }
  getBreadCrums() {
    this.breadcrumbService.setItems(this.route, [
      {
        label: this.tableViewLabel,
        routerLink: this.tableViewPath,
      },
      {
        label:
          this.pageTitle +
          ` (${this.route.snapshot.queryParamMap.get('name')})`,
      },
    ]);
  }
  ngOnInit(): void {
    this.getBreadCrums();
    this.canAdd = this.realUserService.userHasPermission(
      permissions.recording_alert.create
    );
    this.canDelete = this.realUserService.userHasPermission(
      permissions.recording_alert.delete
    );
    this.canEdit = this.realUserService.userHasPermission(
      permissions.recording_alert.manage
    );
    const alertId = +this.route.snapshot.queryParamMap.get('alertId');
    if (alertId) {
      this.isNew = false;
      this.loadddsAlertDetails(alertId);
      !this.canEdit ? (this.isReadonly = true) : (this.isReadonly = false);
      !this.canEdit ? (this.showButtons = false) : (this.showButtons = true);
    } else {
      !this.canAdd ? (this.isReadonly = true) : (this.isReadonly = false);
      !this.canAdd ? (this.showButtons = false) : (this.showButtons = true);
      this.intialVal();
      this.loadAlertDetails();
    }
  }

  toclientauths(clientauth: ClientAuthentication): ClientAuthentication {
    return clientauth;
  }

  loadddsAlertDetails(alertId: number) {
    this.loadingCount++;
    forkJoin({
      alertType: this.alertsService.getAlertType(),
      getalertdetails: this.alertsService.getAlerts_by_id(alertId),
      avaliableClientAuth: this.clientApplicationService.getClientApplications(),
    })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results) => {
          this.alerttype = results.alertType as any;
          this.ddsalert = results.getalertdetails as any;
          this.ddsalert.enable_flag =
            this.ddsalert.enable_flag == true ? true : false;
          this.availableClient = [];
          this.setTime(this.ddsalert.alert_type_id);
          this.ddsalert.clientauths.forEach((ele) => {
            this.selectedClient.push({
              client_auth_id: ele.client_auth_id,
              clientname: ele.clientname,
            });
          });
          (results.avaliableClientAuth as any).forEach((ele) => {
            this.availableClient.push({
              client_auth_id: ele.id,
              clientname: ele.Name,
            });
          });
          this.selectedClient.forEach((ele) => {
            this.selectedClientAuthIDs.push(ele.client_auth_id);
          });
          if (this.availableClient && this.selectedClient) {
            this.availableClient = this.availableClient.filter(
              (p) =>
                this.selectedClient.findIndex(
                  (i) => i.client_auth_id == p.client_auth_id
                ) == -1
            );
          }
          this.initialClientCount = this.selectedClient.length;
          this.availableClient.sort((a, b) => {
            return a.clientname.localeCompare(b.clientname);
          });
          this.alerttype.sort((a, b) => {
            return a.alert_type.localeCompare(b.alert_type);
          });
        },
        error: (error) => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.pageTitle}s`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        },
      });
  }

  loadAlertDetails() {
    this.loadingCount++;
    this.ddsalert.clientauths = [];
    forkJoin({
      alertType: this.alertsService.getAlertType(),
      clientAuth: this.clientApplicationService.getClientApplications(),
    })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results) => {
          this.availableClient = [];
          //sorting to show in alphabetical order
          this.alerttype = results.alertType as any;
          (results.clientAuth as any).forEach((ele) => {
            this.availableClient.push({
              client_auth_id: ele.id,
              clientname: ele.Name,
            });
          });
          this.availableClient.sort((a, b) => {
            return a.clientname.localeCompare(b.clientname);
          });
          this.alerttype.sort((a, b) => {
            return a.alert_type.localeCompare(b.alert_type);
          });
        },
        error: (error) => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.pageTitle}s`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        },
      });
  }

  onAlertTypeChange(event: any) {
    this.setTime(event.value);
  }

  setTime(param: number) {
    if (param === AlertTypes.ProcessingFailures) {
      this.lblTime = 'Minute(s)';
      this.min_timelookback = 0.99; // a validation error would trigger if you used 1 minute here, so we pick a lower number
      this.max_timelookback = 1440;
    } else {
      this.lblTime = 'Hour(s)';
      this.min_timelookback = 1;
      this.max_timelookback = 168;
    }
  }

  onCancel() {
    if (this.DataDeliveryAlertForm.dirty) {
      this.router.navigate([this.tableViewPath]);
    } else {
      this.router.navigate([this.tableViewPath]);
    }
  }

  onReset() {
    this.DataDeliveryAlertForm.resetForm();
    this.availableClient = [];
    this.selectedClientAuthIDs = [];
    this.ngOnInit();
    this.isButtonDisabled = true;
  }

  onMoveToTarget(event) {
    event.items.forEach((ele) => {
      this.selectedClientAuthIDs.push(ele.client_auth_id);
    });
    this.isButtonDisabled = false;
    this.selectedClientAuthIDs = [...new Set(this.selectedClientAuthIDs)]; // remove the duplicate entries
    this.picklistChange = true;
  }

  onPicklistChange(event) {
    this.picklistChange = true;
    this.isButtonDisabled = false;
  }

  onMoveAllToSource(event) {
    event.items.forEach((ele) => {
      const index = this.selectedClientAuthIDs.indexOf(ele.client_auth_id);
      if (index > -1) {
        this.selectedClientAuthIDs.splice(index, 1);
      }
    });
    this.picklistChange = true;
    this.isButtonDisabled = false;
  }

  onMoveAllToTarget(event) {
    this.picklistChange = true;
    this.isButtonDisabled = false;
    event.items.forEach((ele) => {
      this.selectedClientAuthIDs.push(ele.client_auth_id);
    });
  }

  onMoveToSource(event) {
    this.picklistChange = true;
    this.isButtonDisabled = false;
    event.items.forEach((ele) => {
      const index = this.selectedClientAuthIDs.indexOf(ele.client_auth_id);
      if (index > -1) {
        this.selectedClientAuthIDs.splice(index, 1);
      }
    });
  }

  OnSubmit() {
    this.loadingCount++;
    this.submitted = true;
    this.responsealerts = {
      alert_id: this.ddsalert.alert_id,
      alert_type_id: this.ddsalert.alert_type_id,
      name: this.ddsalert.name,
      enable_flag:
        this.ddsalert.enable_flag == undefined
          ? false
          : this.ddsalert.enable_flag,
      organization_id: 0,
      rate: this.ddsalert.rate,
      time_to_look_back: this.ddsalert.time_to_look_back,
      client_auth_id: this.selectedClientAuthIDs,
    };
    if (this.isNew) {
      this.alertsService
        .addRecordingAlert(this.responsealerts)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (result) => {
            this.ddsalert.alert_id = result;
            // Update query param
            this.router
              .navigate([], {
                relativeTo: this.route,
                queryParams: {
                  redactionId: this.ddsalert.alert_id,
                  name: this.ddsalert.name,
                },
                queryParamsHandling: 'merge',
              })
              .then();
            this.updateBreadcrumb(
              this.pageTitle +
                ' (' +
                this.ddsalert.name +
                ')'
            );
            this.picklistChange = false;
          },
          error: (err) => {
            this.showErrorMsg(`${err}`, Action.Add, `${this.pageTitle}s`);
            this.submitted = true;
            this.loadingCount--;
            this.DataDeliveryAlertForm.resetForm(
              this.DataDeliveryAlertForm.value
            );
          },
          complete: () => {
            this.showSuccessMsg(
              Action.Add,
              `${this.pageTitle}`,
              `${this.ddsalert.name}`
            );
            this.isNew = false;
            this.DataDeliveryAlertForm.resetForm(
              this.DataDeliveryAlertForm.value
            );
            this.loadingCount--;
          },
        });
    } else {
      this.alertsService
        .updateRecordingAlert(this.responsealerts)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.updateBreadcrumb(
              this.pageTitle +
                ' (' +
                this.ddsalert.name +
                ')'
            );
            this.picklistChange = false;
          },
          error: (err) => {
            this.showErrorMsg(`${err}`, Action.Update, `${this.pageTitle}s`);
            this.loadingCount--;
          },
          complete: () => {
            this.showSuccessMsg(
              Action.Update,
              `${this.pageTitle}`,
              `${this.ddsalert.name}`
            );
            this.loadingCount--;
          },
        });
    }
  }

  shouldDisableSaveButton() {
    const shouldDisableForNew =
      !this.DataDeliveryAlertForm?.valid ||
      (this?.ddsalert.enable_flag && this?.selectedClientAuthIDs.length < 1);
    const shouldDisableForExisting =
      this.isButtonDisabled &&
      (!this.DataDeliveryAlertForm?.dirty ||
        !this.DataDeliveryAlertForm?.valid);
    return this.isNew ? shouldDisableForNew : shouldDisableForExisting;
  }
}
