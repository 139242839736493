import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { BaseService } from "../../../common/services/base.service";
import { catchError } from "rxjs";
import { Waypoint, WaypointDetails } from "../models/waypoint";

@Injectable({
    providedIn: 'root'
  })
  export class TerminalWaypointService extends BaseService {

    constructor(private http:HttpClient){
        super();
        this.baseUrl = environment.apiUrl.admin;
      }

      public getTerminalWaypoints(dte, icao){
         const url = `${this.baseUrl}terminal-waypoint/${dte}/${icao}`
         return this.http.get<Waypoint[]>(url).pipe(catchError(this.httpHandleError))
      }

      public getTerminalWaypoint(dte, icao, waypoint_id){
         const url = `${this.baseUrl}terminal-waypoint/${dte}/${icao}/${waypoint_id}`
         return this.http.get<WaypointDetails[]>(url).pipe(catchError(this.httpHandleError))
      }
  }
