<p-table
  id="acms"
  #acms
  [value]="acmsreports"
  sortField="name"
  (onRowSelect)="onRowSelect($event)"
  selectionMode="single"
  [showCurrentPageReport]="true"
  [paginator]="acmsreports.length > defaultTableRows"
  [rowsPerPageOptions]="[20, 50, 100, 200]"
  [rows]="defaultTableRows"
  currentPageReportTemplate="{{ currentPageReport + ' ' + pageTitle }}"
  [globalFilterFields]="[
    'name',
    'description',
    'acmsreportname',
    'defaultparametervaluesconfig'
  ]"
  dataKey="dataredaction"
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of cols" [pSortableColumn]="col.field">
        <div
          class="flex justify-content-start align-items-center"
          *ngIf="col.header != 'Action'"
        >
          {{ col.header }}
          <p-sortIcon [field]="col.field"></p-sortIcon>
          <p-columnFilter
            [showAddButton]="false"
            [showOperator]="false"
            class="ml-0"
            display="menu"
            [field]="col.field"
            type="text"
            matchMode="contains"
          ></p-columnFilter>
        </div>
        <div *ngIf="col.header == 'Action'"></div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-acms>
    <tr [pSelectableRow]="acms" class="p-selectable-row">
      <td>{{ acms.name }}</td>
      <td>{{ acms.description }}</td>
      <td>{{ acms.acmsreportname }}</td>
      <td>{{ acms.defaultparametervaluesconfig }}</td>
      <td>
        <div class="flex align-items-center justify-contents-end">
          <button
            id="cloneButton"
            name="cloneButton"
            type="button"
            class="p-button-rounded p-button-text mr-2"
            pButton
            icon="pi pi-clone"
            pRipple
            pTooltip="Clone..."
            *ngIf="canAdd"
            (click)="cloneAcms(acms)"
          ></button>
        </div>
      </td>
      <td>
        <div class="flex align-items-center justify-contents-end">
          <button
            id="deleteButton"
            name="deleteButton"
            type="button"
            class="p-button-rounded p-button-text p-button-danger"
            pButton
            pRipple
            icon="pi pi-trash"
            pTooltip="Delete..."
            *ngIf="canDelete"
            (click)="deleteACMS(acms)"
          ></button>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="7">No ACMS Report Redactions Found.</td>
    </tr>
  </ng-template>
</p-table>
<div *ngIf="acmsreports.length > 0" style="text-align: center">
  <p class="row-paging">
    Showing 1 to {{ acmsreports.length }} of {{ acmsreports.length }}
    {{ pageTitle }}
  </p>
</div>
