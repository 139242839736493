<p-blockUI [blocked]="loadingCount > 0">
  <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<div class="col-12 card p-3">
  <div class="card-header m-2">
    <h5>{{pageTitle}}</h5>
  </div>
  <div class="warningmsg" *ngIf="showMessage">
    <p-messages [(value)]="messages" [enableService]="false" [closable]="true"></p-messages>
  </div>
  <div *ngIf="flightdataredaction" class="col-12 card">
    <form #dataredactionForm="ngForm" (ngSubmit)="onSubmit()" novalidate>
      <div class="formgrid grid">
        <div class="field col-12 md:col-6 lg:col-3">
          <span class="p-float-label">
            <input [disabled]="isReadonly" type="text" [style]="{ width: '100%' }" pattern=".*\S.*" pInputText id="name" name="name"
              [(ngModel)]="flightdataredaction.name" #name="ngModel" required maxlength="50" />
            <label for="name">Name</label>
            <div *ngIf="uniqueError">
              <small class="p-invalid p-error">Name Already Exists</small>
            </div>
            <div class="tdy-required" *ngIf="(name.touched || name.dirty) && name.hasError('required')">
              Name is required.
            </div>
            <div class="tdy-required" *ngIf="name.hasError('pattern')">
              Name cannot be left blank.
            </div>
          </span>
        </div>

        <div class="field col-12 md:col-6 lg:col-3">
          <span class="p-float-label">
            <input [disabled]="isReadonly" type="text" id="description" pInputText [style]="{ width: '100%' }" name="description"
              [(ngModel)]="flightdataredaction.description" />
            <label for="description">Description</label>
          </span>
        </div>

        <div class="field col-12 md:col-6 lg:col-3">
          <span class="p-float-label">
            <p-dropdown [disabled]="isReadonly" #redactiontypeId="ngModel" [style]="{ width: '100%' }" optionLabel="name" id="redactionTypeId"
              optionValue="redactiontype_id" (onChange)="onChangeRedaction($event)" [options]="redactionType"
              [autoDisplayFirst]="false" [(ngModel)]="flightdataredaction.redactiontype_id" [required]=true
              id="redactionType" name="redactionTypeId" appendTo="body">
            </p-dropdown>
            <label for="conversiontype">Conversion Type</label>
            <div class="tdy-required"
              *ngIf="(redactiontypeId.touched || redactiontypeId.dirty) && redactiontypeId.hasError('required')">
              Conversion Type is required.
            </div>
          </span>
        </div>
        <div class="field col-12 md:col-6 lg:col-3">
          <span class="p-float-label">
            <p-dropdown [disabled]="isReadonly" autoWidth="false" [style]="{ width: '100%' }" optionLabel="dataframe_type"
              optionValue="dataframe_id" [autoDisplayFirst]="false" [options]="this.dataframe" #frame="ngModel"
              id="frame" name="frame" [(ngModel)]="flightdataredaction.dataframe_id" [required]=true
              (onChange)="onDataFramChange($event)" [required]="true"></p-dropdown>
            <label for="dataframe">Data Frame</label>
            <div class="tdy-required" *ngIf="(frame.touched || frame.dirty) && frame.hasError('required')">
              Data Frame is required.
            </div>
          </span>
        </div>
        <div class="p-2 field col-12 md:col-6 lg:col-3">
          <span>
          <p-checkbox  [disabled]="isReadonlyredaction || isReadonly" [(ngModel)]="flightdataredaction.textualoutput" id="txtopt"
            name="txt-opt" [binary]="true" inputId="binary"></p-checkbox>
          <label for="txtopt" class="p-2">Textual Output</label>
        </span>
        </div>
        <div class="col-12 file">

          <p-fileUpload [disabled]=" !fileButtonEnabled || isReadonly || !empty" #ContentUpload
            accept="{{acceptedFiles}}" [(ngModel)]="uploadfilepath.filename" name="filepath" ngDefaultControl
            #filepath="ngModel" mode="advanced" chooseLabel="File" mode="advanced" [auto]="true"
            (onRemove)="onFileRemove()" [showCancelButton]=false [maxFileSize]="maxFileSize" [fileLimit]="1"
            (onSelect)="onUpload($event)" class="p-button-rounded p-button-text p-button-danger" id="filepath" url="">
            <ng-template pTemplate="content">
              <div *ngIf="isFileValid">
                <span>
                  No file uploaded.
                </span>
              </div>
            </ng-template>
          </p-fileUpload>
        </div>
      </div>
      <div class="mt-5">
        <h6>Parameter Selection</h6>
        <div class="field">
          <p-pickList [source]="availableDataFramelist" [target]="availableDataFrame" id="dataframe_type"
            sourceHeader="Available Parameter(s)" targetHeader="Selected Parameter(s)" [showSourceControls]="false"
            [showTargetControls]="false" [responsive]="true" [sourceStyle]="{'height':'200px'}"
            (onMoveToTarget)="onMoveToTarget($event)" (onMoveToSource)="onMoveToSource($event)"
            (onMoveAllToSource)="onMoveAllToSource($event)" (onMoveAllToTarget)="onMoveAllToTarget($event)"
            [disabled]="isReadonly" [targetStyle]="{'height':'200px'}">
            <ng-template let-dataframe pTemplate="item">
              <ng-container>
                <div>
                  <div>{{dataframe.dataframe_type}}</div>
                </div>
              </ng-container>
            </ng-template>
          </p-pickList>
          <div class="tdy-required"
            *ngIf="(isNew? availableDataFrame.length < 1 : availableDataFrame.length < 1 && availableDataFrame.length != null  )">
            At least 1 Parameter is required.
          </div>
        </div>
      </div>
      <div class="flex justify-content-end">
        <span class="p-input-icon-left">
          <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" type="button"
            (click)="onCancel()"></button>
        </span>
        <span class="p-input-icon-left p-button-text" style="float: right">
          <button pButton pRipple label="Reset" icon="pi pi-refresh" type="button"
            [disabled]="!dataredactionForm.dirty && isButtonDisabled" class="p-button-text"
            (click)="onReset()"></button>
        </span>
        <span class="p-input-icon-left ml-3" style="float: right">
          <button pButton pRipple label="Save" type="submit" icon="pi pi-check" 
            [disabled]="(isNew? (!dataredactionForm.valid || selectedDataframesIDs.length < 1 || !isValid) : !((dataredactionForm.dirty || picklistChange) && dataredactionForm.valid) || selectedDataframesIDs.length < 1 || !isValid  )"></button>
        </span>
      </div>
    </form>
  </div>
</div>