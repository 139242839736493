import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { Action, BaseViewComponent } from 'src/app/common/components/base-view.component';
import { Location } from '@angular/common';
import { Organization } from 'src/app/features/administration/models/organization';
import { CompanyService } from 'src/app/features/administration/services/company.service';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { permissions } from 'src/app/security/models/permissions';
import { Components } from '../../integration/administration.components';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html'
})
export class CompanyComponent extends BaseViewComponent implements OnInit {
  pageTitle = Components.Company.label;
  companies: Organization[];
  // User permissions
  public canAdd: boolean;
  public canDelete: boolean;
  public canView: boolean;
  messageLabel = 'company';

  constructor(
    messageService: MessageService,
    router: Router,
    confirmationService: ConfirmationService,
    breadcrumbService: BreadcrumbService,
    private companyService: CompanyService,
    private securityUserService: SecurityUserService,
    private route: ActivatedRoute, private location: Location) {
    super(messageService, confirmationService, router, breadcrumbService);
    // Note: Service won't update breadcrumb if caller already did so.
    this.breadcrumbService.setItems(this.route, [
      { label: Components.Company.label }  // No routerLink value for current page
    ]);
  }

  ngOnInit(): void {
    this.canView = this.securityUserService.userHasPermission(permissions.admin.companies.view);
    this.canAdd = this.securityUserService.userHasPermission(permissions.admin.companies.create);

    // Note: Service won't update breadcrumb if caller already did so.
    this.breadcrumbService.setItems(this.route, [
      { label: Components.Company.label }  // No routerLink value for current page
    ]);

    if (this.canView) {
      this.loadCompanies();
    }
  }

  loadCompanies() {
    this.loadingCount++;
    this.companyService.listCompanies().subscribe({
      next: companies => {
        this.companies = companies;
      },
      error: err => {
        this.showErrorMsg(err, Action.Get, `${this.messageLabel}`);
        this.loadingCount--;
      },
      complete: () => this.loadingCount--
    });
  }

  onRowSelect(event) {
    const company = event.data as Organization;

    const item = this.breadcrumbService.popItem();
    if (item) {
      this.breadcrumbService.pushItemsBeforeNavigate([
        {
          label: item.label,
          routerLink: Components.Company.path
        },
        { label: Components.CompanyDetails.label }
      ]);
    }
    this.router.navigate([Components.CompanyDetails.path], {
      queryParams: {
        c: company?.id,
        name: event.data.name
        // c = company id
      }
    }).then();
  }

  onClickAdd() {
    if (!this.canAdd) {
      this.showErrorMsg('User is not authorized to add a company.', Action.Add, `${this.messageLabel}`);
    } else {
      this.navigateToDetailsPage();
    }
  }

  toCompany(company): Organization {
    return company;
  }

  navigateToDetailsPage(companyID = -1) {
    // Set breadcrumb BEFORE navigating to next page - pop top item and add link back to this page
    // and add the item back along with new item for the next page.
    // Necessary here because breadcrumb trail is different for TDY (who can view this page) and company
    // who can only see the company-details page. HOME/company/company-details vs HOME/company-details
    const item = this.breadcrumbService.popItem();
    if (item) {
      this.breadcrumbService.pushItemsBeforeNavigate([
        {
          label: item.label,
          routerLink: Components.Company.path
        },
        { label: Components.CompanyDetails.label }  // No routerLink value for current page
      ]);
    }

    if (companyID > 0) {
      this.router.navigate([Components.CompanyDetails.path], {
        queryParams: {
          a: companyID
        }
      }).then();
    } else {
      this.router.navigate([Components.CompanyDetails.path]).then();
    }
  }

}
