<ng-container *ngIf="control">
    <div class="tdy-required" *ngIf="( control.dirty || control.touched || !control.value  ) && control.hasError('required')">
      {{inputConf.label}} is required.
    </div>
    <div class="tdy-required" *ngIf="control.hasError('pattern')">
      {{inputConf.label}} {{inputConf.patternError}}
    </div>
    <div class="tdy-required" *ngIf="control.hasError('invalidString')">
      {{inputConf.label}} {{ control.errors['invalidString']?.message }}
    </div>
    <div class="tdy-required"*ngIf="control.hasError('invalidInteger')">
      {{inputConf.label}} {{ control.errors['invalidInteger']?.message }}
    </div>
    <div class="tdy-required" *ngIf="control.hasError('invalidNumber')">
      {{inputConf.label}} {{ control.errors['invalidNumber']?.message }}
    </div>
    <div class="tdy-required" *ngIf="control.hasError('min')">
      {{inputConf.label}} should be greater than or equal to {{control.errors['min']?.min}}.
    </div>
    <div class="tdy-required" *ngIf="control.hasError('max')">
      {{inputConf.label}} should be less than or equal to {{control.errors['max']?.max}}.
    </div>
    <div class="tdy-required" *ngIf="control.hasError('sumLte')">
      {{control.errors['sumLte']?.message}}
    </div>
  </ng-container>