import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { Waypoint } from 'src/app/features/administration/models/waypoint';
import { TerminalWaypointService } from 'src/app/features/administration/services/terminalwaypont.service';
import { ApiType, NavigationInformationBaseViewComponent } from '../navigation-information-base/navigation-information-base-view.component';
import { Components } from 'src/app/features/administration/integration/administration.components';


@Component({
  selector: 'app-terminal-waypoints-list',
  templateUrl: './terminal-waypoints.component.html'
})

export class TerminalWaypointsComponent extends NavigationInformationBaseViewComponent implements OnInit {
  pageTitle = Components.TerminalWaypointList.label;
  file_dates: any;
  selected_date: any;
  icao: string;
  display_icao_iata: string;
  terminalWaypoints: Waypoint[];
  selected_terminalwaypoints: Waypoint;

  constructor(
    private terminalwaypointService: TerminalWaypointService,
    messageService: MessageService,
    confirmationService: ConfirmationService,
    router: Router,
    route: ActivatedRoute,
    breadcrumbService: BreadcrumbService) {
    super(messageService, confirmationService, router, breadcrumbService, route)
    this.icao = route.snapshot.queryParamMap.get('a');
    this.display_icao_iata = route.snapshot.queryParamMap.get('b');
    const dte = route.snapshot.queryParamMap.get('c');
    const file_dates = route.snapshot.queryParamMap.get('d');

    this.selected_date = { "dte": dte };
    this.file_dates = JSON.parse(file_dates);
    this.getTerminalWaypoints(dte, this.icao);
    this.setBreadcrumb(this.display_icao_iata);
  }

  ngOnInit(): void {
    this.setBreadcrumb(this.display_icao_iata);
  }

  getTerminalWaypoints(dte, icao) {
    const arg: ApiType = { request: this.terminalwaypointService.getTerminalWaypoints(dte, icao), api_name: 'terminal waypoints', callback: this.callback }
    this.callAPI(arg)
  }

  callback = (data) => {
    this.data = data
  }

  override setBreadcrumb(airport) {
    super.setBreadcrumb(
      [
        {
          label: Components.AirportList.label,
          routerLink: Components.AirportList.path,
          queryParams: { a: this.selected_date["dte"] }
        },
        {
          label: Components.TerminalWaypointList.label + ` (${airport})`
        }
      ]);
  }

  onRowSelect($event) {
    const waypoint_id = this.selected_terminalwaypoints["waypoint_identifier"];
    this.router.navigate([Components.TerminalWaypointDetails.path],
      {
        queryParams: {
          a: this.icao,
          b: this.display_icao_iata,
          c: this.selected_date["dte"],
          d: JSON.stringify(this.file_dates),
          e: waypoint_id
        }
      }).then()
  }

  override onChange($event) {
    super.onChange([Components.TerminalWaypointList.path],
      {
        queryParams: {
          a: this.icao,
          b: this.display_icao_iata,
          c: this.selected_date["dte"],
          d: JSON.stringify(this.file_dates)
        }
      });
  }
}
