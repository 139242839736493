import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/common/services/breadcrumb.service";
import { AcmsReportMapModel } from "src/app/features/configuration/models/acmsReportMap";
import { AcmsReportMapService } from "src/app/features/configuration/services/acms-report-map.service";
import { permissions } from "../../../../integration/configuration.permissions";
import { SecurityUserService } from "src/app/security/services/security-user.service";
import { BaseListComponent } from "../../base/base-list-component";
import { acmsReportMapListInput } from "../../input-model/acms-report-map-input";
import { CommonService } from "src/app/common/services/common.service";
import { Components } from '../../../../integration/configuration.components';

@Component({
    selector: 'app-acms-report-map-versions',
    templateUrl: '../../base/list-versions.component.html',
    providers: [{provide: acmsReportMapListInput  ,
        useFactory:(acmsReportMapService: AcmsReportMapService, commonService: CommonService) => new acmsReportMapListInput(acmsReportMapService, commonService) 
       , 
       deps:[AcmsReportMapService, CommonService]
      }
    ]
  })
  export class AcmsReportMapVersionsComponent 
  extends BaseListComponent<AcmsReportMapModel>
  implements OnInit  {
  
   // pageTitle = MenuService.AcmsReportMapLabel;
    userCanDownload = false;  // no download in acms report
    constructor(
      
      messageService: MessageService,
      confirmationService: ConfirmationService,
      breadcrumbService: BreadcrumbService ,
      acmsReportInput: acmsReportMapListInput,
      router: Router,
      private  route: ActivatedRoute,
      private realUserService: SecurityUserService)
    {
        super(messageService,confirmationService,router,breadcrumbService,acmsReportInput);                         
        this.breadcrumbService.setItems(route,[{label: Components.AcmsReportMap.label, routerLink:Components.AcmsReportMap.path}])
        this.NoRecordMessage ="No versions for this acms report map ..."
      }

    ngOnInit(): void{
        
          this.breadcrumbService.setItems(this.route,[{label: Components.AcmsReportMap.label,routerLink:Components.AcmsReportMap.path}]);
          this.userCanAdd = this.realUserService.userHasPermission(permissions.acms_report.create);
          this.userCanModify = this.realUserService.userHasPermission(permissions.acms_report.manage);
          this.userCanDelete =this.realUserService.userHasPermission(permissions.acms_report.delete);

          if(this.route.snapshot.queryParamMap.get('a')){
            this.loadingCount ++;
          if(this.breadcrumbService.getItems().length<2)
          this.breadcrumbService.pushItems([{label:`Versions`}])
            this.getRecord(+this.route.snapshot.queryParamMap.get('a'));
        }



    
  }
}