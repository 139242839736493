import {
  FeatureItem,
  FeatureComponentsDefinition,
} from 'src/app/common/models/feature-item.model';
import { permissions } from './sendtoteledyne.permissions';
// import { FlightdataredactionComponent } from '../components/flightdataredaction/flightdataredaction.component';
// import { FlightDetaildataredactionComponent } from '../components/flightdataredaction-details/flightdataredaction-details.component';
//import { TeledyneComponent } from '../components/teledyne/teledyne.component';
//import { TeledyneDetailsComponent } from '../components/teledyne-details/teledyne-details.component';
//import { PackagesComponent } from '../components/teledyne-packages/teledyne-packages.component';
//import { TeledynePackagesDetailsComponent } from '../components/teledyne-packages-details/teledyne-packages-details.component';
//import { TeledyneendpointDetailsComponent } from '../components/teledyneendpoint-details/teledyneendpoint-details.component';
import { DataUploadComponent } from '../components/data-upload/data-upload.component';
import { NotificationsViewForSendToTeledyneComponent } from '../components/notifications-view/notifications-view.component';

const ROUTE_ROOT = 'sendtoteledyne';

export class Components extends FeatureComponentsDefinition {
  // stt

  /*
  public static readonly TeledyneAddEndPoint: FeatureItem = {
    label: 'Teledyne Endpoint Details',
    icon: 'send',
    path: `${ROUTE_ROOT}/teledynedetailsendpoints`,
    permission: permissions.endpoint.view,
    component: TeledyneendpointDetailsComponent
  }

  public static readonly FlightDataRedactions: FeatureItem = {
    label: 'Flight Data Redactions',
    path: `${ROUTE_ROOT}/dataredactions`,
    permission: permissions.sendtoteledyne_flight_redaction.view,
    icon: 'flight_takeoff',
    component: FlightdataredactionComponent
  }

  public static readonly AddFlightDataRedactions: FeatureItem = {
    label: 'Flight Data Redaction Details',
    path: `${ROUTE_ROOT}/adddataredactions`,
    permission: permissions.sendtoteledyne_flight_redaction.view,
    component: FlightDetaildataredactionComponent
  }


  public static readonly SendToTeledyne: FeatureItem = {
    label: 'Flight Data Redactions',
    path: `${ROUTE_ROOT}/sendtoteledyne`,
    permission: permissions.sendtoteledyne_flight_redaction.view,
    icon: 'analytics',
    component: TeledyneComponent
  }

  public static readonly AddSendToTeledyne: FeatureItem = {
    label: 'Flight Data Redaction Details',
    path: `${ROUTE_ROOT}/sendtoteledynedetails`,
    permission: permissions.sendtoteledyne_flight_redaction.view,
    component: TeledyneDetailsComponent
  }


  public static readonly TeledynePackages: FeatureItem = {
    label: 'Teledyne Packages',
    path: `${ROUTE_ROOT}/teledynepackages`,
    permission: permissions.sendtoteledyne_client_packages.view,
    icon: 'folder',
    component: PackagesComponent
  }
  public static readonly AddTeledynePackages: FeatureItem = {
    label: 'Teledyne Package Details',
    path: `${ROUTE_ROOT}/teledynepackagesdetails`,
    permission: permissions.sendtoteledyne_client_packages.view,
    component: TeledynePackagesDetailsComponent
  }
*/
  public static readonly DataUpload: FeatureItem = {
    label: 'Data Upload',
    path: `${ROUTE_ROOT}/data-upload`,
    icon: 'upload',
    permission: permissions.sendtoteledyne_data_upload.create,
    component: DataUploadComponent,
  };

  public static readonly NotificationsViewForSendToTeldyne: FeatureItem = {
    label: 'User Notifications',
    path: `${ROUTE_ROOT}/notifications`,
    permission: permissions.alert_notifications.view,
    component: NotificationsViewForSendToTeledyneComponent,
  };
}
