<p-blockUI [blocked]="loadingCount > 0">
  <tdy-spinner class="centered-spinner"></tdy-spinner>
</p-blockUI>
<div class="col-12 card p-3">
  <div class="card-header m-2">
    <h5>{{ pageTitle }}</h5>
  </div>
  <div *ngIf="acmsreport" class="col-12">
    <app-acmsreport-form
      [acmsreport]="acmsreport"
      [acmsReportlist]="acmsReportlist"
      [acmsType]="acmsType"
      [fileType]="fileType"
      [jsonregex]="jsonregex"
      [uniqueError]="uniqueError"
      [avialableAcms]="avialableAcms"
      [selectedMapIDs]="selectedMapIDs"
      [picklistChange]="picklistChange"
      [isButtonDisabled]="isButtonDisabled"
      [jsonParameters]="jsonParameters"
      [showButtons]="showButtons"
      [canAdd]="canAdd"
      [canDelete]="canDelete"
      [canEdit]="canEdit"
      [isNew]="isNew"
      [isReadonly]="isReadonly"
      [acmsreports]="acmsreports"
      [jsonParameters]="jsonParameters"
      (moveToTarget)="onMoveToTarget($event)"
      (moveToSource)="onMoveToSource($event)"
      (moveAllToSource)="onMoveAllToSource($event)"
      (moveAllToTarget)="onMoveAllToTarget($event)"
      (onAirlineChangeEvent)="onAirlineChange($event)"
      (onChangeEvent)="onChange($event)"
      (cancelForm)="onCancel()"
      (resetForm)="onReset()"
      (submitForm)="onSubmit()"
    ></app-acmsreport-form>
  </div>
</div>
