import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { Action, BaseViewComponent } from 'src/app/common/components/base-view.component';
import { takeUntil } from 'rxjs';
import { Location } from '@angular/common';
import { AirlinesModel } from 'src/app/features/configuration/models/sarMaps';
import { SARMapsService } from 'src/app/features/configuration/services/sar-map.service';
import { permissions } from '../../integration/configuration.permissions';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import {Components} from '../../integration/configuration.components';
import { CommonService } from 'src/app/common/services/common.service';
import { Organization } from 'src/app/features/administration/models/organization';

@Component({
  selector: 'app-sar-maps-details',
  templateUrl: './sar-maps-details.component.html'
})
export class SarMapsDetailsComponent extends BaseViewComponent implements OnInit {

  selectedAirlines: AirlinesModel[] = [];
  listOfAirlineIDs: number[] = [];  
  isSaveDisabled: boolean = true;
  pageTitle = Components.SarMapsDetails.label;
  sarMapID: number;
  availableAirlines: AirlinesModel[];
  public canEdit: boolean;
  public canAdd: boolean;
  public canDelete: boolean;
  messageLabel = 'sar maps';

  constructor(
    messageService: MessageService,
    confirmationService: ConfirmationService,    
    router: Router,
    breadcrumbService: BreadcrumbService,
    private route: ActivatedRoute,
    private location: Location,
    private sarMapsService: SARMapsService, 
    private realUserService: SecurityUserService,
    private commonService: CommonService
  ) {
    super(messageService, confirmationService, router, breadcrumbService);
    this.breadcrumbService.setItems(route, [
      { label: Components.SarMaps.label, routerLink: Components.SarMaps.path },
      { label: Components.SarMapsDetails.label  }, 
    ]);
  }

  ngOnInit(): void {
    this.updateBreadcrumb(Components.SarMapsDetails.label );
    this.sarMapID =  +this.route.snapshot.queryParamMap.get('sarMapID'); //converting string to number with '+'
    this.isNew = this.sarMapID == null?  true :  false;

      this.canEdit = this.realUserService.userHasPermission(permissions.sar_maps.manage);
      this.canAdd = this.realUserService.userHasPermission(permissions.sar_maps.create);    
      this.canDelete = this.realUserService.userHasPermission(permissions.sar_maps.delete);
      if (this.canAdd || this.canEdit) {
        this.getAirlineList(this.sarMapID);
      } else {     
          this.showErrorMsg('User is not authorized to modify the SAR Maps.', Action.Update, `${this.messageLabel}`);
          // Wait a few seconds for user to see the error, and send them back to where they came from.
          setTimeout(() => {
            this.location.back();
          }, 3000);
      }
    
  }


  getAirlineList(sarMapID){
    this.loadingCount++;
    this.commonService.listAirlinesFilter().pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (data) => {
        this.availableAirlines = data;
        this.loadSavedAirlines(sarMapID);
      },
      error: (err) => {
        this.showErrorMsg(err, Action.Get, `Airlines`);
        this.loadingCount--;        
      },
      complete: () => this.loadingCount--
    })
  }

  loadSavedAirlines(sarMapID: number) {
    this.loadingCount++;
    this.sarMapsService.getAirlinesBySARMaps(sarMapID).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next:(data) => {
        this.selectedAirlines = data['airlines'];
        
        if(this.availableAirlines && this.selectedAirlines){
          this.availableAirlines = this.availableAirlines.filter(p => this.selectedAirlines.findIndex(i => i.id == p.id) == -1);
          this.selectedAirlines = this.selectedAirlines.filter(p => p.id !== null);          
        }
        
        this.selectedAirlines.forEach(ele => {
          if(ele.id){
            this.listOfAirlineIDs.push(ele.id);
          }
        })

        this.selectedAirlines = this.selectedAirlines.sort((a,b)=>(a.name < b.name)? -1: 1);
        this.availableAirlines = this.availableAirlines.sort((a,b)=>(a.name < b.name)? -1: 1);

        this.updateBreadcrumb(`${Components.SarMapsDetails.label} (${data["name"]})`)
      },
      error: err => {
        this.showErrorMsg(err, Action.Get, `Airlines`);
        this.loadingCount --;
      },
      complete: () => {
        this.loadingCount --;
      }
    })
  }

  onMoveToTarget(event){
    this.isSaveDisabled = false;
    event.items.forEach(ele => {
      this.listOfAirlineIDs.push(ele.id);
    });    
    this.listOfAirlineIDs = [... new Set(this.listOfAirlineIDs)];
    this.selectedAirlines.sort((a,b)=>a.name.localeCompare(b.name))
  }

  onMoveToSource(event){
    event.items.forEach(ele => {
      const index = this.listOfAirlineIDs.indexOf(ele.id);
      if(index > -1) {
        this.listOfAirlineIDs.splice(index,1);
      }
    });
    this.listOfAirlineIDs = [... new Set(this.listOfAirlineIDs)];    
    this.listOfAirlineIDs.length < 1 ? this.isSaveDisabled = true : this.isSaveDisabled = false;
    this.availableAirlines.sort((a,b)=>a.name.localeCompare(b.name))
  }

  toAirlineList(airline:Organization) : Organization {
    return airline;
  }

  onCancel(){
    if(!this.isSaveDisabled) { 
      this.confirmationService.confirm({
          message: `Are you sure you want to cancel editing ${this.route.snapshot.queryParamMap.get('name')} ?`,
          header: 'Cancel?',
          rejectButtonStyleClass: 'p-button-text',
          accept: () => {
            this.router.navigate([Components.SarMaps.path]).then();
          }
        });
        }
        else{
          this.router.navigate([Components.SarMaps.path]).then();
        }
  }

  onReset(){
    this.listOfAirlineIDs = [];
    this.ngOnInit();
  }


  onSubmit(){
    this.loadingCount++
    this.sarMapsService.manageSARMap(this.sarMapID, this.listOfAirlineIDs).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next:(data) => {
        this.updateBreadcrumb(Components.SarMapsDetails.label + ` (${this.route.snapshot.queryParamMap.get('name')})`);
        this.isSaveDisabled = true;
      },
      error:(err) => {
        this.showErrorMsg(err, Action.Update, `${this.messageLabel}`, `${this.route.snapshot.queryParamMap.get('name')}`);
        this.loadingCount--
      },
      complete:()=> {
        this.showSuccessMsg(Action.Update, `${this.messageLabel}`, `${this.route.snapshot.queryParamMap.get('name')}`)
        this
        this.loadingCount--;
      }
    })
  }

}
