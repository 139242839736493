<form #endpointForm="ngForm" (ngSubmit)="onSubmit()" novalidate>
  <div class="field col-12 md:col-6 lg:col-3">
    <p-checkbox  label="Enable"  [disabled]="isReadonly" [(ngModel)]="endpoint.isenabled" id="enable" name="enable" [binary]="true"
      inputId="binary">
    </p-checkbox>
  </div>

  <div class="formgrid grid">
    <div class="col-12 md:col-6 lg:col-4">
      <span class="p-float-label">
        <input [disabled]="isReadonly" type="text" class="form-control" pattern=".*\S.*" pInputText id="name" name="name"
          [(ngModel)]="endpoint.name" required #name="ngModel" maxlength="50" [style]="{ width: '100%' }" />
        <label for="name">Name</label>
      </span>

        <div *ngIf="uniqueError">
          <small class="p-error">Name Already Exists</small>
        </div>
        <div class="tdy-required" *ngIf="(name.touched || name.dirty) && name.hasError('required')">
          Name is required.
        </div>
        <div class="tdy-required" *ngIf="name.hasError('pattern')">
          Name cannot be left blank.
        </div>
    </div>

    <div class="col-12 md:col-6 lg:col-4">
      <span class="p-float-label">
        <p-dropdown [disabled]="isReadonly" autoWidth="false" #organizationId="ngModel" [style]="{ width: '100%' }"
          [autoDisplayFirst]="false" optionLabel="name" optionValue="id" name="organization"
           id="organization" [options]="organizationList" appendTo="body"
          [required]=true [(ngModel)]="endpoint.receiver_organization_id"></p-dropdown>
        <label for="organization">Receiver Organization</label>
      </span>
      <div class="tdy-required"
        *ngIf="(organizationId.touched || organizationId.dirty) && organizationId.hasError('required')">
        Receiver Organization is required.
      </div>
    </div>

    <div class="col-12 md:col-6 lg:col-4">
      <span class="p-float-label">
        <p-dropdown [disabled]="isReadonly" autoWidth="false" #transferTypeId="ngModel" [style]="{ width: '100%' }"
          [autoDisplayFirst]="false" optionLabel="name" optionValue="transfertype_id" name="transfer_type"
          (onChange)="valueChange($event)" id="transfer_type" [options]="transferType" appendTo="body"
          [required]=true [(ngModel)]="endpoint.transfertype_id"></p-dropdown>
        <label for="transfertype_id">Transfer Type</label>
      </span>
      <div class="tdy-required"
        *ngIf="(transferTypeId.touched || transferTypeId.dirty) && transferTypeId.hasError('required')">
        Transfer Type is required.
      </div>
    </div>
  </div>

  <div *ngIf="endpoint.transfertype_id === 1">
    <div class="formgrid grid pt-2">
      <div class="col-12 md:col-6 lg:col-4">
        <span class="p-float-label">
          <input [disabled]="isReadonly" type="text" #description="ngModel" pattern=".*\S.*" id="description" pInputText name="description" [style]="{ width: '100%' }"
            [(ngModel)]="endpoint.description" />
          <label for="description">Description</label>
          <div class="tdy-required" *ngIf="description.hasError('pattern')">
            Description cannot be left blank.
          </div>
        </span>
      </div>
      <div class="field col-12 md:col-6 lg:col-4">
        <span class="p-float-label">
          <input [disabled]="isReadonly" [style]="{ width: '100%' }" type="text" pInputText id="sshfolderpath" name="sshfolderpath"
          pattern="^(\/?([\w\-.]+\/)*|(%YYYY%|%MM%|%DD%|%Reg%|%AirlineICAO%|%AirlineIATA%|%SubsidiaryICAO%|%SubsidiaryIATA%))+(\/[\w\-.]*)*\/?$"
            [(ngModel)]="jsonParameters.endpoint_folderpath" required #sshfolderpath="ngModel" />
          <label for="sshfolderpath">Folder path</label>
          <i   class="material-icons panelIcon"   (mouseenter)="panel.show($event)" (mouseleave)="panel.hide()">help_outlined</i>
          <div class="tdy-required"
            *ngIf="(sshfolderpath.touched || sshfolderpath.dirty) && sshfolderpath.hasError('required')">
            Folder Path is required.
          </div>
          <div class="tdy-required" *ngIf="sshfolderpath.hasError('pattern')">
            Invalid Path.
          </div>
        </span>
      </div>

      <div class="field col-12 md:col-6 lg:col-4">
        <span class="p-float-label">
          <input [disabled]="isReadonly" [style]="{ width: '100%' }" type="text" pInputText id="sftpsshipaddress" name="sftpsshipaddress"
            [(ngModel)]="jsonParameters.endpoint_ipaddress" required #sftpsshipaddress="ngModel"
            pattern="^(?!255\.255\.255\.255$)(\b(25[0-4]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\b(\.(?!$)|$)){4}$" />
          <label for="ipaddress">IP Address</label>
          <div *ngIf="sftpsshipaddress.errors?.['pattern']" class="alert">
            <small class="p-error"> Invalid Address.</small>
          </div>
          <div class="tdy-required"
            *ngIf="(sftpsshipaddress.touched || sftpsshipaddress.dirty) && sftpsshipaddress.hasError('required')">
            IP Address is required.
          </div>
        </span>
      </div>
    </div>

    <div class="formgrid grid">
      <div class="field col-12 md:col-6 lg:col-4">
        <span class="p-float-label">
          <input [disabled]="isReadonly" type="number" pattern="^([0-9]|[1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$" [style]="{ width: '100%' }" id="sshport" name="sshport" pInputText
            [(ngModel)]="jsonParameters.endpoint_port" [required]="true" #sftpsshport="ngModel" />
          <label for="sshport">Port</label>
          <div *ngIf="sftpsshport.errors?.['pattern']" class="alert">
            <small class="p-error"> Invalid Port.</small>
          </div>
          <div class="tdy-required"
            *ngIf="(sftpsshport.touched || sftpsshport.dirty) && sftpsshport.hasError('required')">
            Port is required.
          </div>
        </span>
      </div>
      <div class="field col-12 md:col-6 lg:col-4">
        <span class="p-float-label">
          <input [disabled]="isReadonly || jsonParameters.endpoint_authssh" [style]="{ width: '100%' }" type="text" pInputText id="sftpipusername" name="sftpipusername"
            [(ngModel)]="jsonParameters.endpoint_username" [required]="!jsonParameters.endpoint_authssh" #sftpipusername="ngModel" />
          <label for="userName">Username</label>
          <div class="tdy-required"
            *ngIf="(sftpipusername.touched || sftpipusername.dirty) && sftpipusername.hasError('required')">
            Username is required.
          </div>
        </span>
      </div>
      <div class="field col-12 md:col-6 lg:col-4">
        <span class="p-float-label">
          <input [disabled]="isReadonly || jsonParameters.endpoint_authssh" [style]="{ width: '100%' }" type="password" pInputText autocomplete="on" id="sftppassword"
            name="sftppassword" [(ngModel)]="jsonParameters.endpoint_password" #sftppassword="ngModel" [required]="!jsonParameters.endpoint_authssh" />
          <label for="password">Password</label>
          <div class="tdy-required"
            *ngIf="(sftppassword.touched || sftppassword.dirty) && sftppassword.hasError('required')">
            Password is required.
          </div>
        </span>
      </div>
      <div class="field col-12 md:col-6 lg:col-4 p-field flex flex-spacebetween">
        <span class="p-2">
          <p-checkbox id="authenticate_ssh" [readonly]="isReadonly" [disabled]="isReadonly" [required]="true" label="Authenticate using SSH" [(ngModel)]="jsonParameters.endpoint_authssh"
            name="authenticate_ssh" #authenticate_ssh="ngModel" [binary]="true" inputId="binary"></p-checkbox>
        </span>
      </div>
      <div *ngIf="jsonParameters.endpoint_authssh" class="field col-12 md:col-6 lg:col-4">
        <span class="p-float-label">
          <textarea
            rows="3"
            pInputTextarea
            [disabled]="isReadonly || !jsonParameters.endpoint_authssh"
            [required]="jsonParameters.endpoint_authssh"
            id="sshKey"
            name="sshKey"
            [(ngModel)]="jsonParameters.endpoint_public_ssh_key" #sshKey="ngModel">
          </textarea>
          <label for="sshKey">SSH public key</label>
          <div class="tdy-required" *ngIf="(sshKey.touched || sshKey.dirty) && sshKey.hasError('required')">
            SSH public key is required.
          </div>
        </span>
      </div>
    </div>
  </div>

  <div *ngIf="endpoint.transfertype_id === 2">
    <div class="formgrid flex grid pt-2">

      <div class="col-12 md:col-6 lg:col-4">
        <span class="p-float-label">
          <input [disabled]="isReadonly" type="text" #description="ngModel" pattern=".*\S.*" id="description" pInputText name="description" [style]="{ width: '100%' }"
            [(ngModel)]="endpoint.description" />
          <label for="description">Description</label>
          <div class="tdy-required" *ngIf="description.hasError('pattern')">
            Description cannot be left blank.
          </div>
        </span>
      </div>

      <div class="field col-12 md:col-6 lg:col-4">
        <span class="p-float-label">
          <input [disabled]="isReadonly" [style]="{ width: '100%' }" type="text" pattern="^(\/?([\w\-.]+\/)*|(%YYYY%|%MM%|%DD%|%Reg%|%AirlineICAO%|%AirlineIATA%|%SubsidiaryICAO%|%SubsidiaryIATA%))+(\/[\w\-.]*)*\/?$"
            pInputText id="bucketpath" name="bucketpath" #bucketpath="ngModel"
            [(ngModel)]="jsonParameters.endpoint_bucketpath" required />
          <label for="bucketpath">Bucket Prefix</label>
          <i   class="material-icons panelIcon"   (mouseenter)="panel.show($event)" (mouseleave)="panel.hide()">help_outlined</i>
          <div class="tdy-required"
            *ngIf="(bucketpath.touched || bucketpath.dirty) && bucketpath.hasError('required')">
            Bucket Prefix is required.
          </div>
          <div class="tdy-required" *ngIf="bucketpath.hasError('pattern')">
            Bucket Prefix is invalid.
          </div>
        </span>
      </div>

      <div class="field col-12 md:col-6 lg:col-4">
        <span class="p-float-label">
          <input [disabled]="isReadonly" [style]="{ width: '100%' }" type="text" pInputText id="acckey" name="acckey" type="password"
            [(ngModel)]="jsonParameters.endpoint_accesskey" required #accKey="ngModel" />
          <label for="acckey">Access Key</label>
          <div class="tdy-required" *ngIf="(accKey.touched || accKey.dirty) && accKey.hasError('required')">
            Access Key is required.
          </div>
        </span>
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col-12 md:col-6 lg:col-4">
        <span class="p-float-label">
          <input [disabled]="isReadonly" [style]="{ width: '100%' }" type="text" pInputText id="skey" name="skey" type="password"
            [(ngModel)]="jsonParameters.endpoint_secretkey" required #secretKey="ngModel" />
          <label for="skey">Secret Key</label>
          <div class="tdy-required" *ngIf="(secretKey.touched || secretKey.dirty) && secretKey.hasError('required')">
            Secret Key is required.
          </div>
        </span>
      </div>
      <div class="field col-12 md:col-6 lg:col-4">
        <span class="p-float-label" *ngIf="awsregion.length > 0">
          <p-dropdown [disabled]="isReadonly" autoWidth="false" [style]="{ width: '100%' }" optionLabel="region"
            [autoDisplayFirst]="false" [required]=true appendTo="body" #awsRegion="ngModel" optionValue="region"
            id="region" name="region" [options]="awsregion"
            [(ngModel)]="jsonParameters.endpoint_region"></p-dropdown>
          <label for="region">Region</label>
          <div class="tdy-required"
            *ngIf="(awsRegion.touched || awsRegion.dirty) && awsRegion.hasError('required')">
            Region is required.
          </div>
        </span>
      </div>
      <div class="field col-12 md:col-6 lg:col-4">
        <span class="p-float-label">
          <p-dropdown [disabled]="isReadonly" autoWidth="false" [style]="{ width: '100%' }" optionLabel="name"
            #encryptKey="ngModel" optionValue="name" [required]=true [autoDisplayFirst]="false" id="ekey"
            name="ekey" [options]="encrypttype" [(ngModel)]="jsonParameters.endpoint_encryptkey"></p-dropdown>
          <label for="ekey">Encryption Type</label>
          <div class="tdy-required"
            *ngIf="(encryptKey.touched || encryptKey.dirty) && encryptKey.hasError('required')">
            Encryption Type is required.
          </div>
        </span>
      </div>

      <div *ngIf="jsonParameters.endpoint_encryptkey === 'AWSKMS'" class="field col-12 md:col-6 lg:col-4">
        <span class="p-float-label">
          <input [disabled]="isReadonly" [style]="{ width: '100%' }" type="text" pInputText id="awskmskey" name="awskmskey" type="password"
            [(ngModel)]="jsonParameters.endpoint_awskmskey" required #awsKMSKey="ngModel" />
          <label for="awskmskey">AWS KMS Key</label>
          <div class="tdy-required" *ngIf="(awsKMSKey.touched || awsKMSKey.dirty) && awsKMSKey.hasError('required')">
            AWS KMS Key is required.
          </div>
        </span>
      </div>

    </div>
  </div>
  <div>

  </div>
  <div class="flex justify-content-end">
    <span class="p-input-icon-left">
      <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text btn2" type="button"
        (click)="onCancel()"></button>
    </span>
    <span class="p-input-icon-left p-button-text" style="float: right" *ngIf="!isReadonly">
      <button pButton pRipple label="Reset" [disabled]="!endpointForm.dirty" icon="pi pi-refresh" type="button"
        class="p-button-text btn1" (click)="onReset()"></button>
    </span>
    <span class="p-input-icon-left ml-3" style="float: right" *ngIf="!isReadonly">
      <button pButton [disabled]="endpointForm.invalid" pRipple label="Save" icon="pi pi-check"
        [disabled]="isNew? !endpointForm.valid : !(endpointForm.dirty && endpointForm.valid)"
        type="submit"></button>
    </span>
  </div>
</form>
<p-overlayPanel style="width:500px" #panel  [showCloseIcon]="true" [style]="{'width':'500px'}">
  <ng-template pTemplate="content">
  <div>
    <h5>Wildcards</h5>
  </div>
  <li class="ml-3 mb-2"><span>Current Year('YYYY')</span></li>
  <li class="ml-3 mb-2"><span>Current Month('MM')</span></li>
  <li class="ml-3 mb-2"><span>Current Day('DD')</span></li>
  <li class="ml-3 mb-2"><span>Aircraft Registration (Reg)</span></li>
  <li class="ml-3 mb-2"><span>Airline ICAO (AirlineICAO)</span></li>
  <li class="ml-3 mb-2"><span>Airline IATA (AirlineIATA)</span></li>
  <li class="ml-3 mb-2"><span>Subsidiary ICAO (SubsidiaryICAO)</span></li>
  <li class="ml-3 mb-2"><span>Subsidiary IATA (SubsidiaryIATA)</span></li>
  <div>
    <span>
      <p style="font-size: 1em;" class="mb-0">Note: All wildcards must be surrounded by '%'.</p>
    </span>
  </div>
</ng-template>
</p-overlayPanel>
