<div class="col-12 card">
  <div class="card-header m-2">
    <h5>Endpoints</h5>
    <div class="p-toolbar-group-end">
      <button pButton pRipple label="Add" *ngIf="canAdd " id="addButton" icon="pi pi-plus" (click)="openNew()"></button>
    </div>
  </div>
  <div *ngIf="(endpoints$ | async) as endpoints; else loading">
    <app-endpoints-table
      [endpoints]="endpoints"
      [canAdd]="canAdd"
      [canDelete]="canDelete"
      [canEdit]="canEdit"
      (deleteEndpoint)="deleteEndPoint($event)"
      (selectEndpoint)="onRowSelect($event)"
    ></app-endpoints-table>
  </div>
</div>

<ng-template #loading>
  <p-blockUI [blocked]="true">
    <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
  </p-blockUI>
</ng-template>


<p-confirmDialog header="Confirm Deletion" icon="pi pi-exclamation-triangle"></p-confirmDialog>
