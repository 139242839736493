<p-blockUI [blocked]="loadingCount > 0">
  <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<p-confirmDialog></p-confirmDialog>

<div *ngIf="aircraftRecorderModel" class="col-12 card p-3">
  <form #aircraftConfigDetailsForm="ngForm" (ngSubmit)="onSubmit();" novalidate>
    <div class="card-header m-2">
      <h5>Aircraft Recorder Details</h5>
    </div>  

    <div class="card">
      <h6>Details</h6>
      <div class="formgrid grid">
        <div class="field lg:col-6 mb-0">
          <span class="p-float-label">
            <p-dropdown [options]="acmsReports" name="reportsID" id="reportsID" optionLabel="label" optionValue="value"
              [autoDisplayFirst]="false" #reportsID="ngModel" [(ngModel)]="aircraftRecorderModel.acmsreportmap_id"
              [disabled]="(!canEdit)" (onChange)="onChangeReport($event)" [style]="{'width': '100%'}" appendTo="body">
              <ng-template pTemplate="item" let-option> 
                <span>{{option.label || '&nbsp;'}}                  
                </span> 
              </ng-template>
            </p-dropdown>
            <label for="reportsID">ACMS Report Map</label>
          </span>
        </div>
        <div class="field lg:col-6 mb-0">
          <span class="p-float-label">
            <p-dropdown [options]="acmsReportsVersion" name="reportVersionID" id="reportVersionID" optionLabel="version" optionValue="acmsreportmapversion_id"
              [autoDisplayFirst]="false" #reportVersionID="ngModel" [(ngModel)]="aircraftRecorderModel.acmsreportmapversion_id"
              [disabled]="aircraftRecorderModel.acmsreportmap_id ==null ||(!canEdit)" (onChange)="onChangeReportVersion($event)" [style]="{'width': '100%'}" appendTo="body"></p-dropdown>
            <label for="reportVersionID">ACMS Report Map Version</label>
          </span>
          <div class="tdy-required" *ngIf="aircraftRecorderModel.acmsreportmap_id !=null && aircraftRecorderModel.acmsreportmapversion_id == null ">
            ACMS Report Map Version is required
          </div>
        </div>
        <div class="field lg:col-6 mb-0">
          <span class="p-float-label">
            <p-dropdown [options]="sarMaps" name="sarMapsID" id="sarMapsID" optionLabel="label" optionValue="value"
              [autoDisplayFirst]="false" #sarMapsID="ngModel" [(ngModel)]="aircraftRecorderModel.sarmap_id"
              [disabled]="(!canEdit)" (onChange)="onChangeSarMaps($event)" [style]="{'width': '100%'}" appendTo="body">
              <ng-template pTemplate="item" let-option> 
                <span>{{option.label || '&nbsp;'}}                  
                </span> 
              </ng-template>
            </p-dropdown>
            <label for="sarMapsID">SAR Map</label>
          </span>
        </div>
        <div class="field lg:col-6 mb-0">
          <span class="p-float-label">
            <p-dropdown [options]="sarMapsVersion" name="sarMapsVersionID" id="sarMapsVersionID" optionLabel="version" optionValue="sarmapversion_id"
              [autoDisplayFirst]="false" #sarMapsVersionID="ngModel" [(ngModel)]="aircraftRecorderModel.sarmapversion_id"
              [disabled]="aircraftRecorderModel.sarmap_id ==null ||(!canEdit)" (onChange)="onChangeSarMapsVersion($event)" [style]="{'width': '100%'}" appendTo="body"></p-dropdown>
            <label for="sarMapsVersionID">SAR Map Version</label>
          </span>
          <div class="tdy-required" *ngIf="aircraftRecorderModel.sarmap_id!=null && aircraftRecorderModel.sarmapversion_id== null ">
            SAR Map Version is required
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showMultiRecorderInfo">
      <p-messages [(value)]="multiRecorderMessage" [enableService]="false" [closable]="false" ></p-messages>
    </div>

    <!-- add table here -->
    <div class="card"> 
      <div class=" flex justify-content-end "> 
        <button id="addButton" name="addButton" [disabled]="disableAddNewButton" type="button" class=" mb-2" pButton icon="pi pi-plus" pRipple pTooltip="Add..." label="Add" (click)="addNewRow()"></button> 
      </div>
      
      <div >
        <p-table [value]="aircraftRecorderDetails"  editMode="row" >
          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="recorder">
                <div class="flex justify-content-start align-items-center">
                  Recorder
                </div> 
              </th>
              <th pSortableColumn="data_frame">
                <div class="flex justify-content-start align-items-center">
                  Data Frame
                </div> 
              </th>
              <th pSortableColumn="data_frame_version">
                <div class="flex justify-content-start align-items-center">
                  Data Frame Versions
                </div> 
              </th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-acDetail let-i="rowIndex">
              <tr >
                <td >
                  <p-dropdown [options]="recorders"  [style]="{'width': '100%'}" [ngModelOptions]="{standalone: true}"
                   [autoDisplayFirst]="false"  optionValue="value" optionLabel="label" [disabled]="(!canEdit)"
                   id="recordersID" [name]="acDetail.recorder_id" [(ngModel)]="aircraftRecorderDetails[i].recorder_id"
                  (onChange)="onChangeRecorder($event, i, recorders)" appendTo="body">
                  </p-dropdown>
                </td>

                <td>
                  <p-dropdown [options]="dataFramesByRecorder[i]" [style]="{'width': '100%'}"  [ngModelOptions]="{standalone: true}"
                   [autoDisplayFirst]="false" optionValue="dataframe_id" optionLabel="name" appendTo="body" 
                   id="dataframeID" [name]="acDetail.dataframe_id" [(ngModel)]="aircraftRecorderDetails[i].dataframe_id"
                  (onChange)="onChangeDataFrame($event, i)" [disabled]="disableDataFrame[i] ||(!canEdit)" appendTo="body">
                </p-dropdown>
                </td>
                
                <td>
                  <p-dropdown [options]="dataFrameversion[i]" [style]="{'width': '100%'}" [ngModelOptions]="{standalone: true}"
                  id="dataframeversionID"  [disabled]="disableDataFrameVersion[i] ||(!canEdit)"  [autoDisplayFirst]="false"
                  optionValue="dataframeversion_id" optionLabel="version" [name]="acDetail.dataframeversion_id"
                  [(ngModel)]="aircraftRecorderDetails[i].dataframeversion_id"
                  (onChange)="onChangeDataFrameVersion($event, i)" appendTo="body"  >
                </p-dropdown>
                </td>
    
                <td>
                  <div class="flex align-items-center justify-content-end">
                    <button id="deleteButton" name="deleteButton" type="button"
                            class="p-button-rounded p-button-text p-button-danger"
                            pButton pRipple icon="pi pi-trash" pTooltip="Delete..."
                            (click)="onDeleteRow(aircraftRecorderDetails[i],i)"></button>
                  </div>
                </td>


              </tr>
          </ng-template>
        </p-table>
    </div>

      <div class="tdy-required" *ngIf="showRecorderRequiredError">
        Recorder must be selected.
      </div>
      <div class="tdy-required" *ngIf="showDataFrameRequiredError">
        Data Frame must be selected.
      </div>
      <div class="tdy-required" *ngIf="showDataFrameVersionRequiredError">
        Data Frame Version must be selected.
      </div>
    </div>
    
    <div class="flex justify-content-end">
      <span class="p-input-icon-left">
        <button (click)="onCancel()" class="p-button-text" icon="pi pi-times" id="cancelButton" label="Cancel" pButton
          pRipple type="button"></button>
      </span>
      <span *ngIf="!isNew && (canEdit)" class="p-input-icon-left" >
        <button id="resetButton" icon="pi pi-undo" [disabled]="disableResetButton" label="Reset" class="p-button-text" (click)="onReset()" pButton pRipple
          type="button"></button>
      </span>
      <span class="p-input-icon-left pl-2" >
        <button *ngIf="canEdit" icon="pi pi-check"
          id="submitButton" label="Save" [disabled] = "saveButtonDisabled" pButton pRipple type="submit"></button>
      </span>
    </div>
  </form>
</div>
