export const permissions = {
  endpoint: {
    create: 'send_to_teledyne.endpoint.create',
    manage: 'send_to_teledyne.endpoint.manage',
    delete: 'send_to_teledyne.endpoint.delete',
    view: 'send_to_teledyne.endpoint.view',
  },
  sendtoteledyne_flight_redaction: {
    create: 'send_to_teledyne.flight_data_redactions.create',
    manage: 'send_to_teledyne.flight_data_redactions.manage',
    delete: 'send_to_teledyne.flight_data_redactions.delete',
    view: 'send_to_teledyne.flight_data_redactions.view',
  },
  sendtoteledyne_client_packages: {
    create: 'send_to_teledyne.packages.create',
    manage: 'send_to_teledyne.packages.manage',
    delete: 'send_to_teledyne.packages.delete',
    view: 'send_to_teledyne.packages.view',
  },
  sendtoteledyne_client_authentication: {
    create: 'sendtoteledyne.client_authentication.create',
    manage: 'sendtoteledyne.client_authentication.manage',
    delete: 'sendtoteledyne.client_authentication.delete',
    view: 'sendtoteledyne.client_authentication.view',
  },
  sendtoteledyne_data_upload: {
    create: 'send_to_teledyne.data_upload.create',
  },
  sendtoteledyne_roles: {
    create: 'send_to_teledyne.send_to_teledyne_roles.create',
    manage: 'send_to_teledyne.send_to_teledyne_roles.manage',
    delete: 'send_to_teledyne.send_to_teledyne_roles.delete',
    view: 'send_to_teledyne.send_to_teledyne_roles.view',
  },
  sendtoteledyne_users: {
    manage: 'send_to_teledyne.send_to_teledyne_users.manage',
    view: 'send_to_teledyne.send_to_teledyne_users.view',
  },
  alert_notifications: {
    manage: 'send_to_teledyne.alert_notifications.manage',
    view: 'send_to_teledyne.alert_notifications.view',
    delete: 'send_to_teledyne.alert_notifications.delete',
  },
  alert_subscriptions: {
    manage: 'send_to_teledyne.alert_subscriptions.manage',
    view: 'send_to_teledyne.alert_subscriptions.view',
    delete: 'send_to_teledyne.alert_subscriptions.delete',
  },
};
