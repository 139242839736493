import { Component } from '@angular/core';
import { Components } from '../../../integration/configuration.components';
import { Router, ActivatedRoute } from '@angular/router';
import { TransferdataService } from 'src/app/features/configuration/services/transferdata.service';
import { DataFrameService } from 'src/app/features/configuration/services/data-frame.service';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { Listview767AndDXSComponent } from '../../dataframe-acms/dataframe/data-frame-listview/list-view-767-dxs/listview767-dxs.component';
import { ListViewColumnType } from '../../dataframe-acms/dataframe/shared/helpers/data-frame.enums';

@Component({
  selector: 'app-listviewdxs',
  templateUrl: './listviewdxs.component.html',
  styleUrls: ['./listviewdxs.component.scss'],
})
export class ListviewDXSComponent extends Listview767AndDXSComponent {
  constructor(
    router: Router,
    appService: TransferdataService,
    dataFrameService: DataFrameService,
    route: ActivatedRoute,
    breadcrumbService: BreadcrumbService,
    confirmationService: ConfirmationService,
    messageService: MessageService,
    realUserService: SecurityUserService
  ) {
    super(
      router,
      appService,
      dataFrameService,
      route,
      breadcrumbService,
      confirmationService,
      messageService,
      realUserService
    );
    // Data Frame DXS - Init Data
    this.routeLinkPath = Components.ListViewDXS.path;
    this.messageLabel = 'Data Frame DXS.';
    this.dfPropId = 'Schema767.Mnemonic_Code';
    this.dataFrameClone = Components.DataFrameDynamicDXS;
    this.dataFrameAnalyze = Components.DataFrameAnalyseDXS;
    this.dataFrameEdit = Components.DataFrameDynamicDXS;
    this.dataFrameMapView = Components.MapViewDXS;
    this.dataFrameAdd = Components.DataFrameDynamicDXS
    this.dataFrameDetails = Components.DataFrameDXS;
    this.breadcrumbService.setItems(route, [{ label: Components.ListViewDXS.label }]);
    this.columns = [
      { field: 'Schema767.Mnemonic_Code', header: 'Mnemonic', colType: ListViewColumnType.default, filterType: 'text', extraAttributes: { matchMode: 'contains'}},
      { field: 'Schema767.Name', header: 'Name', colType: ListViewColumnType.default, filterType: 'text', extraAttributes: { matchMode: 'contains'}},
      { field: 'Schema767.Comments', header: 'Description', colType: ListViewColumnType.default, filterType: 'text', extraAttributes: { matchMode: 'contains'}},
      { field: 'Schema767.Units', header: 'Units', colType: ListViewColumnType.default, filterType: 'text', extraAttributes: { matchMode: 'contains'}},
      { field: 'Schema767.Range.Data_Type', header: 'Data Type', colType: ListViewColumnType.default, filterType: 'text', extraAttributes: { matchMode: 'contains'}},

      { field: 'Schema767.Label', header: 'Label', colType: ListViewColumnType.default, filterType: 'text', extraAttributes: { matchMode: 'contains'}},
      { field: 'Schema767.BusName', header: 'Bus Name', colType: ListViewColumnType.default, filterType: 'text', extraAttributes: { matchMode: 'contains'}},
      { field: 'Schema767.SDI', header: 'SDI', colType: ListViewColumnType.default, filterType: 'text', extraAttributes: { matchMode: 'contains'}},
      // TODO Add new DXS columns:
      // Label
      // Bus Name
      // SDI
      // Label Rate

      // OLD 767 different data
      // { field: 'Frame.Frame_ID', header: 'Frame ID', type: 'numeric', colType: ListViewColumnType.default, filterType: 'numeric', extraAttributes: { matchMode: 'contains'}},
      // { field: 'Frame.Frame_Title', header: 'Frame Name', type: 'numeric', colType: ListViewColumnType.default, filterType: 'text', extraAttributes: { matchMode: 'contains'}},
      // { field: 'Frame_Rate', header: 'Frame Rate', type: 'numeric', colType: ListViewColumnType.default, filterType: 'numeric', extraAttributes: { matchMode: 'contains', minFractionDigits: 0, maxFractionDigits: 4}},
      // { field: 'Frame_Number', header: 'Number in Frame', type: 'numeric', colType: ListViewColumnType.default, filterType: 'numeric', extraAttributes: { matchMode: 'contains'}},
    ];

    this.pageTitle = Components.ListViewDXS.label;
    this.emptyMessage = 'No DXS Data Frame Found.';
  }

  override getListView(response) {
    this.frameIdentification = [];
    this.dataparameter = [];
    this.jsonArray = [];
    let updatedJSON = response;
    this.dataFrame = response;
    for (const key in updatedJSON.FREDFile.FRED767.Frame) {
      for (const key1 in updatedJSON.FREDFile.FRED767.Frame[key]) {
        this.frameIdentification.push(updatedJSON.FREDFile.FRED767.Frame[key][key1]);
      }
    }

    for (const key in updatedJSON.FREDFile.FRED767.Parameter767) {
      for (const key1 in updatedJSON.FREDFile.FRED767.Parameter767[key]) {
        this.dataparameter.push(updatedJSON.FREDFile.FRED767.Parameter767[key][key1]);
      }
    }
    this.processBusListData(updatedJSON);

    this.updatedMnemonics();
    this.loadingCount--;
  }

  // processes "Bus Name" property from table
  processBusListData(updatedJSON) {
    for (const key in updatedJSON.FREDFile.FRED767.BusList) {
      for (const key1 in updatedJSON.FREDFile.FRED767.BusList[key]) {
        this.dataparameter.push(updatedJSON.FREDFile.FRED767.BusList[key][key1]);
      }
    }
    for (let i=0; i<this.dataparameter.length; i++) {
      const correspondingBus = updatedJSON.FREDFile.FRED767.BusList.find(bus => bus.Port === this.dataparameter[i].Port);
      this.dataparameter[i] = {...this.dataparameter[i], ...{BusName: correspondingBus?.Name}}
    }
  }
}
