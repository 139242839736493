import { Component, OnInit } from '@angular/core';
import { permissions } from '../../integration/endpointdatadelivery.permissions';
import { Components } from '../../integration/endpointdatadelivery.components';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { Router } from '@angular/router';
import { MenuService } from 'src/app/common/services/menu.service';
import { environment } from 'src/environments/environment';
import { CommonAlertingService } from 'src/app/features/datadelivery/services/alerting.service';

@Component({
  selector: 'app-endpointdatadeliveryalerts',
  templateUrl: './alerts.component.html',
})

export class EndpointDataDeliveryAlertsComponent implements OnInit {
  permissions = permissions
  pageTitle = Components.EndpointDataDeliveryAlert.label
  tableViewPath = Components.EndpointDataDeliveryAlert.path
  detailsViewPath = Components.EndpointDataDeliveryAlertDetails.path

  constructor(public alertsService: CommonAlertingService,
    private securityUserService: SecurityUserService,
    private router: Router
  ) {
    this.alertsService.setBaseUrl(
      environment.apiUrl.endpointdatadelivery
    )
  }

  ngOnInit() {
    const isUserAuthorized = this.securityUserService.userHasPermission(
      permissions.recording_alert.view
    );
    if (!isUserAuthorized) {
      this.router.navigate([MenuService.AccessDenied.Link]).then();
    }
  }
}
