import { EMPTY, throwError } from 'rxjs';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';


const CryptoJS = require('crypto-js/sha256');

@Injectable()
export class BaseService {
  // For Testing
  private static jwtToken = '';
  protected airlineId = 0;
  protected baseUrl = environment.apiBase;
  private userName = '';
  private hashedPassword = '';
  private userID = 0;
  private headers = new HttpHeaders();
  protected options = { headers: this.headers };

  getSessionStorageJWTToken(): string {
    return BaseService.jwtToken;
  }

  protected setLoginCredentialsForBasicAuth(airlineID: number, userID: number, username: string, hashedPassword: string) {
    this.airlineId = airlineID;
    this.userID = userID;
    this.userName = username;
    this.hashedPassword = hashedPassword;
  }

  protected setCredentialsForJWTAuth(airlineID: number, userID: number, username: string, token: string) {
    this.airlineId = airlineID;
    this.userID = userID;
    this.userName = username;
    BaseService.jwtToken = token;
  }

  // protected extractData(res: Response) {
  //   const body = res.json();
  //   return (body && body.results) || [];
  // }
  //
  // protected extractPostResponse(res: Response) {
  //   const body = res.json();
  //   return body || [];
  // }

  /**
   * Handles an HTTP operation that failed. Allows the app continue.
   * @param error - Optional value to return as the observable result.
   */
  protected httpHandleError(error: HttpErrorResponse | any) {
    if (error instanceof HttpErrorResponse) {
      if (error.status === 200) {
        return EMPTY;
      }
      const message = error?.error?.message ?? error?.message;
      console.error(`Spectrum HTTP ERROR: ${message}`);  // Use 'Spectrum' in message so it's obvious that this is our code, not the browser.
      return throwError(() => message);
    }
  }



  protected getPasswordHash(password: string): string {
    return CryptoJS(password).toString();
  }


  protected httpGetHeadersForJSON(contentTypeJson = false) {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Accept', 'application/json');
    // httpHeaders = httpHeaders.set('Cache-Control', 'no-cache');
    // httpHeaders = httpHeaders.set('Pragma', 'no-cache');
    // httpHeaders = httpHeaders.set('Expires', 'Sat, 01 Jan 2050 00:00:00 GMT');

    // todo: to uncomment once ready to release. this header will prevent access from port 4200 during dev mode
    // httpHeaders = httpHeaders.set('X-Frame-Options', 'SAMEORIGIN');

    if (contentTypeJson) {
      httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    }
    return httpHeaders;
  }

  protected httpGetHeadersForLogin(airlineID = -1, userName: string, hashedPassword: string) {
    const authToken = (airlineID > 0 ? 'Basic ' + btoa(airlineID + '|' + userName + ':' + hashedPassword) : 'Basic ' + btoa(userName + ':' + hashedPassword));

    let httpHeaders = new HttpHeaders().set('Authorization', authToken);
    httpHeaders = httpHeaders.set('Accept', 'application/json');
    httpHeaders = httpHeaders.set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');

    // todo: to uncomment once ready to release. this header will prevent access from port 4200 during dev mode

    // httpHeaders = httpHeaders.set('X-Frame-Options', 'SAMEORIGIN');

    return httpHeaders;
  }



  protected httpGetErrorMsg(error: HttpErrorResponse | any): string {
    let errMsg: string;
    if (error instanceof HttpErrorResponse) {
      errMsg = JSON.stringify(error.status) + ' :err' + error.statusText;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return errMsg;
  }

  protected httpGetRequestOptionsForAuthAndJson(addContentTypeJson = false) {
    const headers = this.httpGetHeadersForJSON(addContentTypeJson);
    return { headers: headers };
  }

  protected httpDeleteOption(body: any) {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Accept', 'application/json');
    httpHeaders = httpHeaders.set('body', body)
  }
}
