import { FeatureRoot } from "src/app/common/models/feature-item.model";
import { Components } from "./sendtoteledyne.components";
import { permissions } from "./sendtoteledyne.permissions";

export const sendtoteledyneMenu: FeatureRoot = {
    label: "Send to Teledyne",
    icon: "outgoing_mail",
    user_management: {
        feature_name: "SendToTdy",
        user_permission: permissions.sendtoteledyne_users.view,
        roles_permission: permissions.sendtoteledyne_roles.view
    },
    items: [
       /* {
            label: "Send To Teledyne",
            icon: "send",
            items: [
                Components.TeledyneAddEndPoint,
                Components.FlightDataRedactions,
                //Components.SendToTeledyne,
                Components.TeledynePackages,
            ]
        }*/,
        Components.DataUpload
    ]
}