import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/common/services/breadcrumb.service";
import { DataFrameModel } from "src/app/features/configuration/models/dataframe";
import { DataFrameService } from "src/app/features/configuration/services/data-frame.service";
import { permissions } from "../../../../integration/configuration.permissions";
import { SecurityUserService } from "src/app/security/services/security-user.service";
import { BaseListComponent } from "../../base/base-list-component";
import { dataframeListInput } from "../../input-model/dataframe-input";
import { CommonService } from "src/app/common/services/common.service";
import { Components } from '../../../../integration/configuration.components';

@Component({
    selector:'app-data-frame',
    templateUrl:'../../base/list.component.html',
    providers:[{provide: dataframeListInput,
       useFactory:(dataframeService: DataFrameService, commonService: CommonService)=> new dataframeListInput(dataframeService, commonService),
       deps:[DataFrameService, CommonService]
    }

    ]
})
export class DataFrameListComponent
extends BaseListComponent<DataFrameModel>
implements OnInit {
    
    constructor(
        
        messageService: MessageService,
        confirmationService: ConfirmationService,
        breadcrumService: BreadcrumbService,
        dataframeInput: dataframeListInput,
        router: Router,
        private route: ActivatedRoute,
        private realUserService: SecurityUserService
    ){
          super(messageService,confirmationService,router,breadcrumService,dataframeInput);
          this.breadcrumbService.setItems(route,[{label: Components.Dataframe.label}])
    }
    ngOnInit(): void {
       
        this.breadcrumbService.setItems(this.route,[{label: Components.Dataframe.label}])
        this.userCanAdd = this.realUserService.userHasPermission(permissions.dataframes.create);
        this.userCanModify = this.realUserService.userHasPermission(permissions.dataframes.manage);
        this.userCanDelete =this.realUserService.userHasPermission(permissions.dataframes.delete);
        this.loadingCount ++;
        this.getAllData();
         
         
       
       
    }
}


