import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { TerminalNDBNavaidService } from 'src/app/features/administration/services/terminal-ndb-navaid.service';
import { ApiType, NavigationInformationBaseViewComponent } from '../navigation-information-base/navigation-information-base-view.component';
import { NDBNavaid } from "src/app/features/administration/models/NDBNavaid";
import { Components } from 'src/app/features/administration/integration/administration.components';

@Component({
  selector: 'app-terminal-ndb-navaids',
  templateUrl: './terminal-ndb-navaids.component.html'

})
export class TerminalNDBNavaidsComponent extends NavigationInformationBaseViewComponent implements OnInit {
  pageTitle = Components.TerminalNDBNavaidsList.label;
  file_dates: any;
  selected_date: any;
  icao: string;
  display_icao_iata: string;
  terminalNDBNavaids: NDBNavaid[];
  selected_terminalNDBNavaid: NDBNavaid;

  constructor(
    private terminalNDBService: TerminalNDBNavaidService,
    messageService: MessageService,
    confirmationService: ConfirmationService,
    router: Router,
    route: ActivatedRoute,
    breadcrumbService: BreadcrumbService) {
    super(messageService, confirmationService, router, breadcrumbService, route)
    this.icao = route.snapshot.queryParamMap.get('a');
    this.display_icao_iata = route.snapshot.queryParamMap.get('b');
    const dte = route.snapshot.queryParamMap.get('c');
    const file_dates = route.snapshot.queryParamMap.get('d');

    this.selected_date = { "dte": dte };
    this.file_dates = JSON.parse(file_dates);
    this.getTerminalNDBNavaidList(dte, this.icao);
    this.setBreadcrumb(this.display_icao_iata);
  }

  ngOnInit(): void {
    this.setBreadcrumb(this.display_icao_iata)
  }

  getTerminalNDBNavaidList(dte, airport_identifier) {
    const arg: ApiType = {
      request: this.terminalNDBService.getTerminalNDBNavaids(dte, airport_identifier),
      api_name: 'terminal ndb navaids',
      callback: this.callback.bind(this)
    }
    this.callAPI(arg);
  }

  callback(data) {
    this.data = data;
  }

  override setBreadcrumb(airport_identifier) {
    super.setBreadcrumb(
      [
        {
          label: Components.AirportList.label,
          routerLink: Components.AirportList.path,
          queryParams: { a: this.selected_date["dte"] }
        },
        {
          label: Components.TerminalNDBNavaidsList.label + ` (${airport_identifier})`
        }
      ]);
  }

  onRowSelect($event) {
    console.log(this.selected_terminalNDBNavaid);
    const ndb_identifier = this.selected_terminalNDBNavaid["ndb_identifier"];
    this.router.navigate(
      [Components.TerminalNDBNavaidsDetails.path], {
      queryParams: {
        a: this.icao,
        b: this.display_icao_iata,
        c: this.selected_date["dte"],
        d: JSON.stringify(this.file_dates),
        e: ndb_identifier
      }
    });
  }

  override onChange($event): void {
    super.onChange([Components.TerminalNDBNavaidsList.path],
      {
        queryParams: {
          a: this.icao,
          b: this.display_icao_iata,
          c: this.selected_date["dte"],
          d: JSON.stringify(this.file_dates)
        }
      });
  }
}
