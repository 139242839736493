import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { BaseService } from "../../../common/services/base.service";
import {catchError, Observable} from "rxjs";
import { VHFNavaid } from "../models/VHFNavaid";

@Injectable({
    providedIn: 'root'
  })
  export class EnRouteVHFNavaidService extends BaseService {
    constructor(private http: HttpClient) {
      super();
      this.baseUrl = environment.apiUrl.admin;
    }

    getDatesOfFiles(): Observable<string[]> {
      const url = environment.apiUrl.admin + "date-catalogue"
      return this.http.get<string[]>(url)
        .pipe(catchError(this.httpHandleError));
    }

    getEnrouteVHFNavaids(dte) {
      const url = `${this.baseUrl}enroute-navaid-vhf/${dte}`
      return this.http.get<VHFNavaid[]>(url).pipe(catchError(this.httpHandleError));
    }

    getEnrouteVHFNavaid(dte, short_icao, vor_identifier) {
      const url = `${this.baseUrl}enroute-navaid-vhf/${dte}/${short_icao}/${vor_identifier}`
      return this.http.get<VHFNavaid>(url).pipe(catchError(this.httpHandleError));
    }
}
