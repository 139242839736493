import {Pipe, PipeTransform} from '@angular/core';
import {DateUtil} from '../util/dateUtil';

@Pipe({name: 'dateFormat'})

export class DateFormatPipe implements PipeTransform {
  transform(value: any, format: string): string {
    if (value == null || format == null) {
      return null;
    }

    return DateUtil.format(value, format);
  }
}
