<p-blockUI [blocked]="loadingCount > 0">
  <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<div class="card" *ngIf="loadingCount==0">
  <div class="card-header m-2">
    <h5>{{pageTitle}}</h5>
    <p-dropdown [options]="file_dates" [(ngModel)]="selected_date" optionLabel="dte" [filter]="true"
      (onChange)="onChange($event)" tooltip="Filter by AIRAC date" filterBy="dte" [showClear]="false"
      placeholder="Select a date">
      <ng-template pTemplate="selectedItem">
        <div *ngIf="selected_date">
          <div>{{selected_date.dte}}</div>
        </div>
      </ng-template>
      <ng-template let-filedate pTemplate="item">
        <div class="country-item">
          <div>{{filedate.dte}}</div>
        </div>
      </ng-template>
    </p-dropdown>
  </div>

  <div class="card">
    
    <div class=" grid">
      <div class="lg:col-12 p-0 m-0">
        <div class="custom-panel m-2 p-4 mb-0">

    <h6>Details</h6>



    <div class="grid" *ngIf="procedure">
      <div class="col"><span>ICAO Code: </span></div>
      <div class="col">{{procedure.airport_identifier || "-"}}</div>
      <div class="col"><span>Magnetic Variance: </span></div>
      <div class="col">{{procedure.c_procedure_design_mag_var || "-"}}</div>
      <div class="col"><span>Procedure Design Aircraft Category or Type: </span></div>
      <div class="col">{{procedure.procedure_design_aircraft_category_or_type || "-"}}</div>
    </div>
    <div class="grid" *ngIf="procedure">
      <div class="col"><span>SID/STAR/Approach Identifier: </span></div>
      <div class="col">{{procedure.sid_star_approach_identifier || "-"}}</div>
      <div class="col"><span>Magnetic Variance Indicator: </span></div>
      <div class="col">{{procedure.c_procedure_design_mag_var_ind || "-"}}</div>
      <div class="col"><span>GNSS/FMS Indication: </span></div>
      <div class="col"><span>{{procedure.gnss_fms_indication || "-"}}</span></div>
    </div>

    </div>
    </div>
    </div>
  </div>

<div class="card-header m-2  flex justify-content-end">
    <p-dropdown [options]="transitions" [(ngModel)]="selected_transition" optionLabel="transition_id" [filter]="false"
      (onChange)="onTransitionChange($event)" tooltip="Filter by Transition" filterBy="transition_id" [showClear]="false"
      placeholder="{{selected_transition ? selected_transition : 'Select a transition'}}">
      <ng-template pTemplate="selectedItem">
        <div *ngIf="selected_transition">
          <div>{{selected_transition.transition_id}}</div>
        </div>
      </ng-template>
      <ng-template let-transition pTemplate="item">
        <div class="country-item">
          <div>{{transition.transition_id}}</div>
        </div>
      </ng-template>
    </p-dropdown>
</div>
<div>
  <div class="card">
    <p-table #dt_transitionSteps [value]="transitionSteps" dataKey="transition_step_id" [filterDelay]="0"
      [responsive]="true" [sortOrder]="1" id="transitionStepsTable" selectionMode="single">


      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="sequence_number">Sequence Number
            <p-sortIcon field="sequence_number"></p-sortIcon>
          </th>

          <th pSortableColumn="path_and_termination">Path Descriptor
            <p-sortIcon field="path_and_termination"></p-sortIcon>
          </th>

          <th pSortableColumn="fix_identifier">Identifier
            <p-sortIcon field="fix_identifier"></p-sortIcon>
          </th>

          <th pSortableColumn="fix_identifier_type">Identifier Type
            <p-sortIcon field="fix_identifier_type"></p-sortIcon>
          </th>

          <th pSortableColumn="c_procedure_design_mag_var">Magnetic Variation
            <p-sortIcon field="c_procedure_design_mag_var"></p-sortIcon>
          </th>

          <th pSortableColumn="magnetic_course">Magnetic Course
            <p-sortIcon field="magnetic_course"></p-sortIcon>
          </th>

          <th pSortableColumn="turn_direction">Turn Direction
            <p-sortIcon field="turn_direction"></p-sortIcon>
          </th>

          <th pSortableColumn="altitude_1">Altitude
            <p-sortIcon field="altitude_1"></p-sortIcon>
          </th>

          <th pSortableColumn="transition_altitude">Transition Altitude
            <p-sortIcon field="transition_altitude"></p-sortIcon>
          </th>

          <th pSortableColumn="speed_limit">Speed Limit
            <p-sortIcon field="speed_limit"></p-sortIcon>
          </th>

          <th pSortableColumn="recommended_navaid">Recommended Navaid
            <p-sortIcon field="recommended_navaid"></p-sortIcon>
          </th>

          <th>More Details</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-step let-expanded="expanded">
        <tr>
          <td>
            <span class="p-column-title">Sequence Number</span>
            {{step.sequence_number || "-"}}
          </td>

          <td>
            <span class="p-column-title">Path descriptor</span>
            {{step.path_and_termination || "-"}}
          </td>

          <td>
            <span class="p-column-title">Identifier</span>
            <div [ngSwitch]="true">
              <div *ngSwitchCase="step.fix_identifier && step.fix_identifier_type.includes('VHF') && !showVHFNavaids">
                <div>{{ step.fix_identifier }}</div>
              </div>
              <div *ngSwitchCase="step.fix_identifier && step.fix_identifier_type.includes('NDB') && !showNDBNavaids">
                <div>{{ step.fix_identifier }}</div>
              </div>
              <div *ngSwitchCase="step.fix_identifier && step.fix_identifier_type.includes('waypoint') && !showWaypoints">
                <div>{{ step.fix_identifier }}</div>
              </div>
              <div *ngSwitchCase="step.fix_identifier && step.fix_identifier_type.includes('runways') && !showRunways">
                <div>{{ step.fix_identifier }}</div>
              </div>
              <div *ngSwitchCase="!step.fix_identifier">
                <div>{{"-"}}</div>
              </div>
              <div *ngSwitchDefault>
                <div *ngIf="step.fix_identifier">
                  <a href="#" (click)="onFixIdentifierClick($event, step)">
                    {{ step.fix_identifier }}
                  </a>
                </div>
              </div>
            </div>
          </td>

          <td>
            <span class="p-column-title">Identifier type</span>
            {{formatFixIdentifierType(step.fix_identifier_type) || "-"}}
          </td>

          <td>
            <span class="p-column-title">Magnetic Variation</span>
            {{step.c_procedure_design_mag_var || "-"}}
          </td>

          <td>
            <span class="p-column-title">Magnetic Course</span>
            {{step.magnetic_course || "-"}}
          </td>

          <td>
            <span class="p-column-title">Turn direction</span>
            {{step.turn_direction || "-"}}
          </td>

          <td>
            <span class="p-column-title">Altitude</span>
            <div *ngIf="step.altitude_1">{{step.altitude_description}} {{step.altitude_1}}</div>
            <div *ngIf="step.altitude_2">{{step.altitude_description}} {{step.altitude_2}}</div>
            <div *ngIf="!step.altitude_1 && !step.altitude_2">-</div>
          </td>

          <td>
            <span class="p-column-title">Transition Altitude</span>
            {{step.transition_altitude || "-"}}
          </td>

          <td>
            <span class="p-column-title">Speed Limit</span>
            <div *ngIf="step.speed_limit">{{step.speed_limit_description}} {{step.speed_limit}}</div>
            <div *ngIf="!step.speed_limit">-</div>
          </td>

          <td>
            <span class="p-column-title">Recommended Navaid</span>
            {{step.recommended_navaid || "-"}}
          </td>

          <td>
            <button  class="p-button-rounded p-button-text mr-0" [pRowToggler]="step"  pTooltip="More Details..."  id="moreDetails">
              <i [ngClass]="expanded
       ? 'pi pi-fw pi-chevron-circle-up'
       : 'pi pi-fw pi-chevron-circle-down'"></i>
            </button>
            
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-rowData>
        <tr>
          <!-- 12 columns + 1 more details column  defines the leght of this expandable row -->
          <td [attr.colspan]="12 + 1">
            <div class="grid" *ngIf="rowData">
              <div class="col p-3"> <span>Arc Radius: </span>{{rowData.arc_radius || "-"}}</div>
              <div class="col p-3"> <span>Identifier 2 char ICAO code: </span>{{rowData.icao_code_fix_identifier || "-"}}
              </div> 
              <div class="col  p-3"> <span>Preferred Multiple Approach Indicator:
                </span>{{rowData.preferred_multiple_approach_indicator || "-"}}</div>
            </div>
            <div class="grid" *ngIf="rowData">
              <div class="col  p-3"><span>Rho: </span>{{rowData.rho || "-"}}</div>
              <div class="col  p-3"> <span>RNP: </span>{{rowData.rnp || "-"}}</div>
              <div class="col  p-3"><span>Turn Direction Valid: {{rowData.turn_direction_valid || "-"}}</span></div>
            </div>
            <div class="grid" *ngIf="rowData">
              <div class="col  p-3"><span>Theta: </span>{{rowData.theta || "-"}}</div>
              <div class="col p-3"><span>Vertical Angle: </span>{{rowData.vertical_angle || "-"}}</div>
              <div class="col  p-3"><span>Route Distance, Holding Distance or Time:
                </span>{{rowData.route_distance_holding_distance_or_time || "-"}}</div>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="8">No transition steps found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

</div>
