export class ClientPackages {
  package_id: number;
  name: string;
  description: string;
  redaction_id: number;
  predefined_redaction_id: number;
  ziptype_id: number;
  zip_password: string;
  file_name: string;
  conversiontype_id: number;
  conversiontype_name: string;
  predefined_redaction_name: string;
  ziptype: string;
  endpoint_name: string;
  endpoints?: EndPoints[];
  aircrafts: Aircraft[];
  secondary: boolean;
  primary: boolean;
  datatype: string;
  organization_id: number;
}

export interface ResponseModelClientPackages {
  package_id: number;
  name: string;
  description: string;
  redaction_id: number;
  predefined_redaction_id: number;
  ziptype_id: number;
  zip_password: string;
  conversiontype_id: number;
  file_name: string;
  endpoint_id: number[];
  aircraft_id: number[];
  organization_id: number;
  secondary: boolean;
  primary: boolean;
  enhanced :boolean;
}

export class ClientPackage {
  cp_id?: string;
  name?: string;
  data_type?: string;
  description?: string;
  zip_options?: string;
  redaction?: string;
  conversion_type?: string;
  preset_flightdata?: string;
  preset_acms?: string;
  preset_sar?: string;
  aircraft?: string;
  filepath?: string;
  filename?: string;
  password?: string;
  output_filename?: string;
  endpoints?: EndPoints[];
}
export interface EndPoints {
  endpoint_id: number;
  endpoint_name: string;
}

export interface Redaction {
  redaction_id: number;
  redaction_type: string;
  isPredefined?:boolean;
  redactiontypeId?: number;
}
export interface DataType {
  datatype_id: number;
  data_type: string;
}
export interface ZipType {
  ziptype_id: number;
  ziptype: string;
}
export interface ConversionType {
  conversiontype_id: number;
  conversiontype_name: string;
}
export interface flightDataType {
  flightdata_id: number;
  flightdata_type: string;
}

export interface aircrafts {
  aircraftgroup_id?: number;
  aircraft_id?:number;
  aircraft_registration: string;
}

export interface Aircraft {
  aircraft_id?:number;
  aircraft_name: string;
}

export interface predefined_redaction_id {
  predefined_redaction_id: number;
  name: string;
}
