<app-spinner [isLoading]="loadingCount > 0"></app-spinner>
<p-confirmDialog header="Confirm Deletion" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<div *ngIf="jsonArray" class="col-12 card p-3">

    <app-list-view-header
        [pageTitle]="pageTitle" [showMessage]="showMessage" [messages]="messages" [userCanManage]="userCanManage" (onNavigateToDFDetails)="navigateToDFDetails()"
        (onNavigateToMapView)="navigateToMapView()" (onNavigateToAnalyseDF)="navigateToAnalyseDF()" (onAddDataFrame)="onAddDataFrame()">
    </app-list-view-header>

    <app-list-view-table
        [copydataparameter]="jsonArray" [columns]="columns" [defaultTableRows]="defaultTableRows" [dataKey]="dfPropId" [sortField]="dfPropId"
        [currentPageReport]="currentPageReport" [pageTitle]="pageTitle" [emptyMessage]="emptyMessage" (onRowSelect)="onEdit($event)" [dfPropId]="dfPropId"
    ></app-list-view-table>

    <div *ngIf="jsonArray.length > 0 && defaultTableRows >= jsonArray.length" style="text-align: center;">
        <p class="row-paging">Showing 1 to
        {{jsonArray.length}} of {{jsonArray.length}} {{pageTitle}}</p>
    </div>

    <app-data-frame-footer-action
        [saveDisabled]="!showMessage"
        [resetDisabled]="!showMessage"
        [userCanManage]="userCanManage"
        (onCancel)="onCancel()"
        (onReset)="resetDataFrame()"
        (onSave)="saveDataFrame()">
    </app-data-frame-footer-action>
</div>
