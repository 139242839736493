import {Subject} from 'rxjs';
import {Directive, OnDestroy} from '@angular/core';

// TODO: Add Angular decorator.
@Directive()
export abstract class BaseComponent implements OnDestroy {
  ngUnsubscribe = new Subject<void>();

  ngOnDestroy(): void {
    // The best practice way of unsubscribing from Observable.subscribe() calls is to use “takeUntil()” in
    // the pipe before your “subscribe”.
    // Note that the ngUnsubscribe.next()/complete() calls in the base class will end the subscription in
    // an RxJS way…and automatically when the component is destroyed.
    // So why is this a best practice?    //
    // - Less code to write
    // - No need to do anything in ngOnDestroy, the BaseComponent handles that
    // - No need to keep track of subscriptions
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
