import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { BaseService } from '../../../common/services/base.service';
import { catchError, map } from 'rxjs/operators';
import { environment } from "src/environments/environment";
import { TeledynePackages, ResponseModelTeledynePackages, ConversionType } from "../models/TeledynePackages";
import { SortUtil } from 'src/app/platform/util/sortUtil';
import { SelectItem } from 'primeng/api';
// import { TeledynePackages } from '../sendtoteledyne/models/TeledynePackages';
@Injectable({
    providedIn: 'root'
})
export class TeledynePackagesService extends BaseService {

    constructor(
        private http: HttpClient) {
        super();
        this.baseUrl = environment.apiUrl.sendToTdy;
    }

    getTeledynePackages(): Observable<TeledynePackages[]> {
        let url = this.baseUrl + "sendtotdypackages";
        return this.http.get<TeledynePackages[]>(url, { headers: this.httpGetHeadersForJSON() })
            .pipe(map(telepackage => telepackage.map(r => {
                r.primary = Boolean(r.primary);
                r.secondary = Boolean(r.secondary);
                return r;
              })),catchError(this.httpHandleError)
            );
    }

    getTeledynePackage_by_id(package_id: number): Observable<TeledynePackages> {
        let url = this.baseUrl + "sendtotdypackages" + `/${package_id}`;
        return this.http.get<TeledynePackages>(url, { headers: this.httpGetHeadersForJSON() })
            .pipe(catchError(this.httpHandleError)
            );
    }

    addTeledynePackage(teledynepackages: ResponseModelTeledynePackages) {
        const url = this.baseUrl + 'sendtotdypackages';
        return this.http.post<number>(url, teledynepackages, { headers: this.httpGetHeadersForJSON() })
            .pipe(
                catchError(this.httpHandleError)
            )
    }

    updateTeledynePackage(teledynepackages: ResponseModelTeledynePackages) {
        const url = this.baseUrl + 'sendtotdypackages';
        return this.http.put<number>(url, teledynepackages, { headers: this.httpGetHeadersForJSON() })
            .pipe(
                catchError(this.httpHandleError)
            )
    }

    public deleteteledynepackages(package_id: number) {
        const url = this.baseUrl + 'sendtotdypackages/' + `${package_id}`;
        const options = { headers: this.httpGetHeadersForJSON() }
        return this.http.delete(url, options)
            .pipe(catchError(this.httpHandleError));
    }

    getConvertionTypes(): Observable<ConversionType[]> {
        let url = this.baseUrl + "conversiontypes";
        return this.http.get<ConversionType[]>(url, { headers: this.httpGetHeadersForJSON() })
            .pipe(catchError(this.httpHandleError)
            );
    }

    getsendtotdyConversiontype(convertiontype_id: number) {
        let url = this.baseUrl + "sendtotdyconversiontype/" + `${convertiontype_id}`;
        return this.http.get<any>(url, { headers: this.httpGetHeadersForJSON() })
            .pipe(catchError(this.httpHandleError)
            );
    }

    public populateDropdownItems<T>(items: T[], labelProp: string, valueProp: string, addAllOption = true): SelectItem[] {
        const options = [];
        // Some error checking
        if(!items || items.length == 0 ){
          return options;
        }
        
        const keys = Object.keys(items[0]);
        if(!keys.includes(labelProp) || !keys.includes(valueProp)) {
          return options;
        }
    
        items.forEach(i => {
          options.push({label: i[labelProp], value: i[valueProp]});      
        });
    
        SortUtil.sortByLabel(options);
    
        if(addAllOption) {
          options.splice(0, 0, { label: 'All', value: null });   // Insert empty item as first item
        }
        return options;
      }

}