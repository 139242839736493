<p-blockUI [blocked]="loadingCount > 0">
    <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>

<app-organization-details [organization]="company" [availableApplications]="availableApplications"
    [(assignedApplications)]="assignedApplications" [airlineApplication]="unchangedApplicationsPayload"
    [settings]="settings" [aircraftApps]="companyAircraftApps" [filterAircraftApps]="companyAircraftApps"
    [aircraftGroupsOptions]="aircraftGroupsOptions" [airlineApplication]="unchangedApplicationsPayload"
    [airlineAircraft]="companyAircraft" [aircraftGroups]="aircraftGroups" [isNew]="isNew" [organizationType]="orgType"
    [canEditOrg]="canEdit" [canViewOrg]="canView" [codeIsReadOnly]="codeIsReadOnly"
    [canViewOrgDetail]="companyDetailsView" [canEditOrgDetail]="companyDetailsEdit" [loggedIn]="loggedIn"
    pageTitle="{{getPageTitle()}}" (onCancelClick)="onCancel()" (onResetClick)="onReset()"
    (airlineAircraftChanged)="onAircraftAppsChanged($event)"
    (onSubmitClick)="onSubmit($event)"></app-organization-details>
