import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { BaseService } from '../../../common/services/base.service';
import { catchError, map } from 'rxjs/operators';
import { environment } from "src/environments/environment";
import { EndPoints,TransferType,ResponseEndPointModel } from "../models/ConfigEndPoints";
@Injectable({
  providedIn: 'root'
})
export class DataDeliveryEndPointService extends BaseService {

  constructor(
    public http: HttpClient) {
    super();
    this.baseUrl = environment.apiUrl.datadelivery;
  }

  getTransferType(): Observable<TransferType[]> {
    let url = this.baseUrl + "transfertype";
    return this.http.get<TransferType[]>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError)
      );
  }

  getEndPoints(): Observable<EndPoints[]> {
    let url = this.baseUrl + "endpoint";
    return this.http.get<EndPoints[]>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError)
      );
  }

  getEndPoint_by_id(endpoint_id:number): Observable<EndPoints> {
    let url = this.baseUrl + "endpoint" + `/${endpoint_id}`;
    return this.http.get<EndPoints>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError)
      );
  }

  addEndpoint(endpoints: ResponseEndPointModel) {
    const url = this.baseUrl + 'endpoint';
    return this.http.post<number>(url, endpoints, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError)
      )
  }

  updateEndpoint(endpoints: ResponseEndPointModel) {
    const url = this.baseUrl + 'endpoint';
    return this.http.put<number>(url, endpoints, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError)
      )
  }

  public deleteEndpoint(endpoint_id: number) {
    const url = this.baseUrl + 'endpoint/' + `${endpoint_id}`;
    const options = { headers: this.httpGetHeadersForJSON() }
    return this.http.delete(url, options)
      .pipe(catchError(this.httpHandleError));
  }

}