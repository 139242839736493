import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { Subscription } from 'rxjs';
import { SECURITY_CONSTANTS } from '../models/security-constants';
import { SecurityUserService } from './security-user.service';

export enum PostOrgSwitchBehavior {
  None = "None",
  Reload = "Reload",
  Redirect = "Redirect"
}

@Injectable({
  providedIn: 'root'
})
export class SecurityAuthService implements OnDestroy {

  constructor(
    private _router: Router,
    private userService: SecurityUserService,
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth,
  ) { }

  ngOnDestroy() {
  }

  public async tryLoadLocalSession(): Promise<void> {
    await this._oktaAuth.tokenManager.getTokens().then(tokens => {
      this._oktaAuth.tokenManager.setTokens(tokens);
    });
  }

  public async signIn(originalRequestedUrl?: string): Promise<void> {
    if (originalRequestedUrl) {
      sessionStorage.setItem(SECURITY_CONSTANTS.post_signin_redirect_url_session_key, originalRequestedUrl);
    }

    await this._oktaAuth.signInWithRedirect({
      scopes: [...SECURITY_CONSTANTS.required_scopes, SECURITY_CONSTANTS.no_org_scope],
      originalUri: window.location.origin + '/login/org',
    });
  }

  public async signOut(signOutRedirectRoute?: string): Promise<void> {
    await this._oktaAuth.signOut().then(_ => {
      if (signOutRedirectRoute) {
        this._router.navigate([signOutRedirectRoute])
      }
    });
  }

  public async switchOrg(orgName: string, postSwitchBehavior?: PostOrgSwitchBehavior): Promise<void> {
    await this._oktaAuth.token.getWithoutPrompt({
      prompt: "login",
      scopes: [...SECURITY_CONSTANTS.required_scopes, orgName]
    }).then(res => {
      this._oktaAuth.tokenManager.setTokens(res.tokens);
      // setting the token above kicks off async calls in user service to update userInfo including getting new permission set.
      // we cannot reload or navigate until that completes.
      let userInfoChangedsub: Subscription;

      const timeout = setTimeout(() => {
        if (!userInfoChangedsub?.closed) {
          userInfoChangedsub.unsubscribe();
        }
      }, 10000);

      userInfoChangedsub = this.userService.userInfo.subscribe(userInfo => {
        console.log(userInfo);
        if (userInfo.activeOrg === orgName) {

          if (postSwitchBehavior === PostOrgSwitchBehavior.Reload) {
            window.location.reload();
          } else if (postSwitchBehavior === PostOrgSwitchBehavior.Redirect) {
            const url = sessionStorage.getItem(SECURITY_CONSTANTS.post_signin_redirect_url_session_key) ?? '';
            this._router.navigateByUrl(url);
          }
          if (!userInfoChangedsub?.closed) {
            userInfoChangedsub.unsubscribe();
          }
          clearTimeout(timeout);
        }
      });
    });
  }
}
