import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { Runway } from 'src/app/features/administration/models/runway';
import { RunwayService } from 'src/app/features/administration/services/runway.service';
import { ApiType, NavigationInformationBaseViewComponent } from '../navigation-information-base/navigation-information-base-view.component';
import { Components } from 'src/app/features/administration/integration/administration.components';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { permissions } from "../../../integration/administration.permissions";

@Component({
  selector: 'app-runways',
  templateUrl: './runways.component.html'
})

export class RunwaysComponent extends NavigationInformationBaseViewComponent implements OnInit {
  pageTitle = Components.RunwayList.label;
  file_dates: any;
  selected_date: any;
  icao: string
  display_icao_iata: string
  runways: Runway[];
  selected_runway: Runway;

  constructor(
    private runwayService: RunwayService,
    messageService: MessageService,
    confirmationService: ConfirmationService,
    router: Router,
    route: ActivatedRoute,
    breadcrumbService: BreadcrumbService,
    private realUserService: SecurityUserService) {
    super(messageService, confirmationService, router, breadcrumbService, route);
    this.icao = route.snapshot.queryParamMap.get('a');
    this.display_icao_iata = route.snapshot.queryParamMap.get('b');
    const dte = route.snapshot.queryParamMap.get('c');
    const file_dates = route.snapshot.queryParamMap.get('d');

    this.selected_date = { "dte": dte };
    this.file_dates = JSON.parse(file_dates);
    this.getRunways(dte, this.icao);
    this.setBreadcrumb(this.display_icao_iata);
  }

  ngOnInit(): void {
    this.setBreadcrumb(this.display_icao_iata);
    this.userCanView = this.realUserService.userHasPermission(permissions.procedures.view);
  }

  getRunways(dte, icao) {
    const arg: ApiType = { request: this.runwayService.getRunways(dte, icao), api_name: 'Runways', callback: this.callback }
    this.callAPI(arg);
  }

  callback = (data) => { this.data = data; console.log(this.data); }


  override setBreadcrumb(display_icao_iata) {
    super.setBreadcrumb(
      [
        {
          label: Components.AirportList.label,
          routerLink: Components.AirportList.path,
          queryParams: { a: this.selected_date["dte"] }
        },
        {
          label: Components.RunwayList.label + ` (${display_icao_iata})`
        }
      ]);
  }

  onRowSelect($event) {
    const icao = this.selected_runway["airport_icao_identifier"];
    const runway_identifier = this.selected_runway["runway_identifier"]
    this.router.navigate([Components.RunwayDetails.path],
      {
        queryParams: {
          a: icao,
          b: this.display_icao_iata,
          c: this.selected_date["dte"],
          d: JSON.stringify(this.file_dates),
          e: runway_identifier
        }
      }).then();
  }

  onProceduresClick(runway_identifier) {
    this.router.navigate([Components.ProceduresList.path],
      {
        queryParams: {
          a: this.icao,
          b: this.display_icao_iata,
          c: this.selected_date["dte"],
          d: JSON.stringify(this.file_dates),
          e: runway_identifier
        }
      }).then();
  }

  isProceduresIconEnabled(has_transition, has_procedure) {
    return has_transition || has_procedure;
  }

  override onChange($event) {
    super.onChange([Components.RunwayList.path],
      {
        queryParams: {
          a: this.icao,
          b: this.display_icao_iata,
          c: this.selected_date["dte"],
          d: JSON.stringify(this.file_dates),
        }
      });
  }
}
