import { takeUntil } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { UserService } from 'src/app/features/administration/services/user.service';
import { User } from 'src/app/features/administration/models/user';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { Action, BaseViewComponent } from 'src/app/common/components/base-view.component';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { permissions } from 'src/app/security/models/permissions';
import { Components } from '../../integration/administration.components';

@Component({
  selector: 'app-users',
  templateUrl: 'users.component.html'
})
export class UsersComponent extends BaseViewComponent implements OnInit {
  pageTitle = Components.Users.label;
  users: User[] = [];
  userStatusItems: SelectItem[] = [];  // label: statusDisplayName, value: statusID

  // User permissions
  public canAdd: boolean;
  public canDelete: boolean;
  public canView: boolean;
  messageLabel = 'user'
  currentUser = '';
  /**
   * Constructor for the component.
   *
   * @param userService - Gets user information.
   * @param messageService - Used to display messages.
   * @param route - Used to get the route's query parameters.
   * @param router - Used to navigate between pages.
   * @param breadcrumbService - Used to update the breadcrumb.
   */
  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private securityUserService: SecurityUserService,
    messageService: MessageService,
    router: Router,
    breadcrumbService: BreadcrumbService,
    confirmationService: ConfirmationService) {

    super(messageService, confirmationService, router, breadcrumbService);

    // Note: Service won't update breadcrumb if caller already did so.
    this.breadcrumbService.setItems(route, [
      { label: Components.Users.label }  // No routerLink value for current page
    ]);
  }

  /**
   * Lifecycle hook that is called after data-bound properties of a directive are initialized.
   */
  ngOnInit() {
    this.canView = this.securityUserService.userHasPermission(permissions.admin.users.view);
    this.canAdd = this.securityUserService.userHasPermission(permissions.admin.users.create);
    this.canDelete = this.securityUserService.userHasPermission(permissions.admin.users.delete);

    this.breadcrumbService.setItems(this.route, [{ label: Components.Users.label }]);

    if (this.canView) {
      this.loadUsers();
    }
  }

  loadUsers() {
    this.loadingCount++;
    this.userService.getUsers().pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: users => {
          this.users = (users ?? []);
          this.users.forEach(user => { user.AD_user = Boolean(user.AD_user) });
        },
        error: error => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
          this.loadingCount--;
        },
        complete: () => {
          this.currentUser = this.securityUserService.currentUserInfo.email;
          this.loadingCount--;
        }
      });
  }

  onAdd() {
    if (!this.canAdd) {
      this.showErrorMsg('User is not authorized to add user.', Action.Add, `${this.messageLabel}`);
    } else {
      this.navigateToDetailsPage();
    }
  }

  onRowSelect(event: any) {
    this.navigateToDetailsPage(event.data.user_id, event.data.name);
  }

  navigateToDetailsPage(userID?: number, userName?: string) {
    const item = this.breadcrumbService.popItem();
    if (item) {
      this.breadcrumbService.pushItemsBeforeNavigate([
        {
          label: item.label,
          routerLink: Components.Users.path
        },
        { label: Components.UserDetails.label }
      ]);
    }

    if (userID > 0) {
      this.router.navigate([Components.UserDetails.path], {
        queryParams: {
          u: userID,
          name: userName
        }
      }).then();
    } else {
      this.router.navigate([Components.UserDetails.path], {
        queryParams: {
          u: -1
        }
      }).then();
    }
  }

  onDeleteUser(user: User) {
    if (!this.canDelete || user.user_email == this.currentUser) {
      this.showErrorMsg('User is not authorized to delete a User.', Action.Delete, `${this.messageLabel}`);
    } else {
      this.confirmationService.confirm({
        message: 'Are you sure that you want to delete the selected user?',
        accept: () => {
          this.loadingCount++;
          this.userService.deleteUser(user.user_id).subscribe({
            next: () => {
              this.showSuccessMsg(Action.Delete, `${this.messageLabel}`, `${user.display_name}`);
              // Refresh the list of Users
              this.loadUsers();
            },
            error: error => {
              this.showErrorMsg(error, Action.Delete, `${this.messageLabel}`, `${user.display_name}`);
              this.loadingCount--;
            },
            complete: () => this.loadingCount--
          });
        }
      });
    }
  }

  /**
   * Used in HTML to get strongly-typed reference when using let-
   * @param user The User object.
   */
  toUser(user: User): User {
    return user;
  }

  customSort(event: any) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];

      if ((typeof value1 === 'boolean') && (typeof value2 === 'boolean')) {
        const value1Str = value1 ? 'true' : 'false';
        const value2Str = value2 ? 'true' : 'false';
        return value1Str.localeCompare(value2Str) * event.order;
      }

      const value1Lower = String(value1).toLowerCase();
      const value2Lower = String(value2).toLowerCase();

      return value1Lower.localeCompare(value2Lower) * event.order;
    });
  }
}
