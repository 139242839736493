<p-blockUI [blocked]="loadingCount > 0">
  <tdy-spinner class="tdy-spinner"></tdy-spinner>
</p-blockUI>

<div class="col-12 card p-3">
  <div class="card-header">
    <h5>{{ pageTitle }}</h5>
  </div>
  <app-acmsreport-table
    [acmsreports]="acmsreports"
    [pageTitle]="pageTitle"
    (onRowSelectEvent)="onRowSelect($event)"
  ></app-acmsreport-table>
</div>
