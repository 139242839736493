<p-blockUI [blocked]="loadingCount > 0">
    <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<p-confirmDialog header="Confirm Deletion" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<div class="col-12 card p-3" *ngIf="canView">
    <div class="card-header">
        <h5>{{ pageTitle }}</h5>
        <div>
            <button (click)="onClickAdd()" *ngIf="canAdd" icon="pi pi-plus" id="addButton" label="Add" pButton
                pRipple type="button"></button>
        </div>
    </div>
    
        <p-table (onRowSelect)="onRowSelect($event)" [tableStyle]="{'table-layout':''}" [filterDelay]="0" [sortOrder]="1"
            [value]="aircraftinterior"
            [showCurrentPageReport]="true" [paginator]="aircraftinterior.length > defaultTableRows" [rowsPerPageOptions]="[20,50,100,200]" [rows]="defaultTableRows" currentPageReportTemplate="{{currentPageReport + ' ' + pageTitle}}" 
             dataKey="aircraftinterior_id" id="aircraftInteriorTable" selectionMode="single" sortField="aircraftinterior_deck">
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="aircraftinterior_deck" >
                       
                        <div class="flex justify-content-start align-items-center">
                            Deck
                            <p-sortIcon field="aircraftinterior_deck"></p-sortIcon>
                            <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu" field="aircraftinterior_deck"
                                type="text" matchMode="contains"></p-columnFilter>                               
                        </div>
                    </th>
                    <th pSortableColumn="aircraftinterior_description">
                        <div class="flex justify-content-start align-items-center">
                            Description
                            <p-sortIcon field="aircraftinterior_description"></p-sortIcon>
                            <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu"
                                field="aircraftinterior_description" type="text" matchMode="contains"></p-columnFilter>
                        </div>
                    </th>                  
                  <!--   <th pSortableColumn="airlineIcao">
                        <div class="flex justify-content-between align-items-center">
                            Airline
                            <p-sortIcon field="airlineIcao"></p-sortIcon>
                            <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-auto" display="menu"
                                field="airlineIcao" type="text"></p-columnFilter>
                        </div>
                    </th> -->
                    <th pSortableColumn="aircraftmodel_model" >
                        <div class="flex justify-content-start align-items-center">
                            Aircraft Model
                            <p-sortIcon field="aircraftmodel_model"></p-sortIcon>
                            <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu"
                                field="aircraftmodel_model" type="text" matchMode="contains"></p-columnFilter>
                        </div>
                    </th>
                    <th pSortableColumn="aircraftinterior_drawingnumber" >
                        <div class="flex justify-content-start align-items-center">
                            Drawing Number
                            <p-sortIcon field="aircraftinterior_drawingnumber"></p-sortIcon>
                            <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu"
                                field="aircraftinterior_drawingnumber" type="text" matchMode="contains"></p-columnFilter>
                        </div>
                    </th>   
                    <th></th>
                    </tr>            
            </ng-template>
            <ng-template let-aircraftInterior pTemplate="body">
                <ng-container *ngIf="toAircraftInterior(aircraftInterior); let aircraftInterior">
                    <tr [pSelectableRow]="aircraftInterior">
                        <td>{{aircraftInterior.aircraftinterior_deck}} </td>
                        <td>{{aircraftInterior.aircraftinterior_description}} </td>                       
                        <!-- <td>{{aircraftInterior.airline_icao_code}}</td> -->
                        <td>{{aircraftInterior.aircraftmodel_model}}&nbsp;{{aircraftInterior.aircraftmodel_sub_model}}</td>
                        <td>{{aircraftInterior.aircraftinterior_drawingnumber}}</td>
                        <td *ngIf="canDelete">
                            <div class="flex align-items-center justify-content-end">
                                <button id="deleteButton" name="deleteButton" type="button"
                                    class="p-button-rounded p-button-text p-button-danger" pButton pRipple icon="pi pi-trash"
                                    pTooltip="Delete..."
                                    (click)="onDeleteAircraftInterior(aircraftInterior)"></button>
                            </div>
                        </td>
                    </tr>
                </ng-container>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="3">No Aircraft Interiors found.</td>
                </tr>
            </ng-template>
        </p-table>
        <div *ngIf="aircraftinterior.length > 0 && defaultTableRows >= aircraftinterior.length" style="text-align: center;">
            <p class="row-paging">Showing 1 to
              {{aircraftinterior.length}} of {{aircraftinterior.length}} {{pageTitle}}</p>
          </div>
</div>