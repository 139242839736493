<p-blockUI [blocked]="loadingCount > 0">
    <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<div class="card" *ngIf="loadingCount == 0">
    <div class="card-header m-2">
        <h5>{{pageTitle}}</h5>
    </div>
    <p-table
    #dt_enrouteVHFNavaid
    [value]="data"
    dataKey="vor_identifier"
    [showCurrentPageReport]="true"
    [paginator]="data.length > defaultTableRows"
    [rowsPerPageOptions]="[20,50,100,200]"
    [rows]="defaultTableRows"
    currentPageReportTemplate="{{currentPageReport + ' ' + pageTitle}}"
    styleClass=""
    selectionMode="single"
    (onRowSelect)="onRowSelect($event)"
    sortField="vor_identifier">
       <ng-template pTemplate="caption">
           <div class="flex flex-row-reverse">
               <p-dropdown [options]="file_dates" [(ngModel)]="selected_date" optionLabel="dte" [filter]="true"
                  (onChange)="onChange($event)"
                   filterBy="dte" [showClear]="false" placeholder="Select a date">
                   <ng-template pTemplate="selectedItem">
                       <div *ngIf="selected_date">
                           <div>{{selected_date.dte}}</div>
                       </div>
                   </ng-template>
                   <ng-template let-filedate pTemplate="item">
                       <div class="country-item">
                           <div>{{filedate.dte}}</div>
                       </div>
                   </ng-template>
               </p-dropdown>
           </div>
       </ng-template>
       <ng-template pTemplate="header">
           <tr >
               <th pSortableColumn="vor_identifier" [ngStyle]="{'width':'10%'}">
                   <div class="p-d-flex p-jc-between p-ai-center">
                       Vor identifier
                       <p-sortIcon field="vor_identifier"></p-sortIcon>
                       <p-columnFilter type="text" field="vor_identifier" display="menu" [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                   </div>
               </th>
               <th pSortableColumn="name" [ngStyle]="{'width':'10%'}">
                   <div class="p-d-flex p-jc-between p-ai-center">
                       Name
                       <p-sortIcon field="name"></p-sortIcon>
                       <p-columnFilter type="text" field="name" display="menu" [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                   </div>
               </th>
               <th pSortableColumn="vor_latitude" [ngStyle]="{'width':'15%'}">
                   <div class="p-d-flex p-jc-between p-ai-center">
                      Latitude
                       <p-sortIcon field="vor_latitude"></p-sortIcon>
                       <p-columnFilter type="text" field="ndb_latitude" display="menu" [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                   </div>
               </th>
               <th pSortableColumn="vor_longitude" [ngStyle]="{'width':'15%'}">
                   <div class="p-d-flex p-jc-between p-ai-center">
                      Longitude
                       <p-sortIcon field="vor_longitude"></p-sortIcon>
                       <p-columnFilter type="text" field="ndb_longitude" display="menu" [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                   </div>
               </th>
           </tr>
       </ng-template>
       <ng-template  let-row pTemplate="body" >
               <tr [pSelectableRow]="row">
                   <td>
                       {{row.vor_identifier || "-"}}
                   </td>
                   <td>
                       {{row.name || "-"}}
                   </td>
                   <td>
                       {{row.vor_latitude || "-"}}
                   </td>
                   <td>
                       {{row.vor_longitude || "-"}}
                   </td>
               </tr>
       </ng-template>
       <ng-template pTemplate="emptymessage">
           <tr >
               <td colspan="5">No enroute VHF navaids found.</td>
           </tr>
       </ng-template>
   </p-table>
   <div *ngIf="data.length > 0 && defaultTableRows >= data.length" style="text-align: center;">
    <p class="row-paging">Showing 1 to
      {{data.length}} of {{data.length}} {{pageTitle}}</p>
  </div>
</div>
