import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Airframer } from '../models/airframer';
import { BaseService } from 'src/app/common/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class AirframerService extends BaseService {
  airframerUrl = environment.apiUrl.admin + 'airframer';

  constructor(private http: HttpClient) {
    super();
  }

  getAirframer(airframerID: number): Observable<Airframer> {
    const url = this.airframerUrl + `/${airframerID}`;
    return this.http.get<Airframer>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError)
      );
  }

  listAirframers(): Observable<Airframer[]> {
    return this.http.get<Airframer[]>(this.airframerUrl, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError)
      );
  }

  addAirframer(newAirframer: Airframer) {   
    return this.http.post<number>(this.airframerUrl, newAirframer, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError));
  }

  deleteAirframer(airframerID: number) {
    const url = this.airframerUrl + `/${airframerID}`;
    return this.http.delete(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError));
  }

  updateAirframer(updatedAirframer: Airframer) {
    const url = this.airframerUrl + `/${updatedAirframer.airframer_id}`;
    return this.http.put<number>(url, updatedAirframer, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError));
  }

}
