import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { DropdownModule } from 'primeng/dropdown';

import { OktaAuthModule, OktaCallbackComponent, OKTA_CONFIG } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';

import { OrgloginComponent } from './components/security-org-login.component';
import { OrgSelectorComponent } from './components/security-org-selector.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SpectrumApiInterceptor } from './interceptors/spectrum-api.interceptor';
import { CommonModule } from '@angular/common';
import { LogoutComponent } from './components/security-logout.component';

import { environment } from '../../environments/environment';

const oktaAuth = new OktaAuth({
    issuer: environment.oktaUrl + '/oauth2/default',
    clientId: environment.oktaClientAppId,
    redirectUri: window.location.origin + '/login/callback',
    storageManager: 
    {
        token: {storageType: 'localStorage'}, 
        cache: {storageType: 'localStorage'}, 
        transaction: {storageType: 'localStorage'} 
    }
});

const routes: Routes = [
    {
        path: 'callback',
        component: OktaCallbackComponent
    },
    {
        path: 'org',
        component: OrgloginComponent
    },
    {
        path: 'logout',
        component: LogoutComponent
    },
];

@NgModule({
    declarations: [
        OrgSelectorComponent,
        OrgloginComponent,
        LogoutComponent
    ],
    imports: [
        CommonModule,   
        DropdownModule,
        FormsModule,
        OktaAuthModule,
        RouterModule.forChild(routes),//, {onSameUrlNavigation: 'reload'}),            
    ],
    providers: [
        { 
            provide: OKTA_CONFIG, 
            useValue: { oktaAuth } 
        },
        { 
            provide: HTTP_INTERCEPTORS, 
            useClass: SpectrumApiInterceptor, 
            multi: true 
        }
    ],
    exports: [
        RouterModule,
        OrgSelectorComponent
    ],
    bootstrap: [
    ]
  })
  export class SecurityModule { }
