import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { AircraftInterior } from 'src/app/features/administration/models/aircraftInterior';
import { AircraftInteriorService } from 'src/app/features/administration/services/aircraft-interior.service';
import { Action, BaseViewComponent } from 'src/app/common/components/base-view.component';
import { NgForm } from '@angular/forms';
import { Constants } from 'src/app/common/models/constants';
import { FileUpload } from 'primeng/fileupload';
import { forkJoin, takeUntil } from 'rxjs';
import { AircraftModelFilter } from 'src/app/features/administration/models/aircraftModel';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { permissions } from 'src/app/security/models/permissions';
import { CommonService } from 'src/app/common/services/common.service';
import { Components } from '../../integration/administration.components';

@Component({
  selector: 'app-aircraft-interior-details',
  templateUrl: './aircraft-interior-details.component.html'
})
export class AircraftInteriorDetailsComponent extends BaseViewComponent implements OnInit {
  aircraftInterior: AircraftInterior;
  pageTitle = Components.AircraftInteriorDetails.label;
  drawingUrl = '';
  maxDrawingFileSize = Constants.FiveMegabyte;
  drawingFile: File = null;
  drawingToDisplay: any = null;
  drawingUploading: boolean;
  drawingChanged = false;
  acceptedFiles = ".png, .jpg, .jpeg, .pdf";
  isReadOnly = false;
  messageLabel = 'aircraft interior';

  //Populate these for drop down
  //airlines: Airline[] = null;
  aircraftModels: AircraftModelFilter[] = null;

  @ViewChild('aircraftInteriorForm') aircraftInteriorForm: NgForm;
  @ViewChild('drawingUpload') drawingUpload: FileUpload;

  /**
     * Constructor for the component.
     *
     * @param breadcrumbService - Used to update the breadcrumb.
     * @param aircraftInteriorService - Interacts with Aircraft Interior arrangement data.
     * @param userService - Gets user information.
     * @param messageService - Used to display messages.
     * @param route - Used to get the route's query parameters.
     * @param router - Used to navigate to other pages.
     * @param location - Gets the current location of the page,
     * @param fileSizeFormatter - File size
     */
  constructor(
    breadcrumbService: BreadcrumbService,
    messageService: MessageService,
    confirmationService: ConfirmationService,
    router: Router,
    private route: ActivatedRoute,
    private aircraftInteriorService: AircraftInteriorService,
    private commonService: CommonService,
    private securityUserService: SecurityUserService,
  ) {

    super(messageService, confirmationService, router, breadcrumbService);
    // Note: Service won't update breadcrumb if caller already did so.

    // add AircraftInteriorDetails to breadcrumbService items list ex: Home> AircraftInterior> AircraftInteriorDetails.
    // AircraftInterior have route link to go back
    this.breadcrumbService.setItems(route, [
      { label: Components.AircraftInterior.label, routerLink: Components.AircraftInterior.path },
      { label: (this.route.snapshot.queryParamMap.get('name')) ? Components.AircraftInteriorDetails.label + ` (${this.route.snapshot.queryParamMap.get('name')})` : Components.AircraftInteriorDetails.label },
    ]);

  }

  /**
   * Lifecycle hook that is called after data-bound properties of a directive are initialized.
   */
  ngOnInit(): void {
    const canAdd = this.securityUserService.userHasPermission(permissions.admin.aircraft_interiors.create);
    const canEdit = this.securityUserService.userHasPermission(permissions.admin.aircraft_interiors.manage);

    const isEdit = !!this.route.snapshot.queryParamMap.get('aircraftInteriorID');

    if (!isEdit) {
      this.isReadOnly = !canAdd;
      this.addAircraftInterior();
    }
    else {
      this.isReadOnly = !canEdit;
      this.getAircraftInterior(+this.route.snapshot.queryParamMap.get('aircraftInteriorID'));
    }
  }

  /**
   * Event handler for when the Add button is clicked. Navigates to the details page to create a new object.
   */
  addAircraftInterior() {
    this.loadingCount++;

    //get single aircraft interior details and all drop down data
    const joinedWithObjectForm$ = forkJoin({
      modellist: this.commonService.listAircraftModelFilters()
    });

    joinedWithObjectForm$.pipe(
      takeUntil(this.ngUnsubscribe)).subscribe({
        next: results => {
          this.aircraftModels = results.modellist;

          // Update page title and breadcrumb with New...
          this.updateBreadcrumb('New Aircraft Interior');
          this.pageTitle = "New Aircraft Interior"
          //add  aircraft interior
          this.isNew = true;
          this.aircraftInterior = new AircraftInterior();
          this.aircraftInterior.aircraftinterior_id = -1;

        }, error: error => {
          this.showErrorMsg(error, Action.Get, `${this.messageLabel}`);
          this.loadingCount--;
        }, complete: () => {
          this.loadingCount--;
        }
      });

  }

  /**
   * Gets the data required for the initial load of the page. This includes populating the drop down
   * @param aircraftInteriorId aircraft interior identifier to view/edit.
   */
  getAircraftInterior(aircraftInteriorId: number) {
    this.loadingCount++;
    this.isNew = false;

    //get single aircraft interior details and all drop down data
    const joinedWithObjectForm$ = forkJoin({
      //airlinelist: this.airlineService.listAirlines(),
      modellist: this.commonService.listAircraftModelFilters(),
      interior: this.aircraftInteriorService.getAircraftInterior(aircraftInteriorId)
    });

    joinedWithObjectForm$.pipe(
      takeUntil(this.ngUnsubscribe)).subscribe({
        next: results => {
          //this.airlines = results.airlinelist;
          this.aircraftModels = results.modellist;
          this.aircraftInterior = results.interior;
          if (results.interior.aircraftinterior_drawing != null && results.interior.aircraftinterior_drawing_file_name != null) {
            //assing the drawing
            const base64Db = results.interior.aircraftinterior_drawing;
            // convert base64 to file
            this.drawingFile = new File([base64Db], results.interior.aircraftinterior_drawing_file_name);
            //this.drawingUrl = results.interior.aircraftinterior_drawing_file_name;
            this.drawingToDisplay = base64Db;
          }
          // Update breadcrumb
          this.updateBreadcrumb(Components.AircraftInteriorDetails.label + ' (' + this.aircraftInterior.aircraftinterior_deck + ')');
        }, error: error => {
          this.showErrorMsg(error, Action.Get, `${this.messageLabel}`, `${aircraftInteriorId}`);
          this.loadingCount--;
        }, complete: () => {
          this.loadingCount--;
        }
      });

  }



  /**
     * Remove aircraft interior drawing
     */
  onDrawingRemove() {
    //user confirmation for remove
    this.confirmationService.confirm({
      message: 'Are you sure you want to remove the drawing?',
      header: 'Cancel?',
      // rejectButtonStyleClass: 'p-button-text',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        if (this.drawingUpload != null) {
          this.drawingUpload.clear();
        }
        this.drawingFile = null;
        this.drawingChanged = true;
        this.drawingToDisplay = null;
        this.aircraftInterior.aircraftinterior_drawing_file_name = null
        if (this.isNew)
          this.drawingChanged = false;
      },
      reject: () => {
        const drawing = this.drawingFile;
        this.aircraftInterior.aircraftinterior_drawing_file_name = drawing.name;
      }
    });


  }

  /**
   * Select aircraft interior drawing
   */
  onDrawingSelect(event) {
    this.drawingUploading = true;
    //Check for file size
    if (!event.files[0].type.includes('jpg') && !event.files[0].type.includes('jpeg')
      && !event.files[0].type.includes('png') && !event.files[0].type.includes('pdf')) {
      this.showErrorMsg("Incorrect File Type. Only jpg, png, jpeg, pdf are supported", Action.Upload, `${event.files[0].name}`);
      this.drawingUpload.clear();
      this.drawingFile = null;
    }
    else if (event.files[0].size > this.maxDrawingFileSize) {
      this.showErrorMsg('File Too Large. Max File Size 5MB.', Action.Upload, `${this.messageLabel}`);
      this.drawingUpload.clear();
      this.drawingFile = null;
    } else {
      //assign to the property
      const drawing = event.files[0];
      this.drawingFile = event.files[0];
      this.aircraftInterior.aircraftinterior_drawing_file_name = event.files[0].name; //can get file name
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.drawingToDisplay = reader.result;
      }, false);
      this.drawingChanged = true;
      if (drawing) {
        reader.readAsDataURL(drawing);
      }
    }
    this.drawingUploading = false;
  }

  /**
  * Download aircraft interior drawing file from the service response
  */
  downloadFile(base64String: string, fileName: string) {
    const source = base64String;
    const link = document.createElement("a");
    link.href = source;
    link.download = fileName
    link.click();
  }
  onDownloadDrawing() {
    if (this.aircraftInterior.aircraftinterior_drawing_file_name != undefined && this.aircraftInterior.aircraftinterior_drawing_file_name != null) {
      const base64String = this.aircraftInterior.aircraftinterior_drawing;
      this.downloadFile(base64String, this.aircraftInterior.aircraftinterior_drawing_file_name);
    }
  }

  /**
  * Reset aircraft interior form state
  */
  onReset() {

    if (this.drawingChanged) {
      if (this.aircraftInterior.aircraftinterior_drawing == null && this.drawingFile != null) {
        this.drawingFile = null
        this.drawingToDisplay = null
        if (this.drawingUpload != null) {
          this.drawingUpload.clear();
        }
      }
    } else {
      if (this.aircraftInterior.aircraftinterior_drawing != null && this.drawingFile) {

        //assing the drawing
        const base64Db = this.aircraftInterior.aircraftinterior_drawing;
        // convert base64 to file
        this.drawingFile = new File([base64Db], this.aircraftInterior.aircraftinterior_drawing_file_name);
        this.drawingUrl = this.aircraftInterior.aircraftinterior_drawing_file_name;
      }
    }
    this.drawingChanged = false;

    this.aircraftInteriorForm.resetForm();
    this.ngOnInit();
  }

  /**
  * Submit aircraft interior changes based on new/update
  */
  onSubmit() {
    this.loadingCount++;

    //Add/update aircraft interior to the database
    this.aircraftInteriorService.addOrUpdateAircraftInterior(this.aircraftInterior, this.drawingToDisplay).pipe(
      takeUntil(this.ngUnsubscribe)).subscribe({
        next: id => {
          this.aircraftInterior.aircraftinterior_id = id;
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
              aircraftInteriorID: this.aircraftInterior.aircraftinterior_id
            },
            queryParamsHandling: 'merge'
          }).then();

          this.updateBreadcrumb(Components.AircraftInteriorDetails.label + ' (' + this.aircraftInterior.aircraftinterior_deck + ')');
        },
        error: error => {
          const action = this.isNew ? Action.Add : Action.Update;
          this.showErrorMsg(error, action, `${this.messageLabel}`);
          this.loadingCount--;
        },
        complete: () => {
          const action = this.isNew ? Action.Add : Action.Update;
          this.pageTitle = Components.AircraftInteriorDetails.label;
          this.showSuccessMsg(action, `${this.messageLabel}`, `${this.aircraftInterior.aircraftinterior_deck}`);
          this.loadingCount--;
          this.isNew = false;
          this.drawingChanged = false;
          this.drawingUpload.clear();
          this.aircraftInteriorForm.resetForm(this.aircraftInteriorForm.value);
        }
      });
  }


  /**
  * Cancel or discard aircraft interior changes
  */
  onCancel() {
    if (this.aircraftInteriorForm.dirty || this.drawingChanged)
      this.confirmCancel(Components.AircraftInterior.path);
    else
      this.router.navigate([Components.AircraftInterior.path]).then();
  }

}
