import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { forkJoin, takeUntil } from 'rxjs';
import {
  Action,
  BaseViewComponent,
} from 'src/app/common/components/base-view.component';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { CommonService } from 'src/app/common/services/common.service';
import { AircraftGroup } from 'src/app/features/administration/models/aircraftGroup';
import { AircraftGroupService } from 'src/app/features/administration/services/aircraft-group.service';
import {
  ClientPackages,
  ConversionType,
  EndPoints,
  Redaction,
  ResponseModelClientPackages,
  ZipType,
  aircrafts,
} from 'src/app/features/datadelivery/models/ClientPackage';
import { EndpointClientPackagesService } from '../../../services/clientpackages.services';
import { EndpointDataDeliveryEndPointService } from '../../../services/endpoint.service';
import { EndpointFlightDataRedactionService } from '../../../services/flightDataRedaction.service';
import { TeledynePackagesService } from 'src/app/features/sendtoteledyne/services/teledynepackages.service';
import { Components } from '../../../integration/endpointdatadelivery.components';

@Component({
  selector: 'app-clientpackage-data-details',
  templateUrl: './clientpackage-data-details.component.html',
  styleUrls: ['./clientpackage-data-details.component.css'],
})
export class ClientpackageDataDetailsComponent
  extends BaseViewComponent
  implements OnInit
{
  pageTitle = Components.ViewClientpackagesDataDelivery.label;
  clientpackage!: ClientPackages;
  redactionType: Redaction[] = [];
  zipType: ZipType[] = [];
  endpoints: EndPoints[] = [];
  conversionType: ConversionType[] = [];
  availableEndPoints: EndPoints[];
  availableAircraft: aircrafts[] = [];
  selectedEndPoints: EndPoints[] = [];
  selectedAircraft: aircrafts[] = [];
  selectedEndPointsIDs: number[] = [];
  selectedAircraftIDs: number[] = [];
  responseModelClientPackages: ResponseModelClientPackages;
  picklistChange = false;
  pickAircraftlistChange = false;
  isButtonDisabled = true;
  isAircraftButtonDisabled = true;
  initialEndpointsCount: number;
  initialAircraftsCount: number;
  saveButtonDisabled: boolean;
  isReadonly = true;
  messageLabel = 'client package';
  showButtons = true;
  selectedRedactionId: number;
  defaultfileName: string;
  isRedaction = true;
  noConversionId = 1;
  dropdownIndex = 0;
  fileName = `%Reg%%Source%%YYYY%%MM%%DD%%HH%%mm%%ss%`;
  newLabel = 'New Client Package';
  aircraftGroups: SelectItem[];
  selectedAircraftGroupIDs: aircrafts[] = [];
  targetAircraft: aircrafts[] = [];
  selectedAircraftGroup: number;
  selectedTargetAircraftGroup: number;

  constructor(
    router: Router,
    private route: ActivatedRoute,
    breadcrumbService: BreadcrumbService,
    confirmationService: ConfirmationService,
    messageService: MessageService,
    private endpointDatadeliveryEndPointservice: EndpointDataDeliveryEndPointService,
    private endpointClientPackagesService: EndpointClientPackagesService,
    private endpointFlightDataRedactionService: EndpointFlightDataRedactionService,
    private commonService: CommonService,
    private aircraftGroupService: AircraftGroupService,
    private teledynePackagesService: TeledynePackagesService
  ) {
    super(messageService, confirmationService, router, breadcrumbService);
    this.getBreadCrums();
  }

  getBreadCrums() {
    this.breadcrumbService.setItems(this.route, [
      {
        label: Components.ViewClientpackagesDataDelivery.label,
        routerLink: Components.ViewClientpackagesDataDelivery.path,
      },
      {
        label:
          Components.ViewClientpackagesDataDelivery.label +
          ` (${this.route.snapshot.queryParamMap.get('name')})`,
      },
    ]);
  }

  intialVal() {
    this.isNew = true;
    this.pageTitle = this.newLabel;
    this.updateBreadcrumb(this.pageTitle);
    this.clientpackage.package_id = -1;
  }

  ngOnInit() {
    this.getBreadCrums();
    this.clientpackage = new ClientPackages();
    this.initializePackage();
  }

  initializePackage() {
    const packageId = +this.route.snapshot.queryParamMap.get('packageId');
    this.handleExistingPackage(packageId, false);
  }

  handleExistingPackage(packageId: number, isClone: boolean) {
    this.loadClientPackages(packageId, isClone);
  }

  loadClientPackages(packageId: number, clone: boolean) {
    this.loadingCount++;
    forkJoin({
      getPackages: this.endpointClientPackagesService.getClientPackage_by_id(packageId),
      availableEndPoints: this.endpointDatadeliveryEndPointservice.getEndPoints(),
      availableConversionType: this.endpointClientPackagesService.getConvertionTypes(),
      zipTypes: this.endpointFlightDataRedactionService.getZipTypes(),
      aircraftGroups: this.aircraftGroupService.listAircraftGroups(),
      availableAircraft: this.commonService.listAircraftGroupIdFilters(0),
    })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results) => {
          this.handleLoadClientPackagesSuccess(results, clone);
        },
        error: (error) => {
          this.handleLoadClientPackagesError(error);
        },
        complete: () => {
          this.loadingCount--;
        },
      });
  }

  handleLoadClientPackagesSuccess(results: any, clone: boolean) {
    this.zipType = results.zipTypes;
    this.loadRedactionType(
      results.getPackages.conversiontype_id,
      results.getPackages
    );
    this.clientpackage = results.getPackages;
    this.clientpackage.primary = results.getPackages.primary === true;
    this.clientpackage.secondary = results.getPackages.secondary === true;
    this.isRedaction =
      this.clientpackage.conversiontype_id !== this.noConversionId;
    this.conversionType = results.availableConversionType;
    this.availableAircraft = [];
    this.availableEndPoints = [];
    this.processEndpoints(results);
    this.processAircraft(results);
    if (clone) {
      this.clientpackage.name = '';
    }
  }

  handleLoadClientPackagesError(error: any) {
    this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}`);
    this.loadingCount--;
  }

  processEndpoints(results: any) {
    this.clientpackage.endpoints.forEach((ele) => {
      if (
        !this.selectedEndPoints.find((a) => a.endpoint_id === ele.endpoint_id)
      ) {
        this.selectedEndPoints.push({
          endpoint_id: ele.endpoint_id,
          endpoint_name: ele.endpoint_name,
        });
      }
    });
    this.clientpackage.endpoints = this.selectedEndPoints;
    results.availableEndPoints.forEach((ele) => {
      this.availableEndPoints.push({
        endpoint_id: ele.endpoint_id,
        endpoint_name: ele.name,
      });
    });
    this.selectedEndPoints.forEach((ele) => {
      this.selectedEndPointsIDs.push(ele.endpoint_id);
    });
    this.availableEndPoints = this.availableEndPoints.filter(
      (p) =>
        this.selectedEndPoints.findIndex(
          (i) => i.endpoint_id === p.endpoint_id
        ) === -1
    );
    this.initialEndpointsCount = this.selectedEndPoints.length;
    this.availableEndPoints.sort((a, b) =>
      a.endpoint_name.localeCompare(b.endpoint_name)
    );
    this.clientpackage.endpoints.sort((a, b) =>
      a.endpoint_name.localeCompare(b.endpoint_name)
    );
  }

  doesTargetAircraftExist(availableAircraft: aircrafts, targetAircraft: aircrafts[]){
    return targetAircraft.find(
      (a) => a.aircraft_registration === availableAircraft.aircraft_registration
    )
  }

  processAircraft(results: any) {
    this.aircraftGroups =
      this.teledynePackagesService.populateDropdownItems<AircraftGroup>(
        results.aircraftGroups,
        'aircraftgroup_name',
        'aircraftgroup_id'
      );
    this.targetAircraft = [];
    this.clientpackage.aircrafts.forEach((ele) => {
      this.selectedAircraft.push({
        aircraft_id: ele.aircraft_id,
        aircraft_registration: ele.aircraft_name,
      });
    });
    results.availableAircraft.forEach((ele) => {
      const targhetAricraftExists = this.doesTargetAircraftExist(ele, this.targetAircraft)
      if (
        this.selectedAircraft.find(
          (a) => a.aircraft_registration === ele.aircraft_registration
        ) &&
        !targhetAricraftExists
      ) {
        this.targetAircraft.push({
          aircraftgroup_id: ele.aircraftgroup_id,
          aircraft_id: ele.aircraft_id,
          aircraft_registration: ele.aircraft_registration,
        });
      }
      if (
        !this.availableAircraft.find(
          (a) => a.aircraft_registration === ele.aircraft_registration
        )
      ) {
        this.availableAircraft.push({
          aircraftgroup_id: ele.aircraftgroup_id,
          aircraft_id: ele.aircraft_id,
          aircraft_registration: ele.aircraft_registration,
        });
      }
    });
    this.targetAircraft.forEach((ele) => {
      this.selectedAircraftGroupIDs.push({
        aircraft_id: ele.aircraft_id,
        aircraft_registration: ele.aircraft_registration,
        aircraftgroup_id: ele.aircraftgroup_id,
      });
    });
    this.availableAircraft = this.availableAircraft.filter(
      (p) =>
        this.selectedAircraft.findIndex(
          (i) => i.aircraft_registration === p.aircraft_registration
        ) === -1
    );
    this.initialAircraftsCount = this.selectedAircraft.length;
    this.availableAircraft.sort((a, b) =>
      a.aircraft_registration.localeCompare(b.aircraft_registration)
    );
  }

  getRedactionType(
    convertionTypeId: number,
    drpdownindex: number,
    isEdit: boolean,
    redaction_id: number,
    isRedaction: number
  ) {
    this.loadingCount++;
    forkJoin({
      redactionType:
        this.endpointClientPackagesService.getRedactionTypeDetails(convertionTypeId),
    })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results) => {
          results.redactionType.forEach((ele) => {
            drpdownindex = drpdownindex + 1;
            this.redactionType.push({
              redaction_id: ele.redaction_id,
              redaction_type: ele.name,
              isPredefined: false,
              redactiontypeId: drpdownindex,
            });
          });

          if (isEdit && isRedaction == null) {
            const getValue = this.redactionType.find(
              (a) => a.redaction_id == redaction_id && a.isPredefined == false
            );
            this.selectedRedactionId = getValue.redactiontypeId;
          }
        },
        error: (error) => {
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        },
      });
  }

  loadRedactionType(convertionTypeId: number, data: ClientPackages) {
    this.loadingCount++;
    this.redactionType = [];
    forkJoin({
      predefinedDataType:
        this.endpointClientPackagesService.getPredefinedRedaction(convertionTypeId),
    })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results) => {
          this.dropdownIndex = 0;
          results.predefinedDataType.forEach((ele) => {
            this.dropdownIndex = this.dropdownIndex + 1;
            this.redactionType.push({
              redaction_id: ele.predefined_redaction_id,
              redaction_type: ele.name,
              isPredefined: true,
              redactiontypeId: this.dropdownIndex,
            });
          });

          if (data.predefined_redaction_id != null) {
            const getValue = this.redactionType.find(
              (a) =>
                a.redaction_id == data.predefined_redaction_id &&
                a.isPredefined == true
            );
            this.selectedRedactionId = getValue.redactiontypeId;
          }
          this.getRedactionType(
            convertionTypeId,
            this.dropdownIndex,
            true,
            data.redaction_id,
            data.predefined_redaction_id
          );
        },
        error: (error) => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        },
      });
  }

  loadDataFrames() {
    this.resetClientPackageData();
    this.loadingCount++;
    forkJoin({
      availableEndPoints: this.endpointDatadeliveryEndPointservice.getEndPoints(),
      availableConversionType: this.endpointClientPackagesService.getConvertionTypes(),
      redactionType: this.endpointFlightDataRedactionService.getRedactionType(),
      zipTypes: this.endpointFlightDataRedactionService.getZipTypes(),
      aircraftGroups: this.aircraftGroupService.listAircraftGroups(),
      getAircraft: this.commonService.listAircraftGroupIdFilters(0),
    })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results) => this.handleLoadDataFramesSuccess(results),
        error: (error) => this.handleLoadDataFramesError(error),
        complete: () => this.loadingCount--,
      });
  }

  resetClientPackageData() {
    this.clientpackage.aircrafts = [];
    this.clientpackage.endpoints = [];
    this.conversionType = [];
  }

  handleLoadDataFramesSuccess(results: any) {
    this.availableEndPoints = this.mapEndPoints(results.availableEndPoints);
    this.availableAircraft = this.mapAircraft(results.getAircraft);
    this.zipType = this.mapZipTypes(results.zipTypes);
    this.conversionType = results.availableConversionType;
    this.aircraftGroups =
      this.teledynePackagesService.populateDropdownItems<AircraftGroup>(
        results.aircraftGroups,
        'aircraftgroup_name',
        'aircraftgroup_id'
      );
    this.initialEndpointsCount = 0;
    this.initialAircraftsCount = 0;
    this.clientpackage.file_name = this.fileName;
    this.sortAvailableData();
  }

  handleLoadDataFramesError(error: any) {
    this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
    this.loadingCount--;
  }

  mapEndPoints(endPoints: EndPoints[]): EndPoints[] {
    return endPoints.map((ele) => ({
      endpoint_id: ele.endpoint_id,
      endpoint_name: ele.endpoint_name,
    }));
  }

  mapAircraft(aircraft: aircrafts[]): aircrafts[] {
    return aircraft.reduce((acc, ele) => {
      if (
        !acc.find((a) => a.aircraft_registration === ele.aircraft_registration)
      ) {
        acc.push({
          aircraftgroup_id: ele.aircraftgroup_id,
          aircraft_id: ele.aircraft_id,
          aircraft_registration: ele.aircraft_registration,
        });
      }
      return acc;
    }, []);
  }

  mapZipTypes(zipTypes: ZipType[]): ZipType[] {
    return zipTypes.map((ele) => ({
      ziptype: ele.ziptype,
      ziptype_id: ele.ziptype_id,
    }));
  }

  sortAvailableData() {
    this.availableAircraft.sort((a, b) =>
      a.aircraft_registration.localeCompare(b.aircraft_registration)
    );
    this.availableEndPoints.sort((a, b) =>
      a.endpoint_name.localeCompare(b.endpoint_name)
    );
  }

  onCancel() {
    this.router.navigate([Components.ClientpackagesDataDelivery.path]);
  }
  onReset() {
    this.picklistChange = false;
    this.isRedaction = true;
    this.selectedAircraftIDs = [];
    this.selectedEndPointsIDs = [];
    this.availableAircraft = [];
    this.availableEndPoints = [];
    this.redactionType = [];
    this.conversionType = [];
    this.selectedEndPoints = [];
    this.selectedAircraft = [];
    this.selectedAircraftGroupIDs = [];
    this.targetAircraft = [];
    this.selectedAircraftGroup = null;
    this.selectedTargetAircraftGroup = null;
    this.ngOnInit();
    this.isButtonDisabled = true;
  }

  prepareSelectedAircraftIDs() {
    this.selectedAircraftIDs = this.selectedAircraftGroupIDs.map(
      (a) => a.aircraft_id
    );
  }

  prepareResponseModelClientPackages() {
    this.responseModelClientPackages = {
      package_id: this.clientpackage.package_id,
      conversiontype_id: this.clientpackage.conversiontype_id,
      file_name: this.clientpackage.file_name ?? this.defaultfileName,
      description: this.clientpackage.description,
      name: this.clientpackage.name,
      redaction_id: this.clientpackage.redaction_id,
      predefined_redaction_id: this.clientpackage.predefined_redaction_id,
      ziptype_id: this.clientpackage.ziptype_id,
      zip_password: this.clientpackage.zip_password ?? '',
      aircraft_id: this.selectedAircraftIDs,
      endpoint_id: this.selectedEndPointsIDs,
      organization_id: 0,
      primary: this.clientpackage.primary ?? false,
      secondary: this.clientpackage.secondary ?? false,
      enhanced: false,
    };
  }

  addClientPackage() {
    this.endpointClientPackagesService
      .addClientPackage(this.responseModelClientPackages)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (result) => this.handleAddClientPackageSuccess(result),
        error: (err) => this.handleAddClientPackageError(err),
        complete: () => this.handleAddClientPackageComplete(),
      });
  }

  handleAddClientPackageSuccess(result: number) {
    this.clientpackage.package_id = result;
    this.router
      .navigate([], {
        relativeTo: this.route,
        queryParams: {
          packageId: this.clientpackage.package_id,
          name: this.clientpackage.name,
        },
        queryParamsHandling: 'merge',
      })
      .then();

    this.updateBreadcrumb(
      `${Components.ViewClientpackagesDataDelivery.label} (${this.clientpackage.name})`
    );
    this.pageTitle = Components.ViewClientpackagesDataDelivery.label;
    this.picklistChange = false;
  }

  handleAddClientPackageError(err: any) {
    this.showErrorMsg(`${err}`, Action.Add, `${this.messageLabel}s`);
    this.loadingCount--;
    this.isButtonDisabled = true;
    this.saveButtonDisabled = true;
  }

  handleAddClientPackageComplete() {
    this.showSuccessMsg(
      Action.Add,
      `${this.messageLabel}`,
      `${this.clientpackage.name}`
    );
    this.isNew = false;
    this.loadingCount--;
    this.isButtonDisabled = true;
    this.saveButtonDisabled = true;
    this.ngOnInit();
  }

  updateClientPackage() {
    this.endpointClientPackagesService
      .updateClientPackage(this.responseModelClientPackages)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => this.handleUpdateClientPackageSuccess(),
        error: (err) => this.handleUpdateClientPackageError(err),
        complete: () => this.handleUpdateClientPackageComplete(),
      });
  }

  handleUpdateClientPackageSuccess() {
    this.updateBreadcrumb(
      `${Components.ViewClientpackagesDataDelivery.label} (${this.clientpackage.name})`
    );
    this.picklistChange = false;
  }

  handleUpdateClientPackageError(err: any) {
    this.showErrorMsg(`${err}`, Action.Update, `${this.messageLabel}s`);
    this.loadingCount--;
    this.isButtonDisabled = true;
    this.saveButtonDisabled = true;
  }

  handleUpdateClientPackageComplete() {
    this.showSuccessMsg(
      Action.Update,
      `${this.messageLabel}`,
      `${this.clientpackage.name}`
    );
    this.loadingCount--;
    this.isButtonDisabled = true;
    this.ngOnInit();
  }

  getAircraftValuesBinded(selectedValue: number, isSource: boolean) {
    this.loadingCount++;
    this.commonService
      .listAircraftGroupIdFilters(selectedValue == null ? 0 : selectedValue)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (data) => this.handleAircraftData(data, selectedValue, isSource),
        error: (err) => this.handleError(err),
        complete: () => this.loadingCount--,
      });
  }

  handleAircraftData(data: any[], selectedValue: number, isSource: boolean) {
    if (isSource) {
      this.availableAircraft = this.filterAvailableAircraft(
        data,
        selectedValue
      );
    } else {
      this.targetAircraft = this.filterTargetAircraft(data, selectedValue);
    }
  }

  filterAvailableAircraft(data: any[], selectedValue: number): any[] {
    return data
      .filter((a) => a.aircraft_registration != null)
      .filter(
        (a) =>
          !this.selectedAircraftGroupIDs.some(
            (ele) => ele.aircraft_registration == a.aircraft_registration
          )
      )
      .filter(
        (a) =>
          !this.availableAircraft.some(
            (ele) => ele.aircraft_registration == a.aircraft_registration
          )
      )
      .map((a) => ({
        aircraft_registration: a.aircraft_registration,
        aircraftgroup_id: selectedValue,
        aircraft_id: a.aircraft_id,
      }));
  }

  filterTargetAircraft(data: any[], selectedValue: number): any[] {
    if (selectedValue == null) {
      return this.selectedAircraftGroupIDs;
    } else {
      return this.selectedAircraftGroupIDs.filter((a) =>
        data.some((i) => i.aircraft_registration == a.aircraft_registration)
      );
    }
  }

  handleError(err: any) {
    this.showErrorMsg(`${err}`, Action.Get, `${this.messageLabel}`);
    this.loadingCount--;
  }

  resetSelectedGroup(isTarget: boolean) {
    const getFilterSelection = this.getUniqueAircraftGroupIDs(
      this.selectedAircraftGroupIDs
    );

    if (isTarget) {
      this.handleTargetGroupSelection(getFilterSelection);
    } else {
      const getSourceSelection = this.getUniqueAircraftGroupIDs(
        this.availableAircraft
      );
      this.handleSourceGroupSelection(getSourceSelection);
    }
  }

  getUniqueAircraftGroupIDs(aircrafts: any[]): number[] {
    return Array.from(new Set(aircrafts.map((a) => a.aircraftgroup_id)));
  }

  handleTargetGroupSelection(getFilterSelection: number[]) {
    if (getFilterSelection.length > 1) {
      this.selectedTargetAircraftGroup = null;
    } else {
      this.selectedTargetAircraftGroup = this.selectedAircraftGroup;
    }
    this.getAircraftValuesBinded(this.selectedTargetAircraftGroup, false);
  }

  handleSourceGroupSelection(getSourceSelection: number[]) {
    if (getSourceSelection.length > 1) {
      this.selectedAircraftGroup = null;
    } else {
      this.selectedAircraftGroup = this.selectedTargetAircraftGroup;
    }
    this.getAircraftValuesBinded(this.selectedAircraftGroup, true);
  }
}
