import { takeUntil } from 'rxjs/operators';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Organization } from 'src/app/features/administration/models/organization';
import { AirlineService } from '../../services/airline.service';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { Action, BaseViewComponent } from 'src/app/common/components/base-view.component';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { permissions } from 'src/app/security/models/permissions';
import { Components } from '../../integration/administration.components';


@Component({
  selector: 'app-airlines',
  templateUrl: './airlines.component.html'
})
export class AirlinesComponent extends BaseViewComponent implements OnInit {
  pageTitle = Components.Airlines.label;
  //loadingCount = 0;  // Number of functions that are loading data, block page while not zero.
  airlines: Organization[] = null;
  //loggedInUser: User = null;

  // User permissions
  public canAdd: boolean;
  public canDelete: boolean;
  public canView: boolean;
  messageLabel = "airline";


  constructor(

    messageService: MessageService,
    router: Router,
    confirmationService: ConfirmationService,
    breadcrumbService: BreadcrumbService,
    private airlineService: AirlineService,
    private route: ActivatedRoute, private location: Location,
    private securityUserService: SecurityUserService) {
    super(messageService, confirmationService, router, breadcrumbService);

    // Note: Service won't update breadcrumb if caller already did so.
    this.breadcrumbService.setItems(route, [
      { label: Components.Airlines.label }  // No routerLink value for current page
    ]);
  }

  ngOnInit() {
    this.canView = this.securityUserService.userHasPermission(permissions.admin.airlines.view);
    this.canAdd = this.securityUserService.userHasPermission(permissions.admin.airlines.create);
    this.canDelete = this.securityUserService.userHasPermission(permissions.admin.airlines.delete);

    this.breadcrumbService.setItems(this.route, [{ label: Components.Airlines.label }]);

    if (this.canView) {
      this.getAirlines();
    }
  }

  getAirlines() {
    this.loadingCount++;

    this.airlineService.listAirlines().pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: airlines => {
        this.airlines = airlines;
      },
      error: error => {
        this.showErrorMsg(error, Action.Get, `${this.messageLabel}s`);
        this.loadingCount--;
      },
      complete: () => {
        this.loadingCount--;
      }
    }
    );
  }

  /**
   * Event handler for when a row is selected in the data table. Navigates to the details page.
   * @param event - The browser event. The data property contains the selected aircraft.
   */
  onRowSelect(event) {
    this.navigateToDetailsPage(event.data.id, event.data.name);
  }

  /**
   * Event handler for when the Add button is clicked. Navigates to the details page to create a new airline.
   */
  onClickAdd() {
    if (!this.canAdd) {
      this.showErrorMsg('User is not authorized to add an airline.', Action.Add, `${this.messageLabel}`);
    } else {
      this.navigateToDetailsPage();
    }
  }

  /**
   * Navigates to the airline details page.
   * @param airlineID Optional airline identifier to view/edit, or < 0 if adding a new airline.
   */
  navigateToDetailsPage(airlineID = -1, airlineName?: string) {
    // Set breadcrumb BEFORE navigating to next page - pop top item and add link back to this page
    // and add the item back along with new item for the next page.
    // Necessary here because breadcrumb trail is different for TDY (who can view this page) and airline
    // who can only see the airline-details page. HOME/airlines/airline-details vs HOME/airline-details
    const item = this.breadcrumbService.popItem();
    if (item) {
      this.breadcrumbService.pushItemsBeforeNavigate([
        {
          label: item.label,
          routerLink: Components.Airlines.path
        },
        { label: Components.AirlineDetails.label }  // No routerLink value for current page
      ]);
    }

    if (airlineID > 0) {
      this.router.navigate([Components.AirlineDetails.path], {
        queryParams: {
          a: airlineID,
          name: airlineName
        }
      }).then();
    } else {
      this.router.navigate([Components.AirlineDetails.path]).then();
    }
  }

  /**
   * Used in HTML to get strongly-typed reference when using let-
   * @param airline The Airline object.
   */
  toAirline(airline: Organization): Organization {
    return airline;
  }
}
