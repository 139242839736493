import { Component, OnInit } from '@angular/core';
import { permissions } from '../../integration/datadelivery.permissions';
import { Components } from '../../integration/datadelivery.components';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { Router } from '@angular/router';
import { MenuService } from 'src/app/common/services/menu.service';
import { CommonAlertingService } from '../../services/alerting.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-datadeliveryalerts',
  templateUrl: './alerts.component.html',
})
export class DataDeliveryAlertsComponent implements OnInit {
  permissions = permissions;
  pageTitle = Components.DataDeliveryAlert.label;
  tableViewPath = Components.DataDeliveryAlert.path;
  detailsViewPath = Components.DataDeliveryAlertDetails.path;

  constructor(
    public alertsService: CommonAlertingService,
    private securityUserService: SecurityUserService,
    private router: Router
  ) {
    this.alertsService.setBaseUrl(
      environment.apiUrl.datadelivery
    )
  }

  ngOnInit() {
    const isUserAuthorized = this.securityUserService.userHasPermission(
      permissions.recording_alert.view
    );
    if (!isUserAuthorized) {
      this.router.navigate([MenuService.AccessDenied.Link]).then();
    }
  }
}
