import { Component, Input } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { InputConfiguration } from "../../models/form.models";

@Component({
    selector: 'app-validation-message',
    templateUrl: './validation-message.component.html',
  })
  export class ValidationMessageComponent {
    @Input() control: AbstractControl;
    @Input() inputConf: InputConfiguration;
  }