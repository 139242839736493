<div class="p-grid pt-2">
    <div>
      <h6 class="mt-2">User Information</h6>
      <div class="-ml-2">
        <div class="col-6">
          <span class="p-float-label">
            <input #firstName="ngModel" [(ngModel)]="user.first_name" id="firstName" name="firstName"
                   maxlength="75" type="text" pInputText style="width: 100%" [textContent]="user.first_name"
                   required [disabled]="IsFieldDisabled()" pattern=".*\S.*" />
            <label>First Name</label>
          </span>
          <div *ngIf="(firstName.touched || firstName.dirty)">
            <div *ngIf="firstName.hasError('required')" class="tdy-required">First name is required</div>
            <div *ngIf="firstName.hasError('pattern')" class="tdy-required">First name cannot be blank</div>
          </div>
        </div>
        <div class="col-6">
          <span class="p-float-label">
            <input #lastName="ngModel" [(ngModel)]="user.last_name" id="lastName" maxlength="75"
                   name="lastName" pInputText [textContent]="user.last_name" style="width: 100%" type="text"
                   required [disabled]="IsFieldDisabled()" pattern=".*\S.*" />
            <label for="lastName">Last Name</label>
          </span>
          <div *ngIf="(lastName.touched || lastName.dirty)">
            <div *ngIf="lastName.hasError('required')" class="tdy-required">Last name is required</div>
            <div *ngIf="lastName.hasError('pattern')" class="tdy-required">Last name cannot be blank</div>
          </div>
        </div>
        <div class="col-6">
          <span class="p-float-label">
            <input #displayName="ngModel" [(ngModel)]="user.display_name" id="displayName" maxlength="100"
                   name="displayName" pInputText [textContent]="user.display_name" style="width: 100%" required
                   type="text" [disabled]="IsFieldDisabled()" pattern=".*\S.*" />
            <label for="displayName">Display Name</label>
          </span>
          <div *ngIf="(displayName.touched || displayName.dirty)">
            <div *ngIf="displayName.hasError('required')" class="tdy-required">Display name is required</div>
            <div *ngIf="displayName.hasError('pattern')" class="tdy-required">Display name cannot be blank</div>
          </div>
        </div>
        <div class="col-6">
          <span class="p-float-label">
            <input #email="ngModel" [(ngModel)]="user.user_email" id="email" maxlength="100" name="email"
                   pInputText [textContent]="user.user_email" style="width: 100%" type="email"
                   [disabled]="user.user_id != -1 || user.AD_user" required
                   pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}" />
            <label for="email">Email</label>
          </span>
          <div *ngIf="(email.touched || email.dirty)" class="tdy-required">
            <div *ngIf="email.hasError('required')">Email is required</div>
            <div *ngIf="email.hasError('pattern')">Email is Invalid</div>
          </div>
        </div>
        <div class="col-6">
          <span class="p-float-label">
            <p-dropdown #business="ngModel" [options]="availOrgsOptions" [(ngModel)]="user.business_code"
              placeholder="Select Business" [disabled]="isBusinessExists" id="business" name="business"
              (onChange)="selectBusiness()" [style]="dropdownStyle"></p-dropdown>
            <label for="business">Select Business</label>
          </span>
          <div *ngIf="(business.touched || business.dirty) && business.hasError('required')" class="tdy-required">
            <div>Business is required</div>
          </div>
        </div>
        <div class="flex">
          <div class="col-2" *ngIf="user.status">
            <span class="float">
              <label>Status: <label style="font-weight: bold;">{{user.status ?? 'PROVISIONED'}}</label></label>
            </span>
          </div>
          <div class="col-1">
            <span class="float">
              <p-checkbox #userAdmin="ngModel" [(ngModel)]="user.user_admin" label="Admin"
                          pTooltip="Admin User" [binary]="true"
                          [disabled]="(!canEdit && this.user.user_id > 0) || (!canAdd && this.user.user_id < 0)"
                          name="user_admin"></p-checkbox>
            </span>
          </div>
          <div class="col-2">
            <span class="float">
              <p-checkbox #ADuser="ngModel" [(ngModel)]="user.AD_user" inputId="label"
                          pTooltip="Active Directory User" [binary]="true" [disabled]="true"
                          name="AD_user"></p-checkbox>
              <label style="margin: 7px;" for="label">Active Directory</label>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>