<div class="flex pt-1">
    <span class="ml-auto">
        <span class="p-input-icon-left">
            <button pButton label="Cancel" class="p-button-text" icon="pi pi-times" type="button" id="Cancel_Btn"
                name="Cancel_Btn" (click)="cancelDataFrame()"></button>
        </span>
        <span class="p-input-icon-left">
            <button pButton pRipple type="button" label="Reset" class="p-button-text" icon="pi pi-undo" id="Reset_Btn"
                name="Reset_Btn" [disabled]="resetDisabled" (click)="resetDataFrame()" *ngIf="userCanManage"></button>
        </span>
        <span class="p-input-icon-left pl-2">
            <button pButton pRipple label="Save" type="button" icon="pi pi-check" id="Save_Btn" name="Save_Btn"
                [disabled]="saveDisabled" (click)="saveDataFrame()" *ngIf="userCanManage"></button>
        </span>
    </span>
</div>