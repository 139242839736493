import { Directive, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ConfirmationService, Message, MessageService } from "primeng/api";
import { Table } from "primeng/table";
import { takeUntil } from "rxjs";
import { Action } from 'src/app/common/components/base-view.component';
import { BaseViewComponent } from "src/app/common/components/base-view.component";
import { FeatureItem } from "src/app/common/models/feature-item.model";
import { BreadcrumbService } from "src/app/common/services/breadcrumb.service";
import { Components } from "src/app/features/configuration/integration/configuration.components";
import { permissions } from "src/app/features/configuration/integration/configuration.permissions";
import { DataFrameService } from "src/app/features/configuration/services/data-frame.service";
import { TransferdataService } from "src/app/features/configuration/services/transferdata.service";
import { SecurityUserService } from "src/app/security/services/security-user.service";


@Directive()
export abstract class MapViewComponent extends BaseViewComponent implements OnInit {
  @ViewChild(Table) dtable: Table;
  frameId: number;
  versionId: number;
  pageTitle: string;
  noDataFoundLabel: string;
  messageLabel: string;
  public canAdd?: boolean;
  public canDelete?: boolean;
  public canEdit?: boolean;
  message: any;
  isLoading: boolean = false;
  showMessage: boolean = false;
  messages: Message[] = [{
    severity: 'warn',
    summary: '',
    detail: 'Data Frame Editor changes is not yet saved. click to save'
  }];
  approvalText: any;
  updatedFrame: any;
  datatablecopy = [];
  dataFrame: any;
  mapviewComponent: FeatureItem;

  constructor (
    router: Router,
    breadcrumbService: BreadcrumbService,
    confirmationService: ConfirmationService,
    messageService: MessageService,
    protected appService: TransferdataService,
    protected route: ActivatedRoute,
    protected dataframeservice: DataFrameService,
    protected realUserService: SecurityUserService
  ) {
    super(messageService, confirmationService, router, breadcrumbService);
  }

  setBreadCrumbs() {
    this.breadcrumbService.setItems(this.route, [
      {
        label: Components.Dataframe.label,
        routerLink: Components.Dataframe.path,
      },
      {
        label: `Versions (${this.route.snapshot.queryParamMap.get('Name')})`,
        routerLink: Components.DataframeVersions.path,
        queryParams: {
          a: this.route.snapshot.queryParamMap.get('frame'),
          Name: this.route.snapshot.queryParamMap.get('Name'),
        },
      },
      {
        label:
          this.route.snapshot.queryParamMap.get('Name') +
          ` (Version ${this.route.snapshot.queryParamMap.get(
            'version_index'
          )})`,
        routerLink: Components.ListView717.path,
        queryParams: {
          Name: this.route.snapshot.queryParamMap.get('Name'),
          frame: this.route.snapshot.queryParamMap.get('frame'),
          version: this.route.snapshot.queryParamMap.get('version'),
          version_index: this.route.snapshot.queryParamMap.get('version_index'),
        },
      },
      { label: this.mapviewComponent.label },
    ]);
    this.frameId = parseInt(this.route.snapshot.queryParamMap.get('frame'));
    this.versionId = parseInt(this.route.snapshot.queryParamMap.get('version'));
  }

  ngOnInit(): void {
    this.setBreadCrumbs();
    this.userCanManage = this.realUserService.userHasPermission(permissions.dataframes.data_frame_manage);
    this.userCanView = this.realUserService.userHasPermission(permissions.dataframes.data_frame_view);

    this.getDataFrame(this.frameId, this.versionId);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  abstract getDataFrame(frameId: number, versionId: number);

  navigateTo({ path, state = {}, query = {} }: { path?: string, query?: Record<string, any>, state?: Record<string, any> }) {
    this.router.navigate([path], {
      queryParams: {
        Name: this.route.snapshot.queryParamMap.get('Name'),
        frame: this.route.snapshot.queryParamMap.get('frame'),
        version: this.route.snapshot.queryParamMap.get('version'),
        version_index: this.route.snapshot.queryParamMap.get('version_index'),
        ...query
      },
      state: {
        ...state
      }
    })
  }

  setupMnemonics({ updatedMnemonic, deletedMnemonics }: { updatedMnemonic: any[], deletedMnemonics: any[] }) {
    sessionStorage.setItem('DeletedMnemonics', JSON.stringify(deletedMnemonics));
    sessionStorage.removeItem('Mnemonics')
    sessionStorage.setItem('Mnemonics', JSON.stringify(updatedMnemonic));
  }

  saveDataFrame() {
    this.showMessage = false;
    this.showLoader();
    this.dataframeservice.updateDataFrame(this.frameId, this.versionId, this.dataFrame)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.showSuccessMsg(Action.Update, `${this.messageLabel}`, `${JSON.stringify(res.message).replace(/"/g, '')}`);
          this.appService.clearSessionStorage();
          this.router.navigate([Components.DataframeVersions.path], {
            queryParams: {
              a: this.route.snapshot.queryParamMap.get('frame'),
              Name: this.route.snapshot.queryParamMap.get('Name')
            }
          })
        },
        error: error => {
          this.showErrorMsg(error, Action.Update, `${this.messageLabel}`);
          this.hideLoader();
        },
        complete: () => {
          this.hideLoader();
        }
      });
  }

  showWarning() {
    this.showMessage = true;
  }

  hideWarning() {
    this.showMessage = false;
  }

  showLoader() {
    this.isLoading = true;
  }

  hideLoader() {
    this.isLoading = false;
  }

  onCancel() {
    this.hideWarning()
    this.toListView()
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  abstract toListView(): any;

  resetMapView() {
    this.hideWarning();
    this.hideLoader();
    this.appService.clearSessionStorage();
    this.dtable.reset();
    this.getDataFrame(this.frameId, this.versionId);
  }
}