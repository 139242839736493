import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { INotification, INotificationEmailTemplates } from 'src/app/common/models/notifications';
import { NotificationsService } from 'src/app/common/services/notifications.service';
import { Action, BaseViewComponent } from '../../base-view.component';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Components as AdministrationComponents } from 'src/app/features/administration/integration/administration.components';
import { Components as ConfigurationComponents } from 'src/app/features/configuration/integration/configuration.components';
import { Components as DataDeliveryComponents } from 'src/app/features/datadelivery/integration/datadelivery.components';
import { Components as EndpointDataDeliveryComponents } from 'src/app/features/endpointdatadelivery/integration/endpointdatadelivery.components';
import { Components as SendToTeledyneComponents } from 'src/app/features/sendtoteledyne/integration/sendtoteledyne.components';

@Component({
  selector: 'app-common-notifications-page',
  templateUrl: './notifications-page.component.html',
  styleUrls: ['./notifications-page.component.scss'],
})
export class CommonNotificationsPageComponent
  extends BaseViewComponent
  implements OnInit
{
  items: any;

  @Input() notifications: INotification[];
  @Input() currentOpenNotification: INotification;
  @Input() emailTemplates: INotificationEmailTemplates

  @ViewChild('sortOverlay') sortOverlay: OverlayPanel;

  selectedSortOption: string = 'newest';
  selectedNotifications: { [key: number]: boolean } = {};
  selectAll = false;
  firstCurrentOpenNotificationHasBeenRead = false;
  areTemplatesLoaded: boolean = false;
  currentOpenEmailTemplate: string;

  constructor(
    router: Router,
    private route: ActivatedRoute,
    private notificationsService: NotificationsService,
    confirmationService: ConfirmationService,
    messageService: MessageService,
    breadcrumbService: BreadcrumbService,
    private sanitizer: DomSanitizer
  ) {
    super(messageService, confirmationService, router, breadcrumbService);
    this.setBreadcrumbs()
  }

  ngOnInit() {
    this.setItems();
  }

  setItems() {
    this.items = [
      {
        label: 'Options',
        items: [
          {
            label: 'Refresh',
            icon: 'pi pi-refresh',
          },
          {
            label: 'Export',
            icon: 'pi pi-upload',
          },
        ],
      },
    ];
  }

  getBreadcrumbComponent(url: string){
    const app = url.split("/").filter(Boolean)?.[0];
    switch(app){
      case "datadelivery":
        return DataDeliveryComponents.NotificationsViewForDataDelivery
      case "administration":
        return AdministrationComponents.NotificationsViewForAdministration
      case "configuration":
        return ConfigurationComponents.NotificationsViewForConfiguration
      case "endpointdatadelivery":
        return EndpointDataDeliveryComponents.NotificationsViewForEndpointDataDelivery
      case "sendtoteledyne":
        return SendToTeledyneComponents.NotificationsViewForSendToTeldyne
    }
  }

  setBreadcrumbs(){
    const component = this.getBreadcrumbComponent(this.router.url)
    this.breadcrumbService.setItems(this.route, [
      {
        label: component?.label,
        routerLink: component?.path,
      },
    ]);
  }

  ngOnChanges(changes: SimpleChanges) {
    const currentOpenNotificationIsNotEmptyOnInit =
      changes?.['currentOpenNotification']?.currentValue;
    if (
      !this.firstCurrentOpenNotificationHasBeenRead &&
      currentOpenNotificationIsNotEmptyOnInit
    ) {
      this.areTemplatesLoaded = true;
      this.markFirstLoadedNotificationAsRead();
    }
  }

  markFirstLoadedNotificationAsRead() {
    if (this.currentOpenNotification) {
      this.openNotification(this.currentOpenNotification.id);
      this.firstCurrentOpenNotificationHasBeenRead = true;
    }
  }

  markNotificationAsRead(payload: {
    notification_id: number[];
    seen?: boolean;
  }) {
    this.notificationsService.markNotificationsAsRead(payload).subscribe();
  }

  replaceEachBlock(template: string, recordings_info_list: any): string {
    // Find the section that contains the {{#each recordings_info_list}} block
    const eachRegex = /{{#each recordings_info_list}}(.*?){{\/each}}/gs;
    const match = eachRegex.exec(template);

    if (match && match[1]) {
      const trTemplate = match[1]; // This is the <tr> template part
      let generatedRows = '';

      // Loop through the recordings_info_list and replace the placeholders
      recordings_info_list?.forEach((recording) => {
        let row = trTemplate;
        row = row.replace(/{{this\.aircraft_id}}/g, recording.aircraft_id);
        row = row.replace(/{{this\.submitteddate}}/g, recording.submitteddate);
        generatedRows += row;
      });

      // Replace the entire {{#each}} block with the generated rows
      return template.replace(eachRegex, generatedRows);
    }

    return template;
  }

  replaceTemplatePlaceholders(template: string, template_data: any) {
    let replacedTemplate = template
      .replace(/{{recipient_name}}/g, template_data?.recipient_name || '')
      .replace(/{{organization_name}}/g, template_data?.organization_name || '')
      .replace(/{{failures_count}}/g, template_data?.failures_count || '')
      .replace(/{{time_to_look_back}}/g, template_data?.time_to_look_back || '')
      .replace(/{{last_connection_to_DPM}}/g,
        template_data?.last_connection_to_DPM || ''
      )
      .replace(/{{link}}/g, '#'); // ensure the logo doesn't redirect
    if (template_data?.recordings_info_list?.length > 0) {
      replacedTemplate = this.replaceEachBlock(
        replacedTemplate,
        template_data?.recordings_info_list
      );
    }
    return replacedTemplate;
  }

  loadEmailTemplate(id: number) {
    const notification = this.notifications.find((n) => n.id == id);
    if (!notification?.template_data) {
      this.currentOpenEmailTemplate = this.sanitizer.bypassSecurityTrustHtml("Something went wrong") as string
      return;
    }
    const templateToUse = Object.values(this.emailTemplates).find(
      ({ alert_id }) => alert_id == notification.alert_type_id
    )?.template ?? "Something went wrong";
    const templateWithData = this.replaceTemplatePlaceholders(
      templateToUse,
      notification.template_data
    );
    this.currentOpenEmailTemplate = this.sanitizer.bypassSecurityTrustHtml(
      templateWithData
    ) as string;
  }

  openNotification(id: number) {
    this.router
      .navigate([], {
        queryParams: { selectedNotification: id },
        queryParamsHandling: 'merge',
      })
      .then();

    this.loadEmailTemplate(id);

    const isAlreadySeen = this.notifications.find((n) => n.id == id)?.seen;

    if (isAlreadySeen) {
      return;
    }
    const payload = {
      notification_id: [id],
      seen: true,
    };
    this.markNotificationAsRead(payload);
  }

  cleanupAfterBulkMarkAsRead() {
    const selectedIds = this.getSelectedNotificationIds();
    this.notifications.forEach((notification) => {
      if (selectedIds.includes(notification.id)) {
        if (!notification.seen) {
          notification.seen = true;
        }
      }
    });
  }

  bulkMarkAsRead() {
    const payload = {
      notification_id: this.getSelectedNotificationIds(),
      seen: true,
    };
    this.notificationsService.markNotificationsAsRead(payload).subscribe({
      next: () => {
        this.cleanupAfterBulkMarkAsRead();
      },
      error: (error) => {
        this.showErrorMsg(
          `${error}`,
          Action.Update,
          `Error marking notifications as read`
        );
      },
    });
  }

  toggleNotificationPriority(payload: {
    notification_id: number[];
    priority?: boolean;
  }) {
    this.notificationsService.toggleNotificationsPriority(payload).subscribe();
  }

  toggleSingleNotificationPriority(id: number) {
    const priority = this.notifications.find((n) => n.id == id)?.priority;
    const payload = {
      notification_id: [id],
      priority: !priority,
    };
    this.toggleNotificationPriority(payload);
  }

  deleteNotification(id: number) {
    const payload = { notification_id: [id] };
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this notification?',
      header: 'Delete?',
      accept: () => {
        this.notificationsService.deleteNotifications(payload).subscribe({
          complete: () => {
            this.cleanupAfterSingleDelete(id);
            this.showSuccessMsg(Action.Delete, `notification`)
          },
          error: (error) => {
            this.showErrorMsg(
              `${error}`,
              Action.Delete,
              `Error while deleting notification`
            );
          },
        });
      },
    });
  }

  cleanupAfterSingleDelete(id: number) {
    this.notifications = this.notifications.filter(
      ({ id: notificationId }) => notificationId != id
    );
    const wasCurrentOpenNotificationDeleted =
      this.currentOpenNotification.id == id;
    if (wasCurrentOpenNotificationDeleted) {
      this.currentOpenNotification = null;
      if (this.notifications.length > 0) {
        this.openNotification(this.notifications[0].id);
      }
    }
  }

  isSelected(option: string): boolean {
    return this.selectedSortOption === option;
  }

  toggleSelectNotification() {
    this.updateSelectAllState();
  }

  toggleSelectAll(event: any) {
    this.selectAll = event.checked;
    this.notifications.forEach((notification) => {
      this.selectedNotifications[notification.id] = this.selectAll;
    });
  }

  updateSelectAllState() {
    this.selectAll = this.notifications.every(
      (notification) => this.selectedNotifications[notification.id]
    );
  }

  cleanupAfterBulkDelete(selectedIds: number[]) {
    this.notifications = this.notifications.filter(
      (notification) => !selectedIds.includes(notification.id)
    );
    this.selectedNotifications = {};
    this.selectAll = false;
    const wasCurrentOpenNotificationDeleted = selectedIds.includes(
      this.currentOpenNotification.id
    );
    if (wasCurrentOpenNotificationDeleted) {
      this.currentOpenNotification = null;
      if (this.notifications.length > 0) {
        this.openNotification(this.notifications[0].id);
      }
    }
  }

  bulkDelete() {
    const selectedIds = this.getSelectedNotificationIds();
    const payload = { notification_id: selectedIds };
    if (selectedIds.length > 0) {
      this.confirmationService.confirm({
        message: 'Are you sure you want to delete the selected notifications?',
        accept: () => {
          this.notificationsService.deleteNotifications(payload).subscribe({
            complete: () => {
              this.cleanupAfterBulkDelete(selectedIds);
              this.showSuccessMsg(Action.Delete, `the selected notifications`)
            },
            error: (error) => {
              this.showErrorMsg(
                `${error}`,
                Action.Delete,
                `Error while deleting the selected notifications`
              );
            },
          });
        },
      });
    }
  }

  getSelectedNotificationIds(): number[] {
    return Object.keys(this.selectedNotifications)
      .filter((key) => this.selectedNotifications[+key])
      .map(Number);
  }

  onFilterIconClick(event: Event, overlayPanel: any) {
    overlayPanel.toggle(event);
  }

  toggleOverlayPanel(event: Event, overlayPanel: OverlayPanel, sortIcon: any) {
    overlayPanel.show(event, sortIcon.nativeElement);
  }

  onSortOptionChange(sortOption: string) {
    if (sortOption === 'newest') {
      this.sortByNewest();
    } else if (sortOption === 'oldest') {
      this.sortByOldest();
    }
  }

  sortByNewest() {
    this.notifications.sort(
      (a, b) =>
        new Date(b.alert_datetime).getTime() -
        new Date(a.alert_datetime).getTime()
    );
  }

  sortByOldest() {
    this.notifications.sort(
      (a, b) =>
        new Date(a.alert_datetime).getTime() -
        new Date(b.alert_datetime).getTime()
    );
  }
}
