import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-pick-list-custom',
  templateUrl: './pick-list-custom.component.html'
})
export class PickListCustomComponent<T> implements OnInit {

  // Inputs
  @Input() sourceItems: T[];
  @Input() targetItems: T[];
  @Input() sourceTitle: string;
  @Input() targetTitle: string;
  @Input() sourceDisplayLabel: string;
  @Input() targetDisplayLabel: string;
  @Input() sourceFilterField: string;
  @Input() targetFilterField: string;
  @Input() itemDataKey: string;     // A unique id to be used in the table to improve performance

  // Permissions
  @Input() isEditable: boolean;

  // Selected templates
  @Input() targetTemplate: TemplateRef<any>;

  // Outputs
  @Output() targetItemsChange = new EventEmitter<T[]>();
  @Output() onMoveToSourceItems = new EventEmitter<any>();
  @Output() onMoveToTargetItems = new EventEmitter<any>();

  sourceItemsSelected: T[];
  targetItemsSelected: T[];

  constructor() { 
    //
  }

  ngOnInit(): void {
    //
    if(this.sourceItems && this.targetItems) {
      if(this.itemDataKey) {
        this.sourceItems = this.sourceItems.filter(s => this.targetItems.findIndex(t => t[this.itemDataKey] == s[this.itemDataKey]) == -1);
      } else {
        this.sourceItems = this.sourceItems.filter(s => this.targetItems.findIndex(t => t == s) == -1);
      }     
    }
  }

  selectedChange() {
    this.targetItemsChange.emit(this.targetItems); 
  }

  onMoveToSelectedClick() {
    if (this.sourceItemsSelected?.length > 0) {
    this.targetItems = this.targetItems.concat(this.sourceItemsSelected);
    if (this.itemDataKey) {
      this.sourceItems = this.sourceItems.filter(s => this.sourceItemsSelected.findIndex(sel => sel[this.itemDataKey] == s[this.itemDataKey]) == -1);
    } else {
      this.sourceItems = this.sourceItems.filter(s => this.sourceItemsSelected.findIndex(sel => sel == s) == -1);
    }
      this.targetItemsChange.emit(this.targetItems); 
      this.onMoveToTargetItems.emit();    
    this.sourceItemsSelected = [];
    this.targetItemsSelected = [];
    }   
  }

  onMoveToAvailableClick() {
    this.sourceItems = this.sourceItems.concat(this.targetItemsSelected);
    if (this.itemDataKey) {
      this.targetItems = this.targetItems.filter(t => this.targetItemsSelected.findIndex(sel => sel[this.itemDataKey] == t[this.itemDataKey]) == -1);
    } else {
      this.targetItems = this.targetItems.filter(t => this.targetItemsSelected.findIndex(sel => sel == t) == -1);
    }
    this.targetItemsChange.emit(this.targetItems);
    this.onMoveToSourceItems.emit();
    this.targetItemsSelected = [];
    this.sourceItemsSelected = [];   
  }

}
