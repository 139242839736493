
	<p-blockUI [blocked]="loadingCount > 0">
		<tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
	</p-blockUI>
	<div class="card" *ngIf="loadingCount == 0">
		<div class="card-header m-2">
			<h5>{{pageTitle}}</h5>
			<p-dropdown [options]="file_dates" [(ngModel)]="selected_date" optionLabel="dte" [filter]="true"
			(onChange)="onChange($event)" tooltip="Filter by AIRAC date"
			 filterBy="dte" [showClear]="false" placeholder="Select a date">
			 <ng-template pTemplate="selectedItem">
				 <div *ngIf="selected_date">
					 <div>{{selected_date.dte}}</div>
				 </div>
			 </ng-template>
			 <ng-template let-filedate pTemplate="item">
				 <div class="country-item">

					 <div>{{filedate.dte}}</div>
				 </div>
			 </ng-template>
		 </p-dropdown>
		</div>


		<div class="col-12 nav_airports">
			<p-table
			#dt_airports
			[value]="data"
			dataKey="airport_icao_identifier"
			[showCurrentPageReport]="true"
			[paginator]="data.length > defaultTableRows"
			[rowsPerPageOptions]="[20,50,100,200]"
			[rows]="defaultTableRows"
			currentPageReportTemplate="{{currentPageReport + ' ' + pageTitle}}"
			[loading]="loading"
			styleClass=""
			selectionMode="single" [(selection)]="selected_airport"
			(onRowSelect)="onRowSelect($event)"
			sortField="airport_icao_identifier"
			>
			   <ng-template pTemplate="header">
				   <tr >
					   <th pSortableColumn="airport_icao_identifier" [ngStyle]="{'width':'8%'}">
						   <div class="p-d-flex p-jc-between p-ai-center">
							   ICAO Code
							   <p-sortIcon field="airport_icao_identifier"></p-sortIcon>
							   <p-columnFilter type="text" field="airport_icao_identifier" display="menu" [showOperator]="false" [showAddButton]="false"></p-columnFilter>
						   </div>

					   </th>
					   <th pSortableColumn="ata_iata_designator" [ngStyle]="{'width':'8%'}">
						   <div class="p-d-flex p-jc-between p-ai-center">
							   IATA Code
							   <p-sortIcon field="ata_iata_designator"></p-sortIcon>
							   <p-columnFilter type="text" field="ata_iata_designator" display="menu" [showOperator]="false" [showAddButton]="false"></p-columnFilter>
						   </div>
					   </th>

					   <th pSortableColumn="airport_name" [ngStyle]="{'width':'10%'}">
						   <div class="p-d-flex p-jc-between p-ai-center">
							   Airport Name
							   <p-sortIcon field="airport_name"></p-sortIcon>
							   <p-columnFilter type="text" field="airport_name" display="menu" [showOperator]="false" [showAddButton]="false"></p-columnFilter>
						   </div>
					   </th>

					   <th pSortableColumn="icao_code" [ngStyle]="{'width':'7%'}">
						   <div class="p-d-flex p-jc-between p-ai-center">
							   Country
							   <p-sortIcon field="icao_code"></p-sortIcon>
							   <p-columnFilter type="text" field="icao_code" display="menu" [showOperator]="false" [showAddButton]="false"></p-columnFilter>
						   </div>
					   </th>

					   <th pSortableColumn="airport_reference_pt_latitude" [ngStyle]="{'width':'7%'}">
						   <div class="p-d-flex p-jc-between p-ai-center">
							   Latitude
							   <p-sortIcon field="airport_reference_pt_latitude"></p-sortIcon>
							   <p-columnFilter type="numeric" field="airport_reference_pt_latitude"
								   display="menu" [showOperator]="false" [showAddButton]="false"></p-columnFilter >
						   </div>
					   </th>

					   <th pSortableColumn="airport_reference_pt_longitude" [ngStyle]="{'width':'10%'}">
						   <div class="p-d-flex p-jc-between p-ai-center">
							   Longitude
							   <p-sortIcon field="airport_reference_pt_longitude"></p-sortIcon>
							   <p-columnFilter type="numeric" field="airport_reference_pt_longitude"
								   display="menu" [showOperator]="false" [showAddButton]="false"></p-columnFilter>
						   </div>
					   </th>
   
			   <th *ngIf="showRunways" class="text-center" [ngStyle]="{'width':'6%'}">Runways</th>
			   <th *ngIf="terminalWaypoints"  class="text-center" [ngStyle]="{'width':'8%'}">Terminal Waypoints</th>
			 <th *ngIf="showNDBNavaids"  class="text-center" [ngStyle]="{'width':'10%'}">Terminal NDB Navaids</th>
			 <th *ngIf="showVHFNavaids"  class="text-center" [ngStyle]="{'width':'10%'}">Terminal VHF Navaids</th>
				   </tr>
			   </ng-template>
			   <ng-template pTemplate="body" let-airport>
				   <tr [pSelectableRow]="airport">
					   <td>
						   {{airport.airport_icao_identifier || "-"}}
					   </td>
					   <td>
						   {{airport.ata_iata_designator || "-"}}
					   </td>
					   <td>
						   {{airport.airport_name || "-"}}
					   </td>
					   <td>
						   {{airport.icao_code || "-"}}
					   </td>
					   <td>
						   {{airport.airport_reference_pt_latitude || "-" | number:'1.8-8'}}
					   </td>
					   <td>
						   {{airport.airport_reference_pt_longitude || "-" | number:'1.8-8'}}
					   </td>
					   <td *ngIf="showRunways">
						   <div class="flex justify-content-center">
							   <button id="runwayButton"
									   name="runwayButton"
									   type="button"
									   class="p-button-rounded p-button-text"
									   pButton
									   pRipple
									   pTooltip="View List Of Runways"
                         [disabled]="airport.has_runways == false"
									   (click)="onRunwayClick(airport.airport_icao_identifier,airport.ata_iata_designator)"
								   > <img src="./../../../../../assets/images/runway.png" alt="runway.png" border="0" style="height:30px" /></button>
						   </div>
					   </td>
					   <td *ngIf="terminalWaypoints">
						   <div class="flex justify-content-center">
							   <button id="terminalwaypointButton"
									   name="terminalwaypointButton"
									   type="button"
									   class="p-button-rounded p-button-text "
									   pButton
									   pRipple
									   pTooltip="View List Of Terminal Waypoints"
                         [disabled]="airport.has_terminal_waypoints == false"
									   (click)="onTerminalWaypointClick(airport.airport_icao_identifier,airport.ata_iata_designator)"
								   > <img src="./../../../../../assets/images/Terminal_waypoint.png" alt="terminal_waypoint.png" border="0" style="height:30px" /></button>
						   </div>
					   </td>
			 <td *ngIf="showNDBNavaids">
						   <div class="flex justify-content-center">
							   <button id="terminalNDBNavaidButton"
									   name="terminalNDBNavaidButton"
									   type="button"
									   class="p-button-rounded p-button-text "
									   pButton
									   pRipple
									   pTooltip="View List Of Terminal NDB Navaids"
                         [disabled]="airport.has_terminal_NDB_navaids == false"
									   (click)="onTerminalNDBNavaidClick(airport.airport_icao_identifier,airport.ata_iata_designator)"
								   > <img src="./../../../../../assets/images/ndb_navaid.png" alt="ndb_navaid.png" border="0" style="height:30px" /></button>
						   </div>
			 </td>
			 <td *ngIf="showVHFNavaids">
						   <div class="flex justify-content-center">
							   <button id="terminalVHFNavaidButton"
									   name="terminalVHFNavaidButton"
									   type="button"
									   class="p-button-rounded p-button-text "
									   pButton
									   pRipple
									   pTooltip="View List Of Terminal VHF Navaids"
                         [disabled]="airport.has_terminal_VHF_navaids == false"
									   (click)="onTerminalVHFNavaidClick(airport.airport_icao_identifier,airport.ata_iata_designator)"
								   > <img src="./../../../../../assets/images/vhf_navaid.png" alt="vhf_navaid.png" border="0" style="height:30px" /></button>
						   </div>
			 </td>
				   </tr>
			   </ng-template>
			   <ng-template pTemplate="emptymessage">
				   <tr >
					   <td colspan="8">No airports found.</td>
				   </tr>
			   </ng-template>
		   </p-table></div>
		<div *ngIf="data.length > 0 && defaultTableRows >= data.length" style="text-align: center;">
			<p class="row-paging">Showing 1 to
			  {{data.length}} of {{data.length}} {{pageTitle}}</p>
		  </div>
	</div>







