import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonAlertingService } from './alerting.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataDeliveryAlertService extends CommonAlertingService {
  constructor(http: HttpClient) {
    super(http)
    this.setBaseUrl(environment.apiUrl.datadelivery)
  }
}