import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import {
  INotification,
  INotificationEmailTemplates,
} from 'src/app/common/models/notifications';
import { NotificationsService } from 'src/app/common/services/notifications.service';
import { permissions as adminPermissions } from 'src/app/features/administration/integration/administration.permissions';
import { permissions as configPermissions } from 'src/app/features/configuration/integration/configuration.permissions';
import { permissions as dataDeliveryPermissions } from 'src/app/features/datadelivery/integration/datadelivery.permissions';
import { permissions as endpointDataDeliveryPermissions } from 'src/app/features/endpointdatadelivery/integration/endpointdatadelivery.permissions';
import { permissions as sendToTeledynePermissions } from 'src/app/features/sendtoteledyne/integration/sendtoteledyne.permissions';
import { SecurityUserService } from 'src/app/security/services/security-user.service';

export type TPermissions =
  | typeof adminPermissions
  | typeof configPermissions
  | typeof dataDeliveryPermissions
  | typeof endpointDataDeliveryPermissions
  | typeof sendToTeledynePermissions;

@Injectable()
export abstract class NotificationsBaseComponent implements OnInit {
  notifications: INotification[] = [];
  selectedNotification: number | null = null;
  currentOpenNotification: INotification;
  emailTemplates: INotificationEmailTemplates = {
    DDSFailuresAlert: { alert_id: 1, template: '' },
    DDSNoFlightsAlert: { alert_id: 2, template: '' },
    DDSNoConnectionToDPMAlert: { alert_id: 3, template: '' },
    EndpointDDSNoConnectionToDPMAlert: { alert_id: 4, template: '' },
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private realUserService: SecurityUserService,
    private notificationsService: NotificationsService,
    private http: HttpClient
  ) {}

  ngOnInit() {}

  shouldUserAccessNotificationsPage(permissions: TPermissions) {
    return this.realUserService.userHasPermission(
      permissions.alert_notifications.view
    );
  }

  getEmailTemplates() {
    const templateRequests = {
      DDSNoFlightsAlert: this.http.get(
        'assets/templates/notifications/SES_DDSNoFlightsAlert_Template.html',
        { responseType: 'text' }
      ),
      DDSNoConnectionToDPMAlert: this.http.get(
        'assets/templates/notifications/SES_DDSNoConnectionToDPMAlert_Template.html',
        { responseType: 'text' }
      ),
      DDSFailuresAlert: this.http.get(
        'assets/templates/notifications/SES_DDSFailuresAlert_Template.html',
        { responseType: 'text' }
      ),
      EndpointDDSNoConnectionToDPMAlert: this.http.get(
        'assets/templates/notifications/SES_EndpointDDSNoConnectionToDPMAlert_Template.html',
        { responseType: 'text' }
      ),
    };
    return templateRequests;
  }

  loadAppSpecificNotifications(appId: number) {
    forkJoin({
      notifications:
        this.notificationsService.getAppSpecificNotifications(appId),
      DDSNoFlightsAlertTemplate: this.getEmailTemplates().DDSNoFlightsAlert,
      DDSNoConnectionToDPMAlertTemplate:
        this.getEmailTemplates().DDSNoConnectionToDPMAlert,
      DDSFailuresAlertTemplate: this.getEmailTemplates().DDSFailuresAlert,
      EndpointDDSNoConnectionToDPMAlertTemplate:
        this.getEmailTemplates().EndpointDDSNoConnectionToDPMAlert,
    }).subscribe((results) => {
      this.notifications = results.notifications;
      this.emailTemplates.DDSFailuresAlert.template =
        results.DDSFailuresAlertTemplate;
      this.emailTemplates.DDSNoConnectionToDPMAlert.template =
        results.DDSNoConnectionToDPMAlertTemplate;
      this.emailTemplates.DDSNoFlightsAlert.template =
        results.DDSNoFlightsAlertTemplate;
      this.emailTemplates.EndpointDDSNoConnectionToDPMAlert.template =
        results.EndpointDDSNoConnectionToDPMAlertTemplate;
      this.handleQueryParams();
    });
  }

  handleQueryParams() {
    this.route.queryParams.subscribe((params) => {
      const selectedNotificationId = (() => {
        try {
          return parseInt(params['selectedNotification']);
        } catch (e) {}
      })();
      const notificationExists = this.notifications.find(
        ({ id }) => id == selectedNotificationId
      );
      if (notificationExists) {
        this.currentOpenNotification = notificationExists;
      } else {
        this.currentOpenNotification = this.notifications[0];
        if (this.currentOpenNotification?.id) {
          this.router.navigate([], {
            queryParams: {
              selectedNotification: this.currentOpenNotification.id,
            },
            queryParamsHandling: 'merge',
          });
        }
      }
    });
  }
}
