<p-blockUI [blocked]="loadingCount > 0">
  <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<p-confirmDialog></p-confirmDialog>

<div *ngIf="recorderConfigModel" class="col-12 card p-3">
  <form #recorderConfigForm="ngForm" (ngSubmit)="onSubmit();" novalidate>
    <div class="card-header m-2">
      <h5>{{pageTitle}}</h5>
    </div>
    <div class="card">
      <h6>Details</h6>
      <div class="formgrid grid">
        <div class="field col-12 md:col-6 lg:col-6">
          <div class="p-float-label">
            <input #RecorderConfigNameID="ngModel" [(ngModel)]="recorderConfigModel.name"
              [disabled]="isReadonly" [style]="{'width':'100%'}" required pattern=".*\S.*"
              [textContent]="recorderConfigModel.name" name="recorderRegexName" pInputText required type="text" />
            <label for="engineTypeName">Name</label>
          </div>
          <div class="tdy-required"
            *ngIf="(RecorderConfigNameID.touched || RecorderConfigNameID.dirty) && RecorderConfigNameID.hasError('required')">
            Name is required.
          </div>
          <div *ngIf="RecorderConfigNameID.hasError('pattern')" class="tdy-required">
            Name cannot be blank
        </div>
        </div>
        <div class="field col-12  lg:col-6">
          <span class="p-float-label">
            <p-dropdown #recorderTypeID="ngModel" [options]="recorderType" name="recorderTypeID" id="recorderTypeID"
              optionLabel="type" optionValue="recordertype_id" [autoDisplayFirst]="false"
              [(ngModel)]="recorderConfigModel.recordertype_id" [disabled]="isReadonly"
              (onChange)="onChangeRecorderType($event)" [required]=true [style]="{'width': '100%'}" appendTo="body">
            </p-dropdown>
            <label for="recorderTypeID">Recorder Type</label>
          </span>
          <div class="tdy-required"
            *ngIf="(recorderTypeID.touched || recorderTypeID.dirty) && recorderTypeID.hasError('required')">
            Recorder Type is required.
          </div>
        </div>
        <div class="field col-12  lg:col-6">
          <span class="p-float-label">
            <p-dropdown #formatID="ngModel" [options]="format" name="formatID" id="formatID" optionLabel="name"
              optionValue="recorderformat_id" [autoDisplayFirst]="false"
              [(ngModel)]="recorderConfigModel.recorderformat_id" [disabled]="isReadonly"
              (onChange)="onChangeFormat($event)" [required]=true [style]="{'width': '100%'}" appendTo="body">
            </p-dropdown>
            <label for="formatID">Format</label>
          </span>
          <div class="tdy-required" *ngIf="(formatID.touched || formatID.dirty) && formatID.hasError('required')">
            Format is required.
          </div>
        </div>
        <div class="field col-12 lg:col-6">
          <span class="p-float-label">
            <p-dropdown #subFrameSizeID="ngModel" [options]="subFrameSize" name="subFrameSizeID" id="subFrameSizeID"
              optionValue="subframesize_id" optionLabel="name" [autoDisplayFirst]="false"
              [(ngModel)]="recorderConfigModel.subframesize_id" [disabled]="isReadonly"
              [style]="{'width': '100%'}" [required]=true appendTo="body">
            </p-dropdown>
            <label for="subFrameSizeID">Subframe Size</label>
          </span>
          <div class="tdy-required"
            *ngIf="(subFrameSizeID.touched || subFrameSizeID.dirty) && subFrameSizeID.hasError('required')">
            Subframe is required.
          </div>
        </div>
        <div *ngIf="showDataPacking" class="field col-12 md:col-6 lg:col-6">
          <span class="p-float-label">
            <p-dropdown #dataPackingID="ngModel" [options]="dataPacking" name="dataPackingID" id="dataPackingID"
              optionValue="datapacking_id" optionLabel="datapacking_type" [autoDisplayFirst]="false"
              [(ngModel)]="recorderConfigModel.datapacking_id" [disabled]="isReadonly"
              [style]="{'width': '100%'}" [required]=true appendTo="body">
            </p-dropdown>
            <label for="dataPackingID">Data Packing</label>
          </span>
          <div class="tdy-required"
            *ngIf="(dataPackingID.touched || dataPackingID.dirty) && dataPackingID.hasError('required')">
            Data Packing is required.
          </div>
        </div>
        <div *ngIf="showBlockMode" class="field col-12 md:col-6 lg:col-6">
          <span class="p-float-label">
            <p-dropdown [options]="blockMode" name="blockModeID" id="blockModeID" optionLabel="blockmode_name"
              optionValue="blockmode_id" [autoDisplayFirst]="false" #blockModeID="ngModel"
              [(ngModel)]="recorderConfigModel.blockmode_id" [disabled]="isReadonly"
              [required]=true [style]="{'width': '100%'}" appendTo="body"></p-dropdown>
            <label for="blockModeID">Block Mode</label>
          </span>
          <div class="tdy-required"
            *ngIf="(blockModeID.touched || blockModeID.dirty) && blockModeID.hasError('required')">
            Block Mode is required.
          </div>
        </div>

        <div class="field col-12 md:col-6 lg:col-6">
          <span class="p-float-label">
            <p-dropdown [options]="miniFrames" name="miniFramesID" id="miniFramesID" optionLabel="miniframe_name"
              optionValue="miniframe_id" [autoDisplayFirst]="false" #miniFramesID="ngModel"
              [(ngModel)]="recorderConfigModel.miniframe_id" [disabled]="isReadonly"
              [required]=true [style]="{'width': '100%'}" appendTo="body"></p-dropdown>
            <label for="miniFramesID">Miniframes</label>
          </span>
          <div class="tdy-required"
            *ngIf="(miniFramesID.touched || miniFramesID.dirty) && miniFramesID.hasError('required')">
            Miniframes is required.
          </div>
        </div>

        <div class="field col-12 md:col-6 lg:col-6">
          <span class="p-float-label" >
            <p-inputNumber [(ngModel)]="recorderConfigModel.data_level"  [disabled]="isReadonly" name="dataLevel" id="dataLevelID" mode="decimal" [showButtons]="true" inputId="minmax-buttons" [min]="0" [max]="100"
            #dataLevelID="ngModel" appendTo="body" [style]="{'width':'100%'}"
            >
            </p-inputNumber>
            <label for="miniFramesID">Data Level</label>
          </span>
          <div class="tdy-required"
            *ngIf="(dataLevelID.touched || dataLevelID.dirty) && dataLevelID.hasError('required')">
            Data Level is required.
          </div>
        </div>
      </div>
    </div>



      <div class="card">
        <h6>Regex Selection</h6>
        <div class="field">
          <p-pickList [source]="availableRegexes" [target]="recorderConfigModel.regexes" id="regexID"
            sourceHeader="Available Regex(es)" targetHeader="Selected Regex(es)" [showSourceControls]="false"
            [showTargetControls]="false" [responsive]="true" [sourceStyle]="{'height':'200px'}"
            (onMoveToTarget)="onMoveToTarget($event)" (onMoveToSource)="onMoveToSource($event)
        " (onMoveAllToSource)="onMoveAllToSource($event)" (onMoveAllToTarget)="onMoveAllToTarget($event)" [disabled]="isReadonly"
        [targetStyle]="{'height':'200px'}">
            <ng-template let-regex pTemplate="item">
              <ng-container *ngIf="toRecorderRegexes(regex)">
                <div>
                  <div>{{regex.regex_name}}</div>
                </div>
              </ng-container>
            </ng-template>
          </p-pickList>
          <div class="col-5 p-0"> 
          <div class="tdy-required"
            *ngIf="(isNew? selectedRegexIDs.length < 1 : selectedRegexIDs.length < 1 && selectedRegexIDs.length != null  )">
            At least 1 Regex is required.
          </div>
        </div>
        </div>
      </div>

    <div class="flex justify-content-end">
      <span class="p-input-icon-left">
        <button (click)="onCancel()" class="p-button-text" icon="pi pi-times" id="cancelButton" label="Cancel" pButton
          pRipple type="button"></button>
      </span>
      <span class="p-input-icon-left p-button-text" style="float: right">
        <button *ngIf="showButtons" id="resetButton" (click)="onReset()" [disabled]="!recorderConfigForm.dirty && isButtonDisabled"
          icon="pi pi-undo" label="Reset" class="p-button-text" pButton pRipple type="button"></button>
      </span>
      <span class="p-input-icon-left ml-3" style="float: right">
        <button *ngIf="showButtons"
        [disabled]="(isNew ?(!recorderConfigForm.valid || recorderConfigModel.regexes.length < 1): !recorderConfigForm.dirty && isButtonDisabled)"
          icon="pi pi-check" id="submitButton" label="Save" pButton pRipple type="submit"></button>
      </span>
    </div>
  </form>
</div>