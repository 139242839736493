export class SendtoTdyFlightDataRedactions {
  redaction_id: number;
  name: string;
  description: string;
  textualoutput: boolean;
  mapjson?: string;
  defaultparametervaluesconfig?: string;
  dataframe_id: number;
  organization_id: number;
  dataframename: string;
}

export interface ResponseFlightDataRedaction {
  redaction_id: number;
  redaction_name: string;
  redaction_description?: string;
  redaction_textualoutput?: boolean;
  redaction_mapjson?: string;
  redaction_defaultparametervaluesconfig: string;
  redaction_dataframe_id: number;
  organization_id: number;
}

export interface ConvertType {
  conversion_id: Number;
  conversion_type: String;
}
export interface DataFrameType {
  dataframe_id?: number;
  dataframe_type: string;
}
export class FilePathType {
  filename: string;
  fileContent: string;
}

export interface RedactionType {
  redactiontype_id: number;
  name: string;
}

export interface ZipTypes {
  ziptype_id: number;
  ziptype: string;
}

export const GetErrorMessage={
  Matching: "No matching parameters found ",
  Duplicates: "Found duplicate parameters ",
  Selected:"Selected parameters are not matching ",
  DuplicateandMatching: "Parameters has duplicate and unmatched "
}