<div class="flex justify-content-end">
  <button
    (click)="onCancelClick()"
    *ngIf="canView && !loggedIn"
    id="cancelButton"
    name="cancelButton"
    type="button"
    class="p-button-text p-mr-2"
    pButton
    pRipple
    icon="pi pi-times"
    label="Cancel"
  ></button>
  <button
    (click)="onResetClick()"
    *ngIf="canEdit || canCustomize"
    [disabled]="!(userForm.dirty || orgsChanged || avatarChanged)"
    id="resetButton"
    name="resetButton"
    type="button"
    class="p-button-text p-mr-2"
    pButton
    pRipple
    icon="pi pi-undo"
    label="Reset"
  ></button>
  <button
    id="saveButton"
    name="saveButton"
    type="submit"
    *ngIf="canEdit || canCustomize"
    [disabled]="checkValidations(userForm)"
    pButton
    pRipple
    icon="pi pi-check"
    label="Save"
  ></button>
</div>
