export const hasOwnPropertyCheck = (value, text: string): boolean => {
  if(value === undefined) return false
  return Object.prototype.hasOwnProperty.call(value, text);
};

/**
 *
 * @param obj
 * @param propertyPath
 * @returns the value of the property from the object with the 'propertyPath'
 * Usage example: myObj = { a: { b: { c: { d: 'hello' }}}}
 * getProp(myObj, 'a.b.c.d') => will return the value of 'hello'.
 * In case propertyPath does not exist, it will return null
 */
export const getProp = (object, path, defaultValue?) => path
.split('.')
.reduce((o, p) => o ? o[p] : defaultValue, object)

export const isNumber = (val): boolean => {
  return typeof val === 'number';
}

export const isString = (val): boolean => {
  return typeof val === 'string';
}
export const isInteger = (val): boolean => {
  return Number.isInteger(val);
}

export const isNotEmptyString = (val): boolean => {
  return val != "";
}
