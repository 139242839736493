export class EnhancedRedactions {
    redaction_id?: number;
    name: string;
    description: string;
    textualoutput: boolean;
    mapjson?: string;
    defaultparametervaluesconfig?: string;
    dataframe_id: number;
    redactiontype_id: number;
    organization_id: number;
    ziptype: string;
    dataframename:string;
  }
  
  export interface ResponseEnhancedRedaction {
    redaction_id: number;
    redaction_name: string
    redaction_description?: string;
    redaction_textualoutput?: boolean
    redaction_mapjson?: string;
    redaction_defaultparametervaluesconfig: string;
    redaction_dataframe_id: number;
    redaction_redactiontype_id: number;
    organization_id: number;
    enhanced:boolean;
  }