export class ClientApplication {
    id?: number;
    Name: string;
    ClientId?: string;
    ClientSecret: any;
    IsActive?: boolean;
    organization_id?: number;
    date_of_generation?: any;
}

export interface ResponseClientApplication {
    name: string;
    organization_id?: number;
    client_authentication_id: number;
}

export interface ResponseClientStatus {
    client_auth_id: number
}