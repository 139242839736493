export class AircraftModel {
  aircraftmodel_id: number;
  aircraftmodel_model: string;
  aircraftmodel_sub_model: string;  
  airframer_id: number;
  engine_count: number;
  displayName?: string;
}

export class AircraftModelFilter {
  aircraftmodel: string;
  aircraftmodel_id: number;
}
