import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { BaseService } from '../../../common/services/base.service';
import { catchError, map } from 'rxjs/operators';
import { environment } from "src/environments/environment";
import { ACMS, ResponseACMS } from "../models/ACMS";
@Injectable({
  providedIn: 'root'
})
export class ACMSRedactionServive extends BaseService {

  constructor(
    public http: HttpClient) {
    super();
    this.baseUrl = environment.apiUrl.datadelivery;
  }

  getACMSRedactions(): Observable<ACMS[]> {
    let url = this.baseUrl + "acmsredaction";
    return this.http.get<ACMS[]>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError)
      );
  }

  getACMSRedaction_by_id(redaction_id: number): Observable<ACMS> {
    let url = this.baseUrl + "acmsredaction" + `/${redaction_id}` ;
    return this.http.get<ACMS>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError)
      );
  }

  addACMSRedaction(acmsredaction: ResponseACMS) {
    const url = this.baseUrl + 'acmsredaction';
    return this.http.post<number>(url, acmsredaction, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError)
      )
  }

  updateACMSRedaction(acmsredaction: ResponseACMS) {
    const url = this.baseUrl + 'acmsredaction';
    return this.http.put<number>(url, acmsredaction, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError)
      )
  }

  public deleteACMSRedaction(redaction_id: number) {
    const url = this.baseUrl + 'flightdataredaction/' + `${redaction_id}`;
    const options = { headers: this.httpGetHeadersForJSON() }
    return this.http.delete(url, options)
      .pipe(catchError(this.httpHandleError));
  }

}