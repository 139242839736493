<p-blockUI [blocked]="loadingCount > 0">
  <tdy-spinner
    style="position: absolute; left: 50%; top: 50%; z-index: 10000"
  ></tdy-spinner>
</p-blockUI>
<div class="col-12 card p-3">
  <div class="card-header m-2">
    <h5>{{ pageTitle }}</h5>
  </div>
  <div *ngIf="clientpackage" class="col-12 card">
    <app-clientpackage-form
      [clientpackage]="clientpackage"
      [conversionType]="conversionType"
      [redactionType]="redactionType"
      [zipType]="zipType"
      [availableEndPoints]="availableEndPoints"
      [selectedEndPointsIDs]="selectedEndPointsIDs"
      [availableAircraft]="availableAircraft"
      [aircraftGroups]="aircraftGroups"
      [targetAircraft]="targetAircraft"
      [selectedTargetAircraftGroup]="selectedTargetAircraftGroup"
      [showButtons]="showButtons"
      [selectedRedactionId]="selectedRedactionId"
      [isNew]="false"
      [isReadonly]="true"
      (cancelForm)="onCancel()"
      (resetForm)="onReset()"
    ></app-clientpackage-form>
  </div>
</div>
