<p-blockUI [blocked]="loadingCount > 0">
    <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<div class="card" *ngIf="loadingCount==0">
    <div class="card-header m-2">
        <h5>{{pageTitle}}</h5>
        <p-dropdown [options]="file_dates" [(ngModel)]="selected_date" optionLabel="dte" [filter]="true"
        (onChange)="onChange($event)" tooltip="Filter by AIRAC date" filterBy="dte" [showClear]="false" placeholder="Select a date">
        <ng-template pTemplate="selectedItem">
          <div *ngIf="selected_date">

            <div>{{selected_date.dte}}</div>
          </div>
        </ng-template>
        <ng-template let-filedate pTemplate="item">
          <div class="country-item">

            <div>{{filedate.dte}}</div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>

    <div class="card">
        <h6>Details</h6>
        <div class="grid" *ngIf="data">
            <div class="col"><span>NDB Identifier: </span></div>
            <div class="col">{{data.ndb_identifier || "-"}}</div>
            <div class="col"><span>Class: </span></div>
            <div class="col">{{data.ndb_class || "-"}}</div>
        </div>
        <div class="grid" *ngIf="data">
            <div class="col"><span>Name: </span></div>
            <div class="col">{{data.name || "-"}}</div>
            <div class="col"><span>Cycle Date: </span></div>
            <div class="col">>{{data.cycle_date || "-"}}</div>
        </div>
        <div class="grid" *ngIf="data">
            <div class="col"><span>Frequency: </span></div>
            <div class="col">{{data.frequency || "-"}}</div>
        </div>
    </div>


    <div class="card">
        <h6>Position</h6>
        <div class="grid" *ngIf="data">
            <div class="col"><span>Airport ICAO Identifier: </span></div>
            <div class="col">{{data.airport_icao_identifier || "-"}}</div>
            <div class="col"><span>NDB Latitude: </span></div>
            <div class="col">{{data.ndb_latitude || "-"}}</div>
        </div>
        <div class="grid" *ngIf="data">
            <div class="col"><span>Magnetic Variation: </span></div>
            <div class="col">{{data.magnetic_variation || "-"}}</div>
            <div class="col"><span>NDB Longitude: </span></div>
            <div class="col">{{data.ndb_longitude || "-"}}</div>
        </div>
    </div>
         <ng-template pTemplate="emptymessage">
           <tr >
               <td colspan="5">No enroute NDB navaid found.</td>
           </tr>
       </ng-template>
</div>
