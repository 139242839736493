import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsBaseComponent } from 'src/app/common/components/common/notifications-page/notifications-base-component/notifications-base.component';
import { MenuService } from 'src/app/common/services/menu.service';
import { NotificationsService } from 'src/app/common/services/notifications.service';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { permissions } from '../../integration/configuration.permissions';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-notifications-page-view',
  templateUrl: './notifications-view.component.html',
})

export class NotificationsViewForConfigurationComponent
  extends NotificationsBaseComponent
  implements OnInit
{
  constructor(
    router: Router,
    route: ActivatedRoute,
    notificationsService: NotificationsService,
    realUserService: SecurityUserService,
    httpClient: HttpClient,
  ) {
    super(router, route, realUserService, notificationsService, httpClient);
  }

  override ngOnInit(): void {
    const isAuthorized = this.shouldUserAccessNotificationsPage(permissions);
    if (isAuthorized) {
      this.loadAppSpecificNotifications(2);
    } else {
      this['router'].navigate([MenuService.AccessDenied.Link]).then();
    }
  }
}
