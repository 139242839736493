import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UppercaseDirective} from './directives/uppercase.directive';
import {FileSizeFormatPipe} from './pipes/file-size-format.pipe';
import {DateFormatPipe} from './pipes/date-format.pipe';
import {SpinnerComponent} from './components/spinner/spinner.component';
import { TrimSpacesGlobalDirective } from './directives/trim-spaces-global.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [UppercaseDirective, FileSizeFormatPipe, DateFormatPipe, SpinnerComponent,TrimSpacesGlobalDirective],
  exports: [UppercaseDirective, FileSizeFormatPipe, DateFormatPipe, SpinnerComponent, TrimSpacesGlobalDirective],
  providers: [UppercaseDirective, FileSizeFormatPipe, DateFormatPipe, SpinnerComponent]
})
export class SharedModule {
}
