export class Constants {
  public static readonly TdyAirlineID = 100;

  public static readonly OneMegabyte = 1048576;
  public static readonly FiveMegabyte = 5 * 1048576;
  public static readonly TenMegabytes = 10 * 1048576;

  // One day, measured in milliseconds.
  public static readonly OneDayInMilliseconds = 24 * 60 * 60 * 1000;

  // One hour, measured in milliseconds.
  public static readonly OneHourInMilliseconds = 60 * 60 * 1000;

  // One minute, measured in milliseconds.
  public static readonly OneMinuteInMilliseconds = 60 * 1000;

  public static readonly UnassignedText = '* Unassigned';  // Text can be used when a device does not have a tail, fleet, or location, etc.

}
