import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BlockUIModule } from 'primeng/blockui';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessagesModule } from 'primeng/messages';
import { RippleModule } from 'primeng/ripple';
import { TableModule } from 'primeng/table';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { AccordionModule } from 'primeng/accordion';
import { SplitterModule } from 'primeng/splitter';
import { SharedModule } from '../../../../platform/shared.module';
import { TooltipModule } from 'primeng/tooltip';
import { CommonNotificationsPageComponent } from './notifications-page.component';
import { CommonNotificationsPageRoutingModule } from './notifications-page.routing.module';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { CheckboxModule } from 'primeng/checkbox';
import { MenuModule } from 'primeng/menu';
import { TagModule } from 'primeng/tag';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { NotificationsPageLeftPane } from './left-pane/left-pane.component';
import { NotificationsPageRightPane } from './right-pane/right-pane.component';

@NgModule({
  declarations: [
    CommonNotificationsPageComponent,
    NotificationsPageLeftPane,
    NotificationsPageRightPane,
  ],
  exports: [
    CommonNotificationsPageComponent,
    NotificationsPageLeftPane,
    NotificationsPageRightPane,
  ],
  imports: [
    CommonModule,
    FormsModule,
    CommonNotificationsPageRoutingModule,
    DialogModule,
    BlockUIModule,
    ButtonModule,
    ToggleButtonModule,
    AccordionModule,
    SplitterModule,
    DropdownModule,
    ConfirmDialogModule,
    TableModule,
    SharedModule,
    InputTextModule,
    RippleModule,
    InputTextareaModule,
    MessagesModule,
    TooltipModule,
    ScrollPanelModule,
    CheckboxModule,
    MenuModule,
    TagModule,
    OverlayPanelModule,
  ],
})
export class NotificationsPageModule {}
