<div class="card">
  <div>
    <div class="table-header mb-3 {{userCanManage?'':'readonly'}}">
        <h6 class="title">Frame Details</h6>
        <div class="flex justify-content-end"  *ngIf="userCanManage">
            <button id="frame_details_add" type="button" label="Add Frame" pButton pRipple
                icon="pi pi-plus" (click)="addFrameDetailsGroupRow()"></button>
        </div>
    </div>
    <p-table #dataTableComponent [value]="dataparameter" [responsive]="true" [sortOrder]="1" sortField="Frame_ID"
        [selectionMode]='!userCanManage ? "" : "single"' (onEditComplete)="onCellEdit()">
        <ng-template pTemplate="header">
            <tr>
                <th class="text-center">Frame ID</th>
                <th class="text-center">Frame Title</th>
                <th class="text-center">Frame Type</th>
                <th class="text-center">Recording Phase</th>
                <th class="text-center">Event Condition</th>
                <th class="text-center">Recording Rate</th>
                <th *ngIf="userCanManage" class="text-center action-column"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-control let-editing="editing" let-rowIndex="rowIndex" [editable]="userCanManage" [pEditableRowDisabled]="!userCanManage">
            <tr>
                <td pEditableColumn [pEditableColumnDisabled]="!userCanManage" class="text-center">
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input  [ngModelOptions]="{standalone: true}" id="Frame_ID" type="number" pInputText
                                #Frame_ID="ngModel" (input)="checkFrameID(control.Frame_ID, index)"
                                [(ngModel)]="control.Frame_ID" [pKeyFilter]="'int'" min="1" required
                                [style]="{'width': '100%'}" [disabled]="!userCanManage" />
                            <div class="tdy-required"
                                *ngIf="(Frame_ID.touched || Frame_ID.dirty && !control.Frame_ID) && Frame_ID.hasError('required')">
                                Frame ID is required.
                            </div>
                            <div class="tdy-required"
                                *ngIf="(control.Frame_ID < 0 || Frame_ID.hasError('min')) && control.Frame_ID">
                                Frame ID should be greater than or equal to 1.
                            </div>
                            <div *ngFor="let index of frameIndex">
                                <div class="tdy-required" *ngIf="index == rowIndex && control.Frame_ID">
                                    Frame ID should be unique.
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{control.Frame_ID}}
                            <div class="tdy-required" *ngIf="!control.Frame_ID && control.Frame_ID != 0 ">
                                Frame ID is required.
                            </div>
                            <div *ngFor="let Intindex of isIntegerIndex">
                                <div class="tdy-required" *ngIf="Intindex == rowIndex && control.Frame_ID">
                                    Frame ID should be an integer.
                                </div>
                            </div>
                            <div *ngFor="let Numindex of isNumberIndex">
                                <div class="tdy-required" *ngIf="Numindex == rowIndex && control.Frame_ID">
                                    Frame ID should be number.
                                </div>
                            </div>
                            <div class="tdy-required" *ngIf="control.Frame_ID < 0 && control.Frame_ID">
                                Frame ID be greater than or equal to 1.
                            </div>
                            <div class="tdy-required" *ngIf="frameIDBool && frameIndex == rowIndex" >
                                Frame ID should be unique.
                            </div>
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td pEditableColumn [pEditableColumnDisabled]="!userCanManage" class="text-center">
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input  [ngModelOptions]="{standalone: true}" id="Frame_Title" fomControlName="Frame_Title" type="text" pInputText
                                #Frame_Title="ngModel" [(ngModel)]="control.Frame_Title" required
                                pattern=".*\S.*" [style]="{'width': '100%'}" [disabled]="!userCanManage" />
                            <div class="tdy-required"
                                *ngIf="(Frame_Title.touched || Frame_Title.dirty || !control.Frame_Title ) && Frame_Title.hasError('required')">
                                Frame Title is required.
                            </div>
                            <div class="tdy-required" *ngIf="Frame_Title.hasError('pattern')">
                                Frame Title cannot be left blank.
                            </div>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ control.Frame_Title }}
                            <div class="tdy-required" *ngIf="!control.Frame_Title">
                                Frame Title is required.
                            </div>
                            <div *ngFor="let Strindex of isStringIndex">
                                <div class="tdy-required" *ngIf="Strindex == rowIndex">
                                    Frame Title should be string.
                                </div>
                            </div>
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td pEditableColumn [pEditableColumnDisabled]="!userCanManage" class="text-center">
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <p-dropdown [ngModelOptions]="{standalone: true}" id="Frame_Type" fomControlName="Frame_Type" [required]="true"
                                (onChange)="onSubFrameChange($event,rowIndex,control.Frame_ID)"
                                [options]="SubFrameArray" [(ngModel)]="control.Frame_Type"
                                #Frame_Type="ngModel" [style]="{'width':'100%'}" [showClear]="false"
                                [autoDisplayFirst]="false" [disabled]="!userCanManage">
                            </p-dropdown>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{control.Frame_Type}}
                            <div class="tdy-required" *ngIf="!control.Frame_Type">
                                Frame Type is required.
                            </div>
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td pEditableColumn [pEditableColumnDisabled]="!userCanManage" class="text-center">
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input  [ngModelOptions]="{standalone: true}"
                                [disabled]="!userCanManage || (!isDxs && (control.Frame_Type === 'Event'))"
                                id="Recording_Phase " fomControlName="Recording_Phase" type="number" pInputText
                                 pTooltip="Integer value"
                                (input)="checkRecording(control.Recording_Phase,rowIndex)"
                                #Recording_Phase="ngModel" [(ngModel)]="control.Recording_Phase" min="0"
                                [pKeyFilter]="'int'" required [style]="{'width': '100%'}" />
                            <div class="tdy-required"
                                *ngIf="( Recording_Phase.dirty  || Recording_Phase.touched ) && Recording_Phase.hasError('required')">
                                Recording Phase is required.
                            </div>
                            <div class="tdy-required"
                                *ngIf="(recordingBool === true && Rindex === rowIndex)">
                                Recording Phase cannot be left blank.
                            </div>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ control.Recording_Phase }}

                            <div class="tdy-required"
                                *ngIf=" (recordingBool === true && Rindex === rowIndex)">
                                Recording Phase is required.
                            </div>
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td pEditableColumn [pEditableColumnDisabled]="!userCanManage" class="text-center">
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input  [ngModelOptions]="{standalone: true}"
                                [disabled]="control.Frame_Type === 'Scheduled' || !userCanManage"
                                (keyup)="onCheckEvent()" id="Event_Condition" fomControlName="Event_Condition"
                                type="text" pInputText #Event_Condition="ngModel"
                                [ngModel]="control.Event_Condition"  (input)="checkEUCondition(control.Event_Condition)"
                                (ngModelChange)="control.Event_Condition = $event.toUpperCase()"
                                [style]="{'width': '100%','text-transform':'uppercase'}" />
                            <div class="tdy-required"
                                *ngIf="(Event_Condition.touched || Event_Condition.dirty || !control.Event_Condition) && Event_Condition.hasError('required')">
                                Event Condition is required.
                            </div>
                            <div class="tdy-required" *ngIf="(eventBool === true && index === rowIndex)">
                                Event Condition cannot be left blank.
                            </div>

                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ control.Event_Condition | uppercase }}
                            <div class="tdy-required" *ngIf="(eventBool === true && index === rowIndex)">
                                Event Condition is required.
                            </div>
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td pEditableColumn [pEditableColumnDisabled]="!userCanManage" class="text-center">
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input  [ngModelOptions]="{standalone: true}" [style]="{'width': '100%'}" id="Numerator"
                                pattern="^[1-9][0-9]*Hz$|^[1-9][0-9]*\/[1-9][0-9]*Hz$" fomControlName="Numerator"
                                pInputText #Numerator="ngModel" pTooltip="'Numerator/Denominator Hz' or 'Numerator Hz'" required
                                [(ngModel)]="control.Recording_Rate" (blur)="validateFrame()">
                            <div class="tdy-required" *ngIf="Numerator.hasError('pattern')">
                                Invalid Input.
                            </div>
                            <div class="tdy-required"
                                *ngIf="( Numerator.dirty  || Numerator.touched ) && Numerator.hasError('required')">
                                Recording Rate Numerator is required.
                            </div>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{control.Recording_Rate}}
                            <div class="tdy-required" *ngIf="!control.Recording_Rate">
                                Recording Rate is required.
                            </div>
                            <div *ngFor="let pattindex of isPatternIndex">
                                <div class="tdy-required" *ngIf="pattindex == rowIndex">
                                    Invalid Value. {{control.Recording_Rate}} {{isPatternIndex|json}}
                                </div>
                            </div>
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td *ngIf="userCanManage" class="text-center">
                    <div class="flex justify-content-center p-0">
                        <button type="button" id="showDlg_Button" class="p-button-rounded p-button-tex p-button-danger" pButton icon="pi pi-trash"
                            pRipple pTooltip="Delete..." (click)="onDeleteFrameDetailsGroupRow(control)"></button>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
  </div>
</div>
