<form #SARFormData="ngForm" (ngSubmit)="onSubmit()" novalidate>
  <div class="formgrid grid">
    <div class="lg:col-6">
      <span class="p-float-label">
        <input
          [disabled]="isReadonly"
          pattern=".*\S.*"
          type="text"
          class="form-control"
          [style]="{ width: '100%' }"
          pInputText
          id="name"
          name="name"
          [(ngModel)]="SARredaction.name"
          required
          #name="ngModel"
          maxlength="50"
        />
        <label for="name">Name</label>
        <div
          class="tdy-required"
          *ngIf="(name.touched || name.dirty) && name.hasError('required')"
        >
          Name is required.
        </div>
        <div class="tdy-required" *ngIf="name.hasError('pattern')">
          Name cannot be left blank.
        </div>
      </span>
    </div>

    <div class="lg:col-6">
      <span class="p-float-label">
        <input
          [disabled]="isReadonly"
          [style]="{ width: '100%' }"
          type="text"
          id="description"
          name="description"
          pInputText
          [(ngModel)]="SARredaction.description"
        />
        <label for="description">Description</label>
      </span>
    </div>

    <div class="lg:col-6">
      <span class="p-float-label">
        <p-dropdown
          [disabled]="isReadonly"
          autoWidth="false"
          [style]="{ width: '100%' }"
          optionLabel="conversion_type"
          optionValue="conversion_id"
          [options]="convtype"
          [autoDisplayFirst]="false"
          id="convtype"
          name="convtype"
          #conv_type="ngModel"
          [(ngModel)]="SARredaction.redactiontype_id"
          (onChange)="onConverionChange($event)"
          [required]="true"
        ></p-dropdown>
        <label for="convtype">Conversion Type</label>
        <div
          class="tdy-required"
          *ngIf="
            (conv_type.touched || conv_type.dirty) &&
            conv_type.hasError('required')
          "
        >
          Conversion Type is required.
        </div>
      </span>
    </div>

    <div class="lg:col-6">
      <span class="p-float-label">
        <p-dropdown
          [disabled]="isReadonly"
          autoWidth="false"
          [style]="{ width: '100%' }"
          optionLabel="SARMap_type"
          optionValue="SARMap_id"
          [options]="sarmap"
          id="sarmap"
          name="sarmap"
          #sarmap_type="ngModel"
          [(ngModel)]="SARredaction.sarmap_id"
          (onChange)="onSarMapChange($event)"
          [required]="true"
        ></p-dropdown>
        <label for="sarmap">SAR Map</label>
        <div
          class="tdy-required"
          *ngIf="
            (sarmap_type.touched || sarmap_type.dirty) &&
            sarmap_type.hasError('required')
          "
        >
          SAR Map is required.
        </div>
      </span>
    </div>
  </div>

  <h6 class="mt-5">SAR Channel Selection</h6>
  <div class="field">
    <p-pickList
      [source]="sarMapList"
      [target]="avialableSarMap"
      id="sarmap_id"
      sourceHeader="Available SAR Channel(s)"
      targetHeader="Selected SAR Channel(s)"
      [showSourceControls]="false"
      [showTargetControls]="false"
      [responsive]="true"
      [sourceStyle]="{ height: '200px' }"
      (onMoveToTarget)="onMoveToTarget($event)"
      (onMoveToSource)="onMoveToSource($event)"
      (onMoveAllToSource)="onMoveAllToSource($event)"
      (onMoveAllToTarget)="onMoveAllToTarget($event)"
      [disabled]="isReadonly"
      [targetStyle]="{ height: '200px' }"
    >
      <ng-template let-sarmap pTemplate="item">
        <ng-container>
          <div>
            <div>{{ sarmap.SARMap_type }}</div>
          </div>
        </ng-container>
      </ng-template>
    </p-pickList>
    <div
      class="tdy-required"
      *ngIf="
        isNew
          ? selectedSarMapIDs.length < 1
          : selectedSarMapIDs.length < 1 && selectedSarMapIDs.length != null
      "
    >
      At least 1 SAR Channel is required.
    </div>
  </div>

  <div class="flex justify-content-end">
    <span class="p-input-icon-left p-button-text" style="float: right">
      <button
        pButton
        pRipple
        label="Cancel"
        type="button"
        icon="pi pi-times"
        class="p-button-text"
        (click)="onCancel()"
      ></button>
    </span>
    <span class="p-input-icon-left">
      <button
        pButton
        pRipple
        label="Reset"
        [disabled]="!SARFormData.dirty && isButtonDisabled"
        type="button"
        icon="pi pi-refresh"
        class="p-button-text"
        *ngIf="showButtons"
        (click)="onReset()"
      ></button>
    </span>
    <span class="p-input-icon-left ml-3" style="float: right">
      <button
        pButton
        pRipple
        label="Save"
        icon="pi pi-check"
        type="submit"
        *ngIf="showButtons"
        [disabled]="
          isNew
            ? !SARFormData.valid || selectedSarMapIDs.length < 1
            : !((SARFormData.dirty || picklistChange) && SARFormData.valid) ||
              selectedSarMapIDs.length < 1
        "
      ></button>
    </span>
  </div>
</form>
