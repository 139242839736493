import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, NgForm, Validators} from '@angular/forms';
import {FormInputBase} from "../../input-model/input/form-input-base";
import {Euc, Eul, Lcp, Txd} from "../../../../models/dataframe717-767";

@Component({
  selector: 'dynamic-form',
  templateUrl: './dynamic-form.component.html',
})
export class DynamicFormComponent implements OnInit, OnChanges {
  greet: any;
  @Input() formFields: FormInputBase<string | boolean>[] | null = [];
  @Input() formData: any | undefined;
  @Input() isEditMode = false;
  @Input() isDetailsMode = false;
  @Input() pageTitle:string;
  @Input() formdata:any;
  @Input() userCanManageKey:string;
  @Input() userCanViewKey:string;
  @Input() colsdata: Lcp[] = [];
  @Input() euColsdata: Euc[] = [];
  @Input() euItems: Txd[] = [];
  @Input() disColsdata:  Eul[] = [];
  @Input() isSaveButtonDisabled = true;

  @Output() savedClick: EventEmitter<any> = new EventEmitter();
  @Output() onFormReset: EventEmitter<any> = new EventEmitter();
  @Output() onFormCancel: EventEmitter<any> = new EventEmitter();
  @Output() formGroup: EventEmitter<FormGroup> = new EventEmitter();
  @Output() onChange: EventEmitter<any> = new EventEmitter();

  filteredOptions: FormInputBase<string | boolean>[] | undefined = [];
  form: FormGroup;
  currentIndex = 0;
  formChanged = false;
  loadingCount:number;
  tableUpdated = false;
  SelectedEuType: any;
  userCanManage = false;
  userCanView = false;
  selectedValue: any;
  newRow = false;

  constructor(protected fb: FormBuilder) {}

  ngOnChanges(changes: SimpleChanges): void {
    const formChanges = changes['formData']?.currentValue;
    if(formChanges && Object.keys(formChanges).length != 0) {
      this.form.patchValue(formChanges);
      this.onChange.emit(this.form);
      this.formGroup.emit(this.form);
    }
  }

  ngOnInit(): void {
    this.loadingCount = 0;
    if (!this.formdata) {
      this.formdata = {};
    }
    this.toFormGroup();
    this.form.valueChanges.subscribe((val) => {
      if (this.form.pristine) {
        this.formChanged = false;
      } else {
        this.formChanged = true;
        this.onChange.emit(this.form);
      }
    });
    if (this.formData.length > 0) {
      this.form.patchValue(this.formData);
    }
  }

  getFormFields() {
    this.filteredOptions = this.formFields;
    return this.filteredOptions;
  }

  getIndex(index: number) {
    if (index !== this.currentIndex) {
      this.currentIndex = index + 1;
      return true;
    }
    return false;
  }

  getNext(int: number) {
    return int+1;
  }

  onSubmit(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) return;
  }

  private toFormGroup(): void {
    const group = {};
    this.formFields.forEach((field) => {
        group[field.key] = field.required
          ? new FormControl(field.value || '', [
            ...field.validators,
            Validators.required,
          ])
          : new FormControl(field.value || '', field.validators, field.asyncValidators);
    });
    this.form = new FormGroup(group);
    this.formGroup.emit(this.form);
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  formReset() {
    this.onFormReset.emit(this.form.value);
  }

  saveForm() {
    this.savedClick.emit(this.form.value);
  }

  OnSubmit(dataForm: NgForm) {
    return;
  }

  handleChangeEvent(event) {
    this.onChange.emit(event);
  }

  formCancel()
  {
    this.onFormCancel.emit();
  }
}
