import { Component, OnInit } from '@angular/core';
import { permissions } from '../../integration/datadelivery.permissions';
import { Components } from '../../integration/datadelivery.components';
import { ClientApplicationService } from '../../services/clientApplication.service';
import { environment } from 'src/environments/environment';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { Router } from '@angular/router';
import { MenuService } from 'src/app/common/services/menu.service';
import { DataDeliveryAlertService } from '../../services/DataDeliveryAlert.service';
import { CommonAlertingService } from '../../services/alerting.service';

@Component({
  selector: 'app-datadeliveryalertdetails',
  templateUrl: './alert-details.component.html',
})
export class DataDeliveryAlertDetailsComponent implements OnInit {
  permissions = permissions;
  tableViewLabel = Components.DataDeliveryAlert.label;
  tableViewPath = Components.DataDeliveryAlert.path;
  pageTitle = Components.DataDeliveryAlertDetails.label;
  detailsViewPath = Components.DataDeliveryAlertDetails.path;

  constructor(
    public alertsService: CommonAlertingService,
    public clientApplicationService: ClientApplicationService,
    private securityUserService: SecurityUserService,
    private router: Router
  ) {
    this.clientApplicationService.setBaseUrl(
      environment.apiUrl.datadelivery
    );
    this.alertsService.setBaseUrl(
      environment.apiUrl.datadelivery
    )
  }

  ngOnInit() {
    const isUserAuthorized = this.securityUserService.userHasPermission(
      permissions.recording_alert.view
    );
    if (!isUserAuthorized) {
      this.router.navigate([MenuService.AccessDenied.Link]).then();
    }
  }
}
