import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AircraftInterior } from '../models/aircraftInterior';
import { BaseService } from 'src/app/common/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class AircraftInteriorService extends BaseService {
  aircraftInteriorUrl = environment.apiUrl.admin + 'aircraft-interior-arrangement';

  constructor(private http: HttpClient) {
    super();
  }

  getAircraftInterior(aircraftInteriorID: number): Observable<AircraftInterior> {
    const url = this.aircraftInteriorUrl + `/${aircraftInteriorID}`;
    return this.http.get<AircraftInterior>(url, { headers: this.httpGetHeadersForJSON(true) })
      .pipe(catchError(this.httpHandleError));
  }

  listAircraftInteriors(): Observable<AircraftInterior[]> {
    return this.http.get<AircraftInterior[]>(this.aircraftInteriorUrl, { headers: this.httpGetHeadersForJSON(true) })
      .pipe(catchError(this.httpHandleError));
  }

  addOrUpdateAircraftInterior(aircraftInterior: AircraftInterior, drawingFile: string) {
    const httpHeaders = this.httpGetHeadersForJSON();

    if (drawingFile)
      aircraftInterior.aircraftinterior_drawing = drawingFile;
    else {
      aircraftInterior.aircraftinterior_drawing = null;
      aircraftInterior.aircraftinterior_drawing_file_name = null;
    }

    if (aircraftInterior.aircraftinterior_drawingnumber == undefined || aircraftInterior.aircraftinterior_drawingnumber == null)
      aircraftInterior.aircraftinterior_drawingnumber = null;

    console.log(aircraftInterior);
    if (aircraftInterior.aircraftinterior_id < 0) {

      return this.http.post<number>(this.aircraftInteriorUrl, aircraftInterior, { headers: httpHeaders })
        .pipe(catchError(this.httpHandleError));
    } else {
      const url = this.aircraftInteriorUrl + `/${aircraftInterior.aircraftinterior_id}`;
      return this.http.put<number>(url, aircraftInterior, { headers: httpHeaders })
        .pipe(catchError(this.httpHandleError));
    }
  }

  deleteAircraftInterior(aircraftInteriorID: number) {
    const url = this.aircraftInteriorUrl + `/${aircraftInteriorID}`;
    return this.http.delete<AircraftInterior>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError));
  }
}
