import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { BaseService } from "../../../common/services/base.service";
import { catchError, Observable} from "rxjs";
import {Procedure, Transition, TransitionStep} from "../models/procedure";

@Injectable({
    providedIn: 'root'
  })
  export class ProcedureService extends BaseService {
    constructor(private http: HttpClient) {
      super();
      this.baseUrl = environment.apiUrl.admin;
    }

    getDatesOfFiles(): Observable<string[]> {
      const url = environment.apiUrl.admin + "date-catalogue"
      return this.http.get<string[]>(url)
        .pipe(catchError(this.httpHandleError));
    }

    getProcedure(dte, procedure_id) {
      const url = `${this.baseUrl}procedure/${dte}/${procedure_id}`
      return this.http.get<Procedure>(url).pipe(catchError(this.httpHandleError));
    }

    getProcedures(dte, airport_icao_identifier, transition_identifier) {
      const url = `${this.baseUrl}procedure/${dte}/${airport_icao_identifier}/${transition_identifier}`
      return this.http.get<Procedure[]>(url).pipe(catchError(this.httpHandleError));
    }

    getTransitions(dte, procedure_id) {
      const url = `${this.baseUrl}transition/${dte}/${procedure_id}`
      return this.http.get<Transition[]>(url).pipe(catchError(this.httpHandleError));
    }

    getTransitionSteps(dte, transition_id) {
      const url = `${this.baseUrl}transition-step/${dte}/${transition_id}`
      return this.http.get<TransitionStep[]>(url).pipe(catchError(this.httpHandleError));
    }
}
