import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { UserInfo } from "../models/user-info.model";
import { SecurityUserService } from "../services/security-user.service";

@Injectable()
export class SpectrumApiInterceptor implements HttpInterceptor {

    private userInfo: UserInfo;
    constructor(
        userService: SecurityUserService
    ) { 
        userService.userInfo.subscribe(userInfo => this.userInfo = userInfo);
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let isApiRequest = false;
        for (let apiName in environment.apiUrl) {
            if (request.url.includes(environment.apiUrl[apiName])) {
                isApiRequest = true;
                break;
            }
        }

        if (isApiRequest && this.userInfo.isAuthenticated && !request.headers.has('Authorization')) {            
            const params = request.params;
            let headers = request.headers;
            headers = headers.set('Authorization', `Bearer ${this.userInfo.accessToken}`)
            request = request.clone({
                params,
                headers
            });
        }
        
        return next.handle(request);
    }
}