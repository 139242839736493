import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export enum Status {
  Fail = "Fail",
  Success = "Success",
  Cancel = "Cancel"
}

export interface StatusReport {
  status: Status;
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  // Subjects 
  private statusSub: Subject<StatusReport> = new Subject<StatusReport>();     // Subject to report the status of the actions performed by the parent

  constructor() {
    //
   }

  statusChange(status: StatusReport) {
    this.statusSub.next(status);
  }

  statusChange$(): Observable<StatusReport> {
    return this.statusSub.asObservable();
  }

}
