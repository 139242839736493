export class SAR {
  redaction_id?: number;
  name?: string;
  description?: string;
  textualoutput?: boolean;
  mapjson?: string;
  defaultparametervaluesconfig?: string;
  redactiontype_id?: number;
  sarmap_id?: number;
  organization_id?: number;
  conversiontype_name?: string;
}

export class ResponseSAR {
  redaction_id?: number;
  redaction_name?: string;
  redaction_description?: string;
  redaction_textualoutput?: boolean;
  redaction_mapjson?: string;
  redaction_defaultparametervaluesconfig?: string;
  redaction_redactiontype_id?: number;
  redaction_sarmap_id?: number;
  organization_id?: number;
  enhanced: boolean;
  receiver_organization_id?: number;
}

export class jsonValues {
  dataframe_id: number;
  dataframe_type: string;
}

export interface DataFrameType {
  dataframe_id: Number;
  dataframe_type: String;
}
export interface ConversionType {
  conversion_id: number;
  conversion_type: string;
}
export interface RedactionType {
  redaction_id: number;
  redaction_type: string;
}
export interface SARMap {
  SARMap_id?: number;
  SARMap_type: string;
}
export interface SARChannel {
  SARChannel_id: number;
  SARChannel_type: string;
}
