import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataDeliveryEndPointService } from '../../datadelivery/services/endpoint.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EndpointDataDeliveryEndPointService extends DataDeliveryEndPointService {
  constructor(
    public override http: HttpClient) {
    super(http);
    this.baseUrl = environment.apiUrl.endpointdatadelivery;
  }
}