import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { EngineType } from 'src/app/features/administration/models/engineType';
import { EngineTypeService } from 'src/app/features/administration/services/engine-type.service';
import { Location } from '@angular/common';
import { NgForm } from '@angular/forms';
import { Action, BaseViewComponent } from 'src/app/common/components/base-view.component';
import { takeUntil } from 'rxjs';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { permissions } from 'src/app/security/models/permissions';
import { Components } from '../../integration/administration.components';

@Component({
  selector: 'app-engine-type-details',
  templateUrl: './engine-type-details.component.html'
})
export class EngineTypeDetailsComponent extends BaseViewComponent implements OnInit {

  pageTitle = Components.EngineTypeDetails.label;
  engineType: EngineType;
  isReadOnly = false;
  messageLabel = 'engine type';

  @ViewChild('engineTypeForm') engineTypeForm: NgForm;

  constructor(private engineTypeService: EngineTypeService,
    private route: ActivatedRoute,
    private location: Location,
    private securityUserService: SecurityUserService,
    messageService: MessageService,
    confirmationService: ConfirmationService,
    router: Router,
    breadcrumbService: BreadcrumbService) {
    super(messageService, confirmationService, router, breadcrumbService);

    // Note: Service won't update breadcrumb if caller already did so.
    this.breadcrumbService.setItems(route, [
      { label: Components.EngineTypes.label, routerLink: Components.EngineTypes.path },
      { label: (this.route.snapshot.queryParamMap.get('name')) ? Components.EngineTypeDetails.label + ` (${this.route.snapshot.queryParamMap.get('name')})` : Components.EngineTypeDetails.label },
    ]);
  }

  ngOnInit(): void {
    this.userCanAdd = this.securityUserService.userHasPermission(permissions.admin.engine_types.create);
    this.userCanModify = this.securityUserService.userHasPermission(permissions.admin.engine_types.manage);

    // Optional parameter is:
    // a = airframer ID
    if (this.route.snapshot.queryParamMap.get('e')) {
      this.isReadOnly = !this.userCanModify
      this.loadEngineType(+this.route.snapshot.queryParamMap.get('e'));
    } else {
      // No parameters, new airframer
      if (this.userCanAdd) {
        this.isReadOnly = !this.userCanAdd
        this.addEngineType();
      } else {
        this.showErrorMsg('User is not authorized to add an engine type.', Action.Add, `${this.messageLabel}`);
        // Wait a few seconds for user to see the error, and send them back to where they came from.
        setTimeout(() => {
          this.location.back();
        }, 3000);
      }
    }
  }

  addEngineType() {
    // Update page title and breadcrumb with New...
    this.updateBreadcrumb('New Engine Type');
    this.pageTitle = "New Engine Type";
    this.isNew = true;
    this.engineType = new EngineType();
    this.engineType.enginetype_id = -1;
  }

  loadEngineType(engineTypeID: number) {
    this.isNew = false;
    this.loadingCount++;

    this.engineTypeService.getEngineType(engineTypeID).pipe(
      takeUntil(this.ngUnsubscribe)).subscribe({
        next: result => {
          this.engineType = result;
          this.updateBreadcrumb(Components.EngineTypeDetails.label + ' (' + this.engineType.enginetype_name + ')');
        }, error: error => {
          this.showErrorMsg(error, Action.Get, `${this.messageLabel}`, `${engineTypeID}`);
          this.loadingCount--;
        }, complete: () => {
          this.loadingCount--;
        }
      });
  }

  onCancel() {
    if (this.engineTypeForm.dirty) {
      this.confirmCancel(Components.EngineTypes.path);
    } else {
      this.router.navigate([Components.EngineTypes.path]).then();
    }
  }

  onSubmit() {
    this.loadingCount++;

    if (this.isNew) {
      this.engineTypeService.addEngineType(this.engineType).pipe(
        takeUntil(this.ngUnsubscribe)).subscribe({
          next: id => {
            this.engineType.enginetype_id = id;
            this.router.navigate([], {
              relativeTo: this.route,
              queryParams: {
                e: this.engineType.enginetype_id
              },
              queryParamsHandling: 'merge'
            }).then();

            this.updateBreadcrumb(Components.EngineTypeDetails.label + ' (' + this.engineType.enginetype_name + ')');
          },
          error: error => {
            this.showErrorMsg(error, Action.Add, `${this.messageLabel}`, `${this.engineType.enginetype_name}`);

            this.loadingCount--;
          },
          complete: () => {
            this.showSuccessMsg(Action.Add, `${this.messageLabel}`, `${this.engineType.enginetype_name}`);
            this.isNew = false;
            this.pageTitle = Components.EngineTypeDetails.label;
            this.engineTypeForm.resetForm(this.engineTypeForm.value);
            this.loadingCount--;
          }
        }
        );
    } else {
      this.engineTypeService.updateEngineType(this.engineType).pipe(
        takeUntil(this.ngUnsubscribe)).subscribe({
          next: () => {
            this.updateBreadcrumb(Components.EngineTypeDetails.label + ' (' + this.engineType.enginetype_name + ')');
          },
          error: error => {
            this.showErrorMsg(error, Action.Update, `${this.messageLabel}`, `${this.engineType.enginetype_name}`);
            this.loadingCount--;
          },
          complete: () => {
            this.showSuccessMsg(Action.Update, `${this.messageLabel}`, `${this.engineType.enginetype_name}`);
            this.engineTypeForm.resetForm(this.engineTypeForm.value);
            this.loadingCount--;
          }
        }
        );
    }

  }

  onReset() {
    this.engineTypeForm.resetForm();
    this.ngOnInit();
  }

}
