import { Component, EventEmitter, Input, Output} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validator } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {FormInputBase} from "../../input-model/input/form-input-base";
import { ValidationEnum } from '../../dataframe/shared/enums/validationEnum';

@Component({
  selector: 'app-dynamic-form-input',
  templateUrl: './dynamic-form-input.component.html',
  styleUrls: ['./dynamic-form-input.component.scss']
})
export class DynamicFormInputComponent {
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public optCtrl = new FormControl();
  public opts: string[] = [];
  validationEnum = ValidationEnum;
  @Input() field: FormInputBase<any>;
  @Input() form: FormGroup;
  @Input() isDetailsMode: boolean = false;
  @Input() fb: FormBuilder | undefined;

  @Output() inlineSaveClick: EventEmitter<any> = new EventEmitter();
  @Output() onChange: EventEmitter<any> = new EventEmitter();

  constructor(private formBuilder: FormBuilder) {}

  hasFieldError(): boolean {
    return (
      this.form.get(this.field.key).invalid &&
      (this.form.get(this.field.key).dirty ||
        this.form.get(this.field.key).touched)
    );
  }

  handleChangeEvent(fieldKey) {
    let formValue = this.form.value;
    this.onChange.emit({ 'field': fieldKey, 'form': formValue});
  }
}
