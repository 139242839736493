import { Observable } from "rxjs";
import { Constants } from "src/app/common/models/constants";
import { MenuService } from "src/app/common/services/menu.service";
import { AcmsReportMapModel } from "src/app/features/configuration/models/acmsReportMap";
import { Organization } from "src/app/features/administration/models/organization";
import { AcmsReportMapService } from "src/app/features/configuration/services/acms-report-map.service";
import {  IComponentList,IComponentDetails, IComponentCompare  } from "./IComponentInput";
import { CommonService } from "src/app/common/services/common.service";
import { Components } from '../../../integration/configuration.components';
import { FileType } from "../../../models/dataframeEnums.enum";

export class acmsReportMapListInput implements IComponentList<AcmsReportMapModel>{
    constructor( private acmsReportMapService : AcmsReportMapService, private commonService: CommonService){}



    pageTitle = Components.AcmsReportMap.label;


    getAllData   = () => this.commonService.getAcmsReportMapData();
    getRecord =(id:number) => this.acmsReportMapService.getAcmsReportMapById(id);
    deleteRecord = (id) => this.acmsReportMapService.deleteAcmsReportMap(id);



    noRecordMessage ="No Acms Report Map Found.";
    InputClassName = "Acms Report Map";
    modelName = "acmsreportmap";

    componentLink = Components.AcmsReportMap.path;
    componentLabel = Components.AcmsReportMap.label;

    componentDetailsLink = Components.AcmsReportMapDetails.path;
    componentDetailsLabel = Components.AcmsReportMapDetails.label;

    componentVersionsLink = Components.AcmsReportMapVersions.path;
    componentVersionsLabel = Components.AcmsReportMapVersions.label;

    componentCompareLink = Components.AcmsReportMapCompare.path;
    componentCompareLabel = Components.AcmsReportMapCompare.label;



}

export class acmsReportMapDetailsInput implements IComponentDetails<AcmsReportMapModel,Organization>{
    constructor( private acmsReportMapService : AcmsReportMapService ){}

    pageTitle = Components.AcmsReportMap.label;
    InputClassName = "ACMS Report Map";
    modelName = "acmsreportmap";
    FileMaxSize = Constants.OneMegabyte;
    AllowedFileType =[FileType.RCM]

    componentLink = Components.AcmsReportMap.path;
    componentLabel = Components.AcmsReportMap.label;
    componentDetailsLink = Components.AcmsReportMapDetails.path;
    componentDetailsLabel = Components.AcmsReportMapDetails.label;

    addRecord = (data : AcmsReportMapModel, content: any,rec_type:string) => this.acmsReportMapService.addAcmsReportMap(data,content);
    getRecordVersions = (name: string) => this.acmsReportMapService.getAcmsReportMapVersionByAcmsReportMapName(name)
    getRecord    = (id) => this.acmsReportMapService.getAcmsReportMapDataWithAirlines(id);
    manageRecord = (id,listofIds) => this.acmsReportMapService.manageAcmsReportMap(id,listofIds);
}


export class acmsReportMapCompareInput implements IComponentCompare{
   constructor(private acmsReportMapService: AcmsReportMapService) {}
    pageTitle = Components.AcmsReportMapCompare.label;

    InputClassName = "Acms Report Map Compare";
    modelName = "acmsreportmap";
    noRecordMessage = "No difference found."

    componentLink = Components.AcmsReportMap.path;
    componentLabel = Components.AcmsReportMap.label;
    componentDetailsLink = Components.AcmsReportMapDetails.path;
    componentDetailsLabel = Components.AcmsReportMapDetails.label;

    getDiff = (version1:number, version2: number) => this.acmsReportMapService.compareAcmsReportMap(version1,version2);
    }
