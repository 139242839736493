 
    <div class="grid justify-content-left ">
        <div class="col-4">
            <p-table #dtSourceItems [(selection)]="sourceItemsSelected" [paginator]="sourceItems?.length>10" [rows]="10" [sortOrder]="1"
                [value]="sourceItems" [dataKey]="itemDataKey" id="dtSourceItems" [sortField]="sourceDisplayLabel"
                sortMode="single">
                <ng-template pTemplate="header">
                    <tr>
                        <th class="text-center" style="width:10%">
                            <p-tableHeaderCheckbox *ngIf="isEditable" pTooltip="Check All">
                            </p-tableHeaderCheckbox>
                        </th>
                        <th [pSortableColumn]="sourceDisplayLabel">
                            <div class="flex justify-content-start align-items-center">
                                {{sourceTitle}}
                                <p-sortIcon [field]="sourceDisplayLabel"></p-sortIcon>
                                <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0"
                                    display="menu" pTooltip="Filter..." [hideOnClear]=true [field]="sourceFilterField"
                                    type="text" matchMode="contains">
                                </p-columnFilter>
                            </div>
                        </th>
                    </tr>
                </ng-template>
                <ng-template let-selectedItem pTemplate="body">
                    <tr>
                        <td class="text-center">
                            <p-tableCheckbox *ngIf="isEditable" [value]="selectedItem"></p-tableCheckbox>
                        </td>
                        <td style="word-wrap: break-word;">
                            {{selectedItem[sourceDisplayLabel]}}
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <div class="col-1 align-self-center text-center">
            <div *ngIf="isEditable">
                
                <span pTooltip="Move checked items to selected" tooltipPosition="left">
                    <button (click)="onMoveToSelectedClick()"
                        [disabled]="!sourceItemsSelected || sourceItemsSelected?.length <= 0"
                        class="mb-3 p-element p-ripple p-button p-component p-button-icon-only" icon="pi pi-arrow-right"
                        id="moveToSelectedButton" pButton pRipple type="button"></button>
                </span>
            </div>
            <div *ngIf="isEditable">
                <span pTooltip="Move checked items to available" tooltipPosition="right">
                    <button (click)="onMoveToAvailableClick()"
                        [disabled]="!targetItemsSelected || targetItemsSelected?.length <= 0"
                        class="p-element p-ripple p-button p-component p-button-icon-only" icon="pi pi-arrow-left" id="moveToAvailableButton"
                        pButton pRipple type="button"></button>
                </span>
            </div>
        </div>
        <div class="col-7">
            <p-table #dtTargetItems [(selection)]="targetItemsSelected" [paginator]="targetItems?.length>10" [rows]="10" [sortOrder]="1"
                [value]="targetItems" [dataKey]="itemDataKey" id="dtTargetItems" [sortField]="targetDisplayLabel"
                sortMode="single">
                <ng-template pTemplate="header">
                    <tr>
                        <th class="text-center" style="width:10%">
                            <p-tableHeaderCheckbox *ngIf="isEditable" pTooltip="Check All">
                            </p-tableHeaderCheckbox>
                        </th>
                        <th [pSortableColumn]="targetDisplayLabel" colspan="5">
                            <div class="flex justify-content-start align-items-center">
                                {{targetTitle}}
                                <p-sortIcon [field]="targetDisplayLabel"></p-sortIcon>
                                <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0"
                                    display="menu" pTooltip="Filter..." [hideOnClear]=true [field]="targetFilterField" type="text"
                                    matchMode="contains">
                                </p-columnFilter>
                            </div>
                        </th>
                    </tr>
                </ng-template>
                <ng-template let-selectedItem pTemplate="body">
                    <ng-container let selectedItem>
                        <tr [pSelectableRow]="selectedItem">
                            <td class="text-center">
                                <p-tableCheckbox *ngIf="isEditable" [value]="selectedItem"></p-tableCheckbox>
                            </td>
                            <td style="word-wrap: break-word;">
                                {{selectedItem[targetDisplayLabel]}}
                            </td>                           
                            <td *ngIf="targetTemplate">
                                <ng-container *ngTemplateOutlet="targetTemplate; context:{ $implicit: selectedItem, callback: selectedChange.bind(this)}">                                  
                                </ng-container>
                            </td>
                        </tr>
                    </ng-container>
                </ng-template>
            </p-table>
        </div>
    </div>
 