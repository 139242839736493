import { forkJoin, Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/common/components/base.component';
import { MainComponent } from 'src/app/platform/components/main/main.component';
import { AppComponent } from 'src/app/app.component';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { NotificationsService } from 'src/app/common/services/notifications.service';
import { UserInfo } from 'src/app/security/models/user-info.model';
import { SecurityAuthService } from 'src/app/security/services/security-auth.service';
import { permissions } from 'src/app/security/models/permissions';
import { ThemeService } from '../../services/theme.service';
import {
  INotification,
  INotificationsGroupedByDate,
} from 'src/app/common/models/notifications';
import { Components as AdministrationComponents } from 'src/app/features/administration/integration/administration.components';
import { Components as ConfigurationComponents } from 'src/app/features/configuration/integration/configuration.components';
import { Components as DataDeliveryComponents } from 'src/app/features/datadelivery/integration/datadelivery.components';
import { Components as EndpointDataDeliveryComponents } from 'src/app/features/endpointdatadelivery/integration/endpointdatadelivery.components';
import { Components as SendToTeledyneComponents } from 'src/app/features/sendtoteledyne/integration/sendtoteledyne.components';
import { CommonService } from 'src/app/common/services/common.service';
import { Application } from 'src/app/features/administration/models/application';

console.error = () => {}

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
})
export class TopMenuComponent extends BaseComponent implements OnInit {
  public showOrgSwitch$: Observable<boolean>;
  public orgName$: Observable<string>;
  public userName$: Observable<string>;
  public orgTypeId$: Observable<number>;
  public activeOrgId$: Observable<number>;

  userProfileTitle = AdministrationComponents.UserDetails.label;
  currentAirlines = AdministrationComponents.AirlineDetails.label;
  userRole = '';
  icaoCode = '';
  userAvatar: any;
  userAvatarLoaded = false;
  notifCount: number;
  orgType: number;
  activeOrg: number;
  canViewOrg: boolean;

  notifications: INotification[] = [];
  notificationsGroupedByDate: INotificationsGroupedByDate[] = [];
  unreadNotifications: number = 0;
  applications: Application[] = [];

  constructor(
    public app: AppComponent,
    public appMain: MainComponent,
    //private airlineService: AirlineService,
    private securityUserService: SecurityUserService,
    private authService: SecurityAuthService,
    private domSanitizer: DomSanitizer,
    private themeService: ThemeService,
    private router: Router,
    private notificationsService: NotificationsService,
    private commonService: CommonService
  ) {
    super();
  }

  ngOnInit() {
    this.themeService.initializeDefaultTheme();
    this.showOrgSwitch$ = this.securityUserService.userInfo.pipe(
      map((s: UserInfo) => s.orgs?.length > 1)
    );

    this.orgName$ = this.securityUserService.userInfo.pipe(
      map((s: UserInfo) => s.activeOrg)
    );

    this.userName$ = this.securityUserService.userInfo.pipe(
      map((s: UserInfo) => s.name)
    );

    this.orgTypeId$ = this.securityUserService.userInfo.pipe(
      map((s: UserInfo) => s.orgTypeId)
    );

    this.activeOrgId$ = this.securityUserService.userInfo.pipe(
      map((s: UserInfo) => s.activeOrgId)
    );

    this.orgTypeId$.subscribe((data) => {
      this.orgType = data;
    });

    this.activeOrgId$.subscribe((data) => {
      this.activeOrg = data;
    });

    this.setCanViewBasedOnOrgType(this.orgType)
    this.loadApplications();
  }

  setCanViewBasedOnOrgType(orgType: number){
    if(orgType == 1){
      this.canViewOrg = this.securityUserService.userHasPermission(
        permissions.admin.airline_details.view
      );
    }else if (this.orgType == 2 || this.orgType == 3){
      this.canViewOrg = this.securityUserService.userHasPermission(
        permissions.admin.company_details.view
      );
    }
  }

  loadApplications() {
    forkJoin({
      applications: this.commonService.listApplication(),
    }).subscribe({
      next: (results) => {
        this.applications = results.applications;
      },
      complete: () => {
        this.loadAllNotifications();
      },
    });
  }

  loadAllNotifications() {
    this.notificationsService
      .getCommonNotifications(10)
      .subscribe((notifications) => {
        this.notifications = notifications;
        this.refreshNotificationGrouping(this.notifications);
        this.subscribeToNotificationsUnreadCount();
      });
  }

  subscribeToNotificationsUnreadCount() {
    this.notificationsService.updateUnreadNotificationsCount();
    this.notificationsService.notificationCount$.subscribe((count) => {
      this.unreadNotifications = count;
    });
  }

  groupNotificationsByDate(recentNotifications: INotification[]) {
    const grouped = recentNotifications.reduce((acc, notification) => {
      const dateKey = this.getNormalizedNotificationDate(
        notification.alert_datetime
      );

      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }

      acc[dateKey].push(notification);
      return acc;
    }, {});

    return Object.keys(grouped).map((dateKey) => ({
      date: this.getFormattedNotificationGroupDate(dateKey),
      notifications: grouped[dateKey],
    }));
  }

  // Normalize an ISO string date to "yyyy-mm-dd"
  getNormalizedNotificationDate(isoString: string): string {
    const date = new Date(isoString);
    return date.toISOString().split('T')[0];
  }

  getFormattedNotificationGroupDate(isoDateString: string) {
    const date = new Date(isoDateString);
    const today = new Date();

    const isToday =
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();

    if (isToday) {
      return 'TODAY';
    }

    const day = date.getDate().toString().padStart(2, '0');
    const month = date.toLocaleString('en-US', { month: 'short' });
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  }

  getUnreadNotificationsCount(notifications: INotification[]) {
    return notifications.filter((item) => !item.seen).length;
  }

  refreshNotificationGrouping(notifications: INotification[]) {
    this.notificationsGroupedByDate =
      this.groupNotificationsByDate(notifications);
  }

  markNotificationAsRead(notificationId: number) {
    const isAlreadySeen = this.notifications.find(
      (n) => n.id == notificationId
    )?.seen;
    if (isAlreadySeen) {
      return;
    }
    const payload = {
      notification_id: [notificationId],
      seen: true,
    };
    this.notificationsService.markNotificationsAsRead(payload).subscribe({
      next: () => {
        this.notifications = this.notifications.map((item) => {
          if (item.id === notificationId) {
            return { ...item, seen: true };
          }
          return item;
        });
      },
      complete: () => {
        this.refreshNotificationGrouping(this.notifications);
      },
    });
  }

  navigateToApplicationSpecificNotificationsView(notificationId: number) {
    const notificationApplicationId = this.notifications.find(
      ({ id }) => id === notificationId
    )?.application_id;
    const application = this.applications.find(
      ({ application_id }) => application_id == notificationApplicationId
    );
    const filteredNotifications = this.notifications.filter(
      ({ application_id }) => application_id == application?.application_id
    );
    const routerProps = {
      queryParams: { selectedNotification: notificationId },
      state: {
        selectedNotification: notificationId,
        notifications: filteredNotifications,
      },
    };

    switch (application?.application_name) {
      case 'Administration':
        this.router
          .navigate(
            [AdministrationComponents.NotificationsViewForAdministration.path],
            routerProps
          )
          .then();
        break;
      case 'Configuration':
        this.router
          .navigate(
            [ConfigurationComponents.NotificationsViewForConfiguration.path],
            routerProps
          )
          .then();
        break;
      case 'Data Delivery':
        this.router
          .navigate(
            [DataDeliveryComponents.NotificationsViewForDataDelivery.path],
            routerProps
          )
          .then();
        break;
      case 'Endpoint Data Delivery':
        this.router
          .navigate(
            [
              EndpointDataDeliveryComponents
                .NotificationsViewForEndpointDataDelivery.path,
            ],
            routerProps
          )
          .then();
        break;
      case 'Send To Teledyne':
        this.router
          .navigate(
            [SendToTeledyneComponents.NotificationsViewForSendToTeldyne.path],
            routerProps
          )
          .then();
        break;
      default:
        return;
    }
  }

  onNotificationClick(notificationId: number) {
    this.markNotificationAsRead(notificationId);
    this.navigateToApplicationSpecificNotificationsView(notificationId);
  }

  handleViewAllNotificationsClick() {
    const currentUrl = this.router.url;
    const urlSegments = currentUrl.split('/');
    const applicationName = urlSegments[1];
    const notificationsUrl = `/${applicationName}/notifications`;
    this.router.navigate([notificationsUrl]).then();
  }

  changeTheme(theme: string) {
    this.themeService.switchTheme(theme);
  }

  createUserAvatarFromBlob(image: any) {
    this.userAvatar = ' ';
    const blob = new Blob([image], { type: 'image/png' });
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      () => {
        const c = '' + reader.result;
        this.userAvatar = this.domSanitizer.bypassSecurityTrustUrl(c);
      },
      false
    );

    if (image && image.size > 0) {
      this.userAvatarLoaded = true;
      reader.readAsDataURL(blob);
    } else {
      this.userAvatarLoaded = false;
      this.userAvatar = '';
    }
  }

  /**
   * Event handler for when a user selects their user profile
   */
  onClickUserProfile() {
    this.router
      .navigate([AdministrationComponents.UserDetails.path], {
        queryParams: {
          loggedIn: true,
        },
      })
      .then();
  }

  determineCurrentDetailsPath(orgType: number, canViewOrg: boolean){
    if(!canViewOrg){
      return null
    }

    // type here is airline
    if(orgType == 1){
      return {path: AdministrationComponents.AirlineDetails.path, queryParams: {a: this.activeOrg, loggedIn: true}}
    }

    // type here is company, where orgType 2 is Teledyne
    if(orgType == 2 || orgType == 3){
      return {path: AdministrationComponents.CompanyDetails.path, queryParams: {c: this.activeOrg, loggedIn: true}}
    }
  }

  onClickCurrentDetails() {
    const navDetails = this.determineCurrentDetailsPath(this.orgType, this.canViewOrg)

    if(!navDetails){
      return;
    }

    this.router
      .navigate([navDetails.path], {
        queryParams: navDetails.queryParams
      })
      .then();
  }

  onLogoutClick() {
    this.authService.signOut();
  }
}
