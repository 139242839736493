import { Component, ViewChild, OnInit, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Router, ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from '../../../../common/services/breadcrumb.service';
import {
  Action,
  BaseViewComponent,
} from 'src/app/common/components/base-view.component';
import { forkJoin, takeUntil } from 'rxjs';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import {
  Datadelivery_alert_type as AlertTypeModel,
  DataDeliveryAlerts as AlertsModel,
} from 'src/app/features/datadelivery/models/DatadeliveryAlerts';

@Component({
  selector: 'app-common-alerts-table-component',
  templateUrl: './alerts-table.component.html',
  styleUrls: ['./alerts-table.component.css'],
})
export class CommonAlertsTableComponent
  extends BaseViewComponent
  implements OnInit
{
  @ViewChild('DDSAlertsFormData') AlertsFormData!: NgForm;
  public canAdd: boolean;
  public canDelete: boolean;
  public canEdit: boolean;
  public modalDialog?: boolean;
  public submitted?: boolean;
  isDataFrame: boolean = false;
  uniqueError: boolean = false;
  cols: any[] = [];
  ddsAlerts: AlertsModel[] = [];
  alertType: AlertTypeModel[] = [];

  @Input() permissions: any;
  @Input() pageTitle: string;
  @Input() alertsService: any
  @Input() tableViewPath: string
  @Input() detailsViewPath: string

  constructor(
    router: Router,
    private route: ActivatedRoute,
    breadcrumbService: BreadcrumbService,
    confirmationService: ConfirmationService,
    messageService: MessageService,
    private realUserService: SecurityUserService
  ) {
    super(messageService, confirmationService, router, breadcrumbService);
    this.getBreadCrums();
  }
  getBreadCrums() {
    this.breadcrumbService.setItems(this.route, [
      { label: this.pageTitle },
    ]);
  }
  ngOnInit(): void {
    this.canAdd = this.realUserService.userHasPermission(
      this.permissions.recording_alert.create
    );
    this.canDelete = this.realUserService.userHasPermission(
      this.permissions.recording_alert.delete
    );
    this.canEdit = this.realUserService.userHasPermission(
      this.permissions.recording_alert.manage
    );
    this.getBreadCrums();
    this.cols = [
      {
        field: 'name',
        header: 'Name',
      },
      {
        field: 'alert_type',
        header: 'Type of Alert',
      },
      {
        field: 'time_to_look_back',
        header: 'Time to look back',
      },
      {
        field: 'rate',
        header: 'Rate',
      },
      {
        field: 'action',
        header: 'Action',
      },
    ];

    this.loadRecordingAlertData();
  }

  openNew() {
    if (!this.canAdd) {
      this.showErrorMsg(
        `User is not authorized to add ${this.pageTitle}.`,
        Action.Add,
        `${this.pageTitle}`
      );
    } else {
      this.router.navigate([this.detailsViewPath]).then();
    }
  }

  loadRecordingAlertData() {
    this.loadingCount++;
    forkJoin({
      ddsalertData: this.alertsService.getRecordingAlerts(),
    })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results) => {
          this.ddsAlerts = results.ddsalertData as any;
        },
        error: (error) => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.pageTitle}s`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        },
      });
  }

  onRowSelect(event) {
    this.router
      .navigate([this.detailsViewPath], {
        state: { sardata: event.data },
        queryParams: { alertId: event.data.alert_id, name: event.data.name },
      })
      .then();
  }

  deleteAlert(ddsalerts: AlertsModel) {
    if (!this.canDelete) {
      this.showErrorMsg(
        `User is not authorized to delete an data delivery alert.`,
        Action.Delete,
        `${this.pageTitle}`
      );
    } else {
      this.confirmationService.confirm({
        message:
          'Are you sure that you want to delete the selected data delivery alert?',
        accept: () => {
          this.alertsService
            .deleteRecordingAlert(ddsalerts.alert_id)
            .subscribe({
              next: () => {
                this.showSuccessMsg(
                  Action.Delete,
                  `${this.pageTitle}`,
                  `${ddsalerts.name}`
                );
                this.loadRecordingAlertData();
              },
              error: (error) => {
                this.showErrorMsg(
                  `${error}`,
                  Action.Delete,
                  `${this.pageTitle}`
                );
              },
            });
        },
      });
    }
  }

  hideDialog() {
    this.modalDialog = false;
    this.submitted = false;
  }

  resetDialog() {
    this.AlertsFormData.reset();
  }
}
