<p-blockUI [blocked]="loadingCount > 0">
  <tdy-spinner class="align-items-center justify-content-center z-5"></tdy-spinner>
</p-blockUI>
<p-confirmDialog header="Confirm Deletion" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-panel *ngIf="!canView" header="Unauthorized">
  <p>
    User is not authorized to view aircraft models.
  </p>
</p-panel>
<div *ngIf="allModels != null && canView" class="col-12 card p-3">
  <div class="card-header">
    <h5>{{pageTitle}}</h5>
    <div>
      <button (click)="onClickAdd()" *ngIf="canAdd" icon="pi pi-plus" label="Add" pButton pRipple
        type="button"></button>
    </div>
  </div>

  <div class="">
    <p-table (onRowSelect)="onRowSelect($event)" [filterDelay]="0" [sortOrder]="1" [value]="allModels"
      [showCurrentPageReport]="true" [paginator]="allModels.length > defaultTableRows"
      [rowsPerPageOptions]="[20,50,100,200]" [rows]="defaultTableRows"
      currentPageReportTemplate="{{currentPageReport + ' ' + pageTitle}}" dataKey="aircraftmodel_id"
      id="tblAircraftModels" selectionMode="single" sortField="displayName">
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="displayName">
            <div class="flex justify-content-start align-items-center">
              Display Name
              <p-sortIcon field="displayName"></p-sortIcon>
              <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu"
                field="displayName" type="text" matchMode="contains"></p-columnFilter>
            </div>
          </th>
          <th pSortableColumn="aircraftmodel_model">
            <div class="flex justify-content-start align-items-center">
              Model
              <p-sortIcon field="aircraftmodel_model"></p-sortIcon>
              <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu"
                field="aircraftmodel_model" type="text" matchMode="contains"></p-columnFilter>
            </div>
          </th>
          <th pSortableColumn="aircraftmodel_sub_model">
            <div class="flex justify-content-start align-items-center">
              Sub-Model
              <p-sortIcon field="aircraftmodel_sub_model"></p-sortIcon>
              <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu"
                field="aircraftmodel_sub_model" type="text" matchMode="contains"></p-columnFilter>
            </div>
          </th>
          <th pSortableColumn="airframer_name">
            <div class="flex justify-content-start align-items-center">
              Airframer
              <p-sortIcon field="airframer_name"></p-sortIcon>
              <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu"
                field="airframer_name" matchMode="contains" type="text">
              </p-columnFilter>
            </div>
          </th>
          <th pSortableColumn="engine_count">
            <div class="flex justify-content-start align-items-center">
              Engine Count
              <p-sortIcon field="engine_count"></p-sortIcon>
              <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu"
                field="engine_count" type="numeric"></p-columnFilter>
            </div>
          </th>
          <th>
          </th>
        </tr>
      </ng-template>
      <ng-template let-aircraftModel pTemplate="body">
        <tr [pSelectableRow]="toAircraftModel(aircraftModel)">
          <td>
            {{aircraftModel.displayName}}
          </td>
          <td>
            {{aircraftModel.aircraftmodel_model}}
          </td>
          <td>
            {{aircraftModel.aircraftmodel_sub_model}}
          </td>
          <td>
            {{aircraftModel.airframer_name}}
          </td>
          <td>
            {{aircraftModel.engine_count}}
          </td>
          <td *ngIf="canDelete">
            <div class="flex align-items-center justify-content-end">
              <button id="deleteButton" name="deleteButton" type="button"
                class="p-button-rounded p-button-text p-button-danger" pButton pRipple icon="pi pi-trash"
                pTooltip="Delete..." (click)="onDeleteAircraftModel(aircraftModel)"></button>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="5">No Aircraft Models Found.</td>
        </tr>
      </ng-template>
    </p-table>
    <div *ngIf="allModels.length > 0 && defaultTableRows >= allModels.length" style="text-align: center;">
      <p class="row-paging">Showing 1 to
        {{allModels.length}} of {{allModels.length}} {{pageTitle}}</p>
    </div>
  </div>
</div>
