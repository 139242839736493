<ng-container
  *ngIf="notifications && notifications.length > 0; else noNotifications"
>
  <div
    class="p-0 align-content-start justify-content-start notification_messages"
  >
    <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
      <div class="left_toolbar flex">
        <p-checkbox
          [binary]="true"
          inputId="checkAll"
          class="toolbar_checkall"
          [(ngModel)]="selectAll"
          (onChange)="handleToggleSelectAll($event)"
        ></p-checkbox>

        <span>
          <i
            class="material-symbols-outlined btn_delete"
            (click)="handleBulkDelete()"
          >
            delete
          </i>
        </span>
        <span>
          <i
            class="material-symbols-outlined btn_read_mark"
            (click)="handleBulkMarkAsRead()"
          >
            mark_email_read
          </i>
        </span>
        <span>
          <i
            #sortIcon
            class="material-symbols-outlined btn_filters"
            (click)="toggleOverlayPanel($event, sortIcon)"
          >
            filter_list
          </i>
        </span>
      </div>

      <p-overlayPanel #sortOverlay>
        <ul class="sort-options">
          <li class="sort-option-item" (click)="sortByNewest()">
            <i
              [ngClass]="{ selected: selectedSortOption === 'newest' }"
              class="pi pi-check"
            ></i>
            <span>Newest on top</span>
          </li>
          <li class="sort-option-item" (click)="sortByOldest()">
            <i
              [ngClass]="{ selected: selectedSortOption === 'oldest' }"
              class="pi pi-check"
            ></i>
            <span>Oldest on top</span>
          </li>
        </ul>
      </p-overlayPanel>

      <ul class="message_containers">
        <ng-container *ngFor="let notification of notifications; let i = index">
          <li
            class="all"
            *ngIf="shouldRenderNotificationRow(notification)"
            [ngClass]="{ new: !notification.seen, read: notification.seen }"
          >
            <div class="message_item" (click)="handleOpenNotification(notification.id)">
              <p-checkbox
                [binary]="true"
                inputId="notif-{{ i }}"
                class="pt-1"
                [(ngModel)]="selectedNotifications[notification.id]"
                (onChange)="handleToggleSelectNotification()"
              ></p-checkbox>
              <div
                class="short_message flex flex-column"
              >
                <span class="message_heading">{{
                  notification.alert_name
                }}</span>
                <span class="message_desc text-sm">{{
                  notification.alert_type
                }}</span>
              </div>
              <div class="hover_actions flex">
                <span class="time_date text-sm font-medium">{{
                  notification.alert_datetime | date : "shortTime"
                }}</span>
                <i
                  *ngIf="notification.priority"
                  class="material-symbols-outlined btn_warning"
                >
                  priority_high
                </i>
                <i
                  class="material-symbols-outlined btn_delete"
                  (click)="handleDeleteNotification(notification.id)"
                >
                  delete
                </i>
              </div>
            </div>
          </li></ng-container
        >
      </ul>
    </p-scrollPanel>
  </div>
</ng-container>

<ng-template #noNotifications>
  <div
    class="no-notifications-container"
    style="padding: 16px; text-align: center"
  >
    <p class="no-notifications-message">There are no notifications to show</p>
  </div>
</ng-template>
