import {takeUntil} from 'rxjs/operators';
import {Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {PrimeNGConfig} from 'primeng/api';
import {UserService} from 'src/app/features/administration/services/user.service';
import {BaseComponent} from './common/components/base.component';
import {User} from '../app/features/administration/models/user';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent extends BaseComponent implements OnInit, OnDestroy {
  // These settings are provided by the PrimeNG demo and allow GUI configuration. Most are not used by this app.
  topbarTheme = 'blue';  // TDY configuration (many options (files) in Prime demo). See [class]="'// layout-topbar-' + app.topbarTheme in app.main.component.html
  menuTheme = 'light';  // TDY configuration (options are 'light' or 'dark')  See [class]="'layout-menu-' + app.menuTheme in app.main.component.html
  layoutMode = 'light';  // Only offer light mode
  menuMode = 'slim';
  inputStyle = 'outlined';  // TDY configuration (options are 'outlined' or 'filled')
  ripple = true;  // TDY configuration (why not?)
  isRTL = false;  // TDY configuration is no RTL handling

  loggedInUser: User = null;

  // Topbar variables
  visible = false;
  userName = '';
  icaoCode = '';
  userID: number;
  airlineID: number;
  lastPooledNotifTime: string;
  private subscription: Subscription;

  // Fullscreen variables
  fullscreen = false;

  @ViewChild('layoutContainer', {static: true}) layourContainerViewChild: ElementRef;
  @ViewChild('changePasswordForm', {static: true}) changePasswordForm: any;

  constructor(private primengConfig: PrimeNGConfig,
              public renderer: Renderer2,
              private userService: UserService,
              private router: Router) {
    super();

    router.events
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          const str = event.url;
        }
      });
  }

  /**
   * Lifecycle hook that is called after data-bound properties of a directive are initialized.
   */
  ngOnInit() {
    this.primengConfig.ripple = true;
  }

  override ngOnDestroy() {
    this.subscription?.unsubscribe();
  }


  enterFullscreen() {
    this.fullscreen = true;
    document.getElementsByClassName('layout-main')[0].classList.add('p-ml-0');
    document.getElementsByClassName('layout-main')[0].classList.add('p-pt-0');
    document.getElementById('breadcrumbContainer').style.top = '0';
    document.getElementById('breadcrumbContainer').style.left = '0';
    document.documentElement.requestFullscreen().then();

  }

  exitFullscreen() {
    this.fullscreen = false;
    document.getElementsByClassName('layout-main')[0].classList.remove('p-ml-0');
    document.getElementsByClassName('layout-main')[0].classList.remove('p-pt-0');
    document.getElementById('breadcrumbContainer').style.top = '56px';
    document.getElementById('breadcrumbContainer').style.left = '4.5rem';
    if (window.innerHeight > window.outerHeight) {
      document.exitFullscreen().then();
    }

  }

  /**
   * Logs out the current user. Used by topbar and breadcrumb and any other component that needs it.
   */
  logoutUser() {
    this.subscription?.unsubscribe();
    this.userService.logOut();
  }

  // theme code added by faraz to test 11 april
  themes = [
    {
      id: 'layout-dark-custom',
      label: 'Dark Theme'
    },
    {
      id: 'layout-light-custom',
      label: 'Light Theme'
    }
  ];
  selectedTheme: { id: string; label: string } = this.themes[0];

  // changeTheme(themeId: string) {
  //   this.ThemeService.switchTheme(themeId);
  // }
}
 
