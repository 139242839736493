import { forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { User } from 'src/app/features/administration/models/user';
import { UserService } from 'src/app/features/administration/services/user.service';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { MenuService } from 'src/app/common/services/menu.service';
import { Organization, OrganizationApp } from '../../models/organization';
import { Constants } from 'src/app/common/models/constants';
import {
  Action,
  BaseViewComponent,
} from 'src/app/common/components/base-view.component';
import {
  Application,
  applicationRoleData,
} from 'src/app/features/administration/models/application';
import { NgForm } from '@angular/forms';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { permissions } from 'src/app/security/models/permissions';
import { Components } from '../../integration/administration.components';
import { permissions as adminPermissions } from 'src/app/features/administration/integration/administration.permissions';
import { permissions as configPermissions } from 'src/app/features/configuration/integration/configuration.permissions';
import { permissions as dataDeliveryPermissions } from 'src/app/features/datadelivery/integration/datadelivery.permissions';
import { permissions as endpointDataDeliveryPermissions } from 'src/app/features/endpointdatadelivery/integration/endpointdatadelivery.permissions';
import { permissions as sendToTeledynePermissions } from 'src/app/features/sendtoteledyne/integration/sendtoteledyne.permissions';
import { UserSubscriptionsService } from '../../services/subscriptions.service';
import { UserSubscription } from '../../models/subscriptions';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent extends BaseViewComponent implements OnInit {
  pageTitle = Components.UserDetails.label;
  pageSubTitle = '';
  user: User = null;
  isNewUser = false;
  avatarName = '';
  maxAvatarFileSize = Constants.OneMegabyte;
  avatarLogo: File = null;
  avatarToShow: any = false;
  avatarChanged = false;
  availableApplications: OrganizationApp[] = []; // The list of available applications to possibly associated with the user
  assignedApplications: OrganizationApp[] = []; // The list of applications associated with the user
  previousAssignedApps: number[] = []; // Previously saved applications IDs for the user, used to determine if save is necessary.
  orgsChanged = false;
  appsNotAvail: Application[] = [];
  isReadOnly = true;
  canAdd = false;
  canEdit = false;
  canView = false;
  canCustomize = false;
  availOrgs: Organization[] = [];
  userAssignedOrgs: Organization[] = [];
  availOrgsOptions: SelectItem[] = [];
  additionalAvailOrgs: Organization[] = [];
  isBusinessExists = true;
  loggedIn = false;
  dropdownStyle: any = { width: '100%' };
  activeTabIndex: number = 0;
  isUserAuthorizedToViewSubscriptionsTab = false;
  selectedAppId: null | number = null;
  subscriptionsAndAlerts: {
    subscriptions: UserSubscription[];
    alerts: UserSubscription[];
  } = { subscriptions: [], alerts: [] };

  @ViewChild('userForm') userForm: NgForm;
  @ViewChild('avatarUpload') avatarUpload: FileUpload;

  messageLabel = 'user';

  /**
   * Constructor for the component.
   *
   * @param userService - Gets user information.
   * @param airlineService - Used to get airline information.
   * @param settingsService - Used to get settings values.
   * @param notificationService - Interacts with notification data.
   * @param messageService - Used to display messages.
   * @param breadcrumbService - Used to update the breadcrumb.
   * @param domSanitizer - Used to manipulate the DOM.
   * @param route - Used to get the route's query parameters.
   * @param router - Used to navigate.
   * @param fileSizeFormatter - Used to display avatar file size message.
   */
  constructor(
    private userService: UserService,
    messageService: MessageService,
    breadcrumbService: BreadcrumbService,
    private domSanitizer: DomSanitizer,
    private route: ActivatedRoute,
    router: Router,
    confirmationService: ConfirmationService,
    private securityUserService: SecurityUserService,
    private userSubscriptionsService: UserSubscriptionsService
  ) {
    super(messageService, confirmationService, router, breadcrumbService);

    // Note: Service won't update breadcrumb if caller already did so.
    this.breadcrumbService.setItems(route, [
      { label: Components.UserDetails.label }, // No routerLink value for current page
    ]);

    // Refresh the same page when query params changed in url. Specially when selecting current user.
    this.route.queryParams.subscribe((params) => {
      const currentUser = params['loggedIn'];
      if (currentUser) {
        this.loggedIn = true;
        this.availOrgs = [];
        this.ngOnInit();
      }
    });
  }

  ngOnInit(): void {
    this.canAdd = this.securityUserService.userHasPermission(
      permissions.admin.users.create
    );
    this.canEdit = this.securityUserService.userHasPermission(
      permissions.admin.users.manage
    );
    this.canView = this.securityUserService.userHasPermission(
      permissions.admin.users.view
    );
    this.canCustomize = this.securityUserService.userHasPermission(
      permissions.admin.users.customize
    );

    const userID = +this.route.snapshot.queryParamMap.get('u');
    if (isNaN(userID)) {
      this.showErrorMsg('NAN', Action.Get, 'user');
    } else if (
      (this.canAdd || this.canEdit || this.canView) &&
      (userID > 0 || userID == -1)
    ) {
      if (userID == -1 && this.canAdd) {
        this.isBusinessExists = false;
      }
      this.loadPage(userID);
    } else {
      this.loadData(this.securityUserService.currentUserInfo.activeOrgId);
    }

    this.verifySubscriptionsTabAccessBasedOnUserPermissions();
  }

  verifySubscriptionsTabAccessBasedOnUserPermissions() {
    this.route.queryParams.subscribe((queryParam) => {
      const appName = queryParam['app'];
      if (appName && Object.keys(applicationRoleData).includes(appName)) {
        this.selectedAppId = applicationRoleData[appName].id;
        this.isUserAuthorizedToViewSubscriptionsTab = (() => {
          switch (appName) {
            case 'Administration':
              return this.securityUserService.userHasPermission(
                adminPermissions.alert_subscriptions.view
              );
            case 'Configuration':
              return this.securityUserService.userHasPermission(
                configPermissions.alert_subscriptions.view
              );
            case 'Datadelivery':
              return this.securityUserService.userHasPermission(
                dataDeliveryPermissions.alert_subscriptions.view
              );
            case 'SendToTdy':
              return this.securityUserService.userHasPermission(
                sendToTeledynePermissions.alert_subscriptions.view
              );
            case 'EndpointDataDelivery':
              return this.securityUserService.userHasPermission(
                endpointDataDeliveryPermissions.alert_subscriptions.view
              );
          }
        })();
        if (this.isUserAuthorizedToViewSubscriptionsTab) {
          this.loadSubscriptions(this.selectedAppId);
        }
      }
    });
  }

  loadSubscriptions(selectedAppId: null | number) {
    if (!selectedAppId) return;

    this.userSubscriptionsService
      .getAppSpecificUserSubscriptions(selectedAppId)
      .subscribe((results) => {
        this.subscriptionsAndAlerts = results;
      });
  }

  /**
   * Loads the user information for the page.
   * @param tabIndex Optional tab index to select after the data is loaded.
   */
  loadPage(userID: number) {
    const activeOrgId = this.securityUserService.currentUserInfo.activeOrgId;
    if (this.canView) {
      if (userID > 0) {
        this.loadData(activeOrgId, userID);
      } else {
        if (this.canAdd) {
          this.loadingCount++;
          this.userService
            .getOrganizationsList()
            .pipe()
            .subscribe({
              next: (res) => {
                res.airline.forEach((airline) => this.availOrgs?.push(airline));
                res.company.forEach((company) => this.availOrgs?.push(company));

                this.availOrgs = this.availOrgs.filter(
                  (
                    data,
                    index,
                    self //Avoiding duplication of Airlines
                  ) => index === self.findIndex((a) => a.id === data.id)
                );

                this.availOrgs.sort((a, b) => a.name.localeCompare(b.name));

                this.availOrgs.forEach((org) =>
                  this.availOrgsOptions.push({
                    label: org.name,
                    value: org.code,
                  })
                );
                this.additionalAvailOrgs = this.availOrgs;

                this.canEdit = true;
                this.canCustomize = true;
                // Adding a new user
                // Update breadcrumb item
                this.updateBreadcrumb('New User');
                this.pageTitle = 'New User';
                this.user = new User();
                this.user.business_name = null;
                this.user.user_id = userID;
                this.isNewUser = true;
              },
              error: (error) => {
                this.showErrorMsg(error, Action.Get, 'organizations');
                this.loadingCount--;
              },
              complete: () => {
                this.loadingCount--;
              },
            });
        } else {
          this.router.navigate([MenuService.AccessDenied.Link]).then();
        }
      }
    } else {
      this.router.navigate([MenuService.AccessDenied.Link]).then();
    }
  }

  setUserDetails({
    user,
    Orgs,
    airlineID,
  }: {
    user: User;
    Orgs: any;
    airlineID: number;
  }) {
    this.user = user;
    this.user.status = user.status ?? 'PROVISIONED';
    this.user.AD_user = Boolean(user.AD_user);
    this.user.user_admin = Boolean(user.user_admin);
    this.user.currentAirlineID = airlineID;
    this.isNewUser = false;
    this.avatarToShow = user.display_picture;
    Orgs.airline.forEach((airline) => this.availOrgs?.push(airline));
    Orgs.company.forEach((company) => this.availOrgs?.push(company));

    this.availOrgs = this.availOrgs.filter(
      (
        data,
        index,
        self //Avoiding duplication of Airlines
      ) => index === self.findIndex((a) => a.id === data.id)
    );

    this.user.user_orgs_ids = user.user_orgs_ids.filter(
      (org) => org != user.business_id
    );
  }

  loadData(airlineID: number, userID?: number) {
    this.loadingCount++;

    forkJoin({
      user: userID
        ? this.userService.getUser(userID)
        : this.userService.getCurrentUser(),
      Orgs: this.userService.getOrganizationsList(),
    })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.setUserDetails({ ...res, airlineID });
          this.availOrgs.sort((a, b) => a.name.localeCompare(b.name));

          this.availOrgs.forEach((org) =>
            this.availOrgsOptions.push({ label: org.name, value: org.code })
          );

          this.additionalAvailOrgs = this.availOrgs.filter(
            (org) => org.id != res.user.business_id
          );
          this.populateUserOrgs(
            this.user.user_orgs_ids,
            this.additionalAvailOrgs
          );

          this.updateBreadcrumb(
            Components.UserDetails.label + ' (' + this.user.display_name + ')'
          );
        },
        error: (error) => {
          this.showErrorMsg(
            error,
            Action.Get,
            `${this.messageLabel}/organizations`
          );
          this.user = null;
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        },
      });
  }

  handleShowErrorMsg(payload: {
    message: string;
    action: Action;
    resource: string;
  }) {
    this.showErrorMsg(payload.message, payload.action, payload.resource);
  }

  handleShowSuccessMsg(payload: {
    message: string;
    action: Action;
    resource: string;
  }) {
    this.showSuccessMsg(payload.action, payload.resource);
  }

  saveUser() {
    this.loadingCount++;
    const userID = +this.route.snapshot.queryParamMap.get('u');
    if ((this.canAdd && userID == -1) || (this.canEdit && userID > 0)) {
      if (this.isNewUser) {
        this.userRequest(userID, 'addUser');
      } else {
        this.userRequest(userID, 'saveUser');
      }
    } else {
      if (this.canEdit) {
        this.userRequest(userID, 'saveUser');
      } else if (this.canCustomize) {
        this.userRequest(userID, 'saveCurrentUser');
      }
    }
  }

  userRequest(userID: number, requestMethod: string) {
    let userObservable$;
    if (requestMethod === 'addUser' || requestMethod === 'saveUser') {
      if (this.user.user_id == null) {
        this.user.user_id = userID;
      }
      this.user.user_orgs_ids = [];
      this.user.user_org_codes = [];
      this.userAssignedOrgs.forEach((org) => {
        this.user.user_orgs_ids.push(org.id);
        this.user.user_org_codes.push(org.code);
      });
      this.user.user_admin = this.user.user_admin ?? false;
    }
    if (requestMethod === 'addUser') {
      userObservable$ = this.userService.addUser(this.user);
    } else if (requestMethod === 'saveUser') {
      userObservable$ = this.userService.saveUser(this.user);
    } else if (requestMethod === 'saveCurrentUser') {
      userObservable$ = this.userService.saveCurrentUser(this.user);
    }
    userObservable$.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (userId) => {
        // Update queryParams with new user ID
        if (requestMethod === 'addUser') {
          this.router
            .navigate([], {
              relativeTo: this.route,
              queryParams: {
                u: userId,
              },
              queryParamsHandling: 'merge',
            })
            .then();
          this.user.user_id = userId;
        }
        this.updateBreadcrumb(
          Components.UserDetails.label + ' (' + this.user.display_name + ')'
        );
      },
      error: (err) => {
        if (requestMethod === 'addUser') {
          this.showErrorMsg(err, Action.Add, `${this.messageLabel}`);
        } else {
          this.showErrorMsg(err, Action.Update, `${this.messageLabel}`);
        }
        this.loadingCount--;
      },
      complete: () => {
        if (requestMethod === 'addUser') {
          this.isBusinessExists = true;
          this.user.status = 'PROVISIONED';
          this.successMessage(Action.Add);
          this.pageTitle = Components.UserDetails.label;
          this.userForm.resetForm(this.userForm.value);
        } else {
          this.orgsChanged = false;
          this.avatarChanged = false;
          this.successMessage(Action.Update);
          this.userForm.resetForm(this.userForm.value);
          this.ngOnInit();
        }
      },
    });
  }

  populateUserOrgs(userOrgs: number[], additionalAvailOrgs: Organization[]) {
    this.userAssignedOrgs = [];
    if (userOrgs != null) {
      this.additionalAvailOrgs = additionalAvailOrgs.filter((org) =>
        userOrgs.every((userOrg) => userOrg !== org.id)
      );
      this.userAssignedOrgs = additionalAvailOrgs.filter((org) =>
        userOrgs.some((userOrg) => userOrg == org.id)
      );
      this.userAssignedOrgs.sort((a, b) => a.name.localeCompare(b.name));
    }
  }

  /**
   * Event handler for when a TDY admin changes the selected airline of a user
   */
  onSelectedAirlineChange(event: any) {
    this.loadData(event.value, this.user.user_id);
  }

  toUserOrg(org: Organization): Organization {
    return org;
  }

  onOrgChange() {
    this.userAssignedOrgs.sort((a, b) => a.name.localeCompare(b.name));
    this.additionalAvailOrgs.sort((a, b) => a.name.localeCompare(b.name));
    this.orgsChanged = true;
  }

  /**
   * Event handler for when the user clicks the Cancel button on a new user. Returns them back to the user table.
   */
  onCancel() {
    if (this.userForm.dirty) {
      this.confirmCancel(Components.Users.path);
    } else {
      this.router.navigate([Components.Users.path]).then();
    }
  }

  /**
   * Event handler for when the user clicks the Reset button. Reload the page.
   */
  onReset() {
    this.orgsChanged = false;
    this.avatarChanged = false;
    this.userForm.resetForm();
    this.user.business_name = null;
    this.availOrgs = [];
    this.avatarToShow = false;
    this.userAssignedOrgs = [];
    this.availOrgsOptions = [];
    this.ngOnInit();
  }

  /**
   * Event handler for when the form is submitted
   */
  onSubmit() {
    this.saveUser();
  }

  /**
   * Event handler for when the user deletes the avatar.
   */
  onLogoRemove() {
    if (this.avatarUpload != null) {
      this.avatarUpload.clear();
    }
    this.user.display_picture = null;
    this.avatarLogo = null;
    this.avatarToShow = null;
    this.avatarChanged = true;
  }

  /**
   * Event handler for when the user selects a new avatar. If the file is too large or not the correct type,
   * the default file upload error messages are cleared and a custom one consistent with the rest of the site
   * is displayed. Otherwise, the avatar is generated and added to the user.
   *
   * @param event - The file uploaded
   */
  onAvatarSelect(event: any) {
    if (event.files[0].size > this.maxAvatarFileSize) {
      // Clear the default file upload error message and display our own
      this.avatarUpload.msgs = [];
      this.showErrorMsg(
        'File Too Large, Max File size: 1MB',
        Action.Upload,
        `${event.files[0].name}`
      );
    } else if (!event.files[0].type.includes('image')) {
      // Clear the default file upload error message and display our own
      this.avatarUpload.msgs = [];
      this.showErrorMsg(
        'Incorrect File Type: Only images are supported',
        Action.Upload,
        `${event.files[0].name}`
      );
    } else {
      this.createAvatarImageFromBlob(event.files[0]);
      this.avatarChanged = true;
    }
    this.avatarChanged = true;
  }

  /**
   * Creates an avatar image that can be displayed from a blob file.
   *
   * @param image - the image to create the avatar from
   */
  createAvatarImageFromBlob(image: File) {
    this.avatarLogo = image;
    this.avatarToShow = ' ';
    const blob = new Blob([image], { type: image.type });

    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        const c = '' + reader.result;
        this.avatarToShow = this.domSanitizer.bypassSecurityTrustUrl(c);
        this.user.display_picture = reader.result;
      },
      false
    );

    if (image) {
      reader.readAsDataURL(blob);
    }
  }

  onBusinessSelection() {
    this.user.business_name = this.availOrgs.find(
      (org) => org.code == this.user.business_code
    ).name;
    this.additionalAvailOrgs = this.availOrgs.filter(
      (org) => org.code != this.user.business_code
    );
  }

  successMessage(action: Action) {
    this.isNewUser = false;
    this.showSuccessMsg(
      action,
      `${this.messageLabel}`,
      `${this.user.display_name}`
    );
    this.orgsChanged = false;
    this.avatarChanged = false;
    this.loadingCount--;
  }

  checkValidationsOnSave(userForm: NgForm): boolean {
    if (userForm.valid) {
      if (
        this.user.business_name != null &&
        (this.orgsChanged || this.avatarChanged)
      ) {
        return false;
      }
    }
    if (userForm.valid && userForm.dirty) {
      if (this.user.business_name != null) return false;
    }
    if (this.user.AD_user && this.orgsChanged) {
      return false;
    }
    return true;
  }

  IsFieldDisabled(): boolean {
    // If the user is logged in and has customization permissions, or can edit and has a user ID, or can add and has a user ID, or is an AD user, return false; otherwise, return true.
    return (
      !(
        this.canCustomize &&
        (this.loggedIn ||
          this.user.user_email ===
            this.securityUserService.currentUserInfo.email)
      ) &&
      !(this.canEdit && this.user.user_id > 0) &&
      !(this.canAdd && this.user.user_id < 0) &&
      !this.user.AD_user
    );
  }
}
