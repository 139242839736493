import {Directive, OnInit} from '@angular/core';
import { FeatureItem} from "../../../../../../common/models/feature-item.model";
import {ActivatedRoute, Router} from "@angular/router";
import {Action, BaseViewComponent} from "../../../../../../common/components/base-view.component";
import {ConfirmationService, MessageService} from "primeng/api";
import {BreadcrumbService} from "../../../../../../common/services/breadcrumb.service";
import {takeUntil} from "rxjs";
import {DataFrameService} from "../../../../services/data-frame.service";
import {IAnalyseDataFromServer} from "../../../../models/dataframe717-767";

interface AnalyseTableError {
  Mnemonic: string,
  Attribute: string,
  Error: string
}

@Directive()
export abstract class DataFrameAnalyseAbstract extends BaseViewComponent implements OnInit {
  public dataFrameAnalyse: FeatureItem;
  public frameId: number;
  public versionId: number;
  public dataFrame: any;
  public dataParameter: any[] = [];
  public messageLabel = 'Data Frame Analyse';
  public isLoading = false;
  public errors: AnalyseTableError[] = [];

  constructor(router: Router,
              private route: ActivatedRoute,
              breadcrumbService: BreadcrumbService,
              private dataFrameService: DataFrameService,
              messageService: MessageService,
              confirmationService: ConfirmationService,
              ) {
    super(messageService, confirmationService, router, breadcrumbService)
  }

  ngOnInit(): void {
    this.frameId = +this.breadcrumbService.getQueryParam('frame');
    this.versionId = +this.breadcrumbService.getQueryParam('version');
    if (this.dataFrameAnalyse) {
      this.setBreadCrumbs();
    }
    this.getDataFrame(this.frameId, this.versionId);
  }

  public setBreadCrumbs(): void {
    this.breadcrumbService.setItems(this.route, [
      {
        label: 'Data Frames',
        routerLink: 'configuration/dataframe', queryParams:
          {
            a: this.breadcrumbService.getQueryParam('frame'),
            Name: this.breadcrumbService.getQueryParam('Name')
          }
      },
      {
        label: `Versions (${this.breadcrumbService.getQueryParam('Name')})`,
        routerLink: 'configuration/dataframeVersions',
        queryParams:
          {
            a: this.breadcrumbService.getQueryParam('frame'),
            Name: this.breadcrumbService.getQueryParam('Name')
          }
      },
      {
        label: this.breadcrumbService.getQueryParam('Name') + ` (Version ${this.breadcrumbService.getQueryParam('version_index')})`,
        routerLink: this.dataFrameAnalyse.path,
        queryParams: {
          Name: this.breadcrumbService.getQueryParam('Name'),
          frame: this.breadcrumbService.getQueryParam('frame'),
          version: this.breadcrumbService.getQueryParam('version'),
          version_index: this.breadcrumbService.getQueryParam('version_index')
        }
      },
      {
        label: this.dataFrameAnalyse.label
      }
    ])
  }

  public getDataFrame(frameId: number, versionId: number): void {
    this.isLoading = true;
    this.dataFrameService.IdentifyDataFrame(frameId, versionId).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (data) => this.processReceivedData(data),
      error: (err) => this.showErrorMsg(err, Action.Get, `${this.messageLabel}`),
      complete: () => this.isLoading = false
    });
  }

  public pushError(mnemonic: string, attribute: string, errorMessage: string): void {
    this.errors.push({
      Mnemonic: mnemonic,
      Attribute: attribute,
      Error: errorMessage
    });
  }

  protected abstract processReceivedData(data: IAnalyseDataFromServer): void;

}
