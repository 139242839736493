<p-blockUI [blocked]="loadingCount > 0">
  <tdy-spinner
    style="position: absolute; left: 50%; top: 50%; z-index: 10000"
  ></tdy-spinner>
</p-blockUI>
<div class="col-12 card p-3">
  <div class="card-header m-2">
    <h5>Client Packages</h5>
    <div class="p-toolbar-group-end">
      <button
        *ngIf="false"
        pButton
        pRipple
        label="Add"
        id="addButton"
        icon="pi pi-plus"
      ></button>
    </div>
  </div>
  <app-clientpackage-table
    [clientpackages]="clientpackages"
    [defaultTableRows]="defaultTableRows"
    [currentPageReport]="currentPageReport"
    [pageTitle]="pageTitle"
    [canAdd]="false"
    [canDelete]="false"
    [canEdit]="false"
    (rowSelected)="onRowSelect($event)"
  ></app-clientpackage-table>
  <div
    *ngIf="
      clientpackages.length > 0 && defaultTableRows >= clientpackages.length
    "
    style="text-align: center"
  >
    <p class="row-paging">
      Showing 1 to {{ clientpackages.length }} of {{ clientpackages.length }}
      {{ pageTitle }}
    </p>
  </div>
</div>
