import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonNotificationsPageComponent } from './notifications-page.component';

const routes: Routes = [
  {path: '', component: CommonNotificationsPageComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CommonNotificationsPageRoutingModule { }

export const routedComponents = [CommonNotificationsPageComponent];
