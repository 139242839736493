import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { BaseService } from '../../../common/services/base.service';
import { catchError, map } from 'rxjs/operators';
import { environment } from "src/environments/environment";
import { EnhancedRedactions,ResponseEnhancedRedaction  } from "../models/EnhancedRedaction";
import { ZipType } from '../models/ClientPackage';
@Injectable({
  providedIn: 'root'
})
export class EnhancedRedactionService extends BaseService {

  constructor(
    private http: HttpClient) {
    super();
    this.baseUrl = environment.apiUrl.datadelivery;
  }

  getFligtRedactions(): Observable<EnhancedRedactions[]> {
    let url = this.baseUrl + "enhancedredaction";
    return this.http.get<EnhancedRedactions[]>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError)
      );
  }

  getFligtRedaction_by_id(redaction_id:number): Observable<EnhancedRedactions> {
    let url = this.baseUrl + "enhancedredaction" + `/${redaction_id}`;
    return this.http.get<EnhancedRedactions>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError)
      );
  }

  addFlightRedaction(flightredaction: ResponseEnhancedRedaction) {
    const url = this.baseUrl + 'enhancedredaction';
    return this.http.post<number>(url, flightredaction, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError)
      )
  }

  updateFlightRedaction(flightredaction: ResponseEnhancedRedaction) {
    const url = this.baseUrl + 'enhancedredaction';
    return this.http.put<number>(url, flightredaction, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError)
      )
  }

  public deleteFlightRedaction(redaction_id: number) {
    const url = this.baseUrl + 'enhancedredaction/' + `${redaction_id}`;
    const options = { headers: this.httpGetHeadersForJSON() }
    return this.http.delete(url, options)
      .pipe(catchError(this.httpHandleError));
  }
}