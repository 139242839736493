<p-blockUI [blocked]="loadingCount > 0">
  <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<p-confirmDialog header="Confirm Deletion" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<div *ngIf="aircraft" class="col-12 card p-3">
  <div class="card-header">
    <h5>{{pageTitle}}</h5>
    <div>
      <span class="p-input-icon-left">
        <p-dropdown id="aircraftGroupList" [options]="aircraftGroups" [(ngModel)]="selectedAircraftGroup"
          (onChange)="onChangeAircraftGroup()" tooltip="Filter by Aircraft Group" [autoDisplayFirst]="true"
          [showClear]="true" class="mr-2" [style]="{'width':'200px'}" [dropdownIcon]="'pi pi-filter'"></p-dropdown>
        <span class="p-input-icon-align">
          <button *ngIf="canAdd" id="addButton" type="button" icon="pi pi-plus" (click)="onClickAdd()" label="Add"
            pButton pRipple>
          </button>
        </span>
        <span class="p-input-icon-align mr-0">
          <button *ngIf="canEdit" (click)="dt.exportCSV()" [disabled]="downloadButtonDisabled" icon="pi pi-download"
            id="downloadButton" pButton pRipple type="button"></button>
        </span>
      </span>
    </div>
  </div>
  <p-table id="aircraftTable" #dt [value]="aircraft" (onRowSelect)="onRowSelect($event)" selectionMode="single"
    [filterDelay]="0" [showCurrentPageReport]="true" [paginator]="aircraft.length > defaultTableRows"
    [rowsPerPageOptions]="[20,50,100,200]" [rows]="defaultTableRows"
    currentPageReportTemplate="{{currentPageReport + ' ' + pageTitle}}" sortMode="single"
    sortField="aircraft_registration" [sortOrder]="1" [columns]="cols" exportFilename="{{dynamicdownloadName}}"
    dataKey="aircraft_id" [tableStyle]="{ 'min-width': '50rem' }" >
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="aircraft_registration">
          <div class="flex justify-content-start align-items-center">
            Aircraft Registration
            <p-sortIcon field="aircraft_registration"></p-sortIcon>
            <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu"
              field="aircraft_registration" header="aircraft_registration" type="text"
              matchMode="contains"></p-columnFilter>
          </div>
        </th>
        <th pSortableColumn="aircraftmodel">
          <div class="flex justify-content-start align-items-center">
            Model
            <p-sortIcon field="aircraftmodel"></p-sortIcon>
            <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu"
              field="aircraftmodel" header="aircraftmodel" type="text" matchMode="contains"></p-columnFilter>
          </div>
        </th>
        <th pSortableColumn="enginetype_name">
          <div class="flex justify-content-start align-items-center">
            Engine Type
            <p-sortIcon field="enginetype_name"></p-sortIcon>
            <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu"
              field="enginetype_name" header="enginetype_name" type="text"></p-columnFilter>
          </div>
        </th>
        <th pSortableColumn="serial_number">
          <div class="flex justify-content-start align-items-center">
            Serial Number
            <p-sortIcon field="serial_number"></p-sortIcon>
            <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu"
              field="serial_number" header="serial_number" type="text" matchMode="contains"></p-columnFilter>
          </div>
        </th>
        <th pSortableColumn="nose_number">
          <div class="flex justify-content-start align-items-center">
            Nose Number
            <p-sortIcon field="nose_number"></p-sortIcon>
            <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu"
              field="nose_number" header="nose_number" type="text" matchMode="contains"></p-columnFilter>
          </div>
        </th>
        <th pSortableColumn="aircraftinterior_display">
          <div class="flex justify-content-start align-items-center">
            Aircraft Interior
            <p-sortIcon field="aircraftinterior_display"></p-sortIcon>
            <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu"
              field="aircraftinterior_display" header="aircraftmodel" type="text" matchMode="contains"></p-columnFilter>
          </div>
        </th>
        <th></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-aircraft>
      <ng-container *ngIf="toAircraft(aircraft); let aircraft">
        <tr [pSelectableRow]="aircraft">
          <td>
            {{aircraft.aircraft_registration}}
          </td>
          <!-- <td>
            {{aircraft.airline_icao_code}}
          </td> -->
          <td>
            {{aircraft.aircraftmodel}}
          </td>
          <td>
            {{aircraft.enginetype_name}}
          </td>
          <td>
            {{aircraft.serial_number}}
          </td>
          <td>
            {{aircraft.nose_number}}
          </td>
          <td>
            {{aircraft.aircraftinterior_display}}
          </td>
          <td *ngIf="canAdd || canDelete">
            <div class="flex align-items-center justify-contents-end">
              <button *ngIf="canAdd" id="cloneButton" name="cloneButton" type="button"
                class="p-button-rounded p-button-text mr-2" pButton icon="pi pi-clone" pRipple pTooltip="Clone..."
                [disabled]="!canAdd" (click)="onCloneAircraft(aircraft.aircraft_id)"></button>
              <button *ngIf="canDelete" id="deleteButton" name="deleteButton" type="button"
                class="p-button-rounded p-button-text p-button-danger" pButton pRipple icon="pi pi-trash"
                pTooltip="Delete..." [disabled]="!canDelete" (click)="onDeleteAircraft(aircraft)"></button>
            </div>
          </td>
        </tr>
      </ng-container>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="6">
          No aircraft found
        </td>
      </tr>
    </ng-template>
  </p-table>
  <div *ngIf="aircraft.length > 0 && defaultTableRows >= aircraft.length" style="text-align: center;">
    <p class="row-paging">Showing 1 to
      {{aircraft.length}} of {{aircraft.length}} {{pageTitle}}</p>
  </div>
</div>
