<p-confirmDialog
  header="Confirm Deletion"
  icon="pi pi-exclamation-triangle"
></p-confirmDialog>
<p-blockUI [blocked]="loadingCount > 0">
  <tdy-spinner class="center-spinner"></tdy-spinner>
</p-blockUI>
<div class="col-12 card p-3">
  <div class="card-header m-2">
    <h5>{{ pageTitle }}</h5>
    <div class="p-toolbar-group-end">
      <button
        *ngIf="canAdd"
        pButton
        pRipple
        label="Add"
        id="addButton"
        icon="pi pi-plus"
        (click)="openNew()"
      ></button>
    </div>
  </div>
  <app-clientpackage-table
    [clientpackages]="clientpackages"
    [defaultTableRows]="defaultTableRows"
    [currentPageReport]="currentPageReport"
    [pageTitle]="pageTitle"
    [canAdd]="canAdd"
    [canDelete]="canDelete"
    [canEdit]="canEdit"
    (rowSelected)="onRowSelect($event)"
    (packageCloned)="clonePackage($event)"
    (clientPackageDeleted)="deleteClientPackage($event)"
  ></app-clientpackage-table>
  <div
    *ngIf="
      clientpackages.length > 0 && defaultTableRows >= clientpackages.length
    "
    class="text-center"
  >
    <p class="row-paging">
      Showing 1 to {{ clientpackages.length }} of {{ clientpackages.length }}
      {{ pageTitle }}
    </p>
  </div>
</div>
