// 717 Interface

export interface Root {
  FRA: Fra;
  'ARINC STANDARD': string;
}

export interface Fra {
  FRA_NAME: string;
  FRA_DESCRIPTION: string;
  FRA_SUBFRAME_WORD_NB: number;
  FRA_WORD_LENGTH: number;
  FRA_DFC_LOC_F: number;
  FRA_DFC_LOC_W: number;
  FRA_DFC_BIT_SOURCE_LSB: number;
  FRA_DFC_BIT_LENGTH: number;
  FRA_SFC_LOC_F: number;
  FRA_SFC_LOC_W: number;
  FRA_SFC_BIT_SOURCE_LSB: number;
  FRA_SFC_BIT_LENGTH: number;
  FRA_NB_MINIFRAMES_IN_SUBFRAME: number;
  FRA_MINI_FRAME_TYPE: number;
  AcquiredParams: { [key: string]: AcquiredParams }[];
}

export interface IAnalyseDataFromServer {
  content: string
}

export interface AcquiredParams {
  PRM_MNEMONIC: string;
  PRA_SUPERFRAME?: number;
  PRA_NB_FRAMES_PER_SCYCLE?: number;
  PRA_CONV_CONF: string;
  PRA_NUMBER_OF_LOCATION_PARTS?: number;
  PRM_PLOTLIST_DEFAULT_DISPLAY_FORMAT?: string;
  FMT_ID: number;
  PRM_NAME: string;
  PRM_DESCRIPTION: string;
  PRM_UNIT?: string;
  PRM_DECIMAL_PLACES?: number;
  PRM_RATE: number;
  PRM_MIN_OP_RANGE: number;
  PRM_MAX_OP_RANGE: number;
  PRM_UPDATE_DATE?: string;
  PRM_PARAMETER_SIGNED?: number;
  FMT_ID_DISPLAY: number;
  FTT_ID?: number;
  EUT_ID: number;
  SRT_ID: number;
  // EUC?:                         { [key: string]: number }[];
  EUC?: Euc[];
  LCP: Lcp[];
  TXD?: Txd[];
  PRM_POSITIVE_SIGN?: PrmPositiveSign;
  PRM_NEGATIVE_SIGN?: PrmNegativeSign;
  EUL?: Eul[];
}

export interface Eul {
  EUL_RAW_DATA_VALUE: number;
  EUL_EU_VALUE: number;
}

export interface Lcp {
  LCP_TYPE?: LcpType;
  LCP_BIT_TARGET_LSB?: number;
  LCP_BIT_LENGTH: number;
  LCP_BIT_SOURCE_LSB: number;
  LCP_MODULO: number;
  LCP_SUBFRAME: string;
  LCP_WORD_NB: number;
  LCP_SAMPLE_NUMBER: number;
  LCP_PART_NUMBER: number;
  PRM_RATE?: number;
}

export interface Euc {
  EUC_MIN?: number;
  EUC_MAX?: number;
  EUC_COEFF_1?: number;
  EUC_COEFF_EXP_1?: number;
  EUC_COEFF_0?: number;
  EUC_COEFF_EXP_0?: number;
  EUC_COEFF_2?: number;
  EUC_COEFF_EXP_2?: number;
  EUC_COEFF_3?: number;
  EUC_COEFF_EXP_3?: number;
  EUC_COEFF_4?: number;
  EUC_COEFF_EXP_4?: number;
  EUC_COEFF_5?: number;
  EUC_COEFF_EXP_5?: number;
}

export enum LcpType {
  Regular = 'REGULAR',
  Superframe = 'SUPERFRAME',
}

export enum PrmNegativeSign {
  Empty = '-',
}

export enum PrmPositiveSign {
  Empty = '+',
}

export interface Txd {
  TXD_RAW_DATA_VALUE: number;
  TXD_DESCRIPTION: string;
}

export enum DisplayFormatEnum {
  ASCII = 5,
  Binary = 12,
  Date = 18,
  Float = 16,
  Hexadecimal = 15,
  Integer = 14,
  Time = 17,
}

export enum StorageFormatEnum {
  ASCII = 5,
  BCD = 2,
  Binary = 1,
  BinaryGMT = 9,
  Discrete = 3,
  Integer = 8,
  ISO = 4,
  Real = 7,
}

export enum EuTypeEnum {
  None = 0,
  Polynomial = 1,
  Table = 17,
}

export enum Signed {
  true = 0,
  false = 1,
}

export const StorageMapping = [
  { value: StorageFormatEnum.ASCII, type: 'ASCII' },
  { value: StorageFormatEnum.BCD, type: 'BCD' },
  { value: StorageFormatEnum.Binary, type: 'Binary' },
  { value: StorageFormatEnum.BinaryGMT, type: 'Binary GMT' },
  { value: StorageFormatEnum.Discrete, type: 'Discrete' },
  { value: StorageFormatEnum.Integer, type: 'Integer' },
  { value: StorageFormatEnum.ISO, type: 'ISO#5' },
  { value: StorageFormatEnum.Real, type: 'Real' },
];

export const DisplayMapping = [
  { value: DisplayFormatEnum.ASCII, type: 'ASCII' },
  { value: DisplayFormatEnum.Binary, type: 'Binary' },
  { value: DisplayFormatEnum.Date, type: 'Date' },
  { value: DisplayFormatEnum.Float, type: 'Float' },
  { value: DisplayFormatEnum.Hexadecimal, type: 'Hexadecimal' },
  { value: DisplayFormatEnum.Integer, type: 'Integer' },
  { value: DisplayFormatEnum.Time, type: 'Time' },
];

export const EuTypeMapping = [
  { value: EuTypeEnum.None, type: 'None' },
  { value: EuTypeEnum.Polynomial, type: 'Polynomial' },
  { value: EuTypeEnum.Table, type: 'Table' },
];

export const SignedMapping = [
  { value: Signed.true, type: 'true' },
  { value: Signed.false, type: 'false' },
];

//767 Interface

export interface TopLevel {
  FREDFile: FREDFile;
  'ARINC STANDARD': string;
}

export interface FREDFile {
  ' xmlns:xsd': string;
  ' xmlns:xsi': string;
  ' xsi:schemaLocation': string;
  ' xmlns': string;
  FRED767: Fred767;
}

export interface Bus {
  Port: number;
  Name: string;
}

export interface LabelBus {
  Port: number;
  Frame_ID: number;
  SDIs: Array<string>;
}

export interface LabelBusses {
  Label: number;
  Busses: Array<LabelBus>;
}

export interface Fred767 {
  Header: { [key: string]: string };
  Recorder_File: RecorderFile;
  Frame: { [key: string]: Frame }[];
  Parameter767: { [key: string]: Parameter767 }[];
  BusList?: Array<Bus>;
  LabelList?: Array<LabelBusses>
}

export class Frame {
  Frame_ID: number;
  Frame_Title: string;
  Frame_Type: FrameType;
  Recording_Rate: RecordingRate;
  Recording_Phase: number;
  Parameter_List: ParameterList;
  Frame_Comments: string;
  Event_Condition? : string
}

export enum FrameType {
  Scheduled = 'Scheduled',
  Event = "Event"
}

export interface ParameterList {
  Name_Ref: string[];
}

export interface RecordingRate {
  Numerator?: number;
  Denominator?: number;
}

export interface Parameter767 {
  Name: string;
  Mnemonic_Code: string;
  Modification_Date?: string;
  Comments: string;
  Units: string;
  Sign_Convention?: string;
  Number_of_Bits: number;
  Range: Range;
  Operational_Range: OperationalRange;
  Resolution: number;
  Signal_Source?: null | string;
  DITS_Info?: DITSInfo;
  EU_Type? : string
  Label?: string;
  Port?: number;
  SDI?: string;
  BusName?: string; // this is a processed property; it used processed on the UI side
}

export interface ParameterDXS {
  Name: string;
  Mnemonic_Code: string;
  Modification_Date?: string;
  Comments: string;
  Units: string;
  Sign_Convention?: string;
  Number_of_Bits: number;
  Range: Range;
  Operational_Range: OperationalRange;
  Resolution: number;
  Signal_Source?: null | string;
  DITS_Info?: DITSInfo;
  EU_Type? : string;
  DXS_Label ?: string;
  DXS_BusName ?: string;
  DXS_SDI ?: string;
  Label_rate ?: string;
}

export interface DITSInfo {
  DITS_Label: number;
  DITS_SDI: number;
  DITS_Bits: DITSBits;
  DITS_Coding: DITSCoding;
  DITS_Transmit_Interval: number;
}

export interface DITSBits {
  DITS_Bit_Range: DITSBitRange;
}

export interface DITSBitRange {
  OneBasedIntRange_Start: number;
  OneBasedIntRange_End: number;
}

export enum DITSCoding {
  BCD = 'BCD',
  Binary = 'Binary',
  Discrete = 'Discrete',
}

export interface OperationalRange {
  RealRange_Start?: number;
  RealRange_End?: number;
}

export interface Range {
  Raw_Range: RawRange;
  Data_Type: DataType;
  Conversion_Step?: ConversionStep;
  Text_Conversion?: TextConversion;
}

export interface ConversionStep {
  Poly_Coeff?: PolyCoeff[];
  Integer_Real_Table?: IntegerRealTable[];
  Real_Real_Table?: RealRealTable[];
}

export interface PolyCoeff {
  Coeff_Pair?: CoeffPair;
  "Coeff_Pair:"?: CoeffPair;
}

export interface CoeffPair {
  index: number;
  text: number;
}

export interface IntegerRealTable {
  Integer_Real_Pair?: IntegerRealPair;
  "Integer_Real_Pair:"?: IntegerRealPair;
}

export interface RealRealTable {
  Real_Real_Pair: IntegerRealPair;
}

export interface IntegerRealPair {
  index: number;
  text: number;
}

export enum DataType {
  ASCII = 'ASCII',
  SignedBinary = 'Signed Binary',
  UnsignedBinary = 'Unsigned Binary',
}

export interface RawRange {
  IntRange_Start: number;
  IntRange_End: number;
}

export interface TextConversion {
  Range_Text_Pair: RangeTextPair[];
}

export interface RangeTextPair {
  min: number;
  max: number;
  text: string;
}

export interface RecorderFile {
  Recorder_File_Name: string;
  Recorder_File_Size: string;
  Frame_List: FrameList;
}

export interface FrameList {
  Frame_Title_Ref: string[];
}

export interface Header {
  File_Comments: string;
  File_Revision: string;
  Source_Document: FrameType;
  Recorder_Make_and_Part_Number: string;
  FDAU_Make_and_Part_Number: string;
  Aircraft_Make_and_Model: string;
  Aircraft_Registration: string;
  Aircraft_Alternate_ID: string;
  Aircraft_Serial_Number: string;
}

export interface tableparameters {
  Name?: string;
  Mnemonic_Code?: string;
  Units?: string;
  Data_Type?: DataType;
  Frame_ID?: number;
  Frame_Title?: string;
  Recording_Rate?: RecordingRate;
  Bit_Length?: number;
  Frame_Rate ?:number;
}

export interface IMapViewRow {
  sf?: number;
  word?: number;
  isBlank?: boolean;
  name?: string;
  source?: number;
  length?: number;
  subFrame?: string;
  lcp?: Lcp;
  sourcecol?: number;
  acquiredParam?: AcquiredParams;
  cols?: IMapViewColItem[];
  total?: number;
  rowIndex?: number;
  isConflicted?: boolean;
}

export interface IMapViewColItem {
  colIndex: number;
  word: string;
  sf: string;
  length:number;
  source?: number;
}

export interface ILcpMapView {
  name?: string;
  lcp?: Lcp[];
  acquiredParam?: AcquiredParams;
}

export interface getMapView767 {
  frameDetails: Frame[];
  dataParameter: tableparameters[];
}

export interface getMapViewDXS {
  frameDetails: Frame[];
  dataParameter: tableparameters[];
}

export interface newParameters767 {
  Name?: string;
  Mnemonic_Code?: string;
  Units?: string;
  Data_Type?: DataType;
  Frame_ID?: number;
  Frame_Title?: string;
  Recording_Rate?: RecordingRate;
  Bit_Length?: number;
  Min_Operational_Range?: number;
  Max_Operational_Range?: number;
  Sign_Convention?: string;
  Signal_Source?: string;
  Raw_Range_Min?: number;
  Raw_Range_Max?: number;
  Resolution?: number;
  Number_of_Bits?: number;
  IntRange_Start?: number;
  IntRange_End?: number;
}

export interface parameters767 {
  Name?: string;
  Mnemonic_Code?: string;
  Units?: string;
  Data_Type?: DataType;
  Frame_ID?: number;
  Frame_Title?: string;
  Recording_Rate?: RecordingRate;
  Bit_Length?: number;
  Frame?: Frame;
  Schema767?: Parameter767;
  Frame_Number?: number;
  Frame_Rate ?:number;
  Label?: string;
  Port?: number;
  SDI?: string;
  BusName?: string; // this is a processed property; it used processed on the UI side
}

export interface parametersDXS {
  Name?: string;
  Mnemonic_Code?: string;
  Units?: string;
  Data_Type?: DataType;
  Frame_ID?: number;
  Frame_Title?: string;
  Recording_Rate?: RecordingRate;
  Bit_Length?: number;
  Frame?: Frame;
  SchemaDXS?: ParameterDXS;
  Frame_Number?: number;
  Frame_Rate ?:number;
}