export class AircraftInterior {
    aircraftinterior_id: number;
    aircraftinterior_deck: string; //this should be string ex:Main Deck - 20F / 110Y  and Lower Deck - Crew Rest / 27 ULD)
    aircraftinterior_description: string;
    airline_icao_code: string;
    airline_name: string;
    aircraftmodel_id: number;
    aircraftmodel_model: string;
    aircraftmodel_sub_model: string;
    aircraftinterior_drawingnumber?: string;
    aircraftinterior_drawing?: string;
    aircraftinterior_drawing_file_name?: string
}

export interface AircraftInteriorPair {
    id: number;
    deck: string;
}