import {
  Action,
  BaseViewComponent,
} from 'src/app/common/components/base-view.component';
import { Component, OnInit } from '@angular/core';
import { Components } from '../../../integration/endpointdatadelivery.components';
import {
  AWS_Region,
  EndPoints,
} from 'src/app/features/datadelivery/models/ConfigEndPoints';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { EndpointDataDeliveryEndPointService } from '../../../services/endpoint.service';
import { CommonService } from 'src/app/common/services/common.service';
import { forkJoin, takeUntil } from 'rxjs';

@Component({
  selector: 'app-endpoints-data-details',
  templateUrl: './endpoints-data-details.component.html',
  styleUrls: ['./endpoints-data-details.component.scss'],
})
export class EndpointsDataDetailsComponent
  extends BaseViewComponent
  implements OnInit
{
  public pageTitle = Components.ViewEndpointDataDelivery.label;
  public endpoint!: EndPoints;
  public jsonParameters: any;
  public transferType: any[] = [];
  public awsregion: AWS_Region[] = [];
  private messageLabel = 'endpoint';

  constructor(
    router: Router,
    private route: ActivatedRoute,
    breadcrumbService: BreadcrumbService,
    confirmationService: ConfirmationService,
    messageService: MessageService,
    private endpointDataDeliveryEndPointservice: EndpointDataDeliveryEndPointService,
    private commonService: CommonService
  ) {
    super(messageService, confirmationService, router, breadcrumbService);
    this.getBreadCrums();
  }

  getBreadCrums() {
    this.breadcrumbService.setItems(this.route, [
      {
        label: Components.EndpointDataDelivery.label,
        routerLink: Components.EndpointDataDelivery.path,
      },
      {
        label: this.route.snapshot.queryParamMap.get('name')
          ? Components.ViewEndpointDataDelivery.label +
            ` (${this.route.snapshot.queryParamMap.get('name')})`
          : Components.ViewEndpointDataDelivery.label,
      },
    ]);
  }

  ngOnInit(): void {
    const endpointId = +this.route.snapshot.queryParamMap.get('endpointId'); //converting string to number
    this.getTransferType();
    if (endpointId) {
      this.loadEndPointDetails(endpointId);
    } else {
      this.showErrorMsg(
        `Endpoint id not found`,
        Action.Get,
        `${this.messageLabel}s`
      );
    }
  }

  getTransferType() {
    this.loadingCount++;
    forkJoin({
      transferType: this.endpointDataDeliveryEndPointservice.getTransferType(),
      awsregions: this.commonService.getAwsRegions(),
    })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results) => {
          this.transferType = results.transferType;
          results.awsregions.forEach((ele) => {
            this.awsregion.push({ region: ele });
          });
          this.awsregion.sort((a, b) => {
            return a.region.localeCompare(b.region);
          });
        },
        error: (error) => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        },
      });
  }

  onCancel() {
    this.router.navigate([Components.EndpointDataDelivery.path]);
  }

  loadEndPointDetails(endpointId: number) {
    this.loadingCount++;
    forkJoin({
      transferType: this.endpointDataDeliveryEndPointservice.getTransferType(),
      endpointdetails:
        // TODO: use the right endpoint when it will be available.
        this.endpointDataDeliveryEndPointservice.getEndPoint_by_id(endpointId),
    })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results) => {
          this.transferType = results.transferType;
          this.endpoint = results.endpointdetails;
          this.jsonParameters = JSON.parse(this.endpoint.jsonparameters);
          this.endpoint.isenabled = !!this.endpoint.isenabled;
        },
        error: (error) => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        },
      });
  }
}
