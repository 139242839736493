import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AircraftGroup } from '../models/aircraftGroup';
import { BaseService } from 'src/app/common/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class AircraftGroupService extends BaseService {

  aircraftGroupUrl = environment.apiUrl.admin + 'aircraft-group';

  constructor(private http: HttpClient) {
    super();
  }

  getAircraftGroup(aircraftGroupID: number): Observable<AircraftGroup> {
    const url = this.aircraftGroupUrl + `/${aircraftGroupID}`;
    return this.http.get<AircraftGroup>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError)
      );
  }

  listAircraftGroups(): Observable<AircraftGroup[]> {
    return this.http.get<AircraftGroup[]>(this.aircraftGroupUrl, { headers: this.httpGetHeadersForJSON(true) })
      .pipe(catchError(this.httpHandleError));
  }

  listAircraftGroupFiltersByOrgID(OrgID: number): Observable<AircraftGroup[]> {
    const url = environment.apiUrl.admin + 'aircraft-group-filters' + `/${OrgID}`;
    return this.http.get<AircraftGroup[]>(url, { headers: this.httpGetHeadersForJSON(true) })
      .pipe(catchError(this.httpHandleError));
  }

  addAircraftGroup(newAircraftGroup: AircraftGroup): Observable<number> {
    return this.http.post<number>(this.aircraftGroupUrl, newAircraftGroup, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError)
      );
  }

  deleteAircraftGroup(aircraftGroupid: number) {
    const url = this.aircraftGroupUrl + `/${aircraftGroupid}`;
    return this.http.delete(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError));
  }

  updateAircraftGroup(updatedAircraftGroup: AircraftGroup) {
    const url = this.aircraftGroupUrl + `/${updatedAircraftGroup.aircraftgroup_id}`;
    return this.http.put(url, updatedAircraftGroup, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError)
      );
  }

}
