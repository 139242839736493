import {AfterViewInit, Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {MenuService} from 'src/app/common/services/menu.service';
import {MessageService, PrimeNGConfig} from 'primeng/api';
import {AppComponent} from 'src/app/app.component';
import {UserService} from 'src/app/features/administration/services/user.service';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { UserInfo } from 'src/app/security/models/user-info.model';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html'
})
export class MainComponent implements AfterViewInit, OnInit, OnDestroy {
  public visible$: Observable<boolean>
  topbarMenuActive: boolean;
  menuActive: boolean;
  staticMenuDesktopInactive: boolean;
  mobileMenuActive: boolean;
  menuClick: boolean;
  mobileTopbarActive: boolean;
  topbarRightClick: boolean;
  topbarItemClick: boolean;
  activeTopbarItem: string;
  documentClickListener: () => void;
  configActive: boolean;
  configClick: boolean;
  rightMenuActive: boolean;
  menuHoverActive = false;

  // Right menu variables - boolean used to determine of the appropriate right menu should be shown
  showSearchPanel = false;

  constructor(public app: AppComponent,
              public renderer: Renderer2,
              private menuService: MenuService,
              private primengConfig: PrimeNGConfig,
              private messageService: MessageService,
              private userService: SecurityUserService) {
  }

  ngOnInit() {
    this.visible$ = this.userService.userInfo.pipe(
      map((s: UserInfo) => (s.isAuthenticated ?? false) && !this.app.fullscreen)
    );

    this.menuActive = this.isStatic() && !this.isMobile();

  }

  ngAfterViewInit() {
    // hides the horizontal submenus or top menu if outside is clicked
    this.documentClickListener = this.renderer.listen('body', 'click', () => {
      if (!this.topbarItemClick) {
        this.activeTopbarItem = null;
        this.topbarMenuActive = false;
      }

      if (!this.menuClick && (this.isHorizontal() || this.isSlim())) {
        this.menuService.reset();
      }

      if (this.configActive && !this.configClick) {
        this.configActive = false;
      }

      if (!this.menuClick) {
        if (this.mobileMenuActive) {
          this.mobileMenuActive = false;
        }

        if (this.isOverlay()) {
          this.menuActive = false;
        }

        this.menuHoverActive = false;
        this.unblockBodyScroll();
      }

      this.configClick = false;
      this.topbarItemClick = false;
      this.topbarRightClick = false;
      this.menuClick = false;
    });
  }

  onMenuButtonClick(event) {
    this.menuActive = !this.menuActive;
    this.topbarMenuActive = false;
    this.topbarRightClick = true;
    this.menuClick = true;

    if (this.isDesktop()) {
      this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
    } else {
      this.mobileMenuActive = !this.mobileMenuActive;
      if (this.mobileMenuActive) {
        this.blockBodyScroll();
      } else {
        this.unblockBodyScroll();
      }
    }

    this.app.menuMode = (this.app.menuMode === 'static' ? 'slim' : 'static');  // TDY change to switch between 'slim' and 'static' menus
    event.preventDefault();
  }

  getMenuButtonTooltip(): string {
    return (this.app.menuMode === 'static' ? 'Minimize Menu' : 'Expand Menu');  // TDY change to switch between 'slim' and 'static' menus
  }

  onMenuClick() {
    this.menuClick = true;
  }

  onTopbarItemClick(event, item) {
    this.topbarItemClick = true;

    if (this.activeTopbarItem === item) {
      this.activeTopbarItem = null;
    } else {
      this.activeTopbarItem = item;
    }

    if (item === 'search') {
      this.showSearchPanel = !this.showSearchPanel;
    }

    event.preventDefault();
  }

  onRTLChange(event) {
    this.app.isRTL = event.checked;
  }

  onRippleChange(event) {
    this.app.ripple = event.checked;
    this.primengConfig.ripple = event.checked;
  }

  isDesktop() {
    return window.innerWidth > 991;
  }

  isMobile() {
    return window.innerWidth <= 991;
  }

  isOverlay() {
    return this.app.menuMode === 'overlay';
  }

  isStatic() {
    return this.app.menuMode === 'static';
  }

  isHorizontal() {
    return this.app.menuMode === 'horizontal';
  }

  isSlim() {
    return this.app.menuMode === 'slim';
  }

  blockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.add('blocked-scroll');
    } else {
      document.body.className += ' blocked-scroll';
    }
  }

  unblockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.remove('blocked-scroll');
    } else {
      document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
        'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }
  }

  ngOnDestroy() {
    if (this.documentClickListener) {
      this.documentClickListener();
    }
  }
}
