<p-blockUI [blocked]="loadingCount > 0">
  <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<div class="col-12 card p-3">
  <div class="card-header m-2">
    <h5>{{pageTitle}}</h5>
  </div>
  <div *ngIf="endpoint" class="col-12 card">
      <app-endpoints-form
        [canAdd]="canAdd"
        [canDelete]="canDelete"
        [canEdit]="canEdit"
        [isReadonly]="isReadonly"
        [endpoint]="endpoint"
        [isNew]="isNew"
        [jsonParameters]="jsonParameters"
        [transferType]="transferType"
        [organizationList]="organizationList"
        [awsregion]="awsregion"
        (cancelForm)="onCancel()"
        (resetForm)="onReset()"
        (submitForm)="submitForm($event)"
      ></app-endpoints-form>
  </div>
</div>
