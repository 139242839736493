import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { BaseService } from 'src/app/common/services/base.service';
import { aircraftRecorderModel, updateAircraftRecorderModel2 } from 'src/app/features/configuration/models/aircraftRecorder';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AircraftRecorderService extends BaseService {

  constructor(private http: HttpClient) { super(); }
  aircraftRecodersURL = environment.apiUrl.config + 'aircraft-recorder';

  public getAircraftRecorders(): Observable<aircraftRecorderModel[]> { // get all the aircraftRecorders
    return this.http.get<aircraftRecorderModel[]>(this.aircraftRecodersURL).pipe(catchError(this.httpHandleError));
  }

  public getSelectedAircraftRecorder(aircraftRecorderID: number){
    const url = this.aircraftRecodersURL + `/${aircraftRecorderID}`;
    const data =  this.http.get<aircraftRecorderModel>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError));
    return data;
  }

  public getDataframeByRecorder(recordingStandardId: number){
    const url = environment.apiUrl.config + 'data-frame-by-recorder' +`/${recordingStandardId}`;
    const data =  this.http.get<aircraftRecorderModel>(url, { headers: this.httpGetHeadersForJSON() })
    .pipe(catchError(this.httpHandleError));
    return data;
  }

  public getAircraftRecordersbyAircraftGroup(aircraftGroupdID: number): Observable<aircraftRecorderModel[]> { // get all the aircraftRecorders
    const url = environment.apiUrl.config + 'aircraft-recorders-by-aircraftgroup' + `/${aircraftGroupdID}`;
    return this.http.get<aircraftRecorderModel[]>(url,{ headers: this.httpGetHeadersForJSON() });
  }

  public updateSingleAircraftRecorder(aircraftRecorder: updateAircraftRecorderModel2, recorderID: number){ // for updating single Aircraft Recorder
    return this.http.put(this.aircraftRecodersURL + `/${recorderID}`, aircraftRecorder, { headers: this.httpGetHeadersForJSON()})
    .pipe(catchError(this.httpHandleError)
    );
  }

  public updateMultipleAircraftRecorders(aircraftRecorder: updateAircraftRecorderModel2){ //for updating multiple Aircraft Recorders
    return this.http.put(this.aircraftRecodersURL, aircraftRecorder, { headers: this.httpGetHeadersForJSON()})
    .pipe(catchError(this.httpHandleError)
    );
  }

  public deleteAircraftRecorder(aircraftRecorderID: number){
    const url = this.aircraftRecodersURL + `/${aircraftRecorderID}`;
    const data =  this.http.delete(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError));
    console.log("response data", data);

    return data;
  }

}
