import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { forkJoin, takeUntil } from 'rxjs';
import { RecordingIdModel } from '../../models/recordingClientLogModel';
import { Components } from '../../integration/datadelivery.components';
import { BreadcrumbService } from '../../../../common/services/breadcrumb.service';
import {
  BaseViewComponent,
  Action,
} from 'src/app/common/components/base-view.component';
import { RecordingClientLogService } from '../../services/recordingClientLog.service';

@Component({
  selector: 'recordingclientlog-details',
  templateUrl: './recordingclientlog-details.component.html',
  styleUrls: ['./recordingclientlog-details.component.css'],
})
export class RecordingClientLogDetailComponent extends BaseViewComponent implements OnInit
{
  messageLabel = 'data delivery processing';
  pageTitle = Components.RecordingClientLogDetails.label;
  public canAdd?: boolean;
  public canDelete?: boolean;
  public canEdit?: boolean;
  public modalDialog?: boolean;
  public submitted?: boolean;
  recordingIdModel:RecordingIdModel[]=[];

  record_id: any;
  fileUrl: any;
  constructor(
    router: Router,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    breadcrumbService: BreadcrumbService,
    confirmationService: ConfirmationService,
    messageService: MessageService,
    private recordingClientLogServiceservice: RecordingClientLogService
  ) {
    super(messageService, confirmationService, router, breadcrumbService);
    this.getBreadCrums();
  }
  getBreadCrums() {
    this.breadcrumbService.setItems(this.route, [
      { label: Components.RecordingClientLog.label, routerLink: Components.RecordingClientLog.path },
      {
        label: this.route.snapshot.queryParamMap.get('name')
          ? Components.RecordingClientLogDetails.label +
            ` (${this.route.snapshot.queryParamMap.get('name')})`
          : Components.RecordingClientLogDetails.label,
      },
    ]);
  }
  intialVal() {
    this.getBreadCrums();
    this.canEdit = true;
    this.canDelete = true;
    
  }
  ngOnInit(): void {
    const recording_id = +this.route.snapshot.queryParamMap.get('recording_id');
    this.intialVal();
    this.loadDataDeliveryLists(recording_id);
  }
  downloadFile() {
    const data = 'some text';
    const blob = new Blob([data], {
      type: 'application/octet-stream',
    });
    this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      window.URL.createObjectURL(blob)
    );
  }
  
  loadDataDeliveryLists(recording_id) {
    this.loadingCount++;
    forkJoin({
      getdatadeliverylists:
        this.recordingClientLogServiceservice.getRecordingLogByRecordingId(recording_id),
    })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results) => {
          this.recordingIdModel = results.getdatadeliverylists;
        },
        error: (error) => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        },
      });
  }
}
