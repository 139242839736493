<p-blockUI [blocked]="loadingCount > 0">
  <tdy-spinner
    style="position: absolute; left: 50%; top: 50%; z-index: 10000"
  ></tdy-spinner>
</p-blockUI>
<div class="col-12 card p-3">
  <div class="card-header">
    <h5>{{ pageTitle }}</h5>
  </div>

    <app-dataredaction-form
      [isReadonly]="true"
      [flightdataredaction]="flightdataredaction"
      [uniqueError]="uniqueError"
      [dataframe]="dataframe"
      [redactionType]="redactionType"
      [selectedRedactionTypeId]="selectedRedactionTypeId"
      [fileButtonEnabled]="false"
      [availableDataFramelist]="availableDataFramelist"
      [availableDataFrame]="availableDataFrame"
      [isNew]="isNew"
      [selectedDataframesIDs]="selectedDataframesIDs"
      [picklistChange]="false"
      (onCancel)="onCancel()"
  ></app-dataredaction-form>
</div>
