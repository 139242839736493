<p-blockUI [blocked]="loadingCount > 0">
    <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<p-confirmDialog header="Confirm Deletion" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<div *ngIf="airframers != null && userCanView" class="col-12 card">
    <div class="card-header m-2">
        <h5>{{pageTitle}}</h5>
        <div>
            <button (click)="onClickAdd()" *ngIf="userCanAdd" icon="pi pi-plus" id="addButton" label="Add" pButton
                pRipple type="button"></button>
        </div>
    </div>
    <div class="col-12">
        <p-table (onRowSelect)="onRowSelect($event)" [filterDelay]="0" [responsive]="true" [sortOrder]="1"
            [value]="airframers" [showCurrentPageReport]="true" [paginator]="airframers.length > defaultTableRows" [rowsPerPageOptions]="[20,50,100,200]" [rows]="defaultTableRows" currentPageReportTemplate="{{currentPageReport + ' ' + pageTitle}}" 
            dataKey="airframer_id" id="airframerTable" selectionMode="single" sortField="airframer_name">
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="airframer_name">
                        <div class="flex justify-content-start align-items-center">
                            Name
                            <p-sortIcon field="airframer_name"></p-sortIcon>
                            <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0"
                                display="menu" field="airframer_name" type="text" matchMode="contains"></p-columnFilter>
                        </div>
                    </th>
                    <th>
                    </th>
                </tr>
            </ng-template>
            <ng-template let-airframer pTemplate="body">
                <ng-container *ngIf="toAirframer(airframer); let airframer">
                    <tr [pSelectableRow]="airframer">
                        <td>
                            {{airframer.airframer_name}}
                        </td>
                        <td *ngIf="userCanDelete">
                            <div class="flex justify-content-end">
                                <button id="deleteButton" name="deleteButton" type="button"
                                    class="p-button-rounded p-button-text p-button-danger" pButton pRipple
                                    icon="pi pi-trash" pTooltip="Delete..." [disabled]="!userCanDelete"
                                    (click)="onDeleteAirframer(airframer)"></button>
                            </div>
                        </td>
                    </tr>
                </ng-container>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="3">No airframers found.</td>
                </tr>
            </ng-template>
        </p-table>
        <div *ngIf="airframers.length > 0 && defaultTableRows >= airframers.length" style="text-align: center;">
            <p class="row-paging">Showing 1 to
              {{airframers.length}} of {{airframers.length}} {{pageTitle}}</p>
          </div>
    </div>
</div>