<div [formGroup]="form">
  <div [ngSwitch]="field.controlType" *ngIf="field">
    <div *ngSwitchCase="'textbox'">
      <span class="p-float-label">
        <input id="{{field.key}}" name="{{field.key}}" type="{{field.inputType}}" pInputText
          formControlName="{{field.key}}" [maxlength]="field.maxLength" [max]="field.max" [minLength]="field.minLength"
          [min]="field.min" pattern="{{field.pattern}}" [style]="{'width': '100%'}" 
          [attr.disabled]="field.disabled ? '' : null" />
          <label for="{{field.key}}">{{field.placeholder }}</label>
      </span>
      <ng-container
        *ngIf="field.validators!.length > 0 && ( form.controls[field.key].dirty || form.controls[field.key].touched)">
        <div class="tdy-required" *ngIf="form.controls[field.key].hasError(validationEnum.Required)">
          {{field.errorMessages[validationEnum.Required] }}
        </div>
        <div class="tdy-required" *ngIf="form.controls[field.key].hasError(validationEnum.Pattern)">
          {{field.errorMessages[validationEnum.Pattern] }}
        </div>
        <div class="tdy-required"
          *ngIf="form.controls[field.key].hasError(validationEnum.Exists)">
          {{field.errorMessages[validationEnum.Exists] }}
        </div>
        <div class="tdy-required" *ngIf="form.controls[field.key].hasError(validationEnum.Max)">
          {{field.errorMessages[validationEnum.Max]}}
        </div>
        <div class="tdy-required" *ngIf="form.controls[field.key].hasError(validationEnum.Min)">
          {{field.errorMessages[validationEnum.Min]}}
        </div>
      </ng-container>
    </div>
    <div *ngSwitchCase="'dropdown'">
      <span class="p-float-label">
        <p-dropdown id="{{field.key}}" name="{{field.key}}" [options]="field.options" (onChange)="handleChangeEvent(field.key)" formControlName="{{field.key}}"
          [showClear]="false" [optionLabel]="!field.optionLabel ? 'type' : field.optionLabel" [optionValue]="!field.optionValue ? 'value' : field.optionValue" autoWidth="false"
          [required]="true" [style]="{'width':'100%'}" [autoDisplayFirst]="false"></p-dropdown>
        <label for="{{field.key}}">{{field.placeholder}}</label>
      </span>
      <div class="tdy-required"
        *ngIf="field.validators!.length > 0 && ( form.controls[field.key].dirty || form.controls[field.key].touched) && form.controls[field.key].hasError('required')"  [attr.disabled]="field.disabled ? '' : null">
        {{field.errorMessages.requiredErrorMessage }}
      </div>
    </div>
    <div *ngSwitchDefault></div>
  </div>
</div>