import { Directive } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
import { takeUntil } from "rxjs";
import { BreadcrumbService } from "src/app/common/services/breadcrumb.service";
import { UserService } from "src/app/features/administration/services/user.service";
import { Action, BaseViewComponent } from "src/app/common/components/base-view.component";
import { IComponentList } from "../input-model/IComponentInput";
import { Components } from "../../../integration/configuration.components";

@Directive()
export class BaseListComponent<T> extends BaseViewComponent  {
    pageTitle: string;
    NoRecordMessage :string;
    modelName: string;
    dataKey : string;
    model: T[];
    record: T;
    isloading = []
    id = (obj : Object) => obj[`${this.componentInput.modelName}_id`];
    versionId = (obj: Object) => obj[`${this.componentInput.modelName}version_id`];
    name = (obj: Object) => obj[`name`];
    version = (obj: Object) => obj[`version`];
    downloadButtonDisabled: boolean=true;
    paramSelectionButtonDisabled: boolean=true;
    public dynamicdownloadName: string;
  
    constructor(
       
        messageService: MessageService,
        confirmationService: ConfirmationService,
        router: Router,
        breadcrumbService: BreadcrumbService,
        private componentInput: IComponentList<T>
    ){
        super(messageService,confirmationService,router,breadcrumbService)
        this.NoRecordMessage = componentInput.noRecordMessage;
        this.modelName = this.componentInput.modelName;
        this.dataKey = `${this.modelName}_id`;
       
        sessionStorage.removeItem('Mnemonics');
        sessionStorage.removeItem('FrameDetails');
        sessionStorage.removeItem('DeletedMnemonics');
    }

    cols: any[];

    exportColumns: any[];

    

    getAllData() : void {
        this.pageTitle = this.componentInput.componentLabel;
       
        this.componentInput.getAllData().pipe(
        takeUntil(this.ngUnsubscribe)) 
        .subscribe({
         next: (model)=>{
            this.model =  model;
            this.downloadButtonDisabled = this.model.length<1;
        }  ,
         error: (err) => {
            this.showErrorMsg(err, Action.Get, `${this.componentInput.InputClassName}`);
            this.loadingCount--;
         },
         complete: () => this.loadingCount--
        })        
        this.cols = [
            { field: 'name', header: 'name', customExportHeader: 'Data Frame Name' }          
        ];
        this.dynamicdownloadName = 'Data-Frame' + '_export_' + new Date().toLocaleDateString() + '_' + new Date().toLocaleTimeString();
        this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));  
    }

    getRecord(id:number):void {
        this.pageTitle ="Versions";
        this.componentInput.getRecord(id).pipe(
         takeUntil(this.ngUnsubscribe))
         .subscribe({
           
            next: model => { this.model = model;
               this.model.map(x=>{ this.isloading.push({[x["dataframeversion_id"]]: false})
                                   x["isloading"] = false
                                    // Check if recordingstandard_id is equal to 1
                    if (x["recordingstandard_id"] === 1) {
                        this.paramSelectionButtonDisabled = true;
                    } else {
                        this.paramSelectionButtonDisabled = false;
                    }
                                  
                });
              
         
         this.updateBreadcrumb( `Versions (${model[0]['name']})`);
         
     
             
            },
            error:(err)=> {
                this.showErrorMsg(err, Action.Get, `list of ${this.componentInput.InputClassName}`);
                this.loadingCount--;
            },
            complete: () => this.loadingCount--

         })
        
    }


    toModel(model : T){
        return model;
    }

    navigateToVersionsPage(ID,Name){
       //this.pageTitle = "Versions " + this.componentInput.componentVersionsLabel;
       this.router.navigate([this.componentInput.componentVersionsLink]
        ,{
        queryParams:{
            a: ID
           // ,Name
        }
       }
       )
    }
    navigateToDetailsPage(ID = -1,Name = ""){
        this.pageTitle = this.componentInput.componentDetailsLabel;
       /* const item = this.breadcrumbService.popItem();
        if(item){
            this.breadcrumbService.pushItemsBeforeNavigate([{
             label : item.label,
             routerLink: this.componentInput.componentLink   
            }])
        }*/

        
        if(ID > 0)
        {
            this.router.navigate([this.componentInput.componentDetailsLink],{
                queryParams: {
                    a: ID,
                  //  Name
                }
                
            }).then();
        }else{
            this.router.navigate([this.componentInput.componentDetailsLink]).then();
        }
    }
    navigateToComparePage(ID,VersionID ,Name,versionList){
        this.pageTitle = this.componentInput.componentCompareLabel;
        this.router.navigate([this.componentInput.componentCompareLink],
            {
                queryParams:{
                    a: VersionID,
                    b: ID,
                    Name,
                    versionList 
                   
                }
                
            }
            ).then();
    }

    navigateToComparisonPage(rec){ //navigates to the DataFrame Comparison page
        //TODO add logic for comparison page navigation here
        this.router.navigate([Components.DataframeComparison.path],
            {
                queryParams:{
                    dataFrameName:rec.name,
                    dataFrameID:rec.dataframe_id
                }
            })
    }

    navigateToParameterSelection(rec){
        this.router.navigate([Components.ParameterSelection.path],
            {
                queryParams:{
                    dataFrameName:rec.name,
                    dataFrameID:rec.dataframe_id,
                    dataFrameVersionID:rec.dataframeversion_id,
                    dataFrameVersion: rec.version
                }
            })
    }


    onClickAdd(){
        if (!this.userCanAdd) {
            this.showErrorMsg(`User is not authorized to add  ${this.componentInput.InputClassName}.`, Action.Add, `${this.componentInput.InputClassName}`);
          } else {
            this.navigateToDetailsPage();
          }
    }

    onDeleteRecord(rec :T){
       
        const id = this.id(rec);
        const version_id = this.versionId(rec);
         
        const name = this.name(rec);
           if(!this.userCanDelete){
            this.showErrorMsg(`User is not authorized to delete  ${this.componentInput.InputClassName}.`, Action.Delete, `${this.componentInput.InputClassName}`);
           }
           else{
               this.confirmationService.confirm({
                   message: `Are you sure that you want to delete the selected ${this.componentInput.InputClassName} ?`,
                   accept: () => {
                       this.loadingCount ++ ;
                       this.componentInput.deleteRecord(id).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
                           next: () => {
                               this.showSuccessMsg(Action.Delete, `${this.componentInput.InputClassName}`, `${name}`);
                               this.getAllData();
                             
                           },
                           error: error=>{
                               this.showErrorMsg(`${error}`, Action.Delete, `${this.componentInput.InputClassName}`, `${name}`);
                               this.loadingCount--;
                           },
                           complete: ()=>{}
                       })
                   }
               })
           }
         }

    onCompareDataFrames(rec :T){
        this.navigateToComparisonPage(rec);
    }

    onParameterSelection(rec :T){
        this.navigateToParameterSelection(rec);
    }




    onManageRecord(row){
       if (!this.userCanModify) {
            this.showErrorMsg(`User is not authorized to manage  ${this.componentInput.InputClassName}.`, Action.Update, `${this.componentInput.InputClassName}`);
          } else {
            const Id= this.id(row);
            const Name = this.name(row);
            
            this.navigateToDetailsPage(Id,Name);
          }
      }
   
    onShowVersions(row){
        const Id = this.id(row);
        const Name = this.name(row);
        this.navigateToVersionsPage(Id,Name);
      }
    onRowSelect($event){
       
        const Id = $event.data[Object.keys($event.data)[0]];
        const Name = $event.data[Object.keys($event.data)[1]];
                
        this.navigateToVersionsPage(Id,Name);
    }
    onCompare(row){
        const Id = this.id(row);
        const version_Id = this.versionId(row);
        const Name = this.name(row);
       
        
        
        const ListOfVersions =this.model.filter(v=>this.id(v)=== this.id(row)).map(v=> {
                              return {"aid": this.versionId(v) , "v": this.version(v)} });
       
        const versionList = this.arrayToJson(ListOfVersions)
        this.navigateToComparePage(Id,version_Id,Name,versionList);
      }


    arrayToJson(lst: any): string {
                return JSON.stringify(Object.assign({...lst}));
             }

    
    onClone(row) {}
    onDownload(row){}    
}