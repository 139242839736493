import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { forkJoin, switchMap } from 'rxjs';
import { UserSubscriptionsService } from 'src/app/features/administration/services/subscriptions.service';
import { UserSubscription } from '../../../models/subscriptions';
import { Action } from 'src/app/common/components/base-view.component';
import { NgForm } from '@angular/forms';
import { CommonService } from 'src/app/common/services/common.service';
import { Application } from '../../../models/application';

@Component({
  selector: 'app-user-subscriptions',
  templateUrl: './subscriptions.component.html',
})
export class UserSubscriptionsComponent implements OnInit {
  @ViewChild('UserSubscription') userSubscriptionForm: NgForm;

  @Input() user: any;
  @Input() selectedAppId: null | number;
  @Input() subscriptionsAndAlerts: {subscriptions: UserSubscription[], alerts: UserSubscription[]}

  @Output() handleShowErrorMsg = new EventEmitter<any>()
  @Output() handleShowSuccessMsg = new EventEmitter<any>()
  @Output() activeTabChange = new EventEmitter<number>();

  loadingCount: number = 0;
  pageTitle: string = 'Subscriptions';
  availableSubscriptionTypes: UserSubscription[] = [];
  assignedSubscription: UserSubscription[] = [];

  availableSubscriptionTypesSelected: UserSubscription[] = [];
  assignedSubscriptionSelected: UserSubscription[] = [];

  initialAvailableSubscriptionTypes: UserSubscription[] = [];
  initialAssignedSubscriptionTypes: UserSubscription[] = [];

  applications: Application[] = []

  defaultTableRows: number = 20;
  currentPageReport: string =
    'Showing {first} to {last} of {totalRecords} entries';
  showButtons: boolean = true;

  constructor(private userSubscriptionsService: UserSubscriptionsService) {}

  ngOnInit(){}

  ngOnChanges(changes: SimpleChanges) {
    const results: {subscriptions: UserSubscription[], alerts: UserSubscription[]} = changes['subscriptionsAndAlerts']?.currentValue
    if (results) {
      this.loadSubscriptions(results)
    }
  }

  navigateToPropertiesTab(): void {
    this.activeTabChange.emit(0);
  }

  loadSubscriptions(results: {subscriptions: UserSubscription[], alerts: UserSubscription[]}) {
    this.assignedSubscription = results.subscriptions;
    
    this.availableSubscriptionTypes = [];

    results.alerts.forEach((ele) => {
      const isAlreadyAssignedToUser = this.assignedSubscription.find(
        (a) => a.alert_id == ele.alert_id
      );

      if (!isAlreadyAssignedToUser) {
        this.availableSubscriptionTypes.push({
          alert_id: ele.alert_id,
          alert_type: ele.alert_type,
          alert_type_id: ele.alert_type_id,
          name: ele.name,
        });
      }
    })

    this.setInitialSubscriptionSelections();
    
  }

  setInitialSubscriptionSelections(){
    this.initialAvailableSubscriptionTypes = [...this.availableSubscriptionTypes];
    this.initialAssignedSubscriptionTypes = [...this.assignedSubscription];
  }

  reinstateInitialSubscriptionSelections(){
    this.availableSubscriptionTypesSelected = [];
    this.assignedSubscriptionSelected = [];
    this.assignedSubscription = [...this.initialAssignedSubscriptionTypes];
    this.availableSubscriptionTypes = [...this.initialAvailableSubscriptionTypes];
  }

  toNotificationTypeUser(notificationType: UserSubscription): UserSubscription {
    return notificationType;
  }

  onCheckAllAvailableSubscription(event: any): void {
    if (event.checked) {
      this.availableSubscriptionTypesSelected = [
        ...this.availableSubscriptionTypes,
      ];
    } else {
      this.availableSubscriptionTypesSelected = [];
    }
  }

  onCheckAllAssignedSubscription(event: any): void {
    if (event.checked) {
      this.assignedSubscriptionSelected = [...this.assignedSubscription];
    } else {
      this.assignedSubscriptionSelected = [];
    }
  }

  assignSubscriptionClick(): void {
    this.assignedSubscription = [
      ...this.assignedSubscription,
      ...this.availableSubscriptionTypesSelected,
    ];
    this.availableSubscriptionTypes = this.availableSubscriptionTypes.filter(
      (sub) => !this.availableSubscriptionTypesSelected.includes(sub)
    );
    this.availableSubscriptionTypesSelected = [];
  }

  unassignSunscriptionClick(): void {
    this.availableSubscriptionTypes = [
      ...this.availableSubscriptionTypes,
      ...this.assignedSubscriptionSelected,
    ];
    this.assignedSubscription = this.assignedSubscription.filter(
      (sub) => !this.assignedSubscriptionSelected.includes(sub)
    );
    this.assignedSubscriptionSelected = [];
  }

  OnSubmit(): void {
    const payload = {
      alert_id: this.assignedSubscription.map(item => item.alert_id)
    }
    this.userSubscriptionsService.updateAssignedUserSubscriptions(payload).pipe(
      ).subscribe({
        error: () => {
          this.loadingCount--;
          this.handleShowErrorMsg.emit({
            message: "Failed to update user assigned subscriptions",
            action: Action.Update,
            resource: ""
          })
        },
        complete: () => {
          this.loadingCount--;
          this.setInitialSubscriptionSelections()
          this.handleShowSuccessMsg.emit({
            message: "User assigned subscriptions were updated successfully",
            action: Action.Update,
            resource: "user assigned subscriptions"
          })
        }
      });
  }

  onCancel(): void {
    this.navigateToPropertiesTab()
  }

  onReset(): void {
    this.reinstateInitialSubscriptionSelections()
  }
}
