import { Component, OnInit } from '@angular/core';
import { PostOrgSwitchBehavior, SecurityAuthService } from '../services/security-auth.service';
import { SecurityUserService } from '../services/security-user.service';

@Component({
  selector: 'app-org-logout',
  template: ``
})

export class LogoutComponent implements OnInit {

  public postSwitchBehavior: PostOrgSwitchBehavior = PostOrgSwitchBehavior.Redirect

  constructor(
    private _authService: SecurityAuthService,
    private _userService: SecurityUserService
  ) { }

  public ngOnInit(): void {
    this._authService.signOut();
  }
}