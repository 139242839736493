<p-blockUI [blocked]="loadingCount > 0">
    <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<p-confirmDialog header="Confirm Deletion" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<div *ngIf="engineTypes != null && canView" class="col-12 card">
    <div class="card-header m-2">
        <h5>{{pageTitle}}</h5>
        <div>
            <button (click)="onClickAdd()" *ngIf="canAdd" icon="pi pi-plus" id="addButton" label="Add" pButton
                pRipple type="button"></button>
        </div>
    </div>
    <div class="col-12">
        <p-table (onRowSelect)="onRowSelect($event)" [filterDelay]="0" [responsive]="true" [sortOrder]="1" [value]="engineTypes"
            [showCurrentPageReport]="true" [paginator]="engineTypes.length > defaultTableRows" [rowsPerPageOptions]="[20,50,100,200]" [rows]="defaultTableRows" currentPageReportTemplate="{{currentPageReport + ' ' + pageTitle}}" 
            dataKey="enginetype_id" id="engineTypesTable" selectionMode="single" sortField="enginetype_name">
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="enginetype_name">
                        <div class="flex justify-content-start align-items-center">
                            Name
                            <p-sortIcon field="enginetype_name"></p-sortIcon>
                            <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu"
                                field="enginetype_name" type="text"></p-columnFilter>
                        </div>
                    </th>
                    <th>
                    </th>
                </tr>
            </ng-template>
            <ng-template let-engineType pTemplate="body">
                <ng-container *ngIf="toEngineType(engineType); let engineType">
                    <tr [pSelectableRow]="engineType">
                        <td>
                            {{engineType.enginetype_name}}
                        </td>
                        <td *ngIf="canDelete">
                            <div class="flex align-items-center justify-content-end">
                                <button id="deleteButton" name="deleteButton" type="button" 
                                    class="p-button-rounded p-button-text p-button-danger" pButton pRipple icon="pi pi-trash"
                                    pTooltip="Delete..."
                                    (click)="onDeleteEngineType(engineType)"></button>
                            </div>
                        </td>
                    </tr>
                </ng-container>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="3">No engine types found.</td>
                </tr>
            </ng-template>
        </p-table>
        <div *ngIf="engineTypes.length > 0 && defaultTableRows >= engineTypes.length" style="text-align: center;">
            <p class="row-paging">Showing 1 to
              {{engineTypes.length}} of {{engineTypes.length}} {{pageTitle}}</p>
          </div>
    </div>    
</div>