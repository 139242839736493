<p-blockUI [blocked]="loadingCount > 0">
    <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<p-confirmDialog header="Cancel" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<div *ngIf="aircraftInterior" class="col-12 card p-3">
    <form #aircraftInteriorForm="ngForm" (ngSubmit)="onSubmit()" novalidate>

        
    <div class="card-header m-2">
        <h5>{{pageTitle}}</h5>
    </div>

    <div class="grid m-0">
     
<div class="lg:col-6"> 


    <h6>Details</h6>
    <div class="field">
        <div class="p-float-label">
            <input #aircraftinteriorDeck="ngModel" [(ngModel)]="aircraftInterior.aircraftinterior_deck"
                [disabled]="isReadOnly" [style]="{'width':'100%'}"
                [textContent]="aircraftInterior.aircraftinterior_deck" maxlength="256" minlength="2" 
                name="aircraftinteriorDeck" pInputText required type="text" 
                (change) ="aircraftInterior.aircraftinterior_deck = aircraftInterior.aircraftinterior_deck.trim()"/>
            <label for="aircraftinteriorDeck">Deck</label>
        </div>
        <div *ngIf="(aircraftinteriorDeck.touched || aircraftinteriorDeck.dirty) && aircraftinteriorDeck.hasError('required') &&  !aircraftinteriorDeck.valid"
            class="tdy-required">
            Deck is required
        </div>
    </div>
    <div class="field">
        <div class="p-float-label">
            <input #aircraftinteriorDesc="ngModel"
                [(ngModel)]="aircraftInterior.aircraftinterior_description"
                [disabled]="isReadOnly" [style]="{'width':'100%'}"
                [textContent]="aircraftInterior.aircraftinterior_description" maxlength="256" minlength="2"
                name="aircraftinteriorDesc" pInputText required type="text" 
                (change) ="aircraftInterior.aircraftinterior_description = aircraftInterior.aircraftinterior_description.trim()"/>
            <label for="aircraftinteriorDesc">Description</label>
        </div>
        <div *ngIf="(aircraftinteriorDesc.touched || aircraftinteriorDesc.dirty) && aircraftinteriorDesc.hasError('required')"
            class="tdy-required">
            Description is required
        </div>
    </div>
    <div class="field">
        <div class="p-float-label">
            <p-dropdown #aircraftinteriormodel="ngModel" [(ngModel)]="aircraftInterior.aircraftmodel_id"
                [disabled]="isReadOnly" [style]="{'width':'100%'}"
                id="aircraftinteriormodel" [autoDisplayFirst]="false" name="aircraftinteriormodel"
                [required]=true value [options]="aircraftModels" placeholder="Select AircraftModel"
                optionLabel="aircraftmodel" optionValue="aircraftmodel_id">
            </p-dropdown>
            <ng-template let-aircraftInterior.aircraftModel pTemplate="item">
                <div class="flex align-items-center aircraftInterior.aircraftModel-item">
                    <div>{{aircraftInterior.aircraftmodel_model}}
                        {{aircraftInterior.aircraftmodel_sub_model}}</div>

                </div>
            </ng-template>
            <label for="aircraftinteriormodel">Aircraft Model</label>
        </div>
        <div *ngIf="(aircraftinteriormodel.touched || aircraftinteriormodel.dirty) && aircraftinteriormodel.hasError('required')"
            class="tdy-required">
            Aircraft Model is required
        </div>
    </div>
    <div class="field">
        <div class="p-float-label">
            <input #aircraftinteriorDrawNum="ngModel"
                [(ngModel)]="aircraftInterior.aircraftinterior_drawingnumber"
                [disabled]="isReadOnly" [style]="{'width':'100%'}"
                [textContent]="aircraftInterior.aircraftinterior_drawingnumber" type="text" maxlength="256"
                name="aircraftinteriorDrawNum" pInputText />
            <label for="aircraftinteriorDrawNum">Drawing Number</label>
        </div>
    </div>
</div>
<div class="lg:col-2"><p-divider layout="vertical"></p-divider></div>
<div class="lg:col-4">

    <div class=" custom-panel">
        <h6>Upload</h6>
        <div class="field">
            <div class="p-float-label">
                <p-fileUpload #drawingUpload (onRemove)="onDrawingRemove()"
                    (onSelect)="onDrawingSelect($event)"
                    [disabled]="isReadOnly || drawingFile != null"
                    [showCancelButton]=false [showUploadButton]=false [fileLimit]="1" [multiple]="false"
                    accept="{{acceptedFiles}}" chooseLabel="Drawing" id="drawingUpload"
                    [maxFileSize]="maxDrawingFileSize" mode="advanced" name="drawingUpload"
                    url="{{drawingUrl}}">
                    <ng-template pTemplate="toolbar">
                        <button (click)="onDrawingRemove()" id="deleteButton"
                            *ngIf="drawingFile != null && drawingFile?.size > 0"
                            [disabled]="isReadOnly"
                            class="p-button-rounded p-button-text p-button-danger" icon="pi pi-trash"
                            pButton pRipple pTooltip="Remove Drawing..." style="float: right" type="button">
                        </button>
                    </ng-template>
                    <ng-template pTemplate="file"> 
                        <div style="white-space:wrap">{{aircraftInterior.aircraftinterior_drawing_file_name}}</div>                                   
                    </ng-template>
                    <ng-template pTemplate="content">                    
                            <!--  NO need to preview the drawing  -->
                            <div *ngIf="!isNew && aircraftInterior.aircraftinterior_drawing != null && !drawingChanged ">
                                <label>{{aircraftInterior.aircraftinterior_drawing_file_name}}</label>
                                <button (click)=onDownloadDrawing() id="downButton"
                                class="p-button-rounded p-button-text p-button-danger" icon="pi pi-download"
                                pButton pRipple pTooltip="Download Drawing..." style="float: right" type="button"></button> 
                            </div>
                            <div *ngIf="drawingFile == null || drawingFile?.size <= 0">
                                No drawing uploaded.
                            </div>                              
                    </ng-template> 
                </p-fileUpload>
            </div>
        </div>

    </div>

</div>

</div>

       
     
        <div class="flex justify-content-end">
            <span class="p-input-icon-left">
                <button (click)="onCancel()" class="p-button-text" icon="pi pi-times" id="cancelButton" label="Cancel" 
                 pButton pRipple type="button"></button>
            </span>
            <span class="p-input-icon-left">
                <button *ngIf="!isReadOnly" #aircraftinteriorReset (click)="onReset()" [disabled]="!(aircraftInteriorForm.dirty || drawingChanged)"
                    class="p-button-text" icon="pi pi-undo" id="resetButton" label="Reset" pButton pRipple
                    type="button"></button>
            </span>

            <span class="p-input-icon-left pl-2">
                <button icon="pi pi-check" *ngIf="!isReadOnly"
                    [disabled]="isNew ? !aircraftInteriorForm.valid : !(aircraftInteriorForm.dirty && aircraftInteriorForm.valid || drawingChanged)"
                    id="okButton" label="Save" pButton pRipple type="submit"></button>
            </span>
        </div>


    </form>
</div>