import { Routes } from "@angular/router";

export interface FeatureItem {
    label: string;
    icon?: string;
    permission?: string;
    path?: string;
    component?: any;
}

export interface FeatureRoot extends FeatureHeader {
    user_management?: FeatureUserManagementConfig;
} 

export interface FeatureHeader {
    label: string;
    icon?: string;
    items?: any[] // mixed array of FeatureHeader and/or FeatureItem;
}

export interface FeatureUserManagementConfig {
    feature_name: string;
    user_permission: string;
    roles_permission: string;
}

export class FeatureComponentsDefinition {
    
    public static getRoutes() : Routes {
        const routes = [];
        Object.entries(this).forEach(([key, value]) => { 
            const featureItem = value as FeatureItem;
            if (!!featureItem?.path && !!featureItem?.component)
                routes.push({
                    path: featureItem.path,
                    component: featureItem.component
                });
        })
        return routes;
    } 
}
