import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, Renderer2, RendererFactory2 } from "@angular/core";

@Injectable({
    providedIn: 'root'
})

export class ThemeService {
    private readonly storageKey = 'selectedTheme';

    themePath = '../../../assets/css/'
    private renderer: Renderer2;
    constructor(@Inject(DOCUMENT) private document: Document, private rendererFactory: RendererFactory2) {
        this.renderer = this.rendererFactory.createRenderer(null, null);
    }

    initializeDefaultTheme(activeTheme?: string) {
        // Retrieve the selected theme from storage
        const storedTheme = localStorage.getItem(this.storageKey);

        // If a theme is stored, apply it; otherwise, apply the default theme
        const themeToApply = storedTheme ? storedTheme : 'layout-light-custom.css';
        this.applyTheme(themeToApply);
    }

    applyTheme(themeToApply: string): void {
        // Check if an element with id 'app-theme' exists
        const existingTheme = this.document.getElementById('app-theme');

        // If the element exists, remove it
        if (existingTheme) {
            existingTheme.remove()
        }

        // Create and append the new stylesheet link
        const link = this.renderer.createElement('link');
        this.renderer.setAttribute(link, 'id', 'app-theme');
        this.renderer.setAttribute(link, 'rel', 'stylesheet');
        this.renderer.setAttribute(link, 'type', 'text/css');
        this.renderer.setAttribute(link, 'href', this.themePath + themeToApply);
        this.renderer.appendChild(this.document.head, link);

        // Store the selected theme in localStorage
        localStorage.setItem(this.storageKey, themeToApply);
    }

    switchTheme(changedTheme: string) {
        console.log("changedTheme", changedTheme);
      
        // Store the selected theme in localStorage
        localStorage.setItem(this.storageKey, changedTheme);
      
        let themeLink = this.document.getElementById('app-theme') as HTMLLinkElement;
        if (themeLink) {
          themeLink.href = this.themePath + changedTheme;
        }
      }
}