import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[fwUppercase]'
})

export class UppercaseDirective {
  @HostListener('blur') onBlur() {
    const input = this.el.nativeElement as HTMLInputElement;
    input.value = input.value.toUpperCase();
 }
  constructor(private el: ElementRef) {}
}
