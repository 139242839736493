export enum RecorderType {
    DXS = "DXS",
    TDF = "TDF",
    A717 = "-A717",
    PRM = "PRM",
    CSV = "CSV",
    A767 = "-A767"
}

export enum FileType {
    FAP = ".FAP",
    TDF = ".TDF",
    PRM = ".PRM",
    CSV = ".CSV",
    XML = ".XML",
    RCM = ".RCM"
}
