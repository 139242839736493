import {takeUntil} from 'rxjs/operators';
import {Component, forwardRef, Inject, OnInit} from '@angular/core';
import {AppComponent} from 'src/app/app.component';
import {BaseComponent} from 'src/app/common/components/base.component';
import {DateUtil} from 'src/app/platform/util/dateUtil';

const info = require('../../../../../package.json');

@Component({
  selector: 'app-footer',
  templateUrl: 'footer.component.html'
})
export class FooterComponent extends BaseComponent implements OnInit {
  visible = true;
  utcDateTime: string = DateUtil.formatForGui(new Date());
  version: string = info.version;
  revision: string = info.tdyrevision;
  build: string = info.tdybuild;
  gitref: string = info.tdygitref;
  pn: string = info.tdypn;

  constructor(@Inject(forwardRef(() => AppComponent)) public app: AppComponent,
              ) {
    super();
  }

  ngOnInit() {
    setInterval(() => {
      this.utcDateTime = DateUtil.formatForGui(new Date());
    }, 1000);
  }
}
