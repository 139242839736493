import { Constants } from "src/app/common/models/constants";
import { DataFrameModel } from "src/app/features/configuration/models/dataframe";
import { DataFrameService } from "src/app/features/configuration/services/data-frame.service";
import { IComponentCompare, IComponentDetails, IComponentList } from "./IComponentInput";
import { CommonService } from "src/app/common/services/common.service";
import { Components } from '../../../integration/configuration.components';
import { Organization } from "src/app/features/administration/models/organization";
import { FileType } from "../../../models/dataframeEnums.enum";

export class dataframeListInput implements IComponentList<DataFrameModel>{
    constructor(private dataframeService : DataFrameService, private commonService:CommonService){}

 pageTitle = Components.Dataframe.label;

 getAllData = () => this.commonService.getDataFrameData();
 getRecord = (id) => this.dataframeService.getDataFrameById(id);
 deleteRecord = (id) => this.dataframeService.deleteDataFrameVersion(id);


 noRecordMessage = "No dataframe found.";
 InputClassName ="Data Frame";
 modelName = "dataframe";

 componentLink = Components.Dataframe.path;
 componentLabel = Components.Dataframe.label;

 componentDetailsLink = Components.DataframeDetails.path;
 componentDetailsLabel = Components.DataframeDetails.label;

 componentVersionsLink = Components.DataframeVersions.path;
 componentVersionsLabel = Components.DataframeVersions.label;

 componentCompareLink = Components.DataframeCompare.path;
 componentCompareLabel = Components.DataframeCompare.label;

 componentListView717Link = Components.ListView717.path;
 componentListView717Label = Components.ListView717.label;

 componentListView767Link = Components.ListView767.path;
 componentListView767Label = Components.ListView767.label;

 componentListViewDXSLink = Components.ListViewDXS.path;
 componentListViewDXSLabel = Components.ListViewDXS.label;

}

export class dataframeDetailsInput implements IComponentDetails<DataFrameModel,Organization>{
    constructor(private dataframeService: DataFrameService){}

    pageTitle = Components.DataframeDetails.label;
    InputClassName = "Data Frame";
    modelName ="dataframe"
    FileMaxSize = Constants.TenMegabytes;
    AllowedFileType= [FileType.FAP, FileType.XML, FileType.TDF, FileType.PRM, FileType.CSV]

    componentLink = Components.Dataframe.path;
    componentLabel = Components.Dataframe.label;
    componentDetailsLink = Components.DataframeDetails.path;
    componentDetailsLabel = Components.DataframeDetails.label;

    addRecord = (data: DataFrameModel, content: any, rec_type:string)=> this.dataframeService.addDataFrame(data,content,rec_type);
    getRecordVersions = (name: string) => this.dataframeService.getDataFrameVersionsByDataFrameName(name);
    getRecord =(id) => this.dataframeService.getDataFrameDataWithAirlines(id);


    manageRecord = (id: number, listofids: any) => this.dataframeService.manageDataFrame(id,listofids);
}

export class dataframeCompareInput implements IComponentCompare{
   constructor(private dataframeService: DataFrameService){}

   pageTitle = Components.DataframeCompare.label;

   InputClassName="Dataframe Compare";
   modelName="dataframe";
   noRecordMessage="No difference found."

   componentLink = Components.Dataframe.path;
   componentLabel = Components.Dataframe.label;
   componentDetailsLink = Components.DataframeDetails.path;
   componentDetailsLabel = Components.DataframeDetails.label;
   getDiff = (versionId1, versionId2) => this.dataframeService.compareDataFrameVersions(versionId1,versionId2);

}
