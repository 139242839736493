<p-blockUI [blocked]="loadingCount > 0">
    <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<p-confirmDialog></p-confirmDialog>

<div *ngIf="recorderRegex" class="col-12 card p-3">
    <form #recorderRegexForm="ngForm" (ngSubmit)="onSubmit();" novalidate>
        <div class="card-header m-2">
            <h5>{{pageTitle}}</h5>
        </div>
        <div class="grid mt-2">
            <div class="col-12 lg:col-6">
                <div class="card mb-0">
                    <h6>Details</h6>
                    <div class="">
                        <div class="p-float-label">
                            <input #recorderRegexName="ngModel" [(ngModel)]="recorderRegex.name"
                                [disabled]="(isNew)? !canAdd : !canEdit" [style]="{'width':'100%'}"
                                [textContent]="recorderRegex.name" required pattern=".*\S.*"  name="recorderRegexName" pInputText
                                required type="text" />
                            <label for="engineTypeName">Regex</label>
                        </div>
                        <div *ngIf="(recorderRegexName.touched || recorderRegexName.dirty) && recorderRegexName.hasError('required')"
                            class="tdy-required">
                            Regex is required.
                        </div>
                    </div>                   
                </div>
            </div>
        </div>

        <div class="flex justify-content-end">
            <span class="p-input-icon-left">
                <button (click)="onCancel()" class="p-button-text" icon="pi pi-times" id="cancelButton" label="Cancel"
                    pButton pRipple type="button"></button>
            </span>
            <span class="p-input-icon-left">
                <button *ngIf="canEdit" (click)="onReset()" class="p-button-text" icon="pi pi-undo" id="resetButton" label="Reset" pButton pRipple
                [disabled]="!recorderRegexForm.dirty"    
                type="button"></button>
            </span>

            <span class="p-input-icon-left pl-2">
                <button *ngIf="canAdd || canEdit" 
                [disabled]="isNew? !recorderRegexForm.valid : !(recorderRegexForm.dirty && recorderRegexForm.valid)" icon="pi pi-check"
                    id="okButton" label="Save" pButton pRipple type="submit"></button>
            </span>
        </div>
    </form>
</div>