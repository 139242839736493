<div *ngIf="flightdataredaction" class="field">
  <form #dataredactionForm="ngForm" (ngSubmit)="handleSubmit()" novalidate>
    <div class="formgrid grid">
      <div class="field col-12 md:col-4 lg:col-4">
        <span class="p-float-label">
          <input
            [disabled]="isReadonly"
            type="text"
            [style]="{ width: '100%' }"
            pattern=".*\S.*"
            pInputText
            id="name"
            name="name"
            [(ngModel)]="flightdataredaction.name"
            #name="ngModel"
            required
            maxlength="50"
          />
          <label for="name">Name</label>
        </span>
        <div *ngIf="uniqueError">
          <small class="p-invalid p-error">Name Already Exists</small>
        </div>
        <div
          class="tdy-required"
          *ngIf="(name.touched || name.dirty) && name.hasError('required')"
        >
          Name is required.
        </div>
        <div class="tdy-required" *ngIf="name.hasError('pattern')">
          Name cannot be left blank.
        </div>
      </div>
      <div class="field col-12 md:col-4 lg:col-4">
        <span class="p-float-label">
          <input
            [disabled]="isReadonly"
            type="text"
            id="description"
            pattern=".*\S.*"
            #description="ngModel"
            pInputText
            [style]="{ width: '100%' }"
            name="description"
            [(ngModel)]="flightdataredaction.description"
          />
          <label for="description">Description</label>
        </span>
        <div class="tdy-required" *ngIf="description.hasError('pattern')">
          Description cannot be left blank.
        </div>
      </div>
      <div class="field col-12 md:col-4 lg:col-4">
        <span class="p-float-label">
          <p-dropdown
            [disabled]="isReadonly"
            autoWidth="false"
            [style]="{ width: '100%' }"
            optionLabel="dataframe_type"
            optionValue="dataframe_id"
            [autoDisplayFirst]="false"
            [options]="this.dataframe"
            #frame="ngModel"
            id="frame"
            name="frame"
            [(ngModel)]="flightdataredaction.dataframe_id"
            [required]="true"
            (onChange)="handleDataFrameChange($event)"
            [required]="true"
          ></p-dropdown>
          <label for="dataframe">Data Frame</label>
          <div
            class="tdy-required"
            *ngIf="
              (frame.touched || frame.dirty) && frame.hasError('required')
            "
          >
            Data Frame is required.
          </div>
        </span>
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col-12 md:col-4 lg:col-4">
        <span class="p-float-label">
          <p-dropdown
            [disabled]="isReadonly"
            #redactiontypeId="ngModel"
            [style]="{ width: '100%' }"
            optionLabel="name"
            id="redactionTypeId"
            optionValue="redactiontype_id"
            (onChange)="handleChangeRedaction($event)"
            [options]="redactionType"
            [autoDisplayFirst]="false"
            [(ngModel)]="flightdataredaction.redactiontype_id"
            [required]="true"
            id="redactionType"
            name="redactionTypeId"
            appendTo="body"
          >
          </p-dropdown>
          <label for="conversiontype">Conversion Type</label>
        </span>
        <div
          class="tdy-required"
          *ngIf="
            (redactiontypeId.touched || redactiontypeId.dirty) &&
            redactiontypeId.hasError('required')
          "
        >
          Conversion Type is required.
        </div>
      </div>
    </div>
    <div class="formgrid grid">
      <div class="field col-12 md:col-4 lg:col-4 m-0 mb-3">
        <span>
          <p-checkbox
            label="Textual Output"
            [disabled]="
              (selectedRedactionTypeId === 4 || selectedRedactionTypeId === 5) || isReadonly
            "
            [(ngModel)]="flightdataredaction.textualoutput"
            id="txtopt"
            name="txt-opt"
            [binary]="true"
            inputId="binary"
          ></p-checkbox>
        </span>
      </div>
    </div>
    <div class="mt-5">
      <div class="mb-3 flex justify-content-between align-items-center">
        <h6 class="m-0">Parameter Selection</h6>
        <div class="">
          <div class="file">
            <p-fileUpload
              [disabled]="!fileButtonEnabled || isReadonly || !empty"
              [accept]="acceptedFiles"
              [(ngModel)]="uploadfilepath.filename"
              name="filepath"
              ngDefaultControl
              #filepath="ngModel"
              #ContentUpload
              mode="basic"
              chooseLabel="Import"
              [auto]="true"
              [showCancelButton]="false"
              [fileLimit]="1"
              (onSelect)="handleUpload($event)"
              class="p-button-rounded p-button-text p-button-danger"
              id="filepath"
              url=""
            >
            </p-fileUpload>
          </div>
        </div>
      </div>
      <div class="field">
        <p-pickList
          [source]="availableDataFramelist"
          [target]="availableDataFrame"
          id="dataframe_type"
          sourceHeader="Available Parameter(s)"
          targetHeader="Selected Parameter(s)"
          [showSourceControls]="false"
          [showTargetControls]="false"
          [responsive]="true"
          [sourceStyle]="{ height: '200px' }"
          (onMoveToTarget)="handleMoveToTarget($event)"
          (onMoveToSource)="handleMoveToSource($event)"
          (onMoveAllToSource)="handleMoveAllToSource($event)"
          (onMoveAllToTarget)="handleMoveAllToTarget($event)"
          [disabled]="isReadonly"
          [targetStyle]="{ height: '200px' }"
        >
          <ng-template let-dataframe pTemplate="item">
            <ng-container>
              <div>
                <div>{{ dataframe.dataframe_type }}</div>
              </div>
            </ng-container>
          </ng-template>
        </p-pickList>
        <div class="tdy-required"
          *ngIf="
            isNew
              ? availableDataFrame.length < 1
              : availableDataFrame.length < 1 &&
                availableDataFrame.length != null
          "
        >
          At least 1 Parameter is required.
        </div>
      </div>
    </div>
    <div class="flex justify-content-end">
      <span class="p-input-icon-left">
        <button
          pButton
          pRipple
          label="Cancel"
          icon="pi pi-times"
          class="p-button-text"
          type="button"
          (click)="handleCancel()"
        ></button>
      </span>
      <span *ngIf="!isReadonly" class="p-input-icon-left p-button-text" style="float: right">
        <button
          pButton
          pRipple
          label="Reset"
          icon="pi pi-refresh"
          type="button"
          [disabled]="!dataredactionForm.dirty && isButtonDisabled"
          class="p-button-text"
          (click)="handleReset()"
        ></button>
      </span>
      <span *ngIf="!isReadonly" class="p-input-icon-left ml-3" style="float: right">
        <button
          pButton
          pRipple
          label="Save"
          type="submit"
          icon="pi pi-check"
          [disabled]="
            isNew
              ? !dataredactionForm.valid ||
                selectedDataframesIDs.length < 1 ||
                !isValid
              : !(
                  (dataredactionForm.dirty || picklistChange) &&
                  dataredactionForm.valid
                ) ||
                selectedDataframesIDs.length < 1 ||
                !isValid
          "
        ></button>
      </span>
    </div>
  </form>
</div>
