import { isInteger, isString, isNumber } from './../shared/utils';
import { Directive, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs';
import { Action, BaseViewComponent } from 'src/app/common/components/base-view.component';
import { FeatureItem } from 'src/app/common/models/feature-item.model';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { Components } from 'src/app/features/configuration/integration/configuration.components';
import { permissions } from 'src/app/features/configuration/integration/configuration.permissions';
import { DataFrameService } from 'src/app/features/configuration/services/data-frame.service';
import { SecurityUserService } from 'src/app/security/services/security-user.service';

@Directive()
export abstract class FrameDetails extends BaseViewComponent implements OnInit {
  pageLabel = 'Data Frame Details';
  pageTitle = this.pageLabel;
  order!: any;
  frameId: number;
  versionId: number;
  isMapView = false;
  messageLabel: string;

  dataFrameMapView: FeatureItem;
  dataFrameListView: FeatureItem;

  isNumber = isNumber;
  isString = isString;
  isInteger = isInteger;

  constructor(
    messageService: MessageService,
    confirmationService: ConfirmationService,
    router: Router,
    private route: ActivatedRoute,
    breadcrumbService: BreadcrumbService,
    private realUserService: SecurityUserService,
    private dataframeservice: DataFrameService
  ) {
    super(messageService, confirmationService, router, breadcrumbService);
  }

  abstract formReset();
  abstract processDataFrame(response: any);

  ngOnInit(): void {
    this.setBreadCrumbs();
    this.userCanManage = this.realUserService.userHasPermission(permissions.dataframes.data_frame_manage);
    this.userCanView = this.realUserService.userHasPermission(permissions.dataframes.data_frame_view);
    this.getDataFrame(this.frameId, this.versionId);
  }

  setBreadCrumbs() {
    this.breadcrumbService.setItems(this.route, [
      { label: Components.Dataframe.label, routerLink: Components.Dataframe.path },
      {
        label: `Versions (${this.route.snapshot.queryParamMap.get('Name')})`,
        routerLink: Components.DataframeVersions.path,
        queryParams: {
          a: this.route.snapshot.queryParamMap.get('frame'),
          Name: this.route.snapshot.queryParamMap.get('Name'),
        },
      },
      {
        label:
          this.route.snapshot.queryParamMap.get('Name') +
          ` (Version ${this.route.snapshot.queryParamMap.get('version_index')})`,
        routerLink: this.dataFrameListView.path,
        queryParams: {
          Name: this.route.snapshot.queryParamMap.get('Name'),
          frame: this.route.snapshot.queryParamMap.get('frame'),
          version: this.route.snapshot.queryParamMap.get('version'),
          version_index: this.route.snapshot.queryParamMap.get('version_index'),
        },
      },
      { label: this.pageLabel },
    ]);
    this.frameId = parseInt(this.route.snapshot.queryParamMap.get('frame'));
    this.versionId = parseInt(this.route.snapshot.queryParamMap.get('version'));
    const getIsMap = +this.route.snapshot.queryParamMap.get('isMapView');
    if (getIsMap == 1) {
      this.isMapView = true;
    }
  }

  getDataFrame(frameId: number, versionId: number) {
    this.loadingCount++;
    this.dataframeservice
      .IdentifyDataFrame(frameId, versionId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (data) => {
          const response = JSON.parse(data.content);
          this.processDataFrame(response);
        },
        error: (err) => {
          this.showErrorMsg(err, Action.Get, `${this.messageLabel}`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        },
      });
  }

  onKeySearch(event: any) {
    return (
      (event.charCode != 8 && event.charCode == 0 && event.charCode != 109 && event.charCode != 69) ||
      (event.charCode >= 48 && event.charCode <= 57) ||
      event.charCode == 190
    );
  }

  formCancel() {
    this.formReset();
    this.backToScreen();
  }

  backToScreen() {
    const path = this.isMapView ? this.dataFrameMapView.path : this.dataFrameListView.path;
    this.navigateToPath(path, {});
  }

  navigateToPath(path: string, extraQueryParams: any) {
    const queryParams = {
      Name: this.getQueryParam('Name'),
      frame: this.getQueryParam('frame'),
      version: this.getQueryParam('version'),
      version_index: this.getQueryParam('version_index'),
    };
    this.router.navigate([path], {
      queryParams: { ...queryParams, ...extraQueryParams },
      state: {},
    });
  }

  getQueryParam(queryParam: string): string {
    return this.route.snapshot.queryParamMap.get(queryParam);
  }
}
