import { Application } from "./application";

export enum OrganizationType {
    'Airline' = 1,
    'Teledyne' = 2,
    'Company' = 3
}
export class Organization {
    id: number;
    code: string;
    name: string;
    web_logo?: string;
    mobile_logo?: string;
    airline_iata_code?: string;
}

export class OrganizationApp extends Application {
    number_of_users?: number;
    expiry_date?: any;
    number_of_aircrafts?: number;
    aircraftLimitInvalid?: boolean;
    usersLimitInvalid?: boolean;
}
