import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../../../common/services/base.service';
import { catchError } from 'rxjs/operators';
import {
  DataDeliveryAlerts as AlertsModel,
  Datadelivery_alert_type as AlertType,
  ResponseDDSAlerts as AlertResponse,
} from '../models/DatadeliveryAlerts';
@Injectable({
  providedIn: 'root',
})
export class CommonAlertingService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  setBaseUrl(url: string) {
    this.baseUrl = url;
  }

  getAlertType(): Observable<AlertType[]> {
    let url = this.baseUrl + 'alert-type';
    return this.http
      .get<AlertType[]>(url, {
        headers: this.httpGetHeadersForJSON(),
      })
      .pipe(catchError(this.httpHandleError));
  }

  getRecordingAlerts(): Observable<AlertsModel[]> {
    let url = this.baseUrl + 'alert';
    return this.http
      .get<AlertsModel[]>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError));
  }

  getAlerts_by_id(alert_id: number): Observable<AlertsModel> {
    let url = this.baseUrl + 'alert' + `/${alert_id}`;
    return this.http
      .get<AlertsModel>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError));
  }

  addRecordingAlert(responseAlerts: AlertResponse) {
    const url = this.baseUrl + 'alert';
    return this.http
      .post<number>(url, responseAlerts, {
        headers: this.httpGetHeadersForJSON(),
      })
      .pipe(catchError(this.httpHandleError));
  }

  updateRecordingAlert(responseAlerts: AlertResponse) {
    const url = this.baseUrl + 'alert';
    return this.http
      .put<number>(url, responseAlerts, {
        headers: this.httpGetHeadersForJSON(),
      })
      .pipe(catchError(this.httpHandleError));
  }

  public deleteRecordingAlert(alert_id: number) {
    const url = this.baseUrl + 'alert/' + `${alert_id}`;
    const options = { headers: this.httpGetHeadersForJSON() };
    return this.http
      .delete(url, options)
      .pipe(catchError(this.httpHandleError));
  }
}
