
import { Directive, ElementRef, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { ConfirmationService, Message, MessageService } from "primeng/api";
import { FileUpload } from "primeng/fileupload";
import { takeUntil } from "rxjs";
import { BreadcrumbService } from "src/app/common/services/breadcrumb.service";
import { UserService } from "src/app/features/administration/services/user.service";
import { FileSizeFormatPipe } from "src/app/platform/pipes/file-size-format.pipe";
import { Action, BaseViewComponent } from "src/app/common/components/base-view.component";
import { IComponentDetails } from "../input-model/IComponentInput";
import { Buffer} from 'buffer'
import { Organization } from "src/app/features/administration/models/organization";
import { FileType, RecorderType } from "../../../models/dataframeEnums.enum";

@Directive()
export  class BaseDetailsComponent<T,A extends Organization> extends BaseViewComponent  {
 pageTitle: string;

 record: T;
 recordFile: File = null
 id = (obj : Object) => obj[`${this.componentInput.modelName}_id`];
 name = (obj: Object) => obj[`name`];
 version = (obj: Object) => obj[`version`];

 isSaveDisabled =true;


 maxFileSize :number;

 allowedFileType: string;
 FileToSave : any;
 recordType = "";

 originalSelectedAirlines : A[] = [];
 availableAirlines : A[] = [];

 airlines : A[] = [];
 selectedAirlines : A[] = [];
 listOfAirlineIDs: number;

 fileButtonEnabled = false

 isClone = false;
 newCloneName = "";
 cloneErrMsg : Message[];
 filename : string;

 @ViewChild('ContentUpload') content : FileUpload;
 @ViewChild('addForm') addForm: NgForm;
 @ViewChild('Name') NameControl: ElementRef;

 constructor(

    messageService: MessageService,
    confirmationService: ConfirmationService,
    router: Router,
    breadcrumbService: BreadcrumbService,
    private fileSizeFormatter: FileSizeFormatPipe,
    private componentInput: IComponentDetails<T,A>

){
    super(messageService,confirmationService,router,breadcrumbService)

    this.maxFileSize = componentInput.FileMaxSize;
    this.allowedFileType =  componentInput.AllowedFileType.join(',');



}
/**
 * Add New Section Implementation
 */
onSubmit(){
  this.loadingCount++;

    if (this.isNew){

      // if(this.containsSpecialChars(this.record["name"]) || this.hasWiteSpace(this.record["name"]))
      //    {this.reportError(`name should not contain special character or trailing and leading space`,`Invalid name`);
      //    this.loadingCount--;
      //    return;
      //   }
      this.record["filename"] = this.filename;
      this.componentInput.addRecord(this.record,this.FileToSave,this.recordType).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
       next: res=>{
        this.showSuccessMsg(Action.Add, `${this.componentInput.InputClassName}`, `${this.record["name"]}`);
        this.isSaveDisabled = true;
        this.record["name"] = "";
        this.record["version"] = null;
        this.record["filename"]="";
        this.FileToSave = "";
        this.content.clear();
        this.fileButtonEnabled =false;
        this.addForm.resetForm()
       },
       error: err=>{
        this.showErrorMsg(`${err}`, Action.Add, `${this.componentInput.InputClassName}`, `${this.name(this.record)}`);
        this.loadingCount--;
      },
       complete: () =>  this.loadingCount--
      })

   }
   else{

    //const airlinesId = `[${this.selectedAirlines.map(x=>x["airline_id"]).join(",")}]`
    const airlinesId = this.selectedAirlines.map(x=>x["id"])
    const id = this.id(this.record);


    this.componentInput.manageRecord(id,airlinesId)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe({
      next: res=>{
        this.showSuccessMsg(Action.Update, `${this.componentInput.InputClassName}`, `${this.name(this.record)}`);

        if(this.selectedAirlines.length == 0) {
          this.originalSelectedAirlines = []
        }else {
          this.originalSelectedAirlines = Object.assign([], this.selectedAirlines);
        }
        this.isSaveDisabled = true;

      },
      error: err=>{
      this.showErrorMsg(`${err}`, Action.Update, `${this.componentInput.InputClassName}`, `${this.name(this.record)}`);
      this.loadingCount--;
    },
      complete: () =>this.loadingCount--

    })

   }

}

valuechange($event){

  this.fileButtonEnabled = $event.target.value.trim() !== ""
  this.FileToSave = "";
  this.record["version"] = ""
  this.content.clear();
  this.isSaveDisabled = true;

}
onCancel(){
  if(!this.isSaveDisabled) {
    this.confirmationService.confirm({
        message: `Are you sure you want to cancel adding ${this.componentInput.InputClassName} ?`,
        header: 'Cancel?',
        rejectButtonStyleClass: 'p-button-text',
        accept: () => {
          this.router.navigate([this.componentInput.componentLink]).then();
        }
      });
      }
      else{
        this.router.navigate([this.componentInput.componentLink]).then();
      }
    }

   // Generic function return recrod with id if exists otherwise return new object
load_Record(Id : number)  {
    // Add new section
    if(Id < 0){
      this.isNew=true;
      this.record = {} as T;

      this.pageTitle = `New ${this.componentInput.InputClassName}`;
    //  this.breadcrumbService.pushItems([{label : `${this.pageTitle}`}]);


    }
    else{// Manage section

        this.loadingCount++;
        this.isNew = false;
        this.pageTitle = `Manage ${this.componentInput.InputClassName} `;
        //this.breadcrumbService.pushItems([{label : `${this.pageTitle} (${Name})` }]);

        this.componentInput.getRecord(Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
        next: res=> {

          this.record = { ...res.model } ;

          this.airlines = res.airlines;
          this.originalSelectedAirlines = res.model["airlines"];
          this.selectedAirlines = Object.assign([], res.model["airlines"]);
          this.listOfAirlineIDs = this.selectedAirlines.length;

          this.setAirlinesLists(this.selectedAirlines);


          this.updateBreadcrumb(`Manage ${this.componentInput.InputClassName} (${this.record['name']})`)
         },
        error: err => {
            this.showErrorMsg(`${err}`, Action.Get, `${this.componentInput.InputClassName}`, `${Id}`);
            this.loadingCount--;
        },
        complete: () => {
            this.loadingCount --;
        }
        })
    }
   }


onFileSelect(event){
    // assign FileToSave
    if (this.LoadFileToSave(event)) return;
     this.loadingCount++;
     this.isSaveDisabled = true;
     this.loadingCount--;
     this.isSaveDisabled = false;
}
setVersion(name){
  this.componentInput.getRecordVersions(name).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
    next: res => {

      this.isSaveDisabled = false;
      this.record["version"] = res["maximum_version"] + 1;

    },
    error: err =>{
       this.showErrorMsg(`${err}`, Action.Get, `version of `, `${name}`);
       this.loadingCount--;
    },
    complete: () =>{
        this.loadingCount--;
    }
 }

 );
}
    LoadFileToSave(event): boolean {
      // Check the format of the file
      const fileType = `.${event.files[0].name.split('.').pop().toUpperCase()}`;
      this.filename = event.files[0].name;
      this.recordType = "";

      switch (fileType) {
          case FileType.FAP:
              this.recordType = RecorderType.A717;
              break;
          case FileType.TDF:
              this.recordType = RecorderType.TDF;
              break;
          case FileType.PRM:
              this.recordType = RecorderType.PRM;
              break;
          case FileType.CSV:
              this.recordType = RecorderType.DXS;
              break;
          /* case "xml":
              // we have to check the header to find out
              this.recordType = "-A767";
              break; */
          default:
              this.recordType = "";
              break;
      }

      if (!this.componentInput.AllowedFileType.includes(fileType)) {
          this.messageService.add({
              key: 'message',
              severity: 'error',
              summary: 'Incorrect File Type',
              detail: `${event.files[0].name} is not the correct type. Only ${this.allowedFileType} types are supported.`
          });
          return true;
      }

      const file = event.files[0];
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
          this.FileToSave = reader.result;
          this.FileToSave = this.FileToSave.replace('data:text/xml;base64,', '');
          this.FileToSave = this.FileToSave.replace('data:text/csv;base64,', '');
          this.FileToSave = this.FileToSave.replace('data:application/FAP;base64,', '');
          this.FileToSave = this.FileToSave.replace('data:application/FAP;base64', '');

          if (fileType === FileType.XML) {
            const content = Buffer.from(this.FileToSave, 'base64').toString('utf-8');

            if (!content.includes("utf-8") && !content.includes("UTF-8")) {
                this.showErrorMsg(`File encoding error, file is not UTF-8 encoded`, Action.Upload, null);
                return;
            }
            switch (true) {
                case content.includes('FRED767'):
                    this.recordType = RecorderType.A767;
                    break;
                case content.includes('FRED717'):
                    this.recordType = RecorderType.A717;
                    break;
                default:
                    this.showErrorMsg("File content error, file content is not for A717 or A767", Action.Upload, null);
                    return;
            }
        }
      }
  }

  onFileRemove(){
      this.isSaveDisabled = true;

      this.record["version"] = null;
      this.recordType = "";
      this.fileButtonEnabled = this.record["name"] != "" ;
  }

  onReset(){
    if(this.isNew){
      this.onFileRemove();
      this.record["name"] = "";
      this.content.clear();
      this.fileButtonEnabled =false;
      this.addForm.resetForm()
    }
    else {
      const selected = Object.assign([],this.originalSelectedAirlines)
      this.setAirlinesLists(selected)
      this.isSaveDisabled = true;
    }
  }

/**
 *  Manage Section Implementation
 */
  onMoveToTarget(){
      //this.isSaveDisabled = this.compareList(this.selectedAirlines,this.originalSelectedAirlines);
      this.selectedAirlines.forEach(ele => {
        this.listOfAirlineIDs++;
      })
      this.selectedAirlines.sort((a,b)=> a.name.localeCompare(b.name))
      this.selectedAirlines.length > 0 ? this.isSaveDisabled = false : this.isSaveDisabled = true ;
  }

  onMoveToSource(event){
    event.items.forEach(ele => {
      this.listOfAirlineIDs--;
    })
    this.availableAirlines.sort((a,b)=> a.name.localeCompare(b.name))
    this.selectedAirlines.length < 1 ? this.isSaveDisabled = true : this.isSaveDisabled = false;
  }

  setAirlinesLists(selectedAirlines){
  let airlines = Object.assign([],this.airlines);
    if(selectedAirlines.length != 0){
        selectedAirlines.forEach(element => {
            airlines = this.removeItemFromList(element,airlines);
            });
    }

    this.selectedAirlines = selectedAirlines.sort((a,b)=>(a.name < b.name)? -1: 1);
    this.availableAirlines = airlines.sort((a,b)=>(a.name < b.name)? -1: 1);


 }
  compareList(list1: any[],list2: any[]) {
    return list1.sort().length === list2.sort().length && list1.every(function(value,index){return value ===list2[index]});
  }


  removeItemFromList(item,list){
    return list = list.filter(x=>x["id"] != item["id"]);
  }


  /**
   * Clone Section Implementation - Data frame Section Only
   */
   onCancelClone() {}

   executeClone()  {}


containsSpecialChars(str) {
  return (str.includes("<") || str.includes(">") || str.includes(":") || str.includes('"') || str.includes("/") || str.includes("\\") || str.includes("|") || str.includes("?") || str.includes("*"));
}
hasWiteSpace(s){
  return s.indexOf(' ') == 0 || s.indexOf(' ') >= s.trim().length
}
}


