import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, Observable } from "rxjs";
import { AppsAssignedToAircraft } from "../models/aircraft";
import { environment } from "src/environments/environment";
import { OrganizationApp } from "../models/organization";
import { OrganizationType } from "../models/organization";
import { BaseService } from "../../../common/services/base.service";

@Injectable({
  providedIn: "root"
})

export class ApplicationService extends BaseService {

  constructor(private http: HttpClient) {
    super()
  }

  getAirlineAircraftApps(airlineId: number): Observable<AppsAssignedToAircraft[]> {
    const url = environment.apiUrl.admin + 'airline-aircraft-applications' + `/${airlineId}`;
    return this.http.get<AppsAssignedToAircraft[]>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError)
      );
  }

  getCompanyAircraftApps(companyId: number): Observable<AppsAssignedToAircraft[]> {
    const url = environment.apiUrl.admin + 'company-aircraft-applications' + `/${companyId}`;
    return this.http.get<AppsAssignedToAircraft[]>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError)
      );
  }

  getCurrentAirlineAircraftApps(): Observable<AppsAssignedToAircraft[]> {
    const url = environment.apiUrl.admin + 'current-airline-aircraft-applications';
    return this.http.get<AppsAssignedToAircraft[]>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError)
      );
  }

  getCurrentCompanyAircraftApps(): Observable<AppsAssignedToAircraft[]> {
    const url = environment.apiUrl.admin + 'current-company-aircraft-applications';
    return this.http.get<AppsAssignedToAircraft[]>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError)
      );
  }

  updateAirlineAircraftApps(aircraftApps: any): Observable<any> {
    const url = environment.apiUrl.admin + 'airline-aircraft-applications';
    const aircraftAppData = JSON.stringify(aircraftApps);
    return this.http.post<any>(url, aircraftAppData, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError)
      )
  }

  updateCompanyAircraftApps(aircraftApps: any): Observable<any> {
    const url = environment.apiUrl.admin + 'company-aircraft-applications';
    const aircraftAppData = JSON.stringify(aircraftApps);
    return this.http.post<any>(url, aircraftAppData, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError)
      )
  }

  // get applications assigned to an organization
  getAssignedOrgApps(id: number, orgType: OrganizationType): Observable<OrganizationApp[]> {
    const url = environment.apiUrl.admin + this.getOrgEndPoint(orgType) + `/${id}`;
    return this.http.get<OrganizationApp[]>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError)
      )
  }

  // get assigned applications for currently assigned organization ...
  getCurrentAssignedOrgApps(orgType: OrganizationType): Observable<OrganizationApp[]> {
    let url;
    if (orgType === OrganizationType.Airline) {
      url = environment.apiUrl.admin + 'current-application-airline'
    } else {
      url = environment.apiUrl.admin + 'current-application-company'
    }

    return this.http.get<OrganizationApp[]>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError)
      )
  }

  // call assignAppsToOrganization on create Airline/Company
  assignAppsToOrganization(id: number, apps: OrganizationApp[], orgType: OrganizationType): Observable<string> {
    const url = environment.apiUrl.admin + this.getOrgEndPoint(orgType) + `/${id}`;
    const airlinData = JSON.stringify(apps)
    return this.http.post<string>(url, airlinData, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError)
      )
  }

  // call updateOrganizationApps on update Airline/Company
  updateOrganizationApps(id: number, apps: OrganizationApp[], orgType: OrganizationType): Observable<string> {
    const url = environment.apiUrl.admin + this.getOrgEndPoint(orgType) + `/${id}`;
    const airlinData = JSON.stringify(apps)
    return this.http.put<string>(url, airlinData, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError)
      )
  }

  private getOrgEndPoint(orgType: OrganizationType): string {
    let endpoint = "";
    switch (orgType) {
      case OrganizationType.Airline:
        endpoint = 'application-airline';
        break;
      case OrganizationType.Company:
        endpoint = 'application-company';
        break;
    }
    return endpoint;
  }

}
