<p-confirmDialog></p-confirmDialog>

<div class="card-header m-2 mb-3">
  <h5>User Notifications</h5>
</div>

<p-splitter
  *ngIf="areTemplatesLoaded && notifications.length > 0"
  [style]="{ height: '75vh' }"
  [panelSizes]="[30, 70]"
  styleClass="mb-5 notificatio_splitter"
>
  <ng-template pTemplate>
    <app-notifications-page-left-pane
      class="left-notifications-pane"
      [notifications]="notifications"
      [selectedNotifications]="selectedNotifications"
      [selectAll]="selectAll"
      (bulkDelete)="bulkDelete()"
      (bulkMarkAsRead)="bulkMarkAsRead()"
      (sortOptionChange)="onSortOptionChange($event)"
      (toggleSelectNotification)="toggleSelectNotification()"
      (toggleSelectAll)="toggleSelectAll($event)"
      (openNotification)="openNotification($event)"
      (deleteNotification)="deleteNotification($event)"
    ></app-notifications-page-left-pane>

    <ng-template #noNotifications>
      <div
        class="no-notifications-container"
        style="padding: 16px; text-align: center"
      >
        <p class="no-notifications-message">
          There are no notifications to show
        </p>
      </div>
    </ng-template>
  </ng-template>

  <ng-template pTemplate *ngIf="currentOpenNotification !== null">
    <app-notifications-page-right-pane
      class="right-notifications-pane"
      [currentOpenNotification]="currentOpenNotification"
      [currentOpenEmailTemplate]="currentOpenEmailTemplate"
      (deleteNotification)="deleteNotification($event)"
      (toggleSingleNotificationPriority)="toggleSingleNotificationPriority($event)"
    ></app-notifications-page-right-pane>
  </ng-template>
</p-splitter>

<p-blockUI [blocked]="!areTemplatesLoaded">
  <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>

<div *ngIf="areTemplatesLoaded && notifications.length === 0" class="no-notifications-message">
  <p>There are no notifications to show</p>
</div>
