<p-blockUI [blocked]="loadingCount > 0">
    <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<p-confirmDialog></p-confirmDialog>

<div *ngIf="engineType" class="col-12 card p-3">
    <form #engineTypeForm="ngForm" (ngSubmit)="onSubmit();" novalidate>
        <div class="card-header m-2">
            <h5>{{pageTitle}}</h5>
        </div>
        <div class="grid mt-2">
            <div class="col-12 lg:col-6 p-2">
                <div class="card mb-0">
                    <h6>Details</h6>
                        <div class="p-float-label">
                            <input #engineTypeName="ngModel" [(ngModel)]="engineType.enginetype_name"
                                [disabled]="!userCanAdd && !userCanModify" [style]="{'width':'100%'}"
                                [textContent]="engineType.enginetype_name" maxlength="256" name="engineTypeName" pInputText
                                required pattern=".*\S.*" type="text" [disabled]="isReadOnly" />
                            <label for="engineTypeName">Name</label>
                        </div>
                        <div *ngIf="(engineTypeName.touched || engineTypeName.dirty) && engineTypeName.hasError('required')"
                            class="tdy-required">
                            Name is required
                        </div>
                        <div *ngIf="engineTypeName.hasError('pattern')" class="tdy-required">
                            Name cannot be blank
                        </div>
                </div>
            </div>
        </div>


        <div class="flex justify-content-end">
            <span class="p-input-icon-left">
                <button (click)="onCancel()" class="p-button-text" icon="pi pi-times" id="cancelButton" label="Cancel"
                    pButton pRipple type="button"></button>
            </span>
            <span class="p-input-icon-left">
                <button *ngIf="!isReadOnly" (click)="onReset()" class="p-button-text" icon="pi pi-undo" id="resetButton" label="Reset" pButton pRipple
                [disabled]="!engineTypeForm.dirty"    
                type="button"></button>
            </span>

            <span class="p-input-icon-left pl-2">
                <button *ngIf="!isReadOnly"
                [disabled]="isNew? !engineTypeForm.valid : !(engineTypeForm.dirty && engineTypeForm.valid)" icon="pi pi-check"
                    id="okButton" label="Save" pButton pRipple type="submit"></button>
            </span>
        </div>
    </form>
</div>