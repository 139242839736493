<form #settingsForm="ngForm">
    <div class="col-12 grid mt-2">
        <div class="card grid col-6">
            <div class="card-header flex align-items-center width-100 p-text-bold">
                Application Settings
            </div>
            <div>
                <div *ngFor="let setting of settings" class="flex align-items-center width-100 mb-3">
                    <div *ngIf="setting.settingdefinition_datatype === 'boolean'">
                        <p-checkbox ngModel [(ngModel)]="setting.setting_value" [label]="setting.settingdefinition_name"
                            pTooltip="{{setting.settingdefinition_description}}" [binary]="true"
                            name="{{setting.settingdefinition_id}}" (ngModelChange)="onSettingsChanged(setting)" [disabled]="!isSettingEditable"></p-checkbox>
                    </div>
    
                    <div *ngIf="setting.settingdefinition_datatype !== 'boolean'" class="p-float-label"
                        [style]="{'min-width': '300px'}">
                        <input ngModel *ngIf="setting.settingdefinition_datatype == 'string'" [(ngModel)]="setting.setting_value"
                            [disabled]="!setting.canEdit || !isSettingEditable"
                            pTooltip="{{setting.settingdefinition_description}}" name="{{setting.settingdefinition_id}}"
                            [id]="setting.settingdefinition_id" class="p-inputtext" type="text" maxlength="100"
                            style="width: 100%;" pInputText (ngModelChange)="onSettingsChanged(setting)">
    
                        <input ngModel *ngIf="setting.settingdefinition_datatype === 'password'" [(ngModel)]="setting.setting_value"
                            [disabled]="!setting.canEdit || !isSettingEditable" [textContent]="setting.setting_value"
                            pTooltip="{{setting.settingdefinition_description}}" maxlength="100" pInputText
                            style="width: 100%;" type="password" name="{{setting.settingdefinition_id}}" (ngModelChange)="onSettingsChanged(setting)" />
    
                        <div
                            *ngIf="setting.settingdefinition_datatype === 'double' || setting.settingdefinition_datatype === 'float' || setting.settingdefinition_datatype === 'number' ">
                            <div class="p-float-label">
                                <input #timenum="ngModel" [(ngModel)]="setting.setting_value"
                                    pTooltip="{{setting.settingdefinition_description}}"
                                    [disabled]="!isSettingEditable"
                                    [textContent]="setting.setting_value" onkeypress="return event.charCode >= 48 && event.charCode <= 57" min="1" step="1" pattern="^(0|[1-9]\d{0,14})$"  type="number" style="width: 100%;" pInputText
                                    id="{{setting.settingdefinition_id}}"
                                    name="{{setting.settingdefinition_id}}" required (ngModelChange)="onSettingsChanged(setting)"/>
                                <label [for]="setting.settingdefinition_id">{{setting.settingdefinition_name}}</label>
                            </div>
                            <div *ngIf="(timenum.touched || timenum.dirty) && timenum.hasError('required')"
                                class="tdy-required">
                                {{setting.settingdefinition_name}} is required
                            </div>
                            <div *ngIf="timenum.hasError('min')" class="tdy-required">
                                {{setting.settingdefinition_name}} should be greater than 0
                            </div>
                            <div *ngIf="timenum.hasError('pattern')" class="tdy-required">
                                {{setting.settingdefinition_name}} limit exceeds
                            </div>
                        </div>
                    </div>
    
                    <div *ngIf="setting.settingdefinition_datatype !== 'upload'">
                        <button (click)="onSetToDefault(setting)"
                            [disabled]="setting.settingdefinition_defaultvalue == setting.setting_value || !isSettingEditable"
                            class="p-button-rounded p-button-text pl-3" icon="pi pi-refresh" id="btnSetDefault" pButton
                            pRipple pTooltip="Set to the default value" type="button"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
