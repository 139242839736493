export interface IColHeadItem {
  header: string;
}

export const DEFAULT_BIT_LENGTH = 12;

export const colHeadList: IColHeadItem[] = [
  {
    header: 'Bit 1',
  },
  {
    header: 'Bit 2',
  },
  {
    header: 'Bit 3',
  },
  {
    header: 'Bit 4',
  },
  {
    header: 'Bit 5',
  },
  {
    header: 'Bit 6',
  },
  {
    header: 'Bit 7',
  },
  {
    header: 'Bit 8',
  },
  {
    header: 'Bit 9',
  },
  {
    header: 'Bit 10',
  },
  {
    header: 'Bit 11',
  },
  {
    header: 'Bit 12',
  },
];
