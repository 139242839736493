<p-blockUI [blocked]="loadingCount > 0">
  <tdy-spinner
    style="position: absolute; left: 50%; top: 50%; z-index: 10000"
  ></tdy-spinner>
</p-blockUI>
<div class="col-12 card">
  <div class="card-header m-2">
    <h5>SAR Redactions</h5>
  </div>
  <app-sarredaction-table
    [SARredactions]="SARredactions"
    [defaultTableRows]="defaultTableRows"
    [currentPageReport]="currentPageReport"
    [pageTitle]="pageTitle"
    [canAdd]="false"
    [canDelete]="false"
    [jsonParameters]="jsonParameters"
    [messageLabel]="messageLabel"
    (onRowSelectEvent)="onRowSelect($event)"
  ></app-sarredaction-table>
</div>

<div
  *ngIf="SARredactions.length > 0 && defaultTableRows >= SARredactions.length"
  style="text-align: center"
>
  <p class="row-paging">
    Showing 1 to {{ SARredactions.length }} of {{ SARredactions.length }}
    {{ pageTitle }}
  </p>
</div>
