<p-blockUI [blocked]="loadingCount > 0">
    <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<p-confirmDialog></p-confirmDialog>

<div *ngIf="aircraftGroup" class="col-12 card p-3">
    <form #aircraftGroupForm="ngForm" (ngSubmit)="onSubmit();" novalidate>
        <div class="card-header m-2">
            <h5>{{pageTitle}}</h5>
        </div>

        <div class="card mb-0">
            <h6>Details</h6>
            <div class="formgrid grid">
                <div class="field col-12 lg:col-5">
                    <span class="p-float-label">
                        <input pInputText type="text" name="aircraftGroupName" id="aircraftGroupName"
                            #aircraftGroupName="ngModel" [(ngModel)]="aircraftGroup.aircraftgroup_name"
                            [textContent]="aircraftGroup.aircraftgroup_name" [disabled]="!userCanAdd && !userCanModify"
                            [style]="{'width': '100%'}" maxlength="32" required pattern=".*\S.*" [disabled]="isReadOnly"/>
                        <label for="aircraftGroupName">Name</label>
                    </span>
                    <div class="tdy-required"
                        *ngIf="(aircraftGroupName.touched || aircraftGroupName.dirty) && aircraftGroupName.hasError('required')">
                        Aircraft Group Name is required
                    </div>
                    <div class="tdy-required" *ngIf="aircraftGroupName.hasError('pattern')">
                        Aircraft Group name cannot be blank
                    </div>
                </div>

                <div class="field col-12 lg:col-7">
                    <span class="p-float-label">
                        <input pInputText type="text" style="width: 100%; font-size: small"
                            name="aircraftGroupDescription" id="aircraftGroupDescription"
                            [(ngModel)]="aircraftGroup.aircraftgroup_description" [disabled]="isReadOnly"/>
                        <label>Description</label>
                    </span>
                </div>
            </div>
        </div>

        <div class="card">
            <h6>Aircraft Selection</h6>
            <div class="field">
                <p-pickList [disabled]="isReadOnly" name="aircraft" id="aircraft" [source]="aircraft" [target]="aircraftGroup.aircraft"
                    filterBy="aircraft_registration" sourceHeader="Available Aircraft" targetHeader="Selected Aircraft"
                    [showSourceControls]="false" [showTargetControls]="false" (onMoveToTarget)="onAircraftChange()"
                    (onMoveToSource)="onAircraftChange()" (onMoveAllToSource)="onAircraftChange()"
                    (onMoveAllToTarget)="onAircraftChange()">
                    <ng-template let-ac pTemplate="item">
                        <ng-container *ngIf="toAircraft(ac); let ac">
                            <div>
                                <div>{{ac.aircraft_registration}}</div>
                            </div>
                        </ng-container>
                    </ng-template>
                </p-pickList>
            </div>
        </div>

        <div class="flex justify-content-end">
            <span class="p-input-icon-left">
                <button (click)="onCancel()" class="p-button-text" icon="pi pi-times" id="cancelButton" label="Cancel"
                    pButton pRipple type="button"></button>
            </span>
            <span class="p-input-icon-left">
                <button *ngIf="!isReadOnly" (click)="onReset()" class="p-button-text" icon="pi pi-undo" id="resetButton" label="Reset"
                    [disabled]="!(aircraftGroupForm.dirty || aircraftChange)" pButton pRipple type="button"></button>
            </span>

            <span class="p-input-icon-left pl-2">
                <button *ngIf="!isReadOnly"
                    [disabled]="isNew? !aircraftGroupForm.valid : !((aircraftGroupForm.dirty || aircraftChange) && aircraftGroupForm.valid)"
                    icon="pi pi-check" id="okButton" label="Save" pButton pRipple type="submit"></button>
            </span>
        </div>
    </form>
</div>