import { NotificationsPageModule } from 'src/app/common/components/common/notifications-page/notifications-page.module';
import { NotificationsViewForSendToTeledyneComponent } from './notifications-view.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
    declarations: [NotificationsViewForSendToTeledyneComponent],
    imports: [CommonModule, NotificationsPageModule] 
})

export class NotificationsViewForSendToTeledyneModule {}