<ul
  class="layout-topbar-action-panel p-shadow-6"
  [@topbarActionPanelAnimation]="'visible'"
  *ngIf="appMain.activeTopbarItem === 'notifications'"
>
  <li class="p-3 flex justify-content-between align-items-center">
    <span class="text-lg font-medium">Notifications</span>
    <span class="text-green-600">
      <span class="font-bold">{{ unreadNotifications }}</span>
      unread notifications.
    </span>
  </li>

  <li *ngIf="notifications && notifications.length > 0; else noNotificationsTemplate">
    <div class="notification_message_wrapper p-0">
      <ng-container *ngFor="let group of notificationsGroupedByDate">
        <h4 class="day_title text-sm font-medium">{{ group.date }}</h4>
        <div *ngFor="let notification of group.notifications" class="notification_message p-0">
          <div class="notification_details flex justify-content-between" [ngClass]="{ 'new_noti': !notification.seen }" (click)="handleNotificationClick(notification.id)">
            <div class="p-0">
                <div class="header p-0 font-medium pb-0 pt-2 flex justify-content-between align-items-center">
                    <span>{{ notification.alert_name }}</span>
                  </div>
                  <div class="desc p-0 pt-1 flex justify-content-between align-items-center">
                    <span>{{ getConcatenatedNotificationMessage(notification.alert_type) }}</span>
                  </div>
            </div>
            <div class="flex flex-column justify-content-start p-0" style="white-space: nowrap;">
                <span *ngIf="shouldRenderApplicationBadge(notification.application_id)" class="text-sm font-normal notification_application_type text-right mb-3">{{ getNotificationApplication(notification.application_id) }}</span>
                <span class="text-sm font-normal text-right notification_time">{{ getNotificationTime(notification.alert_datetime) }}</span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </li>

  <ng-template #noNotificationsTemplate>
    <div class="no_notification">
      <p class="noti_alert">No New Notifications</p>
    </div>
  </ng-template>

  <li class="view_all_row p-1 pt-1 pl-3 pb-3" *ngIf="notifications && notifications.length > 0">
    <button
      id="viewAllNotificationsButton"
      label="View All"
      type="button"
      class="text-blue-700 p-0 mt-3 p-button-text fs-small"
      pButton
      pRipple
      (click)="viewAllNotifications()"
    >
    </button>
  </li>
</ul>