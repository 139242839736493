export class DataDeliveryAlerts {
    alert_id: number;
    name: string;
    enable_flag: boolean;
    time_to_look_back: number;
    rate: number;
    alert_type_id: number;
    alert_type: string;
    organization_id: number;
    clientauths :ClientAuthentication[];
}


export class ResponseDDSAlerts {
    alert_id: number;
    name: string;
    enable_flag: boolean;
    time_to_look_back: number;
    rate: number;
    alert_type_id: number;
    organization_id: number;
    client_auth_id:number[];
}

export class Datadelivery_alert_type {
    alert_type_id: number;
    alert_type: string;
}

export class ClientAuthentication{
    client_auth_id:number;
    clientname:string;
}

export enum AlertTypes{
    ProcessingFailures =1,
    NoFlights=2,
    ClientDisconneced=3
}