import { HttpBackend, HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { OktaAuthStateService } from "@okta/okta-angular";
import { AuthState } from "@okta/okta-auth-js";
import { forkJoin, map, mergeMap, Observable, of, ReplaySubject, Subscription } from "rxjs";
import { environment } from "src/environments/environment";
import { SECURITY_CONSTANTS } from "../models/security-constants";
import { DbUserInfoResult, UserInfo } from "../models/user-info.model";

@Injectable({
    providedIn: 'root'
})
export class SecurityUserService implements OnDestroy {

    // private members

    private _httpClient: HttpClient;
    private userInfoSub: ReplaySubject<UserInfo> = new ReplaySubject();

    // public properties
    public _authStateSub: Subscription;
    public userInfo: Observable<UserInfo>;
    public currentUserInfo: UserInfo;

    // lifecycle

    constructor(
        httpBackend: HttpBackend,
        _oktaAuthStateService: OktaAuthStateService,
    ) {
        // Cannot inject HttpClient directly.
        // Causes circular dependency with api http interceptor which uses this service to inject bearer token into API requests
        this._httpClient = new HttpClient(httpBackend);
        this.userInfo = this.userInfoSub.asObservable();

        this._authStateSub = _oktaAuthStateService.authState$.pipe(
            mergeMap(authState => {
                const email = authState.idToken?.claims?.email;
                const activeOrg = this.getActiveOrganization(authState.accessToken?.scopes);
                const tokenUserInfo = this.buildUserInfoFromAuthState(authState);
                const dbUserInfo = this.getUserInfo(email, activeOrg, authState.accessToken?.accessToken)
                return forkJoin([tokenUserInfo, dbUserInfo]);
            })
        )
            .subscribe(dataSet => {
                const userInfo = dataSet[0];
                userInfo.activeOrgId = dataSet[1].orgId;
                userInfo.permissions = dataSet[1].permissions;
                userInfo.orgTypeId = dataSet[1].orgTypeId;
                userInfo.userAdmin = dataSet[1].userAdmin;
                console.log(userInfo);
                this.userInfoSub.next(userInfo);
                this.currentUserInfo = userInfo;
            })
    }

    ngOnDestroy() {
        this._authStateSub.unsubscribe();
    }

    // public methods
    public userHasPermission(permission: string): boolean {
        return this.currentUserInfo?.permissions?.includes(permission) ?? false;
    }

    // private methods
    private getActiveOrganization(scopes?: string[]): string | undefined {
        if (scopes === undefined) {
            return undefined;
        }

        if (scopes.includes(SECURITY_CONSTANTS.no_org_scope)) {
            return SECURITY_CONSTANTS.no_org_scope;
        }

        return scopes.filter(o => !SECURITY_CONSTANTS.required_scopes.includes(o)).shift() ?? SECURITY_CONSTANTS.no_org_scope;
    }

    private buildUserInfoFromAuthState(authState: AuthState): Observable<UserInfo> {
        if (!authState || !authState.isAuthenticated) {
            return of({ isAuthenticated: false } as UserInfo);
        }

        const activeOrg = this.getActiveOrganization(authState.accessToken?.scopes);
        const orgs = this.splitCommaSeparatedList(authState.accessToken?.claims['orgs']?.toString());
        const isAuthenticated = !!activeOrg && activeOrg !== SECURITY_CONSTANTS.no_org_scope && !!orgs?.includes(activeOrg);

        return of({
            name: authState.idToken?.claims.name,
            email: authState.idToken?.claims.email,
            idToken: authState.idToken?.idToken,
            accessToken: authState.accessToken?.accessToken,
            orgs: orgs,
            activeOrg: activeOrg,
            isAuthenticated: isAuthenticated,
            activeOrgId: -1
        });
    }

    private getUserInfo(userEmail: string, userOrg: string, accessToken: string): Observable<DbUserInfoResult> {
        if (!userEmail || !userOrg || !accessToken || userOrg === SECURITY_CONSTANTS.no_org_scope) {
            return of({
                orgId: -1,
                orgTypeId: -1,
                userAdmin: false,
                permissions: []
            });
        }

        // ARO: securrity API has different base url than spectrum API .. hopefully can unify with custom domain in API Gateway!
        const url = `${environment.apiUrl.security}security/userpermissions`;
        // const url = environment.apiBase + SECURITY_CONSTANTS.user_permissions_endpoint;
        return this._httpClient.get<DbUserInfoResult>(url, { headers: this.httpGetAuthHeadersForJSON(accessToken) });
    }

    private httpGetAuthHeadersForJSON(accessToken: string): HttpHeaders {
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.set('Authorization', `Bearer ${accessToken}`);
        return httpHeaders;
    }

    private splitCommaSeparatedList(listString?: string): string[] | undefined {
        if (listString === undefined) {
            return undefined;
        }
        return listString.split(",");
    }
}
