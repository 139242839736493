
<div class="col-12 card">
  <div class="card-header m-2">
    <h5>Endpoints</h5>
  </div>
  <div *ngIf="(endpoints$ | async) as endpoints; else loading">
    <app-endpoints-table
      [endpoints]="endpoints"
      [readOnly]="true"
      (selectEndpoint)="onRowSelect($event)"
    ></app-endpoints-table>
  </div>
</div>

<ng-template #loading>
  <p-blockUI [blocked]="true">
    <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
  </p-blockUI>
</ng-template>
