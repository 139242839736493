<p-blockUI [blocked]="loadingCount > 0">
    <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>

<div class="card" *ngIf="loadingCount == 0">
    <div class="card-header m-2">
        <h5>{{pageTitle}}</h5>
        <p-dropdown [options]="file_dates" [(ngModel)]="selected_date" optionLabel="dte" [filter]="true"
        (onChange)="onChange($event)" tooltip="Filter by AIRAC date"
         filterBy="dte" [showClear]="false" placeholder="Select a date">
         <ng-template pTemplate="selectedItem">
             <div *ngIf="selected_date">
                 <div>{{selected_date.dte}}</div>
             </div>
         </ng-template>
         <ng-template let-filedate pTemplate="item">
             <div class="country-item">
                 <div>{{filedate.dte}}</div>
             </div>
         </ng-template>
     </p-dropdown>
    </div>
    <p-table
     #dt_airports
     [value]="data"
     dataKey="runway_identifier"
     [showCurrentPageReport]="true"
     [paginator]="data.length > defaultTableRows"
     [rowsPerPageOptions]="[20,50,100,200]"
     [rows]="defaultTableRows"
     currentPageReportTemplate="{{currentPageReport + ' ' + pageTitle}}"
     styleClass=""
     selectionMode="single" [(selection)]="selected_runway"
     (onRowSelect)="onRowSelect($event)"
     sortField="runway_identifier"
     >
        <ng-template pTemplate="header">
            <tr >
                <th pSortableColumn="trimmed_runway_identifier" [ngStyle]="{'width':'15%'}">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Identifier
                        <p-sortIcon field="trimmed_runway_identifier"></p-sortIcon>
                        <p-columnFilter type="text" field="trimmed_runway_identifier" display="menu" [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="runway_magnetic_bearing" [ngStyle]="{'width':'15%'}">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Heading
                        <p-sortIcon field="runway_magnetic_bearing"></p-sortIcon>
                        <p-columnFilter type="text" field="runway_magnetic_bearing" display="menu" [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="runway_gradient" [ngStyle]="{'width':'15%'}">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Gradient
                        <p-sortIcon field="runway_gradient"></p-sortIcon>
                        <p-columnFilter type="text" field="runway_gradient" display="menu" [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="runway_length" [ngStyle]="{'width':'15%'}">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Length (ft)
                        <p-sortIcon field="runway_length"></p-sortIcon>
                        <p-columnFilter type="numeric" field="runway_length" display="menu" [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="runway_width" [ngStyle]="{'width':'15%'}">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Width (ft)
                        <p-sortIcon field="runway_width"></p-sortIcon>
                        <p-columnFilter type="numeric" field="runway_width"
                            display="menu" [showOperator]="false" [showAddButton]="false"></p-columnFilter >
                    </div>
                </th>
                <th pSortableColumn="pi_localizer_frequency" [ngStyle]="{'width':'15%'}">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Localizer Frequency
                        <p-sortIcon field="pi_localizer_frequency"></p-sortIcon>
                        <p-columnFilter type="text" field="pi_localizer_frequency"
                            display="menu" [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="pi_glide_slope_angle" [ngStyle]="{'width':'10%'}">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Glide Path Angle
                        <p-sortIcon field="pi_glide_slope_angle"></p-sortIcon>
                        <p-columnFilter type="numeric" field="pi_glide_slope_angle"
                            display="menu" [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                    </div>
                </th>
              	<th [ngStyle]="{'width':'10%'}" *ngIf="userCanView">Procedures</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-runway>
            <tr [pSelectableRow]="runway">
                <td>
                    {{runway.trimmed_runway_identifier}}
                </td>
                <td>
                    {{runway.runway_magnetic_bearing || "-"}}
                </td>
                <td>
                    {{runway.runway_gradient || "-"}}
                </td>
                <td>
                    {{runway.runway_length || "-"}}
                </td>
                <td>
                    {{runway.runway_width || "-"}}
                </td>
                <td>
                   {{runway.pi_localizer_frequency || "-"}}
                </td>
                <td>
                    {{runway.pi_glide_slope_angle || "-"}}
                </td>
              <td *ngIf="userCanView">
                <div class="flex justify-content-start">
                  <button id="proceduresButton"
                          name="proceduresButton"
                      type="button"
                        [disabled]="!isProceduresIconEnabled(runway.has_transition, runway.has_procedure)"
                        class="p-button-rounded p-button-text "
                      pButton
                      pRipple
                        pTooltip="View List Of Procedures"
                      (click)="onProceduresClick(runway.runway_identifier)"
                    > <img src="./../../../../../assets/images/procedures.png" alt="procedures.png" border="0" style="height:30px" /></button>
                </div>
              </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr >
                <td colspan="7">No runways found.</td>
            </tr>
        </ng-template>
    </p-table>
    <div *ngIf="data.length > 0 && defaultTableRows >= data.length" style="text-align: center;">
        <p class="row-paging">Showing 1 to
          {{data.length}} of {{data.length}} {{pageTitle}}</p>
      </div>
</div>
