<p-confirmDialog header="Confirm Deletion" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-blockUI [blocked]="loadingCount > 0">
  <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<div class="col-12 card p-3">
    <div class="card-header">
        <h5>{{ pageTitle }}</h5>
      </div>
    <div class="card-header">
        <h5>Client Recording ID :{{record_id}}</h5>
        <div class="p-toolbar-group-end">
            <span>
                <a [href]="fileUrl" download="file.txt">
                <button (click)="downloadFile()" pButton pRipple type="button" label="Download"></button>
             </a>
            </span>
        </div>
        </div>

    <p-table [value]="recordingIdModel" [tableStyle]="{ 'min-width': '50rem' }">
        <ng-template pTemplate="header">
            <tr>
                <th>Endpoint Name</th>
                <th>Output File Name</th>
                <th>Endpoint Status</th>
                <th>Log File</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-deliverydata>
            <tr>
                <td>{{ deliverydata.endpoint_name}}</td>
                <td>{{ deliverydata.output_file_name}}</td>
                <td>{{ deliverydata.status}}</td>
                <td><span class="err mg">Errors: 5 </span> 
                    <span class="war mg">Warnings: 2 </span></td>
            </tr>
        </ng-template>
    </p-table>
</div>
