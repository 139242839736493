import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { BaseService } from '../../../common/services/base.service';
import { catchError, map } from 'rxjs/operators';
import { environment } from "src/environments/environment";
import {UserRecordingSubscription,ResponseUserRecordingSubscription, UsersRecordingNotifications} from '../models/userRecordingSubscriptionModel';
@Injectable({
    providedIn: 'root'
  })
  export class UserRecordingSubscriptionService extends BaseService {

    constructor(
      private http: HttpClient) {
      super();
      this.baseUrl = environment.apiUrl.datadelivery;
    }


    getUserSubscriptionByUserId(userId: number): Observable<UserRecordingSubscription[]> {
        let url = this.baseUrl + "user-recording-notifications" + `/${userId}`;
        return this.http.get<UserRecordingSubscription[]>(url, { headers: this.httpGetHeadersForJSON() })
          .pipe(catchError(this.httpHandleError)
          );
      }

      addusersubscription(usersubscription: ResponseUserRecordingSubscription) {
        const url = this.baseUrl + 'user-recording-notifications';
        return this.http.put<number>(url, usersubscription, { headers: this.httpGetHeadersForJSON() })
          .pipe(
            catchError(this.httpHandleError)
          )
      }

      getRecordingUsers(applicationId: number): Observable<UsersRecordingNotifications[]> {
        let url = this.baseUrl + "get-application-users" + `/${applicationId}`;
        return this.http.get<UsersRecordingNotifications[]>(url, { headers: this.httpGetHeadersForJSON() })
          .pipe(catchError(this.httpHandleError)
          );
      }
}