import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DisplayFormatEnum, StorageFormatEnum } from '../models/dataframe717-767';

@Injectable({
  providedIn: 'root'
})
export class TransferdataService {

  isNumberIndex = [];
  isIntegerIndex = [];
  isStringIndex = [];
  isRecording = [];
  isRaw = [];
  isDiscrete = [];

  constructor() { }
  setEUC(row, euCols) {
    let eucArray = [];
    var obj = {};
    row.forEach((params) => {
      if (params != undefined) {
        obj = {};
        euCols.map((vals, index) => {
          if (params.hasOwnProperty(vals.field) === true) {
            obj[vals.field] = params[vals.field];
          }
          else {
            obj[vals.field] = 0;
          }
          if (params.hasOwnProperty("EUC_MIN") === true) {
            obj["EUC_MIN"] = params["EUC_MIN"];
          }
          else {
            obj["EUC_MIN"] = 0;
          }
          if (params.hasOwnProperty("EUC_MAX") === true) {
            obj["EUC_MAX"] = params["EUC_MAX"];
          }
          else {
            obj["EUC_MAX"] = 0;
          }
        });
        delete obj['undefined'];
        eucArray.push(obj);
      }
    });
    return eucArray;

  }
  clearSessionStorage() {
    sessionStorage.removeItem('Mnemonics');
    sessionStorage.removeItem('FrameDetails');
    sessionStorage.removeItem('DeletedMnemonics');
  }
  // Validate Input JSON data 767
  validateStringValues(order) {
    this.isStringIndex = [];
    for (const [key, value] of Object.entries(order)) {
      if (key === "PRM_MNEMONIC") {
        if (!this.isString(value)) {
          let obj = { "key": key, "value": value }
          this.isStringIndex.push(obj)
        }
      }
      if (key === "PRM_NAME") {
        if (!this.isString(value)) {
          let obj = { "key": key, "value": value }
          this.isStringIndex.push(obj)
        }
      }
      if (key === "PRM_DESCRIPTION") {
        if (!this.isString(value)) {
          let obj = { "key": key, "value": value }
          this.isStringIndex.push(obj)
        }
      }
    }
    return this.isStringIndex;
  }
  validateNumericValues(order) {
    this.isNumberIndex = [];
    for (const [key, value] of Object.entries(order)) {
      if (key === "PRM_MIN_OP_RANGE" && order.FMT_ID === DisplayFormatEnum.Float) {
        if (!this.isNumber(value)) {
          let obj = { "key": key, "value": value }
          this.isNumberIndex.push(obj)
        }
      }
      if (key === "PRM_MAX_OP_RANGE" && order.FMT_ID === DisplayFormatEnum.Float) {
        if (!this.isNumber(value)) {
          let obj = { "key": key, "value": value }
          this.isNumberIndex.push(obj)
        }
      }
      if (order.hasOwnProperty('PRM_DECIMAL_PLACES') && order.FMT_ID_DISPLAY === DisplayFormatEnum.Float) {
        if (key === "PRM_DECIMAL_PLACES" ) {
          if (!this.isNumber(value)) {
            let obj = { "key": key, "value": value }
            this.isNumberIndex.push(obj)
          }
        }
      }
      if (key === "PRA_CONV_CONF" && order.FMT_ID == (4 || 2)) {
        if (!this.isNumber(value)) {
          let obj = { "key": key, "value": value }
          this.isNumberIndex.push(obj)
        } else {
          if (!this.isInteger(value)) {
            let obj = { "key": key, "value": value }
            this.isNumberIndex.push(obj)
          }
        }
      }
    }
    return this.isNumberIndex;
  }
  validateRecordingValues(order) {
    this.isRecording = [];
    let recording = order.LCP;
    recording.forEach((item, index) => {
      for (const [key, value] of Object.entries(item)) {
        if (key != 'LCP_SUBFRAME') {
          if (key != 'LCP_TYPE') {
            if (key != 'LCP_BIT_TARGET_LSB') {
              this.checkNumbers(value, index, key, 'Recording')
            }
          }
        }
      }
    })
    return this.isRecording;
  }
  validateRawValues(order) {
    this.isRaw = [];
    let eul = order.EUL;
    eul.forEach((item, index) => {
      for (const [key, value] of Object.entries(item)) {
        if (key === 'EUL_RAW_DATA_VALUE') {
          this.checkNumbers(value, index, key, 'Raw')
        }
        if (key === 'EUL_EU_VALUE') {
          if (!this.isNumber(value)) {
            let obj = { "index": index, "key": key, 'error': 'isNumber' }
            this.isRaw.push(obj)
          }
        }
      }

    })
    return this.isRaw;
  }
  validateDiscreteValues(order) {
    this.isDiscrete = [];
    let eul = order.TXD;
    eul.forEach((item, index) => {
      for (const [key, value] of Object.entries(item)) {
        if (key === 'TXD_RAW_DATA_VALUE') {
          this.checkNumbers(value, index, key, 'Discrete')
        }
        if (key === 'TXD_DESCRIPTION') {
          if (!this.isString(value)) {
            let obj = { "index": index, "key": key, 'error': 'isString' }
            this.isDiscrete.push(obj)
          }
        }
      }

    })
    return this.isDiscrete;
  }
  isNumber(val): boolean { return typeof val === 'number'; }
  isString(val): boolean { return typeof val === 'string'; }
  isInteger(val): boolean { return Number.isInteger(val) }
  checkNumbers(value, index, key, name) {
    if (this.isNumber(value)) {
      if (!this.isInteger(value)) {
        let obj = { "index": index, "key": key, 'error': 'isInteger' }
        if (name === 'Recording') {
          this.isRecording.push(obj)
        } else if (name === 'Raw') {
          this.isRaw.push(obj)
        } else if (name === 'Discrete') { this.isDiscrete.push(obj) }
      }
    } else {
      let obj = { "index": index, "key": key, 'error': 'isNumber' }
      if (name === 'Recording') {
        this.isRecording.push(obj)
      } else if (name === 'Raw') {
        this.isRaw.push(obj)
      } else if (name === 'Discrete') { this.isDiscrete.push(obj) }
    }
  }
}