import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RecordingModel,ResponseRecording } from '../../models/recordingClientLogModel';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Components } from '../../integration/datadelivery.components';
import { BreadcrumbService } from '../../../../common/services/breadcrumb.service';
import {
  BaseViewComponent,
  Action,
} from 'src/app/common/components/base-view.component';
import { MenuService } from 'src/app/common/services/menu.service';
import { forkJoin, takeUntil } from 'rxjs';
import { RecordingClientLogService } from '../../services/recordingClientLog.service';
import { permissions } from '../../integration/datadelivery.permissions';
import { ClientApplicationService } from '../../services/clientApplication.service';
import { ClientApplication } from '../../models/clientApplication';
@Component({
  selector: 'recording-clientlog',
  templateUrl: './recording-clientlog.component.html',
  styleUrls: ['./recording-clientlog.component.css'],
})
export class RecordingClientLogComponent
  extends BaseViewComponent
  implements OnInit
{
  pageTitle = Components.RecordingClientLog.label;
  messageLabel = 'client recording log';
  public canAdd: boolean;
  public canDelete: boolean;
  public canEdit: boolean;
  message: any;
  approvalText: any;
  today = new Date();
  todayAsStr = `${this.today.getFullYear()}-${this.today.getMonth()}-${this.today.getDate()}`;
  hour = this.today.getHours();
  min = this.today.getMinutes();
  seconds = this.today.getSeconds();
  deleteDialog: boolean = false;
  displayDialog: boolean = false;
  displayDate = new Date().toLocaleString();
  recording: RecordingModel[] = [];
  clientauths: ClientApplication[] = [];
  client_ids:string;
  responseRecording: ResponseRecording;
  clientIds: string;
  constructor(
    router: Router,
    private route: ActivatedRoute,
    breadcrumbService: BreadcrumbService,
    confirmationService: ConfirmationService,
    messageService: MessageService,
    private recordingClientLogService: RecordingClientLogService,
    private clientApplicationService: ClientApplicationService
  ) {
    super(messageService, confirmationService, router, breadcrumbService);
    this.getBreadCrums();
  }
  getBreadCrums() {
    this.breadcrumbService.setItems(this.route, [
      {
        label: Components.RecordingClientLog.label,
        routerLink: Components.RecordingClientLog.path,
      },
    ]);
  }
  intialVal() {
    this.getBreadCrums();
    this.canEdit = true;
    this.canDelete = true;
    this.loadClientApp();
    this.loadDataDeliveries();
  }
  ngOnInit(): void {
    this.intialVal();
  }

  loadClientApp() {
    this.loadingCount++;
    this.clientApplicationService.getClientApplications().pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (data) => {
          this.client_ids = data.map(a=>a.ClientId).join(",");
          console.log(this.client_ids)
        },
        error: (err) => {
          this.showErrorMsg(`${err}`, Action.Get, `${this.messageLabel}s`);
          this.loadingCount--;
        },
        complete: () => this.loadingCount--,
      });
  }

  loadDataDeliveries() {
    this.loadingCount++;
    this.responseRecording={
      client_ids:this.client_ids,
      status:false
    }
    forkJoin({
      getdatadeliveries: this.recordingClientLogService.getRecordingStatusbyClientIds(this.responseRecording),
    })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results) => {
          this.recording = results.getdatadeliveries;
        },
        error: (error) => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        },
      });
  }
  displayData(recording_id:number){
    
  }
}
