export enum DFDetailsInputCategory717 {
  General = 'General',
  DFCounter = 'Data Frame Counter',
  SFCounter = 'Super Frame Counter',
  DFType = 'Data Frame Type',
}

export enum DFDetailsInputCategory767 {
  FRED = 'FRED Details',
  Recorder = 'Recorder Details Group',
  Aircrat = 'Aircraft Details',
}

export enum DFDetailsInputCategoryDXS {
  TARGET_RECORDER = 'Target Recorder Assignment Selection',
  FRED = 'FRED Details',
  Recorder = 'Recorder Details Group',
  Aircrat = 'Aircraft Details',
}

export enum FormType {
  Input,
  Dropdown
}
