import {
  FeatureItem,
  FeatureComponentsDefinition,
} from 'src/app/common/models/feature-item.model';
import { permissions } from './configuration.permissions';
import { AircraftRecorderComponent } from '../components/aircraft-recorder/aircraft-recorder.component';
import { AircraftRecorderConfigDetailsComponent } from '../components/aircraft-recorder-details/aircraft-recorder-details.component';
import { RecorderDetailsComponent } from '../components/recorder-details/recorder-details.component';
import { RecorderRegexComponent } from '../components/recorder-regex/recorder-regex.component';
import { RecorderRegexDetailsComponent } from '../components/recorder-regex-details/recorder-regex-details.component';
import { RecordersComponent } from '../components/recorders/recorders.component';
import { SarMapsComponent } from '../components/sar-maps/sar-maps.component';
import { SarMapsDetailsComponent } from '../components/sar-map-details/sar-maps-details.component';
import { SarMapAddComponent } from '../components/sar-map-details/sar-map-add/sar-map-add.component';
import { SarMapVersionsComponent } from '../components/sar-map-details/sar-map-versions/sar-map-versions.component';
import { Framedetails717Component } from '../components/dataframe717/framedetails717/framedetails717.component';
import { Listview717Component } from '../components/dataframe717/listview717/listview717.component';
import { Mapview717Component } from '../components/dataframe717/mapview717/mapview717.component';
import { Framedetails767Component } from '../components/dataframe767/framedetails767/framedetails767.component';
import { Listview767Component } from '../components/dataframe767/listview767/listview767.component';
import { Mapview767Component } from '../components/dataframe767/mapview767/mapview767.component';
import { AcmsReportMapCompareComponent } from '../components/dataframe-acms/acms-report-map/acms-report-map-compare/acms-report-map-compare.component';
import { AcmsReportMapDetailsComponent } from '../components/dataframe-acms/acms-report-map/acms-report-map-details/acms-report-map-details.component';
import { AcmsReportMapVersionsComponent } from '../components/dataframe-acms/acms-report-map/acms-report-map-list-versions/acms-report-map-versions.component';
import { AcmsReportMapComponent } from '../components/dataframe-acms/acms-report-map/acms-report-map-list/acms-report-map.component';
import { DataFrameCompareComponent } from '../components/dataframe-acms/dataframe/data-frame-compare-versions/data-frame-compare.component';
import { DataFrameDetailsComponent } from '../components/dataframe-acms/dataframe/data-frame-details/data-frame-details.component';
import { DataFrameVersionsComponent } from '../components/dataframe-acms/dataframe/data-frame-list-versions/data-frame-versions.component';
import { DataFrameListComponent } from '../components/dataframe-acms/dataframe/data-frame-list/data-frame-list.component';
import { Analyse767DfComponent } from '../components/dataframe767/analysedataframe-767/analyse-767.component';
import { Analyse717DfComponent } from '../components/dataframe717/analysedataframe-717/analyse-717.component';
import { DataFrameComparisonComponent } from '../components/dataframe-acms/dataframe/data-frame-compare/data-frame-compare.component';
import { ParameterSelectionComponent } from '../components/dataframe-acms/dataframe/parameter-selection/parameter-selection.component';
import { Parameter767Component } from '../components/dataframe767/parameter767/parameter767.component';
import { Parameter717Component } from '../components/dataframe717/parameter717/parameter717.component';
import { MapviewDXSComponent } from '../components/dataframeDXS/mapviewDXS/mapviewDXS.component';
import { ParameterDXSComponent } from '../components/dataframeDXS/parameterDXS/parameterDXS.component';
import { ListviewDXSComponent } from '../components/dataframeDXS/listviewdxs/listviewdxs.component';
import { FramedetailsDXSComponent } from '../components/dataframeDXS/framedetailsDXS/framedetailsDXS.component';
import { AnalyseDXSDfComponent } from '../components/dataframeDXS/analysedataframeDXS/analyse-DXS.component';
import { NotificationsViewForConfigurationComponent } from '../components/notifications-view/notifications-view.component';

const ROUTE_ROOT = 'configuration';

export class Components extends FeatureComponentsDefinition {
  // Config

  public static readonly Recorders: FeatureItem = {
    label: 'Recorders',
    icon: 'keyboard_voice',
    path: `${ROUTE_ROOT}/recorders`,
    permission: permissions.recorders.view,
    component: RecordersComponent,
  };

  public static readonly RecordersDetails: FeatureItem = {
    label: 'Recorder Details',
    path: `${ROUTE_ROOT}/recorder-config-details`,
    permission: permissions.recorders.view,
    component: RecorderDetailsComponent,
  };

  public static readonly AircraftRecorder: FeatureItem = {
    label: 'Aircraft Recorders',
    path: `${ROUTE_ROOT}/aircraftRecorder`,
    permission: permissions.recorders.view,
    icon: 'flight',
    component: AircraftRecorderComponent,
  };

  public static readonly AircraftRecorderDetails: FeatureItem = {
    label: 'Aircraft Recorder Details',
    path: `${ROUTE_ROOT}/ac-config-details`,
    permission: permissions.recorders.view,
    component: AircraftRecorderConfigDetailsComponent,
  };

  public static readonly AcmsReportMap: FeatureItem = {
    label: 'ACMS Report Maps',
    path: `${ROUTE_ROOT}/acmsReportMap`,
    permission: permissions.acms_report.view,
    icon: 'summarize',
    component: AcmsReportMapComponent,
  };

  public static readonly AcmsReportMapDetails: FeatureItem = {
    label: 'Acms Report Map Details',
    path: `${ROUTE_ROOT}/acmsReportMapDetails`,
    permission: permissions.acms_report.view,
    component: AcmsReportMapDetailsComponent,
  };

  public static readonly AcmsReportMapCompare: FeatureItem = {
    label: 'Acms Report Map Compare',
    path: `${ROUTE_ROOT}/acmsReportMapCompare`,
    permission: permissions.acms_report.view,
    component: AcmsReportMapCompareComponent,
  };

  public static readonly AcmsReportMapVersions: FeatureItem = {
    label: 'Versions',
    path: `${ROUTE_ROOT}/acmsReportMapVersions`,
    permission: permissions.acms_report.view,
    component: AcmsReportMapVersionsComponent,
  };

  public static readonly Dataframe: FeatureItem = {
    label: 'Data Frames',
    path: `${ROUTE_ROOT}/dataframe`,
    permission: permissions.dataframes.view,
    icon: 'filter_frames',
    component: DataFrameListComponent,
  };

  public static readonly DataframeDetails: FeatureItem = {
    label: 'Data Frame Details',
    path: `${ROUTE_ROOT}/dataframeDetails`,
    permission: permissions.dataframes.view,
    component: DataFrameDetailsComponent,
  };

  public static readonly DataframeCompare: FeatureItem = {
    //compares Data Frame Versions
    label: 'Data Frame Compare',
    path: `${ROUTE_ROOT}/dataframeCompare`,
    permission: permissions.dataframes.view,
    component: DataFrameCompareComponent,
  };

  public static readonly DataframeVersions: FeatureItem = {
    label: 'Versions',
    path: `${ROUTE_ROOT}/dataframeVersions`,
    permission: permissions.dataframes.view,
    component: DataFrameVersionsComponent,
  };

  public static readonly DataframeComparison: FeatureItem = {
    //compare Data Frame with each other
    label: 'Data Frame Comparison',
    path: `${ROUTE_ROOT}/dataframeComparison`,
    permission: permissions.dataframes.view,
    component: DataFrameComparisonComponent,
  };
  public static readonly ParameterSelection: FeatureItem = {
    //compare Parameters and select them
    label: 'Parameter Selection',
    path: `${ROUTE_ROOT}/parameterSelection`,
    permission: permissions.dataframes.view,
    component: ParameterSelectionComponent,
  };

  public static readonly RecorderRegexes: FeatureItem = {
    label: 'Recorder Regexes',
    path: `${ROUTE_ROOT}/recorderregexes`,
    permission: permissions.regexes.view,
    icon: 'translate',
    component: RecorderRegexComponent,
  };

  public static readonly RecorderRegexDetails: FeatureItem = {
    label: 'Recorder Regex Details',
    path: `${ROUTE_ROOT}/recorderregex-details`,
    permission: permissions.regexes.view,
    component: RecorderRegexDetailsComponent,
  };

  public static readonly SarMaps: FeatureItem = {
    label: 'SAR Maps',
    path: `${ROUTE_ROOT}/sar-maps`,
    permission: permissions.sar_maps.view,
    icon: 'map',
    component: SarMapsComponent,
  };

  public static readonly SarMapsDetails: FeatureItem = {
    label: 'SAR Maps Details',
    path: `${ROUTE_ROOT}/sar-maps-details`,
    permission: permissions.sar_maps.view,
    component: SarMapsDetailsComponent,
  };

  public static readonly SarMapsVersions: FeatureItem = {
    label: 'SAR Maps Versions',
    path: `${ROUTE_ROOT}/sar-maps-version`,
    permission: permissions.sar_maps.view,
    component: SarMapVersionsComponent,
  };

  public static readonly SarMapsAdd: FeatureItem = {
    label: 'New SAR Map',
    path: `${ROUTE_ROOT}/add-sar-map`,
    permission: permissions.sar_maps.view,
    component: SarMapAddComponent,
  };

  //Dataframe Editor Start
  public static readonly ListView717: FeatureItem = {
    label: '717 Data Frame',
    path: `${ROUTE_ROOT}/listview717`,
    permission: undefined,
    component: Listview717Component,
  };
  public static readonly DataFrame717: FeatureItem = {
    label: '717 Data Frame Details',
    path: `${ROUTE_ROOT}/framedetails717`,
    permission: undefined,
    component: Framedetails717Component,
  };
  public static readonly MapView717: FeatureItem = {
    label: 'MapView 717 Data Frame',
    path: `${ROUTE_ROOT}/mapview717`,
    permission: undefined,
    component: Mapview717Component,
  };
  public static readonly ListView767: FeatureItem = {
    label: '767 Data Frame',
    path: `${ROUTE_ROOT}/listview767`,
    permission: undefined,
    component: Listview767Component,
  };
  public static readonly DataFrame767: FeatureItem = {
    label: '767 Data Frame Details',
    path: `${ROUTE_ROOT}/framedetails767`,
    permission: undefined,
    component: Framedetails767Component,
  };
  public static readonly MapView767: FeatureItem = {
    label: 'MapView 767 Data Frame',
    path: `${ROUTE_ROOT}/mapview767`,
    permission: undefined,
    component: Mapview767Component,
  };
  public static readonly DataFrameDynamic717: FeatureItem = {
    label: 'Add/Edit/Clone 717 Data Frame Details',
    path: `${ROUTE_ROOT}/parameter717`,
    permission: undefined,
    component: Parameter717Component,
  };
  public static readonly DataFrames767: FeatureItem = {
    label: 'Add 767 Data Frame Details v2',
    path: `${ROUTE_ROOT}/parameter767`,
    permission: undefined,
    component: Parameter767Component,
  };
  public static readonly DataFrameAnalyse767: FeatureItem = {
    label: 'Data Frame Analyse 767',
    path: `${ROUTE_ROOT}/analyse767-df`,
    permission: undefined,
    component: Analyse767DfComponent,
  };
  public static readonly DataFrameAnalyse717: FeatureItem = {
    label: 'Data Frame Analyse 717',
    path: `${ROUTE_ROOT}/analyse717-df`,
    permission: undefined,
    component: Analyse717DfComponent,
  };
  public static readonly MapViewDXS: FeatureItem = {
    label: 'MapView DXS Data Frame',
    path: `${ROUTE_ROOT}/mapviewDXS`,
    permission: undefined,
    component: MapviewDXSComponent,
  };
  public static readonly DataFrameDynamicDXS: FeatureItem = {
    label: 'Add/Edit/Clone DXS Data Frame Details',
    path: `${ROUTE_ROOT}/parameterDXS`,
    permission: undefined,
    component: ParameterDXSComponent,
  };
  public static readonly ListViewDXS: FeatureItem = {
    label: 'DXS Data Frame',
    path: `${ROUTE_ROOT}/listviewDXS`,
    permission: undefined,
    component: ListviewDXSComponent,
  };
  public static readonly DataFrameAnalyseDXS: FeatureItem = {
    label: 'Data Frame Analyse DXS',
    path: `${ROUTE_ROOT}/analyseDXS-df`,
    permission: undefined,
    component: AnalyseDXSDfComponent,
  };
  public static readonly DataFrameDXS: FeatureItem = {
    label: 'DXS Data Frame Details',
    path: `${ROUTE_ROOT}/framedetailsdxs`,
    permission: undefined,
    component: FramedetailsDXSComponent,
  };

  public static readonly NotificationsViewForConfiguration: FeatureItem = {
    label: 'User Notifications',
    path: `${ROUTE_ROOT}/notifications`,
    permission: permissions.alert_notifications.view,
    component: NotificationsViewForConfigurationComponent,
  };
}
