import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError } from "rxjs";
import { environment } from "src/environments/environment";
import { Runway } from "../models/runway";
import { BaseService } from "src/app/common/services/base.service";

@Injectable({
    providedIn: 'root'
  })
  export class RunwayService extends BaseService {

    constructor(private http:HttpClient){
      super();
      this.baseUrl=environment.apiUrl.admin;
    }


    public getRunways(dte: string, icao :string){

        const url = `${this.baseUrl}runway/${dte}/${icao}`
        return this.http.get<Runway[]>(url)
        .pipe(
          catchError(this.httpHandleError)
        );
    }

    public getRunway(dte:string, icao:string, runway_id:string){

      const url = `${this.baseUrl}runway/${dte}/${icao}/${runway_id}`;
      return this.http.get<Runway>(url)
      .pipe(
        catchError(this.httpHandleError)
      );
    }

  }
