import { AircraftPair } from "./aircraft";

export class AircraftGroup {
    aircraftgroup_id: number;
    aircraftgroup_name: string;
    aircraftgroup_description?: string;
    airline_icao_code: string;
    airline_name: string;
    aircraft_id?: number[];
    aircraft_registration?: string[];
    aircraft: AircraftPair[];
}
