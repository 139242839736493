<p-blockUI [blocked]="loadingCount > 0">
    <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<p-confirmDialog></p-confirmDialog>

<div class="col-12 card p-3">
    <form #addForm="ngForm" *ngIf="record" (ngSubmit)="onSubmit();" novalidate>
        <div class="card-header m-0">
            <h5>{{pageTitle}}</h5>
        </div>
        <div class="grid mt-2">
            <div class="lg:col-6">
                
                
                <h6>Details</h6>
                
                    <div class="p-float-label">
                        <input required type="text" #name="ngModel" [(ngModel)]="record.name" [style]="{'width':'100%'}"
                        maxlength="256" name="name" [textContent]="record.name" pInputText placeholder="Name" (input)="valuechange($event)"
                        />
                    <label for="name">Name</label>    
                    </div>
            </div>

            <div class="lg:col-2"><p-divider layout="vertical"></p-divider></div>
            <div class="lg:col-4"> 
                <div _ngcontent-ncg-c135="" class="custom-panel">
                    
                    <h6 >Upload</h6>
                    <p-fileUpload #contentUpload (onRemove)="onFileRemove()" (onSelect)="onFileSelect($event)"
                    [disabled]=" !fileButtonEnabled || !canAdd && !canDelete" [showCancelButton]=false
                    [showUploadButton]=false [fileLimit]="1" [multiple]="false" [accept]="allowedFileType"
                    chooseLabel="File" class="" id="FileUpload" mode="advanced" name="FileUpload" url="">
                        <ng-template pTemplate="content">
                            <div class="p-text-center">
                                <div *ngIf="isSaveDisabled">
                                    No file uploaded.
                                </div>
                            </div>
                        </ng-template>
                    </p-fileUpload>
                
                </div>
               
            </div>



            
            <div class="">
              
        
               
            </div>
        </div>
        <div class="flex justify-content-end">
            <span class="p-input-icon-left">
                <button (click)="onCancel()" class="p-button-text" icon="pi pi-times" id="cancelButton"
                    label="Cancel" pButton pRipple type="button"></button>
            </span>
            <span class="p-input-icon-left">
                <button (click)="onReset()" class="p-button-text" icon="pi pi-undo" id="resetButton" label="Reset"
                    pButton pRipple [disabled]="isSaveDisabled" type="button"></button>
            </span>
            <span class="p-input-icon-left pl-2">
                <button *ngIf="canAdd || canDelete"
                    [disabled]="isSaveDisabled "
                    icon="pi pi-check" id="okButton" label="Save" pButton pRipple type="submit"></button>
            </span>
        </div>
    </form>
</div>