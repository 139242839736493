import {SelectItem} from 'primeng/api';

export class SortUtil {
  /**
   * Sorts an array of SelectItems by the label value in ascending order using a natural sort order.
   *
   * @param items - The collection of SelectItem values.
   */
  public static sortByLabel(items: SelectItem[]) {
    SortUtil.sortSelectItems(items, false);
  }

  /**
   * Sorts an array of SelectItems by the label value in descending order using a natural sort order.
   *
   * @param items - The collection of SelectItem values.
   */
  public static sortByLabelDescending(items: SelectItem[]) {
    SortUtil.sortSelectItems(items, true);
  }

  /**
   * Sorts an array of strings in ascending order using a natural sort order.
   *
   * @param array The array of strings.
   */
  public static sortStrings(array: string[]) {
    SortUtil.sortArray(array, false);
  }

  /**
   * Sorts an array of strings in descending order using a natural sort order.
   *
   * @param array The array of strings.
   */
  public static sortStringsDescending(array: string[]) {
    SortUtil.sortArray(array, true);
  }

  /**
   * Private function to sort an array of strings in ascending or descending order using a natural sort order.
   * @param array The array of strings.
   * @param descending Flag indicating if sort is descending or not (ascending).
   */
  private static sortArray(array: string[], descending: boolean) {
    if (array != null) {
      if (descending) {
        array.sort((a, b) => b.localeCompare(a, navigator.languages[0] || navigator.language, {
          numeric: true,
          ignorePunctuation: true
        }));
      } else {
        array.sort((a, b) => a.localeCompare(b, navigator.languages[0] || navigator.language, {
          numeric: true,
          ignorePunctuation: true
        }));
      }
    }
  }

  /**
   * Private function to sort an array of SelectItems by the label value in ascending or descending order using a natural sort order.
   * @param items The array of SelectItem.
   * @param descending Flag indicating if sort is descending or not (ascending).
   */
  private static sortSelectItems(items: SelectItem[], descending: boolean) {
    if (items) {
      if (descending) {
        items.sort((a, b) => b.label.localeCompare(a.label, navigator.languages[0] || navigator.language, {
          numeric: true,
          ignorePunctuation: true
        }));
      } else {
        items.sort((a, b) => a.label.localeCompare(b.label, navigator.languages[0] || navigator.language, {
          numeric: true,
          ignorePunctuation: true
        }));
      }
    }
  }
}
