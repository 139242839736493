import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Organization } from 'src/app/features/administration/models/organization';
import {
  ACMS,
  ACMSType,
  FileType,
  fileType,
  getRegex,
  jsonValues,
} from 'src/app/features/datadelivery/models/ACMS';

@Component({
  selector: 'app-acmsreport-form',
  templateUrl: './acmsreport-form.component.html',
})
export class AcmsreportFormComponent implements OnInit {
  @Input() acmsreport!: ACMS;
  @Input() acmsReportlist: ACMSType[] = [];
  @Input() acmsType: ACMSType[] = [];
  @Input() fileType: fileType[] = [];
  @Input() jsonregex: getRegex;
  @Input() uniqueError: boolean = false;
  @Input() avialableAcms: ACMSType[] = [];
  @Input() selectedMapIDs: string[] = [];
  @Input() picklistChange = false;
  @Input() isButtonDisabled: boolean = true;
  @Input() jsonParameters: jsonValues;
  @Input() showButtons: boolean = true;
  @Input() FileType = FileType;

  @Input() canAdd: boolean;
  @Input() canDelete: boolean;
  @Input() canEdit: boolean;
  @Input() isNew: boolean = false;
  @Input() isReadonly: boolean = true;
  @Input() acmsreports: ACMS[] = [];

  @Output() moveToTarget = new EventEmitter<ACMS>();
  @Output() moveToSource = new EventEmitter<ACMS>();
  @Output() moveAllToSource = new EventEmitter<ACMS>();
  @Output() moveAllToTarget = new EventEmitter<ACMS>();
  @Output() onAirlineChangeEvent = new EventEmitter<ACMS>();
  @Output() onChangeEvent = new EventEmitter<ACMS>();

  @Output() submitForm = new EventEmitter<void>();
  @Output() cancelForm = new EventEmitter<void>();
  @Output() resetForm = new EventEmitter<void>();

  @ViewChild('acmsForm')
  public acmsForm!: NgForm;

  constructor() {}

  ngOnInit(): void {}

  onSubmit() {
    this.submitForm.emit();
  }

  onCancel() {
    this.cancelForm.emit();
  }

  onReset() {
    this.acmsForm.resetForm();
    this.resetForm.emit();
  }

  onMoveToTarget(event: ACMS) {
    this.moveToTarget.emit(event);
  }

  onMoveToSource(event: ACMS) {
    this.moveToSource.emit(event);
  }

  onMoveAllToSource(event: ACMS) {
    this.moveAllToSource.emit(event);
  }

  onMoveAllToTarget(event: ACMS) {
    this.moveAllToTarget.emit(event);
  }

  onAirlineChange(event: ACMS) {
    this.onAirlineChangeEvent.emit(event);
  }

  onChange(event: ACMS) {
    this.onChangeEvent.emit(event);
  }
}
