<p-confirmDialog></p-confirmDialog>
<div *ngIf="organization" class="col-12 card p-3">
    <form #organizationForm="ngForm" (ngSubmit)="onSubmit();" novalidate>
        <div class="card-header">
            <h5>{{pageTitle}}</h5>
        </div>
        <p-tabView id="tabView" [(activeIndex)]="activeTabIndex">
            <p-tabPanel id="PropertiesTab" class="mt-3" leftIcon="pi pi-book" header="Properties" *ngIf="canViewOrg || canViewOrgDetail || canEditOrg || canEditOrgDetail">
                <div class="grid mt-2">
                    <div class="lg:col-6">
                        <div class="  mb-0">
                            <h6>Details</h6>
                            <div class="col-12 pl-0">
                                <div class="p-float-label">
                                    <input #name="ngModel" [(ngModel)]="organization.name" [disabled]="!canEditOrg && (!canEditOrgDetail || (activeOrg != organization.code))"
                                        [style]="{'width':'100%'}" [textContent]="organization.name"
                                        id="organizationName" maxlength="256" name="organizationName" pInputText
                                        required type="text"/>
                                    <label for="organizationName">{{nameDisplay}}</label>
                                </div>
                                <div *ngIf="(name.touched || name.dirty) && name.hasError('required')"
                                    class="tdy-required">
                                    {{nameDisplay}} is required
                                </div>
                            </div>
                            <div class="col-12 pl-0">
                                <div class="p-float-label">
                                  <input #code="ngModel" [(ngModel)]="organization.code"
                                    [disabled]="codeIsReadOnly" [style]="{'width':'100%'}"
                                    id="code" maxlength="3" minlength="3" name="code" pInputText
                                    pattern="[A-Za-z]*" required fwUppercase
                                    type="text" />
                                  <label for="code">{{codeDisplay}}</label>
                                </div>
                                <div *ngIf="(code.touched || code.dirty) && code.hasError('required')" class="tdy-required">
                                  {{codeDisplay}} is required
                                </div>
                                <div *ngIf="code.hasError('minlength') || code.hasError('pattern')" class="tdy-required">
                                    Code must be 3 letters
                                  </div>
                              </div>
                            <div class="col-12 pl-0">
                                <div class="p-float-label" *ngIf="isAirline">
                                    <input #iataCode="ngModel" fwUppercase
                                        [(ngModel)]="organization.airline_iata_code"
                                        [disabled]="!canEditOrg" [style]="{'width':'100%'}"
                                        [textContent]="organization.airline_iata_code" id="iataCode" maxlength="2"
                                        minlength="2" name="iataCode" pInputText pattern="[a-zA-Z]*" type="text" />
                                    <label for="iataCode">IATA Code</label>
                                    <div *ngIf="iataCode.hasError('minlength') || code.hasError('pattern')" class="tdy-required">
                                        Code must be 2 letters
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="lg:col-2"><p-divider layout="vertical"></p-divider></div>
                    <div class="lg:col-4">
              
                        <div class=" custom-panel">
                            <h6>Logos</h6>
                            <div class="">
                                <p-fileUpload #logoUpload (onRemove)="onLogoRemove(false)"
                                    (onSelect)="onLogoSelect($event, false)"
                                    [disabled]="(!canEditOrg && (!canEditOrgDetail || (activeOrg != organization.code))) || logoFile != null" [showCancelButton]=false
                                    [showUploadButton]=false [fileLimit]="1" [multiple]="false" accept=".jpg,.png,.jpeg"
                                    chooseLabel="Web Logo" class="" id="logoUpload"
                                    [maxFileSize]="maxLogoFileSize" mode="advanced" name="logoUpload" url="{{logoUrl}}">
                                    <ng-template pTemplate="toolbar">
                                        <button (click)="onLogoRemove(false)"
                                            *ngIf="logoFile !== undefined && logoFile !== null && logoFile?.size > 0"
                                            [disabled]="(!canEditOrg && (!canEditOrgDetail || (activeOrg != organization.code))) "
                                            class="p-button-rounded p-button-text p-button-danger" id="deleteButton" icon="pi pi-trash"
                                            pButton pRipple pTooltip="Remove Logo..." style="float: right"
                                            type="button">
                                        </button>
                                    </ng-template>
                                    <ng-template pTemplate="file">
                                    </ng-template>

                                    <ng-template pTemplate="content">
                                        <div class="text-center">
                                            <img *ngIf="logoFile !== undefined && logoFile !== null && logoFile?.size > 0"
                                                [src]="organization.web_logo" alt="upload logo"
                                                id="organizationlogoPreview" id="deleteButton"
                                                style="width: 60%; height: 60%; object-fit: contain;" />
                                            <div
                                                *ngIf="logoFile === undefined || logoFile === null || logoFile?.size <= 0">
                                                No image uploaded.
                                            </div>
                                        </div>
                                    </ng-template>
                                </p-fileUpload>
                            </div>
                            <div class="my-4">
                                <p-fileUpload #logoMobileUpload (onRemove)="onLogoRemove(true)"
                                    (onSelect)="onLogoSelect($event, true)"
                                    [disabled]="(!canEditOrg && (!canEditOrgDetail || (activeOrg != organization.code))) || mobileLogoFile != null" [showCancelButton]=false
                                    [showUploadButton]=false [fileLimit]="1" [multiple]="false" accept=".jpg,.png,.jpeg"
                                    chooseLabel="Mobile Logo" id="logoMobileUpload" [maxFileSize]="maxLogoFileSize"
                                    mode="advanced" name="logoMobileUpload" url="{{mobileLogoUrl}}">
                                    <ng-template pTemplate="toolbar">
                                        <button (click)="onLogoRemove(true)" 
                                            *ngIf="mobileLogoFile != null && mobileLogoFile?.size > 0"
                                            [disabled]="(!canEditOrg && (!canEditOrgDetail || (activeOrg != organization.code))) "
                                            class="p-button-rounded p-button-text p-button-danger" id="deleteButton" icon="pi pi-trash"
                                            pButton pRipple pTooltip="Remove Logo..." style="float: right"
                                            type="button">
                                        </button>
                                    </ng-template>
                                    <ng-template pTemplate="file">
                                    </ng-template>
                                    <ng-template pTemplate="content">
                                        <div class="text-center">
                                            <img *ngIf="mobileLogoFile != null && mobileLogoFile?.size > 0"
                                                [src]="organization.mobile_logo" alt="upload logo"
                                                id="organizationMobilelogoPreview"
                                                style="width: 60%; height: 60%; object-fit: contain;" />
                                            <div *ngIf="mobileLogoFile == null || mobileLogoFile?.size <= 0">
                                                No image uploaded.
                                            </div>
                                        </div>
                                    </ng-template>
                                </p-fileUpload>
                            </div>
                        </div>
                    </div>
                </div>
            </p-tabPanel>

            <!-- Settings tab -->
            <ng-container *ngIf="canViewOrg || canViewOrgDetail || canEditOrg || canEditOrgDetail">
                <p-tabPanel id="SettingsTab" leftIcon="pi pi-sliders-h" header="Settings">
                 <app-settings [settings]="settings" (settingsChanged)="onSettingsChanged($event)" [isSettingEditable]="canEditOrg || (canEditOrgDetail && (activeOrg === organization.code))"></app-settings>
                </p-tabPanel>
            </ng-container>

            <!-- Applications tab -->
            <p-tabPanel id="applicationsTab" leftIcon="pi pi-th-large" header="Applications" *ngIf="canViewOrg || canViewOrgDetail || canEditOrg || canEditOrgDetail">
                <app-pick-list-custom [sourceItems]="availableApplications" [(targetItems)]="assignedApplications"
                    itemDataKey="application_name" sourceTitle="Available Applications"
                    targetTitle="Selected Applications" sourceDisplayLabel="application_name"
                    targetDisplayLabel="application_name" sourceFilterField="application_name" targetFilterField="application_name"
                    [isEditable]="canEditOrg" [targetTemplate]="selectedTmpl" (onMoveToTargetItems)="onSelectedChange()"
                    (targetItemsChange)="onSelectedChange($event)">
                </app-pick-list-custom>
            </p-tabPanel>

            <!--For Aircraft Applications-->
            <ng-container *ngIf="(canViewOrg || canViewOrgDetail || canEditOrg || canEditOrgDetail)">
                    <p-tabPanel #table *ngIf="assignedApplications?.length > 0 && (filterAircraftApps?.length > 0 || aircraftGroups?.length > 0)" id="aircraftApplicationsTab" leftIcon="pi pi-check-square"
                        header="Aircraft Applications">
                        <div class="card-header">
                            <h6>Aircraft Selection</h6>
                            <div *ngIf="canViewOrg || canEditOrg">
                                <span class="p-input-icon-left">
                                    <p-dropdown id="aircraftGroupList" [options]="aircraftGroupsOptions"
                                        (onChange)="onChangeAircraftGroup($event)" placeholder="Aircraft Groups" tooltip="Filter by Aircraft Group"
                                        [autoDisplayFirst]="true" [showClear]="true" class="" [style]="{'width':'200px'}"
                                        [dropdownIcon]="'pi pi-filter'" name="filterAircraftGroups"></p-dropdown>
                                </span>
                            </div>
                        </div>
                        <div class="" *ngIf="filterAircraftApps?.length > 0">
                            <p-table [value]="filterAircraftApps" class="p-datatable-gridlines custom-table" [rows]="20"
                                [showCurrentPageReport]="true" [rowsPerPageOptions]="[20,30,40,50]" [paginator]="true"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
                                <ng-template pTemplate="header" let-acApps let-rowIndex="rowIndex">
                                    <tr>
                                        <th rowspan="3">Aircraft Registration
                                        </th>
                                        <th class="aircraft_selection_header" [colSpan]="filterAircraftApps[0]?.availApplications.length" style="text-align: center;">
                                            <p-checkbox [ngModel]="checkAllAppsSelected()" [disabled]="aircraftLimitExceed('allAppsCheck') || !canEditOrg"
                                                pTooltip="Assign all aircraft to all applications" (onChange)="onSelectAllApps($event)"
                                                inputId="allApps" [binary]="true" name="allAppsChecked"></p-checkbox>
                                                <label style="margin: 7px;" for="allApps">Applications</label>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th *ngFor="let app of filterAircraftApps[0]?.availApplications">
                                            <div>
                                                <p-checkbox [ngModel]="checkApplicationSelected(app)" [disabled]="aircraftLimitExceed('AppCheck', app) || !canEditOrg"
                                                    pTooltip="Assign all aircraft to {{app.application_name}} "
                                                     (onChange)="onSelectApp(app, $event, acApps)"
                                                    [binary]="true" [name]="app.application_name" inputId="allAircraft"></p-checkbox>
                                                    <label style="margin: 7px;" for="allAircraft">{{app.application_name}}</label>
                                            </div>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-acApps let-rowIndex="rowIndex">
                                    <tr>
                                        <td >
                                            <p-checkbox [binary]="true" [disabled]="!canEditOrg"
                                                [ngModel]="acApps.selectedApplications?.length === acApps.availApplications?.length"
                                                [label]="acApps.aircraft.aircraft_registration"
                                                (onChange)="onSelectAircraft(acApps, $event, rowIndex)"
                                                [name]="acApps.aircraft?.aircraft_id"></p-checkbox>
                                        </td>
                                        <td *ngFor="let app of acApps.availApplications let index">
                                            <p-checkbox [binary]="true" [ngModel]="acApps.selectedApplications?.includes(app)"
                                                [disabled]="aircraftLimitExceed('aircraftCheck', app, acApps.aircraft, rowIndex) || !canEditOrg"
                                                (onChange)="onSelectIndividual(acApps, app, $event, rowIndex)"
                                                [name]="acApps.aircraft.aircraft_id + app.application_name"></p-checkbox>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                        <div class="card" *ngIf="filterAircraftApps?.length == 0">
                            <p>No aircraft found</p>
                        </div>
                    </p-tabPanel>

            </ng-container>

            <ng-template #selectedTmpl let-app let-callback="callback">
                <div class="p-fluid grid">
                    <div class="col-12 md:col-4">
                      <span class="p-float-label">
                        <p-calendar [(ngModel)]="app.expiry_date" dataType="string" dateFormat="yy-mm-dd" [showTime]="false"
                        [disabled]="!canEditOrg" [ngModelOptions]="{standalone: true}" [required]="true" [minDate]="minExpiryDate"
                        (ngModelChange)="callback()" #applicationExpiry="ngModel"appendTo="body" pTooltip="Expiry date for selected application" tooltipPosition="top">
                      </p-calendar>
                      <label for="numOfAirlineUsers" style="font-size: 12px;">Expiry date</label>
                        </span>
                        <div *ngIf="(applicationExpiry.touched || applicationExpiry.dirty) && applicationExpiry.hasError('required')"
                            class="tdy-required">
                            Expiry date is required
                        </div>
                    </div>
                    <div class="col-12 md:col-3">
                        <span class="p-float-label">
                            <input #applicationUsers="ngModel" [(ngModel)]="app.number_of_users"
                                [disabled]="!canEditOrg" [style]="{'width':'100%'}"
                                [textContent]="app.number_of_users" id="number_of_users" min="1" step="1" name="number_of_users" type="number"
                                pInputText required onkeypress="return event.charCode >= 48 && event.charCode <= 57" [ngModelOptions]="{standalone: true}"
                                (ngModelChange)="limitDecreased(app, 'check-users-limit') ? '' : callback()" pTooltip="Maximum number of users" tooltipPosition="top"/>
                            <label for="number_of_users" style="font-size: 12px;">Users</label>
                        </span>
                        <div *ngIf="(applicationUsers.touched || applicationUsers.dirty) && applicationUsers.hasError('required')"
                            class="tdy-required">
                            Application users are required
                        </div>
                        <div *ngIf="applicationUsers.hasError('min')" class="tdy-required">
                            Users should be greater than 0
                        </div>
                        <div *ngIf="app?.usersLimitInvalid" class="tdy-required">
                            Unassign users from application in order to reduce limit.
                        </div>
                    </div>
                    <div class="col-12 md:col-3">
                        <span class="p-float-label">
                          <input #applicationAircraft="ngModel" [(ngModel)]="app.number_of_aircrafts" [disabled]="!canEditOrg" (ngModelChange)="limitDecreased(app, 'check-aircraft-limit') ? '':  callback()"
                            [style]="{'width':'100%'}" [textContent]="app.number_of_aircrafts" id="number_of_aircrafts" min="1" step="1" type="number" name="number_of_aircrafts"
                            pInputText required onkeypress="return event.charCode >= 48 && event.charCode <= 57" [ngModelOptions]="{standalone: true}" pTooltip="Maximum number of aircraft" tooltipPosition="top"/>
                          <label for="number_of_aircrafts" style="font-size: 12px;">Aircraft</label>
                        </span>
                        <div *ngIf="(applicationAircraft.touched || applicationAircraft.dirty) && applicationAircraft.hasError('required')"
                          class="tdy-required">
                          Number of Aircraft is required
                        </div>
                        <div *ngIf="applicationAircraft.hasError('min')" class="tdy-required">
                            Aircraft should be greater than 0
                        </div>
                        <div *ngIf="limitDecreased(app, 'check-aircraft-limit')" class="tdy-required">
                            Uncheck aircraft from aircraft applications tab to reduce limit
                        </div>
                      </div>
                    </div>
                  </ng-template>

        </p-tabView>
        <div class="flex justify-content-end">
            <span class="p-input-icon-left">
                <button (click)="onCancel()" *ngIf="canViewOrg && !loggedIn" class="p-button-text" icon="pi pi-times" id="cancelButton" label="Cancel"
                    pButton pRipple type="button"></button>
            </span>
            <span class="p-input-icon-left">
                <button #organizationReset (click)="onReset()" *ngIf="canEditOrg || canEditOrgDetail"
                    [disabled]="!(organizationForm.dirty || logoChanged || mobileLogoChanged || settingsChanged || applicationChanged)"
                    class="p-button-text" icon="pi pi-undo" id="resetButton" label="Reset" pButton pRipple
                    type="button"></button>
            </span>

            <span class="p-input-icon-left pl-2">
                <button *ngIf="canEditOrg || canEditOrgDetail"
                [disabled]="isFormValidatedOnSave()"
                icon="pi pi-check" id="okButton" label="Save" pButton pRipple type="submit"></button>
            </span>
        </div>
    </form>
</div>
