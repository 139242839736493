import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {  Observable, of } from 'rxjs';
import { BaseService } from '../../../common/services/base.service';
import { catchError } from 'rxjs/operators';
import { environment } from "src/environments/environment";
import { UpdateUserSubscriptionResponse, UserSubscriptions } from '../models/subscriptions';
import { applicationRoleData } from '../models/application';

@Injectable({
  providedIn: 'root'
})
export class UserSubscriptionsService extends BaseService {

  constructor(
    private http: HttpClient) {
    super();
    this.baseUrl = environment.apiUrl.datadelivery;
  }

  getAppSpecificUserSubscriptions(selectedAppId: number): Observable<UserSubscriptions> {
    let url = Object.values(applicationRoleData).find(({id}) => id == selectedAppId).endPoint + `alert-subscription`;
    return this.http.get<UserSubscriptions>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError)
      );
  }

  updateAssignedUserSubscriptions(usersubscription: UpdateUserSubscriptionResponse) {
    const url = this.baseUrl + 'alert-subscription';
    return this.http.post<number>(url, usersubscription, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError)
      )
  }
}