<div class="col-12 card">
  <div class="card-header m-2">
    <h5>{{pageTitle}}</h5>
  </div>
  <ng-container *ngIf="(flightDataRedactions$ | async) as flightdataredactions; else loading">
    <app-data-redaction-table
      [flightdataredactions]="flightdataredactions"
      [pageTitle]="pageTitle"
      [canAdd]="canAdd"
      [canEdit]="canEdit"
      [canDelete]="canDelete"
      [readonly]="true"
      (onRowClicked)="onRowClick($event.dataredaction, $event.event)">
    </app-data-redaction-table>
  </ng-container>
</div>

<ng-template #loading>
  <p-blockUI [blocked]="true">
    <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
  </p-blockUI>
</ng-template>
