<p-table *ngIf="columns" [value]="copydataparameter" [filterDelay]="0" [tableStyle]="{'width':'100%'}" #dt [dataKey]="dataKey"
[totalRecords]="copydataparameter.length" selectionMode="single" [showCurrentPageReport]="true" [paginator]="copydataparameter.length > defaultTableRows"
[rowsPerPageOptions]="DataFrameConstants.rowsPerPageOptions" [rows]="defaultTableRows" currentPageReportTemplate="{{currentPageReport + ' ' + pageTitle}}"
[sortField]="sortField" [sortOrder]="1" (onRowSelect)="onRowSelect.emit(getProp($event.data, dfPropId))" [responsive]="true">
<ng-template pTemplate="header">
  <tr>
      <ng-container *ngFor="let col of columns">
          <ng-container [ngSwitch]="col.colType">
              <th *ngSwitchCase="ListViewColumnType.button"></th>
              <th *ngSwitchDefault [pSortableColumn]="col.field">
                  {{col.header}} 
                  <p-sortIcon [field]="col.field"></p-sortIcon>   
                  <p-columnFilter [id]="col.field" [attr.name]="col.field" [type]="col.filterType" [showOperator]="false"
                      [class]="col.cssClasses" [field]="col.field" display="menu" [showAddButton]="false" [attributes]="col.extraAttributes">
                  </p-columnFilter>
              </th>
          </ng-container>
      </ng-container>
  </tr>
</ng-template>
<ng-template pTemplate="body" let-data>
  <tr [pSelectableRow]="data" class="p-selectable-row">
      <ng-container *ngFor="let col of columns">
          <ng-container [ngSwitch]="col.colType">
              <td *ngSwitchCase="ListViewColumnType.button" class="p-0">
                  <div class="flex justify-content-center p-0">
                      <button [id]="col.field" [name]="col.field" type="button"
                          class="p-button-rounded p-button-text {{col.cssClass}}" pButton [icon]="col.icon" pRipple
                          [pTooltip]="col.toolTip" (click)="col.onClickAction(data)"></button>
                  </div>
              </td>
              <td *ngSwitchDefault>
                  {{getProp(data, col.field)}}
              </td>
          </ng-container>
      </ng-container>
  </tr>
</ng-template>
<ng-template pTemplate="emptymessage">
  <tr>
      <td [attr.colspan]="3">{{emptyMessage}}</td>
  </tr>
</ng-template>
</p-table>