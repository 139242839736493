export class EndPoints {
  endpoint_id: number;
  name: string;
  description: string;
  transfertype_id?: number;
  receiver_organization_id?: number;
  jsonparameters?: any;
  isenabled: boolean;
  transfertype_name?: string;
  organization_type_name?: string;
}

export class ResponseEndPointModel {
  endpoint_id: number;
  endpoint_name: string;
  endpoint_description: string;
  endpoint_transfertype_id?: number;
  endpoint_jsonparameters: any;
  endpoint_isenabled: boolean;
  receiver_organization_id: number;
  organization_id: number
}

export class jsonValues {
  endpoint_bucketpath: string;
  endpoint_region: string;
  endpoint_accesskey: string;
  endpoint_secretkey: string;
  endpoint_encryptkey: string;
  endpoint_ipaddress?: number;
  endpoint_port?: number;
  endpoint_folderpath: string;
  endpoint_sftfolderpath: string;
  endpoint_username: string;
  endpoint_password: string;
  endpoint_date?: Date;
  endpoint_organization: string;
  endpoint_authssh: boolean;
  endpoint_awskmskey?: string;
  endpoint_public_ssh_key?: string;
}

export interface REGION {
  REG_VALUE?: number;
  REG_DESCRIPTION?: string;
}

export interface TransferType {
  transfertype_id: number;
  name: string;
  desccription: string;
}
export interface EncryptType {
  id: Number;
  name: String;
}

export interface AWS_Region {
  region: string;
}

export enum GetEncrypt {
  None = 1,
  Default = 2,
  AES256 = 3,
  AWSKMS = 4,
}

export const getEncryptType = [
  { id: GetEncrypt.None, name: 'None' },
  { id: GetEncrypt.Default, name: 'Default' },
  { id: GetEncrypt.AES256, name: 'AES256' },
  { id: GetEncrypt.AWSKMS, name: 'AWSKMS' },
];
