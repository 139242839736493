<app-spinner [isLoading]="loadingCount > 0"></app-spinner>
<div *ngIf="order && frameDetailsForm" class="col-12 card p-3">
  <form [formGroup]="frameDetailsForm" (ngSubmit)="OnSubmit()" novalidate>
    <div class="card-header m-2">
      <h5>{{pageTitle}}</h5>
    </div>

    <div *ngFor="let category of inputCategoryList" class="card">
      <h6>{{category}}</h6>
      <div class="formgrid grid">
        <div *ngFor="let inputConf of formConfiguration[category]" class="lg:col-3 {{inputConf.class}}">
          <div class="p-float-label">
            <ng-container [ngSwitch]="inputConf.formType">
              <!-- Input Type -->
              <ng-container *ngSwitchCase="FormType.Input">
                <ng-container [ngSwitch]="inputConf.type">
                  <input *ngSwitchCase="'number'" [id]="inputConf.valuePath" [name]="inputConf.valuePath" type="number" pInputText [formControlName]="inputConf.valuePath"
                    [attributes]="inputConf.extraAttributes" [style]="inputConf.style" (input)="inputConf.onInput()">
                  <input *ngSwitchDefault [id]="inputConf.valuePath" [name]="inputConf.valuePath" [type]="inputConf.type" pInputText [formControlName]="inputConf.valuePath"
                    [attributes]="inputConf.extraAttributes" [style]="inputConf.style" (input)="inputConf.onInput()">
                </ng-container>
              </ng-container>
              <!-- Dropdown Type -->
              <p-dropdown *ngSwitchCase="FormType.Dropdown" [id]="inputConf.valuePath" [name]="inputConf.valuePath" [formControlName]="inputConf.valuePath"
                [options]="inputConf.options" [autoDisplayFirst]="false" autoWidth="false" [style]="inputConf.style" [attributes]="inputConf.extraAttributes"
                [disabled]="inputConf.disabled" [required]="inputConf.required"></p-dropdown>

              </ng-container>
            <label for="{{inputConf.valuePath}}">{{inputConf.label}}</label>

            <app-validation-message [control]="frameDetailsForm?.controls[inputConf.valuePath]" [inputConf]="inputConf"></app-validation-message>

          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-content-end">
      <span class="p-input-icon-left">
        <button pButton pRipple label="Cancel" class="p-button-text" icon="pi pi-times" type="button"
          (click)="formCancel()"></button>
      </span>
      <span class="p-input-icon-left">
        <button (click)="formReset()" class="p-button-text" icon="pi pi-undo" id="resetButton" label="Reset" pButton
          pRipple [disabled]="!frameDetailsForm.dirty" type="button" *ngIf="userCanManage"></button>
      </span>
      <span class="p-input-icon-left pl-2">
        <button pButton pRipple type="submit" label="Save" icon="pi pi-check"
        [disabled]="(!frameDetailsForm.dirty || frameDetailsForm.invalid || ErrorMessage.length > 0)" *ngIf="userCanManage"></button>
      </span>
    </div>
  </form>
</div>
