import {Observable, Observer} from 'rxjs';
import {CanDeactivate} from '@angular/router';
import {Injectable} from '@angular/core';
import {ConfirmationService} from 'primeng/api';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class UnsavedChangesGuard implements CanDeactivate<CanComponentDeactivate> {
  constructor(private confirmationService: ConfirmationService) {

  }

  canDeactivate(component: CanComponentDeactivate) {
    if (component.canDeactivate()) {
      return new Observable((observer: Observer<boolean>) => {
        this.confirmationService.confirm({
          header: 'Warning',
          message: 'You have unsaved changes. Are you sure you want to leave this page?',
          rejectButtonStyleClass: 'p-button-text',
          accept: () => {
            observer.next(true);
            observer.complete();
          },
          reject: () => {
            observer.next(false);
            observer.complete();
          }
        });
      });
    } else {
      return true;
    }
  }
}
