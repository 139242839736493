import {RoleKeyValue} from '../../../../app/common/models/role';

export class User {
  user_id: number;
  first_name: string;
  last_name?: string; 
  display_name: string;
  user_email: string;
  status: string;
  display_picture?: any;
  user_admin: boolean;
  role?: RoleKeyValue;
  user_orgs_ids: number[];
  user_org_codes: string[];
  istdyuser?: boolean;
  currentAirlineID?: number;
  AD_user?: boolean;
  business?:string;
  business_name?: string;
  business_code?: string;
  business_id?: number;
}

export class License {
  number_of_users: number;
  active_application_users: number;
}
