<div class="col-12 card p-0">
  <form #UserSubscription="ngForm" (ngSubmit)="OnSubmit()" novalidate>
    <div class="grid justify-content-between col-12">
      <div class="col-5 flex flex-column justify-content-between">
        <p-table
          #dtAvailableSubscription
          (onHeaderCheckboxToggle)="onCheckAllAvailableSubscription($event)"
          [(selection)]="availableSubscriptionTypesSelected"
          [value]="availableSubscriptionTypes"
          [showCurrentPageReport]="true"
          [paginator]="availableSubscriptionTypes.length > defaultTableRows"
          [rowsPerPageOptions]="[20, 50, 100, 200]"
          [rows]="defaultTableRows"
          currentPageReportTemplate="{{ currentPageReport + ' ' + pageTitle }}"
          dataKey="alert_id"
          id="availableNotifs"
          sortMode="single"
          sortField="name"
          [sortOrder]="1"
        >
          <ng-template pTemplate="header">
            <tr>
              <th class="text-center" style="width: 10%">
                <p-tableHeaderCheckbox
                  pTooltip="Check all"
                ></p-tableHeaderCheckbox>
              </th>
              <th pSortableColumn="name">
                <div class="flex justify-content-between align-items-center">
                  Available Subscriptions
                  <span>
                    <p-sortIcon field="name"></p-sortIcon>
                    <p-columnFilter
                      [showAddButton]="false"
                      [showOperator]="false"
                      class="ml-0"
                      [hideOnClear]="true"
                      display="menu"
                      field="name"
                      type="text"
                    ></p-columnFilter>
                  </span>
                </div>
              </th>
            </tr>
          </ng-template>
          <ng-template let-notificationType pTemplate="body">
            <ng-container
              *ngIf="
                toNotificationTypeUser(notificationType);
                let notificationType
              "
            >
              <tr [pSelectableRow]="notificationType">
                <td
                  style="width: 10%; text-align: center; vertical-align: center"
                >
                  <p-tableCheckbox [value]="notificationType"></p-tableCheckbox>
                </td>
                <td style="word-wrap: break-word; width: 90%">
                  {{ notificationType.name }}
                </td>
              </tr>
            </ng-container>
          </ng-template>
          <ng-template pTemplate="emptymessage"  class="no-available-subscriptions">
            <tr>
              <td [colSpan]="2">No subscription available.</td>
            </tr>
          </ng-template>
        </p-table>
        <div
          *ngIf="
            availableSubscriptionTypes.length > 0 &&
            defaultTableRows >= availableSubscriptionTypes.length
          "
          style="text-align: center"
        >
          <p class="row-paging">
            Showing 1 to {{ availableSubscriptionTypes.length }} of
            {{ availableSubscriptionTypes.length }} {{ pageTitle }}
          </p>
        </div>
      </div>
      <div class="col-1 align-self-center text-center">
        <div>
          <span pTooltip="Assign subscriptions" tooltipPosition="left">
            <button
              (click)="assignSubscriptionClick()"
              [disabled]="
                !availableSubscriptionTypesSelected ||
                availableSubscriptionTypesSelected.length <= 0
              "
              class="mb-3 p-element p-ripple p-button p-component p-button-icon-only"
              icon="pi pi-arrow-right"
              id="assignNotifButton"
              pButton
              pRipple
              type="button"
            ></button>
          </span>
        </div>
        <div>
          <span
            pTooltip="Unassign selected subscriptions"
            tooltipPosition="right"
          >
            <button
              (click)="unassignSunscriptionClick()"
              [disabled]="
                !assignedSubscriptionSelected ||
                assignedSubscriptionSelected.length <= 0
              "
              class="p-element p-ripple p-button p-component p-button-icon-only"
              icon="pi pi-arrow-left"
              id="unassignNotifButton"
              pButton
              pRipple
              type="button"
            ></button>
          </span>
        </div>
      </div>
      <div class="col-5 flex flex-column justify-content-between">
        <p-table
          #dtAssignedSubscription
          (onHeaderCheckboxToggle)="onCheckAllAssignedSubscription($event)"
          [(selection)]="assignedSubscriptionSelected"
          [value]="assignedSubscription"
          dataKey="alert_id"
          id="dtAssignedSubscription"
          [showCurrentPageReport]="true"
          [paginator]="assignedSubscription.length > defaultTableRows"
          [rowsPerPageOptions]="[20, 50, 100, 200]"
          [rows]="defaultTableRows"
          currentPageReportTemplate="{{ currentPageReport + ' ' + pageTitle }}"
          sortMode="single"
          sortField="name"
          [sortOrder]="1"
        >
          <ng-template pTemplate="header">
            <tr>
              <th class="text-center" style="width: 10%">
                <p-tableHeaderCheckbox
                  pTooltip="Check all"
                ></p-tableHeaderCheckbox>
              </th>
              <th pSortableColumn="name" colspan="5">
                <div class="flex justify-content-between align-items-center">
                  Assigned Subscriptions
                  <span>
                    <p-sortIcon field="name"></p-sortIcon>
                    <p-columnFilter
                      [showAddButton]="false"
                      [showOperator]="false"
                      class="ml-0"
                      [hideOnClear]="true"
                      display="menu"
                      field="name"
                      type="text"
                    ></p-columnFilter>
                  </span>
                </div>
              </th>
            </tr>
          </ng-template>
          <ng-template let-notificationType pTemplate="body">
            <ng-container
              *ngIf="
                toNotificationTypeUser(notificationType);
                let notificationType
              "
            >
              <tr [pSelectableRow]="notificationType">
                <td
                  style="width: 10%; text-align: center; vertical-align: center"
                >
                  <p-tableCheckbox [value]="notificationType"></p-tableCheckbox>
                </td>
                <td style="word-wrap: break-word">
                  <span>
                    {{ notificationType.name }}
                  </span>
                </td>
              </tr>
            </ng-container>
          </ng-template>
          <ng-template pTemplate="emptymessage" class="no-assigned-subscriptions">
            <tr>
              <td [colSpan]="3">No subscription assigned.</td>
            </tr>
          </ng-template>
        </p-table>
        <div
          *ngIf="
            assignedSubscription.length > 0 &&
            defaultTableRows >= assignedSubscription.length
          "
          style="text-align: center"
        >
          <p class="row-paging">
            Showing 1 to {{ assignedSubscription.length }} of
            {{ assignedSubscription.length }} {{ pageTitle }}
          </p>
        </div>
      </div>
    </div>
    <div class="flex justify-content-end">
      <span class="p-input-icon-left p-button-text" style="float: right">
        <button
          pButton
          pRipple
          label="Cancel"
          type="button"
          icon="pi pi-times"
          class="p-button-text"
          (click)="onCancel()"
        ></button>
      </span>
      <span class="p-input-icon-left">
        <button
          pButton
          pRipple
          label="Reset"
          [disabled]="assignedSubscription.length < 1"
          type="button"
          icon="pi pi-refresh"
          class="p-button-text"
          *ngIf="showButtons"
          (click)="onReset()"
        ></button>
      </span>
      <span class="p-input-icon-left ml-3" style="float: right">
        <button
          pButton
          pRipple
          label="Save"
          icon="pi pi-check"
          type="submit"
          *ngIf="showButtons"
          [disabled]="assignedSubscription.length === 0 && availableSubscriptionTypes.length === 0"
        ></button>
      </span>
    </div>
  </form>
</div>
