<p-blockUI [blocked]="loadingCount > 0">
  <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>

<form (ngSubmit)="onSubmit()" novalidate>
  <p-sidebar [(visible)]="appMain.showSearchPanel" (onShow)="onShow();setFocus(searchInput)"
             position="right" styleClass="p-sidebar-md">
    <h5>Search Aircraft and Devices</h5>
    <div class="p-inputgroup p-pt-2 p-pb-2">
      <input #searchInput id="searchInput" name="searchInput" type="text"
             pInputText [(ngModel)]="searchQuery" [textContent]="searchQuery"
             (keyup)="onKeyUp()">
      <span class="p-inputgroup-addon" (click)="onClickSearch()"
            style="cursor: pointer; background-color: var(--primary-color); color:white; border: none">
        <i class="pi pi-search"></i>
      </span>
    </div>
    <p-scrollPanel [style]="{'width': '100%', 'height': '75vh'}">
      <p-treeTable #treeAods [value]="rootNodes" filterMode="strict">
        <ng-template pTemplate="header">
          <tr style="vertical-align: top">
            <th style="width: 70%">
              <div class="p-ai-center">
                Results <span *ngIf="noResults" class="p-pl-1"
                              style="font-weight: bolder; font-style: italic; color: red">- No matches found.</span>
              </div>
            </th>
            <th style="width: 10%">
            </th>
            <th style="width: 10%">
            </th>
            <th style="width: 10%">
            </th>
          </tr>
        </ng-template>

        <ng-template let-aod="rowData" let-rowNode pTemplate="body">
          <tr [ttRow]="rowNode">
            <td style="width: 70%">
              <div class="p-d-flex p-ai-center">
                <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                <span *ngIf="rowNode.node.level === nodeLevelAirline">
                {{rowNode.node.label}}
                </span>

                <span *ngIf="rowNode.node.level === nodeLevelTail">
                  <span *ngIf="rowNode.node.label != AppConstants.UnassignedText">
                    {{rowNode.node.label}}
                  </span>
                  <span *ngIf="rowNode.node.label == AppConstants.UnassignedText" class="p-text-italic">
                    {{rowNode.node.label}}
                  </span>
                </span>                           
              </div>
            </td>                        
          </tr>
        </ng-template>
      </p-treeTable>
    </p-scrollPanel>
  </p-sidebar>
</form>
