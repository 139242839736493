import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ListViewColumnType } from '../../shared/helpers/data-frame.enums';
import { getProp } from '../../shared/utils';
import { DataFrameConstants } from '../../shared/constants/constants';

@Component({
  selector: 'app-list-view-table',
  templateUrl: './list-view-table.component.html',
  styleUrls: ['./list-view-table.component.css']
})
export class ListViewTableComponent {

  @Input() copydataparameter;
  @Input() columns: Array<any>;
  @Input() defaultTableRows: number;
  @Input() dfPropId: string;
  @Input() dataKey: string; 
  @Input() sortField: string; 
  @Input() currentPageReport: string;
  @Input() pageTitle: string;
  @Input() emptyMessage: string;

  @Output() onRowSelect = new EventEmitter<string>();
  
  ListViewColumnType = ListViewColumnType;
  DataFrameConstants = DataFrameConstants;
  getProp = getProp;
}
