export class ACMS {
  redaction_id?: number;
  name: string;
  description?: string;
  textualoutput?: boolean;
  mapjson?: string;
  defaultparametervaluesconfig?: string;
  redactiontype_id?: number;
  acmsreportmap_id?: number;
  organization_id?: number;
  acmsreportname: string;
}

export class ResponseACMS {
  redaction_id?: number;
  redaction_name?: string;
  redaction_description?: string;
  redaction_textualoutput?: boolean;
  redaction_mapjson?: string;
  redaction_defaultparametervaluesconfig?: string;
  redaction_redactiontype_id?: number;
  redaction_acmsreportmap_id?: number;
  organization_id?: number;
  enhanced: boolean;
}

export class jsonValues {
  filetype_id?: number;
  acmsreportmap_id: number;
  airlineName: string;
}

export class getRegex {
  regex: string;
}
export interface ACMSType {
  acms_id?: number;
  acms_name?: string;
}
export interface fileType {
  file_id: number;
  file_type: string;
}

export interface selectedAcmsReport {
  selectReports: number[];
}

export enum FileType {
  Teledyne = 1,
  AircraftReport = 2,
}

export const GetFileType = [
  { file_id: FileType.Teledyne, file_type: 'Teledyne' },
  { file_id: FileType.AircraftReport, file_type: 'Aircraft Report' },
];
