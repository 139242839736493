export const DataFrameConstants = {
    rowsPerPageOptions: [20,50,100,200],
    rowsPerPageOptionsMapView: [20, 40, 80, 200],
    enumType: [ "ASCII","BCD","Floating Point", "Signed Binary", "Unsigned Binary"],
    euType: ["Integer-Real Table","Polynomial", "Real-Real Table"],
}

export enum DataFrameAnalyseAttributes  {
  GENERAL = "General",
  MNEMONIC = "Mnemonic",
  DESCRIPTION = "Description",
  NAME = "Name",
  TEST_CONVERSION = "Test Conversion",
  EU_CONVERSION = "EU Conversion",
  RECORDING = "Recording",
  DISCRETE = "Discrete",
  LOCATION_PARTS = "Location Parts",
}
