import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { TerminalVHFNavaidService } from 'src/app/features/administration/services/terminal-vhf-navaid.service';
import { ApiType, NavigationInformationBaseViewComponent } from '../navigation-information-base/navigation-information-base-view.component';
import { Components } from 'src/app/features/administration/integration/administration.components';

@Component({
  selector: 'app-terminal-vhf-navaids',
  templateUrl: './terminal-vhf-navaids-details.component.html',
})
export class TerminalVHFNavaidsDetailsComponent extends NavigationInformationBaseViewComponent implements OnInit {
  pageTitle = Components.TerminalVHFNavaidsDetails.label;
  selected_date: any;
  vor_identifier: any;
  airport_icao_identifier: any;
  file_dates: any;
  airport_identifier: any;
  procedureBreadcrumb: any;

  constructor(
    private terminalVHFService: TerminalVHFNavaidService,
    messageService: MessageService,
    confirmationService: ConfirmationService,
    router: Router,
    route: ActivatedRoute,
    breadcrumbService: BreadcrumbService
  ) {
    super(messageService, confirmationService, router, breadcrumbService, route);
    this.airport_icao_identifier = route.snapshot.queryParamMap.get('a');
    this.airport_identifier = route.snapshot.queryParamMap.get('b');
    const dte = route.snapshot.queryParamMap.get('c');
    this.selected_date = { "dte": dte };
    const file_dates = route.snapshot.queryParamMap.get('d');
    this.file_dates = JSON.parse(file_dates);
    this.vor_identifier = route.snapshot.queryParamMap.get('e');
    this.procedureBreadcrumb = JSON.parse(route.snapshot.queryParamMap.get('f'));

    this.setBreadcrumb();
    this.getTerminalVHFNavaidDetail(dte, this.airport_icao_identifier, this.vor_identifier);
  }

  ngOnInit(): void {
    this.setBreadcrumb();
  }

  getTerminalVHFNavaidDetail(dte, airport_icao_identifier, vor_identifier) {
    const arg: ApiType = { request: this.terminalVHFService.getTerminalVHFNavaid(dte, airport_icao_identifier, vor_identifier), api_name: `vhf terminal navaid`, callback: this.callback.bind(this) }
    this.callAPI(arg);
  }

  callback(data) {
    this.data = data;
    console.log(data);
  }

  override onChange($event) {
    super.onChange([Components.TerminalVHFNavaidsDetails.path],
      {
        queryParams: {
          a: this.airport_icao_identifier,
          b: this.airport_identifier,
          c: this.selected_date["dte"],
          d: JSON.stringify(this.file_dates),
          e: this.vor_identifier,
          f: JSON.stringify(this.procedureBreadcrumb)
        }
      }
    );
  }

  override setBreadcrumb() {
    super.setBreadcrumb(
      [
        {
          label: Components.AirportList.label,
          routerLink: Components.AirportList.path,
          queryParams: { a: this.selected_date["dte"] }
        },
        {
          label: Components.TerminalVHFNavaidsList.label + ` (${this.airport_identifier})`,
          routerLink: Components.TerminalVHFNavaidsList.path,
          queryParams: {
            a: this.airport_icao_identifier,
            b: this.airport_identifier,
            c: this.selected_date["dte"],
            d: JSON.stringify(this.file_dates)
          }
        },
        {
          label: Components.TerminalVHFNavaidsDetails.label + ` (${this.vor_identifier || "-"})`
        }
      ]);
  }
}
