<dynamic-form [formFields]="myForm" [isEditMode]="false" [formData]="formdata" (onFormReset)="formReset($event)"
[isDetailsMode]="false" [pageTitle]="title" [isSaveButtonDisabled]="isSaveButtonDisabled()"
(onFormCancel)="formCancel()" (savedClick)="onSave($event)" *ngIf="!isLoading" (onChange)="handleFormChange($event)">

<p-accordion [multiple]="true">
    <p-accordionTab header="General">
        <div class="formgrid grid">
            <div class="lg:col-4">
                <span class="p-float-label">
                    <input id="Frame_ID" name="Frame_ID" type="number" pInputText
                        [(ngModel)]="formdata.Frame_ID" readonly [style]="{'width': '100%'}"
                        [disabled]="!userCanManage" />
                    <label for="Frame_ID">Frame ID</label>
                </span>
            </div>
            <div class="lg:col-4">
                <span class="p-float-label">
                    <input id="Frame_Title" name="Frame_Title" type="text" pInputText
                        [(ngModel)]="formdata.Frame_Title" readonly [style]="{'width': '100%'}"
                        [disabled]="!userCanManage" />
                    <label for="Frame_Title">Frame Name</label>
                </span>
            </div>
            <div class="lg:col-4">
                <span class="p-float-label">
                    <input id="Recording_Rate" name="Recording_Rate" pInputText type="numeric"
                        [(ngModel)]="frameRate" readonly [style]="{'width': '100%'}" [disabled]="!userCanManage" />
                    <label for="Recording_Rate">Frame Rate </label>
                </span>
            </div>
        </div>
        <div class="formgrid grid">
            <div class="lg:col-4">
                <span class="p-float-label">
                    <input id="NumberFrame" name="NumberFrame" type="number" pInputText
                        [(ngModel)]="formdata.Frame_Number" readonly [style]="{'width': '100%'}"
                        [disabled]="!userCanManage" />
                    <label for="NumberFrame">Number in Frame</label>
                </span>
            </div>
            <div class="lg:col-4">
                <span class="p-float-label">
                    <input id="Units" name="Units" type="text" pInputText #Units
                        [(ngModel)]="formdata.Units" [style]="{'width': '100%'}"
                        [disabled]="!userCanManage" />
                    <label for="Units">Units</label>
                </span>
            </div>
        </div>
        <div class="formgrid grid">
            <div class="lg:col-4">
                <span class="p-float-label">
                    <input id="Min_Operational_Range" name="Min_Operational_Range" type="number" pInputText
                        #Min_Operational_Range="ngModel" [(ngModel)]="formdata.RealRange_Start" required
                        [pKeyFilter]="'int'" [max]="formdata.RealRange_End" [style]="{'width': '100%'}"
                        [disabled]="!userCanManage" (keypress)="onKeypressNum($event)"/>
                    <label for="Min_Operational_Range">Operational Range Min</label>
                </span>
                <div class="tdy-required"
                    *ngIf="( Min_Operational_Range.dirty  || Min_Operational_Range.touched) && Min_Operational_Range.hasError('required')">
                    Min Operational Range is required.
                </div>
                <div class="tdy-required" *ngIf="Min_Operational_Range.hasError('max')">
                    Min Operational Range should not be greater than Max Operational Range.
                </div>
            </div>
            <div class="lg:col-4">
                <span class="p-float-label">
                    <input id="Max_Operational_Range" name="Max_Operational_Range" type="number" pInputText
                        #Max_Operational_Range="ngModel" [(ngModel)]="formdata.RealRange_End" required
                        [pKeyFilter]="'int'" [min]="formdata.RealRange_Start" [style]="{'width': '100%'}"
                        [disabled]="!userCanManage" (keypress)="onKeypressNum($event)"/>
                    <label for="Max_Operational_Range">Operational Range Max</label>
                </span>
                <div class="tdy-required"
                    *ngIf="(Max_Operational_Range.dirty  || Max_Operational_Range.touched) && Max_Operational_Range.hasError('required')">
                    Max Operational Range is required.
                </div>
                <div class="tdy-required" *ngIf="Max_Operational_Range.hasError('min')">
                    Max Operational Range should not be less than Min Operational Range.
                </div>
            </div>
        </div>
    </p-accordionTab>
    <p-accordionTab header="Recording">
        <div class="formgrid grid">
            <div class="lg:col-4">
                <span class="p-float-label">
                    <input id="Bit_Length" name="Bit_Length" type="number" pInputText #Bit_Length="ngModel"
                        [(ngModel)]="formdata.Number_of_Bits" required [pKeyFilter]="'int'" [min]="1"
                        (keypress)="onKeySearch($event)" [style]="{'width': '100%'}"
                        (input)="calculateBitlength(formdata.Number_of_Bits)"
                        [disabled]="!userCanManage" />
                    <label for="Bit_Length">Number of Bits </label>
                </span>
                <div class="tdy-required"
                    *ngIf="( Bit_Length.dirty  || Bit_Length.touched || !formdata.Number_of_Bits) && Bit_Length.hasError('required')">
                    Number of Bits is required.
                </div>
                <div class="tdy-required"
                    *ngIf="( Bit_Length.dirty  || Bit_Length.touched) && Bit_Length.hasError('min')">
                    Number of Bits should not be less than 1.
                </div>
                <div class="tdy-required"
                    *ngIf="!isNumber(formdata.Number_of_Bits) && formdata.Number_of_Bits">
                    Number of Bits should be number.
                </div>
                <div class="tdy-required"
                    *ngIf="!isInteger(formdata.Number_of_Bits) && formdata.Number_of_Bits">
                    Number of Bits should be an integer.
                </div>
            </div>
            <div class="lg:col-4">
                <span class="p-float-label">
                    <input id="Raw_Range_Min" name="Raw_Range_Min" type="number" pInputText #Raw_Range_Min="ngModel"
                        [(ngModel)]="formdata.Raw_Range_Min" [pKeyFilter]="'int'"
                        [min]="0" [max]="bitLength" required [style]="{'width': '100%'}"
                        (keypress)="onKeySearch($event)" [disabled]="!userCanManage" />
                    <label for="Raw_Range_Min">Raw Range Min</label>
                </span>
                <div class="tdy-required"
                    *ngIf="( Raw_Range_Min.dirty  || Raw_Range_Min.touched ||!formdata.Raw_Range_Min) && Raw_Range_Min.hasError('required')">
                    Raw Range Min is required.
                </div>
                <div class="tdy-required"
                    *ngIf="( Raw_Range_Min.dirty  || Raw_Range_Min.touched) && Raw_Range_Min.hasError('min')">
                    Raw Range Min should not be less than 0.
                </div>
                <div class="tdy-required"
                    *ngIf="!isNumber(formdata.Raw_Range_Min) && formdata.Raw_Range_Min">
                    Raw Range Min should be number.
                </div>
                <div class="tdy-required"
                    *ngIf="( Raw_Range_Min.dirty  || Raw_Range_Min.touched) && Raw_Range_Min.hasError('max')">
                    Raw Range Min should not be greater than Raw Range Max.
                </div>
                <div class="tdy-required"
                    *ngIf="!isInteger(formdata.Raw_Range_Min) && formdata.Raw_Range_Min">
                    Raw Range Min should be an integer.
                </div>
            </div>
            <div class="lg:col-4">
                <span class="p-float-label">
                    <input id="Raw_Range_Max" name="Raw_Range_Max" type="number" pInputText #Raw_Range_Max="ngModel"
                        [(ngModel)]="bitLength" [pKeyFilter]="'int'" [min]="copyBitLength" [max]="copyBitLength"
                        required [style]="{'width': '100%'}" [disabled]="!userCanManage"
                        (keypress)="onKeySearch($event)" />
                    <label for="RawRangeMax">Raw Range Max</label>
                </span>
                <div class="tdy-required"
                    *ngIf="( Raw_Range_Max.dirty  || Raw_Range_Max.touched || !bitLength) && Raw_Range_Max.hasError('required')">
                    Raw Range Max is required.
                </div>
                <div class="tdy-required"
                    *ngIf="( Raw_Range_Max.dirty  || Raw_Range_Max.touched) && Raw_Range_Max.hasError('min')">
                    Raw Range Max should not be less than (2 ^ Bit Length) -1.
                </div>
                <div class="tdy-required"
                    *ngIf="( Raw_Range_Max.dirty  || Raw_Range_Max.touched) && Raw_Range_Max.hasError('max')">
                    Raw Range Max should not be greater than (2 ^ Bit Length) -1.
                </div>
                <div class="tdy-required" *ngIf="!isNumber(bitLength) && bitLength">
                    Raw Range Max should be number.
                </div>
                <div class="tdy-required" *ngIf="!isInteger(bitLength) && bitLength">
                    Raw Range Max should be an integer.
                </div>
            </div>
        </div>
        <div class="formgrid grid">
            <div class="lg:col-4">
                <span class="p-float-label">
                    <p-dropdown id="Data_Type" name="Data_Type" [options]="this.enumType" #Data_Type="ngModel"
                        [(ngModel)]="formdata.Data_Type" (onChange)="onChange($event)"
                        [showClear]="false" autoWidth="false" [required]="true" [style]="{'width':'100%'}"
                        [autoDisplayFirst]="false" [disabled]="!userCanManage"></p-dropdown>
                    <label for="Data_Type">Data Type</label>
                    <div class="tdy-required"
                        *ngIf="(Data_Type.dirty  || Data_Type.touched ) && Data_Type.errors?.['required']">
                        Data Type is required.
                    </div>
                </span>
            </div>
            <div class="lg:col-4">
                <span class="p-float-label">
                    <input id="Signal_Source" name="Signal_Source" type="text" pInputText #Signal_Source
                        [(ngModel)]="formdata.Signal_Source"
                        [style]="{'width': '100%' ,'text-transform':'uppercase'}" [disabled]="!userCanManage" />
                    <label for="Signal_Source">Signal Source</label>
                </span>
            </div>
        </div>
    </p-accordionTab>
    <p-accordionTab header="EU Conversion">
        <div class="flex justify-content-end  mb-3" *ngIf="this.selectedEuValue === 'Polynomial' && !(indexValue === 0) && userCanManage">
            <button id="EU_Button" name="EU_Button" type="button" label="Add EU" pButton pRipple icon="pi pi-plus"
                (click)="addEUParameters()"></button>
        </div>
        <div class="flex justify-content-end  mb-3" *ngIf="this.selectedEuValue === 'Integer-Real Table' && !(indexValue === 0) && userCanManage">
            <button id="EU_Button" name="EU_Button" type="button" label="Add EU" pButton pRipple icon="pi pi-plus"               
                (click)="addEUInt()"></button>
        </div>
        <div class="flex justify-content-end  mb-3" *ngIf="this.selectedEuValue === 'Real-Real Table' && !(indexValue === 0) && userCanManage">
            <button id="EU_Button" name="EU_Button" type="button" label="Add EU" pButton pRipple icon="pi pi-plus"
                (click)="addEUReal()"></button>
        </div>
        <div class="formgrid grid">
        <div class="lg:col-4">
            <span class="p-float-label">
                <input id="Resolution" name="Resolution" type="number" pInputText #Resolution="ngModel"
                    [(ngModel)]="formdata.Resolution" required [style]="{'width': '100%'}"
                    (keypress)="onKeySearch($event)" [disabled]="!userCanManage" />
                <label for="Resolution">Resolution</label>
            </span>
            <div class="tdy-required"
                *ngIf="( Resolution.dirty  || Resolution.touched || !formdata.Resolution) && Resolution.hasError('required')">
                Resolution is required.
            </div>
            <div class="tdy-required"
                *ngIf="((formdata.Data_Type ==='ASCII' && formdata.Resolution != 0 && formdata.Resolution))">
                Resolution should be 0.
            </div>
            <div class="tdy-required"
                *ngIf="((formdata.Data_Type !='ASCII' && formdata.Resolution === 0))">
                Resolution should not be 0.
            </div>
            <div class="tdy-required"
                *ngIf="( formdata.Resolution != 1 && tableData.length > 0 && formdata.Resolution )">
                Resolution should be 1.
            </div>
        </div>

        <div class="lg:col-4">
            <span class="p-float-label">
                <p-dropdown id="EU_Type" name="EU_Type" (onChange)="onChangeEU($event)" [options]="this.euType"
                    [(ngModel)]="selectedEuValue" #EU_Type="ngModel" [style]="{'width':'100%'}"
                    [readonly]="emptyVal == true" [showClear]="false" [autoDisplayFirst]="false"
                    [disabled]="!userCanManage"></p-dropdown>
                <label for="EU_Type">EU Type</label>
                <div class="tdy-required"
                    *ngIf="(EU_Type.dirty  || EU_Type.touched ) && EU_Type.errors?.['required']">
                    EU Type is required.
                </div>
            </span>
        </div>

        <div class="lg:col-4">
            <span class="p-float-label">
                <input id="Sign_Convention" name="Sign_Convention" type="text" pInputText #Sign_Convention
                    [(ngModel)]="formdata.Sign_Convention" [style]="{'width': '100%'}"
                    [disabled]="!userCanManage" />
                <label for="Sign_Convention">Sign Convention</label>
            </span>
        </div>
</div>
<div class="flex justify-content-end  mb-3">
    <button id="EU_Button" name="EU_Button" type="button" label="Add EU" pButton pRipple icon="pi pi-plus"
        *ngIf="selectedEuValue === 'Polynomial' && userCanManage"
    
        (click)="addEUParameters()"></button>
</div>
<div class="flex justify-content-end  mb-3">
    <button id="EU_Button" name="EU_Button" type="button" label="Add EU" pButton pRipple icon="pi pi-plus"
        *ngIf="selectedEuValue === 'Integer-Real Table' && userCanManage"
       
        (click)="addEUInt()" ></button>
</div>
<div class="flex justify-content-end  mb-3">
    <button id="EU_Button" name="EU_Button" type="button" label="Add EU" pButton pRipple icon="pi pi-plus"
        *ngIf="selectedEuValue === 'Real-Real Table' && userCanManage"
        (click)="addEUReal()" ></button>
</div>

            <div *ngIf="this.selectedEuValue === 'Polynomial'">
                <p-table [value]="EuData" #Poly
                    scrollHeight="400px" [tableStyle]="{'width':'100%', 'height':'30%'}" [selectionMode]='((!userCanView  && userCanManage)|| (!userCanView  && !userCanManage))? "":"single"'>
                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn={{dis.field}} *ngFor="let dis of EuCols">
                                {{dis.header}}
                            </th>
                            <th *ngIf="userCanManage">Delete</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-eudata let-editing="editing" let-di="rowIndex">
                        <tr>
                            <td pEditableColumn class="text-left">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input type="number" pInputText [(ngModel)]="eudata.index" name="poly"
                                            (input)="checkEmptyValue(eudata.index)" id="poly" #poly="ngModel"
                                            required [disabled]="!userCanManage" (keypress)="onKeypressNum($event)"/>
                                        <div class="tdy-required"
                                            *ngIf="( poly.dirty  || poly.touched) && poly.hasError('required')">
                                            Coefficient Index is required.
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{eudata.index}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td pEditableColumn class="text-left">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input type="number" pInputText [(ngModel)]="eudata.text"
                                            name="polytext" (input)="checkEmptyValue(eudata.text)" id="polytext"
                                            #polytext="ngModel" required [disabled]="!userCanManage" (keypress)="onKeypressNum($event)"/>
    
                                        <div class="tdy-required"
                                            *ngIf="( polytext.dirty  || polytext.touched) && polytext.hasError('required')">
                                            Coefficient Value is required.
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{eudata.text}}
    
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td> <button id="EU_del_Button" name="EU_del_Button" type="button"
                                    class="p-button-rounded p-button-text p-button-danger" pButton pRipple
                                    icon="pi pi-trash" *ngIf="userCanManage"
                                    [pTooltip]="((selectedEuValue == 'Polynomial') && ( eudata.index == null || eudata.text == null) ||  minIndex.length > 0)?'Enter valid data...':'Delete...'"
                                    [disabled]="((selectedEuValue == 'Polynomial') && ( eudata.index == null || eudata.text == null) || minIndex.length > 0)"
                                    (click)="openDeleteDialog(eudata,di)"></button></td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
            <div *ngIf="this.selectedEuValue === 'Integer-Real Table'">
                <p-table [value]="EuInteger" #Int
                    scrollHeight="400px" [tableStyle]="{'width':'100%', 'height':'30%'}" [selectionMode]='((!userCanView  && userCanManage)|| (!userCanView  && !userCanManage))? "":"single"'>
                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn={{dis.field}} *ngFor="let dis of EuIntCols">
                                {{dis.header}}
                            </th>
                            <th *ngIf="userCanManage">Delete</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-euindex let-editing="editing" let-di="rowIndex">
                        <tr>
                            <td pEditableColumn class="text-left">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input type="number" pInputText [(ngModel)]="euindex.index"
                                            name="index_real" (input)="checkEmptyValue(euindex.index)"
                                            id="index_real" #index_real="ngModel" required
                                            [disabled]="!userCanManage" (keypress)="onKeypressNum($event)"/>
                                        <div class="tdy-required"
                                            *ngIf="( index_real.dirty  || index_real.touched) && index_real.hasError('required')">
                                            Int-Real Pair Index is required.
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{euindex.index}}
    
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td pEditableColumn class="text-left">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input type="number" pInputText [(ngModel)]="euindex.text"
                                            name="index_text" (input)="checkEmptyValue(euindex.text)"
                                            id="index_text" #index_text="ngModel" required
                                            [disabled]="!userCanManage" (keypress)="onKeypressNum($event)"/>
                                        <div class="tdy-required"
                                            *ngIf="( index_text.dirty  || index_text.touched) && index_text.hasError('required')">
                                            Int-Real Pair Value is required.
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{euindex.text}}
    
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td> <button id="EU_del_Button" name="EU_del_Button" type="button"
                                    class="p-button-rounded p-button-text p-button-danger" pButton pRipple
                                    icon="pi pi-trash" *ngIf="userCanManage"
                                    (click)="openDeleteDialog(euindex,di)"></button></td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
            <div *ngIf="this.selectedEuValue === 'Real-Real Table'">
                <p-table [value]="EuReal" #Real
                    scrollHeight="400px" [tableStyle]="{'width':'100%', 'height':'30%'}" [selectionMode]='((!userCanView  && userCanManage)|| (!userCanView  && !userCanManage))? "":"single"'>
                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn={{dis.field}} *ngFor="let dis of EuRealCols">
                                {{dis.header}}
                            </th>
                            <th *ngIf="userCanManage">Delete</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-eureal let-editing="editing" let-di="rowIndex">
                        <tr>
                            <td pEditableColumn class="text-left">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input type="number" pInputText [(ngModel)]="eureal.index"
                                            name="real_index" (input)="checkEmptyValue(eureal.index)"
                                            id="real_index" #real_index="ngModel" required
                                            [disabled]="!userCanManage" (keypress)="onKeypressNum($event)"/>
                                        <div class="tdy-required"
                                            *ngIf="( real_index.dirty  || real_index.touched) && real_index.hasError('required')">
                                            Real-Real Pair Index is required.
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{eureal.index}}
    
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td pEditableColumn class="text-left">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input type="number" pInputText [(ngModel)]="eureal.text"
                                            name="real_text" (input)="checkEmptyValue(eureal.text)"
                                            id="real_text" #real_text="ngModel" required
                                            [disabled]="!userCanManage" (keypress)="onKeypressNum($event)"/>
                                        <div class="tdy-required"
                                            *ngIf="( real_text.dirty  || real_text.touched) && real_text.hasError('required')">
                                            Real-Real Pair Value is required.
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{eureal.text}}
    
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td> <button id="EU_del_Button" name="EU_del_Button" type="button"
                                    class="p-button-rounded p-button-text p-button-danger" pButton pRipple
                                    icon="pi pi-trash" *ngIf="userCanManage"
                                    [pTooltip]="((selectedEuValue == 'Real-Real Table') && (eureal.index == null || eureal.text == null)) ? 'Enter valid data...' : 'Delete...'"
                                    [disabled]="((selectedEuValue == 'Real-Real Table') && ( eureal.index == null || eureal.text == null))"
                                    (click)="openDeleteDialog(eureal,di)"></button></td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
    </p-accordionTab>
    <p-accordionTab header="Discrete Text">
        <div class="flex justify-content-end mb-3">
            <button id="TXD_Button" name="TXD_Button" type="button" label="Add Discrete" pButton pRipple
                icon="pi pi-plus" *ngIf="selectedValue ==='Unsigned Binary' && indexValue== 0 && userCanManage"
                [disabled]="(formdata.Data_Type != 'Unsigned Binary' ? true : false || isDuplicate === true || minIndex.length > 0 )"
                (click)="addDiscreteParameters(tableData)"></button>
        </div>
            <div *ngIf="formdata.Data_Type === 'Unsigned Binary'">
                <p-table [value]="tableData" #discrete [tableStyle]="{'width':'100%'}" [selectionMode]='((!userCanView  && userCanManage)|| (!userCanView  && !userCanManage))? "":"single"'>
                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn={{dis.field}} *ngFor="let dis of disCols">
                                {{dis.header}}
                            </th>
                            <th *ngIf="userCanManage">Delete</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-tableData let-editing="editing" let-di="rowIndex">
                        <tr>
                            <td pEditableColumn class="text-left">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input id="text" name="text" #text="ngModel" type="text" pInputText
                                            [ngModel]="tableData.text"
                                            (ngModelChange)="tableData.text = $event.toUpperCase()"
                                            (input)="isCellEmpty(tableData.text)"
                                            (keyup)="isDuplicateText(tableData,di)" required pattern=".*\S.*"
                                            [style]="{'text-transform':'uppercase'}"
                                            [disabled]="!userCanManage" />
                                        <div class="tdy-required"
                                            *ngIf="( text.dirty  || text.touched) && text.hasError('required')">
                                            Discrete Text is required.
                                        </div>
                                        <div class="tdy-required" *ngIf="text.hasError('pattern')">
                                            Discrete Text cannot be left blank.
                                        </div>
                                        <div class="tdy-required"
                                            *ngIf="isDuplicate === true && textIndex === di">
                                            Discrete Text should be unique.
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{tableData.text}}
                                        <div class="tdy-required" *ngIf="isCellEmpty(tableData.text)">
                                            Discrete Text is required.
                                        </div>
                                        <div class="tdy-required"
                                            *ngIf="isDuplicate === true && textIndex === di">
                                            Discrete Text should be unique.
                                        </div>
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td pEditableColumn class="text-left">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input id="min" name="min" type="number" pInputText #min="ngModel"
                                            [(ngModel)]="tableData.min" (input)="checkBitLength(tableData,di)"
                                            [min]="0" [pKeyFilter]="'int'" required [max]="bitLength"
                                            [disabled]="!userCanManage" (keypress)="onKeypressNum($event)" />
                                        <div class="tdy-required"
                                            *ngIf="( min.dirty  || min.touched) && min.hasError('required')">
                                            Discrete Min is required.
                                        </div>
                                        <div class="tdy-required"
                                            *ngIf="( min.dirty  || min.touched) && min.hasError('min')">
                                            Discrete Min should not be less than 0.
                                        </div>
                                        <div class="tdy-required"
                                            *ngIf="( min.dirty  || min.touched) && min.hasError('max')">
                                            Discrete Min should not be greater than Raw Range Max.
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{tableData.min}}
    
                                        <div *ngFor="let index of minIndex">
                                            <div class="tdy-required" *ngIf="di == index">
                                                Discrete Min already exist.
                                            </div>
                                        </div>
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td pEditableColumn class="text-left">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input id="max" name="max" type="number" pInputText #max="ngModel"
                                            [(ngModel)]="tableData.max" [pKeyFilter]="'int'" [min]="0"
                                            (input)="checkBitLength(tableData,di)" [max]="bitLength" required
                                            [disabled]="!userCanManage" (keypress)="onKeypressNum($event)"/>
                                        <div class="tdy-required"
                                            *ngIf="( max.dirty  || max.touched) && max.hasError('required')">
                                            Discrete Max is required.
                                        </div>
                                        <div class="tdy-required"
                                            *ngIf="( max.dirty  || max.touched) && max.hasError('max')">
                                            Discrete Max should not be greater than Raw Range Max.
                                        </div>
                                        <div class="tdy-required"
                                            *ngIf="( max.dirty  || max.touched) && max.hasError('min')">
                                            Discrete Max should not be less than 0.
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{tableData.max}}
                                        <div *ngFor="let index of minIndex">
                                            <div class="tdy-required" *ngIf="di == index">
                                                Discrete Max already exist.
                                            </div>
                                        </div>
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td> <button id="TXD_del_Button" name="TXD_del_Button" type="button"
                                    class="p-button-rounded p-button-text p-button-danger" pButton pRipple
                                    icon="pi pi-trash" *ngIf="userCanManage"
                                    [pTooltip]="((tableData.text == '') || (tableData.min == null) || (tableData.max == null) || isDuplicate === true || textIndex != null) ? 'Enter valid data...' : 'Delete...'"
                                    [disabled]="((tableData.text == '') || (tableData.min == null) || (tableData.max == null) || isDuplicate === true || textIndex != null)"
                                    (click)="openDeleteDialog(tableData,di)"></button></td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
    </p-accordionTab>
</p-accordion>
</dynamic-form>
<app-spinner [isLoading]='isLoading'></app-spinner>