import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { NDBNavaidService } from 'src/app/features/administration/services/enroutewaypoint.service';
import { UserService } from 'src/app/features/administration/services/user.service';
import { ApiType, NavigationInformationBaseViewComponent } from '../navigation-information-base/navigation-information-base-view.component';
import { Components } from 'src/app/features/administration/integration/administration.components';

@Component({
  selector: 'app-en-route-ndb-navaids',
  templateUrl: './enroute-ndb-navaids-details.component.html',

})
export class EnrouteNDBNavaidsDetailsComponent extends NavigationInformationBaseViewComponent implements OnInit {
  pageTitle = Components.EnRouteNdbNavaidsDetails.label;
  selected_date: any;
  ndb_identifier: any;
  short_icao: any;
  file_dates: any;
  procedureBreadcrumb: any;

  constructor(
    private enRouteNdbService: NDBNavaidService,
    userService: UserService,
    messageService: MessageService,
    confirmationService: ConfirmationService,
    router: Router,
    route: ActivatedRoute,
    breadcrumbService: BreadcrumbService
  ) {
    super(messageService, confirmationService, router, breadcrumbService, route);
    this.short_icao = route.snapshot.queryParamMap.get('a');
    this.ndb_identifier = route.snapshot.queryParamMap.get('b');
    const dte = route.snapshot.queryParamMap.get('c');
    this.selected_date = { "dte": dte };
    const file_dates = route.snapshot.queryParamMap.get('d');
    this.procedureBreadcrumb = JSON.parse(route.snapshot.queryParamMap.get('e'));

    this.file_dates = JSON.parse(file_dates)
    this.setBreadcrumb();
    this.getEnrouteNdbNavaid(dte, this.short_icao, this.ndb_identifier);
  }

  ngOnInit(): void {
    this.setBreadcrumb();
  }

  getEnrouteNdbNavaid(dte, short_icao, ndb_identifier) {
    const arg: ApiType = { request: this.enRouteNdbService.getEnrouteNDBNavaid(dte, short_icao, ndb_identifier), api_name: `enroute ndb navaid`, callback: this.callback.bind(this) }
    this.callAPI(arg);
  }

  callback(data) {
    this.data = data;
    console.log(data);
  }

  override setBreadcrumb(): void {
    const trimRunwayIdentifier = (runwayIdentifier: string): string => {
      if (runwayIdentifier) {
        return runwayIdentifier.replace(/^RW/, '');
      }
      return '-';
    };

    if (this.procedureBreadcrumb) {
      super.setBreadcrumb(
        [
          {
            label: Components.AirportList.label,
            routerLink: Components.AirportList.path,
            queryParams: { a: this.selected_date["dte"] }
          },
          {
            label: Components.RunwayList.label + ` (${this.procedureBreadcrumb["display_icao_iata"]})`,
            routerLink: Components.RunwayList.path,
            queryParams: {
              a: this.procedureBreadcrumb["icao"],
              b: this.procedureBreadcrumb["display_icao_iata"],
              c: this.selected_date["dte"],
              d: JSON.stringify(this.file_dates)
            }
          },
          {
            label: Components.ProceduresList.label + ` (${trimRunwayIdentifier(this.procedureBreadcrumb["transition_identifier"])})`,
            routerLink: Components.ProceduresList.path,
            queryParams: {
              a: this.procedureBreadcrumb["icao"],
              b: this.procedureBreadcrumb["display_icao_iata"],
              c: this.selected_date["dte"],
              d: JSON.stringify(this.file_dates),
              e: this.procedureBreadcrumb["transition_identifier"]
            }
          },
          {
            label: Components.ProcedureDetails.label + ` (${this.procedureBreadcrumb["sid_star_approach_identifier"] || "-"})`,
            routerLink: Components.ProcedureDetails.path,
            queryParams: {
              a: this.procedureBreadcrumb["icao"],
              b: this.procedureBreadcrumb["display_icao_iata"],
              c: this.selected_date["dte"],
              d: JSON.stringify(this.file_dates),
              e: this.procedureBreadcrumb["transition_identifier"],
              f: this.procedureBreadcrumb["procedure_id"],
              g: this.procedureBreadcrumb["sid_star_approach_identifier"]
            }
          },
          {
            label: Components.EnRouteNdbNavaidsDetails.label + ` (${this.ndb_identifier || "-"})`
          }
        ]);
    }
    else {
      super.setBreadcrumb(
        [
          {
            label: Components.EnRouteNdbNavaidsList.label,
            routerLink: Components.EnRouteNdbNavaidsList.path,
            queryParams: { a: this.selected_date["dte"] }
          },
          {
            label: Components.EnRouteNdbNavaidsDetails.label + ` (${this.ndb_identifier || "-"})`
          }
        ]);
    }
  }

  override onChange($event): void {
    super.onChange([Components.EnRouteNdbNavaidsDetails.path], {
      queryParams: {
        a: this.short_icao,
        b: this.ndb_identifier,
        c: this.selected_date["dte"],
        d: JSON.stringify(this.file_dates),
        e: JSON.stringify(this.procedureBreadcrumb)
      }
    });
  }
}
