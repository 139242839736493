import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/common/services/base.service';
import { Organization } from 'src/app/features/administration/models/organization';
import { environment } from 'src/environments/environment';
import { SettingPair } from 'src/app/common/models/setting';

export class CompanyPayloadModel {
  id: number; code?: string; name: string; web_logo?: string; mobile_logo?: string; company_settings?: SettingPair[];
}
@Injectable({
  providedIn: 'root'
})
export class CompanyService extends BaseService {
  companyUrl = environment.apiUrl.admin + 'company';

  constructor(private http: HttpClient) {
    super();
  }

  getCompany(companyID: number): Observable<Organization> {
    const url = this.companyUrl + `/${companyID}`;
    return this.http.get<Organization>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError));
  }

  getCurrentCompany(): Observable<Organization> {
    const url = environment.apiUrl.admin + 'current-company';
    return this.http.get<Organization>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError));
  }

  listCompanies(): Observable<Organization[]> {
    return this.http.get<Organization[]>(this.companyUrl, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError));
  }

  addCompany(addCompanyPayload: CompanyPayloadModel): Observable<number> {
    return this.http.post<number>(this.companyUrl, addCompanyPayload, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError)
      );
  }

  updateCompany(company: CompanyPayloadModel): Observable<number> {
    const url = this.companyUrl + `/${company.id}`;
    return this.http.put<number>(url, company, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError));
  }

  updateCurrentCompany(currentCompany: CompanyPayloadModel): Observable<number> {
    const url = environment.apiUrl.admin + 'current-company';
    return this.http.put<number>(url, currentCompany, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError),
      );
  }
}
