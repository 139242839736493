<p-blockUI [blocked]="loadingCount > 0">
    <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<p-confirmDialog header="Confirm Deletion" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<div *ngIf="model != null" class="col-12 card">
    <div class="card-header m-2">
        <h5>{{pageTitle}}</h5>
    </div>
    <div class="col-12">
        <p-table [filterDelay]="0" [responsive]="true" [sortOrder]="1" [selectionMode]='((!userCanView  && userCanManage)|| (!userCanView  && !userCanManage))? "":"single"' (onRowSelect)="onRowSelect($event)" [value]="model" 
        [showCurrentPageReport]="true" [paginator]="model.length > defaultTableRows" [rowsPerPageOptions]="[20,50,100,200]" [rows]="defaultTableRows" currentPageReportTemplate="{{currentPageReport + ' ' + pageTitle}}" 
        dataKey="rec_id" id="ListTable"  sortField="version">
            <ng-template pTemplate="header">
                <tr >
                   <th pSortableColumn="version"  style="width: 10%;">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Version
                            <p-sortIcon field="version"></p-sortIcon>
                            <p-columnFilter [showAddButton]="false" [showOperator]="false" class=""
                                display="menu" field="version" type="text" matchMode="contains"></p-columnFilter>
                        </div>
                    </th>
                    <th style="width: 80%;">
                        Description
                    </th>
                    <th>
                        <!-- Compare -->
                    </th>
                    
                    <th *ngIf ="modelName=='dataframe' && userCanAdd"> <!--Clone--></th>
                    <th *ngIf ="modelName=='dataframe' && userCanDownload"> <!--Download--></th>
                   <th></th>
                
                </tr>
            </ng-template>
            <ng-template let-row pTemplate="body">
                <ng-container *ngIf="toModel(row); let row">
                    <tr [pSelectableRow]="row">
                        <td >
                            {{row.version}} 
                        </td>
                        <td>
                          {{ row.description}}
                           
                        </td>


                        <td class="p-0 pr-2" *ngIf ="modelName=='dataframe' && paramSelectionButtonDisabled && userCanModify">
                            <div class="flex justify-content-end" >
                                <button id="parameterSelectionButton" name="parameterSelectionButton" type="button"
                                    class="p-button-rounded p-button-text " pButton pRipple
                                    icon="pi pi-align-center" pTooltip="Parameters Selection..." 
                                    (click)="onParameterSelection(row)"></button>
                            </div>
                            
                        </td>

                        <td class="p-0 pr-2">
                            <div class="flex justify-content-end" >
                                <button id="manageButton" name="manageButton" type="button"
                                    class="p-button-rounded p-button-text " pButton pRipple
                                    icon="pi pi-tags" pTooltip="Compare..." [disabled]="row.cnt == 1"
                                    (click)="onCompare(row)"></button>
                            </div>
                            
                        </td>



                        <td class="p-0 pr-2" *ngIf ="modelName=='dataframe' && userCanAdd">
                            <div class="flex justify-content-end" >
                                <button id="cloneButton" name="cloneButton" type="button"
                                    class="p-button-rounded p-button-text " pButton pRipple
                                    icon="pi pi-clone" pTooltip="Clone..." 
                                    (click)="onClone(row)"></button>
                                  
                            </div>
                            
                        </td>
                        <td class="p-0 pr-3" *ngIf ="modelName=='dataframe' && userCanDownload">
                            <div class="flex justify-content-end" >
                                <div><i class="pi pi-spin pi-spinner" *ngIf="row.isloading"></i></div>
                                <button id="downloadButton" name="downloadButton" type="button" *ngIf="!row.isloading"
                                    class="p-button-rounded p-button-text " pButton pRipple
                                    icon="pi pi-download " pTooltip="Download" 
                                    (click)="onDownload(row)"></button>
                                    
                            </div>
                            
                        </td>


                    </tr>
                </ng-container>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="3">{{NoRecordMessage}}</td>
                </tr>
            </ng-template>
        </p-table>
        <div *ngIf="model.length > 0 && defaultTableRows >= model.length" style="text-align: center;">
            <p class="row-paging">Showing 1 to
              {{model.length}} of {{model.length}} {{pageTitle}}</p>
          </div>
    </div>
</div>