import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Airport } from '../models/airport';
import { BaseService } from 'src/app/common/services/base.service';


@Injectable({
  providedIn: 'root'
})
export class AirportService extends BaseService {

  constructor(private http: HttpClient) {
    super()
    this.baseUrl = environment.apiUrl.admin;
  }

  getDatesOfFiles(): Observable<string[]> {
    const url = `${this.baseUrl}date-catalogue`
    return this.http.get<string[]>(url)
      .pipe(
        catchError(this.httpHandleError)
      )
  }
  getAirports(dte: string): Observable<Airport[]> {
    const url = `${this.baseUrl}airport/${dte}`
    return this.http.get<Airport[]>(url)
      .pipe(
        catchError(this.httpHandleError)
      )
  }

  getAirport(dte: string, icao: string): Observable<Airport> {
    const url = `${this.baseUrl}airport/${dte}/${icao}`
    return this.http.get<Airport>(url)
      .pipe(
        catchError(this.httpHandleError)
      )
  }
}
