import { forkJoin, of } from 'rxjs';
import { concatMap, takeUntil } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { AirlineService } from '../../services/airline.service';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { Organization, OrganizationApp } from 'src/app/features/administration/models/organization';
import { SettingsService } from 'src/app/common/services/settings.service';
import { Action, BaseViewComponent } from 'src/app/common/components/base-view.component';
import { Setting, SettingPair } from 'src/app/common/models/setting';
import { ApplicationService } from 'src/app/features/administration/services/application.service';
import { Application } from 'src/app/features/administration/models/application';
import { OrganizationType } from 'src/app/features/administration/models/organization';
import { AircraftService } from 'src/app/features/administration/services/aircraft.service';
import { Aircraft, AircraftApplication, AircraftPair, AppsAssignedToAircraft } from 'src/app/features/administration/models/aircraft';
import { OrgFormChanges } from 'src/app/features/administration/components/organization-details/organization-details.component';
import { OrganizationService, Status } from 'src/app/features/administration/services/organization.service';
import { AircraftGroupService } from 'src/app/features/administration/services/aircraft-group.service';
import { SortUtil } from 'src/app/platform/util/sortUtil';
import { AircraftGroup } from 'src/app/features/administration/models/aircraftGroup';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { permissions } from 'src/app/security/models/permissions';
import { CommonService } from 'src/app/common/services/common.service';
import { Components } from '../../integration/administration.components';

@Component({
  selector: 'app-airline-details',
  templateUrl: './airline-details.component.html',
  styleUrls: ['./airline-details.component.scss']
})
export class AirlineDetailsComponent extends BaseViewComponent implements OnInit {
  // For Airline
  airline: Organization = null;
  settings: Setting[];
  orgType: OrganizationType = OrganizationType.Airline;

  // For Applications
  availableApplications: Application[];
  assignedApplications: OrganizationApp[] = [];
  applicationChanged = false;

  // For Aircraft Applications
  airlineAircraft: AircraftPair[] = [];
  selectedAircraftApps: AircraftApplication[];
  airlineAircraftApps: AircraftApplication[] = [];
  aircraft: Aircraft[];
  aircraftGroupsOptions: SelectItem[];
  aircraftGroups: AircraftGroup[];
  selectedAircraftGroup: number;
  allAircraftAppsSelected = false;
  unchangedApplicationsPayload: any[] = [];
  loggedIn = false;
  // user permissions
  canView = false;
  canAdd = false;
  canEdit = false;
  airlineDetailsView = false;
  airlineDetailsEdit = false;
  codeIsReadOnly = true;

  messageLabel = 'airline';

  constructor(private airlineService: AirlineService,
    private securityUserService: SecurityUserService,
    private settingsService: SettingsService,
    private applicationService: ApplicationService,
    private organizationService: OrganizationService,
    private aircraftService: AircraftService,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private aircraftGroupService: AircraftGroupService,
    messageService: MessageService,
    router: Router,
    confirmationService: ConfirmationService,
    breadcrumbService: BreadcrumbService,
  ) {
    super(messageService, confirmationService, router, breadcrumbService);

    // Note: Service won't update breadcrumb if caller already did so.
    // TDY user who comes to this page via the airlines page must update breadcrumb trail. It differs
    // for airline users (HOME/airlines/airline-details vs HOME/airline-details)
    this.breadcrumbService.setItems(route, [
      { label: Components.AirlineDetails.label }  // No routerLink value for current page
    ]);

    // Refresh the same page when querry params changed in url. Specially when selecting current airline.
    this.route.queryParams.subscribe((params) => {
      if (params['loggedIn']) {
        this.loggedIn = true;
        this.ngOnInit();
      }
    });
  }

  ngOnInit(): void {
    this.canView = this.securityUserService.userHasPermission(permissions.admin.airlines.view);
    this.canAdd = this.securityUserService.userHasPermission(permissions.admin.airlines.create);
    this.canEdit = this.securityUserService.userHasPermission(permissions.admin.airlines.manage);

    // Separate permissions for currently loggedIn user who has no permission to view/edit other airlines.
    this.airlineDetailsView = this.securityUserService.userHasPermission(permissions.admin.airline_details.view);
    this.airlineDetailsEdit = this.securityUserService.userHasPermission(permissions.admin.airline_details.manage);

    const queryParamExists = !!this.route.snapshot.queryParamMap.get('a'); // a = airline_id

    if (!queryParamExists && this.canAdd) {
      this.canEdit = true;
      this.addAirline();
      this.codeIsReadOnly = false;
    }
    else if (queryParamExists && this.canEdit || queryParamExists && this.canView) {
      this.loadAirline(false, +this.route.snapshot.queryParamMap.get('a'));
    }
    else if (this.airlineDetailsView) {
      // load current airline...
      this.loadAirline(true);
    }
  }

  getPageTitle(): string {
    return this.isNew ? "New Airline" : Components.AirlineDetails.label;
  }

  /**
  * Add airline
  */
  addAirline() {
    this.loadingCount++;
    // Update page title and breadcrumb with New...
    this.updateBreadcrumb('New Airline');

    forkJoin([this.commonService.listApplication(),
    this.settingsService.getDefaultSettings(OrganizationType.Airline)])
      .pipe(
        takeUntil(this.ngUnsubscribe)).subscribe({
          next: ([apps, settings]) => {
            this.availableApplications = apps;
            this.settings = settings ?? [];

            // new airline
            this.isNew = true;
            this.airline = new Organization();
            this.airline.id = -1;
          },
          error: error => {
            this.showErrorMsg(error, Action.Add, `${this.messageLabel}`);
            this.loadingCount--;
          },
          complete: () => {
            this.loadingCount--;
          }
        });
  }

  /**
    * Load  exsisting airline details
    */
  loadAirline(currentAirline: boolean, airlineID?: number) {
    this.loadingCount++;
    this.isNew = false;

    // Check if Airline have any aircraft.
    const airlineAircraftObservable$ = currentAirline ? this.commonService.listAircraftFilters() : this.aircraftService.listAircraftFiltersByOrgID(airlineID);

    airlineAircraftObservable$.pipe(
      takeUntil(this.ngUnsubscribe),
      concatMap((aircraft) => {
        this.airlineAircraft = aircraft;
        if (this.airlineAircraft.length > 0) {
          return forkJoin([currentAirline ? this.airlineService.getCurrentAirline() : this.airlineService.getAirline(airlineID),
          currentAirline ? this.settingsService.getSettings(OrganizationType.Airline) : this.settingsService.getSettingsByOrg(OrganizationType.Airline, airlineID),
          currentAirline ? this.applicationService.getCurrentAssignedOrgApps(OrganizationType.Airline) : this.applicationService.getAssignedOrgApps(airlineID, OrganizationType.Airline),
          this.commonService.listApplication(),
          currentAirline ? this.commonService.listAircraftGroupFilters() : this.aircraftGroupService.listAircraftGroupFiltersByOrgID(airlineID),
          currentAirline ? this.applicationService.getCurrentAirlineAircraftApps() : this.applicationService.getAirlineAircraftApps(airlineID)
          ])
        } else {
          return forkJoin([currentAirline ? this.airlineService.getCurrentAirline() : this.airlineService.getAirline(airlineID),
          currentAirline ? this.settingsService.getSettings(OrganizationType.Airline) : this.settingsService.getSettingsByOrg(OrganizationType.Airline, airlineID),
          currentAirline ? this.applicationService.getCurrentAssignedOrgApps(OrganizationType.Airline) : this.applicationService.getAssignedOrgApps(airlineID, OrganizationType.Airline),
          this.commonService.listApplication(),
          ])
        }
      }
      )).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
        next: ([airline, settings, airlineApplications, allApplication, airlineAircraftGroups, aircraft]) => {
          this.loadAirlineData(airline, settings, airlineApplications, allApplication, airlineAircraftGroups, aircraft)
        }, error: error => {
          this.showErrorMsg(error, Action.Get, `${this.messageLabel}`, currentAirline ? `${airlineID}` : '');
          this.loadingCount--;
        }, complete: () => {
          this.loadingCount--;
        }
      });
  }

  loadAirlineData(airline: Organization, settings: Setting[], assignedApps: OrganizationApp[], allApplication: Application[], aircraftGroups: AircraftGroup[], aircraftApps: AppsAssignedToAircraft[]) {
    this.airline = airline;
    this.unchangedApplicationsPayload = assignedApps.map(
      (app) => {
        return {
          application_id: app.application_id,
          number_of_users: app.number_of_users,
          number_of_aircrafts: app.number_of_aircrafts
        }
      }
    )

    this.assignedApplications = assignedApps ?? [];
    // Filter available apps
    this.availableApplications = allApplication;
    if (this.assignedApplications?.length > 0) {
      this.availableApplications = allApplication.filter(s => assignedApps.findIndex(t => t.application_id == s.application_id) == -1) ?? [];
    }

    if (this.airlineAircraft.length > 0) {
      // Merge applications with each aircraft for gird view...
      this.airlineAircraftApps = this.airlineAircraft.map(
        (ac) => {
          return {
            aircraft: ac,
            availApplications: this.assignedApplications.filter(app => new Date(app.expiry_date) > new Date()) ?? [],
            selectedApplications: this.assignedApplications.filter((app) =>
              app.application_id === (aircraftApps.find((acResponse) =>
                acResponse.aircraft_id === ac.aircraft_id)?.applications.find((ap) =>
                  ap.application_id === app.application_id))?.application_id)
          }
        }
      );

      this.aircraftGroupsOptions = this.populateDropdownItems<AircraftGroup>(aircraftGroups, 'aircraftgroup_name', 'aircraftgroup_id');
      this.aircraftGroups = aircraftGroups;
    }

    this.settings = settings ?? [];
    this.updateBreadcrumb(Components.AirlineDetails.label + ' (' + this.airline.code + ')');
  }

  populateDropdownItems<T>(items: T[], labelProp: string, valueProp: string, addAllOption = true): SelectItem[] {
    const options = [];
    // Some error checking
    if (!items || items.length == 0) {
      return options;
    }

    const keys = Object.keys(items[0]);
    if (!keys.includes(labelProp) || !keys.includes(valueProp)) {
      return options;
    }

    items.forEach(i => {
      options.push({ label: i[labelProp], value: i[valueProp] });
    });

    SortUtil.sortByLabel(options);

    if (addAllOption) {
      options.splice(0, 0, { label: 'All', value: null });   // Insert empty item as first item
    }
    return options;
  }

  onAppSourceChanged() {
    // Merge applications with each aircraft for gird view...
    this.airlineAircraftApps = this.airlineAircraft.map(
      (aircraft) => {
        return {
          aircraft: aircraft,
          availApplications: this.assignedApplications,
        }
      }
    );
  }

  onAircraftAppsChanged(updatedAircraftApps: AircraftApplication[]) {
    this.airlineAircraftApps = updatedAircraftApps;
  }

  /**
 * Save airline changes
 */
  onSubmit(event: OrgFormChanges) {
    this.loadingCount++;
    const queryParamExists = !!this.route.snapshot.queryParamMap.get('a'); // a = airline_id
    const assignedApps = this.assignedApplications.map((app) => {
      return {
        application_id: app.application_id,
        expiry_date: app.expiry_date,
        number_of_users: app.number_of_users,
        number_of_aircrafts: app.number_of_aircrafts
      }
    })

    // Map settings to setting pair
    const settingPairs: SettingPair[] = [];
    this.settings.forEach(s => settingPairs.push({ settingdefinition_id: s.settingdefinition_id, setting_value: s.setting_value }));

    if (this.canAdd && !queryParamExists) {
      const aircraftAppsPost = this.airlineAircraftApps.map(
        (aircraft) => {
          return {
            aircraft_id: aircraft.aircraft.aircraft_id,
            selectedApplications: aircraft.selectedApplications
          }
        });
      const addAirlinePayload = {
        id: this.airline.id,
        code: this.airline.code,
        name: this.airline.name,
        web_logo: this.airline.web_logo,
        mobile_logo: this.airline.mobile_logo,
        airline_iata_code: this.airline.airline_iata_code,
        airline_settings: settingPairs
      }
      this.airlineService.addAirline(addAirlinePayload).pipe(concatMap(id => {
        this.airline.id = id;
        const sources = [
          event.apps === true ? this.applicationService.assignAppsToOrganization(id, assignedApps, OrganizationType.Airline) : of(null),
          event.aircraftApps === true ? this.applicationService.updateAirlineAircraftApps(aircraftAppsPost) : of(null)
        ];
        return forkJoin(sources);
      }),
        takeUntil(this.ngUnsubscribe)).subscribe(
          {
            next: () => {
              this.updateBreadcrumb(Components.AirlineDetails.label + ' (' + this.airline.code + ')');
              this.router.navigate([], {
                relativeTo: this.route,
                queryParams: {
                  a: this.airline.id
                },
                queryParamsHandling: 'merge'
              }).then();
            },
            error: err => {
              this.showErrorMsg(err, Action.Add, `${this.messageLabel}`);
              this.loadingCount--;
            },
            complete: () => {
              this.isNew = false;
              this.codeIsReadOnly = true;
              this.organizationService.statusChange({ status: Status.Success, message: `Airline ${this.airline.name} added` });
              this.loadingCount--;
            }
          })
    }
    else if (this.canEdit && queryParamExists) {
      const aircraftAppsPost = this.airlineAircraftApps.map(
        (aircraft) => {
          return {
            aircraft_id: aircraft.aircraft.aircraft_id,
            selectedApplications: aircraft.selectedApplications
          }
        });
      const updateAirlinePayload = {
        id: this.airline.id,
        name: this.airline.name,
        web_logo: this.airline.web_logo,
        mobile_logo: this.airline.mobile_logo,
        airline_iata_code: this.airline.airline_iata_code,
      }
      this.airlineService.updateAirline(updateAirlinePayload).pipe(concatMap(id => {
        this.airline.id = id;
        const sources = [
          event.apps === true ? this.applicationService.updateOrganizationApps(id, assignedApps, OrganizationType.Airline) : of(null),
          event.settings === true || this.isNew ? this.settingsService.updateSetting(settingPairs, OrganizationType.Airline, id) : of(null),
          event.aircraftApps === true ? this.applicationService.updateAirlineAircraftApps(aircraftAppsPost) : of(null)
        ];
        return forkJoin(sources);
      }),
        takeUntil(this.ngUnsubscribe)).subscribe(
          {
            next: () => {
              this.updateBreadcrumb(Components.AirlineDetails.label + ' (' + this.airline.code + ')');
              this.router.navigate([], {
                relativeTo: this.route,
                queryParams: {
                  a: this.airline.id
                },
                queryParamsHandling: 'merge'
              }).then();
            },
            error: err => {
              this.showErrorMsg(err, Action.Update, `${this.messageLabel}`);
              this.loadingCount--;
            },
            complete: () => {
              this.isNew = false;
              this.organizationService.statusChange({ status: Status.Success, message: `Airline ${this.airline.name} updated` });
              this.loadingCount--;
              this.ngOnInit();
            }
          })
    } else {
      const currentAirlinePayload = {
        id: this.airline.id,
        name: this.airline.name,
        web_logo: this.airline.web_logo,
        mobile_logo: this.airline.mobile_logo
      }
      this.airlineService.updateCurrentAirline(currentAirlinePayload).pipe(concatMap(res => {
        const sources = [
          event.settings === true || this.isNew ? this.settingsService.updateCurrentOrgSetting(settingPairs, OrganizationType.Airline) : of(null),
        ];
        return forkJoin(sources);
      }),
        takeUntil(this.ngUnsubscribe)).subscribe(
          {
            next: () => {
              this.updateBreadcrumb(Components.AirlineDetails.label + ' (' + this.airline.code + ')');
              this.router.navigate([], {
                relativeTo: this.route,
                queryParams: {
                  a: this.airline.id
                },
                queryParamsHandling: 'merge'
              }).then();
            },
            error: err => {
              this.showErrorMsg(err, Action.Update, `${this.messageLabel}`);
              this.loadingCount--;
            },
            complete: () => {
              this.isNew = false;
              this.organizationService.statusChange({ status: Status.Success, message: `Airline ${this.airline.name} updated` });
              this.loadingCount--;
              this.ngOnInit();
            }
          })
    }
  }

  /**
  * Reset airline form state
  */
  onReset() {
    this.ngOnInit()
  }

  /**
 * Cancel airline changes
 */
  onCancel() {
    this.router.navigate([Components.Airlines.path]).then();
  }
}
