import { Injectable } from "@angular/core";
import { FeatureItem, FeatureRoot } from "src/app/common/models/feature-item.model";
import { MenuService } from "src/app/common/services/menu.service";

@Injectable({
    providedIn: 'root'
  })
  export class RouteSecurityService {

    private static readonly coreRoutes : { [link: string]: string } = {     
        '/': undefined,
        'login': undefined,
        'access-denied': undefined,
        'errors': undefined,
    };

    private permissionsForRoutes: { [link: string]: string } = RouteSecurityService.coreRoutes;
    private readonly maxRecursionDepth = 5;
    
    constructor(menuService: MenuService) {
        menuService.getFeatures().forEach((item: FeatureRoot) => {
            this.mapFeatureMenuItemRoutes(item);
        });
    }

    private mapFeatureMenuItemRoutes(featureRoot: FeatureRoot, recursionGuard: number = 0) {
        featureRoot.items.forEach((item) => {
            if (item.path) 
                this.permissionsForRoutes[item.path] = item.permission;
            if (item.items && recursionGuard < this.maxRecursionDepth)
                this.mapFeatureMenuItemRoutes(item, recursionGuard + 1)
        });
    }

    public getPermissionForRoute(route: string): string {
        if (route.startsWith('/'))
            route = route.substring(1);

        const guard = this.permissionsForRoutes[route];
        return guard;
    }
  }